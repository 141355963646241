export default function (to, from, next) {
  const token = String(localStorage.getItem('auth-token'));

  if (!token || token.trim() !== 'null') {
    return next();
  }

  return next({
    path: '/login',
    query: { redirect: to.fullPath },
  });
}
