export function getTags({
  traffic_source_id, sort_column, sort_dir, limit, offset,
} = {}) {
  return this.affiliates
    .get('traffic-source-tags', {
      params: {
        traffic_source_id,
        sort_column,
        sort_dir,
        limit,
        offset,
      },
    })
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err));
}

export function getTagsCount({
  traffic_source_id, sort_column, sort_dir, limit, offset,
} = {}) {
  return this.affiliates
    .get('traffic-source-tags/count', {
      params: {
        traffic_source_id,
        sort_column,
        sort_dir,
        limit,
        offset,
      },
    })
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err));
}

export function createTag({
  traffic_source_id, tag_manager_template_id, name, embed_platform, triggers, attributes,
} = {}) {
  return this.affiliates
    .post('traffic-source-tags', {
      traffic_source_id,
      tag_manager_template_id,
      name,
      embed_platform,
      triggers,
      attributes,
    })
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err));
}

export function updateTag({
  id, traffic_source_id, tag_manager_template_id, name, embed_platform, triggers, attributes,
} = {}) {
  return this.affiliates
    .put(`traffic-source-tags/${id}`, {
      traffic_source_id,
      tag_manager_template_id,
      name,
      embed_platform,
      triggers,
      attributes,
    })
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err));
}

export function getTag(id) {
  return this.affiliates
    .get(`traffic-source-tags/${id}`)
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err));
}

export function deleteTag({ id }) {
  return this.affiliates
    .delete(`traffic-source-tags/${id}`)
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err));
}
