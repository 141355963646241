import eventBus from 'src/lib/eventBus';
import { debounce } from 'lodash';

const state = () => ({
  loginProgress: true,
  isAuthenticated: !!localStorage.getItem('auth-token') && localStorage.getItem('auth-token') !== 'null',
  userToken: false,
  userTtl: 0,
  recaptcha: '',
});
const actions = {
  login({ commit }, data) {
    commit('loginPending');
    return this.$request
      .post('auth/login?rate_limiting=true', data)
      .then((res) => {
        if (res.data) {
          commit('userToken', res.data.payload.id);
          commit('userTtl', res.data.payload.ttl);
          this.$request.setAuthToken(res.data.payload.id);
        }
        if (res.data.payload.google2fa_challenge_needed) {
          commit('loginPending');
          eventBus.$emit('otp-dialogue');
        }
        commit('loginSuccess');
        eventBus.$emit('login');
      })
      .catch((err) => {
        commit('loginFailed');
        return Promise.reject(err);
      });
  },
  logout({ commit }) {
    commit('logout');
    commit('userToken', false);
    this.$api.postAuthLogout()
      .then(() => {
        this.$request.removeAuthToken();
      });

    return Promise.resolve();
  },
  loginSuccess({ commit }) {
    commit('loginSuccess');
  },
  loginSuccessLazy: debounce(({ dispatch }) => dispatch('loginSuccess'), 100),
  loginPending({ commit }) {
    commit('loginPending');
  },
};
const mutations = {
  loginPending: () => {
    state.loginProgress = true;
  },
  loginSuccess: () => {
    state.loginProgress = false;
    state.isAuthenticated = true;
  },
  userToken(state, id) {
    state.userToken = id;
  },
  userTtl(state, ttl) {
    state.userTtl = ttl;
  },
  loginFailed: () => {
    state.loginProgress = false;
    state.isAuthenticated = false;
  },
  logout: () => {
    state.isAuthenticated = false;
  },
  recaptcha(state, data) {
    state.recaptcha = data;
  },
};

export default () => ({
  state: state(),
  actions,
  mutations,
});
