<template>
  <section class="promocodes-loader">
    <div class="card wavebg w-100">
      <div class="d-flex pc-header justify-content-between">
        <div class="header-left">
          <div class="form-group mt-0 mb-0">
            <input placeholder="Search..." class="form-control mt-0 mr-8" type="text">
          </div>
        </div>
        <div class="header-right">
          <div class="d-flex justyfy-content-end ">
          </div>
          <div class="empty-pagination ml-8" style="width: 269px">
            <div class="empty-select mr-8" style="width: 80px">
              100
            </div>
            <span>> >> Page</span>
            <div class="form-group mb-0 mt-0">
              <input placeholder="1" class="form-control mt-0 pl-16 pr-16" style="width: 40px">
            </div>
            <span>of 2 > >></span>
          </div>
        </div>
      </div>
      <div class="filter-search pb-24">
        Filter i Search
      </div>
      <div class="table-empty mt-16 mb-8">
        <div class="table-empty__head">
        </div>
        <div class="table-empty__body">
        </div>
        <div class="mob-table__block mob-table__block-actions pt-0">
          <div class="d-flex justify-content-between">
            <span>Promocode</span>
            <span>0</span>
          </div>
          <div class="d-flex justify-content-between">
            <span>Promocode</span>
            <span>0</span>
          </div>
          <div class="d-flex justify-content-between">
            <span>Promocode</span>
            <span>0</span>
          </div>
          <div class="mob-table__actions">
            <span class="text-green">Promocode</span>
            <span class="text-red">Promocode</span>
            <span class="text-green">Promocode</span>
          </div>
        </div>
        <div class="mob-table__block mob-table__block-actions">
          <div class="d-flex justify-content-between">
            <span>Promocode</span>
            <span>0</span>
          </div>
          <div class="d-flex justify-content-between">
            <span>Promocode</span>
            <span>0</span>
          </div>
          <div class="d-flex justify-content-between">
            <span>Promocode</span>
            <span>0</span>
          </div>
          <div class="mob-table__actions">
            <span class="text-green">Promocode</span>
            <span class="text-red">Promocode</span>
            <span class="text-green">Promocode</span>
          </div>
        </div>
        <div class="mob-table__block mob-table__block-actions">
          <div class="d-flex justify-content-between">
            <span>Promocode</span>
            <span>0</span>
          </div>
          <div class="d-flex justify-content-between">
            <span>Promocode</span>
            <span>0</span>
          </div>
          <div class="d-flex justify-content-between">
            <span>Promocode</span>
            <span>0</span>
          </div>
          <div class="mob-table__actions">
            <span class="text-green">Promocode</span>
            <span class="text-red">Promocode</span>
            <span class="text-green">Promocode</span>
          </div>
        </div>
        <div class="mob-table__block mob-table__block-actions">
          <div class="d-flex justify-content-between">
            <span>Promocode</span>
            <span>0</span>
          </div>
          <div class="d-flex justify-content-between">
            <span>Promocode</span>
            <span>0</span>
          </div>
          <div class="d-flex justify-content-between">
            <span>Promocode</span>
            <span>0</span>
          </div>
          <div class="mob-table__actions">
            <span class="text-green">Promocode</span>
            <span class="text-red">Promocode</span>
            <span class="text-green">Promocode</span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {

};
</script>

<style lang="scss">
.promocodes-loader{
  overflow: hidden;
  .header-left, .header-right{
    display: flex;
  }
  .filter-search{
    display: none;
  }
  @media (max-width: 1200px){
    .header-right{
      flex-direction: column;
      align-items: flex-end;
      >div:first-child{
        margin-bottom: 10px;
      }
    }
    .header-left{
      align-items: center;
    }
  }
  @media (max-width: 650px){
    .filter-search{
      display: block;
      border-bottom: 1px solid #e7ecf1;
    }
    .pc-header{
      display: none !important;
    }
  }
}


</style>
