export default {
  methods: {
    isHidden(dimesnsion) {
      return Boolean((this.reports[this.currentReport].defaultFilter[dimesnsion] !== void 0 && this.reports[this.currentReport].defaultFilter[dimesnsion].op !== '!=')
        || (this.reports[this.currentReport].currentFilter[dimesnsion] !== void 0 && this.reports[this.currentReport].currentFilter[dimesnsion].op !== '!='));
    },
    isHiddenMany(list) {
      return list
        .map(e => Boolean(this.isHidden(e)))
        .every(e => e);
    },
  },
};
