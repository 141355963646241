<template>
  <div class="profile-card profile__general-info">
    <edit-general ref="editProfileModal" />
    <div class="row">
      <h5 class="card-title d-flex justify-content-between align-items-center w-100 pt-0 mb-12 ml-14 mr-14">
        <span>{{ $t('profile.generalInfo.title') }}</span>
        <span class="btn-wrapper">
          <button
            class="btn btn-outline-primary btn-sm btn__pass-change"
            @click="enablePasswordChange"
          >
            {{ $t('profile.generalInfo.changePassword') }}
          </button>
          <button
            class="btn btn-outline-primary btn-sm"
            @click="$refs.editProfileModal.open({...form, user_date_of_birth: user.date_of_birth})"
          >
            {{ $t('profile.buttons.edit') }}
          </button>
        </span>
      </h5>
    </div>
    <hr />
    <div class="row pl-14 pr-14">
      <MenuItem
        class="mt-16 ui-12"
        :text="`${form.first_name} ${form.last_name}`"
        :type="birthday"
        icon="fas fa-user"
      />
      <MenuItem
        class="mt-16 ui-12"
        :text="form.email"
        :type="$t('profile.generalInfo.email')"
        icon="fas fa-envelope"
      />
      <div class="col-12 pl-0 item-container">
        <MenuItem
          v-if="form.phone_number"
          class="col-12 col-sm-6 pl-0 mt-16"
          :text="formattedPhone"
          :type="$t('profile.generalInfo.contactNumber')"
          icon="fas fa-phone"
        />
        <MenuItem
          v-if="form.messenger_id"
          class="col-12 col-sm-6 pl-0 mt-16"
          :text="form.messenger_id"
          :type="messengerType"
          :icon="form.messenger"
        />
      </div>
      <MenuItem
        class="mt-16 ui-12"
        :text="joinValues([currentCountry, form.city]) || $t('profile.generalInfo.notSpecified')"
        :type="joinValues([form.address, form.postal_code]) || $t('profile.generalInfo.notSpecified')"
        icon="fas fa-location-pin"
      />
      <div class="icon-label mt-16">
        <i class="fas fa-globe" />
        <span class="ml-8">{{ $t('profile.generalInfo.timezone') }}: </span>
        <span class="text">{{ currentTimezone }}</span>
      </div>
      <div v-if="form.timezone" class="icon-label mt-4">
        <i class="fas fa-calendar" />
        <span class="ml-8">{{ $t('profile.generalInfo.firstDay') }}: </span>
        <span class="text">{{ $t(translateMap[form.first_day_of_week]) }}</span>
      </div>
    </div>
    <div v-show="false">
      <input ref="telRef" />
    </div>
    <change-password ref="changepassword" />
  </div>
</template>

<script>
import ChangePassword from './ChangePassword';
import MenuItem from '@/components/Form/MenuItem.vue';
import EditGeneral from './Modal/EditGeneral.vue';
import TelInput from 'intl-tel-input';


export default {
  components: {
    EditGeneral,
    MenuItem,
    ChangePassword,
  },
  props: ['user', 'form', 'btnDisable'],
  data() {
    return {
      disabled: true,
      isLoading: false,
      errors_: {},
      forms: {},
      calendarOpen: false,
      tel: TelInput,
      translateMap: {
        monday: 'profile.generalInfo.days.mo',
        sunday: 'profile.generalInfo.days.su',
      }
    };
  },
  computed: {
    currentCountry() {
      const country = this.$store.state.misc.countries.find((country) => country.code === this.form.country_code);

      if (country) {
        return `${country.name} - ${country.name_native}`
      }

      return this.form.country_code;
    },
    currentTimezone() {
      const timezeone = this.$store.state.misc.timezones.find((timezone) => timezone.zone === this.form.timezone);
      return timezeone?.name || this.form.timezone
    },
    birthday() {
      if (!this.user.date_of_birth) return '';

      return `${this.$t('profile.generalInfo.birthDate')}: ${moment(this.user.date_of_birth).format('DD MMM YYYY')}`
    },
    messengerType() {
      return this.$store.state.misc.messengers.find(({ id }) => this.form.messenger === id)?.name
    },
    messengers() {
      return this.$store.state.misc.messengers.map((e) => ({
        id: e.id,
        text: e.name,
      }));
    },
    formattedPhone() {
      if (this.form.phone_number && this.tel.setNumber) {
        this.tel.setNumber(this.form.phone_number, intlTelInputUtils.numberFormat.INTERNATIONAL);
        return this.tel.getNumber(intlTelInputUtils.numberFormat.INTERNATIONAL);
      }

      return this.form.phone_number;
    },
  },
  methods: {
    joinValues(values) {
      return values.filter(el => el).join(', ');
    },
    enablePasswordChange() {
      this.$refs.changepassword.open();
    },
    initTel() {
      const phone$el = this.$refs.telRef;
      this.tel = TelInput(phone$el, {
        initialCountry: this.form.country_code,
        formatOnDisplay: true,
        nationalMode: true,
        autoPlaceholder: true,
        autoHideDialCode: true,
      });
    },
  },
  mounted() {
    this.initTel();
  },
};
</script>

<style lang="scss" scoped>
.btn-wrapper {
  display: flex;
  gap: 8px;

  @media (max-width: 450px) {
    flex-direction: column;
  }
}

.item-container {
  display: flex;
  flex-wrap: wrap;
}

.icon-label {
  font-size: 12px;
  width: 100%;

  .text {
    font-weight: 600;
  }
}

@media (max-width: 992px) {
  .profile .row, .profile .row .card-title {
    margin-left: 0;
    margin-right: 0;
    padding-left: 0;
  }
}


</style>
<style lang="scss">
@import 'src/assets/theme/default/profile/editprofile';
@import '~flatpickr/dist/flatpickr';
</style>
