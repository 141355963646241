<template>
  <div class="ui-d-flex">
    <div v-if="icon" class="icon-wrapper">
      <ui-icon
        v-if="iconMap[icon] && iconMap[icon].icon"
        :custom-class="iconMap[icon].icon"
        :color="iconMap[icon].color || '#fff'"
        :name="icon"
        :size="16"
      />
      <img
        v-if="iconMap[icon] && iconMap[icon].img"
        :src="iconMap[icon].img"
        alt=""
      />
      <i v-else style="font-size: 16px" :class="icon"></i>
    </div>
    <div class="item">
      <el-tooltip :disabled="!tooltipPlacement" :placement="tooltipPlacement" :content="text">
        <div class="text">
          <a
            v-if="isSocial"
            :href="getSocialLink(type, text)"
            :target="type === 'email' && '_blank'"
          >
            {{ text }}
          </a>
          <span v-else>{{ text }}</span>
        </div>
      </el-tooltip>
      <div class="label">
        {{ type }}
      </div>
    </div>
  </div>
</template>

<script>

export default {
  props: {
    icon: String,
    text: String,
    type: String,
    iconColor: String,
    isSocial: Boolean,
    tooltipPlacement: String,
  },
  data() {
    return {
      iconMap: {
        email: {
          icon: 'fas fa-envelope',
        },
        skype: {
          icon: 'ui-icon skype-icon',
          color: '#4088f7',
        },
        telegram: {
          img: require('@/assets/theme/icon/telegram.svg'),
        },
        instagram: {
          img: require('@/assets/theme/icon/instagram.svg'),
        },
        facebook: {
          img: require('@/assets/theme/icon/facebook.svg'),
        },
        twitter: {
          img: require('@/assets/theme/icon/twitter.svg'),
        },
        viber: {
          img: require('@/assets/theme/icon/viber.svg'),
        },
        whatsapp: {
          img: require('@/assets/theme/icon/whatsapp.svg'),
        },
      }
    }
  },
  methods: {
    getSocialLink(type, value) {
      switch (type) {
        case 'telegram':
          return `tg://resolve?domain=${value.replace('@', '')}`
        case 'skype':
          return `skype:${value.replace('live:.cid.', '')}?chat`
        case 'email':
          return `mailto:${value}`
        default:
          return ''
      }
    },
  },
};

</script>

<style lang="scss" scoped>
.item {
  display: flex;
  flex-direction: column;
  margin-left: 12px;
  overflow: hidden;

  .text {
    height: 22px;
    font-size: 14px;
    font-weight: 600;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    line-height: normal;

    a {
      color: inherit;
      text-decoration: none;
    }
  }

  .label {
    font-size: 12px;
    height: 17px;
    line-height: normal;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.icon-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  min-width: 40px;
  height: 40px;
  border-radius: 8px;
  background-color: #e7ecf1;
  font-size: 16px;
  color: #303634;
}
</style>
