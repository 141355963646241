<template>
  <div class="row">
    <div class="col-12 col-xl-6 p-0">
      <card class="android-app">
        <span class="header">
          {{ $t('androidApp.downloadApp') }}
        </span>
        <hr />
        <div class="row">
          <div class="col-12">
            <label class="title">{{ $t('androidApp.offer') }} </label>
            <v-select
              v-model="offerId"
              :options="offersOption"
              class="el-input"
              :placeholder="$t('promoCodes.addPromocodes.selectOffer')"
            />
          </div>
        </div>
        <div class="row mt-16">
          <div class="col-12 select-wrapper" :class="{'disabled': !offerId}">
            <label class="title">{{ $t('androidApp.brand') }} </label>
            <v-select
              v-model="brandId"
              :options="brandsOption"
              class="el-input"
              :disabled="!offerId"
              :placeholder="$t('profile.generalInfo.notSpecified')"
            />
            <div v-if="errors_.site_id" class="text-danger text-right">
              {{ errors_.site_id[0].message }}
            </div>
          </div>
        </div>
        <div
          class="select-wrapper mt-16"
          :class="{'disabled': !brandId}"
        >
          <label>{{ $t('trafficSources.trafficSource') }}</label>
          <v-select
            key="id"
            v-model="trafficSourceId"
            :options="trafficSourceList"
            :disabled="!brandId"
            class="el-input"
            label="name"
            @change="changeSource"
            :placeholder="$t('profile.generalInfo.notSpecified')"
          />
        </div>
        <el-popover
          ref="popover"
          v-model="warningOpen"
          placement="top-start"
          trigger="manual"
          width="320"
        >
          <p>{{ $t('promoCodes.addPromocodes.changeSubId') }}</p>
          <button
            class="btn btn-outline-primary btn-md"
            @click="warningOpen = false"
          >
            {{ $t('offerRequest.btnCancel') }}
          </button>
          <button
            class="btn btn-primary btn-md ml-3"
            @click="confirmChanges(); warningOpen = false"
          >
            {{ $t('offerRequest.btnConfirm') }}
          </button>
        </el-popover>
        <div class="row row-wrap__subid">
          <div
            v-for="(item, i) in subIds"
            :key="i"
            class="col-6 col-6__add-subid"
            :class="{'disabled': !brandId}"
          >
            <div class="subIdPopover" v-if="i === 0" v-popover:popover></div>
            <fg-input
              v-model="item.value"
              :placeholder="$t('trafficSources.postbacks.notSpecified')"
              class="no-border form-control-lg mb-0"
              :disabled="!brandId"
            >
              <label
                slot="label"
                class="d-flex justify-content-between"
              >
                <span>SubID{{ subIds.indexOf(item) + 1 }}</span>
                <a
                  v-show="subIds.indexOf(item) === subIds.length - 1 && subIds.length > 1"
                  href="#"
                  class="btn-cross"
                  @click.prevent="popSubIds"
                >
                  <span>×</span>
                </a>
              </label>
            </fg-input>
          </div>
          <div class="col-6 d-flex align-items-end col-6__add-subid">
            <button
              :disabled="subIds.length >= 5 || !brandId"
              href="#"
              class="btn btn-block btn-outline-primary btn-sm mt-24 mb-0"
              @click.prevent="subIds.length < 5 && addSubId()"
            >
              + {{ $t('promoCodes.addPromocodes.addAnotherSubid') }}
            </button>
          </div>
        </div>
        <div class="row mt-16">
          <div class="col-12 select-wrapper" :class="{'disabled': !offerId || !brandId }">
            <label class="title">{{ $t('androidApp.selectApp') }} </label>
            <v-select
              v-model="app"
              :options="appsOption"
              class="el-input"
              :class="{ disabled: !offerId || !brandId }"
              :placeholder="$t('profile.generalInfo.notSpecified')"
            />
          </div>
        </div>
        <div class="row mt-16">
          <div class="col-12">
            <a
              v-loading="isLoading"
              class="btn btn-primary btn-md w-100 confirm-btn"
              :class="{ disabled: !downloadLink }"
              :href="downloadLink"
              target="_blank"
            >
              <ui-icon
                name="download-icon"
                :size="12"
                class="btn-icon download-icon ui-m-sm-r"
              />
              {{ $t('androidApp.downloadApp') }}
            </a>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>

export default {
  components: {},
  mixins: [],
  data() {
    return {
      brandId: null,
      brands: [],
      app: null,
      apps: [],
      offerId: null,
      isLoading: false,
      trafficSourceId: null,
      subIds: [{ value: null }],
      warningOpen: false,
      downloadLink: '',
      errors_: {},
      oldValue: null,
    };
  },
  computed: {
    trafficSourceList() {
      const data = this.$store.state.trafficSource.list.map(e => ({
        id: e.data.id,
        text: e.name,
      }));
      return [{ id: '', text: this.$t('profile.generalInfo.notSpecified') }].concat(data);
    },
    offersOption() {
      return [
        {
          id: '',
          text: this.$t('promoCodes.addPromocodes.selectOffer'),
        },
        ...this.$store.state.offers.my.map((el) => ({
          id: el.id,
          text: el.name,
        })),
      ]
    },
    appsOption() {
      return [
        {
          id: '',
          text: this.$t('profile.generalInfo.notSpecified'),
        },
        ...this.apps.map((el) => ({
          id: el.id,
          text: el.name,
        })),
      ]
    },
    brandsOption() {
      return [
        {
          id: '',
          text: this.$t('profile.generalInfo.notSpecified'),
        },
        ...this.brands.map((el) => ({
          id: el.site_id,
          text: el.site_name,
        })),
      ]
    }
  },
  mounted() {
    this.$store.dispatch('getAffiliateOffers');
    this.$store.dispatch('getTrafficSources', {});
  },
  watch: {
    $route(to) {
      if (to.name === 'routes.androidApps') {
        this.resetData()
        this.$store.dispatch('getTrafficSources', {});
      } else if (this.warningOpen) {
        this.warningOpen = false;
      }
    },
    brandId(val) {
      this.app = null;
      this.downloadLink = '';
      this.errors_ = {};

      if (val) {
        this.$api.getMobileApps({ affiliate_offer_id: this.offerId, site_id: val }).then((res) => {
          this.apps = res.payload;
        }).catch((e) => {
          this.errors_ = e.response.data.errors
        });
      }
    },
    offerId(val) {
      this.brandId = null;

      if (val) {
        this.$api.getAffiliateOffer(val).then((res) => {
          this.brands = res.payload.visible_brands;
        });
      }
    },
    trafficSourceId() {
      this.app = null;
      this.downloadLink = '';
    },
    subIds: {
      deep: true,
      handler() {
        this.app = null;
        this.downloadLink = '';
      }
    },
    app(id) {
      if (!id) return

      const subIdObj = this.subIds.reduce((acc, { value }, i) => {
        acc[`subid${parseInt(i) + 1}`] = value;
        return acc;
      }, {});

      const app = this.apps.find(el => el.id === id)
      this.isLoading = true;
      this.$api.downloadApp(id, {
        affiliate_offer_id: this.offerId,
        site_id: app.site_id,
        ...(this.trafficSourceId && {
          traffic_source_id: this.trafficSourceId,
        }),
        ...subIdObj,
      })
        .then((res) => this.downloadLink = res.data.payload.url)
        .finally(() => this.isLoading = false);
    },
  },
  methods: {
    resetData() {
      this.brandId = null;
      this.brands = [];
      this.app = null;
      this.apps = [];
      this.offerId = null;
      this.isLoading = false;
      this.downloadLink = '';
      this.subIds = [{ value: null }];
      this.trafficSourceId = '';
      this.warningOpen = false;
      this.errors_ = {};
      this.oldValue = null;
    },
    changeSource(value) {
      if (!value) {
        // if no traffic source selected clear subIds
        this.subIds = [{ value: null }];
        return;
      }
      // subIds from ts
      let source = this.$store.state.trafficSource.list.filter(e => e.data.id === value)[0];
      source = _.pick(source, ['subid1', 'subid2', 'subid3', 'subid4', 'subid5']);

      // subIds from old ts
      let oldSource = this.$store.state.trafficSource.list.filter(e => e.data.id === this.oldValue)[0];
      oldSource = _.pick(oldSource, ['subid1', 'subid2', 'subid3', 'subid4', 'subid5']);

      // take current subIds
      const subIds = {};
      for (let i = 0; i < 5; i++) {
        const key = `subid${i + 1}`;
        subIds[key] = this.subIds[i] ? this.subIds[i].value : null;
      }

      const subIdObj = this.subIds.reduce((acc, { value }, i) => {
        acc[`subid${parseInt(i) + 1}`] = value;
        return acc;
      }, {});

      // if current subIds is equal with subIds from trafficsources
      if (_.isEmpty(oldSource) || _.isEqual(subIds, oldSource)) {
        this.addSubIds(source);
      } else if(!_.isEmpty(oldSource) && !_.isEqual(oldSource, subIdObj)) {
        // show warning
        this.warningOpen = true;
      }
      this.oldValue = value;
    },
    addSubIds(source) {
      if (!source) {
        this.subIds = [{ value: null }];
        return;
      }
      this.subIds = [];
      // Push subIds
      Object.keys(source).map((k) => {
        if (k.startsWith('subid')) {
          if (source[k]) {
            this.subIds.push({
              value: source[k],
            });
          } else {
            this.subIds.push({
              value: null,
            });
          }
        }
      });
      // reduce subIds
      for (let i = this.subIds.length - 1; i >= 1; i--) {
        if (!this.subIds[i].value) this.subIds.pop();
        else break;
      }
    },
    addSubId() {
      this.subIds.push({
        value: null,
      });
    },
    popSubIds() {
      this.subIds.pop();
    },
    confirmChanges() {
      this.subIds = [];
      const source = this.$store.state.trafficSource.list.filter(e => e.data.id === this.trafficSourceId)[0];
      this.addSubIds(source);
    },
  }
};
</script>

<style lang="scss" scoped>
.android-app {
  margin-left: 15px;

  .header {
    font-size: 16px;
    font-weight: 600;
    margin: 0;
  }

  .title {
    text-transform: uppercase;
    margin-bottom: 4px;
    font-size: 13px;
    font-weight: 600;
    color: var(--color-text-primary);
  }

  .el-input {
    /deep/ {
      .select2-container {
        z-index: 9999!important;
      }
    }
  }

  .btn {
    border-radius: 8px !important;

    /deep/ {
      .ui-icon {
        color: var(--color-text-primary) !important;
      }
    }
  }

  .confirm-btn {
    display: flex;
    align-items: center;
    justify-content: center;

    height: 34px;
    font-size: 12px !important;
    font-weight: 600;
    text-transform: capitalize;

    &.disabled {
      filter: grayscale(100%);
    }
  }

  .select-wrapper {
    &.disabled {
      opacity: .5;
      pointer-events: none;
    }
  }

  .row-wrap__subid {
    margin-top: 0;

    .subIdPopover {
      position: relative;
      top: 40px;
    }

    .col-6__add-subid {
      .form-group {
        margin-top: 26px !important;

        .btn-cross {
          top: initial;
          bottom: -3px;
        }
      }
      .btn{
        margin-top: 37px;
      }
      &.disabled {
        opacity: 0.5;
        pointer-events: none;
      }
    }
  }
}
</style>
