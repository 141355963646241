import { debounce } from 'lodash';

export default {
  createTrafficSource({ commit, dispatch }, data) {
    return this.$api.postTrafficSources(data)
      .then(() => {
        dispatch('getTrafficSourceCount');
      });
  },
  deleteTrafficSource({ dispatch }, id) {
    return this.$api.deleteTrafficSource(id)
      .then(() => {
        dispatch('getTrafficSourceCount');
      });
  },
  updateTrafficSource({ dispatch }, payload) {
    return this.$api.putTrafficSource(payload.id, payload)
      .then((data) => {
        dispatch('getTrafficSource', payload.id);
        return data;
      });
  },
  createPostback({ commit }, data) {
    return this.$api.postTrafficSourcePostbacks(data)
      .then(data => data);
  },
  updatePostback({ commit }, data) {
    return this.$api.putTrafficSourcesPostback(data.id, data)
      .then(data => data);
  },
  deletePostback({ commit }, id) {
    return this.$api.deleteTrafficSourcesPostback(id)
      .then(data => data);
  },
  getTrafficSources({ commit }, params) {
    return this.$api.getTrafficSources(params)
      .then((data) => {
        commit('saveTrafficSources', data.payload);
        return data;
      });
  },
  getTrafficSourcePostbacksCount({ commit }, params) {
    return this.$api.getTrafficSourcePostbacksCount(params)
      .then((data) => {
        commit('saveTrafficSourcePostbacksCount', data.payload.count);
        return data;
      });
  },
  getTrafficSourcesLazy: debounce(({ dispatch }, params) => dispatch('getTrafficSources', params), 100),
  getTrafficSourceCount({ commit }) {
    return this.$api.getTrafficSorceCount({})
      .then((data) => {
        commit('saveTrafficSourceCount', data.payload.count);
        return data;
      });
  },
  getSubAffiliateTrafficSources({ commit }, id) {
    return this.$api
      .getTrafficSources({
        sub_affiliate_id: id,
      })
      .then((data) => {
        commit('saveSubAffiliateTrafficSources', data.payload);
        return data;
      });
  },
  getSubAffiliateTrafficSourcesLazy: debounce(({ dispatch }, id) => dispatch('getSubAffiliateTrafficSources', id), 100),
  getTrafficSource({ commit }, id) {
    return this.$api.getTrafficSource(id)
      .then((data) => {
        commit('saveTrafficSource', data.payload);
        return data;
      });
  },
  getTrafficSourceLazy: debounce(({ dispatch }, id) => dispatch('getTrafficSource', id), 100),
  getPostback({ commit }, opts) {
    return this.$api.getTrafficSourcesPostback(opts.id)
      .then((data) => {
        commit('savePostback', data.payload);
        return data;
      });
  },
  getPostbacks({ commit }, options) {
    return this.$api.getTrafficSourcePostbacks(options || {})
      .then((data) => {
        commit('savePostbacks', data.payload);
        return data;
      });
  },
  getTrafficSourcePostbackRequestsLog({ commit }, filters) {
    const id = filters.id;
    return this.$api.getTrafficSourcePostbackRequestsLog(id, filters)
      .then((data) => {
        commit('saveTrafficSourcePostbackRequestsLog', data.data.payload);
        commit('saveTrafficSourcePostbackResponseUrl', data.data.misc.export_urls);
        return data;
      });
  },
  getTrafficSourcePostbackRequestsLogCount({ commit }, filters) {
    const id = filters.id;
    return this.$api.getTrafficSourcePostbackRequestsLogCount(id, filters)
      .then((data) => {
        commit('saveTrafficSourcePostbackRequestsLogCount', data.payload);
        return data;
      });
  },
  getPostbacksLazy: debounce(({ dispatch }, opts) => dispatch('getPostbacks', opts), 100),
  getTagsCount({ commit }, payload) {
    return this.$api.getTagsCount(payload || {})
      .then((data) => {
        commit('saveTagsCount', data.payload);
        return data;
      });
  },
  getTags({ commit }, payload) {
    return this.$api.getTags(payload || {})
      .then((data) => {
        commit('saveTags', data.payload);
        return data;
      });
  },
  createTag({ dispatch }, payload) {
    return this.$api
      .createTag(payload.data)
      .then(result =>
        // dispatch('getTagsCount', payload.passedParams);
        // dispatch('getTags', payload.passedParams);
        result);
  },
  updateTag({ dispatch }, payload) {
    return this.$api
      .updateTag(payload.data)
      .then(result =>
        // dispatch('getTags', payload.passedParams);
        result);
  },
  deleteTag({ dispatch }, payload) {
    return this.$api
      .deleteTag(payload.data)
      .then(result =>
        // dispatch('getTagsCount', payload.passedParams);
        // dispatch('getTags', payload.passedParams);
        result);
  },
  getTag({ commit }, payload) {
    return this.$api.getTag(payload || {})
      .then((data) => {
        commit('saveTag', data.payload);
        return data.payload;
      });
  },
};
