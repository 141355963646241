import eventBus from 'src/lib/eventBus';
import AuthLayout from 'src/views/General/AuthLayout';
import Login from 'src/views/Pages/Login';
import RestorePassword from 'src/views/Pages/RestorePassword';
import RestoreSuccess from 'src/views/Pages/RestoreSuccess';
import ResetPassword from 'src/views/Pages/ResetPassword';
import Registration from 'src/views/Pages/Registration';
import RegConfirm from 'src/views/Pages/RegConfirm';
import BlockedAccount from 'src/views/Pages/BlockedAccount';
import ReviewAccount from 'src/views/Pages/ReviewAccount';
import SecondFactor from 'src/views/Pages/SecondFactor';
import ConnectionLost from 'src/views/Pages/ConnectionLost';
import WrongCredentials from 'src/views/Pages/WrongCredentials';
import UnverifiedAccount from 'src/views/Pages/UnverifiedAccount.vue';
import RejectedAccount from 'src/views/Pages/RejectedAccount.vue';
import OtherAccountSession from 'src/views/Pages/OtherAccount.vue';
import isAuthenticated from './hooks/is_authenticated';
import loginByToken from './hooks/login_by_token';
import not_auth_required from './hooks/not_auth_required';

export default {
  path: '/',
  component: AuthLayout,
  name: 'landing',
  redirect: '/login',
  children: [
    {
      path: '/login',
      name: 'routes.login',
      component: Login,
      beforeEnter: isAuthenticated,
    },
    {
      path: '/login/:token',
      name: 'routes.loginToken',
      beforeEnter: loginByToken,
    },
    {
      path: '/second-factor',
      name: 'routes.2fa',
      component: SecondFactor,
    },
    {
      path: '/restore-password',
      name: 'routes.restorePassword',
      beforeEnter: not_auth_required,
      component: RestorePassword,
    },
    // reg
    {
      path: '/registration',
      name: 'routes.registration',
      beforeEnter: not_auth_required,
      component: Registration,
    },
    {
      path: '/registration-confirm',
      name: 'routes.registrationConfirm',
      beforeEnter: not_auth_required,
      component: RegConfirm,
    },
    {
      path: '/verify-email',
      name: 'routes.verifyEmail',
      component: RegConfirm,
    },
    {
      path: '/verify-additional-email-dms',
      name: 'routes.verifyAdditionalEmail',
      component: RegConfirm,
    },
    {
      name: 'routes.resetPassword',
      path: '/reset-password/:id/:email',
      component: ResetPassword,
    },
    {
      path: '/restore-success',
      name: 'routes.restoreSuccess',
      beforeEnter: isAuthenticated,
      component: RestoreSuccess,
    },
    {
      path: '/second-factor',
      name: 'routes.secondFactor',
      beforeEnter: isAuthenticated,
      component: SecondFactor,
    },
    {
      path: '/blocked-account',
      name: 'routes.blockedAccount',
      component: BlockedAccount,
    },
    {
      path: '/rejected-account',
      name: 'routes.rejectedAccount',
      component: RejectedAccount,
    },
    {
      path: '/unverified-account',
      name: 'routes.unverifiedAccount',
      component: UnverifiedAccount,
    },
    {
      path: '/pending-account',
      name: 'routes.reviewAccount',
      component: ReviewAccount,
    },
    {
      path: '/connection-lost',
      name: 'routes.connectionLost',
      component: ConnectionLost,
    },
    {
      path: '/wrong-credentials',
      name: 'routes.wrongCredentials',
      component: WrongCredentials,
    },
    {
      path: '/register',
      name: 'register',
      beforeEnter: isAuthenticated,
    },
    {
      path: '/logout',
      name: 'logout',
      beforeEnter: (to, from, next) => {
        try {
          eventBus.$emit('logout');
        } catch (e) {
          console.log(e);
        }
        return next('/login');
      },
    },
    {
      path: '/other-account',
      name: 'other-account',
      component: OtherAccountSession,
    },
  ],
};
