<template>
  <section>
    <div class="lang__wrap form-group">
      <div
        v-if="iphone"
        :class="{transLang: transLang}"
        class="lang__container"
      >
        <ul class="lang__list">
          <li
            v-for="language in availableLanguages"
            :key="language.code"
            class="lang__item mt-6 mb-6"
            :class="{active: selectedLanguageCode === language.code}"
            @click="changeLanguage(language.code)"
          >
            <span href="#" class="lang__link">
              {{ language.code }}
            </span>
          </li>
        </ul>
      </div>
      <input
        id="lang"
        class="lang form-control"
        type="text"
      >
      <label
        class="label w-100 mb-0"
        for="lang"
        @click="toggleTransLang"
      >
        <button class="btn btn-primary btn-sm lang__btn text-left w-100 m-0">
          {{$t('media.selectTitle')}}: {{ selectedLanguageCode }}
          <i class="el-icon-arrow-down el-icon--right"/>
        </button>
      </label>
      <div
        v-if="!iphone"
        :class="{transLang: transLang}"
        class="lang__container"
      >
        <ul class="lang__list">
          <li
            v-for="language in availableLanguages"
            :key="language.code"
            class="lang__item mt-6 mb-6 w-100"
            :class="{active: selectedLanguageCode === language.code}"
            @click="changeLanguage(language.code)"
          >
            <span href="#" class="lang__link">
              {{ language.code }}
            </span>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: ['iphone'],
  data() {
    return {
      transLang: true,
      selectedLanguageCode: localStorage.getItem('language') || 'en',
      availableLanguages: [],
    };
  },
  created() {
    this.setAvailableLanguages();
  },
  methods: {
    setAvailableLanguages() {
      this.$store.dispatch('getAvailableLanguages')
        .then((availableLanguages) => {
          this.availableLanguages = availableLanguages;
        });
    },
    changeLanguage(code) {
      this.selectedLanguageCode = code;
      this.$locale = code;
      this.toggleTransLang();
      this.$sidebar.displaySidebar(false);
    },
    toggleTransLang() {
      this.transLang = !this.transLang;
    },
  },
};
</script>

<style lang="scss" scoped>
	.lang {
    display: none;

    &__wrap {
      position: relative;
      margin: 0;
      margin-bottom: -4px !important;

      .el-icon--right {
        float: right !important;
        bottom: 5px;
        left: 10px;
      }

      .transLang {
        display: none;
      }
    }

    &__btn {
      color: #000 !important;
      height: 38px;
    }

    &__container {
      z-index: 10;
      text-align: center;
      padding: 10px;
      background-color: #444444;
      transition: all .3s;
    }

    &__search {
      background-color: #ffffff;
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      justify-content: left;
      list-style-type: none;
      padding: 10px 0 0 0;
    }

    &__item {
      width: 33.33%;
      text-align: center;
    }

    &__link {
      color: #fff;
      text-transform: uppercase;
      cursor: pointer;
    }
  }

  .lang:checked + .label + .lang__container {
    opacity: 1;
  }
</style>
