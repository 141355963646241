<template>
  <card class="notifications" v-loading="isLoading">
    <div class="header">
      <span class="title">{{ $t('notifications.title') }}</span>
      <span v-if="unreadCount > 1" class="mark-all" @click="readAll">{{ $t('notifications.markAllRead')}}</span>
    </div>
    <hr />
    <div v-if="!$store.state.misc.notifications.length" class="no-data">
      <i class="far fa-folder-open" />
      {{ $t('alerts.dataTable.noData') }}
    </div>
    <div v-else class="row" v-for="item in $store.state.misc.notifications">
      <div class="d-flex justify-content-between align-items-center w-100">
        <div class="w-100">
          <div class="ui-align-center">
            <div class="w-100">
              <div class="date" :class="{ attention: !item.is_read }">
                {{ renderDate(item.created_at) }}
              </div>
              <div class="title">
                {{ item.title }}
              </div>
            </div>
            <div v-if="!item.is_read" class="status-read mobile" @click="readNotification(item.id)">
              <i class="fas fa-check"></i>
            </div>
          </div>

          <div class="description">
            {{ item.message }}
          </div>
        </div>
        <div v-if="!item.is_read" class="status-read" @click="readNotification(item.id)">
          <i class="fas fa-check"></i>
        </div>
      </div>
      <hr />
    </div>
  </card>
</template>

<script>
import moment from 'moment';

export default {
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    unreadCount() {
      return this.$store.state.misc.notifications.reduce((acc, item) => {
        if (!item.is_read) {
          acc++;
        }

        return acc;
      }, 0)
    },
  },
  methods: {
    readNotification(id) {
      if (this.isLoading) return;

      this.isLoading = true;
      this.$api.readNotification(id)
        .then(() => this.$store.dispatch('getNotifications', {}))
        .finally(() => this.isLoading = false);
    },
    readAll() {
      if (this.isLoading) return;

      this.isLoading = true;

      return this.$api.readAllNotifications()
        .then(() => this.$store.dispatch('getNotifications', {}))
        .finally(() => this.isLoading = false);
    },
    renderDate(date) {
      if (moment(date).isSame(moment(), 'day')) {
        return `${this.$t('dashboard.today')}, ${moment(date).format('HH:mm')}`;
      }

      if (moment(date).isSame(moment().subtract(1, 'days'), 'day')) {
        return `${this.$t('dashboard.yesterday')}, ${moment(date).format('HH:mm')}`;
      }

      return moment(date).format('DD MMM YYYY, HH:mm');
    },
  }
};
</script>

<style lang="scss" scoped>
.notifications {
  height: 100%;

  .no-data {
    height: calc(100vh - 200px);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 32px;
    color: #a6b2c4;

    @media (max-width: 992px) {
      height: calc(100vh - 140px);
    }

    i {
      margin-right: 8px;
    }
  }

  hr {
    margin: 16px 0 0 0;
  }

  /deep/ {
    .card-body {
      padding: 0;
    }
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0;

    .title {
      font-size: 16px;
      font-weight: 600;
    }

    .mark-all {
      cursor: pointer;
      font-size: 12px;
      font-weight: 600;
      color: var(--color-text-green);
    }

  }

  .row {
    + .row {
      border-top: 1px solid #e7ecf1;
    }

    margin: 0;

    .date {
      color: #7d7d7d;
      font-size: 12px;
      margin-top: 12px;
      position: relative;

      &.attention::before {
        position: absolute;
        left: -12px;
        top: 50%;
        transform: translateY(-50%);
        content: '';
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: var(--color-text-red);
      }
    }
    .title {
      font-size: 13px;
      font-weight: 600;
      color: #2d2d2d;
      margin-top: 4px;
      white-space: pre-line;
    }

    .description {
      font-size: 14px;
      color: #2d2d2d;
      margin-top: 4px;
      margin-bottom: 16px;
      white-space: pre-line;
    }

    .status-read {
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #e7e7e7;
      border-radius: 50%;
      width: 32px;
      min-width: 32px;
      height: 32px;
      cursor: pointer;

      &.mobile {
        display: none;
      }

      @media (max-width: 991px) {
        display: none;

        &.mobile {
          display: flex;
        }
      }

      i {
        color: var(--color-text-green);
      }
    }
  }
}

</style>
