<template>
  <div class="profile-card">
    <h4 class="card-title d-flex justify-content-between">
      <span>
        {{ $t('profile.secondFactor.googleAuthenticator') }}
      </span>
      <el-switch
        active-color="#58b229"
        inactive-color="#ff5c70"
        :value="user.google2fa_enabled"
        @change="toggle"
      />
    </h4>
    <span v-if="!user.google2fa_enabled" class="tip pt-8 pb-16">
      {{ $t('profile.secondFactor.tip') }}
    </span>
    <div class="text-left d-flex">
      <button
        v-if="user.google2fa_enabled"
        class="btn btn-outline-primary btn-sm"
        @click="showBackupCodes"
      >
        {{ $t('profile.secondFactor.backupCodes') }}
      </button>
    </div>
    <enable-second-factor ref="secondfactor"/>
    <show-backup-codes ref="showBackup"/>
    <disable-second-factor ref="disableSecondFactor"/>
  </div>
</template>

<script>
import EnableSecondFactor from './otp/EnableSecondFactor';
import ShowBackupCodes from './otp/ShowBackupCodes';
import DisableSecondFactor from './otp/DisableSecondFactor';

export default {
  components: {
    EnableSecondFactor,
    ShowBackupCodes,
    DisableSecondFactor,
  },
  props: ['user', 'form', 'btnDisable'],
  methods: {
    toggle() {
      if (this.user.google2fa_enabled) {
        this.$refs.disableSecondFactor.open();
      } else {
        this.$refs.secondfactor.enable();
      }
    },
    showBackupCodes() {
      this.$refs.showBackup.open();
    },
    turnOff2FA(pwd, token) {
      this.$store
        .dispatch('otpDisable', {
          token,
          password: pwd,
        })
        .then(() => {
          this.$alert.success(this.$t('profile.secondFactor.googleAuthdisabled'));
        })
        .catch(() => {
          this.$alert.error(this.$t('profile.secondFactor.accessDenied'), () => {
            this.disableSecondFactor();
          });
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.profile-card {
  @media (max-width: 650px) {
    padding: 0!important;
  }
}
</style>
