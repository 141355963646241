import { debounce } from 'lodash';

const STATS_COLS = [
  'visits_count',
  'impressions_count',
  'registrations_count',
  'deposits_all_count',
  'net_profit_sum',
];
const STATS_MINI_COLS = [
  'visits_count',
  'impressions_count',
  'registrations_count',
  'deposits_all_count',
  'net_profit_sum',
];

export default {
  getDashboardEarnings({ commit, state }) {
    state.loading = true;
    return this.$api.getDashboardEarnings({}).then((data) => {
      commit('saveDashboardEarnings', data.payload);
      state.loading = false;
      return data;
    });
  },

  getDashboardEarningsLazy: debounce(
    ({ dispatch }) => dispatch('getDashboardEarnings'),
    100,
  ),

  getReportSummary({ state, commit }, { filters, from, to }) {
    state.loading = true;
    return this.$api
      .postReport({
        from,
        to,
        dimensions: ['date_formatted'],
        sorting: [
          {
            sort_by: 'date_formatted',
            sort_dir: 'desc',
          },
        ],
        filters,
        metrics: state.SUMMARY_COLS,
        limit: 1000,
        offset: 0,
      })
      .then((data) => {
        commit('saveReportSummary', data);
        return data;
      });
  },

  getCampaignSummaryReport({ state, commit }, opts) {
    state.loading = true;
    return this.$api
      .getCampaignSummaryReport({
        ...opts,
        group_by: 'date',
        sort_by: 'date',
        sort_dir: 'desc',
        columns: state.SUMMARY_COLS,
      })
      .then((data) => {
        commit('saveReportSummaryMb', data);
        return data;
      });
  },

  getReportSummaryLazy: debounce(
    ({ dispatch }, opts) => dispatch('getReportSummary', opts),
    100,
  ),

  getReportDashboardSummary({ state, commit }, opts) {
    // console.log(opts);
    return this.$api
      .postReport({
        ...opts,
        dimensions: ['date_formatted'],
      })
      .then((data) => {
        commit('saveReportDashboardSummary', data);
        return data;
      });
  },

  getReportDashboardSummaryLazy: debounce(
    ({ dispatch }, opts) => dispatch('getReportDashboardSummary', opts),
    100,
  ),

  getStatistics({ state, commit }, opts) {
    state.loadingmain = true;
    return this.$api
      .postReport({
        ...opts,
        dimensions: ['date_formatted'],
        metrics: STATS_COLS,
        sorting: [{ sort_by: 'date_formatted', sort_dir: 'desc' }],
        offset: 0,
        limit: 1000,
      })
      .then((data) => {
        commit('saveStatistics', data.payload);
        return data;
      })
      .catch((err) => {
        state.loadingmain = false;
        state.dataerrmain = false;
      });
  },

  // getStatisticsLazy: debounce(({ dispatch }, opts) => dispatch('getStatistics', opts), 100),

  getStatisticsMini({ state, commit }, opts) {
    state.loadingmini = true;
    this.$api
      .postReport({
        ...opts,
        dimensions: ['date_formatted'],
        metrics: STATS_MINI_COLS,
        sorting: [{ sort_by: 'date_formatted', sort_dir: 'desc' }],
        offset: 0,
        limit: 1000,
      })
      .then((data) => {
        commit('saveStatisticsMini', data.payload);
        return data;
      })
      .catch((err) => {
        state.loadingmini = false;
        state.dataerr = false;
      });
  },

  // getStatisticsMiniLazy: debounce(({ dispatch }, opts) => dispatch('getStatisticsMini', opts), 100),

  // report options
  getReportOptions({ commit }, opts) {
    const grouping = [
      'traffic_source_name',
      'country_name',
      'affiliate_offer_name',
      'site_player_id',
    ];
    grouping.forEach((group) => {
      if (opts.except && opts.except === group) return;
      this.$api.getReportOptions({ group_by: group, ...opts }).then((res) => {
        const data = res.payload && res.payload.data ? res.payload.data : [];
        switch (group) {
          case 'traffic_source_name':
            commit('saveReportOptionsTrafficSources', {
              data,
              view: opts.view,
              tab: opts.tab,
            });
            break;
          case 'country_name':
            commit('saveReportOptionsCountries', {
              data,
              view: opts.view,
              tab: opts.tab,
            });
            break;
          case 'affiliate_offer_name':
            commit('saveReportOptionsAffiliateOffers', {
              data,
              view: opts.view,
              tab: opts.tab,
            });
            break;
          case 'site_player_id':
            commit('saveReportOptionsPlayers', {
              data,
              view: opts.view,
              tab: opts.tab,
            });
            break;
        }
      });
    });
  },

  getReportOptionsLazy: debounce(
    ({ dispatch }, opts) => dispatch('getReportOptions', opts),
    100,
  ),

  getReportSubs({ commit }, opts) {
    for (let i = 1; i <= 5; i++) {
      if (opts.except == i) continue;
      this.$api
        .getReportOptions({ ...opts, group_by: `subid${i}` })
        .then((res) => {
          const data = res.payload && res.payload.data ? res.payload.data : [];
          commit('saveReportSubs', {
            n: i,
            data,
            view: opts.view,
            tab: opts.tab,
          });
        });
    }
  },

  getReportSubsLazy: debounce(
    ({ dispatch }, opts) => dispatch('getReportSubs', opts),
    100,
  ),

  // end report options
  getReportSupportedDimmentions({ commit }) {
    return this.$api.getReportSupportedDimentions({}).then((data) => {
      commit('saveReportSupportedDimmentions', data.payload);
      return data;
    });
  },

  getReportSupportedMetrics({ commit }, params = {}) {
    return this.$api.getReportSupportedMetrics(params).then((data) => {
      const payload = data.payload;
      commit('saveReportSupportedMetrics', payload);
      return data;
    });
  },
};
