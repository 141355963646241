import Vue from 'vue';

export default Vue.component('render', {
  props: ['data'],
  data() {
    return {};
  },
  render(h) {
    return this.data;
  },
});
