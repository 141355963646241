export default (mock, types) => {
  mock.onGet('test-unauthorized').reply(401, {
    success: false,
    payload: {},
    code: types.UNAUTHORIZED,
    errors: [
      {},
    ],
  });
};
