import { debounce } from 'lodash';

export default {
  createPromocode({}, data) {
    return this.$api.postPromoCode(data)
      .then(data => data);
  },
  putPromocode(undefined, data) {
    return this.$api.putPromoCode(data.id, data.opts);
  },
  deletePromoCode({}, id) {
    return this.$api.deletePromoCode(id)
      .then(data => data);
  },
  getPromocodes({ commit }, filter) {
    return this.$api.getPromoCode(filter)
      .then((data) => {
        commit('savePromocodes', data.payload);
        commit('savePromocodesTotal', data.misc.count);
        return data;
      });
  },
  getPromocodesLazy: debounce(({ dispatch }, filter) => dispatch('getPromocodes', filter), 100),

  getPromocodesCount({ commit }, filter) {
    return this.$api.getPromoCodeCount({ ...filter })
      .then((data) => {
        commit('savePromocodesCount', data.payload.count);
        return data;
      });
  },
  getPromocodesCountLazy: debounce(({ dispatch }, filter) => dispatch('getApiKeys', filter), 100),

  getPromocodeOffers({ commit }) {
    return this.$api.getAffiliateOffers({})
      .then((data) => {
        commit('savePromocodeOffers', data.payload);
        return data;
      });
  },
  getPromocodeOffersLazy: debounce(({ dispatch }) => dispatch('getPromocodeOffers'), 100),
};
