<template>
  <div class="req-success">
    <modal
      :show.sync="active"
      class="offer__request"
    >
      <template>
        <h5
          slot="header"
          class="modal-title mt-8"
        >
          {{ $t('offer.offerRequestSuccess.title') }}
        </h5>
        <div class="text-center pt-30 pb-30">
          <div class="request-img-wr ">
            <img
              :src="require('@/assets/theme/icon/ok.svg')"
              class="request-img"
              alt="exclamation"
            >
            <br>
            <br>
          </div>
          <br>
          <span>{{ $t('offer.offerRequestSuccess.text') }}.</span>
        </div>
        <div
          slot="footer"
          class="text-center btn-wrap"
        >
          <button
            class="btn btn-primary btn-sm mr-10 mt-8 mb-4 req-btn-c"
            @click="active = false"
          >
            {{ $t('offer.offerRequestSuccess.btn') }}
          </button>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import { Modal } from 'src/components';

export default {
  components: {
    Modal,
  },
  data() {
    return {
      active: false,
    };
  },
  methods: {

    open() {
      this.active = true;
    },
  },
};
</script>

<style lang="scss">
.req-success{
	.modal-dialog{
		max-width: 350px !important;
	}
	.btn-wrap{
		display: flex;
		width: 100%;

		justify-content: center;
	}
	.req-btn-c{
		min-width: 190px !important;
	}
	@media (min-width: 576px){
		.modal-dialog{
			max-width: 350px !important;
		}
	}
}
</style>
