export default {
  data() {
    return {
      idNeedsDeletedMessage: [],
      flagEditComment: false,
      editIndexComment: null,
      editComment: {},
      fileListBackupComment: [],
      fileListDoubleComment: [],
      saveImageDefault: [],
      cloneEditComment: [],
      errorFile: [],
      isDisabledBtnComment: false,
    };
  },

  methods: {
    /* || */
    preloadImage() {
      this.loading = true;
      this.errorFile = [];
      this.isDisabledBtnComment = true;
      this.fileListCommentBack = this.$_.cloneDeep(this.fileListComment);
      this.countFileComment = this.fileListComment.length;
      this.loadImageComment();
    },
    /* || */
    loadImageComment() {
      if (this.flagEditComment) {
        this.requestSendMessage();
        return;
      }
      if (this.countFileComment === 0) {
        this.postCommentsPayment(this.form.id);
        return;
      }
      if (this.countFileComment !== 0) {
        const formData = new FormData();
        const lastFile = this.fileListComment[0];
        formData.append('files', this.fileListComment[0].raw);
        this.$store.dispatch('uploadFiles', formData)
          .then((res) => {
            this.fileListComment.splice(0, 1);
            this.countFileComment -= 1;
            this.formComment.file_ids.push(res.payload.files.id);
            this.loadImageComment();
          })
          .catch((error) => {
            this.isDisabledBtnComment = false;
            this.formComment.file_ids = [];
            this.fileListComment = this.$_.cloneDeep(this.fileListCommentBack);
            this.errorFile.push(`File "${lastFile.raw.name}" is not valid`);
          });
      }
    },
    deleteMessage(comment, index) {
      this.loading = true;
      this.$store.dispatch('deleteComments', comment.id)
        .then((res) => {
          this.getComments();
        });
    },
    editMessage(comment, index) {
      this.flagEditComment = true;
      this.editIndexComment = index;
      this.editComment = comment;
      this.comment = comment.content;

      this.idNeedsDeletedMessage = this.$_.clone(comment.file_ids);
      this.fileListComment = comment.files.map(e => ({
        status: 'ready',
        name: e.filename,
        id: e.id,
      }));
    },

    requestSendMessage() {
      this.fileListBackupComment = this.$_.cloneDeep(this.fileListComment);
      this.fileListDoubleComment = this.$_.cloneDeep(this.fileListComment);
      this.idNeedsDeletedMessage.forEach((e) => {
        const isThereId = this.fileListDoubleComment.findIndex(el => el.id === e);
        if (isThereId !== -1) {
          this.fileListDoubleComment.splice(isThereId, 1);
        } else {
          const indexDeleteFile = this.editComment.file_ids.findIndex(el => el === e);
          this.editComment.file_ids.splice(indexDeleteFile, 1);
        }
      });

      this.cloneEditComment = this.$_.clone(this.editComment.file_ids);
      this.countFileComment = this.fileListDoubleComment.filter(e => !e.hasOwnProperty('id')).length;
      this.loadImageEdit();
    },
    resetEdit() {
      this.flagEditComment = false;
      this.comment = '';
      this.fileListComment = [];
      this.idNeedsDeletedMessage = [];
      this.editComment = {};
      this.editIndexComment = null;
      this.fileListDoubleComment = [];
      this.errorFile = [];
      this.isDisabledBtnComment = false;
    },

    loadImageEdit() {
      this.errorFile = [];
      this.saveImageDefault = this.$_.cloneDeep(this.fileListComment);
      if (this.countFileComment === 0) {
        this.fileIdsBackupComment = this.$_.clone(this.editComment.file_ids);
        this.idNeedsDeletedComment = this.$_.clone(this.editComment.file_ids);
        this.fileListComment = this.$_.cloneDeep(this.fileListDoubleComment);
        const data = {
          id: this.editComment.id,
          content: this.comment,
          file_ids: this.editComment.file_ids,
        };
        this.comment = '';

        this.$store.dispatch('updateComments', data)
          .then((res) => {
            this.resetEdit();
            this.getComments();
          });
        return;
      }
      if (this.countFileComment !== 0) {
        const formData = new FormData();
        const lastFile = this.fileListDoubleComment[0];
        formData.append('files', this.fileListDoubleComment[0].raw);
        this.$store.dispatch('uploadFiles', formData)
          .then((res) => {
            this.fileListDoubleComment.splice(0, 1);
            this.countFileComment -= 1;
            this.editComment.file_ids.push(res.payload.files.id);
            this.loadImageEdit();
          })
          .catch((error) => {
            this.fileListComment = this.saveImageDefault;
            this.fileListDoubleComment = this.saveImageDefault;
            this.editComment.file_ids = this.cloneEditComment;
            this.errorFile.push(`File "${lastFile.raw.name}" is not valid`);
          });
      }
    },
    /* | */
    handleRemoveComment(file, fileList) {
      this.fileListComment = fileList;
      this.countFileComment = fileList.length;
    },
    /* | */
    onSuccessComment(file, fileList) {
      this.fileListComment = fileList;
      this.fileListComment.length > this.$store.state.mediaBuying.uploadSettingsData.max_files_per_request
        ? this.fileListComment.length = this.$store.state.mediaBuying.uploadSettingsData.max_files_per_request
        : this.fileListComment;
      this.countFileComment = this.fileListComment.length;
    },

    hideEditAndDelete(comment) {
      return this.$moment(new Date()).diff(this.$moment(comment.created_at), 'day') < 1;
    },
  },
};
