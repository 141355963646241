<template>
  <section class="index-dashboard index-dashboard-loader">
    <div class="card mb-20">
      <div>
        <skeleton width="120px" height="20px" class="mb-10"/>
        <skeleton width="150px" height="25px"/>
      </div>
      <div>
        <skeleton width="120px" height="20px" class="mb-10"/>
        <skeleton width="150px" height="25px"/>
      </div>
      <div>
        <skeleton width="120px" height="20px" class="mb-10"/>
        <skeleton width="150px" height="25px"/>
      </div>
      <div>
        <skeleton width="120px" height="20px" class="mb-10"/>
        <skeleton width="150px" height="25px"/>
      </div>
      <div>
        <skeleton width="120px" height="20px" class="mb-10"/>
        <skeleton width="150px" height="25px"/>
      </div>
    </div>
    <div class="mb-20">
      <div class="card mr-20">
        <div>
          <skeleton width="190px" height="18px"/>
          <el-divider class="mb-20 mt-20"/>
          <skeleton width="auto" height="auto"/>
        </div>
      </div>
      <div class="card">
        <div>
          <skeleton width="190px" height="18px"/>
          <el-divider class="mb-20 mt-20"/>
          <skeleton width="auto" height="auto"/>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="mb-20">
        <div>
          <div>
            <skeleton height="12px" width="163px" class="mr-12 mt-10 mb-0"/>
          </div>
          <div>
            <skeleton height="34px" width="200px" class="mr-12"/>
          </div>
        </div>
        <div>
          <div>
            <skeleton height="34px" width="80px" class="mr-12"/>
            <skeleton height="34px" width="212px"/>
          </div>
        </div>
      </div>
      <skeleton width="auto" height="auto"/>
    </div>
  </section>
</template>

<script>
import Skeleton from '../../components/Skeleton';

export default {
  components: {
    Skeleton,
  },
};
</script>

<style lang="scss" scoped>
@import 'src/assets/theme/default/dashboard/index';
</style>
