import Vue from 'vue';

function singleton() {
  if (Vue.prototype.$eventBus) return Vue.prototype.$eventBus;

  Vue.prototype.$eventBus = Vue.prototype.$event = new Vue({
    methods: {
      listen(type, cb) {
        this.$on(`state.${type}`, cb);
      },
      clean(type) {
        this.$off(`state.${type}`);
      },
    },
  });
  return Vue.prototype.$eventBus;
}

export default singleton();
