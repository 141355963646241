<template>
  <page-layout>
    <div class="col-md-4 ml-auto mr-auto login-wrapper reset__main not-found pt-30">
      <div class="text-center text-white mt-30">
        <div class="login-logo">
          <img :src="logo" alt="logo">
        </div>
        <h1 class="mb-10">
          {{ error }}
        </h1>
        <h4>{{ $t("notFound.notFoundPage") }}</h4>
      </div>

      <div class="login-logo mt-32">
        <router-link
          :to="{ path: '/login'}"
          class="btn btn-primary btn-lg"
          replace
        >
          {{ $t('forms.btn.login') }}
        </router-link>
      </div>
    </div>
  </page-layout>
</template>

<script>
import PageLayout from './AuthLayout';

export default {
  components: {
    PageLayout,
  },
  created() {
    this.error = this.$route.path.match(/\d/g)?.join('');
  },
  computed: {
    logo() {
      return this.$config.layout.logoFull;
    },
  },
};
</script>

<style lang="scss">
.not-found {
  h1 {
    font-size: 116px;
    font-weight: 700;
    color: #e7e7e7;
    font-family: 'Montserrat' !important;
    margin-top: 70px;
  }
  h4 {
    font-size: 16px;
    font-weight: 600;
  }
}

.not-found-page {
  .full-page > .content,
  .centered {
    min-height: calc(100vh - 160px);
  }
  .not-found-icon {
    margin-right: 10px;
  }
  .centered {
    h1,
    i {
      font-size: 50px;
    }
    p {
      font-size: 20px;
    }
    display: flex;
    padding-bottom: 150px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
  }
}
</style>
