var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-popover',{ref:"isPopoverBook",staticClass:"bookmarks-list",attrs:{"placement":"bottom-end","trigger":"manual","popper-class":"boookmarks"},model:{value:(_vm.isPopoverVis),callback:function ($$v) {_vm.isPopoverVis=$$v},expression:"isPopoverVis"}},[_c('div',{ref:"bookmarkMain",staticClass:"tabs-right-btn__bookmarks",class:{
      'bookmark-title-disabled': _vm.isBookmarkTitleDisabled
    },attrs:{"slot":"reference","filled":_vm.isReportingSettings},on:{"click":function($event){return _vm.handleShowPopover($event)}},slot:"reference"},[_c('ui-icon',{staticClass:"tab-action",attrs:{"name":'star-filled',"size":13}}),_c('ui-icon',{staticClass:"tab-action tabs-right-btn__chevron",class:{
        'tabs-right-btn__chevron--open': _vm.isPopoverVis
      },attrs:{"name":'left-chevron',"color":`#303634`,"size":8}})],1),_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.isLoadingBookmark),expression:"isLoadingBookmark"}]},[_c('div',{staticClass:"search-input-popover__wrap"},[_c('ui-input',{staticClass:"search-input-popover",attrs:{"is-search":"","placeholder":_vm.$t('customReports.search')},on:{"search":_vm.searchBookmark,"clear":_vm.handleClear}})],1),_c('div',{staticClass:"list-popover__wrap"},_vm._l((_vm.bookmarks),function(item,key){return _c('div',{key:item.key,staticClass:"select-row-popover",class:{
          'popover-open': _vm.isPopoverEditIndex === key
        }},[_c('div',{staticClass:"select-row-popover__wrap"},[_c('span',{staticClass:"select-row-popover__title",on:{"click":function($event){$event.stopPropagation();return _vm.handlePushBookmark(key)}}},[_vm._v(" "+_vm._s(item.bookmarkName)+" ")]),_c('div',{staticClass:"select-row-popover__wrap-inn"},[_c('span',{staticClass:"select-row-popover__edit",class:{
                'select-row-popover__edit--active': _vm.isPopoverEdit[key]
              },attrs:{"slot":"reference"},on:{"click":function($event){$event.stopPropagation();return _vm.handleEditBookmarkName(key, item.bookmarkName, false)}},slot:"reference"},[_vm._v(_vm._s(_vm.$t('profile.buttons.edit')))]),_c('span',{staticClass:"select-row-popover__delete",class:{
                'select-row-popover__delete--active': _vm.isPopoverEdit[key]
              },on:{"click":function($event){$event.stopPropagation();return _vm.handleEditBookmarkName(key, item.bookmarkName, true)}}},[_vm._v(_vm._s(_vm.$t('profile.apiKeys.table.delete')))])])]),_c('div',{staticClass:"hide-edit__bookbarks",class:{
            'open-edit__bookbarks': _vm.isPopoverEditExpand && _vm.isPopoverEditIndex == key
          }},[(!_vm.openPopupDelete)?_c('ui-input',{staticClass:"boookmark-edit__input",attrs:{"placeholder":"","label":_vm.$t('customReports.EditBookmark')},model:{value:(_vm.constBookmarkName),callback:function ($$v) {_vm.constBookmarkName=$$v},expression:"constBookmarkName"}}):_vm._e(),(_vm.openPopupDelete)?_c('div',{staticClass:"delete-text mb-4"},[_vm._v(" "+_vm._s(_vm.$t('customReports.sureWeleteBookmark'))+" ")]):_vm._e(),_c('div',{staticClass:"boookmark-edit__wrap-btn"},[_c('button',{staticClass:"btn btn-outline-primary btn-sm boookmark-edit__close mr-8",on:{"click":function($event){$event.stopPropagation();return _vm.handleModalCancel.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t('profile.buttons.cancel'))+" ")]),(!_vm.openPopupDelete)?_c('button',{staticClass:"btn btn-primary btn-sm boookmark-edit__success",on:{"click":function($event){$event.stopPropagation();return _vm.handleModalSave(key)}}},[_vm._v(" "+_vm._s(_vm.$t('profile.buttons.save'))+" ")]):_vm._e(),(_vm.openPopupDelete)?_c('button',{staticClass:"btn btn-danger btn-sm boookmark-edit__success",on:{"click":function($event){$event.stopPropagation();return _vm.handleModalDelete(key)}}},[_vm._v(" "+_vm._s(_vm.$t('profile.apiKeys.table.delete'))+" ")]):_vm._e()])],1)])}),0),(_vm.notFoundBookmark)?_c('div',[_c('span',{staticClass:"notFoundBookmark"},[_vm._v(_vm._s(_vm.$t('customReports.noBbookmarksFound')))])]):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }