<template>
  <v-selectmenu
    ref="selectmenu"
    :class="{'disabled': disabled}"
    :key="key"
    :data="list"
    :title="title"
    :regular="regular"
    :multiple="multiple"
    :position="position"
    :query="query"
    :key-field="pKeyField"
    show-field="text"
    v-model="pValue"
    language="en"
    @values="values"
    @show="show"
    @hide="hide"
  >
    <label v-if="!notLabel" v-show="!multiple">{{ label || 'Label' }}</label>
    <button
      v-if="!multiple"
      type="button"
      class="sm-default-btn"
      :class="{'showDropdown': showDropdown}"
    >
      <span class="sm-default-btn-span" v-if="!notLabel">
        {{ (selectValue !== 'incrementing_id' ? valueText : 'ID') || value || $t('profile.generalInfo.notSpecified') }}
      </span>
      <span class="sm-default-btn-span" v-if="notLabel">
        <span class="fw-600">{{label}} </span>
        <span>{{ (selectValue !== 'incrementing_id' ? valueText : 'ID') || $t('profile.generalInfo.notSpecified') }}</span>
      </span>
      <span class="sm-caret-down" />
    </button>
    <ui-button v-if="multiple" class="btn" icon="eye" />
  </v-selectmenu>
</template>

<script>
import Vue from 'vue';
import vSelectMenu from 'v-selectmenu';

Vue.use(vSelectMenu);

export default {
  components: {
    vSelectMenu,
  },
  props: ['title', 'label', 'value', 'list', 'multiple', 'position', 'disabled', 'keyField', 'regular', 'notLabel', 'query', 'i18n-path', 'static-key', 'alt-value-text'],
  data() {
    return {
      changed: 0,
      selectValue: this.value,
      selectList: this.list,
      showDropdown: false,
      pValue: '',
      locale: '',
    };
  },
  computed: {
    key() {
      return this.staticKey
        ? 'static-key'
        : this.changed;
    },
    pKeyField() {
      return this.keyField || 'name';
    },
    // проверить что ничего не поломалось и убрать prop: altValueText
    valueText() {
      if (this.altValueText && !this.multiple) {
        const item = this.selectList
          .map(e => e.list)
          .reduce((a, b) => a.concat(b), [])
          .find(e => e[this.pKeyField] === this.value);

        return item
          ? item.text || item.name || item.label
          : this.selectValue;
      }

      return this.selectValue;
    },
  },
  watch: {
    list(value) {
      this.changed++;
      this.selectList = value;
      this.init();
      this.initComponent(value);
    },
    selectValue() {
      if (this.multiple) {
        this.pValue = this.value.join(',');
      } else {
        this.pValue = this.selectValue;
      }
    },
    locale() {
      if (!this.multiple) {
        this.selectList.forEach((e) => {
          if (e.list) {
            e.list.find((i) => {
              if (this.selectValue === i.id) { this.selectValue = i.text; }
            });
          }
        });
      }
    },
  },
  destroyed() {
    document.removeEventListener('mousedown', this.eventSelects);
  },
  mounted() {
    this.init();
    document.addEventListener('mousedown', this.eventSelects);
  },
  updated() {
    this.showDropdown ? document.body.setAttribute('data-overflow', 'hidden')
      : document.body.removeAttribute('data-overflow', 'hidden');
    this.locale = this.$locale;
  },
  methods: {
    initComponent(list) {
      const selectmenu = this.$refs.selectmenu;
      if (selectmenu) {
        const sample = list[0];
        const isGroup = !!(sample && sample.title && sample.list);
        const flatList = this.list
          .map(item => item.list)
          .reduce((a, b) => a.concat(b), []);

        try {
          selectmenu.searchList();

          const sampleRefItem = selectmenu.results && selectmenu.results[0];
          const hasResultRef = selectmenu.results && selectmenu.results.some(item => item.id === this.value);
          const hasResult = flatList.some(item => item.id === this.value);

          if (isGroup !== selectmenu.state.group || !sampleRefItem || !sampleRefItem.name || !hasResult || !hasResultRef) {
            selectmenu.checkDataType();
            this.$set(selectmenu, 'tabIndex', 0);
            this.$set(selectmenu, 'results', list[0].list.concat());
          }
        } catch (e) {
          console.error(e);
        }
      }
    },
    init() {
      if (!this.multiple) {
        this.changed++;
        if (!this.value) {
          this.selectList.find((e) => {
            if (e.id === '') this.selectValue = e.text;
          });
        }

        const el = this.selectList
          .map(e => e.list)
          .reduce((a, b) => a.concat(b), [])
          .find(e => e[this.pKeyField] === this.value);

        if (el) {
          this.selectValue = el.text || el.name || el.label;
          this.pValue = this.value;
          this.$forceUpdate();
        }
      }

      if (this.multiple) {
        this.pValue = this.value.join(',');
        this.selectList.forEach(e => (e.text = this.$t(`customReports.${e.name}`)));
      }
    },
    values(arr) {
      if (!this.multiple) {
        arr.forEach((e) => {
          this.selectValue = e.name || e.text;
          this.$emit('change', e);
          this.$forceUpdate();
        });
      } else {
        const val = [];
        arr.forEach(e => val.push(e.name));
        this.$emit('change', val);
      }
    },
    show() {
      try {
        this.showDropdown = true;
        const selectmenu = document.getElementsByClassName('sm-default-btn')[0];
        const dropdown = document.getElementsByClassName('v-dropdown-container')[0];
        dropdown.style.width = `${selectmenu.offsetWidth}px`;
      } catch (e) {
      }
    },
    hide() {
      this.showDropdown = false;
    },
    eventSelects(e) {
      $('.add-placeholder')
        .remove();

      if (e.target.classList.contains('sm-default-btn') || e.target.classList.contains('sm-default-btn-span')) {
        const input = document.querySelector('.sm-input');
        const inputWrap = document.querySelector('.sm-input-area');
        const placeholder = document.createElement('span');
        placeholder.innerHTML = this.$t.bind(this)('customReports.search');
        placeholder.className = 'add-placeholder';

        if (input) {
          if (!input.value) {
            setTimeout(() => {
              inputWrap.appendChild(placeholder);
            }, 0);
          }
          $('.sm-input')
            .on('input', () => {
              if (input.value && placeholder) {
                try {
                  inputWrap.removeChild(placeholder);
                } catch (err) {
                  console.log(err);
                }
              } else {
                inputWrap.appendChild(placeholder);
              }
            });
        }
      }
    },
  },
};
</script>

<style lang="scss">
.v-selectmenu {
  width: 100%;

  .caller-container {
    width: 100%;
  }

  label {
    display: block;
    font-size: 13px !important;
    color: #303634 !important;
    margin: 2px 0 2px;
    text-transform: uppercase;
    font-weight: 600;
  }

  .sm-default-btn {
    line-height: 18px;
    margin-top: -1px;
    border-radius: 8px;
    height: 34px !important;
    border: 1px solid #d3d3d3 !important;
    background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.06)), to(rgba(255, 255, 255, 0.06)));
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%);
    background-color: #ffffff;
    color: #303634;
    padding: 4px 16px;
    min-width: 130px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 13px;

    &:hover {
      color: #303634;
      background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.06)), to(rgba(255, 255, 255, 0.06)));
      background-image: linear-gradient(to top, rgba(0, 0, 0, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%);
      background-color: #ffffff;
      cursor: pointer;
    }

    > span {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .fw-600 {
      font-weight: 600;
    }

    &.showDropdown {
      border-radius: 8px 8px 0 0;
    }
  }

  .sm-caret-down {
    border-color: #909090;
    border-left: 5px solid transparent;
    border-right: 4px solid transparent;
  }

  &.disabled {
    pointer-events: none !important;
  }
}

.v-dropdown-container {
  transition: initial;
  max-width: 100%;

  &:before {
    content: "";
    display: block;
    border-left: 1px solid #d6d7d7;
    border-right: 1px solid #d6d7d7;
    width: calc(100% + 2px);
    height: 6px;
    background: #fff;
    position: absolute;
    left: -1px;
    top: -6px;
  }

  .sm-header {
    display: none;
  }

  .sm-input-area {
    padding: 0 4px 4px;
    background-color: #fff;
    position: relative;
    top: -2px;

    input {
      border: 1px solid #d9d9d9;
      border-radius: 8px;
      box-shadow: none;
      padding: 4px 12px;
      width: 100%;
      height: 31px;
      transition: none;

      &:focus {
        outline: none;
        box-shadow: none;
      }
    }
  }

  .sm-result-area {
    border-top: 0 !important;
    position: relative;
    top: -2px;

    .sm-results > li {
      min-width: 120px;
      padding: 4px 16px 4px 16px;

      .sm-item-text {
        margin: 0;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 12px;
      }
    }

    .sm-selected {
      background-color: rgba(255, 206, 6, 0.2);
      display: flex;
      justify-content: space-between;
      flex-direction: row-reverse;

      .sm-item-text {
        width: 100%;
        font-weight: normal;
      }

      .icon-selected:before {
        content: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAALCAMAAAB8rpxtAAAAAXNSR0IB2cksfwAAADlQTFRFAAAAMDg0MDU0MDUzMDY0MDU1MDAwMDY0MDY0MDg4MDU1MDY0MDY0MDY1MDYzMDYzMDYzMDY0MDY0MTa4/QAAABN0Uk5TAEC/kPAwEM//IGB/76DfUNCA4BJJM/4AAABHSURBVHicRY1bEoAwCAPRWro++tD7H1Ycp8APm0wIIj7LGpy2PFELznvhsJVPNb5In1dp3TLjT1Ro+IEpbi8aPCqheryT6b9ttwGwXpFrrwAAAABJRU5ErkJggg==');
      }
    }
  }

  ul.sm-results > li.sm-over {
    background-color: var(--color-bg-select-hover) !important;
    color: #303634 !important;
  }

  @media (max-width: 650px) {
    right: 50px !important;
    left: 16px !important;
  }
}
</style>
