<template>
  <label
    :class="[
      size,
      {'search': config.isSearch},
      {'borderless': config.borderless},
      {'disabled': config.disabled},
      {'required': config.required},
      {'clearable': config.clearable},
      {'focus': focused},
      {'filled': !!currentValue},
      {'error': currentError},
      {'with-icon': config.preIcon || config.postIcon},
    ]"
    :style="{ width: getInputWidth }"
    class="ui-input"
    @mouseenter="hovered = true"
    @mouseleave="hovered = false"
  >
    <span
      v-if="config.label"
      class="label-text"
    >{{ config.label }}</span>
    <div class="input-wrapper">
      <ui-icon
        v-if="config.preIcon"
        :name="config.preIcon"
        :size="getIconSize"
        :color="config.isSearch ? '#747474' : ''"
        class="input-pre-icon"
      />
      <input
        ref="input"
        :tabindex="config.tabindex"
        :placeholder="config.placeholder"
        :readonly="config.readonly"
        :type="config.type || 'text'"
        :disabled="config.disabled"
        :value="currentValue"
        class="input-element"
        @input="handleInput"
        @focus="handleFocus"
        @blur="handleBlur"
        @change="handleChange"
        @keyup="keyup"
      >
      <div
        v-if="config.clearable"
        :class="{'show-clear': showClear}"
        class="input-post-icon clear-btn"
        @click="clear"
      >
        <span>×</span>
      </div>
      <ui-icon
        v-else-if="config.postIcon"
        :name="config.postIcon"
        :size="getIconSize"
        class="input-post-icon"
      />
    </div>
    <span
      v-if="currentError"
      class="input-error"
    >{{ $t(`validation.errors.${currentError}`) }}</span>
  </label>
</template>

<script>
export default {
  name: 'UiInput',
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    isSearch: {
      type: Boolean,
      default: false,
    },
    searchTimer: {
      type: Number,
      default: 300,
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    error: {
      type: [String, Number, Array],
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    required: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: 'medium',
    },
    width: {
      type: [String, Number],
      default: 0,
    },
    autosize: {
      type: Boolean,
      default: false,
    },
    preIcon: {
      type: String,
      default: '',
    },
    postIcon: {
      type: String,
      default: '',
    },
    tabindex: {
      type: Number,
      default: 1,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    borderless: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      focused: false,
      hovered: false,
      currentValue: '',
      currentError: null,
      config: {},
    };
  },
  computed: {
    getIconSize() {
      if (this.config.isSearch) {
        return 16;
      }
      switch (this.config.size) {
        case 'medium':
          return 12;
        case 'big':
          return 16;
        default:
          return 12;
      }
    },
    getInputWidth() {
      if (this.config.autosize) {
        return '100%';
      }
      if (this.config.width) {
        return this.$_.isNumber(this.config.width)
          ? `${this.config.width}px`
          : this.config.width;
      }
      switch (this.config.size) {
        case 'medium':
          return '180px';
        case 'big':
          return '180px';
        default:
          return '180px';
      }
    },
    showClear() {
      return (
        this.config.clearable
        && !this.config.disabled
        && !this.config.readonly
        && this.currentValue !== ''
        && (this.focused || this.hovered)
      );
    },
  },
  watch: {
    value: {
      handler(nv, ov) {
        if (nv !== ov) {
          this.currentValue = nv === undefined || nv === null ? '' : nv.toString();
        }
      },
      deep: true,
    },
    error(nv, ov) {
      if (nv !== ov) {
        this.currentError = this.$_.isArray(nv) ? nv[0].code : nv;
      }
    },
    _props: {
      deep: true,
      immediate: true,
      handler() {
        this.initConfig();
      },
    },
  },
  created() {
    this.currentValue = this.value === undefined || this.value === null
      ? ''
      : this.value.toString();
    this.currentError = this.$_.isArray(this.error)
      ? this.error[0].code
      : this.error;
  },
  methods: {
    initConfig() {
      this.config = this.$_.cloneDeep(this._props);
      if (this.config.isSearch) {
        if (!this.debouncedSearch) {
          this.debouncedSearch = this.$_.debounce(() => {
            this.$emit('search', this.currentValue);
          }, this.config.searchTimer);
        }
        if (!this.config.placeholder) {
          this.config.placeholder = 'Search...';
        }
        this.config.preIcon = 'search';
        this.config.size = 'big';
        this.config.borderless = true;
        this.config.clearable = true;
      }
      this.$emit('init');
    },
    clear() {
      this.$emit('input', '');
      this.$emit('change', '');
      this.$emit('clear');
      this.currentValue = '';
      this.focus();
    },
    handleInput(event) {
      const { value } = event.target;
      this.currentValue = value;
      this.$emit('input', value);
    },
    handleChange(event) {
      const { value } = event.target;
      this.$emit('change', value);
    },
    handleFocus(event) {
      this.focused = true;
      this.$emit('focus', event);
    },
    handleBlur(event) {
      this.focused = false;
      if (this.required && !event.target.value) {
        this.currentError = 1000;
        this.$emit('error', this.currentError);
      }
      this.$emit('blur', event);
    },
    focus() {
      this.$refs.input.focus();
    },
    blur() {
      this.$refs.input.blur();
    },
    select() {
      this.$refs.input.select();
    },
    keyup(ev) {
      if (ev.key === 'Enter' && this.isSearch) this.debouncedSearch();
    },
  },
};
</script>

<style lang="scss" scoped>
  @import 'src/assets/theme/default/components/UiInput';
</style>
