<template>
  <div class="testbox card bg-dark">
    <p
      class="text-white"
      @click="foldBox = !foldBox"
    >
      :: TESTBOX
    </p>
    <hr :class="{'fold-box__none': foldBox }">
    <div :class="{'fold-box__none': foldBox}">
      <a
        v-for="i in commits"
        class="btn btn-danger btn-block"
        href="#"
        @click.prevent="$store.commit(i.commit)"
      >:: {{ i.text }}</a>
      <hr>
      <a
        v-for="i in buttons"
        class="btn btn-danger btn-block"
        href="#"
        @click.prevent="$request[i.type || 'post' ](i.url)"
      >:: {{ i.text }}</a>
      <hr>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      foldBox: false,
      actions: [],
      commits: [
        { text: 'Get Wallet', commit: 'getWallets' },
        { text: 'Get Promocodes', commit: 'getPromocodes' },
        { text: 'Get Wallet History', commit: 'getWalletHistory' },
        { text: 'Get Subaffiliates', commit: 'getSubAffiliates' },
        { text: 'Get Offers', commit: 'getOffers' },
        { text: 'Get Media', commit: 'getMediaContents' },
        { text: 'Get Traffic Sources', commit: 'getTrafficSources' },
      ],
      buttons: [
        { text: 'Reg Pending', url: 'test-pending' },
        { text: 'Acc Blocked', url: 'test-blocked' },
        { text: 'Unauthorized', url: 'test-unauthorized' },
        { text: '2FA Unathorized', url: 'test-2fa-fail' },
        { text: '500', url: 'test-fail', type: 'get' },
        { text: '404', url: 'abracadabra', type: 'get' },
      ],
    };
  },
  methods: {
    ping(url) {
      console.log(url);
    },
  },
};
</script>

<style lang="scss" scoped>
.testbox {
  position: fixed;
  z-index: 10000000000000;
  width: 260px;
  max-height: 100%;
  bottom: 0px;
  right: 0px !important;
  overflow-y: auto;
  .text-white:hover {
    cursor: pointer;
  }
  .fold-box__none {
    display: none;
  }
}
</style>
