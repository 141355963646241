/**
 * @argument viewName <String|Number|null>
 * @argument _default <Number>
 * @argument sizes <Map>
 */
export const resolvePageSize = (viewName = 'viewName', { _default, sizes }) => {
  const manual = window.localStorage.getItem(viewName);

  if (manual && manual !== 'null' && manual !== 'undefined') {
    return parseInt(manual);
  }

  const screenHeight = window.screen.height;

  if (sizes[screenHeight]) {
    return sizes[screenHeight];
  }

  if (screenHeight >= 1080 && screenHeight <= 1200) {
    return sizes[1080];
  }

  if (screenHeight > 1200 && screenHeight < 4000) {
    return sizes[1440];
  }

  return _default;
};
export const pageSizeMixin = {
  updated() {
    const { pageLimitProp = 'limit', viewName = 'viewName' } = this;
    window.localStorage.setItem(viewName, this[pageLimitProp]);
  },
};
