<template>
  <div class="modal__complete-body">
    <div>
      <img :src="require('@/assets/theme/icon/ok.svg')" alt="">
    </div>
    <p class="success__message">
      {{ message || 'Success' }}
    </p>
  </div>
</template>

<script>

export default {
  props: ['message'],
};

</script>

<style lang="scss" scoped>
  .modal__complete-body{
    padding-left: 40px;
    padding-right: 40px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    width:300px;
    text-align: center;
    .success__message{
      text-align: center;
      margin-top: 20px;
    }
  }
</style>
