export default (mock, types) => {
  mock.onGet('test-pending').reply(421, {
    success: false,
    payload: {},
    code: types.AUTH_ACCOUNT_PENDING,
    errors: [
      {},
    ],
  });
};
