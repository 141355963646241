<template>
  <div class="col-md-5 ml-auto mr-auto login-wrapper second-factor">
    <div class="login-logo">
      <img :src="logo" alt="logo" />
    </div>

    <form>
      <card class="card-login">
        <h3>{{ $t('forms.secondFactor.secondFactorTitle') }}</h3>
        <div class="inputs">
          <div class="title-input">
            {{ formInfo.title }}
          </div>
          <CodeInput
            ref="codeinput"
            :key="formInfo.typeInput"
            :type="formInfo.typeInput"
            :fields="formInfo.typeInput === 'text' ? 8 : 6"
            :fieldWidth="formInfo.typeInput === 'text' ? 35 : 46.6"
            :fieldHeight="formInfo.typeInput === 'text' ? 34 : 34"
            class="input w-100"
            @change="validation = false"
            @complete="verify"
          />

          <div class="text-danger text-right mt-8">
            <span v-show="validation">{{ $t(`forms.validation.${formInfo.typeInput === 'text' ? 'bcfa' : 'sfa'}`) }}</span>
          </div>
        </div>
        <span class="backup-switch" @click="handlerType">{{ formInfo.switchLink }}</span>
        <div class="d-flex pb-header justify-content-between align-items-center">
          <button @click.prevent="$router.push('/logout')" class="btn btn-primary btn-sm w-100">
            &lt; {{ $t('customReports.back') }}
          </button>
        </div>
        <a class="d-block text-center trouble-get" :href="contactsUrl" target="_blank"> {{ $t('forms.secondFactor.secondFactorText') }}? </a>
      </card>
    </form>
  </div>
</template>

<script>
import CodeInput from 'vue-verification-code-input';
import mixinsPasteCodeInput from '@/helpers/mixinsPasteCodeInput';

export default {
  mixins: [mixinsPasteCodeInput],
  components: {
    CodeInput,
  },
  data() {
    return {
      validation: false,
      token: '',
      typeCode: 'ga',
    };
  },

  mounted() {
    document.getElementsByClassName('react-code-input')[0].children[0].focus();
  },

  computed: {
    formInfo() {
      if (this.typeCode === 'ga') {
        return {
          title: this.$t('profile.disableSecondFactor.token'),
          switchLink: this.$t('profile.secondFactor.enter_bc'),
          typeInput: 'number',
        };
      }
      return {
        title: this.$t('profile.secondFactor.bc'),
        switchLink: this.$t('profile.secondFactor.ecfa'),
        typeInput: 'text',
      };
    },

    logo() {
      return this.$config.layout.logoFull;
    },
    contactsUrl() {
      return `https://${this.$config.frontPageUrl}/contact-us`;
    },
  },
  methods: {
    handlerType() {
      this.validation = false;
      this.typeCode = this.typeCode === 'ga' ? 'bc' : 'ga';
      setTimeout(() => document.getElementsByClassName('react-code-input')[0].children[0].focus());
    },

    verify(value) {
      this.token = value;
      this.$request
        .post('auth/totp', { token: this.token, trust: true })
        .then((res) => {
          if (!res.data.payload.result) {
            this.validation = true;
          } else {
            this.$router.push(this.$config.mainScreen);
            this.$store.dispatch('loginSuccess');
            this.$eventBus.$emit('login');
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss">
.second-factor {
  .title-input {
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
  }
  .backup-switch {
    display: inline-block;
    border-bottom: 1px dashed var(--color-text-green);
    color: var(--color-text-green);
    margin-bottom: 16px;
    font-size: 14px;
    cursor: pointer;
  }
  .login-page-link {
    font-size: 16px !important;
    &:hover {
      text-decoration: underline !important;
      cursor: pointer;
    }
  }
  .inputs {
    margin-bottom: 16px !important;
    margin-top: 24px;
    .input {
      display: flex;
      align-items: center;
      .react-code-input {
        input {
          // width: 100% !important;
          justify-content: flex-start !important;
          border: 1px solid #d9d9d9 !important;
          caret-color: #000;
          font-size: 26px;
          border-right: 0px !important;
          font-family: 'Open Sans';
          color: #303634;
          &:first-child {
            border-radius: 8px 0 0 8px !important;
          }
          &:last-child {
            border-radius: 0 8px 8px 0 !important;
            border-right: 1px solid #d9d9d9 !important;
          }
          &:focus {
            border: 1px solid var(--active-color) !important;
            border-right: 0px !important;
            &:last-child {
              border-right: 1px solid var(--active-color) !important;
            }
            & + input {
              border-left: 1px solid var(--active-color) !important;
            }
          }
        }
      }
    }
  }
  .text-danger {
    margin-top: 8px !important;
  }
  .btn {
    margin: 0px 1px 7px !important;
    padding: 13px 13px 14px !important;
  }
  .btn-outline-primary {
    width: 100px;
  }
  .btn-primary {
    width: 164px;
  }
  .trouble-get {
    color: #408ce4;
    font-size: 12px !important;
    font-weight: 600;
  }

  @media (max-width: 460px) {
    margin-top: 4vh !important;
    min-width: 290px !important;
    form {
      position: absolute;
      width: calc(100% + 52px);
      left: 0;
      top: 80px;
      margin-left: -26px;
    }
    .card-login {
      height: 1000px;
      border-radius: 0 !important;
      padding: 16px 35px !important;
    }
    .btn-box {
      .btn-outline-primary {
        min-width: 100px;
      }
      .btn-primary {
        width: 100%;
        margin-left: 10px !important;
      }
    }
  }
  @media (max-width: 760px) {
    .inputs {
      .input {
        width: 100% !important;
        justify-content: flex-start !important;
        align-items: flex-start;
        .react-code-input {
          input {
            font-size: 18px;
          }
        }
      }
    }
  }
}
</style>
