<template>
  <div>
    <textarea
      ref="text"
      class="copytext__area"
      autocomplete="off"
      autocorrect="off"
      autocapitalize="off"
      :readonly="true"
      spellcheck="false"
      @keydown.prevent
      v-model="data"
    />
    <el-tooltip
      :content="success"
      :manual="true"
      :value="done"
      :hide-after="1000"
      effect="light"
      placement="right"
    >
      <a
        v-if="button"
        :class="{ 'disabled': disabled }"
        class="btn btn-primary btn-sm btn-copy mr-8 mt-4 mb-4"
        @click="copy"
      >
        <span><img
          :src="require('@/assets/theme/icon/mono-copy.svg')"
          alt="copy"
        >{{ button }}</span>
      </a>
    </el-tooltip>
    <slot name="btnRight"/>
  </div>
</template>

<script>
export default {
  name: 'Copytext',
  props: ['button', 'success', 'text', 'disabled'],
  data() {
    return {
      done: false,
    };
  },
  computed: {
    data() {
      if (Array.isArray(this.text)) return this.text.join();
      return this.text;
    },
  },
  methods: {
    copy() {
      this.done = false;

      if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
        const el = $(this.$refs.text)
          .get(0);
        const editable = el.contentEditable;
        const readOnly = el.readOnly;
        el.contentEditable = true;
        el.readOnly = false;
        const range = document.createRange();
        range.selectNodeContents(el);
        const sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
        el.setSelectionRange(0, 999999);
        el.contentEditable = editable;
        el.readOnly = readOnly;
      } else {
        this.$refs.text.select();
      }
      this.done = true;
      document.execCommand('copy');
      setTimeout(() => {
        this.done = false;
      }, 1400);
    },
  },
};
</script>

<style lang="scss">
.copytext {
  &__area {
    border: 2px dashed var(--main-color) !important;
    resize: none;
    width: 100%;
    height: 100px;
    padding: 16px;

    &:focus {
      outline: none;
    }
  }

  &__btn {
    background: #fff;
    border: 2px solid #ffce06;
    text-transform: uppercase;
    font-weight: 700 !important;
    font-size: 12px !important;
    padding: 11px 22px !important;
  }

  &__done {
    position: relative;
    left: -6px;
  }
}

.perfect-scrollbar-off.modal-open {
  > .el-tooltip__popper {
    z-index: 123124343322 !important;
  }
}
</style>
