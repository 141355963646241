<template>
  <div class="bookmarks-modal">
    <modal
      :show="activeModal"
      @close="activeModal = false"
    >
      <h5 slot="header" class="modal-title">
        {{$t('customReports.addBookmark')}}
      </h5>
      <template>
        <div class="">
          <ui-input
            v-model="constTabBookmarkName"
            :label="$t('customReports.addBookmark')"
            class="boookmark-edit__input"
          />
        </div>
      </template>
      <div slot="footer" class="">
        <div class="boookmark-edit__wrap-btn">
          <button
            class="btn btn-outline-primary btn-sm boookmark-edit__close"
            @click="activeModal = false"
          >
            {{ $t('profile.buttons.cancel') }}
          </button>
          <button
            class="btn btn-primary btn-sm boookmark-edit__success ml-8"
            @click="handleSave"
          >
            {{ $t('profile.buttons.save') }}
          </button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { Modal } from 'src/components';
import BookmarksMixin from './bookmarks-mixin.js';

export default {
  components: {
    Modal,
  },
  mixins: [
    BookmarksMixin,
  ],
  data() {
    return {
      activeModalIndex: '',
      activeModal: false,
      constTabBookmarkName: '',
    };
  },
  methods: {
    open(index, event) {
      this.activeModalIndex = '';
      this.activeModal = true;
      this.setBookmarkName(index, event);
    },
    handleSave() {
      this.saveTabBookmark(this.activeModalIndex);
      this.activeModal = false;
    },
    setBookmarkName(index, e) {
      this.activeModalIndex = index;
      if (this.reports[index].bookmarkName === '') {
        this.constTabBookmarkName = `${(this.allDimension.find(e => e.column_name === Object.keys(this.reports[index].defaultFilter)[0]) || {}).title}: ${this.reports[index].defaultFilterName}`;
      } else {
        this.constTabBookmarkName = this.reports[index].bookmarkName;
      }
    },
  },
};
</script>
