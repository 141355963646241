<template>
  <div class="card card-body profile-card profile__general-info edit-notifications">
    <div class="row">
      <h5 class="card-title d-flex justify-content-between align-items-center w-100 pt-0 mb-12">
        <span>{{$t('profile.notifications.title')}}</span>
        <span>
          <button v-if="!editable"
                  class="btn btn-outline-primary btn-sm"
                  @click.prevent="toggleEdit"
          >{{$t('profile.buttons.edit')}}</button>
          <button v-else
                  class="btn btn-outline-primary btn-sm btn-outline__close"
                  @click.prevent="toggleEdit"
          >x</button>
        </span>
      </h5>
    </div>
    <div class="row notification">
      <div class="col-12">
        <hr>
      </div>
      <div class="col-12 mt-16">
        <span class="tip pt-8 pb-16">{{$t('profile.notifications.tip')}}</span>
      </div>
    </div>
    <div v-if="!editable && !turnon" class="row disabled mt-10">
      <span class="col-12">{{$t('profile.notifications.disabled')}}</span>
    </div>
    <div class="row">
      <div class="form-group d-flex justify-content-between w-100 mb-4 mt-10">
        <label v-if="turnon || editable" class="mb-0 label">{{$t('profile.notifications.receive_to')}}</label>
        <el-switch v-if="editable"
                   active-color="#58b229"
                   inactive-color="#ff5c70"
                   :value="turnon"
                   @change="toggleNotifications"
        />
      </div>
    </div>
    <span class="channel" v-if="channel === 'telegram' && !editable && turnon">
      <i class="fab fa-telegram pr-4"></i>
      <span>Telegram</span>
    </span>
    <div class="channel-radio" v-if="editable && turnon" :class="{'mb-6': !editable || channel === 'email'}">
      <div>
        <el-radio v-model="channel" label="email" class="mb-4 mr-8">
          E-mail
        </el-radio>
        <el-radio :disabled="!telegramBotData.bot_username" v-model="channel" label="telegram" class="mb-4 ml-8">
          Telegram
        </el-radio>
      </div>
    </div>
    <div v-if="turnon" class="channel-radio">
      <div v-if="channel === 'email'" class="">
        <span class="channel pb-6" v-if="channel === 'email'">
          <i class="fas fa-envelope mt-2 mr-6"></i>
          <span>{{userData.email}}</span>
        </span>
        <div v-if="channel === 'email'">
          <div
            class="email-wrap"
            :class="{'pt-0': !additional_email.is_verified}"
            :key="additional_email.id"
            v-for="additional_email in userData.service_domain_changes_notifications_additional_emails"
          >
            <span class="pending-text-wrap" v-show="!additional_email.is_verified">
              <span class="pending-text pr-14">
                {{$t('profile.notifications.pending_activation')}}
              </span>
              <span class="resend-email" @click="resendEmail(additional_email.id)">
                <i class="fas fa-sync-alt text-green"></i>
                <span class="text-green">
                  {{$t('profile.notifications.resend')}}
                </span>
              </span>
            </span>
            <span class="email-item d-flex justify-content-between">
              <span class="channel" :class="{'channel-pending': !additional_email.is_verified}" >
                <i class="fas fa-envelope mt-2 mr-6" />
                <span>{{additional_email.email}}</span>
              </span>
              <el-popover
                placement="top-end"
                trigger="click"
                ref="popover"
              >
                <div class="message__wrapper">
                  <p>{{ $t('topNavbar.wantDelete') }}?</p>
                  <div class="d-flex justify-content-end">
                    <button
                      class="btn btn-outline-primary btn-sm mb-0"
                      @click="hidePopup()"
                    >{{ $t('alerts.dataTable.cancel') }}
                    </button>
                    <button
                      class="btn btn-danger btn-sm mb-0 ml-8"
                      @click="deleteEmail(additional_email.id), hidePopup()"
                      :disabled="isLoading"
                    >{{ $t('alerts.dataTable.delete') }}
                    </button>
                  </div>
                </div>
                <span slot="reference">
                  <i class="fas fa-trash-alt text-red" />
                </span>
              </el-popover>
            </span>
          </div>
          <div class="email-wrap another-mail" @click="addEmailModal = true">
            <i class="fas fa-plus text-green" />
            <span class="text-green">
              {{$t('profile.notifications.add_another_email')}}
            </span>
          </div>
        </div>
      </div>
      <div v-if="channel === 'telegram'" class="mt-10">
        <ol class="not-list-wrap">
          <li>
            <span>{{$t('profile.notifications.add')}} <a :href="telegramBotData.bot_url" target="_blank">@{{telegramBotData.bot_username}}</a>
              {{$t('profile.notifications.to_telegram')}}</span>
          </li>
          <li>
            <span>{{$t('profile.notifications.press')}} <strong>&#171;{{$t('profile.notifications.start')}}&#187;</strong>
              {{$t('profile.notifications.and_follow')}}</span>
          </li>
          <li>
            <span>{{$t('profile.notifications.once_bot')}}:</span>
          </li>
        </ol>
        <div class="copy mb-10">
          <input type="text" readonly :value="userData.telegram_bot_auth_key" />
          <div class="copy__copy-button-wrapper mr-4 ml-4">
            <tooltip
              :title="$t('profile.notifications.copy')"
            >
              <button class="copy__copy-button" @click="copyToClipboard(userData.telegram_bot_auth_key)">
                <i class="far fa-copy text-green" />
              </button>
            </tooltip>
          </div>
        </div>
      </div>
    </div>
    <div v-if="editable" class="row mt-8">
      <div class="col-12 w-100 text-right bottom-actions">
        <hr>
        <button class="btn btn-outline-primary btn-sm" @click="toggleEdit">
          {{$t('profile.buttons.cancel')}}
        </button>
        <button class="btn btn-primary btn-sm" :disabled="!changed" @click="onSaveClick">
          {{$t('profile.buttons.save')}}
        </button>
      </div>
    </div>

    <modal :show.sync="addEmailModal" @close="closeAnotherEmail" class="modal-alert profile__modal-paymet-edit">
      <h5 slot="header" class="modal-title">
        {{$t('profile.notifications.add_another_email')}}
      </h5>
      <template>
        <div class="mb-8">
          {{$t('profile.notifications.modal_text_another_email')}}
        </div>
        <div class="form-group select-wrapper mb-0 mt-10">
          <label>{{$t('customReports.e_mail')}}</label>
          <input v-model="anotherEmail" class="form-control mt-2" @input="invalidEmail = ''"/>
          <span
            v-if="invalidEmail"
            class="text-danger text-right"
            style="font-size: 12px"
          >{{ invalidEmail }}</span>
        </div>
      </template>
      <div slot="footer" class="d-flex justify-content-end w-100 mt-8 mr-0">
        <button class="btn btn-outline-primary btn-sm btn__back" @click="closeAnotherEmail">
          {{ $t("profile.buttons.cancel") }}
        </button>
        <button class="btn btn-primary btn-sm ml-16" @click="addEmail" :disabled="isLoading">
          {{$t('profile.notifications.add_email')}}
        </button>
      </div>
    </modal>
  </div>
</template>

<script>
import { Modal } from 'src/components';
import Tooltip from 'src/components/Tooltip.vue';

export default {
  components: {
    Modal,
    Tooltip,
  },
  name: 'EditNotifications',
  props: ['channelValue', 'turnonValue'],
  data() {
    return {
      isLoading: false,
      editable: false,
      changed: false,
      turnon: null,
      channel: null,
      addEmailModal: false,
      anotherEmail: '',
      invalidEmail: '',
    };
  },
  computed: {
    userData() {
      return this.$store.getters.getUser;
    },
    telegramBotData() {
      return (this.$store.state.settings || {}).telegram_bot || {};
    },
  },
  mounted() {
    this.channel = this.channelValue;
    this.turnon = this.turnonValue;
  },
  activated() {
    this.channel = this.channelValue;
    this.turnon = this.turnonValue;
  },
  watch: {
    channelValue() {
      this.channel = this.channelValue;
    },
    channel() {
      this.changed = true;
    },
    turnonValue() {
      this.turnon = this.turnonValue;
    },
    turnon() {
      this.changed = true;
    },
  },
  methods: {
    toggleEdit() {
      this.editable = !this.editable;

      if (!this.editable) {
        this.channel = this.channelValue;
        this.turnon = this.turnonValue;
      }

      this.changed = false;
    },
    toggleNotifications() {
      this.turnon = !this.turnon;
    },
    copyToClipboard(value) {
      const el = document.createElement('textarea');
      el.value = value;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
      this.copyDone = true;
      this.$alert.success(this.$t('profile.notifications.copied_message'));
    },
    resendEmail(id) {
      if (this.isLoading) return;

      this.isLoading = true;
      this.$store.dispatch('resendAdditionalEmail', id).then((res) => {
        this.$alert.success(this.$t('profile.notifications.email_again'));
      }).catch((err) => {
        this.$alert.error(err.response.data.message);
      }).finally(() => this.isLoading = false);
    },
    deleteEmail(id) {
      this.isLoading = true;
      this.$store.dispatch('deleteAdditionalEmail', id).then((res) => {
        this.$store.dispatch('getProfileData');
        this.$alert.success(this.$t('profile.notifications.email_removed'));
      }).catch((err) => {
        this.$alert.error(err.response.data.message);
      }).finally(() => this.isLoading = false);
    },
    addEmail() {
      this.invalidEmail = '';
      this.isLoading = true;
      this.$store.dispatch('addAdditionalEmail', {
        email: this.anotherEmail,
      }).then(() => {
        this.addEmailModal = false;
        this.anotherEmail = '';
        this.$store.dispatch('getProfileData');
        this.$alert.success(this.$t('profile.notifications.email_added'));
      }).catch((err) => {
        this.invalidEmail = err.response.data.errors.email[0].message;
      }).finally(() => this.isLoading = false);
    },
    closeAnotherEmail() {
      this.addEmailModal = false;
      this.anotherEmail = '';
      this.invalidEmail = '';
    },
    hidePopup() {
      this.$refs.popover.forEach(e => e.doClose());
    },
    onSaveClick() {
      this.$store.dispatch('saveNotificationSettings', {
        enabled: this.turnon,
        channel: this.channel,
      });
      this.editable = false;
      this.changed = false;
      this.$alert.success(this.$t('profile.notifications.save_success_message'));
      setTimeout(() => {
        this.$store.dispatch('getProfileData');
      }, 500);
    },
  },
};
</script>

<style lang="scss">
@import 'src/assets/theme/default/profile/editprofile';
@import '~flatpickr/dist/flatpickr';
</style>
