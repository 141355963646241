<template>
  <el-popover
    class="bookmarks-list"
    v-model="isPopoverVis"
    ref="isPopoverBook"
    placement="bottom-end"
    trigger="manual"
    popper-class="boookmarks"
  >
    <div
      slot="reference"
      ref="bookmarkMain"
      :filled="isReportingSettings"
      class="tabs-right-btn__bookmarks"
      :class="{
        'bookmark-title-disabled': isBookmarkTitleDisabled
      }"
      @click="handleShowPopover($event)"
    >
      <ui-icon
        :name="'star-filled'"
        :size="13"
        class="tab-action"
      />
      <ui-icon
        :name="'left-chevron'"
        :color="`#303634`"
        :size="8"
        class="tab-action tabs-right-btn__chevron"
        :class="{
          'tabs-right-btn__chevron--open': isPopoverVis
        }"
      />
    </div>
    <div v-loading="isLoadingBookmark">
      <div class="search-input-popover__wrap">
        <ui-input
          is-search
          class="search-input-popover"
          :placeholder="$t('customReports.search')"
          @search="searchBookmark"
          @clear="handleClear"
        />
      </div>
      <div class="list-popover__wrap">
        <div
          v-for="(item, key) in bookmarks"
          :key="item.key"
          class="select-row-popover"
          :class="{
            'popover-open': isPopoverEditIndex === key
          }"
        >
          <div class="select-row-popover__wrap">
            <span
              class="select-row-popover__title"
              @click.stop="handlePushBookmark(key)"
            >
              {{ item.bookmarkName }}
            </span>

            <div class="select-row-popover__wrap-inn">
              <span
                slot="reference"
                :class="{
                  'select-row-popover__edit--active': isPopoverEdit[key]
                }"
                class="select-row-popover__edit"
                @click.stop="handleEditBookmarkName(key, item.bookmarkName, false)"
              >{{ $t('profile.buttons.edit') }}</span>
              <span
                :class="{
                  'select-row-popover__delete--active': isPopoverEdit[key]
                }"
                class="select-row-popover__delete"
                @click.stop="handleEditBookmarkName(key, item.bookmarkName, true)"
              >{{ $t('profile.apiKeys.table.delete')}}</span>
            </div>
          </div>
          <div
            class="hide-edit__bookbarks"
            :class="{
              'open-edit__bookbarks': isPopoverEditExpand && isPopoverEditIndex == key
            }"
          >
            <ui-input
              v-if="!openPopupDelete"
              v-model="constBookmarkName"
              placeholder=""
              :label="$t('customReports.EditBookmark')"
              class="boookmark-edit__input"
            />
            <div
              v-if="openPopupDelete"
              class="delete-text mb-4"
            >
              {{ $t('customReports.sureWeleteBookmark') }}
            </div>
            <div class="boookmark-edit__wrap-btn">
              <button
                class="btn btn-outline-primary btn-sm boookmark-edit__close mr-8"
                @click.stop="handleModalCancel"
              >
                {{ $t('profile.buttons.cancel') }}
              </button>
              <button
                v-if="!openPopupDelete" class="btn btn-primary btn-sm boookmark-edit__success"
                @click.stop="handleModalSave(key)"
              >
                {{ $t('profile.buttons.save') }}
              </button>
              <button
                v-if="openPopupDelete"
                class="btn btn-danger btn-sm boookmark-edit__success"
                @click.stop="handleModalDelete(key)"
              >
                {{ $t('profile.apiKeys.table.delete')}}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="notFoundBookmark">
        <span class="notFoundBookmark">{{$t('customReports.noBbookmarksFound')}}</span>
      </div>
    </div>
  </el-popover>
</template>

<script>
import BookmarksMixin from './bookmarks-mixin.js';

export default {
  mixins: [
    BookmarksMixin,
  ],
  computed: {
    isReportingSettings() {
      return this.reports[this.currentReport].playersType !== 'all';
    },
    isBookmarkTitleDisabled() {
      return this.bookmarks.length === 0 && this.searchBookmarkText.length === 0;
    },
  },
  data() {
    return {
      isPopoverVis: false,
      isPopoverEdit: [],
      isPopoverEditExpand: false,
      openPopupDelete: false,
      notFoundBookmark: false,
      searchBookmarkText: '',
      constBookmarkName: '',
    };
  },
  async mounted() {
    document.addEventListener('click', this.eventStop, true);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.eventStop, true);
  },
  methods: {
    eventStop(e) {
      if (this.$refs.bookmarkMain.contains(e.target)) {

      }
    },
    onBookmarksUpdated() {
      // if(this.bookmarks.length === 0) {
      //   this.isPopoverVis = false;
      // }
    },
    searchBookmark(search) {
      this.searchBookmarkText = search;
      const searchLowerCase = search.toLowerCase();

      this.getBookmarks()
        .then(() => {
          const bookmarks = this.bookmarks
            .filter(e => e.bookmarkName
              .toLowerCase()
              .indexOf(searchLowerCase) !== -1);

          this.$emit('update:bookmarks', bookmarks);
          this.notFoundBookmark = this.bookmarks.length === 0;
        });
    },
    bookmarkMainOpen(event) {
      const popper = this.$refs.isPopoverBook.$refs.popper;
      let listener;
      document.addEventListener('mousedown', listener = (e) => {
        if (event.target !== e.target && (e.clientY > popper.offsetTop + popper.offsetHeight || e.clientY < popper.offsetTop || e.clientX > popper.offsetLeft + popper.offsetWidth || e.clientX < popper.offsetLeft)) {
          this.$refs.isPopoverBook.showPopper = false;
          document.removeEventListener('mousedown', listener);
        }
      });
    },
    handleClear() {
      this.searchBookmarkText = '';
      this.searchBookmark(this.searchBookmarkText);
    },
    handleShowPopover(event) {
      this.$refs.isPopoverBook.showPopper = !this.$refs.isPopoverBook.showPopper;
      this.bookmarkMainOpen(event);
    },
    handlePushBookmark(key) {
      this.pushBookmark(key);
      this.$refs.isPopoverBook.showPopper = false;
    },
    handleEditBookmarkName(key, bookmarkName, openPopupDelete) {
      this.editBookmarkName(key, bookmarkName);
      this.openPopupDelete = openPopupDelete;
    },
    handleModalCancel() {
      this.isPopoverEditExpand = false;
      this.$emit('update:isPopoverEditIndex', null);
      // this.isPopoverEditIndex = null;
    },
    handleModalSave(key) {
      this.saveEditBookmarkName(key);
      this.handleModalCancel();
    },
    handleModalDelete(key) {
      this.deleteBookmark(key);
      this.handleModalCancel();
    },
    saveEditBookmarkName(index) {
      this.bookmarks[index].bookmarkName = this.constBookmarkName;
      this.saveBookmark();
    },
    editBookmarkName(index, name) {
      this.constBookmarkName = name;
      this.$emit('update:isPopoverEditIndex', index);
      // this.isPopoverEditIndex = index;
      this.isPopoverEditExpand = true;
    },
  },
};
</script>
