export default (mock) => {
  mock.onGet('daily').reply(200, {
    success: true,
    payload: [
      {
        day: 1,
        views: 3454,
        clicks: 45345,
        registrations: 445,
        deposits: 3,
        blabal: 444,
        deposits_sum: 466,
        blabal: 89,
        betting_profit: 653,
        revenue: 78,
      },
      {
        day: 56,
        views: 88,
        clicks: 45,
        registrations: 89,
        deposits: 0,
        blabal: 78,
        deposits_sum: 56,
        blabal: 83,
        betting_profit: 66,
        revenue: 56,
      },
      {
        day: 45,
        views: 78,
        clicks: 54,
        registrations: 667533,
        deposits: 80,
        blabal: 4,
        deposits_sum: 45,
        blabal: 55,
        betting_profit: 775,
        revenue: 676,
      },
      {
        day: 5,
        views: 78,
        clicks: 28,
        registrations: 445,
        deposits: 3,
        blabal: 444,
        deposits_sum: 89,
        blabal: 46,
        betting_profit: 38,
        revenue: 78,
      },
    ],
    errors: [
      {},
    ],
  });
};
