<template>
  <SlideYUpTransition :duration="animationDuration">
    <div
      v-show="show"
      :class="[{'show d-block': show}, {'d-none': !show}, {'modal-mini': type === 'mini'}]"
      :aria-hidden="!show"
      class="modal fade"
      tabindex="-1"
      role="dialog"
    >
      <div
        :class="[{'modal-notice': type === 'notice'}, modalClasses]"
        class="modal-dialog"
      >
        <div class="modal-content">
          <div
            :class="headerClasses"
            class="modal-header"
          >
            <slot name="header"/>
            <slot name="close-button">
              <button
                v-if="showClose"
                :aria-hidden="!show"
                type="button"
                class="close"
                data-dismiss="modal"
                @click="closeModal"
              >
                <!-- <i class="now-Components-icons Components-1_simple-remove"></i> -->
                <span class="close-icon">x</span>
              </button>
            </slot>
          </div>
          <div
            :class="bodyClasses"
            class="modal-body"
          >
            <slot/>
          </div>
          <div
            :class="footerClasses"
            class="modal-footer"
          >
            <slot name="footer">
              <button
                v-if="closeModalBottom"
                :aria-hidden="!show"
                type="button"
                class="close"
                data-dismiss="modal"
                @click="closeModal"
              >
                <!-- <i class="now-Components-icons Components-1_simple-remove"></i> -->
                Close
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </SlideYUpTransition>
</template>
<script>
import { SlideYUpTransition } from 'vue2-transitions';

export default {
  name: 'Modal',
  components: {
    SlideYUpTransition,
  },
  props: {
    show: Boolean,
    showClose: {
      type: Boolean,
      default: true,
    },
    closeModalBottom: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
      validator(value) {
        const acceptedValues = ['', 'notice', 'mini'];
        return acceptedValues.indexOf(value) !== -1;
      },
    },
    modalClasses: [Object, String],
    headerClasses: [Object, String],
    bodyClasses: [Object, String],
    footerClasses: [Object, String],
    animationDuration: {
      type: Number,
      default: 500,
    },
  },
  watch: {
    show(val) {
      const documentClasses = document.body.classList;
      if (val) {
        documentClasses.add('modal-open');
        document.addEventListener('keydown', (e) => {
          if (e.keyCode == 27) {
            this.$emit('update:show', false);
            this.$emit('close');
          }
        });
      } else {
        documentClasses.remove('modal-open');
        document.removeEventListener('keydown', document);
      }
    },
  },
  methods: {
    closeModal() {
      this.$emit('update:show', false);
      this.$emit('close');
    },
  },
};
</script>
