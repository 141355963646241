import store from 'src/store';
import config from '../config';

export const fetchMiscSettings = async () => {
  const resp = await fetch(`${config.api.miscURL}/settings`, {
    headers: {
      'X-Customer-Id': config.customerId,
    },
  });
  const result = await resp.json();
  store.commit('saveMiscSettings', result.payload);
  return result;
};
