<template>
  <section class="promo-materials">
    <table-card
      ref="datatable"
      class="promo-materials__table"
      :loading="loading"
      :columns="columns"
      :sort.sync="sorting"
      :sort-by-name="sortByName"
      :sort-dir-name="sortDirName"
      :page.sync="page"
      :page-size.sync="pageSize"
      :rows="rows"
      :count="count"
      :formaters="formaters"
      :actions="!isEmptyOffers ? actions : undefined"
      :header="$t('media.media')"
      tablet-layout
      dont-fill-empty
      extend-height
      i18n-path="media"
      @fetch-data="fetchDataTable"
    >
      <table-card-search-header
        slot="header"
        class="ui-g-md"
        v-model="search"
        @search="handleSearch"
      />
      <div
        slot="header"
        :class="[
          'ui-mobile-12',
          'ui-mobile-wrap',
          'ui-d-flex'
        ]"
      >
        <ui-filters
          :use-filters="availableFilters"
          :filters="filters"
          :route="route"
          @input="getProMaterials"
          @reset="reset"
        />
      </div>
    </table-card>
    <media-tags-popup ref="tagsPopup" />
  </section>
</template>

<script>
import UrlMixin from 'src/components/Query/url-mixin';
import UrlMixinHooks from 'src/components/Query/url-mixin-hooks';
import { pageSizeMixin, resolvePageSize } from 'src/views/Dashboard/Reports/page_size.js';
import TableCard from 'src/components/Cards/TableCard.vue';
import TableCardSearchHeader from 'src/components/Cards/TableCardSearchHeader.vue';
import tableFlowMixinGenerator from 'src/views/Dashboard/Reports/tableFlowMixin.js';
import UiFilters from 'src/components/UiFilters/UiFilters.vue';
import Mobtable from '../../components/Mobiletable/Mobtable';
import Mobpagination from '../../components/Mobiletable/MobPagination';
import MediaTagsPopup from './Media/MediaTagsPopup';
import SortBy from '../../components/SortBy';
import Formatters from './CustomReports/formaters';
import InjectPlugins from '../../components/Report/Components/inject-plugins';

const sizes = {
  1080: 20,
  1440: 30,
};
const viewName = '/promo-materials';
const pageLimitProp = 'limit';
const pageSize = resolvePageSize(viewName, {
  _default: 15,
  sizes,
});

const defaultSettings = {
  search: '',
  language_code: 'all',
  site_id: 'all',
  type: 'all',
  visibility: 'all',
  sort_column: 'updated_at',
  sort_dir: 'desc',
  page: 1,
  pageSize,
};

const fetch = async (ctx, params) => {
  if (ctx.$_.isEmpty(params)) {
    params = {
      ...defaultSettings,
    };
  }

  const itemsParams = {
    search: params.search || defaultSettings.search,
    language_code: params.language_code && params.language_code !== 'all' ? params.language_code : '',
    site_id: params.site_id && params.site_id !== 'all' ? params.site_id : '',
    type: params.type && params.type !== 'all' ? params.type : '',
    limit: params.limit || pageSize || defaultSettings.limit,
    offset: params.offset || defaultSettings.offset,
    sort_column: params.sort_column || defaultSettings.sort_column,
    sort_dir: params.sort_dir || defaultSettings.sort_dir,
  };

  const visibilityStatus = params.visibility && params.visibility !== 'all' ? params.visibility : '';
  if (visibilityStatus !== '') {
    itemsParams.visibility = visibilityStatus;
  }

  const count = () => ctx.$store.dispatch('getCount', itemsParams);
  const items = () => ctx.$store.dispatch('getItems', itemsParams);
  return await Promise.all([items(), count()]);
};

const tableFlowMixin = tableFlowMixinGenerator({
  defaultParams: {
    ...defaultSettings,
  },
  params: Object.keys(defaultSettings),
  fetch,
  windowRefreshHook: true,
  sortByName: 'sort_column',
  sortDirName: 'sort_dir',
});

export default {
  name: 'Media',
  fetch,
  data() {
    return {
      route: 'promoMaterials',
      filters: this.getDefaultFilter(),
      availableFilters: [
        'language', 'brand', 'type', 'visibility',
      ],
      viewName,
      pageLimitProp,
      columns: [
        {
          name: 'incrementing_id',
          align: 'left',
          filterable: false,
          sortable: 'custom',
          width: 60,
        },
        {
          name: 'media_type',
          align: 'left',
          filterable: true,
          sortable: false,
          width: 120,
          renderValue: v => `<span class="d-flex">
                                <span class="">${this.$t(`media.${v.text}`)}</span>
                                ${v.icons.length ? `${v.icons.map(e => `<i class="far fa-${e} ml-4 mt-2"></i>`).join('')}` : ''}
                              </span>`,
        },
        {
          name: 'name',
          align: 'left',
          filterable: true,
          sortable: 'custom',
          width: 250,
        },
        {
          name: 'language_codes',
          align: 'left',
          filterable: true,
          sortable: false,
          renderValue: v => (v.length ? v.join(', ') : '—'),
        },
        {
          name: 'visible_brands',
          align: 'left',
          filterable: true,
          sortable: false,
          renderValue: v => (v.length ? v.map(e => e.site_name).join(', ') : '—'),
        },
        {
          name: 'sizes',
          align: 'left',
          filterable: true,
          sortable: false,
          width: 300,
          renderValue: v => (v.length ? v.join(', ') : '—'),
        },
        {
          name: 'visibility',
          align: 'left',
          filterable: true,
          sortable: false,
          width: 140,
          renderValue: Formatters.visibility.bind(this),
        },
        {
          name: 'updated_at',
          align: 'left',
          filterable: true,
          sortable: 'custom',
          width: 150,
          format: 'date-time',
          renderValue: v => this.$moment(v).format('DD MMM YYYY'),
        },
        {
          name: 'created_at',
          align: 'left',
          filterable: true,
          sortable: 'custom',
          width: 150,
          format: 'date-time',
          renderValue: v => this.$moment(v).format('DD MMM YYYY'),
        },
      ],

      actions: {
        width: 80,
        name: () => this.$t('profile.apiKeys.table.actions'),
        operations: [{
          name: 'show',
          component: (row, ctx) => {
            const { getLinks } = this;
            const span = ctx.$createElement('span', {
              on: {
                click(e) {
                  e.cancelBubble = true;
                  getLinks(row);
                },
              },
            }, [ctx.$createElement('i', {
              attrs: {
                class: 'far fa-code text-green',
              },
            })]);
            const links = ctx.$createElement('el-tooltip', {
              attrs: {
                class: 'el-tooltip icon__wrapper text-green show-channel ui-m-sm-l',
                placement: 'top',
                content: this.$t('media.get_links'),
              },
            }, [span]);

            const downloadLink = ctx.$createElement('a', {
              attrs: {
                target: '_blank',
                href: row.download_url,
              }
            }, [ctx.$createElement('i', {
              attrs: {
                class: 'far fa-download text-info',
              },
            })]);

            const download = ctx.$createElement('el-tooltip', {
              attrs: {
                class: `el-tooltip icon__wrapper text-info ${row.type !== 'simple_banner' || !row.download_url ? 'disabled' : ''}`,
                placement: 'top',
                content: this.$t('profile.apiKeys.createDialog.download'),
              },
            }, [downloadLink]);

            return ctx.$createElement('div', {
              attrs: {
                class: 'ui-align-center'
              }
            }, [download, links]);
          },
        }],
      },
      loading: false,
      formaters: Formatters,
    };
  },
  components: {
    Mobtable,
    Mobpagination,
    SortBy,
    MediaTagsPopup,
    TableCard,
    TableCardSearchHeader,
    UiFilters,
  },
  mixins: [
    UrlMixin,
    UrlMixinHooks,
    InjectPlugins,
    pageSizeMixin,
    tableFlowMixin,
  ],
  props: ['passedParams'],
  watch: {
    filters: {
      immediate: true,
      handler(value) {
        let _value = value;
        if (!value) {
          this.filter = this.getDefaultFilter();
          _value = this.filter;
        }

        this.language_code = _value.language_code;
        this.site_id = _value.site_id;
        this.type = _value.type;
        this.visibility = _value.visibility;
      },
    },
  },
  computed: {
    isEmptyOffers() {
      return this.$_.isEmpty(this.$store.state.promocode.offers);
    },

    rows() {
      return this.$store.state.media.items.map(i => ({
        ...i,
        media_type: {
          text: i.type,
          icons: i.supported_platforms,
        },
      }));
    },
    count() {
      return this.$store.state.media.totals.count;
    },
  },
  methods: {
    getDefaultFilter() {
      return {
        language_code: defaultSettings.language_code,
        site_id: defaultSettings.site_id,
        type: defaultSettings.type,
        visibility: defaultSettings.visibility,
      };
    },

    getLinks(row) {
      this.$refs.tagsPopup.open(row);
    },

    async getProMaterials(data) {
      this.filters = { ...this.filters, ...data };
      this.page = 1;
      await this.update(this.filters);
    },
    reset() {
      this.filters = null;
      this.update(this.filters);
    },
    initFilter(query) {
      const filters = this.$_.pick(query, ['language_code', 'site_id', 'type', 'visibility']);
      const defaultFilters = this.$_.pick(defaultSettings, ['language_code', 'site_id', 'type', 'visibility']);

      this.filters = {
        ...defaultFilters,
        ...this.filters,
        ...filters,
      };
    },

    async update(params = {}) {
      const q = {
        ...this.offsetParams,
        search: this.search,
        ...this.filters,
        ...this.sorting,
        ...params,
      };
      await this.fetchData(q);

      this.search = q.search;
    },
  },
  created() {
    this.$store.dispatch('getMarketingCampaigns');
    this.initFilter(this.urlQuery);
    this.update(this.filters);
    this.actions.operations.forEach(o => o.component && (o.component = o.component.bind(this)));
  },
  mounted() {
    this.$store.dispatch('getPromocodeOffers');
  },
  activated() {
    this.$store.dispatch('getMarketingCampaigns');
    this.$store.dispatch('getPromocodeOffers');
    this.update(this.urlQuery);
  },
};
</script>

<style lang="scss">
@import 'src/assets/theme/default/media/media';
.promo-materials {
  &__status-selector {
    width: 150px;
    // margin-top: 3px;

    // .select2-selection__arrow {
    //   top: 4px !important;
    // }
  }

  &__header-radio-group {
    display: flex;
    // width: unset !important;
    // flex-grow: 0;
  }

  &__header-radio-group-item {
    &.el-radio {
      margin-bottom: 0;
      margin-right: 16px;
    }

    &.el-radio + .el-radio {
      margin-left: 0;
    }

    .el-radio__label {
      padding-left: 4px;
    }
  }

  .table-card-search-header {
    width: 200px;
  }

  &__table {
    .table-card__header {

      .table-card__header-left {
        flex-grow: 0;
        flex-shrink: 1;
      }

      .table-card__header-right {
        flex-grow: 1;
        width: 100% !important;
      }

      @media (min-width: 1050px) and (max-width: 1455px) {
        .ui-tablet-6 {
          flex-grow: 0.5;
          width: 50% !important;
        }

        .table-card__header-left {
          flex-grow: 1;
        }

        .radio-group__left {
          justify-content: flex-end;
        }

        .ui-tablet-12 {
          flex-grow: 1;
          width: 100% !important;
        }

        .ui-tablet-wrap {
          flex-wrap: wrap !important;
        }
      }

      @media (max-width: 1455px) {
        .table-card__header-title {
          width: unset !important;
          flex-grow: 0;
        }

        .table-card__header-left {
          flex-grow: 1;
          width: 100% !important;
        }

        .table-card__header-right {
          flex-grow: 0;
          flex-shrink: 1;
          width: min-content !important;
        }
      }

      @media (max-width: 650px) {
        .table-card__header-right {
          width: 100% !important;
          flex-grow: 1;
        }
      }
    }
  }
}
</style>
