<template>
  <div class="period-wr">
    <!-- date select -->
    <div
      :class="[
        'input-block-period',
        'input-padding-left',
        'input-padding-bottom',
        'select-respons'
      ]"
    >
      <v-select
        class="el-input"
        :key="$locale"
        ref="select"
        v-model="period"
        :options="periodItems"
        :settings="{
          minimumResultsForSearch: Infinity,
          dropdownCssClass: 'date-drop-select',
        }"
        @change="change($event, true)"
        v-click-outside="onBlur"
      />
      <i class="fas cust fa-calendar-alt" />
      <span
        v-if="customPeriodText"
        class="custom-data"
      >{{customPeriodText}}</span>
      <i
        v-if="customPeriodText"
        class="fas cust fa-times-circle"
        @click="removeCustom($event)"
      />
    </div>

    <!-- date picker calendar -->
    <div
      :class="[
        'select-respons',
        'input-block-period',
        'form-group',
        'mt-0',
        'mb-0'
      ]"
    >
      <el-date-picker
        class="date-picker"
        ref="datepicker"
        v-model="datepicker"
        type="daterange"
        :picker-options="{
          firstDayOfWeek: this.firstDay,
        }"
        format="dd MMM yyyy"
        @change="setRange($event, true)"
        @blur="blur"
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment';
import UrlMixin from 'src/components/Query/url-mixin';
import { setTimeout } from 'timers';

export default {
  mixins: [UrlMixin],
  props: {
    settings: {
      default: null,
    },
    defaultAllTime: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      range: [],
      period: this.defaultAllTime ? 2 : 30,
      customPeriodText: '',
      datepicker: '',
      prev: this.defaultAllTime ? 2 : 30,
      periodItems: [
        {
          id: 1,
          text: this.$t('periodWrapper.custom'),
          field: 'custom',
        }, {
          id: 2,
          text: this.$t('periodWrapper.all_period'),
          field: 'all_period',
        }, {
          id: 7,
          text: this.$t('periodWrapper.this_week'),
          field: 'this_week',
        }, {
          id: -70,
          text: this.$t('periodWrapper.previous_week'),
          field: 'previous_week',
        }, {
          id: -7,
          text: this.$t('periodWrapper.last_7'),
          field: 'last_7',
        }, {
          id: -14,
          text: this.$t('periodWrapper.last_14'),
          field: 'last_14',
        }, {
          id: 30,
          text: this.$t('periodWrapper.this_month'),
          field: 'this_month',
        }, {
          id: -30,
          text: this.$t('periodWrapper.previous_month'),
          field: 'previous_month',
        }, {
          id: 100,
          text: this.$t('periodWrapper.last_30'),
          field: 'last_30',
        }, {
          id: 365,
          text: this.$t('periodWrapper.this_year'),
          field: 'this_year',
        }, {
          id: -365,
          text: this.$t('periodWrapper.previous_year'),
          field: 'previous_year',
        }, {
          id: -3650,
          text: this.$t('periodWrapper.last_365'),
          field: 'last_365',
        },
      ].filter(el => (this.defaultAllTime ? true : el.id !== 2)),
      locale: '',
      loh: false,
    };
  },
  computed: {
    customPlaceholder() {
      return !this.periodItems.some(e => e.id === +this.period);
    },
    firstDay() {
      const firstDay = this.$store.state.profile.user.first_day_of_week;
      const days = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'];
      return days.findIndex(d => d === firstDay) + 1 || 1;
    },
  },
  watch: {
    period(value, oldValue) {
      if (this.customPeriodText) {
        this.$nextTick(() => {
          this.period = '';
        });
      } else if (+value === 1) {
        this.$nextTick(() => {
          this.period = +oldValue;
        });
      } else if (value !== oldValue) {
        this.prev = +oldValue;
      }
    },
    $locale: {
      immediate: true,
      handler(value) {
        if (this.range && this.range.length) {
          const [a, b] = this.$_.clone(this.range);

          moment.updateLocale(value, {
            week: {
              dow: this.firstDay < 7 ? this.firstDay : 0,
            },
          });

          this.customPeriodText = `${moment(a)
            .locale(value)
            .format('DD MMM YYYY')} ${this.$t('periodWrapper.to')} ${moment(b)
            .locale(value)
            .format('DD MMM YYYY')}`;
        }
        this.periodItems.map((e) => {
          e.text = this.$t(`periodWrapper.${e.field}`);
        });
      },
    },
    settings: {
      immediate: true,
      deep: true,
      handler(value, oldValue) {
        if (!this.$_.isEqual(value, oldValue)) {
          this.initPeriod(value);
        }
      },
    },
    firstDay() {
      this.initLocale();

      if (typeof this.period === 'number') {
        this.setPeriod(+this.period);
      }
    },
  },
  activated() {
    this.initLocale();
  },
  mounted() {
    this.initLocale();
  },
  updated() {
    this.locale = this.$locale;
  },
  methods: {
    onBlur() {
      this.$refs.select.select2.select2('close')
    },
    initPeriod(settings) {
      if (settings && settings.period) {
        this.period = settings.period;
        const isCustomPeriod = typeof settings.period === 'string' || settings.period == 1;

        if (isCustomPeriod && settings.to && settings.from) {
          this.setRange([settings.from, settings.to]);
        } else {
          this.setPeriod(settings.period);
        }
      } else if (settings && settings.range) {
        this.range = settings.range;
        this.setRange(settings.range);
      } else {
        this.period = this.defaultAllTime ? 2 : 30;
        this.setPeriod(this.period);
      }
    },
    initLocale() {
      moment.updateLocale(localStorage.getItem('language'), {
        week: {
          dow: this.firstDay < 7 ? this.firstDay : 0,
        },
      });
    },
    blur(v) {
      if (this.period == 1 && !this.customPeriodText) {
        this.period = this.prev;
        this.setPeriod(this.prev);
      }
    },
    setRange(range, changeEvent = false) {
      if (range.length !== 2) {
        this.period = this.prev;
        this.change(this.prev, changeEvent);
        return;
      }
      range = range.map((e, i) => {
        if (!i) {
          return `${moment(e)
            .format('YYYY-MM-DD 00:00:00')}`;
        }
        return `${moment(e)
          .format('YYYY-MM-DD 23:59:59')}`;
      });

      this.period = 1;
      this.range = range;
      let [a, b] = this.$_.clone(this.range);
      this.customPeriodText = `${moment(a)
        .format('DD MMM YYYY')} ${this.$t('periodWrapper.to')} ${moment(b)
        .format('DD MMM YYYY')}`;

      if (a.length === 10) {
        a = `${a} 00:00:00`;
      }

      if (b.length === 10) {
        b = `${b} 23:59:59`;
      }

      this.$emit('update', {
        from: a,
        to: b,
        period: this.period,
      });

      setTimeout(() => {
        if (changeEvent) {
          this.$emit('change', {
            from: a,
            to: b,
            period: this.period,
          });
        }
      });
    },
    change(period, changeEvent = false) {
      this.setPeriod(+period || (this.defaultAllTime ? 2 : 30), changeEvent);
    },
    setPeriod(period, changeEvent = false) {
      if (!period) return;

      if (period === 1 && this.range.length) {
        this.datepicker = [
          moment(this.range[0]).format('YYYY-MM-DD 00:00:00'),
          moment(this.range[1]).format('YYYY-MM-DD 00:00:00'),
        ];

        if (changeEvent) {
          setTimeout(() => {
            this.$refs.datepicker.showPicker();
          }, 100);
        }

        return;
      }

      // invoke custom dates
      if (typeof period === 'object') {
        //
        // this.$emit('update', period);
        return;
      } else if (typeof period === 'string') {
        let [a, b] = period.split(this.$t('periodWrapper.to'))
          .map(e => e.trim());

        if (a.length === 10) {
          a = `${a} 00:00:00`;
        } else {
          a = `${moment(a)
            .format('YYYY-MM-DD 00:00:00')}` !== 'Invalid date' ? `${moment(a)
              .format('YYYY-MM-DD 00:00:00')}` : this.setPeriod(30);
          return;
        }

        if (b.length === 10) {
          b = `${b} 23:59:59`;
        } else {
          b = `${moment(b)
            .format('YYYY-MM-DD 23:59:59')}`;
        }

        this.range = [a, b];
        this.customPeriodText = `${moment(a)
          .format('DD MMM YYYY')} ${this.$t('periodWrapper.to')} ${moment(b)
          .format('DD MMM YYYY')}`;
        this.$emit('update', {
          from: a,
          to: b,
          period: this.period,
        });

        // if(changeEvent) {
        //   this.$emit('change', {
        //     from: a,
        //     to: b,
        //     period: this.period,
        //   });
        // }
        return;
      }

      moment.updateLocale(this.$locale, {
        week: {
          dow: this.firstDay === 7 ? 0 : 1,
        },
      });

      const firstDayOfMonth = moment()
        .startOf('month');
      const firstDayOfWeek = moment()
        .startOf('week');
      const firstDayOfYear = moment()
        .startOf('year');
      const lastDayOfMonth = moment()
        .endOf('month');
      const lastDayOfWeek = moment()
        .endOf('week');
      const lastDayOfYear = moment()
        .endOf('year');
      const endOfDay = moment()
        .endOf('day');
      let start = new Date(); let
        end = new Date();

      if (period !== 1) {
        this.customPeriodText = '';
        this.range = [];
      }

      switch (period) {
        // all data
        case 2:
          start = moment(firstDayOfYear)
            .subtract(10, 'year');
          end = lastDayOfYear;
          break;
        // custom
        case 1:
          if (changeEvent) {
            setTimeout(() => {
              this.$refs.datepicker.showPicker();
            }, 100);
          }
          return;
        // this week
        case 7:
          start = firstDayOfWeek;
          end = lastDayOfWeek;
          break;
        // Previous week
        case -70:
          start = moment(firstDayOfWeek)
            .subtract(1, 'week');
          end = moment(lastDayOfWeek)
            .subtract(1, 'week');
          break;
        // Last 7 days
        case -7:
          start = (() => {
            const d = new Date();
            d.setDate(d.getDate() - 6);
            return moment(d)
              .startOf('day');
          })();
          end = endOfDay;
          break;
        // Last 14 days
        case -14:
          start = (() => {
            const d = new Date();
            d.setDate(d.getDate() - 13);
            return moment(d)
              .startOf('day');
          })();
          end = endOfDay;
          break;
        // this month
        case 30:
          start = firstDayOfMonth;
          end = endOfDay;
          break;
        // Previous month
        case -30:
          start = moment(firstDayOfMonth)
            .subtract(1, 'months');
          end = moment()
            .subtract(1, 'months')
            .endOf('month');
          break;
        // last 30 days
        case 100:
          start = (() => {
            const d = new Date();
            d.setDate(d.getDate() - 29);
            return moment(d)
              .startOf('day');
          })();
          end = endOfDay;
          break;
        // this year
        case 365:
          start = firstDayOfYear;
          end = lastDayOfYear;
          break;
        // last year
        case -365:
          start = moment(firstDayOfYear)
            .subtract(1, 'year');
          end = moment(lastDayOfYear)
            .subtract(1, 'year');
          break;
        // last 365
        case -3650:
          start = (() => {
            const d = new Date();
            d.setDate(d.getDate() - 364);
            return moment(d)
              .startOf('day');
          })();
          end = endOfDay;
          break;
        default:
          start = firstDayOfMonth;
          end = lastDayOfMonth;
      }

      start = `${moment(start)
        .format('YYYY-MM-DD 00:00:00')}`;
      end = `${moment(end)
        .format('YYYY-MM-DD 23:59:59')}`;

      if (period !== 1) {
        this.$emit('update', {
          from: start,
          to: end,
          period,
        });

        if (changeEvent) {
          this.$emit('change', {
            from: start,
            to: end,
            period,
          });
        }
      }
    },
    removeCustom(e) {
      e.stopPropagation();
      this.period = this.defaultAllTime ? 2 : 30;
      this.customPeriodText = '';
      this.change(this.period, true);
    },
  },
};
</script>

<style lang="scss">
@import '~flatpickr/dist/flatpickr';
@import 'src/assets/theme/default/components/periodWrapper';
</style>
