<template>
  <section
    class="player-report sub-affiliates-reports"
    :class="{wavebg: loading}"
  >
    <ui-columns-select
      ref="columnsPopup"
      v-model="selectedColumns"
      :metrics="getMetrics"
      :groups="columns_groups"
      :default="default_cols"
      :supported="supportedMetrics"
      @changed="getReport"
    ></ui-columns-select>

    <table-card
      ref="datatable"
      :loading="loading"
      :columns="getTableFields"
      :sort.sync="sorting"
      sort-by-name="prop"
      sort-dir-name="order"
      :page.sync="page"
      :page-size.sync="limit"
      :currency="currencyLabel"
      :rows="data"
      :totals="totals"
      :count="count"
      :formaters="formaters"
      :hide-columns="hiddenFields"
      period-picker
      :period.sync="period"
      i18n-path="reports"
      show-total
      row-selectable
      custom-context
      condenced
      height-fix
      :selected-row.sync="selectedRow"
      mobile-switchable
      mobile-has-period
      @current-change="handleCurrentRowChange"
      @change-range="getReport"
      @fetch-data="getReport"
      @filter="handleFilter"
    >
      <div
        slot="headerPeriodPickerActions"
        :class="[
          'ui-d-flex',
          'ui-tablet-hide',
          'ui-desktop-hide',
        ]"
      >
        <div class="ui-g-md">
          <ui-button
            :class="{'search-fill-btn': multiSelectBlock}"
            class="btn"
            icon="search"
            @click="multiSelectBlock = !multiSelectBlock"
          />
        </div>

        <export-report
          class="ui-g-md"
          :export-links="exportUrls"
        ></export-report>

        <div class="ui-g-md">
          <ui-button
            icon="refresh"
            class="d-flex btn btn-lowercase"
            style="width: 34px;"
            @click="getReport"
          ></ui-button>
        </div>
      </div>

      <div
        slot="header"
        :class="[
          'ui-d-flex',
          'ui-mobile-12',
          'ui-mobile-wrap'
        ]"
      >
        <table-card-search-header
          :class="{
            'ui-g-md': true,
            'ui-mobile-12': true,
            'ui-mobile-hide': !multiSelectBlock,
            'ui-tablet-hide': true,
          }"
          v-model="filter"
          @search="search"
        ></table-card-search-header>

        <tooltip
          class="ui-g-md ui-mobile-hide"
          :title="$t('sidebar.menu.reports.summary')"
          :disabled="!selectedRow"
        >
          <a :href="linkTo('summary', true)">
            <ui-button
              class="btn btn-hide btn-lowercase"
              :disabled="!selectedRow"
              fixed-width
            >
              <img
                class="sub-affiliates-reports__img-icon"
                src="@/assets/theme/sidebar/reports.svg"
              >
            </ui-button>
          </a>
        </tooltip>

        <tooltip
          class="ui-g-md ui-mobile-hide"
          :title="$t('customReports.daily')"
          :disabled="!selectedRow"
        >
          <a :href="linkTo('daily', true)">
            <ui-button
              class="btn btn-hide btn-lowercase"
              :disabled="!selectedRow"
              fixed-width
            >
              <img
                class="sub-affiliates-reports__img-icon"
                src="@/assets/theme/sidebar/daily.svg"
              >
            </ui-button>
          </a>
        </tooltip>

        <tooltip
          class="ui-g-md ui-mobile-hide"
          :title="$t('dashboard.players')"
          :disabled="!selectedRow"
        >
          <a :href="linkTo('players', true)">
            <ui-button
              class="btn btn-hide btn-lowercase"
              :disabled="!selectedRow"
              fixed-width
            >
              <img
                class="sub-affiliates-reports__img-icon"
                src="@/assets/theme/sidebar/players.svg"
              >
            </ui-button>
          </a>
        </tooltip>

        <tooltip
          class="ui-g-md ui-mobile-hide"
          :title="$t('routes.custom')"
          :disabled="!selectedRow"
        >
          <a :href="linkTo('custom', true)">
            <ui-button
              class="btn btn-hide btn-lowercase"
              :disabled="!selectedRow"
              fixed-width
            >
              <img
                class="sub-affiliates-reports__img-icon"
                src="@/assets/theme/sidebar/custom.svg"
              >
            </ui-button>
          </a>
        </tooltip>
      </div>

      <div
        class="ui-d-flex ui-mobile-hide"
        slot="actionsPrepend"
      >
        <export-report
          class="ui-g-md"
          :export-links="exportUrls"
        ></export-report>

        <div class="ui-g-md">
          <ui-button
            class="btn btn-eye"
            icon="eye"
            :icon-size="9"
            @click="openColumnsPopup"
          ></ui-button>
        </div>

        <div class="ui-g-md">
          <ui-button
            icon="refresh"
            class="d-flex btn btn-lowercase"
            @click="getReport"
          ></ui-button>
        </div>
      </div>
    </table-card>
  </section>
</template>

<script>
import eventBus from 'src/lib/eventBus';
import { Select, Option } from 'element-ui';
import Mobtable from 'src/components/Mobiletable/Mobtable';
import PeriodWrapper from 'src/views/Dashboard/Reports/Filters/PeriodWrapper.vue';
import VSelectMenu from 'src/components/VSelectMenu.vue';
import SortBy from 'src/components/SortBy.vue';
import Formaters from 'src/views/Dashboard/CustomReports/formaters';
import InjectPlugins from 'src/components/Report/Components/inject-plugins';
import DimensionsMixin from 'src/components/Report/Components/DimensionsMixin';
import UrlMixin from 'src/components/Query/url-mixin';
import UrlMixinHooks from 'src/components/Query/url-mixin-hooks';
import ColumnsMixin from 'src/views/Dashboard/Reports/columns-mixin';
import HelpersMixin from 'src/components/Helpers/helpers-mixin.js';
import { pageSizeMixin, resolvePageSize } from 'src/views/Dashboard/Reports/page_size.js';
import TableCard from 'src/components/Cards/TableCard.vue';
import TableCardSearchHeader from 'src/components/Cards/TableCardSearchHeader.vue';
import ExportReport from 'src/views/Dashboard/Reports/ExportReport.vue';
import Tooltip from 'src/components/Tooltip.vue';
import tableFlowMixinGenerator from 'src/views/Dashboard/Reports/tableFlowMixin.js';
import formatCurrency from '@/mixins/format-currency';

const sizes = {
  1080: 20,
  1440: 30,
};
const viewName = 'reports/affiliates';
const pageSize = resolvePageSize(viewName, {
  _default: 15,
  sizes,
});
const fetch = async (ctx) => {
  await ctx.$store.dispatch('getSubAffiliates', {
    limit: 10000,
    offset: 0,
  });
  return [];
};

export default {
  fetch,
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    PeriodWrapper,
    VSelectMenu,
    Mobtable,
    SortBy,
    TableCard,
    TableCardSearchHeader,
    ExportReport,
    Tooltip,
  },
  mixins: [
    InjectPlugins,
    ColumnsMixin,
    DimensionsMixin,
    UrlMixin,
    UrlMixinHooks,
    pageSizeMixin,
    HelpersMixin,
    formatCurrency,
  ],
  props: ['passedParams'],
  data() {
    return {
      viewName,
      default_cols: [],
      hiddenFields: [],
      selectedRow: null,
      sort_dir: 'desc',
      sort_by: 'sub_affiliate_email',
      filter: '',
      oldFilter: '',
      group_by: 'sub_affiliate_email',
      count: 0,
      period: {},
      columnsFilter: {},
      data: [],
      loading: false,
      limit: pageSize,
      page: 1,
      query: {},
      multiSelectBlock: false,
      totals: {},
      formaters: Formaters,
      selectedColumns: [],
      columns_groups: [],
      swowInput: false,
      passedPeriod: {
        period: 30,
      },
      exportUrls: {},
      render: 0,
    };
  },
  computed: {
    currencyLabel() {
      return this.getCurrencyLabel(this.$store.state.payments.currencyCode);
    },
    cacheData() {
      return {
        query: this.query,
      };
    },
    getTableFields() {
      if (!this.getMetrics.length) {
        return [];
      }

      const metrics = this.getMetrics.map((e) => {
        this.allMetrics.map((i) => { if (i.name === e.column_name) e = { ...e, ...i }; });
        return e;
      });

      const fields = this.selectedColumns.map(e => metrics.find(f => f.column_name === e));
      fields.forEach((item) => {
        item.sortable = 'custom';
        item.format = false;
        item.source = 'metrics';
      });
      fields.unshift(this.groupByField);
      fields[0].sortable = 'custom';
      fields[0].minWidth = 180; // костыль
      fields[0].source = 'metrics';
      // this.render++;

      return fields;
    },
    getMetrics() {
      return (this.$store.state.settings.metrics || []).map(el => this.formatMetric(el));
    },
    groupByField() {
      const { dimensions } = this.$store.state.settings;
      return dimensions
        ? dimensions.find(field => field.column_name === this.group_by)
        : [];
    },
    sorting: {
      set(values) {
        this.sort_by = values.prop;
        this.sort_dir = values.order;
      },
      get() {
        this.dependsHack();

        return {
          prop: this.sort_by,
          order: this.sort_dir,
        };
      },
    },
  },
  watch: {
    '$i18n.locale': async function () {
      if (this.isComponentActivated) {
        const promise = Promise.all([
          this.$store.dispatch('getSettings'),
          this.resolveMetrics(),
          this.getReport(),
        ]);

        this.loading = true;
        await promise;
        this.loading = false;
      }
    },
  },
  async created() {
    this.setFromQuery(this.passedParams);
    // this.getReport = debounce(this.getReport.bind(this), 150);
    this.$store.dispatch('getSettingsLazy');
    await this.resolveMetrics();
  },
  async mounted() {
    this.loading = true;
    await this.resolveMetrics();
    this.selectedColumns = this.default_cols;
    this.setFromQuery(this.passedParams);
    await this.getReport();

    eventBus.$on('window-refresh', () => {
      if (this.$route.name === 'routes.subAffiliatesReports') this.getReport();
    });
  },
  async activated() {
    this.loading = false;
    this.$store.dispatch('getSettingsLazy');
    await this.resolveMetrics();
    await this.getReport();
  },
  deactivated() {
    this.loading = true;
  },
  methods: {
    async resolveMetrics() {
      const { ready } = this.$store.state.settings;
      await ready.then(() => {
        const {
          dimensions, grouped_metrics, metrics, metrics_visible_by_default,
        } = this.$store.state.settings;

        this.columns_groups = grouped_metrics.map((e) => {
          e.items.map((i) => {
            if (i.items && !i.items.length) delete i.items;
            return i;
          });
          return e;
        });
        this.supportedMetrics = metrics.map(e => e.name);
        this.default_cols = metrics_visible_by_default;

        return this.default_cols;
      });
    },
    setFromQuery(params) {
      const { query = {} } = params;
      this.query = query;

      if (query.page) {
        this.page = query.page;
      }

      if (query.limit) {
        this.limit = query.limit;
      }

      if (query.group_by) {
        this.group_by = query.group_by;
        this.sort_by = query.sorting[0].sort_by;
        this.sort_dir = query.sorting[0].sort_dir;
      }

      if (query.metrics) {
        this.selectedColumns = query.metrics;
      }

      if (query.having) {
        this.columnsFilter = query.having;
      }

      if (query.passedPeriod) {
        this.setPeriod(query.passedPeriod);
      } else if (query.period) {
        this.setPeriod({
          from: query.from,
          to: query.to,
          period: query.period,
        });
      }

      if (query.search && query.search.sub_affiliate_email) {
        this.filter = query.search.sub_affiliate_email.value[0];
      }

      this.cacheQuery();
    },
    getDataQuery() {
      const columnsFilter = {};
      this.$_.forOwn(this.columnsFilter, (e, key) => {
        columnsFilter[key] = this.$_.pick(e, ['op', 'value']);
      });
      // check column filter
      Object.keys(columnsFilter)
        .forEach((e) => {
          let flagDel = false;
          this.selectedColumns.forEach((el) => {
            e === el ? flagDel = true : '';
          });
          !flagDel ? delete columnsFilter[e] : '';
        });

      const sort_by = this.sort_by && this.selectedColumns.includes(this.sort_by) ? this.sort_by : this.group_by;
      const params = {
        dimensions: [this.group_by],
        sorting: [{
          sort_by,
          sort_dir: this.sort_dir,
        }],
        metrics: this.selectedColumns,
        limit: this.limit,
        offset: this.limit * this.page - this.limit,
        from: this.period.from,
        to: this.period.to,
        search: this.filter ? {
          [this.group_by]: {
            op: 'like',
			      value: [this.filter],
          },
        } : {},
        having: columnsFilter,
        metrics_format: 'pretty',
      };

      this.query = {
        ...params,
        period: this.period.period,
        group_by: this.group_by,
        page: this.page,
      };

      this.cacheQuery();

      return params;
    },
    async getReport(from) {
      this.loading = true;
      await this.resolveMetrics();
      const params = this.getDataQuery();
      if (!params.metrics || !params.metrics.length) return;
      try {
        return this.$api
          .postReport(params)
          .then((res) => {
            this.count = res.misc.count;
            this.exportUrls = res.misc.export_urls;
            this.totals = res.payload.totals;
            this.data = res.payload.data.map((e) => {
              e.data = {
                id: e.row_id,
              };
              e.sub_affiliate_email = e.affiliate_email;
              delete e.row_filter_column;
              Object.keys(e)
                .forEach((k) => {
                  if (e[k] === null) e[k] = 0; // TEMPFIX
                });
              return e;
            });
            //  this.$refs.dataTable.setSortVisibility([params.sort_by, ...Object.keys(params.having)]);
            //  this.$refs.dataTable.$refs.dataTable.sort(params.sort_by, `${params.sort_dir}ending`);
            delete params.having;

            this.afterBrowserRedraw(() => {
              this.loading = false;
            });
          })
          .catch((err) => {
            this.loading = false;
            this.data = [{}];
            delete params.having;
          });
      } catch (e) {
        console.error(e);
      }
    },
    handleCurrentRowChange(current) {
      this.selectedRow = current;
    },
    linkTo(where, noAction) {
      if (!this.selectedRow) {
        return;
      }
      switch (where) {
        case 'summary':
          const summaryQuery = {};
          summaryQuery.subAffiliateActive = true;
          summaryQuery.query = {
            sub_affiliate_id: this.selectedRow.data.id,
            passedPeriod: this.period,
            period: this.period.period,
            // Reset previuos cache
            affiliate_offer_id: '',
            player_id: '',
            traffic_source_id: '',
            country_code: '',
            subid1: '',
            subid2: '',
            subid3: '',
            subid4: '',
            subid5: '',
            trans: true,
          };
          if (!noAction) {
            this.$router.push({
              name: 'routes.summary',
              params: {
                urlQuery: this.compressUrlQuery(summaryQuery),
              },
            });
          }
          return `#/dashboard/summary/${this.compressUrlQuery(summaryQuery)}`;
        case 'daily':
          const dailyQuery = {};
          dailyQuery.subAffiliateActive = true;
          dailyQuery.query = {
            sub_affiliate_id: this.selectedRow.data.id,
            passedPeriod: this.period,
            period: this.period.period,
            group_by: 'date_formatted',
            sorting: [{
              sort_by: 'date_formatted',
              sort_dir: 'desc',
            }],
            // Reset previuos cache
            affiliate_offer_id: '',
            player_id: '',
            traffic_source_id: '',
            country_code: '',
            subid1: '',
            subid2: '',
            subid3: '',
            subid4: '',
            subid5: '',
            trans: true,
          };
          if (!noAction) {
            this.$router.push({
              name: 'routes.daily',
              params: {
                urlQuery: this.compressUrlQuery(dailyQuery),
              },
            });
          }
          return `#/dashboard/daily/${this.compressUrlQuery(dailyQuery)}`;
        case 'players':
          const playerQuery = {};
          playerQuery.subAffiliateActive = true;
          playerQuery.query = {
            sub_affiliate_id: this.selectedRow.data.id,
            passedPeriod: this.period,
            period: this.period.period,
            // Reset previuos cache
            affiliate_offer_id: '',
            player_id: '',
            traffic_source_id: '',
            country_code: '',
            subid1: '',
            subid2: '',
            subid3: '',
            subid4: '',
            subid5: '',
            trans: true,
          };
          if (!noAction) {
            this.$router.push({
              name: 'routes.players',
              params: {
                urlQuery: this.compressUrlQuery(playerQuery),
              },
            });
          }
          return `#/dashboard/players/${this.compressUrlQuery(playerQuery)}`;
        case 'custom':
          const urlQuery = {};
          urlQuery.openSubAffiliateReport = this.selectedRow;
          urlQuery.passedPeriod = this.period;
          if (!noAction) {
            this.$router.push({
              name: 'routes.custom',
              params: {
                urlQuery: this.compressUrlQuery(urlQuery),
              },
            });
          }
          return `#/dashboard/custom/${this.compressUrlQuery(urlQuery)}`;
        default:
      }
    },
    handleFilter(filter) {
      this.columnsFilter = {};
      Object.keys(filter)
        .forEach((key) => {
          if (filter[key].value && filter[key].op) {
            this.columnsFilter[key] = {
              op: filter[key].op,
              value: filter[key].value,
            };
          }
        });
      this.getReport('handle columns filter');
    },
    handleSort({ prop, order }) {
      this.$refs.dataTable.$refs.dataTable.setCurrentRow();
      this.sort_by = prop;
      this.sort_dir = order;
      this.getReport('handle sort');
    },
    changeSort(sort) {
      this.sort_dir = sort.sort_dir;
      this.sort_by = sort.sort_by;
      this.getReport();
    },
    setRange(period) {
      this.setPeriod(period);
      this.getReport();
    },
    setPeriod(period) {
      if (!this.$_.isEqual(this.period, period)) {
        this.page = 1;
      }

      const periodItems = [7, -70, -7, -14, 30, -30, 100, 365, -365, -3650];
      // убрать, если не поможет(наверно на случай глюка)
      if (period.from.length <= 10) {
        return;
      }
      this.period = period;

      if (period.period === 1) {
        // сюда скорее всего никогда не заходит
        this.passedPeriod = { range: [period.from, period.to] };
      } else if (periodItems.includes(period.period)) {
        this.passedPeriod = { period: period.period };
      } else {
        this.passedPeriod = {
          period: `${moment(period.from)
            .format('YYYY-MM-DD')} ${this.$t('periodWrapper.to')} ${moment(period.to)
            .format('YYYY-MM-DD')}`,
        };
      }
    },
    pageChange(page, size) {
      if (this.page !== page || this.limit !== size) {
        this.$refs.dataTable.$refs.dataTable.setCurrentRow();
        this.page = page;
        this.limit = size;
        this.getReport('handle page change');
      }
    },
    search() {
      if (this.filter.trim() || this.oldFilter !== '') {
        this.getReport('search');
      }
      this.oldFilter = this.filter;
    },
    clearInput() {
      if (this.filter.trim()) {
        this.filter = '';
        this.oldFilter = '';
        this.getReport('clearInput');
      }
    },
    getCurrencyClass(value) {
      return value < 0 ? 'negative' : '';
    },
    openColumnsPopup() {
      this.$refs.columnsPopup.open();
    },
    getExportLink(format) {
      return (this.exportUrls || {})[format];
    },
    dependsHack() {
      return this.getTableFields;
    },
  },
};
</script>

<style lang="scss">
@import 'src/assets/theme/default/reports/subaffiliates';
</style>
