export default {
  savePromocodes(state, payload) {
    state.promocodes = payload;
  },
  savePromocodesTotal(state, payload) {
    state.totalRows = payload;
  },
  savePromocodesCount(state, payload) {
    state.count = payload;
  },
  savePromocodeOffers(state, payload) {
    state.offers = payload;
  },
};
