<template>
  <transition name="fade">
    <section
      v-if="hide"
      :class="{
        'success': success == 'success',
        'warning': warning =='warning',
        'danger': danger == 'danger',
        'info': info == 'info'
      }"
      class="notif"
      @click="hideClick"
      @click.right.prevent="hideClick"
      @mouseover="hover = !hover"
      @mouseleave="hout = !hout"
    >
      <div class="notif__wrap">
        <p class="notif__text">
          <img
            v-if="success == 'success'"
            :src="require('@/assets/theme/notification/suc.png')"
            class="notif__img"
            alt="ok"
          >
          <img
            v-if="warning == 'warning'"
            :src="require('@/assets/theme/notification/warn.png')"
            class="notif__img"
            alt="warrning"
          >
          <img
            v-if="danger == 'danger'"
            :src="require('@/assets/theme/notification/dang.png')"
            class="notif__img"
            alt="danger"
          >
          <img
            v-if="info == 'info'"
            :src="require('@/assets/theme/notification/info.png')"
            class="notif__img"
            alt="danger"
          >
          {{ message }}
        </p>
      </div>
    </section>
  </transition>
</template>

<script>
export default {

  props: {
    typeMessage: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      message: '',
      success: false,
      warning: false,
      danger: false,
      info: false,
      action: false,
      hide: false,
      hover: false,
      hout: false,
      timeout: Function,
    };
  },

  watch: {
    action(e) {
      clearTimeout(this.timeout);
      this.hide = true;
      this.timeout = setTimeout(() => {
        this.hide = false;
      }, 3000);
    },
    hover(e) {
      clearTimeout(this.timeout);
    },
    hout(e) {
      setTimeout(() => {
        this.hide = false;
      }, 500);
    },
    typeMessage() {
      this.success = this.typeMessage;
      this.warning = this.typeMessage;
      this.danger = this.typeMessage;
      this.info = this.typeMessage;
    },
  },

  created() {
    this.success = this.typeMessage;
    this.warning = this.typeMessage;
    this.danger = this.typeMessage;
    this.info = this.typeMessage;
  },

  methods: {
    open(message) {
      this.action = !this.action;
      this.message = message;
    },
    hideClick() {
      this.hide = false;
    },
  },
};
</script>

<style lang="scss" scoped>

.success{
	background-color: #4eae1b;
}
.success.notif{
	.notif__text{
		color: #ffffff;
	}
}

.warning{
	background-color: #ffce06;
}
.warning.notif{
	.notif__text{
		left: 10px;
		color: #000000;
	}
	.notif__img{
		top: 5px;
	}
}


.danger{
	background-color: #ff5c70;
}
.danger.notif{
	.notif__text{
		left: 7px;
		color: #ffffff;
	}
	.notif__img{
		top: 5px;
	}
}


.info{
	background-color: #3b94e3;
}
.info.notif{
	.notif__text{
		left: 7px;
		color: #ffffff;
	}
	.notif__img{
		top: 3px;
	}
}


.notif{
	z-index: 111111111;
	display: flex;
	position: fixed;
	align-items: center;
	max-width: 375px;
	padding: 14px 12px 14px 0px;
	border-radius: 8px;
	opacity: 0.95;
	&__img{
		position: absolute;
		left: 15px;
		top: 3px;
	}
	&__text{
		padding: 0;
		margin: 0;
		word-wrap: break-word;
		position: relative;
		padding-left: 40px;

		font-size: 14px;
		font-weight: 600;
	}
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 1s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}


@media (max-width: 450px){
	.notif{
		max-width: 250px;
	}
}
</style>
