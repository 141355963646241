import bus from './eventBus';

export default (app) => {
  const error = app.$error;
  const router = app.$router;
  // const alerts = app.$alert; //inited as component, does not exist on this moment

  bus.$on('error.AUTH_INVALID_CREDENTIALS_ERROR', (data) => {
    if (app.$route.path.indexOf('/dashboard') !== -1) router.push('/logout');
  });
  bus.$on('error.AUTH_ACCOUNT_PENDING', (data) => {
    router.push('/pending-account');
  });
  bus.$on('error.AUTH_ACCOUNT_BLOCKED', (data) => {
    router.push('/blocked-account');
  });
  bus.$on('error.AUTH_ACCOUNT_REJECTED', (data) => {
    router.push('/rejected-account');
  });
  bus.$on('error.AUTH_ACCOUNT_UNVERIFIED_ERROR', (data) => {
    router.push('/unverified-account');
  });
  bus.$on('error.AUTH_ACCOUNT_INACTIVE_ERROR', (data) => {
    router.push('/blocked-account');
  });
  bus.$on('error.FORBIDDEN_ERROR', (data) => {
    console.log('router', data);
  });
  // bus.$on(error.VALIDATION_ERROR, function (data) {
  //   console.log('router', data);
  //   const errors = data.errors;
  //   if (errors.password) this.$eventBus.$emit('otp-validation', 'Wrong password', 1);
  //   if (errors.token) this.$eventBus.$emit('otp-validation', 'Wrong 2FA code', 2);
  // });
  bus.$on('error.NATIVE_SERVICE_UNAVAILABLE', () => {
    router.push('/error/503');
  });
  bus.$on('error.NATIVE_GATEWAY_TIMEOUT', () => {
    router.push('/error/504');
  });
  bus.$on('error.TWO_FACTOR_UNAUTHORIZED', (data) => {
    router.push('/second-factor');
  });
  bus.$on('error.AUTH_TOTP_EXPIRED', (data) => {
    router.push('/second-factor');
  });
  bus.$on('error.AUTH_EMAIL_IS_NOT_VERIFIED', (data) => {
    router.push('/unverified-account');
  });
  // bus.$on('error.AUTH_INVALID_RECAPTCHA_RESPONSE', (data) => {
  //   router.push('/wrong-recaptcha');
  // });
  bus.$on('request/error', (data) => {
    const alerts = app.$alert;

    if (data.status >= 500) {
      alerts.error('Server Error');
    }
    if (data.status === 429) {
      return bus.$emit('flood-warning', data.data);
    }
    // if (app.$errors.knownTypes.indexOf(data.code) === -1) {
    //   alerts.error('Unknown Error: Please contact administrator');
    // }
  });
};
