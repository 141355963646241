<template>
  <section class="media-buying media-buying-loader" :data-name="name">
    <template v-if="name === 'channels'">
      <div slot="head" class="head-wrap">
        <div class="justify-content-between d-flex ml-8 mr-8 level-0">
          <div class="d-flex level-1">
            <div class="d-flex level-2">
              <skeleton height="12px" width="163px" class="mr-12 mt-10 mb-0"/>
            </div>
            <div class="d-flex level-2">
              <skeleton height="34px" width="200px" class="mr-12"/>
              <skeleton height="34px" width="150px" class="mr-12"/>
            </div>
          </div>
          <div class="d-flex level-1">
            <div class="d-flex level-2 ml-8">
              <skeleton height="34px" width="153px"/>
            </div>
            <div class="d-flex level-2 ml-12">
              <div>
                <skeleton height="34px" width="80px" class="mr-12"/>
                <skeleton height="34px" width="212px"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <skeleton width="auto" class="mb-24 ml-24 mr-24"/>
    </template>
    <template v-if="name === 'campaigns'">
      <div slot="head" class="head-wrap">
        <div class="ml-10 mr-10">
          <skeleton height="34px" width="90px" class="mr-12"/>
          <skeleton height="12px" width="200px"/>
        </div>
        <el-divider class="mt-12 mb-12 ml-10 mr-10"/>
        <div class="justify-content-between d-flex ml-8 mr-8 level-0">
          <div class="d-flex level-1">
            <div class="d-flex level-2">
              <skeleton height="12px" width="163px" class="mr-12 mt-10 mb-0"/>
            </div>
            <div class="d-flex level-2">
              <skeleton height="34px" width="200px" class="mr-12"/>
              <skeleton height="34px" width="150px" class="mr-12"/>
            </div>
          </div>
          <div class="d-flex level-1">
            <div class="d-flex level-2 ml-8">
              <skeleton height="34px" width="153px"/>
            </div>
            <div class="d-flex level-2 ml-12">
              <div>
                <skeleton height="34px" width="80px" class="mr-12"/>
                <skeleton height="34px" width="212px"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <skeleton width="auto" class="mb-24 ml-24 mr-24"/>
    </template>
    <template v-if="name === 'campaign_details'">
      <div slot="head" class="head-wrap">
        <div class="justify-content-between d-flex ml-10 mr-10">
          <div>
            <skeleton height="34px" width="90px" class="mr-12 mt-10"/>
            <skeleton height="12px" width="200px" class="mt-10"/>
          </div>
          <div>
            <skeleton height="34px" width="138px" class="mt-10"/>
            <skeleton height="34px" width="138px" class="ml-12 mt-10"/>
          </div>
        </div>
        <skeleton height="12px" width="303px" class="ml-10 mt-22 mb-10"/>
        <el-divider class="mt-12 mb-12 ml-10 mr-10"/>
        <div class="ml-8 mr-8 level-0">
          <skeleton height="38px" width="96px" class="mt-12 mr-32"/>
          <skeleton height="38px" width="96px" class="mt-12 mr-32"/>
          <skeleton height="38px" width="96px" class="mt-12 mr-32"/>
          <skeleton height="38px" width="96px" class="mt-12 mr-32"/>
          <skeleton height="38px" width="96px" class="mt-12"/>
        </div>
      </div>
      <skeleton width="auto" class="ml-24 mr-24 mb-24 mt-10"/>
    </template>
  </section>
</template>

<script>
import Skeleton from '../../components/Skeleton';

export default {
  components: {
    Skeleton,
  },
  created() {
    this.name = this.$vnode.data.props.name;
  },
};
</script>

<style lang="scss" scoped>
@import 'src/assets/theme/default/mediabuying/mediabuying';
</style>
