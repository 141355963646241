<template>
  <section class="datatable-wrap">
    <div
      ref="tableWrap"
      :class="showPagination && paginationDimensions.position"
      class="w-100 card"
    >
      <slot name="head"/>
      <section
        v-if="ready"
        class="mob-wrap"
      >
        <div
          v-if="loadingError || nothingFound"
          class="loadingMessages"
        >
          <span
            v-if="loadingError"
            class="loadingError"
          >{{ $t('alerts.dataTable.loadingError') }}</span>
          <span
            v-if="nothingFound"
            class="nothingFound"
          >{{ $t('alerts.dataTable.noData') }}</span>
        </div>
        <div
          v-for="(item, i) in screen"
          :key="i"
          class="card castom-margin-bottom"
          :class="{'active': switchItem === item && switchValue, 'castom-margin-bottom__actions': actions}"
        >
          <div class="btns-reports__mob-wrap mb-12" v-if="switchable">
            <a
              class="text-green"
              :href="linkTo('summary', true)"
              @click="selectedRow = item, linkTo('summary')"
            >
              {{$t('sidebar.menu.reports.summary')}}
            </a>
            <a
              class="text-green"
              :href="linkTo('daily', true)"
              @click="selectedRow = item, linkTo('daily')"
            >
              {{$t('customReports.daily')}}
            </a>
            <a
              class="text-green"
              :href="linkTo('players', true)"
              @click="selectedRow = item, linkTo('players')"
            >
              {{$t('customReports.players')}}
            </a>
            <a
              class="text-green"
              :href="linkTo('custom', true)"
              @click="selectedRow = item, linkTo('custom')"
            >
              <span>{{$t('routes.custom')}}</span>

            </a>
          </div>
          <div
            v-for="v in columnsComputed"
            :key="v.name"
            class="sub-aff__wrap"
          >
            <div class="sub-aff__row" :class="{'row-reverse': drilldown || switchable}">
              <p
                class="sub-aff__left-text sub-aff__title-id mb-4 mt-4"
                :class="`${v.name}__left`"
              >
                {{ v.title }}
              </p>
              <div v-if="drilldown" class="text-green btn-drilldown text-uppercase">
                <div :class="{'drilldown-exclude': drilldown}">
                  <span @click="drilldownReport({current: item, op: '='})">
                    <i class="fas fa-random mr-4"/>
                    {{ $t('customReports.drilldown') }}
                  </span>
                  <span class="mt-4" @click="drilldownReport({current: item, op: '!='})"
                        :class="{'hide-exclude': hideExclude}"
                  >
                    <i class="fas fa-page-break mr-2"/>
                    {{ $t('customReports.exclude') }}
                  </span>
                </div>
              </div>
              <p
                class="sub-aff__right-text sub-aff__id-value mb-4 mt-4"
                :class="{expandHead: expandHeadSelect === item[v.name] && expandHead}"
                v-if="v.name === 'period'"
              >
                {{ $moment(item.post_period_start).format('DD MMM YYYY HH:mm') }}
                - {{ $moment(item.post_period_end).format('DD MMM YYYY HH:mm') }}
              </p>
              <p
                class="sub-aff__right-text sub-aff__id-value mb-4 mt-4"
                :class="{expandHead: expandHeadSelect === item[v.name] && expandHead}"
                v-if="v.name === 'updated_at'"
              >
                {{ $moment(item.updated_at).format('DD MMM YYYY HH:mm') }}
              </p>
              <p
                class="sub-aff__right-text sub-aff__id-value mb-4 mt-4"
                :class="{expandHead: expandHeadSelect === item[v.name] && expandHead}"
                v-else-if="v.name === 'urls'"
              >
                <el-tooltip placement="top" popper-class="urls-tooltip">
                  <span>
                    <a :href="item.urls[0]" target="_blank" class="text-green">
                      {{item.urls[0]}}
                    </a>
                    <span v-if="item.urls.length > 1" class="countUrl" @click="clickUrls(item[v.name])">
                      +{{item.urls.length - 1}}
                    </span>
                  </span>
                  <span v-if="expandUrlSelect === item[v.name] && expandUrl">
                    <span v-if="item.urls.length > 1" class="countUrl" @click="clickUrls(item[v.name])">
                      +{{item.urls.length - 1}}
                    </span>
                    <span
                      class="d-flex flex-column"
                      :key="url"
                      v-for="url in item.urls"
                    >
                      <a :href="url" target="_blank" class="text-green">{{url}}</a>
                    </span>
                  </span>
                </el-tooltip>
              </p>
              <p
                v-else
                :data-content="item[v.name]"
                :class="{
                  expandHead: expandHeadSelect === item[v.name] && expandHead,
                  [`${v.name}__right`]: true,
                }"
                class="sub-aff__right-text sub-aff__id-value mb-4 mt-4"
                @click="clickMobHead(item[v.name])"
                v-html="formatColumn(item[v.name], v.name, item, v)"
              />
            </div>
          </div>
          <div class="actions" v-if="actions">
            <template v-for="action in actions.operations">
              <span
                v-if="action.computedValue"
                :key="action.name"
                :class="action.class"
                v-html="action.computedValue(scope.row)"
              />
              <render-component v-if="action.component" :key="action.name" :data="action.component(item, target)"/>
              <el-popover
                ref="popover"
                placement="top-end"
                trigger="click"
                :key="action.name"
                v-else-if="action.popup"
              >
                <div class="message__wrapper">
                  <p>{{ $t('topNavbar.wantDelete') }}?</p>
                  <div class="d-flex justify-content-between">
                    <button
                      class="btn btn-outline-primary btn-sm mr-2 mb-0"
                      @click="cancelDelete()"
                    >
                      {{$t('alerts.dataTable.cancel')}}
                    </button>
                    <button
                      class="btn btn-danger btn-sm mb-0"
                      @click="action.callback(item), cancelDelete()"
                    >
                      {{$t('alerts.dataTable.delete')}}
                    </button>
                  </div>
                </div>
                <span v-if="action.icon" slot="reference"
                      :class="`${action.class} el-tooltip icon__wrapper ${action.status !== item.status ? 'disabled' : ''}`"
                >
                  <i v-if="action.icon" :class="`far fa-${action.icon}`"></i>
                </span>
                <a v-else class="btn" :class="action.classMobile" slot="reference">
                  <span>{{action.name}}</span>
                </a>
              </el-popover>
              <el-tooltip
                placement="top"
                :key="action.name"
                v-else-if="action.icon"
              >
                <span
                  class="icon__wrapper"
                  :class="action.class"
                  @click.stop="action.callback(item)"
                >
                  <i :class="`far fa-${action.icon}`"/>
                </span>
                <div slot="content">
                  {{action.tooltip}}
                </div>
              </el-tooltip>
              <a
                v-else
                class="btn"
                :key="action.name"
                :class="action.class"
                :href="action.url ? action.url(item, action.callbackOptions) : null"
                @click="action.callback(item, action.callbackOptions, $event)"
              >{{ action.name }}</a>
            </template>
          </div>
        </div>
      </section>
      <slot name="footer"/>
      <div
        v-if="totals && !nothingFound && !loadingError"
        :class="{totalsFold: !totalsFold}"
        class="total-wrap"
      >
        <div class="card totals-block">
          <div
            v-for="v in totalsComputed"
            :key="v.name"
          >
            <div class="sub-aff__wrap">
              <div class="sub-aff__row">
                <p class="sub-aff__left-text sub-aff__title-id">
                  {{ v.title }}
                </p>
                <p
                  class="sub-aff__right-text sub-aff__id-value"
                  v-html="v = (v.name==='date' || v.totals==='') ? `<span class='text-green'>${$t('customReports.totals')}</span>` : formatColumn(v.totals, v.name, undefined, v)"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          class="total-wrap__select m-0"
          @click="clickMobTotal"
        >
          <span>{{ $t('customReports.totals') }}</span>
          <i class="el-icon-arrow-up el-icon--right"/>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { Popover, Pagination } from 'element-ui';
import render from 'src/components/Report/Components/render';
import UrlMixin from 'src/components/Query/url-mixin';
import UrlMixinHooks from 'src/components/Query/url-mixin-hooks';
import ACPopover from '../Popover';

export default {
  components: {
    [Popover.name]: Popover,
    [Pagination.name]: Pagination,
    RenderComponent: render,
    'ac-popover': ACPopover,
  },
  mixins: [UrlMixin, UrlMixinHooks],
  props: [
    'columns',
    'data',
    'limit',
    'actions',
    'selectable',
    'loading',
    'loadingError',
    'multiselect',
    'tableHeader',
    'messageDelete',
    'formaters',
    'totalRows',
    'pagination',
    'paginationLayout',
    'paginationPosition',
    'paginationBackground',
    'paginationSizes',
    'totals',
    'switchable',
    'drilldown',
    'hideFields',
    'period',
    'hideExclude',
    'i18nPath',
    'currency',
  ],
  data() {
    return {
      page: 1,
      selected: [],
      ready: false,
      switchValue: false,
      switchItem: false,
      paginationDimensions: {
        justify: 'justify-content-end',
        position: '',
      },
      totalsFold: false,
      expandHead: false,
      expandHeadSelect: false,
      defaultPageSizes: [10, 20, 30, 40],
      fitHeight: false,
      fitHeightTouched: false,
      pagerSize: 10,
      selectedRow: null,
      expandUrl: false,
      expandUrlSelect: false,
    };
  },
  computed: {
    target() {
      return this;
    },
    pageSizes() {
      return this.paginationSizes;
    },
    pageSize() {
      this.pagerSize = this.fitHeight ? this.pageSizes[0] : this.limit;
      return this.pagerSize;
    },
    showPagination() {
      if (this.pagination && this.totalRows) return true;
    },
    nothingFound() {
      return !this.data.length;
    },
    screen() {
      return this.data;
    },
    head() {
      const result = [];

      if (this.multiselect && this.selectable) {
        result.push({
          label: '',
          headerComponent: SelectAll,
        });
      } else if (this.selectable) {
        result.push({ label: '' });
      }

      if (!this.actions) {
        return result.concat(this.columns);
      } else {
        for (let i = 0; i < this.columns.length; i++) {
          const k = this.columns[i].field;
          result.push(this.columns[i]);
          if (this.actions[k]) {
            result.push({ label: this.actions[k][0].title || ' ' });
          }
        }
        return result;
      }
    },
    totalsComputed() {
      return this.columns
        .filter(item => typeof this.totals[item.name] !== 'undefined')
        .map((item) => {
          const totals = this.totals[item.name];
          return {
            ...item,
            totals: totals === null
              ? 0
              : totals,
            title: item.title
              ? item.title
              : this.getColumnTitle(item.name),
          };
        });
    },
    columnsComputed() {
      const hideFields = this.hideFields || [];

      return this.columns
        .filter(item => !hideFields.includes(item.name))
        .map(item => ({
          ...item,
          title: item.title
            ? item.title
            : this.getColumnTitle(item.name),
        }));
    },
  },
  created() {
    setTimeout(() => (this.ready = true), 480);
  },
  mounted() {
    this.setPaginationPositions(this.paginationPosition);
  },
  methods: {
    getColumnTitle(name) {
      return this.$t(this.i18nPath ? `${this.i18nPath}.fields.${name}.title` : `reports.fields.${name}.title`);
    },
    clickMobTotal() {
      this.totalsFold = !this.totalsFold;
    },
    clickUrls(v) {
      if (this.expandUrlSelect !== v && this.expandUrl) {
        this.expandUrlSelect = v;
        this.expandUrl = !this.expandUrl;
      }
      this.expandUrlSelect = v;
      this.expandUrl = !this.expandUrl;
    },
    clickMobHead(v) {
      if (this.expandHeadSelect !== v && this.expandHead) {
        this.expandHeadSelect = v;
        this.expandHead = !this.expandHead;
      }
      this.expandHeadSelect = v;
      this.expandHead = !this.expandHead;
    },
    drilldownReport(item) {
      this.$emit('drilldown', item);
    },
    checkRow(item) {
      if (this.switchValue && this.switchItem !== item) {
        this.switchValue = !this.switchValue;
        this.switchItem = item;
      } else {
        this.switchItem = item;
      }
      if (this.switchValue) {
        this.$emit('checkRow', false);
        return;
      }
      this.$emit('checkRow', item);
    },
    linkTo(where, noAction) {
      if (!this.selectedRow) {
        return;
      }
      switch (where) {
        case 'custom':
          const urlQuery = this.$store.state.misc.urlQueries['routes.custom'] || {};
          urlQuery.openSubAffiliateReport = this.selectedRow;
          urlQuery.passedPeriod = this.period;
          if (!noAction) {
            this.$router.push({
              name: 'routes.custom',
              params: {
                urlQuery: this.compressUrlQuery(urlQuery),
              },
            });
          }
          this.$nextTick(() => this.selectedRow = null);
          return `#/dashboard/custom/${this.compressUrlQuery(urlQuery)}`;
        case 'players':
          const playerQuery = {};
          playerQuery.subAffiliateActive = true;
          playerQuery.sub_affiliate_id = this.selectedRow.data.id;
          playerQuery.query = {
            sub_affiliate_id: this.selectedRow.data.id,
            passedPeriod: this.period,
            period: this.period.period,
          };
          if (!noAction) {
            this.$router.push({
              name: 'routes.players',
              params: {
                urlQuery: this.compressUrlQuery(playerQuery),
              },
            });
          }
          this.$nextTick(() => this.selectedRow = null);
          return `#/dashboard/players/${this.compressUrlQuery(playerQuery)}`;
        case 'daily':
          const dailyQuery = {};
          dailyQuery.subAffiliateActive = true;
          dailyQuery.sub_affiliate_id = this.selectedRow.data.id;
          dailyQuery.query = {
            sub_affiliate_id: this.selectedRow.data.id,
            passedPeriod: this.period,
            period: this.period.period,
          };
          if (!noAction) {
            this.$router.push({
              name: 'routes.daily',
              params: {
                urlQuery: this.compressUrlQuery(dailyQuery),
              },
            });
          }
          this.$nextTick(() => this.selectedRow = null);
          return `#/dashboard/daily/${this.compressUrlQuery(dailyQuery)}`;
        case 'summary':
          const summaryQuery = {};
          summaryQuery.subAffiliateActive = true;
          summaryQuery.sub_affiliate_id = this.selectedRow.data.id;
          summaryQuery.query = {
            sub_affiliate_id: this.selectedRow.data.id,
            passedPeriod: this.period,
            period: this.period.period,

            // Reset previuos cache
            affiliate_offer_id: '',
            player_id: '',
            traffic_source_id: '',
            country_code: '',
            subid1: '',
            subid2: '',
            subid3: '',
            subid4: '',
            subid5: '',
          };
          if (!noAction) {
            this.$router.push({
              name: 'routes.summary',
              params: {
                urlQuery: this.compressUrlQuery(summaryQuery),
              },
            });
          }
          this.$nextTick(() => this.selectedRow = null);
          return `/dashboard/summary/${this.compressUrlQuery(summaryQuery)}`;
        default:
      }
    },
    resetPager() {
      this.page = 1;
    },
    setPaginationPositions(position) {
      position = position || (this.opts && this.opts.position);
      if (!position) return;

      const pos = position.split(' ');
      const main = (pos || [])[0];
      const secondary = (pos || [])[1];

      switch (main) {
        case 'top':
          this.paginationDimensions.position = 'top';
          break;
        case 'bottom':
          this.paginationDimensions.position = 'bottom';
          break;
        case 'both':
          this.paginationDimensions.position = 'both';
          break;
      }
      switch (secondary) {
        case 'left':
          this.paginationDimensions.justify = 'justify-content-start';
          break;
        case 'right':
          this.paginationDimensions.justify = 'justify-content-end';
          break;
        case 'center':
          this.paginationDimensions.justify = 'justify-content-center';
          break;
      }
    },
    formatColumn(v, k, row, column) {
      const formaters = this.formaters || {};

      if (['currency', 'ncurrency'].includes(column.format)) {
        return this.formaters.currency(v, undefined, row, this.currency);
      }

      return (formaters[k] && formaters[k].apply(this, [v, this.$t, row])) || v;
    },
    getTitle(k) {
      const col = this.columns.filter(e => e.name === k)[0];

      if (col) return col.title;
    },
    cancelDelete() {
      this.$refs.popover.forEach(e => e.doClose());
    },
  },
};
</script>

<style lang="scss">
@import 'src/assets/theme/default/components/mobtable';
</style>
