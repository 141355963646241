<template>
  <navbar
    :show-navbar="showNavbar"
    :transparent="false"
    class="fixed-top"
  >
    <span class="section-title">
      <span class="section-title-only-desc">{{ $t($route.name || 'routes.default') }}</span>
      <span class="section-title-mob">{{ $t($route.name || 'routes.default') }}</span>
    </span>
    <div class="navbar-wrapper">
      <div class="bord" />
      <div class="balance-small">
        <span>{{ $t('topNavbar.balance') }}</span>
      </div>
      <drop-down
        :class="{'active': $route.path === '/dashboard/payment-history'}"
        tag="li"
        position="center"
        class="balance-small"
      >
        <div
          slot="title"
          href="#"
          @click.prevent
        >
          <span class="balance-small-title">{{ $t('topNavbar.balance') }}</span>
          <p class="mb-0 balance-small-price" v-html="formatPrice($store.state.payments.balance)" />
        </div>
        <div class="money-box">
          <span class="dropdown-small__triangle" />
          <p class="text-info label">
            {{ $t('topNavbar.inHold') }}
          </p>
          <p class="money" v-html="formatPrice($store.state.payments.holdBalance)">
          </p>

          <p class="text-success label">
            {{ $t('topNavbar.available') }}
          </p>
          <p class="money" v-html="formatPrice($store.state.payments.availableBalance)">
          </p>

          <p class="text-primary label">
            {{ $t('topNavbar.pendingPayment') }}
          </p>
          <p class="money" v-html="formatPrice($store.state.payments.pendingPayment)">
          </p>

          <router-link
            to="/dashboard/payment-information"
            class="btn btn-outline-primary btn-sm btn-block"
          >
            {{ $t('topNavbar.paymentInformation') }} >
          </router-link>
        </div>
      </drop-down>
      <div
        :class="{'active': $route.path === '/dashboard/notifications'}"
        class="notifications-mobile"
      >
        <router-link
          class="nav-link notifications"
          :class="{ 'attention': $store.getters.hasUnreadNotifications }"
          to="/dashboard/notifications"
        >
          <i class="fas fa-bell"></i>
        </router-link>
      </div>
      <div
        :class="{toggled: $sidebar.showSidebar}"
        class="navbar-toggle navbar-toggle-custom"
      >
        <navbar-toggle-button @click.native="toggleSidebar" />
      </div>
    </div>
    <template slot="navbar-menu">
      <ul class="navbar-nav">
        <li
          :class="{'active': $intercom.visible}"
          class="nav-item"
          @click="toggleLivechat"
        >
          <a class="nav-link chat">
            <img class="default-icon-chat" :src="require('@/assets/theme/icon/livechat.svg')" alt>
            <img class="blue-icon-chat" :src="require('@/assets/theme/icon/livechat-blue.svg')" alt>
            <img class="orange-icon-chat" :src="require('@/assets/theme/icon/livechat-orange.svg')" alt>
            <span v-if="intercomUnreadCount" class="text-green">
              <span class="text">
                (</span>{{ intercomUnreadCount }}<span class="text">)
              </span>
            </span>
          </a>
        </li>

        <li
          :class="{'active': $route.path === '/dashboard/notifications'}"
          class="nav-item"
        >
          <router-link
            class="nav-link notifications"
            :class="{ 'attention': $store.getters.hasUnreadNotifications }"
            to="/dashboard/notifications"
          >
            <i class="fas fa-bell"></i>
          </router-link>
        </li>
        <drop-down
          :class="{'active': $route.path === '/dashboard/payment-history'}"
          tag="li"
          position="center"
          class="nav-item balance"
        >
          <a
            slot="title"
            class="nav-link stacked"
            href="#"
            @click.prevent
          >&nbsp;
            <p>{{ $t('topNavbar.balance') }}</p>
            <span v-html="formatPrice($store.state.payments.balance)" />
          </a>
          <div class="money-box">
            <p class="text-info label">
              {{ $t('topNavbar.inHold') }}
            </p>
            <p class="money">
              <span v-html="formatPrice($store.state.payments.holdBalance)" />
            </p>

            <p class="text-success label">
              {{ $t('topNavbar.available') }}
            </p>
            <p class="money">
              <span v-html="formatPrice($store.state.payments.availableBalance)" />
            </p>

            <p class="text-primary label">
              {{ $t('topNavbar.pendingPayment') }}
            </p>
            <p class="money">
              <span v-html="formatPrice($store.state.payments.pendingPayment)" />
            </p>

            <router-link
              to="/dashboard/payment-information"
              class="btn btn-outline-primary btn-sm btn-block"
            >
              {{ $t('topNavbar.paymentInformation') }} >
            </router-link>
          </div>
        </drop-down>
        <li
          :class="{'active': $route.path === '/dashboard/profile'}"
          class="nav-item"
        >
          <a
            class="nav-link profile-link"
            href="#/dashboard/profile"
          >
            <img
              :src="require('@/assets/theme/sidebar/profile.svg')"
              alt
            >
            &nbsp;
            <span
              v-if="profile"
              class="user-name"
            >{{ profile.first_name }} {{ profile.last_name }}</span>
          </a>
        </li>
        <drop-down
          tag="li"
          position="center"
          class="nav-item balance languages-navbar"
        >
          <a
            slot="title"
            class="nav-link"
            href="#"
            @click.prevent
          >
            <div class="d-flex">
              <img
                :src="flags[selectedLanguageCode]"
                height="15"
                class="mt-4 mr-2"
                v-if="flags[selectedLanguageCode]"
                :alt="selectedLanguageCode"
              />
              <span class="pl-4 text-uppercase">{{ selectedLanguageCode }}</span>
              <i class="el-icon-arrow-down ml-4 mt-4" />
            </div>
          </a>
          <ul class="lang__items pl-0">
            <li
              v-for="language in availableLanguages"
              :key="language.code"
              :class="{active: selectedLanguageCode === language.code}"
              class="d-flex justify-content-between"
              @click="changeLanguage(language.code)"
            >
              <div>
                <img
                  :src="flags[language.code]"
                  height="14"
                  class="mr-2"
                  v-if="flags[language.code]"
                  :alt="language.code"
                />
                <span href="#" class="lang__link">
                  {{ language.name }}
                </span>
              </div>
              <div class="checkbox mt-4" />
            </li>
          </ul>
        </drop-down>
        <drop-down
          tag="li"
          position="right"
          class="nav-item logout"
          style="width:60px;text-align: center;"
        >
          <a
            slot="title"
            class="nav-link"
            href="#"
            @click.prevent
          >
            <img :src="logoutIcon" :alt="$t('topNavbar.logout')" />
            <span class="text text-black">{{ $t('topNavbar.logout') }}</span>
          </a>
          <div class="logout-box">
            <h4>{{ $t('topNavbar.logout') }}</h4>
            <p>
              {{ $t('topNavbar.youSure') }}
              <br>
              {{ $t('topNavbar.wantExit') }}?
            </p>
            <a
              class="btn btn-danger btn-sm btn-block"
              href="#/logout"
              @click="cookiesRemove"
            >{{ $t('topNavbar.logout') }}</a>
          </div>
        </drop-down>
      </ul>
    </template>
  </navbar>
</template>

<script>
import { Navbar, NavbarToggleButton } from 'src/components';
import { CollapseTransition } from 'vue2-transitions';
import CookiesProvider from 'src/lib/cookieProvider.js';
import { debounce } from 'lodash';
import deFlag from '@/assets/img/de-top.png';
import enFlag from '@/assets/img/en-top.png';
import esFlag from '@/assets/img/es-top.png';
import frFlag from '@/assets/img/fr-top.png';
import heFlag from '@/assets/img/he-top.png';
import ptFlag from '@/assets/img/pt-top.png';
import ruFlag from '@/assets/img/ru-top.png';
import trFlag from '@/assets/img/tr-top.png';
import bnFlag from '@/assets/img/bn-top.png';
import logoutIcon from '@/assets/theme/icon/logout-icon.svg';
import formatCurrency from '@/mixins/format-currency';

export default {
  components: {
    Navbar,
    NavbarToggleButton,
    CollapseTransition,
  },
  mixins: [
    formatCurrency,
  ],
  data() {
    return {
      livechatOn: false,
      intercomReady: false,
      intercomUnreadCount: 0,
      activeNotifications: false,
      showNavbar: false,
      logoutIcon,
      selectedLanguageCode: localStorage.getItem('language') || 'en',
      availableLanguages: [],
      flags: {
        de: deFlag,
        en: enFlag,
        es: esFlag,
        fr: frFlag,
        he: heFlag,
        pt: ptFlag,
        ru: ruFlag,
        tr: trFlag,
        bn: bnFlag,
      },
      intervalId: null,
      notificationsInterval: null,
      campaignsAttentionInterval: null,
    };
  },
  computed: {
    currencyLabel() {
      return this.getCurrencyLabel(this.$store.state.payments.currencyCode);
    },
    routeName() {
      const { name } = this.$route;
      return this.capitalizeFirstLetter(name);
    },
    profile() {
      return this.$store.state.profile.user;
    },
  },
  created() {
    this.setAvailableLanguages();
    this.$Progress.start = debounce(this.$Progress.start.bind(this.$Progress), 220);
    const OMIT_LOADING = ['routes.custom'];
    this.$event.$on('request-start', () => {
      if (!OMIT_LOADING.includes(this.$route.name)) {
        this.$Progress.start();
      }
    });
    this.$event.$on('request-fail', () => this.$Progress.fail());
    this.$event.$on('request-end', () => this.$Progress.finish());

    if (localStorage.getItem('auth-token') !== 'null') {
      const profile = async () => await this.$store.dispatch('getProfileData')
        .then(user => user);

      const settings = async () => await this.$store.dispatch('getSettings');
      Promise.all([profile(), settings()]).then(() => {
        const user = this.$store.state.profile.user;
        const intercomSettings = this.$store.state.settings.intercom;

        this.checkRedirect();

        this.$intercom.boot({
          user_id: user.id,
          name: `${user.first_name} ${user.last_name}`,
          email: user.email,
          created_at: moment(user.created_at)
            .unix(),
          app_id: intercomSettings.app_id,
          user_hash: intercomSettings.user_hash,
        });
        this.intercomReady = true;
      }).then(() => {
        this.initCampaignAttention();
        this.initNotificationsAttention();
      });
    }
  },
  mounted() {
    this.intercomUnreadCount = this.$intercom.unreadCount;
    document.addEventListener('mousedown', this.eventSelect);
  },
  destroyed() {
    clearInterval(this.campaignsAttentionInterval);
    clearInterval(this.notificationsInterval);
    // не срабатывает
    document.removeEventListener('mousedown', this.eventSelect);
  },
  watch: {
    '$route': function() {
      this.checkRedirect()
    }
  },
  methods: {
    checkRedirect() {
      const isRouteAvailable = ({ name, path }) => {
        const mediaBuyingRoutes = [
          'routes.mediaBuying',
          'routes.mediaBuyingCampaignDetails',
          'routes.mediaBuyingCampaignDetailsSub',
          'routes.mediaBuyingPayments',
          'routes.mediaBuyingPosts',
        ]

        if (!path) {
          return true;
        }

        if (name === 'routes.domains') {
          const { count } = this.$store.state.domains.dedicatedDomainCount
          return !!count || count === 0 ;
        }

        if (name === 'routes.subAffiliates') {
          return this.$store.state.profile.user.referral_program_enabled;
        } else if (name === 'routes.subAffiliatesReports') {
          return this.$store.state.profile.user.referral_program_enabled;
        } else if (mediaBuyingRoutes.includes(name)) {
          return this.$store.state.profile.user.media_buying_enabled;
        } else if (name === 'routes.androidApps') {
          return this.$store.state.settings.optional_sections.mobile_apps_available;
        } else {
          return true;
        }
      }

      if (!isRouteAvailable(this.$route)) {
        this.$router.push({ name: '404' })
      }
    },

    initCampaignAttention() {
      if (this.$store.state.profile.user.media_buying_enabled) {
        this.$store.dispatch('getCampaignsAttentionCounters');

        this.campaignsAttentionInterval = setInterval(() => {
          if (this.$store.state.profile.user.media_buying_enabled) {
            this.$store.dispatch('getCampaignsAttentionCounters');
          }
        }, 60000);
      }
    },

    initNotificationsAttention() {
      this.$store.dispatch('getNotifications');

      this.notificationsInterval = setInterval(() => {
        this.$store.dispatch('getNotifications');
      }, 60000);

    },

    eventSelect(e) {
      $('.add-placeholder')
        .remove();

      if (e.target.classList.contains('select2-selection') || e.target.classList.contains('select2-selection__rendered')) {
        const input = document.querySelector('.select2-search__field');
        const inputWrap = document.querySelector('.select2-search');
        const placeholder = document.createElement('span');
        placeholder.innerHTML = this.$t.bind(this)('customReports.search');
        placeholder.className = 'add-placeholder';

        if (input) {
          if (!input.value) {
            setTimeout(() => {
              inputWrap.appendChild(placeholder);
            }, 0);
          }

          $('.select2-search__field')
            .on('input', () => {
              if (input.value && placeholder) {
                try {
                  if (inputWrap.contains(placeholder)) {
                    inputWrap.removeChild(placeholder);
                  }
                } catch (err) {
                  console.log(err);
                }
              } else {
                inputWrap.appendChild(placeholder);
              }
            });
        }
      }
    },
    cookiesRemove() {
      const name = 'autologin_token';
      this.$store.dispatch('logout');
      CookiesProvider.remove(name);
      clearInterval(this.intervalId);
    },
    toggleLivechat() {
      if (!this.intercomReady) {
        return;
      }

      if (!this.$store.state.settings.intercom.live_chat_available) {
        this.$router.push({ name: 'routes.contacts' });
        return;
      }

      if (!this.$intercom.visible) {
        this.$intercom.show();
        this.intercomUnreadCount = 0;
      } else {
        this.$intercom.hide();
      }
    },
    capitalizeFirstLetter(string) {
      return string.charAt(0)
        .toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    toggleNavbar() {
      this.showNavbar = !this.showNavbar;
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    setAvailableLanguages() {
      this.$store.dispatch('getAvailableLanguages')
        .then((availableLanguages) => {
          this.availableLanguages = availableLanguages;
        });
    },
    changeLanguage(code) {
      this.selectedLanguageCode = code;
      this.$locale = code;
      this.$store.dispatch('getSettingsLazy');
      this.$store.dispatch('saveProfileLanguage', { language_code: code });
    },

    formatPrice(amount) {
      const sum = this.$options.filters.currency(amount, this.currencyLabel, null, {
        symbolOnLeft: true,
        spaceBetweenAmountAndSymbol: false,
        decimalSeparator: ',',
      });

      if (Math.abs(amount) <= 0.005) {
        return `<span>${sum.replace('-', '')}</span>`;
      }

      return `<span>${sum}</span>`;
    },
  },
};
</script>

<style lang="scss" scoped>
.notifications-mobile {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 0;
  top: 0;
  border-left: 1px solid #eee;
  width: 40px;

  @media (min-width: 992px) {
    display: none;
  }


  .notifications {
    border: 0;
    padding: 0;

    &.attention::before {
      position: absolute;
      top: 2px;
      right: -4px;
      width: 4px;
      height: 4px;
    }

    i {
      font-size: 14px;
      padding: 0;
    }
  }
}
.fixed-top {
  z-index: 100000020;
}

.notifications {
  position: relative;

  &.attention::before {
    position: absolute;
    top: 12px;
    right: 16px;
    content: '';
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: var(--color-text-red);
  }
  i {
    font-size: 24px;
    padding: 0 10px;
  }
}

@import 'src/assets/theme/default/components/topnavbar';
@media (max-width: 991px) {
  .navbar-toggle-custom {
    position: relative;
    right: calc(100% - 35px);
  }
  .balance-small {
    margin-top: 0 !important;
  }
}
</style>
<style lang="scss">
#navigation {
  margin-right: -15px;
}
</style>
