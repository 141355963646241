export default {
  data() {
    return {
      isComponentActivated: false,
    };
  },
  mounted() {
    this.isComponentActivated = true;
  },
  beforeDestory() {
    this.isComponentActivated = false;
  },
  activated() {
    this.isComponentActivated = true;
  },
  deactivated() {
    this.isComponentActivated = false;
  },
  methods: {
    getMinTimoutPromise(timeout = 500) {
      return new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, timeout);
      });
    },
    afterBrowserRedraw(cb) {
      return new Promise((resolve) => {
        window.requestAnimationFrame(() => {
          window.requestAnimationFrame(() => {
            resolve();

            if (cb) {
              cb();
            }
          });
        });
      });
    },
  },
};
