export default {
  data() {
    return {
      supportedDimensions: [],
      supportedMetrics: [],
      allDimensions: [
        {
          name: 'date_formatted',
          // filterName: 'date_raw',
          type: 'datetime',
          minWidth: '120',
          // format: 'date',
          // formatOptions: {
          //   in: 'YYYY-MM-DD',
          //   out: 'DD MMM YYYY',
          // },
        },
        {
          name: 'start_of_week_formatted',
          // filterName: 'start_of_week_raw',
          type: 'datetime',
          minWidth: '120',
          // format: 'date',
          // formatOptions: {
          //   in: 'YYYY-MM-DD',
          //   out: 'DD MMM YYYY',
          // },
        },
        {
          name: 'start_of_month_formatted',
          // filterName: 'start_of_month_raw',
          type: 'datetime',
          minWidth: '126',
          // format: 'date',
          // formatOptions: {
          //   in: 'YYYY-MM-DD',
          //   out: 'MMMM YYYY',
          // },
        },
        {
          name: 'year_raw',
          // filterName: 'year_raw',
          type: 'datetime',
          minWidth: '80',
          // format: 'date',
          // formatOptions: {
          //   in: 'YYYY',
          //   out: 'YYYY',
          // },
        },
        {
          name: 'day_of_year_raw',
          // filterName: 'day_of_year_raw',
          type: 'datetime',
          minWidth: '80',
          // format: 'date',
        },
        {
          name: 'quarter_raw',
          // filterName: 'quarter_raw',
          type: 'datetime',
          minWidth: '80',
          // format: 'date',
        },
        {
          name: 'start_of_quarter_raw',
          // filterName: 'start_of_quarter_raw',
          type: 'datetime',
          minWidth: '80',
          // format: 'date',
        },
        {
          name: 'start_of_quarter_formatted',
          // filterName: 'start_of_quarter_formatted',
          type: 'datetime',
          minWidth: '80',
          // format: 'date',
        },
        {
          name: 'day_of_week_formatted',
          // filterName: 'day_of_week_raw',
          type: 'datetime',
          minWidth: '100',
          // format: 'date',
          // formatOptions: {
          //   in: 'E',
          //   out: 'dddd',
          // },
        },
        {
          name: 'day_of_month_raw',
          // filterName: 'day_of_month_raw',
          type: 'datetime',
          minWidth: '100',
          // format: 'date',
          // formatOptions: {
          //   in: 'D',
          //   out: 'Do',
          // },
        },
        {
          name: 'month_raw',
          // filterName: 'month_raw',
          type: 'datetime',
          minWidth: '120',
          // format: 'date',
          // formatOptions: {
          //   in: 'YYYY-MM-DD',
          //   out: 'DD MMM YYYY',
          // },
        },
        {
          name: 'month_formatted',
          // filterName: 'month_formatted',
          type: 'datetime',
          minWidth: '120',
          // format: 'date',
          // formatOptions: {
          //   in: 'YYYY-MM-DD',
          //   out: 'DD MMM YYYY',
          // },
        },
        {
          name: 'hour_raw',
          // filterName: 'hour_raw',
          type: 'datetime',
          minWidth: '110',
          // format: 'date',
          // formatOptions: {
          //   in: 'H',
          //   out: 'HH:mm',
          // },
        },
        {
          name: 'hour_formatted',
          // filterName: 'hour_raw',
          type: 'datetime',
          minWidth: '110',
          // format: 'date',
          // formatOptions: {
          //   in: 'H',
          //   out: 'HH:mm',
          // },
        },
        {
          name: 'referred_email',
          // filterName: 'sub_affiliate_id',
          type: 'affiliate',
          minWidth: '180',
        },
        {
          name: 'sub_affiliate_email',
          // filterName: 'affiliate_id',
          type: 'affiliate',
          minWidth: '180',
        },
        {
          name: 'affiliate_offer_name',
          // filterName: 'affiliate_offer_id',
          type: 'offer',
          minWidth: '180',
        },
        {
          name: 'affiliate_offer_settings_name',
          // filterName: 'affiliate_offer_settings_id',
          type: 'offer',
          minWidth: '150',
        },
        {
          name: 'offer_type_name',
          // filterName: 'offer_type',
          type: 'offer',
          minWidth: '150',
        },
        {
          name: 'site_name',
          // filterName: 'site_id',
          type: 'offer',
          minWidth: '150',
        },
        {
          name: 'traffic_source_name',
          // filterName: 'traffic_source_id',
          type: 'promotion',
          minWidth: '130',
        },
        {
          name: 'bet_category_name',
          // filterName: 'bet_category_id',
          type: 'player_activity',
          minWidth: '130',
        },
        {
          name: 'bet_game_name',
          // filterName: 'bet_game_id',
          type: 'player_activity',
          minWidth: '130',
        },
        {
          name: 'site_player_id',
          // filterName: 'player_id',
          type: 'player',
          minWidth: '130',
        },
        {
          name: 'player_region_name',
          // filterName: 'player_region_name',
          type: 'player',
          minWidth: '130',
        },
        {
          name: 'player_country_code',
          // filterName: 'player_country_code',
          type: 'player',
          minWidth: '130',
        },
        {
          name: 'player_country_name',
          // filterName: 'player_country_name',
          type: 'player',
          minWidth: '130',
        },
        {
          name: 'site_registration_type_name',
          // filterName: 'site_registration_type_id',
          type: 'player',
          minWidth: '130',
        },
        {
          name: 'media_buying_campaign_name',
          // filterName: 'media_buying_campaign_id',
          type: 'media_buying',
          minWidth: '130',
        },
        {
          name: 'media_buying_channel_name',
          // filterName: 'media_buying_channel_id',
          type: 'media_buying',
          minWidth: '130',
        },
        {
          name: 'affiliate_player_promo_code_name',
          // filterName: 'affiliate_player_promo_code_id',
          type: 'promotion',
          minWidth: '130',
        },
        {
          name: 'marketing_material_name',
          // filterName: 'marketing_material_id',
          type: 'promotion',
          minWidth: '130',
        },
        {
          name: 'landing_name',
          // filterName: 'landing_id',
          type: 'promotion',
          minWidth: '130',
        },
        {
          name: 'region_name',
          // filterName: 'region_name',
          type: 'traffic',
          minWidth: '120',
        },
        {
          name: 'country_name',
          // filterName: 'country_name',
          type: 'traffic',
          minWidth: '110',
        },
        {
          name: 'country_code',
          // filterName: 'country_code',
          type: 'traffic',
          minWidth: '110',
        },
        {
          name: 'state_name',
          // filterName: 'state_name',
          type: 'traffic',
          minWidth: '120',
        },
        {
          name: 'city_name',
          // filterName: 'city_name',
          type: 'traffic',
          minWidth: '120',
        },
        {
          name: 'referrer',
          // filterName: 'referrer',
          type: 'referrer',
          minWidth: '110',
        },
        {
          name: 'referrer_url',
          // filterName: 'referrer_url',
          type: 'promotion',
          minWidth: '110',
        },
        {
          name: 'referrer_domain',
          // filterName: 'referrer_domain',
          type: 'promotion',
          minWidth: '140',
          class: 'link',
        },
        {
          name: 'device_type_name',
          // filterName: 'device_type_id',
          type: 'traffic',
          minWidth: '120',
        },
        {
          name: 'device_vendor_name',
          // filterName: 'device_vendor_name',
          type: 'traffic',
          minWidth: '130',
        },
        {
          name: 'device_model_name',
          // filterName: 'device_model_name',
          type: 'traffic',
          minWidth: '150',
        },
        {
          name: 'browser_name',
          // filterName: 'browser_name',
          type: 'traffic',
          minWidth: '150',
        },
        {
          name: 'browser_name_with_version',
          // filterName: 'browser_name_with_version',
          type: 'traffic',
          minWidth: '150',
        },
        {
          name: 'language_code',
          // filterName: 'language_code',
          type: 'traffic',
          minWidth: '110',
        },
        {
          name: 'language_name',
          // filterName: 'language_name',
          type: 'traffic',
          minWidth: '110',
        },
        {
          name: 'os_name',
          // filterName: 'os_name',
          type: 'traffic',
          minWidth: '100',
        },
        {
          name: 'os_name_with_version',
          // filterName: 'os_name_with_version',
          type: 'traffic',
          minWidth: '120',
        },
        {
          name: 'isp_name',
          // filterName: 'isp_name',
          type: 'traffic',
          minWidth: '180',
        },
        {
          name: 'connection_type_name',
          // filterName: 'connection_type_id',
          type: 'traffic',
          minWidth: '140',
        },
        {
          name: 'external_id',
          // filterName: 'external_id',
          type: 'subid',
          minWidth: '130',
          filterable: true,
          sortable: true,
        },
        {
          name: 'subid1',
          // filterName: 'subid1',
          type: 'subs',
          minWidth: '110',
        },
        {
          name: 'subid2',
          // filterName: 'subid2',
          type: 'subs',
          minWidth: '110',
        },
        {
          name: 'subid3',
          // filterName: 'subid3',
          type: 'subs',
          minWidth: '110',
        },
        {
          name: 'subid4',
          // filterName: 'subid4',
          type: 'subs',
          minWidth: '110',
        },
        {
          name: 'subid5',
          // filterName: 'subid5',
          type: 'subs',
          minWidth: '110',
        },
        {
          name: 'subid6',
          // filterName: 'subid6',
          type: 'subs',
          minWidth: '110',
        },
        {
          name: 'subid7',
          // filterName: 'subid7',
          type: 'subs',
          minWidth: '110',
        },
        {
          name: 'subid8',
          // filterName: 'subid8',
          type: 'subs',
          minWidth: '110',
        },
        {
          name: 'subid9',
          // filterName: 'subid9',
          type: 'subs',
          minWidth: '110',
        },
        {
          name: 'subid10',
          // filterName: 'subid10',
          type: 'subs',
          minWidth: '110',
        },
      ],
      allMetrics: [
        {
          name: 'affiliate_created_at',
          minWidth: '144',
          format: 'datetime',
          defaultOrder: 142,
          order: 142,
          filterable: false,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'referred_affiliate_created_at',
          minWidth: '144',
          format: 'datetime',
          defaultOrder: 140,
          order: 140,
          filterable: false,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'referred_created_at',
          minWidth: '144',
          format: 'format-date',
          defaultOrder: 141,
          order: 141,
          filterable: false,
          selected: false,
          shadowSelected: false,
          formatOptions: {
            in: 'YYYY-MM-DD',
            out: 'DD MMM YYYY',
          },
        },
        {
          name: 'player_created_at',
          minWidth: '144',
          format: 'date',
          defaultOrder: 143,
          order: 143,
          filterable: true,
          selected: false,
          shadowSelected: false,
          formatOptions: {
            in: 'YYYY-MM-DD',
            out: 'DD MMM YYYY',
          },
        },
        {
          name: 'external_id',
          minWidth: '144',
          format: 'string',
          defaultOrder: 144,
          order: 144,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'subid1',
          minWidth: '144',
          format: 'string',
          defaultOrder: 145,
          order: 145,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'subid2',
          minWidth: '144',
          format: 'string',
          defaultOrder: 146,
          order: 146,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'subid3',
          minWidth: '144',
          format: 'string',
          defaultOrder: 147,
          order: 147,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'subid4',
          minWidth: '144',
          format: 'string',
          defaultOrder: 148,
          order: 148,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'subid5',
          minWidth: '144',
          format: 'string',
          defaultOrder: 149,
          order: 149,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'email',
          minWidth: '144',
          format: 'string',
          defaultOrder: 150,
          order: 150,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'first_name',
          minWidth: '144',
          format: 'string',
          defaultOrder: 151,
          order: 151,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'last_name',
          minWidth: '144',
          format: 'string',
          defaultOrder: 152,
          order: 152,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'phone_number',
          minWidth: '144',
          format: 'string',
          defaultOrder: 153,
          order: 153,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'impressions_count',
          minWidth: '114',
          format: 'number',
          defaultOrder: 0,
          order: 0,
          filterable: true,
          selected: true,
          shadowSelected: true,
          group: 'raising',
        },
        {
          name: 'impressions_uniq_count',
          minWidth: '144',
          format: 'number',
          defaultOrder: 3,
          order: 3,
          filterable: true,
          selected: false,
          shadowSelected: false,
          group: 'raising',
        },
        {
          name: 'visits_count',
          minWidth: '100',
          format: 'number',
          defaultOrder: 4,
          order: 4,
          filterable: true,
          selected: true,
          shadowSelected: true,
          group: 'raising',
        },
        {
          name: 'visits_uniq_count',
          minWidth: '130',
          format: 'number',
          defaultOrder: 5,
          order: 5,
          filterable: true,
          selected: false,
          shadowSelected: false,
          group: 'raising',
        },
        {
          name: 'registrations_count',
          minWidth: '120',
          format: 'number',
          defaultOrder: 6,
          order: 6,
          filterable: true,
          selected: true,
          shadowSelected: true,
          group: 'raising',
        },
        {
          name: 'visits_per_impression_percent',
          minWidth: '100',
          format: 'percent',
          defaultOrder: 50,
          order: 50,
          filterable: true,
          selected: false,
          shadowSelected: false,
          group: 'raising',
        },
        {
          name: 'registrations_per_visit_percent',
          minWidth: '100',
          format: 'percent',
          defaultOrder: 51,
          order: 51,
          filterable: true,
          selected: false,
          shadowSelected: false,
          group: 'raising',
        },
        {
          name: 'deposits_first_count',
          minWidth: '120',
          format: 'number',
          defaultOrder: 7,
          order: 7,
          filterable: true,
          selected: true,
          shadowSelected: true,
          group: 'depositsWithdrawals',
        },
        {
          name: 'deposits_first_cpa_count',
          minWidth: '144',
          format: 'number',
          defaultOrder: 102,
          order: 102,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'deposits_first_revshare_count',
          minWidth: '144',
          format: 'number',
          defaultOrder: 106,
          order: 106,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'deposits_first_no_revenue_count',
          minWidth: '144',
          format: 'number',
          defaultOrder: 104,
          order: 104,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'deposits_first_per_registration_percent',
          minWidth: '100',
          format: 'percent',
          defaultOrder: 52,
          order: 52,
          filterable: true,
          selected: false,
          shadowSelected: false,
          group: 'depositsWithdrawals',
        },
        {
          name: 'deposits_first_per_visit_percent',
          minWidth: '100',
          format: 'percent',
          defaultOrder: 53,
          order: 53,
          filterable: true,
          selected: false,
          shadowSelected: false,
          group: 'depositsWithdrawals',
        },
        {
          name: 'deposits_first_avg',
          minWidth: '150',
          format: 'ncurrency',
          defaultOrder: 41,
          order: 41,
          filterable: true,
          selected: true,
          shadowSelected: true,
          group: 'depositsWithdrawals',
        },
        {
          name: 'deposits_first_median',
          minWidth: '154',
          format: 'ncurrency',
          defaultOrder: 44,
          order: 44,
          filterable: true,
          selected: false,
          shadowSelected: false,
          group: 'depositsWithdrawals',
        },
        {
          name: 'deposits_first_sum',
          minWidth: '130',
          format: 'ncurrency',
          // class: 'positive',
          defaultOrder: 11,
          order: 11,
          filterable: true,
          selected: false,
          shadowSelected: false,
          group: 'depositsWithdrawals',
        },
        {
          name: 'deposits_first_cpa_sum',
          minWidth: '144',
          format: 'ncurrency',
          defaultOrder: 103,
          order: 103,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'deposits_first_revshare_sum',
          minWidth: '144',
          format: 'ncurrency',
          defaultOrder: 107,
          order: 107,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'deposits_first_no_revenue_sum',
          minWidth: '144',
          format: 'ncurrency',
          defaultOrder: 105,
          order: 105,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'deposits_other_count',
          minWidth: '130',
          format: 'number',
          defaultOrder: 8,
          order: 8,
          filterable: true,
          selected: false,
          shadowSelected: false,
          group: 'depositsWithdrawals',
        },
        {
          name: 'deposits_other_cpa_count',
          minWidth: '144',
          format: 'number',
          defaultOrder: 109,
          order: 109,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'deposits_other_revshare_count',
          minWidth: '144',
          format: 'number',
          defaultOrder: 113,
          order: 113,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'deposits_other_no_revenue_count',
          minWidth: '144',
          format: 'number',
          defaultOrder: 111,
          order: 111,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'deposits_other_avg',
          minWidth: '160',
          format: 'ncurrency',
          defaultOrder: 42,
          order: 42,
          filterable: true,
          selected: false,
          shadowSelected: false,
          group: 'depositsWithdrawals',
        },
        {
          name: 'deposits_other_median',
          minWidth: '160',
          format: 'ncurrency',
          defaultOrder: 45,
          order: 45,
          filterable: true,
          selected: false,
          shadowSelected: false,
          group: 'depositsWithdrawals',
        },
        {
          name: 'deposits_other_sum',
          minWidth: '130',
          format: 'ncurrency',
          // class: 'positive',
          defaultOrder: 12,
          order: 12,
          filterable: true,
          selected: false,
          shadowSelected: false,
          group: 'depositsWithdrawals',
        },
        {
          name: 'deposits_other_cpa_sum',
          minWidth: '144',
          format: 'ncurrency',
          defaultOrder: 110,
          order: 110,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'deposits_other_revshare_sum',
          minWidth: '144',
          format: 'ncurrency',
          defaultOrder: 114,
          order: 114,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'deposits_other_no_revenue_sum',
          minWidth: '144',
          format: 'ncurrency',
          defaultOrder: 112,
          order: 112,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'deposits_all_count',
          minWidth: '120',
          format: 'number',
          defaultOrder: 9,
          order: 9,
          filterable: true,
          selected: true,
          shadowSelected: true,
          group: 'depositsWithdrawals',
        },
        {
          name: 'deposits_all_cpa_count',
          minWidth: '144',
          format: 'number',
          defaultOrder: 96,
          order: 96,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'deposits_all_revshare_count',
          minWidth: '144',
          format: 'number',
          defaultOrder: 100,
          order: 100,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'deposits_all_no_revenue_count',
          minWidth: '144',
          format: 'number',
          defaultOrder: 98,
          order: 98,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'deposits_all_avg',
          minWidth: '124',
          format: 'ncurrency',
          defaultOrder: 43,
          order: 43,
          filterable: true,
          selected: true,
          shadowSelected: true,
          group: 'depositsWithdrawals',
        },
        {
          name: 'deposits_all_median',
          minWidth: '126',
          format: 'ncurrency',
          defaultOrder: 46,
          order: 46,
          filterable: true,
          selected: false,
          shadowSelected: false,
          group: 'depositsWithdrawals',
        },
        {
          name: 'deposits_all_sum',
          minWidth: '120',
          format: 'ncurrency',
          class: 'positive',
          defaultOrder: 13,
          order: 13,
          filterable: true,
          selected: true,
          shadowSelected: true,
          group: 'depositsWithdrawals',
        },
        {
          name: 'deposits_all_cpa_sum',
          minWidth: '144',
          format: 'ncurrency',
          defaultOrder: 97,
          order: 97,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'deposits_all_revshare_sum',
          minWidth: '144',
          format: 'ncurrency',
          defaultOrder: 101,
          order: 101,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'deposits_all_no_revenue_sum',
          minWidth: '144',
          format: 'ncurrency',
          defaultOrder: 99,
          order: 99,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'deposits_withdrawals_count',
          minWidth: '134',
          format: 'number',
          defaultOrder: 10,
          order: 10,
          filterable: true,
          selected: false,
          shadowSelected: false,
          group: 'depositsWithdrawals',
        },
        {
          name: 'deposits_withdrawals_cpa_count',
          minWidth: '144',
          format: 'number',
          defaultOrder: 115,
          order: 115,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'deposits_withdrawals_revshare_count',
          minWidth: '144',
          format: 'number',
          defaultOrder: 119,
          order: 119,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'deposits_withdrawals_no_revenue_count',
          minWidth: '144',
          format: 'number',
          defaultOrder: 117,
          order: 117,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'deposits_withdrawals_sum',
          minWidth: '134',
          format: 'ncurrency',
          class: 'negative',
          defaultOrder: 14,
          order: 14,
          filterable: true,
          selected: true,
          shadowSelected: true,
          group: 'depositsWithdrawals',
        },
        {
          name: 'deposits_withdrawals_cpa_sum',
          minWidth: '144',
          format: 'ncurrency',
          defaultOrder: 116,
          order: 116,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'deposits_withdrawals_revshare_sum',
          minWidth: '144',
          format: 'ncurrency',
          defaultOrder: 120,
          order: 120,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'deposits_withdrawals_no_revenue_sum',
          minWidth: '144',
          format: 'ncurrency',
          defaultOrder: 118,
          order: 118,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'deposits_flow_sum',
          minWidth: '144',
          format: 'ncurrency',
          defaultOrder: 108,
          order: 108,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        // приходит с апи, но не добавлено в таблицу
        // {
        //   name: 'deposits_withdrawals_diff_sum',
        //   minWidth: '144',
        //   format: 'ncurrency',
        //   defaultOrder: 108,
        //   order: 108,
        //   filterable: true,
        //   selected: false,
        //   shadowSelected: false,
        // },
        {
          name: 'deposit_bonus_count',
          minWidth: '150',
          format: 'number',
          defaultOrder: 57,
          order: 57,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'deposit_bonus_sum',
          minWidth: '160',
          format: 'ncurrency',
          defaultOrder: 56,
          order: 56,
          filterable: true,
          // computedClass: this.getCurrencyClass,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 're_deposits_percent',
          minWidth: '130',
          format: 'percent',
          defaultOrder: 17,
          order: 17,
          filterable: true,
          selected: false,
          shadowSelected: false,
          group: 'depositsWithdrawals',
        },
        {
          name: 'players_with_first_deposit_count',
          minWidth: '140',
          format: 'number',
          defaultOrder: 15,
          order: 15,
          filterable: true,
          selected: false,
          shadowSelected: false,
          group: 'depositsWithdrawals',
        },
        {
          name: 'players_with_deposit_count',
          minWidth: '154',
          format: 'number',
          defaultOrder: 16,
          order: 16,
          filterable: true,
          selected: false,
          shadowSelected: false,
          group: 'depositsWithdrawals',
        },
        {
          name: 'bet_new_count',
          minWidth: '110',
          format: 'number',
          defaultOrder: 18,
          order: 18,
          filterable: true,
          selected: false,
          shadowSelected: false,
          group: 'betsResults',
        },
        {
          name: 'bet_new_sports_count',
          minWidth: '126',
          format: 'number',
          defaultOrder: 19,
          order: 19,
          filterable: true,
          selected: false,
          shadowSelected: false,
          group: 'betsResults',
        },
        {
          name: 'bet_new_games_count',
          minWidth: '134',
          format: 'number',
          defaultOrder: 20,
          order: 20,
          filterable: true,
          selected: false,
          shadowSelected: false,
          group: 'betsResults',
        },
        {
          name: 'bet_new_sum',
          minWidth: '110',
          format: 'ncurrency',
          // computedClass: this.getCurrencyClass,
          defaultOrder: 27,
          order: 27,
          filterable: true,
          selected: false,
          shadowSelected: false,
          group: 'betsResults',
        },
        {
          name: 'avg_bet_amount',
          minWidth: '144',
          format: 'ncurrency',
          defaultOrder: 63,
          order: 63,
          filterable: true,
          selected: false,
          shadowSelected: false,
          group: 'betsResults',
        },
        {
          name: 'median_bet_amount',
          minWidth: '144',
          format: 'ncurrency',
          defaultOrder: 62,
          order: 62,
          filterable: true,
          selected: false,
          shadowSelected: false,
          group: 'betsResults',
        },
        {
          name: 'avg_bet_odd',
          minWidth: '130',
          format: 'number',
          defaultOrder: 1,
          order: 1,
          filterable: true,
          computedValue(row) {
            return row.avg_bet_odd === null ? 0 : row.avg_bet_odd;
          },
          selected: true,
          shadowSelected: true,
          group: 'betsResults',
        },
        {
          name: 'median_bet_odd',
          minWidth: '130',
          format: 'number',
          defaultOrder: 2,
          order: 2,
          filterable: true,
          selected: false,
          shadowSelected: false,
          group: 'betsResults',
        },
        {
          name: 'bet_new_sports_sum',
          minWidth: '124',
          format: 'ncurrency',
          // computedClass: this.getCurrencyClass,
          defaultOrder: 28,
          order: 28,
          filterable: true,
          selected: false,
          shadowSelected: false,
          group: 'betsResults',
        },
        {
          name: 'avg_sports_bet_amount',
          minWidth: '144',
          format: 'ncurrency',
          defaultOrder: 64,
          order: 64,
          filterable: true,
          selected: false,
          shadowSelected: false,
          group: 'betsResults',
        },
        {
          name: 'median_sports_bet_amount',
          minWidth: '144',
          format: 'ncurrency',
          defaultOrder: 65,
          order: 65,
          filterable: true,
          selected: false,
          shadowSelected: false,
          group: 'betsResults',
        },
        {
          name: 'bet_new_games_sum',
          minWidth: '130',
          format: 'ncurrency',
          // computedClass: this.getCurrencyClass,
          defaultOrder: 29,
          order: 29,
          filterable: true,
          selected: false,
          shadowSelected: false,
          group: 'betsResults',
        },
        {
          name: 'avg_games_bet_amount',
          minWidth: '144',
          format: 'ncurrency',
          defaultOrder: 66,
          order: 66,
          filterable: true,
          selected: false,
          shadowSelected: false,
          group: 'betsResults',
        },
        {
          name: 'median_games_bet_amount',
          minWidth: '144',
          format: 'ncurrency',
          defaultOrder: 67,
          order: 67,
          filterable: true,
          selected: false,
          shadowSelected: false,
          group: 'betsResults',
        },
        {
          name: 'bet_result_count',
          minWidth: '120',
          format: 'number',
          defaultOrder: 21,
          order: 21,
          filterable: true,
          selected: false,
          shadowSelected: false,
          group: 'betsResults',
        },
        {
          name: 'bet_result_sum',
          minWidth: '150',
          format: 'ncurrency',
          // computedClass: this.getCurrencyClass,
          defaultOrder: 30,
          order: 30,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'bet_result_cpa_sum',
          minWidth: '144',
          format: 'ncurrency',
          defaultOrder: 75,
          order: 75,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'bet_result_revshare_sum',
          minWidth: '144',
          format: 'ncurrency',
          defaultOrder: 86,
          order: 86,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'bet_result_no_revenue_sum',
          minWidth: '144',
          format: 'ncurrency',
          defaultOrder: 82,
          order: 82,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'bet_result_sports_count',
          minWidth: '140',
          format: 'number',
          defaultOrder: 22,
          order: 22,
          filterable: true,
          selected: true,
          shadowSelected: true,
          group: 'betsResults',
        },
        {
          name: 'bet_result_sports_sum',
          minWidth: '126',
          format: 'ncurrency',
          // computedClass: this.getCurrencyClass,
          defaultOrder: 31,
          order: 31,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'bet_result_sports_cpa_sum',
          minWidth: '144',
          format: 'ncurrency',
          defaultOrder: 87,
          order: 87,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'bet_result_sports_revshare_sum',
          minWidth: '144',
          format: 'ncurrency',
          defaultOrder: 92,
          order: 92,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'bet_result_sports_no_revenue_sum',
          minWidth: '144',
          format: 'ncurrency',
          defaultOrder: 88,
          order: 88,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'bet_result_games_count',
          minWidth: '146',
          format: 'number',
          defaultOrder: 23,
          order: 23,
          filterable: true,
          selected: true,
          shadowSelected: true,
          group: 'betsResults',
        },
        {
          name: 'bet_result_games_sum',
          minWidth: '132',
          format: 'ncurrency',
          // computedClass: this.getCurrencyClass,
          defaultOrder: 32,
          order: 32,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'bet_result_games_cpa_sum',
          minWidth: '144',
          format: 'ncurrency',
          defaultOrder: 76,
          order: 76,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'bet_result_games_revshare_sum',
          minWidth: '144',
          format: 'ncurrency',
          defaultOrder: 81,
          order: 81,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'bet_result_games_no_revenue_sum',
          minWidth: '144',
          format: 'ncurrency',
          defaultOrder: 77,
          order: 77,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'bet_result_sports_profit_sum',
          minWidth: '156',
          format: 'ncurrency',
          // computedClass: this.getCurrencyClass,
          defaultOrder: 48,
          order: 48,
          filterable: true,
          selected: true,
          shadowSelected: true,
        },
        {
          name: 'bet_result_sports_profit_cpa_sum',
          minWidth: '144',
          format: 'ncurrency',
          defaultOrder: 89,
          order: 89,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'bet_result_sports_profit_revshare_sum',
          minWidth: '144',
          format: 'ncurrency',
          defaultOrder: 91,
          order: 91,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'bet_result_sports_profit_no_revenue_sum',
          minWidth: '144',
          format: 'ncurrency',
          defaultOrder: 90,
          order: 90,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'bet_result_games_profit_sum',
          minWidth: '164',
          format: 'ncurrency',
          // computedClass: this.getCurrencyClass,
          defaultOrder: 49,
          order: 49,
          filterable: true,
          selected: true,
          shadowSelected: true,
        },
        {
          name: 'bet_result_games_profit_cpa_sum',
          minWidth: '144',
          format: 'ncurrency',
          defaultOrder: 78,
          order: 78,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'bet_result_games_profit_revshare_sum',
          minWidth: '144',
          format: 'ncurrency',
          defaultOrder: 80,
          order: 80,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'bet_result_games_profit_no_revenue_sum',
          minWidth: '144',
          format: 'ncurrency',
          defaultOrder: 79,
          order: 79,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'bet_result_profit_sum',
          minWidth: '140',
          format: 'ncurrency',
          // computedClass: this.getCurrencyClass,
          defaultOrder: 47,
          order: 47,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'bet_result_profit_cpa_sum',
          minWidth: '144',
          format: 'ncurrency',
          defaultOrder: 83,
          order: 83,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'bet_result_profit_revshare_sum',
          minWidth: '144',
          format: 'ncurrency',
          defaultOrder: 85,
          order: 85,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'bet_result_profit_no_revenue_sum',
          minWidth: '144',
          format: 'ncurrency',
          defaultOrder: 84,
          order: 84,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'bet_sell_count',
          minWidth: '100',
          format: 'number',
          defaultOrder: 24,
          order: 24,
          filterable: true,
          selected: false,
          shadowSelected: false,
          group: 'betsResults',
        },
        {
          name: 'bet_sell_profit_sum',
          minWidth: '124',
          format: 'ncurrency',
          // computedClass: this.getCurrencyClass,
          defaultOrder: 33,
          order: 33,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'bet_sell_profit_cpa_sum',
          minWidth: '144',
          format: 'ncurrency',
          defaultOrder: 93,
          order: 93,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'bet_sell_profit_revshare_sum',
          minWidth: '144',
          format: 'ncurrency',
          defaultOrder: 95,
          order: 95,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'bet_sell_profit_no_revenue_sum',
          minWidth: '144',
          format: 'ncurrency',
          defaultOrder: 94,
          order: 94,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'bet_insurance_count',
          minWidth: '124',
          format: 'number',
          defaultOrder: 25,
          order: 25,
          filterable: true,
          selected: false,
          shadowSelected: false,
          group: 'betsResults',
        },
        {
          name: 'bet_insurance_sum',
          minWidth: '126',
          format: 'ncurrency',
          // computedClass: this.getCurrencyClass,
          defaultOrder: 34,
          order: 34,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'bet_insurance_result_count',
          minWidth: '124',
          format: 'number',
          defaultOrder: 26,
          order: 26,
          filterable: true,
          selected: false,
          shadowSelected: false,
          group: 'betsResults',
        },
        {
          name: 'bet_insurance_result_sum',
          minWidth: '126',
          format: 'ncurrency',
          // computedClass: this.getCurrencyClass,
          defaultOrder: 35,
          order: 35,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'bet_insurance_result_cpa_sum',
          minWidth: '144',
          format: 'ncurrency',
          defaultOrder: 72,
          order: 72,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'bet_insurance_result_revshare_sum',
          minWidth: '144',
          format: 'ncurrency',
          defaultOrder: 74,
          order: 74,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'bet_insurance_result_no_revenue_sum',
          minWidth: '144',
          format: 'ncurrency',
          defaultOrder: 73,
          order: 73,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'players_with_bet_count',
          minWidth: '134',
          format: 'number',
          defaultOrder: 36,
          order: 36,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'profit_sum',
          minWidth: '100',
          format: 'ncurrency',
          computedClass: this.getCurrencyClass,
          defaultOrder: 37,
          order: 37,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'profit_cpa_sum',
          minWidth: '144',
          format: 'ncurrency',
          defaultOrder: 134,
          order: 134,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'profit_revshare_sum',
          minWidth: '144',
          format: 'ncurrency',
          defaultOrder: 136,
          order: 136,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'profit_no_revenue_sum',
          minWidth: '144',
          format: 'ncurrency',
          defaultOrder: 135,
          order: 135,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'new_players_profit_sum',
          minWidth: '144',
          format: 'ncurrency',
          defaultOrder: 68,
          order: 68,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'new_players_profit_cpa_sum',
          minWidth: '144',
          format: 'ncurrency',
          defaultOrder: 127,
          order: 127,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'new_players_profit_revshare_sum',
          minWidth: '144',
          format: 'ncurrency',
          defaultOrder: 129,
          order: 129,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'new_players_profit_no_revenue_sum',
          minWidth: '144',
          format: 'ncurrency',
          defaultOrder: 128,
          order: 128,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'players_uniq_count',
          minWidth: '134',
          format: 'number',
          defaultOrder: 58,
          order: 58,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'affiliates_uniq_count',
          minWidth: '144',
          format: 'number',
          defaultOrder: 59,
          order: 59,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'revenue_sum',
          minWidth: '114',
          format: 'ncurrency',
          // computedClass: this.getCurrencyClass,
          defaultOrder: 38,
          order: 38,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'revenue_cpa_sum',
          minWidth: '144',
          format: 'ncurrency',
          defaultOrder: 137,
          order: 137,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'revenue_revshare_sum',
          minWidth: '144',
          format: 'ncurrency',
          defaultOrder: 139,
          order: 139,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'chargebacks_count',
          minWidth: '144',
          format: 'number',
          defaultOrder: 60,
          order: 60,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'chargebacks_players_count',
          minWidth: '144',
          format: 'number',
          defaultOrder: 61,
          order: 61,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'chargebacks_sum',
          minWidth: '136',
          format: 'ncurrency',
          class: 'negative',
          defaultOrder: 40,
          order: 40,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'affiliate_program_profit_sum',
          minWidth: '140',
          format: 'ncurrency',
          // computedClass: this.getCurrencyClass,
          defaultOrder: 39,
          order: 39,
          filterable: true,
          selected: true,
          shadowSelected: true,
        },
        {
          name: 'affiliate_program_profit_cpa_sum',
          minWidth: '144',
          format: 'ncurrency',
          defaultOrder: 69,
          order: 69,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'affiliate_program_profit_revshare_sum',
          minWidth: '144',
          format: 'ncurrency',
          defaultOrder: 71,
          order: 71,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'affiliate_program_profit_no_revenue_sum',
          minWidth: '144',
          format: 'ncurrency',
          defaultOrder: 70,
          order: 70,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'new_players_revenue_sum',
          minWidth: '144',
          format: 'ncurrency',
          defaultOrder: 133,
          order: 133,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'new_players_revenue_cpa_sum',
          minWidth: '144',
          format: 'ncurrency',
          defaultOrder: 130,
          order: 130,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'new_players_revenue_revshare_sum',
          minWidth: '144',
          format: 'ncurrency',
          defaultOrder: 132,
          order: 132,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'new_players_chargebacks_count',
          minWidth: '144',
          format: 'number',
          defaultOrder: 125,
          order: 125,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'new_players_chargebacks_sum',
          minWidth: '144',
          format: 'ncurrency',
          defaultOrder: 126,
          order: 126,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'new_players_affiliate_program_profit_sum',
          minWidth: '144',
          format: 'ncurrency',
          defaultOrder: 124,
          order: 124,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'new_players_affiliate_program_profit_cpa_sum',
          minWidth: '144',
          format: 'ncurrency',
          defaultOrder: 121,
          order: 121,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'new_players_affiliate_program_profit_revshare_sum',
          minWidth: '144',
          format: 'ncurrency',
          defaultOrder: 123,
          order: 123,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'new_players_affiliate_program_profit_no_revenue_sum',
          minWidth: '144',
          format: 'ncurrency',
          defaultOrder: 122,
          order: 122,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        // две группировки ниже тоже под вопросом - не приходят с апи
        {
          name: 'median_player_profit_category',
          minWidth: '150',
          format: 'number',
          defaultOrder: 54,
          order: 54,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'avg_player_profit_category',
          minWidth: '150',
          format: 'number',
          defaultOrder: 55,
          order: 55,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        // не приходят с апи
        {
          name: 'new_players_revenue_no_revenue_sum',
          minWidth: '144',
          format: 'ncurrency',
          defaultOrder: 131,
          order: 131,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'revenue_no_revenue_sum',
          minWidth: '144',
          format: 'ncurrency',
          defaultOrder: 138,
          order: 138,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
        {
          name: 'commissions_sum',
          minWidth: '134',
          format: 'ncurrency',
          defaultOrder: 155,
          order: 155,
          filterable: true,
          selected: false,
          shadowSelected: false,
        },
      ],
    };
  },
};
