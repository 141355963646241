<template>
  <div class="toolbar tabs">
    <div class="wrapper">
      <div class="inner">
        <draggable
          :list="reports"
          v-bind="dragOptions"
          draggable=".tab__report"
          class="inner-wrap"
          @change="draggable"
          @start="draggableStart"
          @end="draggableEnd"
        >
          <div
            ref="tab"
            v-for="(report, index) in reports"
            :key="index"
            :class="{
              active : currentReport === index,
              'tab__report': index !== 0 && !(tabCountVisible !== null && tabCountVisible < index),
              'hidden-tab': tabCountVisible !== null && tabCountVisible < index
            }"
            class="tab"
            @mousedown.middle="index ? removeReport(index, $event) : () => {}"
            @click="selectReport(index)"
          >
            <div class="report_name">
              <span class="field">
                <i
                  v-if="reportsMeta[index].firstFilterValue.op == '!='"
                  class="fas fa-page-break"
                  style="font-size: 11px;"
                />
                <i
                  v-else-if="reportsMeta[index].firstFilterValue.op == '='"
                  class="far fa-random"
                  style="font-size: 11px;"
                />
                {{ reportsMeta[index].isMediaBuying ? $t('customReports.mb') : '' }}
                {{
                  index === 0
                    ? $t('customReports.home')
                    : `${(allDimensionHash[reportsMeta[index].firstFilterKey] || {}).title}:`
                }}
              </span>
              <span class="value d-flex align-items-center">
                <el-tooltip
                  :content="`${report.defaultFilterName || ''}`"
                  :disabled="arrayWidthItemForTooltip[index] < 149"
                  placement="bottom"
                  :open-delay="500"
                >
                  <span ref="valueText" class="value value-text">
                    {{ report.defaultFilterName }}
                  </span>
                </el-tooltip>
                <el-dropdown v-if="index !== 0" trigger="click" placement="bottom-end">
                  <span class="dropdown-reports-span">
                    {{ reportsMeta[index].firstFilterValue.selectIdsLength }}
                  </span>
                  <el-dropdown-menu slot="dropdown" class="dropdown-reports">
                    <el-dropdown-item
                      class="d-flex justify-content-between dropdown-item-drill"
                      v-for="(row, key) in reportsMeta[index].firstFilterValue.selectedRowList"
                      :key="key"
                    >
                      <div>
                        {{row.rowTooltip }}
                      </div>
                      <span class="cross-close"
                            @click="removeCurrentReport(reportsMeta[index].firstFilterValue, row)"
                      >×</span>
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </span>
            </div>
            <el-dropdown
              :show-timeout="200"
              class="report-action ml-4"
              placement="bottom-start"
            >
              <div class="">
                <i class="fas fa-chevron-down"/>
              </div>
              <el-dropdown-menu slot="dropdown" class="ddgroup dropdown-action">
                <el-dropdown-item>
                  <div @click="copyUrl(index)" class="d-flex justify-content-between align-items-center w-100">
                    <span>
                      {{$t('offer.card.btn')}}
                    </span>
                    <i class="fas fa-link"></i>
                  </div>
                </el-dropdown-item>
                <el-dropdown-item v-if="index !== 0">
                  <div @click="openBookmarksModal(index, $event)">
                    <span>
                      {{$t('customReports.addBookmark')}}
                    </span>
                    <ui-icon
                      :name="`star-${report.isPinnedBookmark || isPinnedPopover[index] ? 'filled' : 'outline'}`"
                      :color="`#${report.isPinnedBookmark || isPinnedPopover[index] ? 'ffce06' : '303634'}`"
                      :size="13"
                      class="tab-action"
                    />
                  </div>
                </el-dropdown-item>
                <el-dropdown-item divided>
                  <div
                    class=""
                    @click="resetHomeReport($event, index)"
                  >
                    <span>
                      {{$t('filters.resetFilters')}}
                    </span>
                    <i class="far fa-undo"></i>
                  </div>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
            <div v-if="index !== 0" class="ctrls">
              <el-tooltip
                :content="$t('customReports.removeReport')"
                placement="bottom"
                popper-class="tooltip-capitalize"
                :open-delay="200"
              >
                <span
                  class="cross-close"
                  @click="removeReport(index, $event)"
                >×</span>
              </el-tooltip>
            </div>
          </div>
          <div
            v-if="isVisibleTabMore"
            :class="{'active': isNavTabDropdownVisible}"
            class="nav-tab-dropdown ml-8"
          >
            <el-dropdown trigger="click" placement="bottom">
              <div class="nav-tab-dropdown__wrap">
                <ui-icon :size="10" name="hamburger" class="icon-rows"/>
                <span class="nav-tab-dropdown__text pl-6 pr-6">
                  {{ reports.length - tabCountVisible - 1 }} {{$t('customReports.more')}}
                </span>
                <i class="fas fa-chevron-down" :class="isNavTabDropdownVisible ? 'angle-down-rotate' : ''"></i>
              </div>
              <el-dropdown-menu
                slot="dropdown"
                class="ddgroup dropdown-action dropdown-more"
                v-model="isNavTabDropdownVisible"
              >
                <el-dropdown-item
                  class="d-flex justify-content-between dropdown-item-drill"
                  v-for="(report, index) in reportsCloneDropdown"
                  :key="index"
                  divided
                >
                  <div @click="selectReport(tabCountVisible + index + 1); isNavTabDropdownVisible = false">
                    <span class="field">
                      <i
                        v-if="reportsMeta[tabCountVisible + index + 1].firstFilterValue.op == '!='"
                        class="fas fa-page-break"
                        style="font-size: 11px;"
                      />
                      <i
                        v-else-if="reportsMeta[tabCountVisible + index + 1].firstFilterValue.op == '='"
                        class="far fa-random"
                        style="font-size: 11px;"
                      />
                      {{ reportsMeta[tabCountVisible + index + 1].isMediaBuying ? $t('customReports.mb') : ''}}
                      {{ (allDimensionHash[reportsMeta[tabCountVisible + index + 1].firstFilterKey] || {}).title }}
                    </span>
                    <span class="value">
                      {{report.defaultFilterName || '' }}
                    </span>
                    <span class="ml-2">
                      {{ reportsMeta[tabCountVisible + index + 1].firstFilterValue.selectIdsLength }}
                    </span>
                  </div>
                  <span
                    class="cross-close"
                    @click="removeReport(tabCountVisible + index + 1, $event); isNavTabDropdownVisible = false"
                  >×</span>
                </el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </draggable>
      </div>
      <div class="tabs-right-btn">
        <div
          v-if="reports.length >= 3"
          class="tab-close mr-16"
          @click="removeAllReport($event)"
        >
          <div class="ctrls">
            <el-tooltip
              :content="$t('customReports.removeAllReport')"
              placement="bottom"
              popper-class="tooltip-capitalize"
            >
              <span>×</span>
            </el-tooltip>
          </div>
          <div class="report_name">
            <span class="value field-close">{{$t('customReports.closeAll')}}</span>
          </div>
        </div>
        <slot name="append"></slot>
      </div>
    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable';
import lzString from 'lz-string';

export default {
  components: {
    draggable,
  },
  props: {
    reports: {
      type: Array,
      default: () => ([]),
    },
    currentReport: {
      type: Number,
      default: 0,
    },
    allDimension: {
      type: Array,
      default: () => [],
    },
    cacheData: {
      type: Object,
      default: () => {},
    },
    groupByField: {
      type: Object,
      default: () => ({}),
    },
    getTableFields: {
      type: Array,
      default: () => [],
    },
    singleGroupingSelect: {
      type: String,
      default: '',
    },
    groupingSelects: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isPinnedPopover: [],
      arrayWidthItemForTooltip: [],
      tabCountVisible: null,
      isNavTabDropdownVisible: false,
    };
  },
  computed: {
    dragOptions() {
      return {
        animation: 0,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      };
    },
    isVisibleTabMore() {
      if (!(this.tabCountVisible !== null && this.tabCountVisible < this.reports.length - 1)) {
        this.isNavTabDropdownVisible = false;
      }
      return this.tabCountVisible !== null && this.tabCountVisible < this.reports.length - 1;
    },
    reportsMeta() {
      return this.reports.map((item) => {
        const key = Object.keys(item.defaultFilter)[0];
        return {
          firstFilterKey: key,
          firstFilterValue: item.defaultFilter[key] || {},
          isMediaBuying: key === 'media_buying_channel_id' || key === 'media_buying_campaign_id',
        };
      });
    },
    reportsCloneDropdown() {
      return this.reports.slice(-(this.reports.length - this.tabCountVisible - 1));
    },
    allDimensionHash() {
      const result = {};

      for (const item of this.allDimension) {
        result[item.column_name] = item;
      }

      return result;
    },
  },
  watch: {
    currentReport: {
      immediate: true,
      handler(value) {
        this.getWidthItemForTooltip();
      },
    },
  },
  async mounted() {
    window.addEventListener('resize', this.resizeTabMove);

    this.getWidthItemForTooltip();
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.resizeTabMove);
  },
  methods: {
    selectReport(index) {
      this.$emit('select-report', index);
    },
    removeReport(index, event) {
      event.stopPropagation(true);
      this.reports[index].isPinned = false;
      this.selectReport(0);
      this.reports.splice(index, 1);
    },
    removeAllReport(event) {
      event.stopPropagation(true);
      this.selectReport(0);
      this.reports.splice(1);
      this.$nextTick(() => {
        this.lastTab();
      });
      // this.selectedRow = {};
    },
    resetHomeReport(event, i) {
      event.stopPropagation(true);
      this.$emit('reset-filters', i);
    },
    getReport() {
      this.$emit('get-report', this.reports[this.currentReport]);
    },
    lastTab() {
      if (document.body.clientWidth > 650) {
        let tabCountVisible = null;
        let tabWidthIterator = 0;
        if (this.$el) {
          const tabWrapWidth = this.$el ? this.$el.querySelector('.inner').offsetWidth : 0;

          (this.$refs.tab || []).forEach((e, i) => {
            if (tabCountVisible === null) {
              tabWidthIterator += e.offsetWidth + 8;
            }

            if (tabWrapWidth - 400 < tabWidthIterator && tabCountVisible === null) {
              tabCountVisible = i;
            }
          });
          const tabsCollection = this.$el.querySelectorAll('.inner .tab');
          tabsCollection.forEach((el, i) => {
            if (tabCountVisible < i && tabCountVisible !== null) {
              el.classList.add('hidden-tab');
            } else {
              el.classList.remove('hidden-tab');
            }
          });
        }
        this.tabCountVisible = tabCountVisible;
        let changeItem;
        if (this.currentReport > tabCountVisible
            && tabCountVisible !== null
            && changeItem === undefined) {
          changeItem = this.reports.splice(this.currentReport, 1);
          this.reports.splice(tabCountVisible, 0, ...changeItem);
          // this.currentReport = tabCountVisible;
          this.$emit('update:currentReport', tabCountVisible);
        } else {
          changeItem = undefined;
        }
      }
    },
    removeCurrentReport(value, row) {
      const filterList = this.updateFilter(this.reports[this.currentReport].defaultFilter[value.group_by], row);

      this.reports[this.currentReport].defaultFilter[value.group_by] = filterList;
      this.reports[this.currentReport].defaultFilterName = filterList.selectedRowList[0].rowTooltip;
      this.getReport('removeCurrentReport');
    },
    updateFilter(filterList, row) {
      filterList.row_id = filterList.row_id.filter(e => e !== row.row_id);
      filterList.selectedRow = filterList.selectedRow.filter(e => e !== row.rowTooltip);
      filterList.selectedRowList = filterList.selectedRowList.filter(e => e.row_id !== row.row_id);
      return {
        row_id: filterList.row_id,
        visible: filterList.selectedRowList[0].rowTooltip,
        group_by: filterList.group_by || '',
        op: filterList.op,
        selectIdsLength: filterList.row_id.length <= 1 ? '' : `[+${filterList.row_id.length - 1}]`,
        selectedRow: filterList.selectedRow,
        selectedRowList: filterList.selectedRowList,
        row_filter_column: filterList.row_filter_column,
      };
    },
    openBookmarksModal(index, event) {
      this.$emit('open-bookmark-modal', index, event);
    },
    getWidthItemForTooltip() {
      this.$nextTick(() => {
        this.arrayWidthItemForTooltip = [];
        if (this.$refs.valueText) {
          this.$refs.valueText.forEach((elem, i) => {
            this.$set(this.arrayWidthItemForTooltip, i, elem.offsetWidth || 0);
          });
        }
      });
    },
    resizeTabMove: _.debounce(function resizeTabMove() {
      this.lastTab();
    }, 50),
    draggable({ moved }) {
      const { newIndex, oldIndex } = moved;
      let currentReport = this.currentReport;
      this.$nextTick(() => {
        this.$emit('cache-query');
      });
      if (this.currentReport === oldIndex) {
        currentReport = newIndex;
        this.$emit('update:currentReport', currentReport);
        // this.currentReport = currentReport;
        return;
      }

      if ((this.currentReport > oldIndex && this.currentReport <= newIndex)) {
        currentReport -= 1;
      } else if ((this.currentReport < oldIndex && this.currentReport >= newIndex)) {
        currentReport += 1;
      }
      this.$nextTick(() => {
        this.$emit('cache-query');
      });
      // this.currentReport = currentReport;
      this.$emit('update:currentReport', currentReport);
    },
    draggableStart() {
      this.isDragged = true;
    },
    draggableEnd() {
      this.$nextTick(() => {
        this.isDragged = false;
      });
    },
    copyUrl(index) {
      const cacheData = this.$_.cloneDeep(this.cacheData);

      if (index !== 0) {
        cacheData.reports = [cacheData.reports[0], cacheData.reports[index]];
        cacheData.currentReport = this.currentReport === index ? 1 : 0;
      } else {
        cacheData.reports = [cacheData.reports[0]];
        cacheData.currentReport = 0;
      }

      const urlQuery = lzString.compressToEncodedURIComponent(JSON.stringify(cacheData));
      const value = `${window.location.origin}/#/dashboard/custom/${urlQuery}`;
      const el = document.createElement('textarea');
      el.value = value;
      el.setAttribute('readonly', '');
      el.style.position = 'absolute';
      el.style.left = '-9999px';
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);

      this.$alert.success(this.$t('customReports.urlCopied'));
    },
  },
};
</script>
