export default {
  saveAffiliateOffers(state, payload) {
    state.my = payload;
  },
  saveOffers(state, payload) {
    state.all = payload;
  },

  saveSubAffilateOffers(state, payload) {
    state.my = payload;
  },

  saveOffer(state, payload) {
    state.offer = payload;
  },
};
