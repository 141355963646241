const isNumber = (str) => {
  const pattern = /^\d+$/;
  return pattern.test(str);
};

export default {
  mounted() {
    window.addEventListener('paste', this.paste);
  },

  methods: {
    paste(event) {
      const value = (event.clipboardData || window.clipboardData).getData('text');
      const { type, fields } = this.$refs.codeinput;

      if (
        (type === 'number' ? isNumber(value) : true)
        && value.length === fields
      ) {
        this.insertCodeInput(value);
      }
    },

    insertCodeInput(value) {
      const convertValue = value.split('');
      this.$refs.codeinput.values = convertValue;
    },
  },

  destroyed() {
    window.removeEventListener('paste', this.paste);
  },
};
