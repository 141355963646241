<template>
  <div class="card card-body profile-card profile__general-info edit-notifications">
    <edit-public-profile ref="editPublicProfileModal" />
    <modal :show.sync="isConfirmDelete" @close="isConfirmDelete = false" class="modal-alert profile__modal-paymet-edit">
      <h5 slot="header" class="modal-title">
        {{$t('profile.public_profile.delete.title')}}
      </h5>
      <template>
        <div class="">
          <i class="fas fa-trash-alt text-red pointer" /><span class="ml-8">{{$t('profile.public_profile.delete.confirm')}}</span>
        </div>
      </template>
      <div slot="footer" class="d-flex justify-content-end w-100 mt-8 mr-0">
        <button class="btn btn-outline-primary btn-sm btn__back" @click="isConfirmDelete = false">
          {{ $t('profile.buttons.cancel') }}
        </button>
        <button class="btn btn-danger btn-sm ml-16" @click="confirmDelete" :disabled="isLoading">
          {{$t('profile.buttons.delete')}}
        </button>
      </div>
    </modal>
    <div class="row">
      <h5 class="card-title d-flex justify-content-between align-items-center w-100 pt-0 mb-12">
        <span>{{$t('profile.public_profile.title')}}</span>
        <span class="btn-wrapper">
          <button
            v-if="!$_.isEmpty(publicProfileData)"
            class="btn btn-outline-danger btn-sm"
            @click.prevent="handleDelete"
          >{{$t('profile.buttons.delete')}}</button>
          <button
            class="btn btn-outline-primary btn-sm"
            @click.prevent="handleEdit"
          >{{$t('profile.buttons.edit')}}</button>
        </span>
      </h5>
    </div>
    <hr>
    <div v-if="$_.isEmpty(publicProfileData)" class="row">
      <div class="col-12 mt-16 no-data">
        <i class="fas fa-user" />
        <span class="ml-8">{{$t('profile.public_profile.no_data')}}</span>
      </div>
    </div>
    <div v-else class="row">
      <div class="mt-16 ui-align-center">
        <MenuItem
          icon="fas fa-user"
          :text="publicProfileData.name"
          :type="$t('profile.public_profile.name')"
          tooltip-placement="top"
        ></MenuItem>
      </div>
      <div class="item-container col-12">
        <div class="col-12 col-sm-6 pl-0 mt-16" v-for="item in publicProfileData.contacts">
          <MenuItem
            :icon="$_.get(iconMap, [item.type, 'name'], item.type)"
            :icon-color="$_.get(iconMap, [item.type, 'color'], '#303634')"
            :text="item.value"
            :type="item.type"
            :is-social="true"
            tooltip-placement="top"
          ></MenuItem>
        </div>
      </div>
      <div class="mt-16 misc">
        {{ publicProfileData.additional_information }}
      </div>
    </div>
  </div>
</template>

<script>
import Tooltip from 'src/components/Tooltip.vue';
import MenuItem from '@/components/Form/MenuItem.vue';
import EditPublicProfile from './Modal/EditPublicProfile.vue';
import { Modal } from '@/components';


export default {
  components: {
    Modal,
    EditPublicProfile,
    MenuItem,
    Tooltip,
  },
  name: 'PublicProfile',
  props: {
    publicProfileData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      isLoading: false,
      isEdit: false,
      isConfirmDelete: false,
      iconMap: {
        email: {
          name: 'fas fa-envelope',
        },
        skype: {
          icon: 'ui-icon skype-icon',
          color: '#4088f7',
        },
        telegram: {
          img: require('@/assets/theme/icon/telegram.svg'),
        },
      },
    };
  },
  computed: {
    messengers() {
      return this.$store.state.misc.messengers.map((e) => ({
        id: e.id,
        text: e.name,
      })).concat({
        id: '',
        text: this.$t('registration.placeholder_messenger'),
      });
    },
  },
  mounted() {},
  watch: {},
  methods: {
    handleDelete() {
      this.isConfirmDelete = true;
    },
    confirmDelete() {
      this.isLoading = true;

      this.$api.deletePublicProfile(this.form)
        .then(() => {
          this.$store.dispatch('getProfileData');
          this.isConfirmDelete = false;
          this.$alert.success(this.$t('profile.public_profile.success.deleted'));
        })
        .finally(() => this.isLoading = false);
    },
    handleEdit() {
      this.$refs.editPublicProfileModal.open(this.publicProfileData);
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-wrapper {
  display: flex;
  gap: 8px;

  @media (max-width: 450px) {
    flex-direction: column;
  }
}

.profile-card {
  overflow: hidden;
}

.item-container {
  display: flex;
  flex-wrap: wrap;
}

.no-data {
  font-size: 16px;
  color: #a6b2c4;
  text-align: center;
}

.misc {
  white-space: pre-line;
}
</style>
