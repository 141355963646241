export default {
  data() {
    return {
      currencyCode: '',
    };
  },
  computed: {
    currencyLabel() {
      return this.getCurrencyLabel(this.currencyCode);
    },
  },
  methods: {
    getCurrencyLabel(code) {
      if (!code) {
        return '';
      }

      const currency = this.$store.state.misc.currencies.find((el => el.code === code));

      return currency?.symbol || `${code} `;
    },
    formatMetric(metric, currencyLabel = this.currencyLabel) {
      return {
        ...metric,
        ...(currencyLabel && {
          title: metric.title.replace(/, \$|, {{ currencyCode }}/, `, ${currencyLabel}`),
          title_short: metric.title.replace(/, \$|, {{ currencyCode }}/, `, ${currencyLabel}`),
        }),
      }
    },
  },
};
