const symbolMap = {
  1: '1',
  2: '2',
  3: '3',
  4: '4',
  5: '5',
  6: '6',
  7: '7',
  8: '8',
  9: '9',
  0: '0',
};
const numberMap = {
  1: '1',
  2: '2',
  3: '3',
  4: '4',
  5: '5',
  6: '6',
  7: '7',
  8: '8',
  9: '9',
  0: '0',
};

const bn = {
  months: 'জানুয়ারি_ফেব্রুয়ারি_মার্চ_এপ্রিল_মে_জুন_জুলাই_আগস্ট_সেপ্টেম্বর_অক্টোবর_নভেম্বর_ডিসেম্বর'.split(
    '_',
  ),
  monthsShort: 'জানু_ফেব্রু_মার্চ_এপ্রিল_মে_জুন_জুলাই_আগস্ট_সেপ্ট_অক্টো_নভে_ডিসে'.split(
    '_',
  ),
  weekdays: 'রবিবার_সোমবার_মঙ্গলবার_বুধবার_বৃহস্পতিবার_শুক্রবার_শনিবার'.split(
    '_',
  ),
  weekdaysShort: 'রবি_সোম_মঙ্গল_বুধ_বৃহস্পতি_শুক্র_শনি'.split('_'),
  weekdaysMin: 'রবি_সোম_মঙ্গল_বুধ_বৃহ_শুক্র_শনি'.split('_'),
  longDateFormat: {
    LT: 'A h:mm সময়',
    LTS: 'A h:mm:ss সময়',
    L: 'DD/MM/YYYY',
    LL: 'D MMMM YYYY',
    LLL: 'D MMMM YYYY, A h:mm সময়',
    LLLL: 'dddd, D MMMM YYYY, A h:mm সময়',
  },
  calendar: {
    sameDay: '[আজ] LT',
    nextDay: '[আগামীকাল] LT',
    nextWeek: 'dddd, LT',
    lastDay: '[গতকাল] LT',
    lastWeek: '[গত] dddd, LT',
    sameElse: 'L',
  },
  relativeTime: {
    future: '%s পরে',
    past: '%s আগে',
    s: 'কয়েক সেকেন্ড',
    ss: '%d সেকেন্ড',
    m: 'এক মিনিট',
    mm: '%d মিনিট',
    h: 'এক ঘন্টা',
    hh: '%d ঘন্টা',
    d: 'এক দিন',
    dd: '%d দিন',
    M: 'এক মাস',
    MM: '%d মাস',
    y: 'এক বছর',
    yy: '%d বছর',
  },
  preparse(string) {
    return string.replace(/[১২৩৪৫৬৭৮৯০]/g, match => numberMap[match]);
  },
  postformat(string) {
    return string.replace(/\d/g, match => symbolMap[match]);
  },
  meridiemParse: /রাত|সকাল|দুপুর|বিকাল|রাত/,
  meridiemHour(hour, meridiem) {
    if (hour === 12) {
      hour = 0;
    }
    if (
      (meridiem === 'রাত' && hour >= 4)
      || (meridiem === 'দুপুর' && hour < 5)
      || meridiem === 'বিকাল'
    ) {
      return hour + 12;
    } else {
      return hour;
    }
  },
  meridiem(hour, minute, isLower) {
    if (hour < 4) {
      return 'রাত';
    } else if (hour < 10) {
      return 'সকাল';
    } else if (hour < 17) {
      return 'দুপুর';
    } else if (hour < 20) {
      return 'বিকাল';
    } else {
      return 'রাত';
    }
  },
  week: {
    dow: 0, // Sunday is the first day of the week.
    doy: 6, // The week that contains Jan 6th is the first week of the year.
  },
};

export default bn;
