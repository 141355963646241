<template>
  <section class="">
    <modal :show.sync="isEdit" @close="isEdit = false" class="modal-alert">
      <h5 slot="header" class="modal-title">
        {{$t('profile.paymentMethod.editTitle')}}
      </h5>
      <template>
        <div class="row" v-loading="isLoadingPaymentMethod">
          <div class="col-12">
            <label class="title mb-6">{{ $t("profile.paymentMethod.paymentMethod") }}</label>
            <v-select
              v-model="form.site_payment_method_id"
              :options="paymentMethodOptions"
              @change="errors_.payment_method = ''"
            />
            <span
              v-if="errors_.site_payment_method_id"
              class="text-danger"
              style="font-size: 12px"
            >
              {{ errors_.site_payment_method_id[0].message }}
            </span>
            <div v-if="getSelectedPaymentMethodDescription" class="profile__payment-method-info">
              <span v-for="(line, i) of getSelectedPaymentMethodDescription.split('\n')" :key="i">
                {{ line }}<br/>
              </span>
            </div>
          </div>

          <div class="col-12 mt-16">
            <fg-input
              v-model="form.payment_wallet"
              :placeholder="wallet_placeholder"
              class="no-border form-control-lg mb-8"
              @input="errors_.payment_wallet = ''"
            >
              <label slot="label">{{ $t("profile.paymentMethod.walletNumber") }}</label>
            </fg-input>
            <span
              v-if="errors_.payment_wallet"
              class="text-danger"
              style="font-size: 12px"
            >
              {{ errors_.payment_wallet[0].message }}
            </span>
          </div>
        </div>
      </template>
      <div slot="footer" class="d-flex justify-content-end w-100 mt-8 mr-0">
        <button class="btn btn-outline-primary btn-sm btn__back" @click="close">
          {{ $t('profile.buttons.cancel') }}
        </button>
        <button class="btn btn-primary btn-sm ml-16" @click="save" :disabled="isLoading">
          {{$t('profile.buttons.save')}}
        </button>
      </div>
    </modal>
  </section>
</template>

<script>
import { Modal } from 'src/components';

export default {
  components: {
    Modal,
  },
  data() {
    return {
      isEdit: false,
      isLoadingPaymentMethod: false,
      paymentMethods: [],
      form: {},
      isLoading: false,
      errors_: {},
    };
  },
  computed: {
    paymentMethodOptions() {
      return [
        {
          id: '',
          text: this.$t('profile.generalInfo.notSpecified'),
        },
        ...this.paymentMethods.map(el => ({ ...el, text: el.name })),
      ];
    },
    getSelectedPaymentMethodDescription() {
      if (this.form.site_payment_method_id === undefined) return null;
      const findSelectMethod = this.paymentMethods.find(pm => pm.id === this.form.site_payment_method_id);
      return findSelectMethod?.description || null;
    },

    wallet_placeholder() {
      if (this.form.site_payment_method_id) {
        return this.$store.state.misc.paymentMethods.find(e => e.id === this.form.site_payment_method_id).wallet_placeholder;
      }
      return this.$t('profile.generalInfo.notSpecified');
    },
  },
  watch: {
    form(v) {
      if (v.payment_method === 'btc' || v.payment_method === 'bank_transfer') {
        this.form.payment_method = this.form.payment_method_name
      }
    },
    '$i18n.locale': function () {
      this.$store.dispatch('getPaymentMethods');
    },
  },

  methods: {
    getPaymentMethod(currencyCode = this.$store.state.payments.currencyCode) {
      this.isLoadingPaymentMethod = true;
      return this.$api.getPaymentMethods({ currency_code: currencyCode })
        .then(res => this.paymentMethods = res.payload)
        .finally(() => this.isLoadingPaymentMethod = false);
    },
    open(data) {
      this.paymentMethods = [];
      this.isLoadingPaymentMethod = true;
      this.errors_ = {};
      this.isEdit = true;
      this.getPaymentMethod().then(() => this.form = data)
    },
    close() {
      this.isEdit = false;
    },

    save() {
      this.errors_ = {};

      const fields = {
        site_payment_method_id: this.form.site_payment_method_id,
        payment_wallet: this.form.payment_wallet,
      };

      this.isLoading = true;

      this.$store
        .dispatch('saveProfilePaymentMethod', fields)
        .then(() => {
          this.$alert.success(this.$t('profile.success.saved'));
          this.errors_ = {};
          this.isEdit = false;
        })
        .catch((err) => {
          const errors = err.response.data.errors;

          if (Object.keys(errors).length) {
            this.errors_ = errors;
          } else {
            this.$alert.fail(this.$t('profile.errors.incomplete_profile'));
          }
        }).finally(() => this.isLoading = false);
    },
  },
};
</script>

<style lang="scss" scoped>
.row {
  .title {
    font-size: 13px;
    font-weight: 600;
    line-height: normal;
    color: var(--charcoal);
    text-transform: uppercase;
  }
}
.form-error {
  font-size: 12px;
  top: -5px;
  position: relative;
}

.modal .modal-footer .btn {
  min-width: 74px!important;
}
</style>
