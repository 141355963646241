<template>
  <section>
    <modal :show.sync="active" class="offer get-links-modal" @close="close">
      <template>
        <h5 slot="header" class="modal-title mt-8">
          {{ $t('media.card.btn') }}
        </h5>
        <div v-loading="loading">
          <div class="select-wrapper w-100 mt-12">
            <label>{{ $t('media.media') }}</label>
            <v-select
              :id="`${$randomizer()}GenerateLinks.promo_material`"
              v-model="promo_material"
              :options="promoMaterials"
              class="el-input"
              @change="changePromoMaterials"
            />
          </div>
          <div class="wrap-row-column d-flex mt-12 mb-8">
            <div v-if="$store.state.profile.user.referral_program_enabled" class="select-wrapper w-100" :class="{ disabled: promo_material === '' }">
              <label>{{ $t('mediaBuying.campaign.affiliate') }}</label>
              <v-select
                :id="`${$randomizer()}GenerateLinks.sub_affiliate_id`"
                v-model="sub_affiliate_id"
                :options="referrals"
                class="el-input"
                @change="changeSubAffiliateId"
              />
            </div>
            <div
              class="select-wrapper w-100 ml-16"
              :class="{ disabled: promo_material === '', 'ml-0': !$store.state.profile.user.referral_program_enabled }"
            >
              <label>{{ $t('promoCodes.addPromocodes.offer') }}</label>
              <v-select :id="`${$randomizer()}GenerateLinks.offer`" v-model="offer" :options="offers" class="el-input" @change="changeOffer" />
              <span v-if="!offerSelect && sending" class="text-danger text-left mt-2">
                {{ $t('promoCodes.addPromocodes.selectOffer') }}
              </span>
            </div>
          </div>
          <!-- brand -->
          <div v-if="active && hideSelect(brands)" class="select-wrapper w-100 mt-14 mb-10" :class="{ disabled: offer === '' }">
            <label>{{ $t('mediaTags.brand') }}</label>
            <v-select :id="`${$randomizer()}GenerateLinks.brand`" v-model="brand" :options="brands" :disabled="offer === ''" class="el-input" @change="changeBrand" />
          </div>
          <!-- rotation -->
          <div v-if="hideSelect(rotations)" class="select-wrapper w-100 mt-14 mb-10" :class="{ disabled: brand === '' }">
            <label>{{ $t('mediaTags.rotation') }}</label>
            <v-select :id="`${$randomizer()}GenerateLinks.rotation`" v-model="rotation" :options="rotations" class="el-input" @change="changeRotation" />
          </div>
          <!-- target -->
          <div class="select-wrapper w-100" :class="{ disabled: brand === '' }">
            <label>{{ $t('mediaTags.target') }}</label>
            <v-select :id="`${$randomizer()}GenerateLinks.activeTab`" v-model="activeTab" :options="urls" class="el-input" @change="setActive" />
          </div>
          <div class="wrap-row-column d-flex">
            <!-- languages -->
            <div class="select-wrapper mt-12 mb-8 w-100" v-if="(languages || []).length > 2" :class="{ disabled: activeTab === '' }">
              <label>{{ $t('mediaTags.languages') }}</label>
              <v-select :id="`${$randomizer()}GenerateLinks.language`" v-model="language" :options="languages" :disabled="activeTab === ''" class="el-input" @change="handlerLanguage"/>
            </div>
            <!-- size -->
            <div
              v-if="type === 'html5_banner' || type === 'simple_banner'"
              class="select-wrapper w-100 mt-12 mb-8"
              :class="{ disabled: activeTab === '', 'ml-16': (languages || []).length > 2 }"
            >
              <label>{{ $t('mediaTags.size') }}</label>
              <v-select
                :id="`${$randomizer()}GenerateLinks.size`"
                v-model="size"
                :options="sizes"
                :placeholder="$t('profile.generalInfo.notSpecified')"
                class="el-input"
                @change="handlerSizes"
              />
            </div>
          </div>
          <div class="row pt-6">
            <div class="col-lg-12 col-12">
              <div class="generate-tag__right-col">
                <div v-popover:popover class="select-wrapper mb-10" :class="{ disabled: activeTab === '' }">
                  <label>{{ $t('trafficSources.trafficSource') }}</label>
                  <v-select
                    :id="`${$randomizer()}GenerateLinks.traffic_source_id`"
                    v-model="traffic_source_id"
                    :options="list"
                    class="el-input"
                    @change="selectTrafficSource"
                  />
                  <router-link v-if="!list.length" :to="{ path: '../traffic-source' }" class="text-green d-block text-right">
                    {{ $t('trafficSources.addTrafficSources.title') }}
                  </router-link>
                </div>
                <el-popover ref="popover" v-model="warningOpen" placement="top-end" trigger="manual" width="320">
                  <p>{{ $t('promoCodes.addPromocodes.changeSubId') }}</p>
                  <button class="btn btn-outline-primary btn-md" @click="saveSubids">
                    {{ $t('offerRequest.btnCancel') }}
                  </button>
                  <button
                    class="btn btn-primary btn-md ml-3"
                    @click="
                      confirmChanges();
                      warningOpen = false;
                    "
                  >
                    {{ $t('offerRequest.btnConfirm') }}
                  </button>
                </el-popover>
                <div class="pb-6">
                  <div class="form-group generate-tag__form-group generate-tag__form-group--underline" :class="{ 'disable-input': activeTab === '' }">
                    <label class="generate-tag__label generate-tag__label--final" for="final-url">{{ $t('offer.card.finalPageUrl') }}</label>
                    <input id="final-url" type="text" v-model="link" :disabled="activeTab === ''" @input="inputLink" class="form-control generate-tag__input" />
                  </div>
                  <div class="tag-wrap">
                    <span
                      v-for="item in links"
                      :key="item.page_url"
                      :class="{ 'el-tag--selected': link === item.page_url, 'disabled-link': activeTab === '' }"
                      class="el-tag el-tag--primary el-tag--small"
                      @click="setLink(item.page_url)"
                    >{{ item.name }}</span>
                  </div>
                </div>
                <div class="row row-wrap__subid">
                  <div v-for="(item, i) in form.subids" :key="i" :class="{ 'disable-input': activeTab === '' }" class="col-6 col-6__add-subid">
                    <fg-input
                      v-model="item.value"
                      :disabled="activeTab === ''"
                      :placeholder="$t('trafficSources.postbacks.notSpecified')"
                      class="no-border form-control-lg mb-0"
                    >
                      <label slot="label" class="d-flex justify-content-between">
                        <span>SubID{{ i + 1 }}</span>
                        <a
                          v-if="calcSubIdInTrafficSource < i + 1"
                          v-show="form.subids.indexOf(item) === form.subids.length - 1 && form.subids.length > 1"
                          href="#"
                          class="btn-cross"
                          @click.prevent="popSubIds"
                        >
                          <span>×</span>
                        </a>
                      </label>
                    </fg-input>
                  </div>
                  <div class="col-6 d-flex align-items-end col-6__add-subid">
                    <button
                      :disabled="form.subids.length >= 5 || activeTab === ''"
                      href="#"
                      class="btn btn-block btn-outline-primary btn-sm mb-0"
                      @click.prevent="form.subids.length < 5 && addSubId()"
                    >
                      + {{ $t('promoCodes.addPromocodes.addAnotherSubid') }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-12 col-12">
              <div class="w-100 pt-16 mb-getlinks">
                <h6 v-if="type === 'landing'" class="textarea__track-title">
                  {{ $t('mediaTags.redirectUrl') }}
                </h6>
                <h6 v-if="type.indexOf('banner') > 0" class="textarea__track-title">
                  {{ $t('mediaTags.htmlCode') }}
                </h6>
                <copytext
                  :button="type === 'landing' ? $t('offer.card.btn') : $t('offer.card.btnCode')"
                  :success="$t('offer.card.btnDone')"
                  :text.sync="url"
                  :disabled="warningOpen"
                >
                  <a
                    v-if="type === 'landing' && url"
                    :href="url"
                    target="_blank"
                    class="card__btn-arrow btn btn-outline-primary btn-sm mb-4 mt-4"
                    slot="btnRight"
                  >
                    {{ $t('mediaTags.preview') }}
                    <span class="card__triangle" />
                  </a>
                  <a
                    v-if="type === 'html5_banner' && url"
                    href="#"
                    class="card__btn-arrow btn btn-outline-primary btn-sm mb-4 mt-4"
                    @click="mediaPreview(url)"
                    slot="btnRight"
                  >
                    {{ $t('mediaTags.preview') }}
                    <span class="card__triangle" />
                  </a>
                </copytext>
              </div>
            </div>
          </div>
        </div>
        <button slot="footer" class="btn btn-outline-primary btn-sm btn-close-modal" @click="close">
          {{ $t('offer.offerRequestSuccess.close') }}
        </button>
      </template>
    </modal>
    <media-preview ref="mediaPreview" @closeBanner="cBanner" @openBanner="oBanner" />
  </section>
</template>

<script>
import { mapState } from 'vuex';
import { debounce } from 'lodash';
import Copytext from 'src/components/Copytext';
import { Modal } from 'src/components';
import { Select, Option } from 'element-ui';
import VSelectMenu from 'src/components/VSelectMenu.vue';
import randomizer from 'src/helpers/randomizer.js';
import mediaPreview from '../../Media/MediaPreview';

export default {
  components: {
    Modal,
    Copytext,
    [Select.name]: Select,
    [Option.name]: Option,
    VSelectMenu,
    mediaPreview,
  },
  data() {
    return {
      offersPayload: [],
      warningOpen: false,
      params_id: '',
      params_query: '',
      active: false,
      activeTab: '',
      url: '',
      random: '',
      links: [],
      form: {
        name: '',
        extid: '',
        subids: [{ value: '' }],
      },
      promo_material: '',
      link: '',
      traffic_source_id: null,
      selected: null,
      oldTSid: '',
      checkChange: false,
      sub_affiliate_id: '0',
      offer: '',
      offerSelect: false,
      sending: false,
      loading: false,
      size: null,
      language: '',
      type: '',
      brand: '',
      brands: [
        {
          id: '',
          text: this.$t('profile.generalInfo.notSpecified'),
        },
      ],
      customSubids: [{ value: '' }],
      locale: '',
      rotation: '',
      getUrlD: debounce(this.getUrl, 800),
      site_page: null,
      paramsSubsId: {},
    };
  },
  computed: {
    ...mapState({
      marketingCampaigns: state => state.mediaBuying.marketingCampaigns || [],
    }),

    params() {
      if (this.type === 'landing') {
        return {
          affiliate_offer_id: this.offer,
          landing_id: this.activeTab,
          traffic_source_id: this.traffic_source_id,
          media_buying_campaign_id: this.$route.params.id,
          marketing_campaign_id: this.rotation,
          site_page: this.site_page,
          language_code: this.language,
          ...this.paramsSubsId,
        };
      }
      return {
        affiliate_offer_id: this.offer,
        landing_id: this.activeTab,
        size: this.size,
        marketing_campaign_id: this.rotation,
        media_buying_campaign_id: this.$route.params.id,
        traffic_source_id: this.traffic_source_id,
        site_page: this.site_page,
        language_code: this.language,
        ...this.paramsSubsId,
      };
    },

    rotations() {
      const rotations = this.$_.cloneDeep(this.marketingCampaigns)
        .sort(
          (a, b) => {
            if (a.type) {
              return a.type.length - b.type.length;
            }
          },
        )
        .map(e => ({
          id: e.id,
          text: e.name,
        }));
      return rotations.concat({
        id: '',
        text: this.$t('profile.generalInfo.notSpecified'),
      });
    },

    promoMaterials() {
      return this.$store.state.media.items
        .map(e => ({
          ...e,
          id: e.id,
          text: e.name,
        }))
        .concat({
          id: '',
          text: this.$t('profile.generalInfo.notSpecified'),
        });
    },
    offers() {
      const visible_brands = this.promoMaterials.find(e => e.id === this.promo_material).visible_brands;
      const brandsPm = (visible_brands || []).map(i => i.site_id);

      return this.offersPayload
        .map(e => ({
          id: e.id,
          text: e.name,
          visible_brands: e.visible_brands,
        }))
        .filter(e => e.visible_brands.filter(i => brandsPm.includes(i.site_id)).length)
        .concat({
          id: '',
          text: this.$t('promoCodes.addPromocodes.selectOffer'),
        });
    },
    urls() {
      return this.$store.state.mediaBuying.landings
        .map(e => ({
          ...e,
          text: e.name,
        }))
        .concat({
          id: '',
          text: this.$t('profile.generalInfo.notSpecified'),
        });
    },

    referrals() {
      return this.$store.state.subaffiliates.all
        .map(e => ({
          id: e.data.id,
          text: e.email,
        }))
        .concat({
          id: 0,
          text: this.$t('mediaBuying.campaign.my_account'),
        });
    },

    list() {
      return this.$store.state.trafficSource.list
        .map(e => ({
          id: e.data.id,
          text: e.name,
        }))
        .concat({
          id: '',
          text: this.$t('offerRequest.noTrafficSources'),
        });
    },
    languages() {
      const dictionary = this.$store.state.misc.languages;
      const data = (this.promoMaterials.find(e => e.id === this.promo_material).language_codes || []).map(e => ({
        id: e,
        text: dictionary.find(f => f.code == e.toLowerCase()).name,
      }));
      return [
        {
          id: '',
          text: this.$t('mediaTags.languagesAuto'),
        },
      ].concat(data);
    },
    sizes() {
      return (this.promoMaterials.find(e => e.id === this.promo_material).sizes || []).map(e => ({
        id: e,
        text: e,
      }));
    },

    calcSubIdInTrafficSource() {
      const id = this.traffic_source_id;
      if (!id) return 1;

      const subidArray = Array(5).fill(null).map((el, i) => `subid${i + 1}`);

      const targetTrafficSoutce = this.$store.state.trafficSource.list
        .find(ts => ts.data.id === id);

      let resultCount = 1;
      subidArray.forEach((el, i) => {
        if (targetTrafficSoutce[el] !== null) {
          resultCount = i + 1;
        }
      });

      return resultCount;
    },
  },
  watch: {
    brand: {
      handler(data) {
        if (data) {
          this.$api.getFinalPages(data).then(res => {
            this.links = res.payload.site_final_page_urls;
          })
        } else {
          this.links = [];
        }
      }
    },
    'form.subids': {
      handler(subids) {
        this.parseSubids(subids);
      },
      deep: true,
    },

    traffic_source_id(value, oldValue) {
      if (this.checkChange) return;
      this.oldTSid = oldValue;

      let oldSource = this.$store.state.trafficSource.list.filter(e => e.data.id === oldValue)[0];
      oldSource = _.pick(oldSource, ['subid1', 'subid2', 'subid3', 'subid4', 'subid5']);
      const subids = {};

      if (!value) {
        for (let i = 0; i < 5; i++) {
          const key = `subid${i + 1}`;
          subids[key] = this.form.subids[i] ? this.form.subids[i].value : null;
        }
        if (!_.isEmpty(oldSource) && !_.isEqual(subids, oldSource)) {
          this.addSubids(subids);
          return;
        }
        if (this.customSubids.some(e => e.value)) {
          this.form.subids = this.customSubids;
        } else {
          // if no traffic source selected clear subids
          this.form.subids = [{ value: null }];
        }
        return;
      }

      let source = this.$store.state.trafficSource.list.filter(e => e.data.id === value)[0];
      this.form.extid = source.data.extid || '';
      source = _.pick(source, ['subid1', 'subid2', 'subid3', 'subid4', 'subid5']);
      // if no traffic source selected, like from start
      if (!oldValue) {
        if (this.form.subids === 1 || !this.form.subids[0].value) {
          // if subids empty
          this.addSubids(source);
        } else {
          // take current subids
          const subids = {};
          for (let i = 0; i < 5; i++) {
            const key = `subid${i + 1}`;
            subids[key] = this.form.subids[i] ? this.form.subids[i].value : null;
          }
          // test if current subids is equal with new subids
          if (_.isEqual(subids, source)) {
            this.form.subids = [{ value: null }];
            this.addSubids(source);
          } else {
            // show warning
            this.warningOpen = true;
          }
        }
        return;
      }

      // take current subids
      for (let i = 0; i < 5; i++) {
        const key = `subid${i + 1}`;
        subids[key] = this.form.subids[i] ? this.form.subids[i].value : null;
      }
      // test if current subids is equal with old subids
      if (_.isEqual(subids, oldSource)) {
        this.form.subids = [{ value: null }];
        this.addSubids(source);
      } else {
        // show warning
        this.warningOpen = true;
      }
    },
    locale() {
      this.brands[0].text = this.$t('profile.generalInfo.notSpecified');
    },
  },
  updated() {
    this.locale = this.$locale;
  },
  methods: {
    hideSelect(optoins = []) {
      return optoins.length > 2;
    },

    handlerLanguage() {
      this.getUrl();
    },

    handlerSizes() {
      this.getUrl();
    },

    parseSubids(subids) {
      if (!subids.subid1 && this.activeTab !== '') {
        const objSubs = {};
        for (let i = 0; i < subids.length; i++) {
          const key = `subid${i + 1}`;
          objSubs[key] = subids[i] ? subids[i].value : null;
        }

        this.paramsSubsId = objSubs;
        this.setActive('debounce');
      }
    },

    inputLink() {
      this.site_page = this.link;
      this.getUrlD();
    },

    setLink(url) {
      this.link = url;
      this.site_page = url;
      this.getUrl();
    },

    async changeRotation() {
      if (this.rotation === '') {
        this.resetTargetData();
        this.activeTab = '';
        this.url = '';
        return;
      }
      if (this.activeTab) {
        await this.getUrl();
      }
    },

    changePromoMaterials() {
      this.reset();

      if (this.promo_material !== '') {
        this.loading = true;
        const offers = this.$store.dispatch('getAffiliateOffers', {}).then(res => Promise.all(res.payload.map(offer => this.$api.getAffiliateOffer(offer.id).then((res) => {
          const { visible_brands } = res.payload;

          return {
            ...offer,
            visible_brands,
            text: offer.name,
          };
        }))));
        const traffic = this.$store.dispatch('getTrafficSources', {});

        Promise.all([offers, traffic]).then(([offers]) => {
          this.offersPayload = offers;
          this.loading = false;
        });

        this.type = this.promoMaterials.find(e => e.id === this.promo_material).type;
        if (this.type === 'html5_banner'
          || this.type === 'simple_banner') this.size = this.sizes[0].id;
      }
    },
    changeSubAffiliateId() {
      this.resetAffiliateData();
      this.offer = '';
      this.brand = '';
      this.rotation = '';
      this.loading = true;
      const id = this.sub_affiliate_id !== '0' ? { sub_affiliate_id: this.sub_affiliate_id } : {};
      const offers = this.$store.dispatch('getAffiliateOffers', id).then(res => Promise.all(res.payload.map(offer => this.$api.getAffiliateOffer(offer.id).then((res) => {
        const { visible_brands } = res.payload;

        return {
          ...offer,
          visible_brands,
          text: offer.name,
        };
      }))));
      const traffic = this.$store.dispatch('getTrafficSources', id);

      this.$store.dispatch('getMarketingCampaigns', id);
      Promise.all([offers, traffic]).then(([offers]) => {
        this.offersPayload = offers;
        this.loading = false;
      });
    },

    changeOffer() {
      this.resetAffiliateData();
      this.brand = '';
      this.offerSelect = true;

      if (this.offer !== '') {
        this.loading = true;

        this.$store.dispatch('getMediaContent', this.promo_material).then(() => {
          const brandsPm = this.$store.state.media.mediacontent.visible_brands.map(i => i.site_id);

          this.$api.getAffiliateOffer(this.offer).then((res) => {
            const { visible_brands } = res.payload;
            const brands = visible_brands
              .filter(e => brandsPm.includes(e.site_id))
              .map(e => ({
                id: e.site_id,
                text: e.site_name,
              }));

            this.brands = [
              {
                id: '',
                text: this.$t('profile.generalInfo.notSpecified'),
              },
            ].concat(brands);

            if (brands.length === 1) {
              this.brand = brands[0].id;
              this.changeBrand();
            }

            this.loading = false;
          });
        });
      }
    },

    async changeBrand() {
      this.resetAffiliateData();
      if (this.brand === '') {
        return;
      }

      await this.$store.dispatch('getLandings', { site_id: this.brand });
      this.rotation = this.rotations[0].id;
      if (this.urls.length === 2) {
        this.activeTab = this.urls[0].id;
        this.setActive();
      }
    },

    async setActive(action) {
      if (this.activeTab === '') {
        this.resetTargetData();
        this.url = '';
        return;
      }
      if (action === 'debounce') {
        await this.getUrlD();
        return;
      }
      await this.getUrl();
    },

    selectTrafficSource() {
      this.checkChange = false;
      this.getUrlD();
    },

    async getUrl() {
      if (this.type === 'landing') {
        const {
          payload: { url },
        } = await this.$api.getMarketingMaterialLandingUrl(this.promo_material, this.params);
        this.url = url;
        return;
      }

      const {
        payload: { script },
      } = await this.$api.getMarketingMaterialBannerScript(this.promo_material, this.params);
      this.url = script;
    },

    open() {
      this.reset();
      this.random = randomizer();
      this.active = true;
      this.loading = true;
      const items = this.$store.dispatch('getItems', {});
      const offers = this.$store.dispatch('getAffiliateOffers', {}).then(res => Promise.all(res.payload.map(offer => this.$api.getAffiliateOffer(offer.id).then((res) => {
        const { visible_brands } = res.payload;

        return {
          ...offer,
          visible_brands,
          text: offer.name,
        };
      }))));
      const traffic = this.$store.dispatch('getTrafficSources', {});
      this.$store.dispatch('getLanguagesLazy');

      const id = this.sub_affiliate_id !== '0' ? { sub_affiliate_id: this.sub_affiliate_id } : {};
      this.$store.dispatch('getMarketingCampaigns', id);

      Promise.all([items, offers, traffic]).then(([_, offers]) => {
        this.offersPayload = offers;
        this.loading = false;
      });
    },

    close() {
      this.active = false;
      this.promo_material = '';
      this.loading = false;
      this.reset();
    },

    reset() {
      this.sub_affiliate_id = '0';
      this.offer = '';
      this.brand = '';
      this.rotation = '';
      this.activeTab = '';
      this.url = '';
      this.type = '';
      this.size = '';
      this.language = '';
      this.traffic_source_id = null;
      this.form.subids = [{ value: null }];
      this.customSubids = [{ value: null }];
      this.paramsSubsId = {};
      this.form.extid = '';
      this.link = '';
      this.links = [];
      this.site_page = '';
      this.checkChange = false;
      this.warningOpen = false;
    },

    resetAffiliateData() {
      this.activeTab = '';
      this.url = '';
      this.language = '';
      this.traffic_source_id = null;
      this.form.subids = [{ value: null }];
      this.customSubids = [{ value: null }];
      this.paramsSubsId = {};
      this.form.extid = '';
      this.link = '';
      this.site_page = '';
      this.rotation = '';
      this.checkChange = false;
      this.warningOpen = false;
      if (this.type === 'html5_banner'
        || this.type === 'simple_banner') this.size = this.sizes[0].id;
    },

    resetTargetData() {
      this.language = '';
      this.traffic_source_id = null;
      this.form.subids = [{ value: null }];
      this.customSubids = [{ value: null }];
      this.paramsSubsId = {};
      this.form.extid = '';
      this.link = '';
      this.checkChange = false;
      this.warningOpen = false;
      if (this.type === 'html5_banner'
        || this.type === 'simple_banner') this.size = this.sizes[0].id;
    },

    saveSubids() {
      if (this.form.subids.length < this.calcSubIdInTrafficSource) {
        const remainder = this.calcSubIdInTrafficSource - this.form.subids.length;

        this.customSubids = [
          ..._.cloneDeep(this.form.subids),
          ...Array(remainder).fill({ value: '' }),
        ].map(el => (!el.value ? { value: '' } : el));
        this.form.subids = this.customSubids;
      } else {
        this.customSubids = _.cloneDeep(this.form.subids);
      }

      this.checkChange = true;
      this.warningOpen = false;
    },
    popSubIds() {
      this.form.subids.pop();
    },
    addSubId() {
      this.form.subids.push({
        value: '',
      });
    },

    addSubids(source) {
      if (!source) {
        this.form.subids = [{ value: null }];
        return;
      }

      this.form.subids = [];
      // Push subids

      Object.keys(source).map((k) => {
        if (k.startsWith('subid')) {
          if (source[k]) {
            this.form.subids.push({
              value: source[k],
            });
          } else {
            this.form.subids.push({
              value: null,
            });
          }
        }
      });

      // reduce subids
      for (let i = this.form.subids.length - 1; i >= 1; i--) {
        if (!this.form.subids[i].value) {
          this.form.subids.pop();
        } else {
          break;
        }
      }
    },
    confirmChanges() {
      this.form.subids = [{ value: null }];
      this.customSubids = [{ value: null }];
      const source = this.$store.state.trafficSource.list.filter(e => e.data.id === this.traffic_source_id)[0];
      this.addSubids(source);
    },
    mediaPreview(item) {
      this.$refs.mediaPreview.open(item);
    },
    cBanner() {
      this.hiddenModal = false;
    },
    oBanner(v) {
      this.hiddenModal = v;
    },
  },
};
</script>

<style lang="scss">
@import 'src/assets/theme/default/offers/offer';
</style>
