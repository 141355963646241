<template>
  <span style="display: inline-block;" class="">
    <el-popover
      :ref="'popper'"
      v-model="poperVis"
      placement="top-end"
      trigger="click"
    >
      <div class="message__wrapper">
        <p>{{ $t('paymentHistory.cancelPayRequest') }}?</p>
        <div class="d-flex justify-content-between">
          <button
            class="btn btn-outline-primary btn-sm mr-2 mb-0"
            @click="poperVis=false"
          >{{ $t('paymentHistory.no') }}</button>
          <button
            class="btn btn-danger btn-sm mb-0"
            @click="sendRequest(); poperVis=false"
          >{{ $t('paymentHistory.yes') }}</button>
        </div>
      </div>
      <button
        slot="reference"
        :loading="loading"
        :disabled="loading || disabled"
        class="btn btn-sm btn-outline-danger btn-req"
      >
        <i class="fas fa-money-bill-wave pr-2"></i>
        {{ $store.state.payments.has_approved_not_paid_payment ? $t('paymentHistory.pendingPayments') : $t('paymentHistory.cancelPayRequest') }}
      </button>
    </el-popover>
  </span>
</template>

<script>

export default {
  components: {
  },
  props: ['changeSuccess', 'disabled'],
  data() {
    return {
      active: false,
      loading: false,
      success: false,
      form: {},
      errors_: {},
      poperVis: false,
    };
  },
  watch: {
    active() {
      this.errors_ = {};
      this.form = {};
    },
  },
  methods: {
    sendRequest() {
      this.$api
        .postPaymentsHistoryCancelPaymentRequest({})
        .then((res) => {
          this.loading = false;
          this.success = true;

          const { period, sort_column, sort_dir, pageSize } = this.$parent

          this.$store.dispatch('getWalletHistoryLazy', {
            limit: pageSize,
            start_timestamp: period.from,
            end_timestamp: period.to,
            offset: 0,
            sort_dir: sort_dir,
            sort_column: sort_column,
          });

          this.$emit('update:changeSuccess', '');
          this.$alert.success(this.$t('paymentHistory.canceledRequest'));
        })
        .catch((err) => {
          this.loading = false;
          this.$alert.warning(this.$t('paymentHistory.canceledRequest'));
        });
    },
  },
};
</script>
