<template>
  <div class="breadcrumbs">
    <span
      v-for="(item, i) in items"
      :key="i"
      :class="{
        'breadcrumbs__breadcrumb': true,
        'breadcrumbs__breadcrumb--active': i === lastBreadcrumbIndex
      }"
      @click="item.click && item.click()"
    >
      <i
        v-if="i > 0"
        class="fas fa-chevron-right breadcrumbs__breadcrumb-icon"
      ></i>
      <span
        :class="{
          'text-green': i !== lastBreadcrumbIndex
        }"
      >{{ item.text }}</span>
    </span>
  </div>
</template>


<script>
export default {
  name: 'Breadcrumbs',
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    lastBreadcrumbIndex() {
      return this.items.length - 1;
    },
  },
};
</script>

<style lang="scss">
  .breadcrumbs {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    width: 100%;

    &__breadcrumb {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 600;
      cursor: pointer;

      &--active {
        line-height: 21px;
        font-size: 12px;
        color: #7d7d7d;
        cursor: default;
      }
    }

    &__breadcrumb-icon {
      font-size: 12px;
      padding: 0 4px 0 4px;
    }
  }
</style>
