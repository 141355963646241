export default {
  // Campaigns
  getCampaigns({ commit }, filter) {
    return this.$api.getCampaigns(filter).then((data) => {
      commit('saveCampaigns', data);
      return data;
    });
  },

  getCampaignsAttentionCounters({ commit }) {
    return this.$api.getCampaignsAttentionCounters().then((data) => {
      commit('saveCampaignsAttentionCounters', data.payload);
      return data.payload;
    });
  },

  createCampaign({ dispatch }, filter) {
    return this.$api.createCampaign(filter).then(data => data.payload);
  },
  editCampaign({ dispatch }, data) {
    return this.$api.editCampaign(data).then(data => data.payload);
  },
  getCampaign({ commit }, id) {
    return this.$api.getCampaign(id).then((data) => {
      commit('saveCampaign', data.payload);
      commit('saveCampaignAvailableSpend', data.payload);
      return data;
    });
  },
  postCampaignArchive({}, id) {
    return this.$api.postCampaignArchive(id).then(data => data);
  },
  postCampaignActivate({ dispatch }, id) {
    return this.$api.postCampaignActivate(id).then(data => data);
  },

  // Payments
  getPayments({ commit }, filter) {
    return this.$api.getPayments(filter).then((data) => {
      commit('savePayments', data);
      return data;
    });
  },
  getPaymentMethod({ commit }, filter) {
    return this.$api.getPaymentMethod(filter).then((data) => {
      commit('savePaymentMethod', data.payload);
      return data;
    });
  },

  createPayment({ dispatch }, filter) {
    return this.$api.createPayment(filter).then(data => data);
  },
  editPayment({ dispatch }, data) {
    return this.$api.editPayment(data).then(data => data);
  },
  getPayment({ commit }, id) {
    return this.$api.getPayment(id).then((data) => {
      commit('savePayment', data.payload);
      return data;
    });
  },
  postPaymentComments({ commit }, id, payload) {
    return this.$api.postPaymentComments(id, payload).then(data => data);
  },
  getPaymentComment({ commit }, id, data) {
    return this.$api.getPaymentComment(id, data).then(data => data);
  },

  // Posts
  getPosts({ commit }, filter) {
    return this.$api.getPosts(filter).then((data) => {
      commit('savePosts', data);
      return data;
    });
  },

  createPost({ dispatch }, filter) {
    return this.$api.createPost(filter).then(data => data);
  },
  editPost({ dispatch }, filter) {
    return this.$api.editPost(filter).then(data => data);
  },
  deletePost({ dispatch }, id) {
    return this.$api.deletePost(id).then(data => data);
  },
  postToReview({ dispatch }, id) {
    return this.$api.postToReview(id).then(data => data);
  },
  getPost({ commit }, id) {
    return this.$api.getPost(id).then((data) => {
      commit('savePost', data.payload);
      return data;
    });
  },
  setPostComments({ commit }, data) {
    return this.$api.setPostComments(data).then(data => data);
  },
  getPostComment({ commit }, data) {
    return this.$api.getPostComment(data).then(data => data);
  },
  getPostCommentsCount({ commit }, id) {
    return this.$api.getPostCommentsCount(id).then(data => data);
  },

  // Files
  uploadFiles({ commit }, file) {
    return this.$api.uploadFiles(file).then((data) => {
      commit('saveFile', data);
      return data;
    });
  },
  uploadSettings({ commit }) {
    return this.$api.uploadSettings().then((data) => {
      commit('uploadSettingsData', data);
      return data;
    });
  },

  // comments
  updateComments({ commit }, data) {
    return this.$api.updateComments(data).then(data => data);
  },
  deleteComments({ commit }, data) {
    return this.$api.deleteComments(data).then(data => data);
  },
  getTrackingUrl({ commit }, data) {
    return this.$api.getTrackingUrl(data).then((data) => {
      commit('saveTrackingUrl', data.payload);
      return data;
    });
  },
  getMarketingCampaigns({ commit }, data) {
    return this.$api.getMarketingCampaigns(data).then((data) => {
      commit('saveMarketingCampaigns', data.payload);
      return data;
    });
  },
  getLandings({ commit }, data) {
    return this.$api.getLandings(data).then((data) => {
      commit('saveLandings', data.payload);
      return data;
    });
  },
};
