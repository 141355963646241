<template>
  <div
    class="ui-tooltip"
    :class="{
      'ui-tooltip--enabled': !pDisabled
    }"
  >
    <slot></slot>
    <div class="ui-tooltip__title-host" :class="placement">
      <div class="ui-tooltip__title">
        {{ title }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Tooltip',
  props: {
    title: String,
    disabled: [Boolean, String],
    placement: String,
  },
  computed: {
    pDisabled() {
      return this.disabled || this.disabled === '';
    },
  },
};
</script>

<style lang="scss">
.ui-tooltip {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  &__title-host {
    position: absolute;
    bottom: -4px;
    height: 0;
  }

  &__title {
    display: none;
    justify-content: center;
    position: relative;
    background-color: var(--color-bg-tooltip) !important;
    color: #fff;
    border-radius: 8px;
    font-size: 11px !important;
    text-transform: none !important;
    padding: 9px 25px 9px 23px !important;
    white-space: nowrap;
    word-break: break-word;
    font-weight: 600;
    z-index: 99999999999999 !important;
    min-width: auto !important;
    box-shadow: 0px 5px 25px 0px rgba(0, 0, 0, 0.2);

    &::before {
      content: "";
      top: -12px;
      border-top-width: 0;
      border-bottom-color: var(--color-bg-tooltip) !important;
      border-width: 6px;
      position: absolute;
      display: block;
      width: 0;
      height: 0;
      border-color: transparent;
      border-style: solid;
    }
  }

  .bottom-left {
    bottom: -8px;
    right: 0;
    .ui-tooltip__title:before {
      right: 20px;
    }
  }

  .bottom-right {
    left: 0px;
    .ui-tooltip__title {
      width: 400px;
      bottom: -4px;
      left: -4px;
      white-space: normal;
      &:before {
        left: 10px;
      }
    }
  }

  &--enabled:hover &__title {
    display: flex;
  }

  @media (max-width: 650px) {
    width: 100%;
    .bottom-left {
      right: unset;
      .ui-tooltip__title:before {
        right: unset;
      }
    }
    .bottom-right .ui-tooltip__title {
      width: 200px;
    }
    &.tooltip-btn-disabled {
      .ui-tooltip__title {
        // white-space: normal;
      }
    }
  }
}
</style>
