<template>
  <section class="media-buying-detail">
    <div v-loading="isLoadingCampaign" class="card detail-card">
      <div class="head-wrap-block d-flex mb-4 ui-g-w-md">
        <breadcrumbs
          class="ui-g-md ui-align-center"
          :items="[
            {
              text: $t('mediaBuying.campaign.campaigns'),
              click: () => back(),
            },
            {
              text: campaign_name,
            },
          ]"
        ></breadcrumbs>

        <div class="ui-d-flex ui-desktop-no-wrap ui-mobile-wrap">
          <div class="ui-g-md ui-mobile-12">
            <button
              class="btn btn-outline-primary btn-sm ui-mobile-12"
              :disabled="statusCampaign === 'archived'"
              @click="editCampaign"
            >
              {{ $t('mediaBuying.campaigns.edit') }}
            </button>
          </div>

          <div class="ui-g-md ui-mobile-12">
            <button
              v-if="nestedSettingView !== 'only_nested' && nestedSettingView !== 'only_payment'"
              class="btn btn-primary btn-sm ui-mobile-12"
              :disabled="statusCampaign === 'archived'"
              @click="generateLinks"
            >
              {{ $t('mediaBuying.campaign.get_promo_url') }}
            </button>
          </div>

          <div class="ui-g-md ui-mobile-12">
            <button
              v-if="nestedSettingView !== 'only_nested' && nestedSettingView !== 'only_payment'"
              class="btn btn-primary btn-sm ui-mobile-12"
              :disabled="statusCampaign === 'archived'"
              @click="getLinks"
            >
              {{ $t('mediaBuying.campaign.get_offer_url') }}
            </button>
          </div>
        </div>
      </div>
      <div class="w-75 white-space-pre media-buying-detail__title">
        {{ campaign_name }}
      </div>
      <div class="w-75 white-space-pre media-buying-detail__description">
        {{ campaign_description }}
      </div>
    </div>
    <!-- 1 -->
    <div v-if="!expand && !onceBlock" v-loading="isLoadingCampaign" class="card detail-card detail-card__balance mt-16">
      <div class="info-wrap d-flex pt-16">
        <div v-for="(_, ind) in balancesConvertedParse" :key="ind">
          <span>{{ $t(`mediaBuying.campaigns.${ind}`) }}</span>
          <span>
            <span
              :class="{
                'info-wrap__green': ind === 'balance',
                'info-wrap__red': ind === 'spent',
              }"
            >
              {{ currencyFormatter(campaignCurrencyCode, balancesConvertedParse[ind].approved) }}
              &nbsp; ({{ currencyFormatter(campaignCurrencyCode, balancesConvertedParse[ind][ind === 'balance' ? 'total' : 'pending']) }})
            </span>
          </span>
        </div>
      </div>
      <div v-if="expandedBlock" class="btn-detail text-green" @click="() => (expand = !expand)">
        {{ $t('mediaBuying.campaign.details') }}
        <i class="fa-solid fa-angle-down" :class="{ active: expand }"></i>
      </div>
    </div>
    <!-- 2 -->
    <div v-if="expand || onceBlock" v-loading="isLoadingCampaign" class="card detail-card detail-card__balance mt-16">
      <div class="info-wrap d-flex pt-16">
        <div v-for="(blockBalance, ind) in balancesParse" :key="ind">
          <span>{{ $t(`mediaBuying.campaigns.${ind}`) }}</span>
          <div class="ui-d-flex flex-column">
            <span
              v-for="(item, key) in balancesParse[ind].approved"
              :key="key"
              class="balance"
              :class="{
                'info-wrap__green': ind === 'balance',
                'info-wrap__red': ind === 'spent',
              }"
            >
              {{ currencyFormatter(key, item) }}
              &nbsp; ({{ currencyFormatter(key, balancesParse[ind][ind === 'balance' ? 'total' : 'pending'][key]) }})
            </span>
          </div>
        </div>
      </div>
      <div v-if="expandedBlock" class="btn-detail text-green" @click="() => (expand = !expand)">
        {{ $t('mediaBuying.campaign.details') }}
        <i class="fa-solid fa-angle-down" :class="{ active: expand }"></i>
      </div>
    </div>

    <el-tabs
      v-show="!isLoadingCampaign"
      v-loading="isLoadingCampaign"
      v-model="activeTab"
      type="card"
      class="tab-pane__wrap tab-pane mt-16"
      @tab-click="handleTabClick"
      :class="{ wavebg: loading }"
    >
      <el-tab-pane
        v-if="
          nestedSettingView === 'only_nested' ||
            nestedSettingView === 'only_payment' ||
            nestedSettingView === 'only_traffic_tracking'
        "
        name="campaign"
      >
        <div slot="label">
          <span>{{ $t('mediaBuying.campaign.nested_campaigns') }}</span>
          <span v-if="showTotalNeedsAttention" class="tab-count ml-4">
            {{ showTotalNeedsAttention }}
          </span>
        </div>
        <campaign-table
          class="campaign-table"
          ref="tablecampaign"
          :rootCampaignId="$route.params.id"
          :statusChannel="statusChannel"
          :statusCampaign="statusCampaign"
          :activeTab="activeTab"
          :table-sorting="sortings.posts"
          :campaign-name="campaign_name"
          :needs-attention="needsAttention"
          :nested-setting-view="nestedSettingView"
          @update="update"
        />
      </el-tab-pane>

      <el-tab-pane v-if="nestedSettingView === 'all' || nestedSettingView === 'only_payment'" name="payments">
        <div slot="label">
          <span>{{ $t('mediaBuying.campaigns.payments') }}</span>
          <span v-if="needsAttention.payments" class="tab-count ml-4">{{ needsAttention.payments }}</span>
        </div>
        <payments-table
          ref="tablepayments"
          :statusChannel="statusChannel"
          :statusCampaign="statusCampaign"
          :activeTab="activeTab"
          :table-sorting="sortings.payments"
          @update="update"
        />
      </el-tab-pane>
      <el-tab-pane v-if="nestedSettingView === 'all' || nestedSettingView === 'only_traffic_tracking'" name="posts">
        <div slot="label">
          <span>{{ $t('mediaBuying.campaigns.posts') }}</span>
          <span class="tab-count ml-4" v-if="needsAttention.posts">{{ needsAttention.posts }}</span>
        </div>
        <posts-table
          ref="tableposts"
          :statusChannel="statusChannel"
          :statusCampaign="statusCampaign"
          :activeTab="activeTab"
          :table-sorting="sortings.posts"
          @update="update"
        />
      </el-tab-pane>
      <el-tab-pane
        v-if="nestedSettingView === 'all' || nestedSettingView === 'only_traffic_tracking'"
        name="promocodes"
      >
        <div slot="label">
          <span>{{ $t('mediaBuying.campaigns.promo_codes') }}</span>
        </div>
        <promocodes-table
          :statusChannel="statusChannel"
          :statusCampaign="statusCampaign"
          :activeTab="activeTab"
          :table-sorting="sortings.promocodes"
          @update="update"
        />
      </el-tab-pane>
      <el-tab-pane v-if="nestedSettingView === 'all' || nestedSettingView === 'only_traffic_tracking'" name="summary">
        <div slot="label">
          <span>{{ $t('sidebar.menu.reports.summary') }}</span>
        </div>
        <summary-table
          :statusChannel="statusChannel"
          :statusCampaign="statusCampaign"
          :activeTab="activeTab"
          :passedParams="querySum"
          @update="update"
        />
      </el-tab-pane>
    </el-tabs>
    <edit-campaign ref="editCampaign" id="detailCampaign" @addCampaign="getCampaign" />
    <get-links ref="getLinks" />
    <generate-links ref="generateLinks" />
  </section>
</template>

<script>
import eventBus from 'src/lib/eventBus';
import UrlMixin from 'src/components/Query/url-mixin';
import UrlMixinHooks from 'src/components/Query/url-mixin-hooks';
import InjectPlugins from 'src/components/Report/Components/inject-plugins';
import { pageSizeMixin } from 'src/views/Dashboard/Reports/page_size.js';
import CampaignTable from 'src/views/Dashboard/MediaBuying/CampaignTable';
import PostsTable from 'src/views/Dashboard/MediaBuying/PostsTable';
import PaymentsTable from 'src/views/Dashboard/MediaBuying/PaymentsTable';
import PromocodesTable from 'src/views/Dashboard/MediaBuying/PromocodesTable';
import SummaryTable from 'src/views/Dashboard/MediaBuying/SummaryTable';
import EditCampaign from 'src/views/Dashboard/MediaBuying/Modal/EditCampaign';
import GetLinks from 'src/views/Dashboard/MediaBuying/Modal/GetLinks';
import GenerateLinks from 'src/views/Dashboard/MediaBuying/Modal/GenerateLinks';
import Breadcrumbs from 'src/views/Dashboard/Reports/Breadcrumbs.vue';
import moment from 'moment';
import formattedCurrency from 'src/helpers/formattedCurrency.js';
import formatCurrency from '@/mixins/format-currency';

const fieldMap = {
  post_status: 'status',
  post_type: 'type',
  period: 'post_period_start',
  post_sum: 'amount',
  payment_status: 'status',
  payment_sum: 'amount',
};

const defaultSortings = {
  campaign: {
    sort_column: 'created_at',
    sort_dir: 'desc',
    status: 'active',
  },
  posts: {
    sort_column: 'created_at',
    sort_dir: 'desc',

    status: '',
    type: '',
    created_at_from: moment()
      .startOf('month')
      .subtract(10, 'year')
      .format('YYYY-MM-DD HH:mm:ss'),
    created_at_to: moment()
      .endOf('month')
      .format('YYYY-MM-DD HH:mm:ss'),
    period: 2,
    needs_attention_first: true,
  },
  payments: {
    sort_column: 'created_at',
    sort_dir: 'desc',
  },
  promocodes: {
    sort_column: 'created_at',
    sort_dir: 'desc',
    search: '',
  },
};

const fetch = async (ctx, params) => {
  let { activeTab, sortings, querySum } = params;
  sortings = sortings || defaultSortings;
  switch (activeTab) {
    case 'campaign':
      const campaignParams = {
        root_campaign_id: ctx.$route.params.id,
        sort_column: fieldMap[sortings.campaign.sort_column] || sortings.campaign.sort_column,
        sort_dir: sortings.campaign.sort_dir,

        needs_attention_first: params.needs_attention_first !== undefined ? params.needs_attention_first : true,
        status:
          params.status !== undefined
            ? params.status
            : ctx.$refs?.tablecampaign?.status !== undefined
              ? ctx.$refs.tablecampaign.status
              : defaultSortings.campaign.status,
        search: params.search || '',

        offset: params.offset || 0,
        limit: params.limit || 20,
      };

      const campaign = await ctx.$store.dispatch('getCampaigns', campaignParams);
      return [campaign];
    case 'payments':
      const paymentsParams = {
        sort_column: fieldMap[sortings.payments.sort_column] || sortings.payments.sort_column,
        sort_dir: sortings.payments.sort_dir,
        campaign_id: ctx.$route.params.id,

        search: params.search || '',
        needs_attention_first: params.needs_attention_first !== undefined ? params.needs_attention_first : true,

        moderation_status:
          params.moderation_status !== undefined
            ? params.moderation_status
            : defaultSortings.payments.moderation_status,
        payout_status:
          params.payout_status !== undefined ? params.payout_status : defaultSortings.payments.payout_status,

        created_at_from:
          params.created_at_from !== undefined ? params.created_at_from : defaultSortings.payments.created_at_from,
        created_at_to:
          params.created_at_to !== undefined ? params.created_at_to : defaultSortings.payments.created_at_to,
        period: params.period !== undefined ? params.period : defaultSortings.payments.period,

        offset: params.offset || 0,
        limit: params.limit || 20,
      };
      paymentsParams.sort_column = paymentsParams.sort_column !== 'payment_method' ? paymentsParams.sort_column : 'full_payment_method';
      const payments = await ctx.$store.dispatch('getPayments', paymentsParams);
      return [payments];

    case 'promocodes':
      const promocodes = await ctx.$store.dispatch('getPromocodes', {
        sort_column: fieldMap[sortings.promocodes.sort_column] || sortings.promocodes.sort_column,
        sort_dir: sortings.promocodes.sort_dir,
        media_buying_campaign_id: ctx.$route.params.id,
        search: params.search || defaultSortings.promocodes.search,

        offset: params.offset || 0,
        limit: params.limit || 20,
      });
      return [promocodes];

    case 'summary':
      const summary = await ctx.$store.dispatch('getCampaignSummaryReport', {
        from: moment()
          .startOf('month')
          .format('YYYY-MM-DD 00:00:00'),
        to: moment()
          .endOf('day')
          .format('YYYY-MM-DD 23:59:59'),
        ...querySum,
        ...querySum.period,
        traffic_filter: querySum.traffic_filter || 'all',
        id: ctx.$route.params.id,
      });
      return [summary];

    case 'posts':
      const posts = await ctx.$store.dispatch('getPosts', {
        sort_column: fieldMap[sortings.posts.sort_column] || sortings.posts.sort_column,
        sort_dir: sortings.posts.sort_dir,
        campaign_id: ctx.$route.params.id,

        search: params.search || '',
        needs_attention_first:
          params.needs_attention_first !== undefined
            ? params.needs_attention_first
            : defaultSortings.posts.needs_attention_first,
        status: params.status !== undefined ? params.status : defaultSortings.posts.status,
        type: params.type !== undefined ? params.type : defaultSortings.posts.type,

        created_at_from:
          params.created_at_from !== undefined ? params.created_at_from : defaultSortings.posts.created_at_from,
        created_at_to: params.created_at_to !== undefined ? params.created_at_to : defaultSortings.posts.created_at_to,
        period: params.period !== undefined ? params.period : defaultSortings.posts.period,
        offset: params.offset || 0,
        limit: params.limit || 20,
      });
      return [posts];
    default:
  }
};

export default {
  name: 'CampaignDetails',

  fetch,
  components: {
    CampaignTable,
    PostsTable,
    PaymentsTable,
    PromocodesTable,
    SummaryTable,
    EditCampaign,
    GetLinks,
    GenerateLinks,
    Breadcrumbs,
  },
  mixins: [UrlMixin, UrlMixinHooks, InjectPlugins, pageSizeMixin, formatCurrency],
  props: ['passedParams'],

  data() {
    return {
      expand: false,
      subRootId: null,
      available_spend: {},
      balances_converted: {
        approved: {},
        pending: {},
        total: {},
      },
      payments_converted: {
        approved: {},
        pending: {},
        total: {},
      },
      posts_converted: {
        approved: {},
        pending: {},
        total: {},
      },
      balances: {
        approved: {},
        pending: {},
        total: {},
      },
      payments: {
        approved: {},
        pending: {},
        total: {},
      },
      posts: {
        approved: {},
        pending: {},
        total: {},
      },
      isLoadingCampaign: true,
      payment_requests: 'parent',
      cost_traffic_tracking: 'parent',
      loading: false,
      activeTab: '',
      campaign_name: '',
      campaign_description: '',
      channel_id: '',
      campaign_id: '',
      balance_sum: '',
      spent_sum: '',
      paid_sum: '',
      payments_on_review_sum: '',
      cost_model: '',
      sortings: defaultSortings,
      statusChannel: 'active',
      statusCampaign: 'archived',
      campaignCurrencyCode: null,
      interval: null,
      listener: '',
      querySum: {
        query: {},
        sub_affiliate_id: null,
        passedPeriod: {
          period: 30,
        },
        period: {},
        filters: [],
      },
    };
  },

  computed: {
    showTotalNeedsAttention() {
      if (this.nestedSettingView === 'all' || this.nestedSettingView === 'only_payment') {
        return this.needsAttention.total - this.needsAttention.payments;
      }
      if (this.nestedSettingView === 'all' || this.nestedSettingView === 'only_traffic_tracking') {
        return this.needsAttention.total - this.needsAttention.posts;
      }

      return this.needsAttention.total;
    },

    expandedBlock() {
      const keys = Object.keys(this.balances.approved)
      return keys.length > 1 || (keys.length && keys[0] !== this.campaignCurrencyCode);
    },

    onceBlock() {
      return Object.keys(this.balances.approved).length === 1 && Object.keys(this.balances.approved)[0] === this.campaignCurrencyCode;
    },

    balancesConvertedParse() {
      return {
        balance: { ...this.balances_converted, approved: this.balances_converted.approved },
        paid: { ...this.payments_converted, approved: this.payments_converted.approved },
        spent: { ...this.posts_converted, approved: this.posts_converted.approved },
      };
    },

    balancesParse() {
      if (this.expand) {
        return {
          balance: this.balances,
          paid: this.payments,
          spent: this.posts,
        };
      }
      const k_balance = Object.keys(this.balances.approved)[0];
      const k_paid = Object.keys(this.payments.approved)[0];
      const k_spent = Object.keys(this.posts.approved)[0];
      const balance = {
        balance: { ...this.balances, approved: { [k_balance]: this.balances.approved[k_balance] } },
        paid: { ...this.payments, approved: { [k_paid]: this.payments.approved[k_paid] } },
        spent: { ...this.posts, approved: { [k_spent]: this.posts.approved[k_spent] } },
      };
      return balance;
    },

    nestedSettingView() {
      if (this.payment_requests === 'child' && this.cost_traffic_tracking === 'child') {
        return 'only_nested';
      }
      if (this.payment_requests === 'parent' && this.cost_traffic_tracking === 'child') {
        return 'only_payment';
      }
      if (this.payment_requests === 'child' && this.cost_traffic_tracking === 'parent') {
        return 'only_traffic_tracking';
      }
      return 'all';
    },

    cacheData() {
      return {
        activeTab: this.activeTab,
        sortings: this.sortings,
        querySum: this.querySum,
      };
    },

    needsAttention() {
      return {
        total: this.$store.state.mediaBuying.campaign.needs_attention_total,
        posts: this.$store.state.mediaBuying.campaign.needs_attention_posts,
        payments: this.$store.state.mediaBuying.campaign.needs_attention_payments,
      };
    },

    countPosts() {
      return this.$store.state.mediaBuying.postsCount;
    },
    countPayments() {
      return this.$store.state.mediaBuying.paymentsCountModeration + this.$store.state.mediaBuying.paymentsCountPayout;
    },
    currency() {
      return this.currencyFormatter(this.$store.state.mediaBuying.campaign.payments_currency_code);
    },
  },
  methods: {
    async fetchData(params) {
      if (!params.typeAction && params.action !== 'silent') this.loading = true;
      this.cacheQuery();

      try {
        await fetch(this, params);
      } finally {
        this.loading = false;
      }
    },
    setFromQuery(params) {
      if (Object.keys(params).length) {
        const { activeTab, sortings, querySum } = params;

        this.activeTab = activeTab || this.activeTab;
        this.sortings = sortings || this.sortings;
        this.querySum = querySum || this.querySum;
      }
    },
    async update({
      tab, prop, order, action, ...q
    }, querySum) {
      this.activeTab = tab;
      this.sortings[tab] = {
        sort_column: prop,
        sort_dir: order,
      };

      if (querySum) {
        this.querySum = querySum;
      }

      if (action === 'hard') await this.getCampaign(q.typeAction);
      this.fetchData({
        activeTab: this.activeTab,
        sortings: this.sortings,
        querySum: this.querySum,
        ...q,
      });
    },

    back() {
      this.$router.push('/dashboard/media-buying-campaigns/');
      clearInterval(this.interval);
      document.removeEventListener('mouseup', this.listener);
    },

    backChannel() {
      this.$router.push(JSON.parse(localStorage.getItem('getBackUrlChannel')));
      clearInterval(this.interval);
      document.removeEventListener('mouseup', this.listener);
    },
    getLinks() {
      this.$refs.getLinks.open();
    },
    generateLinks() {
      this.$refs.generateLinks.open();
    },
    editCampaign() {
      this.$refs.editCampaign.open({
        ...this.$store.state.mediaBuying.campaign,
        edited: true,
      });
    },

    async getCampaign(action) {
      if (action !== 'silent') this.isLoadingCampaign = true;

      if (this.$route.params.id === undefined) {
        return;
      }
      this.$store
        .dispatch('getCampaign', this.$route.params.id)
        .then((res) => {
          this.campaignCurrencyCode = res.misc.currency_code;
          this.statusCampaign = res.payload.status;
          this.campaign_name = res.payload.name;
          this.campaign_description = res.payload.description;
          this.balance_sum = res.payload.balance_sum;
          this.spent_sum = res.payload.spent_sum;
          this.paid_sum = res.payload.paid_sum;
          this.payments_on_review_sum = res.payload.payments_on_review_sum;
          this.cost_model = res.payload.cost_model;
          this.payment_requests = res.payload.payment_requests;
          this.cost_traffic_tracking = res.payload.cost_traffic_tracking;
          this.balances = res.payload.balances;
          this.balances_converted = res.payload.balances_converted;
          this.payments = res.payload.payments;
          this.payments_converted = res.payload.payments_converted;
          this.posts = res.payload.posts;
          this.posts_converted = res.payload.posts_converted;
          this.available_spend = res.payload.available_spend;
          this.subRootId = res.payload.root_campaign_id;
        })
        .finally(() => {
          this.isLoadingCampaign = false;
          if (this.getUrlQueryParam('activeTab') && this.getUrlQueryParam('period')) {
            this.activeTab = this.getUrlQueryParam('activeTab');
            this.period = this.getUrlQueryParam('period');
            this.$nextTick(() => {
              this.$refs[`table${this.activeTab}`].setPeriod(this.period);
              this.$refs[`table${this.activeTab}`].fetchData();
            });
          } else {
            if (this.getUrlQueryParam('activeTab')) {
              this.activeTab = this.getUrlQueryParam('activeTab');
            } else {
              this.setActiveTab();
            }

            if (action !== 'silent') {
              if (this.$refs[`table${this.activeTab}`]) {
                this.$refs[`table${this.activeTab}`].fetchData();
              } else {
                this.fetchData({
                  activeTab: this.activeTab,
                  sortings: this.sortings,
                  querySum: this.querySum,
                  action,
                });
              }
            }
          }
        });
    },

    setActiveTab() {
      if (
        this.nestedSettingView === 'only_nested'
        || this.nestedSettingView === 'only_payment'
        || this.nestedSettingView === 'only_traffic_tracking'
      ) {
        this.activeTab = 'campaign';
      } else {
        this.activeTab = 'payments';
      }
    },

    getPosts(params) {
      this.$store.dispatch('getPosts', {
        ...params,
        campaign_id: this.$route.params.id,
      });
    },
    getPayments(params) {
      this.$store.dispatch('getPayments', {
        ...params,
        campaign_id: this.$route.params.id,
      });
    },
    getPromocodes(params) {
      this.loading = true;
      this.$store
        .dispatch('getPromocodes', {
          ...params,
          campaign_id: this.$route.params.id,
        })
        .then(() => (this.loading = false));
    },

    handleTabClick() {
      this.cacheQuery();
    },
    currencyFormatter(code, amount) {
      return `${this.getCurrencyLabel(code)}${formattedCurrency(amount)}`;
    },
    clearTooltip() {
      document.addEventListener(
        'mouseup',
        (this.listener = (e) => {
          const tooltip = document.querySelectorAll('.el-tooltip__popper');

          tooltip.forEach((e, i) => {
            tooltip[i].style.display = 'none';
          });
        }),
      );
    },
  },

  created() {
    this.getCampaign();

    this.setFromQuery(this.passedParams);
    this.$store.dispatch('getSubAffiliates');
    this.$store.dispatch('getPromocodeOffers');
    this.$store.dispatch('getTrafficSources', {});
    this.$store.dispatch('getCurrencies', {});
  },

  async activated() {
    this.expand = false;
    // this.$refs.tablepayments.getDefaultFilter();
    // this.$refs.tableposts.getDefaultFilter();
    await this.getCampaign();

    this.clearTooltip();
  },

  mounted() {
    this.$store.dispatch('uploadSettings');
    eventBus.$on('window-refresh', () => {
      if (this.$route.name === 'routes.mediaBuyingCampaignDetails') {
        this.getCampaign();
        // this.fetchData({
        //   activeTab: this.activeTab,
        //   sortings: this.sortings,
        //   querySum: this.querySum,
        // });
      }
    });
    this.clearTooltip();
  },
  destroyed() {
    clearInterval(this.interval);
  },
  deactivated() {
    clearInterval(this.interval);
  },
};
</script>

<style lang="scss">
@import 'src/assets/theme/default/mediabuying/mediabuyingdetail';
</style>
