/**
 *
 * QueryMetaConfig
 *
 * @param type
 * @param translateTo
 *
 */

import eventBus from 'src/lib/eventBus';
import moment from 'moment';
import HelpersMixin from 'src/components/Helpers/helpers-mixin.js';
import TableFlowExportMixin from 'src/views/Dashboard/Reports/tableFlowExportMixin.js';

export const defaultConfig = {
  defaultParams: {},
  params: [],
  requestOmit: [],
  windowRefreshHook: false,
  fetchOnMounted: false,
  fetchOnActivated: true,
  rules: {
    offset: params => params.page,
  },
  sortByName: 'sort_by',
  sortDirName: 'sort_dir',
  offsetName: 'offset',
  limitName: 'limit',
  searchName: 'search',
  fromName: 'from',
  toName: 'to',
  // null -- no period
  // init -- init params with default values
  // param -- period as param
  period: null,
  export: false,
  fetch: (ctx, params) => {},
};

export function getDefaultPeriod() {
  return {
    from: moment()
      .startOf('month')
      .format('YYYY-MM-DD 00:00:00'),
    to: moment()
      .endOf('day')
      .format('YYYY-MM-DD 23:59:59'),
    period: 30,
  };
}

export default function (tableFlowMixinConfig) {
  tableFlowMixinConfig = {
    ...defaultConfig,
    ...tableFlowMixinConfig,
  };

  if (tableFlowMixinConfig.period) {
    const defaultPeriod = getDefaultPeriod();

    switch (tableFlowMixinConfig.period) {
      case 'init':
        tableFlowMixinConfig.defaultParams = {
          ...tableFlowMixinConfig.defaultParams,
          ...defaultPeriod,
        };
        break;

      case 'param':
        tableFlowMixinConfig.defaultParams = {
          ...tableFlowMixinConfig.defaultParams,
          period: defaultPeriod,
        };
        break;
    }
  }

  const { page, pageSize } = tableFlowMixinConfig.defaultParams;

  if (page) {
    tableFlowMixinConfig.defaultParams[tableFlowMixinConfig.offsetName] = page - 1;
    tableFlowMixinConfig.params.push(tableFlowMixinConfig.offsetName);
  }

  if (pageSize) {
    tableFlowMixinConfig.defaultParams[tableFlowMixinConfig.limitName] = pageSize;
    tableFlowMixinConfig.params.push(tableFlowMixinConfig.limitName);
  }

  const mixins = [
    HelpersMixin,
  ];

  if (tableFlowMixinConfig.export) {
    mixins.push(TableFlowExportMixin);
  }

  return {
    mixins,
    data() {
      const { defaultParams } = tableFlowMixinConfig;

      return {
        tableMixinConfig: tableFlowMixinConfig,
        defaultParams: {
          ...defaultParams,
        },
        ...defaultParams,
        sortByName: tableFlowMixinConfig.sortByName,
        sortDirName: tableFlowMixinConfig.sortDirName,
        search: '',
        oldSearch: '',
        lastQuery: {},
        isTableReady: false,
        loading: false,
      };
    },
    computed: {
      queryParams() {
        return {
          ...this.offsetParams,
          ...this.sorting,
          offset: (this.page - 1) * this.pageSize,
          limit: this.pageSize,
        }
      },
      offsetParams() {
        const { offsetName, limitName } = this.tableMixinConfig;

        return {
          [offsetName]: this[offsetName],
          [limitName]: this[limitName],
        };
      },
      pageParams() {
        return this.isHasPaging
          ? {
            page: this.page,
            pageSize: this.pageSize,
          }
          : {};
      },
      paramsMeta() {
        const result = [];

        const { params, searchName } = tableFlowMixinConfig;

        if (Array.isArray(params)) {
          return params.map((item) => {
            if (item === searchName) {
              return {
                key: item,
                to: item,
                from: 'search',
              };
            }

            return {
              key: item,
              to: item,
              from: item,
            };
          });
        }

        return result;
      },
      paramsMetaKeys() {
        return Object.keys(this.paramsMeta);
      },
      paramsMetaFiltered() {
        const { requestOmit } = tableFlowMixinConfig;
        return this.paramsMeta.filter(item => !requestOmit.includes(item.key));
      },
      cacheData() {
        const { period } = this.tableMixinConfig;

        const result = {};

        for (const meta of this.paramsMeta) {
          result[meta.from] = this[meta.from];
        }

        if (period === 'param') {
          result.period = this.period;
        }

        result.lastQuery = this.params;

        if (this.isHasPaging) {
          const { offsetName, limitName } = this.tableMixinConfig;

          result[offsetName] = (this.page - 1) * this.pageSize;
          result[limitName] = this.pageSize;
        }

        return result;
      },
      isHasPaging() {
        return typeof this.page !== 'undefined' && typeof this.pageSize !== 'undefined';
      },
      currentQuery() {
        const result = {};

        for (const meta of this.paramsMetaFiltered) {
          result[meta.to] = this[meta.from];
        }

        if (this.tableMixinConfig.period === 'param') {
          const { fromName, toName } = this.tableMixinConfig;

          result[fromName] = this.period.from;
          result[toName] = this.period.to;

          delete result.period;
        }

        if (this.isHasPaging) {
          const { offsetName, limitName } = this.tableMixinConfig;

          result[offsetName] = (this.page - 1) * this.pageSize;
          result[limitName] = this.pageSize;
        }

        return result;
      },
      sorting: {
        get() {
          this.dependsHack();

          const { sortByName, sortDirName } = this.tableMixinConfig;

          return {
            [sortByName]: this[sortByName],
            [sortDirName]: this[sortDirName],
          };
        },
        set(value) {
          const { sortByName, sortDirName } = this.tableMixinConfig;

          this[sortByName] = value[sortByName];
          this[sortDirName] = value[sortDirName];
        },
      },
    },
    watch: {
      pageParams(value) {
        if (this.isHasPaging) {
          const { offsetName, limitName } = this.tableMixinConfig;

          this[offsetName] = value.page - 1;
          this[limitName] = value.pageSize;
        }
      },
    },
    async mounted() {
      await this.afterBrowserRedraw();

      if (this.tableMixinConfig.fetchOnMounted) {
        await this.fetchData(this.passedParams.lastQuery, this.fetchedData);
      }

      this.isTableReady = true;

      if (tableFlowMixinConfig.windowRefreshHook) {
        const routeName = this.$route.name;

        eventBus.$on('window-refresh', () => {
          if (this.$route.name === routeName) {
            this.onBusWindowRefresh();
          }
        });
      }
    },
    async activated() {
      if (this.tableMixinConfig.fetchOnActivated) {
        await this.fetchData(this.lastQuery);
        await this.afterBrowserRedraw();
      }

      this.isTableReady = true;
    },
    methods: {
      async fetch(query) {
        return await this.tableMixinConfig.fetch(this, query);
      },
      async getFetchDataParams(query) {
        const params = {
          ...query,
        };

        return params;
      },
      async preFetchDataParams(query) {
        return false;
      },
      async fetchData(query, preFetched) {
        if (this.loading) {
          return;
        }

        this.loading = true;

        this.search = this.oldSearch;

        const params = await this.getFetchDataParams(query);

        const result = preFetched || await this.fetch(params, preFetched);

        this.processFetchResult(result);

        this.lastQuery = params;

        this.cacheQuery();
        this.afterBrowserRedraw(() => {
          this.loading = false;
        });
      },

      fetchDataTable(query, preFetched) {
        if (this.isTableReady) {
          query = {
            ...this.currentQuery,
            ...query,
          };

          this.fetchData(query, preFetched);
        }
      },

      onBusWindowRefresh() {
        this.refresh();
      },

      setFromQuery(params) {
        const { period } = this.tableMixinConfig;

        if (params) {
          for (const meta of this.paramsMeta) {
            this[meta.from] = params[meta.to] || this[meta.from];
          }

          if (period === 'param') {
            this.period = params.period || getDefaultPeriod();
          }
        }
      },

      refresh() {
        this.search = this.oldSearch;
        this.fetchData(this.$_.cloneDeep(this.currentQuery));
      },

      handleSearch() {
        this.resetPager();

        const { searchName } = this.tableMixinConfig;

        this.oldSearch = this.search;

        this.fetchData({
          ...this.currentQuery,
          ...this.period,
          [searchName]: this.search,
        });
      },

      clearInput() {
        if (this.search.trim()) {
          this.search = '';
          this.handleSearch();
        }
      },

      resetPager() {
        this.offset = 0;
        this.page = 1;
      },

      setPeriod(period) {
        if (this.period && !this.$_.isEqual(this.period, period)) {
          this.group_by === 'hour' ? this.page = 1 : this.page = this.oldpage || 1;
        }

        if (period.from.length <= 10) {
          return;
        }

        this.period = period;
      },

      handleFilter(filter) {
        this.columnsFilter = {};

        Object
          .keys(filter)
          .forEach((key) => {
            if (filter[key].value && filter[key].op) {
              this.columnsFilter[key] = {
                op: filter[key].op,
                value: filter[key].value,
              };
            }
          });
        this.fetchDataTable('handle columns filter');
      },

      /** @override */
      dependsHack() {},

      /** @override */
      processFetchResult(data) {},
    },
  };
}
