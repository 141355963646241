import Cookie from 'src/lib/cookieProvider';

export default function (to, from, next) {
  Object.keys(localStorage)
    .forEach((key) => {
      localStorage.removeItem(key);
    });
  localStorage.setItem('auth-token', to.params.token);
  Cookie.set('autologin_token', to.params.token, 15778463000, true);
  return next('/dashboard/main');
}
