<template>
  <section class="daily-report daily-report-loader">
    <div class="card">
      <div class="mb-20">
        <div>
          <div>
            <skeleton height="34px" width="212px" class="mr-12" />
            <skeleton height="34px" width="68px" />
          </div>
        </div>
        <div>
          <div>
            <skeleton height="34px" width="34px" class="mr-12" />
            <skeleton height="34px" width="34px" class="mr-12" />
            <skeleton height="34px" width="34px" class="mr-12" />
          </div>
          <div>
            <skeleton height="34px" width="80px" class="mr-12" />
            <skeleton height="34px" width="212px" />
          </div>
        </div>
      </div>
      <skeleton width="auto" height="auto" />
    </div>
  </section>
</template>

<script>
import Skeleton from '../../components/Skeleton';

export default {
  components: {
    Skeleton,
  },
};
</script>

<style lang="scss">
@import 'src/assets/theme/default/reports/players';
</style>
