export default (mock) => {
  mock.onGet('media').reply(200, {
    success: true,
    payload: [
      {
        id: 'string',
        name: 'BetWinner Reliable Bookmaker',
        type: 'html5',
        status: 'active',
        sizes: [
          'string',
        ],
        languages: [
          'string',
        ],
        created_at: '2018-07-24T13:39:54.641Z',
        updated_at: '2018-07-24T13:39:54.641Z',
      },
      {
        id: 'string',
        name: 'BetWinner Reliable Bookmaker',
        type: 'video',
        status: 'active',
        sizes: [
          'string',
        ],
        languages: [
          'string',
        ],
        created_at: '2018-07-24T13:39:54.641Z',
        updated_at: '2018-07-24T13:39:54.641Z',
      },
      {
        id: 'string',
        name: 'BetWinner Reliable Bookmaker',
        type: 'image',
        status: 'active',
        sizes: [
          'string',
        ],
        languages: [
          'string',
        ],
        created_at: '2018-07-24T13:39:54.641Z',
        updated_at: '2018-07-24T13:39:54.641Z',
      },
      {
        id: 'string',
        name: 'BetWinner Reliable Bookmaker',
        type: 'html5',
        status: 'active',
        sizes: [
          'string',
        ],
        languages: [
          'string',
        ],
        created_at: '2018-07-24T13:39:54.641Z',
        updated_at: '2018-07-24T13:39:54.641Z',
      },
      {
        id: 'string',
        name: 'BetWinner Reliable Bookmaker',
        type: 'video',
        status: 'active',
        sizes: [
          'string',
        ],
        languages: [
          'string',
        ],
        created_at: '2018-07-24T13:39:54.641Z',
        updated_at: '2018-07-24T13:39:54.641Z',
      },
    ],

    errors: [
      {},
    ],
  });
};
