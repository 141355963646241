<template>
  <div class="col-md-12 ml-auto mr-auto reg-confirm">
    <div class="login-logo">
      <img :src="logo" alt="logo" />
    </div>

    <div v-loading="isLoading" class="reg-confirm__body">
      <div class="row">
        <div class="col-md-6 col-6 reg-confirm__logo-icon">
          <i :class="`${config.icon.name} ${config.icon.color}`"></i>
        </div>
        <div class="col-md-6 col-6 registration__login-back login-back-stik">
          <span class="login-back-stik__title">{{ $t('registration_confirm.go_acc') }} </span>
          <router-link to="/" class="login-back-stik__link">
            {{ $t('registration.log_in') }}
          </router-link>
        </div>
      </div>
      <div class="reg-confirm__title">
        {{ config.title }}
      </div>
      <div class="reg-confirm__text-first" v-html="config.textFirst"></div>
      <div class="reg-confirm__text-second">
        {{ config.textSecond }}
      </div>

      <div class="reg-confirm__link-wrap">
        <div class="row ui-jc-center">
          <div
            class="col-12"
            v-for="btn in config.btn"
            :key="btn.text"
            :class="config.btn.length === 4 ? 'col-md-3' : 'col-md-4'"
          >
            <a :href="btn.link" target="_blank" rel="noopener noreferrer" class="btn-custon" :class="btn.text">
              <img :src="btn.imgURL" alt="" />
              <span class="btn-custon__text">{{ btn.text }}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      statusReq: '',
    };
  },

  created() {
    if (this.routePath === '/verify-email' || this.routePath === '/verify-additional-email-dms') {
      this.verify();
    }
  },

  computed: {
    contacts() {
      const contacts = this.$store.getters.getterMiscSetting.contacts || {};

      return Object.entries(contacts)
        .filter(([, value]) => !!value)
        .map(([key, value]) => ({
          text: key,
          imgURL: require(`@/assets/img/white-social-icon/${key}-logo.svg`),
          link: this.hrefEncode(key, value),
        }));
    },

    routePath() {
      return this.$route.path;
    },

    tplForStatus() {
      if (this.statusReq === 'verified_successfully') {
        return {
          icon: {
            name: 'fas fa-envelope',
            color: 'success',
          },
          title: this.$t('registration_confirm.verified_successfully.title'),
          textFirst: this.$t('registration_confirm.verified_successfully.textFirst'),
        };
      }
      if (this.statusReq === 'already_verified') {
        return {
          icon: {
            name: 'fas fa-envelope',
            color: 'danger',
          },
          title: this.$t('registration_confirm.already_verified.title'),
          textFirst: this.$t('registration_confirm.already_verified.textFirst'),
        };
      }
      if (this.statusReq === 'unable_to_verify') {
        return {
          icon: {
            name: 'fas fa-frown',
            color: 'danger',
          },
          title: this.$t('registration_confirm.unable_to_verify.title'),
          textFirst: this.$t('registration_confirm.unable_to_verify.textFirst'),
        };
      }
      return {};
    },

    config() {
      const tpl = {
        icon: {
          name: 'far fa-check',
          color: 'success',
        },
        title: this.$t('registration_confirm.registration_successfully.title'),
        textFirst: this.$t('registration_confirm.registration_successfully.textFirst'),
        textSecond: this.$t('registration_confirm.registration_successfully.textSecond'),
        btn: this.contacts,
      };
      if (this.routePath === '/verify-email' || this.routePath === '/verify-additional-email-dms') {
        return {
          ...tpl,
          ...this.tplForStatus,
        };
      }
      return tpl;
    },

    logo() {
      return this.$config.layout.logoFull;
    },
  },

  methods: {
    hrefEncode(type, contact) {
      if (type === 'skype') return `skype:${contact}?call`;
      if (type === 'email') return `mailto:${contact}`;
      if (type === 'telegram') return `https://t.me/${contact}`;
      return contact;
    },

    async verify() {
      this.isLoading = true;
      const { query } = this.$route;
      try {
        if (this.routePath === '/verify-email') {
          const { payload } = await this.$api.postVerifyEmail(query);
          this.statusReq = payload.verification_status;
        }

        if (this.routePath === '/verify-additional-email-dms') {
          const { payload } = await this.$api.postVerifyAdditionalEmail(query);
          this.statusReq = payload.verification_status;
        }
      } catch (error) {
        this.statusReq = 'unable_to_verify';
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
