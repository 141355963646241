export default (mock) => {
  mock.onGet('/wallets').reply(200, {
    success: true,
    payload: [
      {
        id: '0000',
        affiliate_id: 'qweqweqweqweq',
        currency_code: 'usd',
        hold_balance: 2001,
        available_balance: 600,
        created_at: '2018-07-16T06:43:59.681Z',
        updated_at: '2018-07-16T06:43:59.681Z',
      },
    ],
    errors: [{}],
  });

  const history = {
    success: true,
    payload: [],
    errors: [{}],
  };

  for (let i = 0; i < 100; i++) {
    history.payload.push({
      grouping: 'string',
      sum_add_to_hold: i,
      sum_transfer_to_available: 1000 + i,
      sum_to_withdraw: 300 + i,
      sum_to_withdraw_abort: 20 + i,
      sum_to_add_bonus: 0,
      sum_to_chargeback: 40 + i,
      result_balance_hold: 1000 + 300 + i,
      result_balance_available: 300 + i,
    });
  }

  mock.onGet('wallets/0000/history').reply((config) => {
    const data = history;
    if (config.start_timestamp && config.end_timestamp) {
      data.payload = history.payload.slice(0, 5);
    }
    return [200, history];
  });
};
