export default {
  methods: {
    /* | */
    requestSend(flag) {
      this.loading = true;
      if (flag === 'save') this.saveFlag = true;
      this.fileListBackup = this.$_.cloneDeep(this.fileList);
      this.fileListDouble = this.$_.cloneDeep(this.fileList);

      this.idNeedsDeleted.forEach((e) => {
        const isThereId = this.fileListDouble.findIndex(el => el.id === e);
        if (isThereId !== -1) {
          this.fileListDouble.splice(isThereId, 1);
        } else {
          const indexDeleteFile = this.form.file_ids.findIndex(el => el === e);
          this.form.file_ids.splice(indexDeleteFile, 1);
        }
      });
      this.fileIdsBackup = this.$_.clone(
        this.form.file_ids,
      ); /* cloneEditComment */
      this.countFile = this.fileListDouble.filter(
        e => !e.hasOwnProperty('id'),
      ).length;
      this.loadImage();
    },
    /* | */
    loadImage() {
      this.errorFileDown = [];
      this.saveImageDefaultAdd = this.$_.cloneDeep(this.fileList);
      if (this.countFile === 0) {
        this.idNeedsDeleted = this.$_.clone(this.form.file_ids);
        this.fileList = this.fileListDouble;
        this.saveFlag ? this.save() : this.send();
        this.saveFlag = false;
        return;
      }
      if (this.countFile !== 0) {
        const formData = new FormData();
        const lastFile = this.fileListDouble[0];
        formData.append('files', this.fileListDouble[0].raw);
        this.$store
          .dispatch('uploadFiles', formData)
          .then((res) => {
            this.fileListDouble.splice(0, 1);
            this.countFile -= 1;
            this.form.file_ids.push(res.payload.files.id);
            this.loadImage();
          })
          .catch((error) => {
            this.saveFlag = false;
            this.fileList = this.saveImageDefaultAdd;
            this.fileListDouble = this.saveImageDefaultAdd;
            this.form.file_ids = this.fileIdsBackup;

            this.errorFileDown.push(`File "${lastFile.raw.name}" is not valid`);
          });
      }
    },
    /* | */
    handleRemove(file, fileList) {
      this.fileList = fileList;
      this.countFileComment = fileList.length;
    },
    /* | */
    onSuccess(file, fileList) {
      this.fileList = fileList;
      this.fileList.length
      > this.$store.state.mediaBuying.uploadSettingsData.max_files_per_request
        ? (this.fileList.length = this.$store.state.mediaBuying.uploadSettingsData.max_files_per_request)
        : this.fileList;
      this.countFile = this.fileList.length;
    },
  },
};
