export default () => ({
  languages: [],
  countries: [],
  paymentMethods: [],
  timezones: [],
  messengers: [],
  urlQueries: {},
  translatedlanguages: [],
  translations: [],
  availableLanguages: [],
  availableLanguagesPromise: null,
  channelIcons: [],
  postPlatforms: [],
  postPlatformsCount: [],
  currencies: [],
  customerCurrencies: [],
  tagTemplates: [],
  notifications: [],
});
