<template>
  <drop-down
    tag="li"
    position="center"
    class="language-select nav-item balance languages-navbar"
  >
    <a
      slot="title"
      class="nav-link"
      href="#"
      @click.prevent
    >
      <div class="d-flex cont-it">
        <img
          :src="flags[selectedLanguageCode]"
          height="15"
          class="mt-2 mr-2"
          v-if="flags[selectedLanguageCode]"
          :alt="selectedLanguageCode"
        />
        <span class="pl-4 text-uppercase">{{ selectedLanguageCode }}</span>
        <i class="fas fa-angle-down ml-4 mt-4" />
      </div>
    </a>
    <ul class="lang__items pl-0">
      <li
        v-for="language in availableLanguages"
        :key="language.code"
        :class="{active: selectedLanguageCode === language.code}"
        class="d-flex justify-content-between"
        @click="changeLanguage(language.code)"
      >
        <div>
          <img
            :src="flags[language.code]"
            height="14"
            class="mr-2"
            v-if="flags[language.code]"
            :alt="language.code"
          />
          <span
            href="#"
            class="lang__link"
          >
            {{ language.name }}
          </span>
        </div>
        <div class="checkbox mt-4" />
      </li>
    </ul>
  </drop-down>
</template>

<script>
import deFlag from '@/assets/img/de-top.png';
import enFlag from '@/assets/img/en-top.png';
import esFlag from '@/assets/img/es-top.png';
import frFlag from '@/assets/img/fr-top.png';
import heFlag from '@/assets/img/he-top.png';
import ptFlag from '@/assets/img/pt-top.png';
import ruFlag from '@/assets/img/ru-top.png';
import trFlag from '@/assets/img/tr-top.png';
import bnFlag from '@/assets/img/bn-top.png';


export default {
  name: 'LanguageSelect',

  data() {
    return {
      selectedLanguageCode: localStorage.getItem('language') || 'en',
      availableLanguages: [],
      flags: {
        de: deFlag,
        en: enFlag,
        es: esFlag,
        fr: frFlag,
        he: heFlag,
        pt: ptFlag,
        ru: ruFlag,
        tr: trFlag,
        bn: bnFlag,
      },
    };
  },

  created() {
    this.setAvailableLanguages();
  },

  methods: {
    setAvailableLanguages() {
      this.$store.dispatch('getAvailableLanguages')
        .then((availableLanguages) => {
          this.availableLanguages = availableLanguages;
        });
    },

    changeLanguage(code) {
      this.selectedLanguageCode = code;
      this.$locale = code;
    },
  },

};
</script>

<style>

</style>
