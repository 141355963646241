<template>
  <div class="col-md-5 ml-auto mr-auto login-wrapper login__main">
    <div class="login-logo">
      <a href="/">
        <img :src="logo" alt="logo">
      </a>
    </div>

    <form :class="{ 'loading':loading, 'error':error }" @submit.prevent>
      <card class="card-login">
        <div>
          <h6>{{ $t('forms.emailLabel') }}</h6>
          <input
            v-model="model.email"
            :class="{'error': emailValidation && !valid_email }"
            :placeholder="$t('forms.emailPlaceholder')"
            type="email"
            class="login-input"
            autofocus
            @focus="emailValidation = false; passwordValidation = false"
          >
          <div v-if="emailValidation && !valid_email" class="text-danger text-right">
            {{ $t('forms.validation.email') }}
          </div>

          <h6>{{ $t('forms.passwordLabel') }}</h6>
          <div class="text-right" style="height:0px;">
            <i
              :class="{'fa-eye':!passwordShown, 'fa-eye-slash': passwordShown}"
              class="fas fa-eye text-muted"
              @click="showPassword"
            />
          </div>
          <input
            v-model="model.password"
            :type="passwordShown && 'text' || 'password'"
            :class="{'error': passwordValidation && !valid_password }"
            placeholder="***********"
            class="login-input"
            @focus="emailValidation = true; passwordValidation = false"
            @blur="passwordValidation = true"
            @keyup.enter="valid_password && valid_email && login"
          >
          <div v-if="passwordValidation && !valid_password" class="text-danger text-right">
            {{ $t('forms.validation.password') }}
          </div>
        </div>
        <div class="ui-m-lg-b" v-show="isCaptchaV2Required">
          <vue-recaptcha
            @verify="verifyV2"
            ref="captchaV2"
            :sitekey="$store.state.misc.miscSettings.recaptcha.public_key_v2"
          />
        </div>
        <div class="actions ui-m-lg-b ui-align-center">
          <div class="remember_me_checkbox">
            <div class="checkbox check">
              <input
                id="checklog"
                v-model="model.remember"
                type="checkbox"
              >
              <label class="checkbox-label remText" for="checklog">
                {{ $t('forms.remember_me') }}
              </label>
            </div>
          </div>
          <router-link
            :to="{ path: '/restore-password'}"
            replace
          >
            {{ $t('forms.forgot_password') }}
          </router-link>
        </div>

        <div class="button__login">
          <button
            :disabled="!valid_password || !valid_email || (isCaptchaV2Required && !recaptchaTokenV2) || loading"
            class="btn btn-primary btn-block big login btn-md"
            @click="login"
          >
            {{ $t('forms.btn.login') }} >
          </button>
          <div class="text-center link_sign-up">
            <span>{{ $t('forms.notAccount') }}</span>
            <router-link class="ml-8" to="/registration">
              {{ $t('forms.signUp') }}
            </router-link>
          </div>
        </div>
      </card>

      <div class="loading__block">
        <i class="fa fa-spinner fa-3x fa-fw login__spinner" />
      </div>

      <div class="error__block">
        <i class="far fa-times-circle" />
        <h2 class="login__error-title">
          {{ $t($store.state.errors.message) }}
        </h2>
        <button class="btn btn-primary btn-block big login btn-md" @click="error = false">
          &lt; {{ $t('forms.btn.back') }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { required, email, minLength } from 'vuelidate/lib/validators';
import eventBus from 'src/lib/eventBus';

export default {
  data() {
    return {
      passwordShown: false,
      passwordValidation: false,
      emailValidation: false,
      isCaptchaV2Required: false,
      recaptchaTokenV2: null,
      recaptchaTokenV3: null,
      loading: false,
      count: 0,
      error: false, // не используется?
      model: {
        email: localStorage.getItem('email') || '',
        password: '',
        remember: true,
      },
    };
  },
  validations: {
    model: {
      password: {
        required,
        minLength: minLength(8),
      },
      email: {
        required,
        email,
      },
    },
  },
  computed: {
    valid_email() {
      const _email = this.$v.model.email;
      return _email.email && _email.required;
    },
    valid_password() {
      const _password = this.$v.model.password;
      return _password.minLength && _password.required;
    },
    logo() {
      return this.$config.layout.logoFull;
    },
  },

  mounted() {
    document.title = `${this.$t(this.$route.name)} - ${this.$config.pageTitle}`;
    eventBus.$on('otp-dialogue', () => {
      this.$router.push('/second-factor');
    });
  },

  watch: {
    'model.email': function modelEmail() {
      localStorage.setItem('email', this.model.email);
    },
  },

  methods: {
    verifyV2(token) {
      this.recaptchaTokenV2 = `google_recaptcha_v2__${token}`;
    },
    async recaptcha() {
      try {
        await this.$recaptchaLoaded();
        const token = await this.$recaptcha('login');
        this.recaptchaTokenV3 = `google_recaptcha_v3__${token}`

      } catch (error) {
        console.log(error);
      }
    },
    async login() {
      if (this.invalid_form) return;
      this.loading = true;

      const recaptcha_token = this.recaptchaTokenV2 || this.recaptchaTokenV3;

      await this.$store.dispatch('login', {
        ...this.model,
        ...(recaptcha_token && { recaptcha_token }),
      })
        .then(() => {
          setTimeout(async () => {
            if (this.$route.name !== 'routes.2fa') {
              const profileData = await this.$store.dispatch('getProfileData');
              /**
               * setting language from profile
               */
              localStorage.setItem('language', profileData.language_code);
              this.$locale = profileData.language_code;
              await this.$store.dispatch('getSettingsLazy');

              this.$router.push(this.$config.mainScreen);
            }
          }, 1000);
        })
        .catch(async (err) => {
          const errors = err.response.data?.errors || [];
          if (errors.hasOwnProperty('recaptcha_token')) {
            if (!this.recaptchaTokenV3) {
              await this.recaptcha();
              return await this.login();
            }

            if (!this.recaptchaTokenV2) {
              this.isCaptchaV2Required = true;
              this.loading = false;
              return;
            }

            this.$alert.error(this.$t('alerts.captchaRequestFailed'));
            this.loading = false;
            return;
          }

          if (err.response && err.response.data.code === 'AUTH_INVALID_CREDENTIALS_ERROR') {
            this.$router.push('/wrong-credentials');
          }
          if (err.response && err.response.data.message) {
            this.$alert.error(err.response.data.message);
          }
          this.loading = false;
        })
        .finally(() => {
          this.$refs.captchaV2?.reset()
          this.recaptchaTokenV2 = null;
        });
    },
    showPassword() {
      this.passwordShown = !this.passwordShown;
    },
  },
};
</script>

<style lang="scss">
@import 'styles/login';
</style>
