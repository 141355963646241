<template>
  <modal :show.sync="active" class="modal-password enable-success">
    <h5 slot="header" class="modal-title">
      {{ $t('profile.modalPasswordTwoFactor.title') }}
    </h5>
    <template>
      <div class="text-center">
        <h1 class="text-center mt-32">
          <img :src="require('@/assets/theme/icon/ok.svg')" alt="">
        </h1>
        <p class="mb-32">
          {{ $t('profile.modalPasswordTwoFactor.securityCode') }}
        </p>
      </div>
    </template>
    <div slot="footer" class="text-center">
      <button class="btn btn-primary btn-md" @click="active = false">
        {{ $t('profile.modalPasswordTwoFactor.btn') }}
      </button>
    </div>
  </modal>
</template>

<script>
import { Modal } from 'src/components/index';

export default {
  components: {
    Modal,
  },
  data() {
    return {
      active: false,
    };
  },
  methods: {
    open() {
      this.active = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.enable-success {
  .btn-primary {
    min-width: 196px !important;
  }
}
</style>
