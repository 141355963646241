var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"public-table media-buying-ad",class:{
    wavebg: _vm.loading
  }},[_c('table-card',{ref:"datatable",attrs:{"loading":_vm.loading,"columns":_vm.columns,"sort":_vm.sorting,"sort-by-name":"prop","sort-dir-name":"order","rows":_vm.rows,"page":_vm.page,"page-size":_vm.pageSize,"count":_vm.$store.state.promocode.totalRows,"formaters":_vm.formaters,"actions":_vm.actions,"header":_vm.$t('mediaBuying.campaigns.promo_codes'),"is-static":"","dont-fill-empty":"","custom-context":"","disable-actions":_vm.isArchived,"has-pagination":true,"placementTooltip":"bottom","i18n-path":"mediaBuying.promocodes"},on:{"update:sort":function($event){_vm.sorting=$event},"update:page":function($event){_vm.page=$event},"update:pageSize":function($event){_vm.pageSize=$event},"update:page-size":function($event){_vm.pageSize=$event},"fetch-data":_vm.fetchDataTable}},[_c('div',{class:[
        'ui-d-flex',
        'ui-desktop-no-wrap',
        'ui-mobile-wrap',
        'ui-mobile-12',
      ],attrs:{"slot":"header"},slot:"header"},[_c('table-card-search-header',{staticClass:"ui-g-md",on:{"search":_vm.handleSearch},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('div',{staticClass:"ui-g-md ui-mobile-12",attrs:{"slot":"actionsPrepend"},slot:"actionsPrepend"},[_c('button',{staticClass:"btn btn-primary btn-sm ui-mobile-12",attrs:{"disabled":_vm.isArchived},on:{"click":_vm.addPromocode}},[_vm._v(" + "+_vm._s(_vm.$t('mediaBuying.campaigns.add_promocode'))+" ")])])]),_c('add-promo-code',{ref:"addCode",on:{"addPromoCode":_vm.update}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }