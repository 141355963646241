<template>
  <div class="col-md-4 ml-auto mr-auto login-wrapper blocked-account">
    <div class="login-logo">
      <img :src="logo" alt="logo">
    </div>

    <form>
      <card class="card-login">
        <i class="fas fa-exclamation-triangle d-flex justify-content-center" />
        <h3>
          {{ $t('forms.blockedAccount.yourAccount') }}
          <br>
          {{ $t('forms.blockedAccount.isBlocked') }}
        </h3>
        <div class="row">
          <div class="col-12 blocked-account__text">
            {{ $t('forms.blockedAccount.blockedAccountText') }}.
          </div>
        </div>
        <div class="btn-box d-flex justify-content-between">
          <button class="btn btn-outline-primary btn-md" @click="$router.push('/logout')">
            &lt; {{ $t('forms.btn.back') }}
          </button>
          <a
            class="btn btn-primary btn-md"
            :href="contactsUrl"
            target="_blank"
          >
            {{ $t('forms.btn.contactUs') }}
          </a>
        </div>
      </card>
    </form>
  </div>
</template>

<script>
export default {
  computed: {
    logo() {
      return this.$config.layout.logoFull;
    },
    contactsUrl() {
      return `https://${this.$config.frontPageUrl}/contact-us`;
    },
  },
};
</script>
