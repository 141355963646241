<template>
  <div class="row summary-tables pl-3 mt-16">
    <div class="col-12 col-md-6 pl-0 pr-8">
      <div class="card">
        <h5>{{ $t('summary.tables.general') }}</h5>

        <!-- GENERAL -->
        <table class="table table-full-width">
          <tbody>
            <!--Impressions-->
            <tr v-if="model.impressions_count !== null">
              <td class>
                {{ metrics.impressions_count }}
              </td>
              <td>{{ model.impressions_count }}</td>
            </tr>

            <!--Visits-->
            <tr v-if="model.visits_count !== null">
              <td class>
                {{ metrics.visits_count }}
              </td>
              <td>{{ model.visits_count }}</td>
            </tr>

            <!--Profit -->
            <tr v-if="model.net_profit_sum !== null">
              <td class>
                {{ metrics.net_profit_sum }}
              </td>
              <td v-html="this.formatPrice(model.net_profit_sum)" />
            </tr>

            <!--Revenue -->
            <tr v-if="model.revenue_sum !== null">
              <td class>
                {{ metrics.revenue_sum }}
              </td>
              <td v-html="this.formatPrice(model.revenue_sum)" />
            </tr>
          </tbody>
        </table>
      </div>

      <div class="card">
        <h5>{{ $t('summary.tables.registrations') }}</h5>

        <!-- REGISTRATIONS -->
        <table class="table table-full-width">
          <tbody>
            <!--Registrations-->
            <tr v-if="model.registrations_count !== null">
              <td class>
                {{ metrics.registrations_count }}
              </td>
              <td>{{ model.registrations_count }}</td>
            </tr>

            <!--Registrations / Visits Ratio-->
            <tr v-if="model.registrations_count !== null && model.visits_count !== null">
              <td class>
                {{ $t('customReports.registrations_visits_ratio') }}
              </td>
              <td v-html="this.percent(model.registrations_count, model.visits_count)" />
            </tr>

            <!--New Deposits / Registrations Ratio-->
            <tr v-if="model.deposits_first_count !== null && model.registrations_count !== null">
              <td class>
                {{ $t('customReports.new_deposits_amount_registrations_ratio') }}
              </td>
              <td
                v-html="this.percent(model.deposits_first_count, model.registrations_count)"
              />
            </tr>
          </tbody>
        </table>
      </div>

      <div class="card">
        <h5>{{ $t('summary.tables.deposits') }}</h5>

        <!-- DEPOSITS -->
        <table class="table table-full-width">
          <tbody>
            <!--Number of Deposits-->
            <tr v-if="model.deposits_all_count !== null">
              <td class>
                {{ metrics.deposits_all_count }}
              </td>
              <td>{{ model.deposits_all_count }}</td>
            </tr>

            <!--Accounts with Deposits-->
            <tr v-if="model.players_with_deposit_count !== null">
              <td class>
                {{ metrics.players_with_deposit_count }}
              </td>
              <td>{{ model.players_with_deposit_count }}</td>
            </tr>

            <!--New Depositors-->
            <tr v-if="model.players_with_first_deposit_count !== null">
              <td class>
                {{ metrics.players_with_first_deposit_count }}
              </td>
              <td>{{ model.players_with_first_deposit_count }}</td>
            </tr>

            <!--New Deposits Amount-->
            <tr v-if="model.deposits_first_sum !== null">
              <td class>
                {{ metrics.deposits_first_sum }}
              </td>
              <td v-html="this.formatPrice(model.deposits_first_sum)" />
            </tr>

            <!--Deposits Bonuses-->
            <tr v-if="model.deposit_bonus_count !== null">
              <td class>
                {{ metrics.deposit_bonus_count }}
              </td>
              <td>{{ model.deposit_bonus_count }}</td>
            </tr>

            <!--Deposits Bonuses Amount-->
            <tr v-if="model.deposit_bonus_sum !== null">
              <td>{{ metrics.deposit_bonus_sum }}</td>
              <td v-html="this.formatPriceDep(model.deposit_bonus_sum)" />
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="col-12 col-md-6 pr-0 pl-8">
      <div class="card">
        <h5>{{ $t('summary.tables.bets') }}</h5>
        <!-- BETS -->
        <table class="table table-full-width">
          <tbody>
            <!--Players with Bet-->
            <tr v-if="model.players_with_bet_count !== null">
              <td class>
                {{ metrics.players_with_bet_count }}
              </td>
              <td>{{ model.players_with_bet_count }}</td>
            </tr>

            <!--New Sports Bets -->
            <tr v-if="model.bet_new_sports_count !== null">
              <td class>
                {{ metrics.bet_new_sports_count }}
              </td>
              <td>{{ model.bet_new_sports_count }}</td>
            </tr>

            <!--Sports Bets Results -->
            <tr v-if="model.bet_result_sports_count !== null">
              <td class>
                {{ metrics.bet_result_sports_count }}
              </td>
              <td>{{ model.bet_result_sports_count }}</td>
            </tr>

            <!--Sports Bets Profit -->
            <tr v-if="model.bet_result_sports_profit_sum !== null">
              <td class>
                {{ metrics.bet_result_sports_profit_sum }}
              </td>
              <td v-html="this.formatPrice(model.bet_result_sports_profit_sum)" />
            </tr>

            <tr>
              <td colspan="2">
                <br>
              </td>
            </tr>

            <!--New Games Bets-->
            <tr v-if="model.bet_new_games_count !== null">
              <td class>
                {{ metrics.bet_new_games_count }}
              </td>
              <td>{{ model.bet_new_games_count }}</td>
            </tr>

            <!--Games Bets Results-->
            <tr v-if="model.bet_result_games_count !== null">
              <td class>
                {{ metrics.bet_result_games_count }}
              </td>
              <td>{{ model.bet_result_games_count }}</td>
            </tr>

            <!--Games Bets Profit-->
            <tr v-if="model.bet_result_games_profit_sum !== null">
              <td class>
                {{ metrics.bet_result_games_profit_sum }}
              </td>
              <td v-html="this.formatPrice(model.bet_result_games_profit_sum)" />
            </tr>

            <tr>
              <td colspan="2">
                <br>
              </td>
            </tr>

            <!--Bet Sells-->
            <tr v-if="model.bet_sell_count !== null">
              <td class>
                {{ metrics.bet_sell_count }}
              </td>
              <td>{{ model.bet_sell_count }}</td>
            </tr>

            <!--Bet Sell Profit-->
            <tr v-if="model.bet_sell_profit_sum !== null">
              <td class>
                {{ metrics.bet_sell_profit_sum }}
              </td>
              <td v-html="this.formatPrice(model.bet_sell_profit_sum)" />
            </tr>

            <!--Bet Insurances-->
            <tr v-if="model.bet_insurance_count !== null">
              <td class>
                {{ metrics.bet_insurance_count }}
              </td>
              <td>{{ model.bet_insurance_count }}</td>
            </tr>

            <!--Bet Insurances Amount-->
            <tr v-if="model.bet_insurance_sum !== null">
              <td class>
                {{ metrics.bet_insurance_sum }}
              </td>
              <td v-html="this.formatPrice(model.bet_insurance_sum)" />
            </tr>

            <!--Paid Insurances-->
            <tr v-if="model.bet_insurance_result_count !== null">
              <td class>
                {{ metrics.bet_insurance_result_count }}
              </td>
              <td>{{ model.bet_insurance_result_count }}</td>
            </tr>

            <!--Insurance payments-->
            <tr v-if="model.bet_insurance_result_sum !== null">
              <td class>
                {{ metrics.bet_insurance_result_sum }}
              </td>
              <td v-html="this.formatPrice(model.bet_insurance_result_sum)" />
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
import HelpersMixin from 'src/components/Helpers/helpers-mixin.js';
import formatCurrency from '@/mixins/format-currency';

export default {
  props: ['model'],
  mixins: [
    HelpersMixin,
    formatCurrency,
  ],
  data() {
    return {

    };
  },
  computed: {
    currencyLabel() {
      return this.getCurrencyLabel(this.$store.state.report.currencyCode);
    },
    metrics() {
      const obj = {};

      const metrics = this.$store.state.settings.metrics || [];
      metrics.forEach((e) => {
        obj[e.column_name] = this.formatMetric(e, this.currencyLabel).title;
      });

      return obj || {};
    },
  },
  watch: {
    '$i18n.locale': function () {
      if (this.isComponentActivated) {
        this.$store.dispatch('getSettingsLazy');
      }
    },
  },
  methods: {
    formatPrice(amount) {
      const sum = this.$options.filters.currency(amount, this.currencyLabel, null, {
        symbolOnLeft: true,
        spaceBetweenAmountAndSymbol: false,
        decimalSeparator: ',',
      });

      if (amount < 0) {
        return `<span class="text-red">${sum}</span>`;
      }

      if (amount > 0) {
        return `<span class="text-green">${sum}</span>`;
      }

      if (amount != undefined) {
        return `<span>${sum}</span>`;
      }
    },
    formatPriceDep(amount) {
      const sum = this.$options.filters.currency(amount, this.currencyLabel, null, {
        symbolOnLeft: true,
        spaceBetweenAmountAndSymbol: false,
        decimalSeparator: ',',
      });

      if (amount != undefined) {
        return `<span class="text-red">${sum}</span>`;
      }
    },
    percent(val, denom) {
      denom = parseInt(denom);

      if (!denom) return '0%';

      const ratio = val / denom;

      return `${Math.floor(ratio * 100)}%`;
    },
  },
};
</script>
