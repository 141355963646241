var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'table-card-search-header',
    'ui-mobile-12'
  ]},[_c('div',{class:{
      'table-card-search-header__content': true,
      'table-card-search-header--content-mobile': _vm.showActionsMobile,
      'ui-g-w-md': true,
      'ui-mobile-12': true
    }},[_c('div',{class:[
        'table-card-search-header__search-wrapper',
        'form-group',
        'form-groups',
        'ui-mobile-12',
        'ui-g-md'
      ]},[_c('i',{class:[
          'table-card-search-header__search-icon',
          'fa',
          'fa-search'
        ],attrs:{"aria-hidden":"true"}}),_c('input',{staticClass:"table-card-search-header__input-search",attrs:{"placeholder":_vm.pPlaceholder,"autocomplete":"off","type":"text"},domProps:{"value":_vm.pValue},on:{"input":function($event){return _vm.setValue($event.target.value)},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.search.apply(null, arguments)}}}),(_vm.pValue)?_c('span',{staticClass:"table-card-search-header__clear-icon-wrapper",on:{"click":_vm.clearInput}},[_vm._v(" × ")]):_vm._e()]),(_vm.hasActions)?_c('div',{class:{
        'table-card-search-header__actions': true,
        'table-card-search-header__actions--show-mobile': _vm.showActionsMobile,
        'ui-g-md': true
      }},[_vm._t("actions")],2):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }