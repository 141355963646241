export default {
  saveProfileData(state, payload) {
    state.user = payload;
  },

  saveApiKeys(state, payload) {
    state.apiKeys = payload;
  },

  saveApiKeysCount(state, payload) {
    state.totalRows = payload;
  },
  iphoneFix(state, payload) {
    state.iphoneFix = payload;
  },
};
