<template>
  <modal :show.sync="active" class="modal-password">
    <h5 slot="header" class="modal-title mr-8">
      <span v-if="!showBackup">
        {{$t('profile.secondFactor.showBackupCodes')}}
      </span>
      <span v-if="showBackup">
        {{ $t('profile.showBackupCodes.backupCodes') }}
      </span>
    </h5>

    <template>
      <div v-if="!showBackup">
        <div class="mb-20">
          {{$t('profile.secondFactor.enterGoogleAuth')}}
        </div>
        <CodeInput
          ref="codeinput"
          :key="active"
          :fieldWidth="40"
          :fieldHeight="40"
          class="input justify-content-center w-100 d-flex"
          @change="errors_ = {}"
          @complete="confirm"
        />
        <div class="text-danger error-style wrongPassword" v-if="errors_.token">
          {{ errors_.token[0].message }}
        </div>
      </div>

      <div class="row backup_codes" v-if="showBackup">
        <div class="col-12 backup_codes-text mb-16">
          {{ $t('profile.showBackupCodes.backupText') }}
        </div>
        <div class="col-6 text-left">
          <p class="mb-2" v-for="item in codes.slice(0,5)" :key="item">
            {{ item }}
          </p>
        </div>
        <div class="col-6 text-left pl-0">
          <p class="mb-2" v-for="item in codes.slice(5,10)" :key="item">
            {{ item }}
          </p>
        </div>
      </div>
    </template>

    <div slot="footer" class="text-left mt-8">
      <div class="d-flex justify-content-between" v-if="!showBackup">
        <button class="btn btn-outline-primary btn-sm modal__btn-cancel mr-16" @click="close">
          {{ $t('alerts.passwordDialog.cancel') }}
        </button>
      </div>

      <button class="btn btn-outline-primary modal__btn-cancel btn-sm mt-8" v-if="showBackup" @click="close">
        &lt; {{ $t('profile.buttons.back') }}
      </button>
    </div>
  </modal>
</template>

<script>
import { Modal } from 'src/components';
import CodeInput from 'vue-verification-code-input';
import mixinsPasteCodeInput from '@/helpers/mixinsPasteCodeInput';

export default {
  mixins: [mixinsPasteCodeInput],
  components: {
    Modal,
    CodeInput,
  },
  data() {
    return {
      active: false,
      codes: ['abcdefg', 'abcdefg', 'abcdefg', 'abcdefg', 'abcdefg'],
      errors_: {},
      password: '',
      showBackup: false,
    };
  },
  methods: {
    open() {
      this.active = true;
      this.showBackup = false;
      this.password = '';
      this.errors_ = {};
      setTimeout(() => {
        const inputs = document.getElementsByClassName('react-code-input');
        this.$_.forIn(inputs, (e => e.children && e.children[0].focus()));
      });
    },
    confirm() {
      this.password = this.$refs.codeinput.values.reduce((a, b) => a.concat(b), '');
      this.$store
        .dispatch('createOtpBackupCodes', { token: this.password })
        .then((data) => {
          this.codes = data.backup_codes;
          this.showBackup = true;
        })
        .catch((err) => {
          this.errors_ = err.response.data.errors;
        });
    },
    close() {
      this.password = '';
      this.active = false;
      this.showBackup = false;
      this.errors_ = {};
    },
  },
};
</script>

<style lang="scss" scoped>
.backup_codes-text {
  max-width: 90%;
}
.modal__btn-cancel{
  min-width: 100px !important;
}
.modal__btn-confirm{
  min-width: 180px !important;
}
</style>
