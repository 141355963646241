var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"postback"},[_c('table-card',{ref:"datatable",attrs:{"loading":_vm.loading,"columns":_vm.columns,"actions":_vm.actions,"page":_vm.page,"page-size":_vm.pageSize,"sort":_vm.sorting,"sort-by-name":_vm.sortByName,"sort-dir-name":_vm.sortDirName,"rows":_vm.items,"count":_vm.$store.state.trafficSource.totalPostbacksRows,"i18n-path":"reports","dont-fill-empty":"","extend-height":""},on:{"update:page":function($event){_vm.page=$event},"update:pageSize":function($event){_vm.pageSize=$event},"update:page-size":function($event){_vm.pageSize=$event},"update:sort":function($event){_vm.sorting=$event},"fetch-data":_vm.fetchDataTable}},[_c('div',{staticClass:"ui-align-center",attrs:{"slot":"header"},slot:"header"},[_c('div',{staticClass:"ui-g-md"},[_c('button',{staticClass:"btn btn-outline-primary btn-sm text-nowrap",on:{"click":_vm.back}},[_vm._v(" < "+_vm._s(_vm.$t('profile.buttons.back'))+" ")])]),_c('span',{class:[
          'table-card__header-title',
          'ui-g-md',
          'ui-m-md-l'
        ]},[_vm._v(_vm._s(_vm.$store.state.trafficSource.item.name)+" "+_vm._s(_vm.$t('postbacks.card.title')))])]),_c('div',{staticClass:"ui-g-md ui-mobile-12",attrs:{"slot":"actionsPrepend"},slot:"actionsPrepend"},[_c('tooltip',{staticClass:"tooltip-btn-disabled",attrs:{"title":_vm.$t('tooltip_limits.postbacks'),"placement":"bottom-left","disabled":!_vm.addDisabled}},[_c('button',{class:[
            'btn',
            'btn-primary',
            'btn-sm',
            'text-nowrap',
            'ui-mobile-12'
          ],attrs:{"disabled":_vm.addDisabled},on:{"click":_vm.addPostback}},[_vm._v(" "+_vm._s(_vm.$t('postbacks.card.btnAdd'))+" ")])])],1)]),_c('edit-postback',{ref:"editPostback",attrs:{"offset-params":_vm.queryParams}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }