<template>
  <section class="sub-affiliates">
    <div class="col-12 custom-padding pl-0 pr-0">
      <div class="card" :class="{wavebg: loading && !tableLoading}">
        <h5 class="card-title">
          {{ $t('subAffiliates.referal.title') }}
        </h5>
        <hr>
        <div class="card-body row pl-0 pb-0 pt-0">
          <div class="col-md-6 col-12 pb-0 padding-right pl-0">
            {{ $t('subAffiliates.referal.paymentsText') }}
            <br>
            <br>
            {{ $t('subAffiliates.referal.referralText') }}
            <div class="referral_percent mt-8">
              {{$t('subAffiliates.table.referralPercent')}} - {{$store.state.profile.user.referral_percent}}%
            </div>
          </div>
          <div class="col-md-6 col-12 padding-left">
            <div>
              <h6>{{ $t('subAffiliates.referal.homepageUrl') }}</h6>
              <copylink
                :text="homepage_url"
                :message="$t('subAffiliates.referal.copied')"
              />
              <br>
              <h6>{{ $t('subAffiliates.referal.registrationUrl') }}</h6>
              <copylink
                :text="registration_url"
                :message="$t('subAffiliates.referal.copied')"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <table-card
      ref="datatable"
      class="ui-m-xxl-t ui-flex-grow"
      :loading="loading"
      :columns="columns"
      :sort.sync="sorting"
      :sort-by-name="sortByName"
      :sort-dir-name="sortDirName"
      :page.sync="page"
      :page-size.sync="pageSize"
      :rows="items"
      :count="totalCountRow"
      :formaters="formaters"
      :header="$t('subAffiliates.card.title')"
      :actions="actions"
      i18n-path="reports"
      dont-fill-empty
      extend-height
      @fetch-data="fetchDataTable"
    >
      <table-card-search-header
        slot="header"
        class="ui-g-md"
        v-model="search"
        @search="handleSearch"
      />
    </table-card>
  </section>
</template>

<script>
import eventBus from 'src/lib/eventBus';
import Copylink from 'src/components/Copylink';
import Mobtable from 'src/components/Mobiletable/Mobtable';
import Mobpagination from 'src/components/Mobiletable/MobPagination';
import SortBy from 'src/components/SortBy.vue';
import Formaters from 'src/views/Dashboard/CustomReports/formaters';
import UrlMixin from 'src/components/Query/url-mixin';
import UrlMixinHooks from 'src/components/Query/url-mixin-hooks';
import InjectPlugins from 'src/components/Report/Components/inject-plugins';
import HelpersMixin from 'src/components/Helpers/helpers-mixin.js';
import TableCard from 'src/components/Cards/TableCard.vue';
import moment from 'moment';
import _ from 'lodash';
import { pageSizeMixin, resolvePageSize } from 'src/views/Dashboard/Reports/page_size.js';
import tableFlowMixinGenerator from 'src/views/Dashboard/Reports/tableFlowMixin.js';
import TableCardSearchHeader from 'src/components/Cards/TableCardSearchHeader.vue';

const sizes = {
  1080: 15,
  1440: 25,
};
const viewName = '/sub-affiliates';
const pageLimitProp = 'pageSize';
const pageSize = resolvePageSize(viewName, {
  _default: 10,
  sizes,
});

const defaultParams = {
  totalRows: 0,
  pageSize,
  page: 1,
  sort_column: 'created_at',
  sort_dir: 'desc',
};

const fetch = (ctx, params) => {
  if (ctx.$_.isEmpty(params)) {
    params = {
      ...defaultParams,
    };
  }

  const query = ctx.params
    ? ctx.params.lastQuery
    : {};

  const count = () => ctx.$store.dispatch('getSubAffiliatesCount', {
    sort_column: defaultParams.sort_column,
    sort_dir: defaultParams.sort_dir,
    limit: pageSize,
    ...query,
    ...params,
  });

  const subaffs = () => ctx.$store.dispatch('getSubAffiliates', {
    sort_column: defaultParams.sort_column,
    sort_dir: defaultParams.sort_dir,
    limit: pageSize,
    ...query,
    ...params,
  });

  const urls = () => ctx.$api.getSubAffiliatesReferralUrls().then(data => data.payload);

  if (ctx.lastQuery) {
    ctx.lastQuery = {
      ...params,
      ...query,
    };
  }

  return Promise.all([count(), subaffs(), urls()]);
};

const tableFlowMixin = tableFlowMixinGenerator({
  defaultParams: {
    ...defaultParams,
  },
  params: Object.keys(defaultParams),
  fetch,
  sortByName: 'sort_column',
  sortDirName: 'sort_dir',
  windowRefreshHook: true,
});

export default {
  fetch,
  components: {
    Copylink,
    Mobtable,
    Mobpagination,
    SortBy,
    TableCard,
    TableCardSearchHeader,
  },
  mixins: [
    UrlMixinHooks,
    UrlMixin,
    InjectPlugins,
    pageSizeMixin,
    HelpersMixin,
    tableFlowMixin,
  ],
  props: ['fetchedData', 'passedParams'],
  data() {
    return {
      viewName,
      pageLimitProp,
      multiSelectBlock: false,
      copied: false,
      link1: false,
      link2: false,
      filter: '',
      lastQuery: {},
      actions: {
        width: 100,
        name: () => this.$t('profile.apiKeys.table.actions'),
        operations: [
          {
            name: 'statistics',
            component: (row, ctx) => {
              row = _.cloneDeep(row);
              const { $router, compressUrlQuery } = this;
              const summaryQuery = {};
              summaryQuery.subAffiliateActive = true;
              summaryQuery.query = {
                sub_affiliate_id: row.data.id,
                passedPeriod: {
                  period: 30,
                  from: moment()
                    .startOf('month')
                    .format('YYYY-MM-DD 00:00:00'),
                  to: moment()
                    .endOf('day')
                    .format('YYYY-MM-DD 23:59:59'),
                },
                period: 30,
                affiliate_offer_id: '',
                player_id: '',
                traffic_source_id: '',
                country_code: '',
                subid1: '',
                subid2: '',
                subid3: '',
                subid4: '',
                subid5: '',
                trans: true,
              };
              const span = ctx.$createElement('a', {
                on: {
                  click(e) {
                    e.cancelBubble = true;
                    $router.push({
                      name: 'routes.summary',
                      params: { urlQuery: compressUrlQuery(summaryQuery) },
                    });
                  },
                },
                attrs: {
                  href: `#/dashboard/summary/${this.compressUrlQuery(summaryQuery)}`,
                },
              }, [
                ctx.$createElement('i', {
                  attrs: {
                    class: 'far fa-chart-bar text-info',
                  },
                }),
              ]);
              return ctx.$createElement('el-tooltip', {
                attrs: {
                  class: 'el-tooltip icon__wrapper text-info',
                  placement: 'top',
                  content: this.$t('promoCodes.promocodes.statistics'),
                  'open-delay': 500,
                },
              }, [span]);
            },
          },
          {
            name: 'offers',
            component: (row, ctx) => {
              row = _.cloneDeep(row);
              const { showOffers } = this;
              const offersQuery = {};
              offersQuery.subAffiliateActive = true;
              offersQuery.sub_affiliate_id = row.data.id;
              offersQuery.sub_affilate_email = row.email;
              const span = ctx.$createElement('a', {
                on: {
                  click(e) {
                    e.cancelBubble = true;
                    showOffers(row, offersQuery);
                  },
                },
                attrs: {
                  href: `#/dashboard/offers/${this.compressUrlQuery(offersQuery)}`,
                },
              }, [
                ctx.$createElement('i', {
                  attrs: {
                    class: 'fas fa-badge-dollar text-green',
                  },
                }),
              ]);
              return ctx.$createElement('el-tooltip', {
                attrs: {
                  class: 'el-tooltip icon__wrapper text-green',
                  placement: 'top',
                  content: this.$t('subAffiliates.table.offers'),
                  'open-delay': 500,
                },
              }, [span]);
            },
          },
        ],
      },
      columns: [
        {
          name: 'email',
          filterable: false,
          sortable: 'custom',
          source: 'i18n',
          i18n: 'reports.fields.e_mail.title',
        },
        {
          name: 'created_at',
          filterable: false,
          sortable: 'custom',
          format: 'date-time-sec',
          source: 'i18n',
          i18n: 'reports.fields.reg_date.title',
        },
        {
          name: 'status',
          align: 'center',
          filterable: false,
          sortable: false,
        },
      ],
      pagination: true,
      totalRows: 0,
      homepage_url: '',
      registration_url: '',
      loading: false,
      tableLoading: false,
      formaters: Formaters,
      isTableReady: false,
    };
  },
  beforeMount() {
    this.pageSize = this.limit;
  },
  computed: {
    cacheData() {
      const {
        totalRows, pageSize, page, sort_dir, sort_by, lastQuery, search,
      } = this;
      return {
        totalRows,
        pageSize,
        page,
        sort_dir,
        sort_by,
        lastQuery,
        search,
      };
    },
    items() {
      return this.$store.state.subaffiliates.all.map((e) => {
        const data = {
          data: e.data,
          email: e.email || this.$t('trafficSources.postbacks.notSpecified'),
          created_at: this.$moment(e.date)
            .format('YYYY-MM-DD HH:mm:ss') || this.$t('trafficSources.postbacks.notSpecified'),
          status: this.$t(`subAffiliates.table.${e.status}`) || this.$t('trafficSources.postbacks.notSpecified'),
        };

        return data;
      });
    },

    totalCountRow() {
      return this.$store.state.subaffiliates.count;
    },


  },
  created() {
    this.actions.operations.map((e) => {
      if (e.component) {
        e.component = e.component.bind(this);
      }
    });
    this.setFromQuery(this.passedParams);
  },
  async mounted() {
    this.processFetchResult(this.fetchedData);
  },
  async activated() {
    this.processFetchResult(this.fetchedData);
  },
  methods: {
    setFromQuery(params) {
      const {
        totalRows, pageSize, page, sort_dir, sort_by, lastQuery, search,
      } = params;
      if (!totalRows || !pageSize || !page) {
        return;
      }
      this.totalRows = totalRows;
      this.pageSize = pageSize;
      this.page = page;
      this.sort_dir = sort_dir;
      this.sort_by = sort_by;
      this.lastQuery = lastQuery;
      this.search = search;
    },
    processFetchResult(data) {
      if (!this.$_.isEmpty(data)) {
        const [total, rows, urls] = data;

        this.homepage_url = urls.homepage_url;
        this.registration_url = urls.registration_url;
        this.totalRows = total.count;
      }
    },
    showOffers(row, offersQuery) {
      this.$router.push({
        name: 'routes.offers',
        params: {
          urlQuery: this.compressUrlQuery(offersQuery),
        },
      });
    },
  },
};
</script>

<style lang="scss">
@import 'src/assets/theme/default/subaffiliates/subaffiliates';
</style>
