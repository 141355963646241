<template>
  <div class="filters-wrap d-flex">
    <el-popover
      placement="bottom"
      trigger="manual"
      popper-class="filter-fixed-wrap"
      width="327"
      v-model="filterOpen"
    >
      <button
        :disabled="disabled"
        :class="{ 'btn-primary': filterOpen || firstFilter.values || sub_affiliate_id || filter_active,
                  'btn-outline-primary': !filterOpen,
                  'btn-events-none': filterOpen,
                  'btn-mob-content': (sub_affiliate_id && sub_affiliate_id !== '0')}"
        class="btn btn-refresh btn-sm d-flex"
        slot="reference"
        @click="clickFilterOpen"
      >
        <span class="d-flex align-items-center">
          <i class="fas fa-filter mr-4"></i>
          {{$t('filters.filter')}}
          <span class="length-filter pl-2">{{calcLengthFilters()}}</span>
        </span>
        <span v-if="sub_affiliate_id && sub_affiliate_id !== '0'" class="btn-filter-email">{{sub_affiliate_email}}</span>
      </button>


      <div v-if="filterOpen" class="filter-fixed" v-loading="loadingSelect">
        <div class="select-block-wrap select-block-head-wrap mb-16">
          <span class="d-flex align-items-center">
            <div class="cross" @click="filterOpen = false">×</div>{{$t('filters.filter')}}
          </span>
          <div
            class="reset-filters text-red"
            v-if="firstFilter.values || (sub_affiliate_id && sub_affiliate_id !== '0') || filter_active"
            @click="resetFilters"
          >
            {{$t('filters.resetFilters')}}
          </div>
        </div>

        <div
          v-if="$store.state.profile.user.referral_program_enabled"
          class="select-block-wrap select-block-wrap-traffic mt-16"
        >
          <span class="pl-16" v-if="!mbFilter">
            {{$t('filters.subaffiliate')}}
          </span>
          <div class="el-select__wrap" v-if="!mbFilter">
            <v-select
              class="el-input el-input-select"
              v-model="sub_affiliate_id"
              :options="referrals"
              :disabled="false"
              key="sub_affiliate_id"
              @change="currentFilters.map(e => e.values = '')"
            />
          </div>
          <div class="el-select__wrap" v-else>
            <span class="pl-16">
              {{$t('filters.traffic')}}
            </span>
            <el-select
              popper-class="traffic-dropdown-list"
              v-model="traffic_filter_name"
              @change="changeTraffic"
            >
              <div class="form-group select-wrapper mt-0 mb-4 mr-4 ml-4">
                <input
                  v-model="search_traffic"
                  :placeholder="$t('customReports.search')"
                  type="text"
                  class="form-control mt-0 pl-12"
                  @keydown.enter.prevent
                >
              </div>
              <el-option-group
                v-for="group in traffic_list"
                :key="group.label"
                :label="group.label"
              >
                <el-option
                  v-for="item in group.options"
                  :key="item.id"
                  :label="item.text"
                  :value="item.id"
                >
                </el-option>
              </el-option-group>
            </el-select>
          </div>
        </div>

        <filter-reports-item
          v-for="(item, index) in filtersBasic"
          :key="item.param"
          :class="{
            'mt-16': index === 0,
            'select-block-wrap__nb': index < filtersMetaFiltered.length,
            'mb-8 ': index > filtersMetaFiltered.length
          }"
          :item="item"
          :disabled="isFiltersDisabled"
        ></filter-reports-item>

        <div
          class="select-block-wrap"
          :class="{'select-opacity': isFiltersDisabled}"
          @click="expandSubids = !expandSubids"
        >
          <span class="pl-16">
            SubID
          </span>

          <div class="d-flex align-items-center text-expand text-green pr-16">
            <template v-if="expandSubids">
              {{$t('customReports.hide')}} <div class="plus ml-8 mb-2">
                -
              </div>
            </template>
            <template v-else>
              {{$t('customReports.show')}} <div class="plus ml-8 mb-2">
                +
              </div>
            </template>
          </div>
        </div>

        <div v-if="expandSubids">
          <filter-reports-item
            v-for="(item, index) in filtersSubid"
            :key="item.param"
            :class="{
              'select-block-wrap__nb': index < filtersSubid.length
            }"
            :item="item"
            :disabled="isFiltersDisabled"
          ></filter-reports-item>
        </div>

        <div class="d-flex pl-16 pr-16 mb-16 mt-16">
          <button
            class="btn btn-primary btn-refresh w-100"
            :class="{'not-disabled': (firstFilter.values || (sub_affiliate_id && sub_affiliate_id !== '0') || filter_active)}"
            :disabled="!(firstFilter.values || (sub_affiliate_id && sub_affiliate_id !== '0') || filter_active)"
            @click="applyFilters()"
          >
            <span class="before-icon">{{$t('filters.applyFilters')}}</span>
          </button>

          <button
            v-if="firstFilter.values || (sub_affiliate_id && sub_affiliate_id !== '0') || filter_active"
            class="btn btn-outline-danger btn-sm ml-8 w-100"
            @click="resetFilters"
          >
            <span class="text-red">×</span>
            {{$t('filters.resetFilters')}}
          </button>
        </div>
      </div>
    </el-popover>

    <div
      v-if="firstFilter.values || (sub_affiliate_id && sub_affiliate_id !== '0') || filter_active"
      class="reset-filters text-red ml-8"
      @click="resetFilters"
    >
      {{$t('filters.resetFilters')}}
    </div>
  </div>
</template>

<script>
import InjectPlugins from 'src/components/Report/Components/inject-plugins';
import FilterReportsItem from 'src/components/Filter-reports/Filter-reports-item.vue';
import { debounce } from 'lodash';

export default {
  components: {
    FilterReportsItem,
  },
  mixins: [InjectPlugins],
  props: {
    useFilters: Array,
    value: Array,
    from: String,
    to: String,
    filterId: [String, Object, Array],
    mbFilter: Boolean,
    defaultFilter: {
      type: Object,
      default() {
        return {};
      },
    },
    accumulative: {
      type: [String, Boolean],
      default() {
        return false;
      },
    },
  },

  data() {
    return {
      disabled: false,
      currentFilters: [
        { type: 'traffic_source_id', values: '' },
        { type: 'country_code', values: '' },
        { type: 'site_player_id', values: '' },
        { type: 'affiliate_offer_id', values: '' },
        { type: 'subid1', values: '' },
        { type: 'subid2', values: '' },
        { type: 'subid3', values: '' },
        { type: 'subid4', values: '' },
        { type: 'subid5', values: '' },
      ],
      sub_affiliate_id: 0,
      filterOptionsBase: {},
      filterOpen: false,
      loadingSelect: false,
      expandSubids: false,
      stateApply: false,
      sub_affiliate_id_old: 0,
      currentFilters_old: [],
      blockPupop: {},
      traffic_filter: 'all',
      traffic_filter_name: 'all',
      traffic_filter_old: '',
      filter_active: false,
      search_traffic: '',
      select: debounce(this.selected, 300),
      paramsChanged: true,
    };
  },
  mounted() {
    this.select();
    this.blockPupop = document.querySelector('.filter-fixed-wrap');
  },
  activated() {
    this.select();
  },
  watch: {
    sub_affiliate_id() {
      this.$nextTick(() => {
        this.resetOptions();
      });
    },
    traffic_filter_name() {
      if (this.traffic_filter_old !== 'own') this.filter_active = this.traffic_filter_name !== this.traffic_filter_old;
    },
    filterOpen() {
      this.search_traffic = '';
      if (this.filterOpen) {
        let listener;
        document.addEventListener('mousedown', listener = (e) => {
          if (document.documentElement.clientWidth > 650
            && (e.clientY > this.blockPupop.offsetTop + this.blockPupop.offsetHeight || e.clientY < this.blockPupop.offsetTop
            || e.clientX > this.blockPupop.offsetLeft + this.blockPupop.offsetWidth || e.clientX < this.blockPupop.offsetLeft)) {
            this.filterOpen = false;
            document.removeEventListener('mousedown', listener);
          }
        });

        this.sub_affiliate_id_old = this.sub_affiliate_id;
        this.traffic_filter_old = this.traffic_filter_name;
        this.currentFilters_old = this.$_.cloneDeep(this.currentFilters);
      }
      if (!this.filterOpen && !this.stateApply) {
        this.sub_affiliate_id = this.sub_affiliate_id_old;
        this.traffic_filter_name = this.traffic_filter_old;
        this.currentFilters = this.currentFilters_old;
      }
    },
    params: {
      deep: true,
      handler(value, oldValue) {
        if (!this.$_.isEqual(value, oldValue)) {
          this.paramsChanged = true;
        }
      },
    },
    accumulativeFilters() {
      this.$nextTick(() => {
        this.loadingSelect = true;
        this.getCurrentFilters();
      });
    },
  },

  methods: {
    resetFilters() {
      this.currentFilters.map(e => e.values = '');
      this.sub_affiliate_id = 0;
      this.traffic_filter = 'all';
      this.traffic_filter_name = 'all';
      this.stateApply = true;
      this.filterOpen = false;
      this.loadingSelect = false;
      this.$emit('input', [{}]);
      this.$nextTick(() => {
        this.stateApply = false;
        this.filter_active = false;
      });
    },
    applyFilters() {
      if (this.traffic_filter_name === 'all' && this.mbFilter) {
        this.resetFilters();
      }
      this.$emit('input', JSON.parse(JSON.stringify([
        { sub_affiliate_id: this.sub_affiliate_id },
        { traffic_filter: this.traffic_filter },
        { traffic_filter_name: this.traffic_filter_name },
        ...this.currentFilters,
      ])));
      this.stateApply = true;
      this.filterOpen = false;
      this.loadingSelect = false;
      this.$nextTick(() => {
        this.stateApply = false;
      });
    },

    clickFilterOpen() {
      this.filterOpen = !this.filterOpen;
      if (this.filterOpen) {
        this.loadingSelect = true;
        this.getCurrentFilters();
      }
    },
    getCurrentFilters() {
      if (!this.paramsChanged) {
        this.loadingSelect = false;
        return;
      }

      const params = this.hasOptionsFilters.map(e => ({
        limit: 1000,
        offset: 0,
        metrics: ['visits_count', 'deposits_all_sum'],
        dimensions: [e.field],
        from: this.params.from,
        to: this.params.to,
        filters: this.params.filters,
      }));

      this.$api.postReport(params)
        .then((res) => {
          this.filterOptionsBase = {};

          res.payload.data.forEach((e) => {
            if (e[0] && e[0].row_filter_column) {
              this.$set(this.filterOptionsBase, e[0].row_filter_column, [
                this.defaultFilterOption,
                ...e,
              ]);
            }
          });

          this.loadingSelect = false;
          this.paramsChanged = false;
        })
        .catch((error) => {
          console.log(error);
          this.loadingSelect = false;
        });
    },

    resetOptions() {
      this.loadingSelect = true;
      this.getCurrentFilters();
    },

    selected() {
      if (this.value) {
        this.$nextTick(() => {
          this.value.map((item) => {
            if (item.sub_affiliate_id) {
              this.sub_affiliate_id = item.sub_affiliate_id;
            }
            if (item.traffic_filter) {
              this.traffic_filter = item.traffic_filter;
            }
            if (item.traffic_filter_name) {
              this.traffic_filter_name = item.traffic_filter_name;
            }
            const index = this.currentFilters.map(e => e.type).indexOf(item.type);
            if (index !== -1) this.currentFilters.splice(index, 1);
            if (item.type) {
              this.disabled = true;
              this.currentFilters.push(item);
              this.disabled = false;
              this.currentFilters = this.currentFilters.filter(e => e.type);
              if (!this.currentFilters.length) this.currentFilters = [];
            }
            if (!this.currentFilters.length) this.currentFilters = [];
          });
        });
      }
    },
    calcLengthFilters() {
      const length = this.currentFilters.filter(e => e.values !== '').length;
      return length ? `(${length})` : '';
    },
    changeTraffic(v) {
      this.currentFilters.map(e => e.values = '');
      this.traffic_filter = this.traffic_filter_name;
      if (v !== 'own' && v !== 'all') {
        this.traffic_filter = 'sub_affiliate';
        this.sub_affiliate_id = v;
      } else {
        this.sub_affiliate_id = 0;
      }
    },
  },

  computed: {
    // sub_affiliate filter
    // subAffiliates() {
    //   const subAffiliates = this.filterOptionsBase.affiliate_id || [];
    //   return subAffiliates.filter((item) => item.row_id);
    // },
    subAffiliates() {
      return this.$store.state.subaffiliates.all.map(e => ({
        id: e.data.id,
        text: e.email,
      }));
    },
    traffic_list() {
      const dif = [];
      let list = [{
        label: '',
        options: [{
          id: 'all',
          text: this.$t('filters.total'),
        }, {
          id: 'own',
          text: this.$t('filters.own'),
        }],
      }, {
        label: this.$t('sidebar.menu.main.subAffiliates'),
        options: this.subAffiliates,
      }];

      if (this.search_traffic !== '') {
        list.forEach((e) => {
          e.options = e.options.filter(i => i.text.toLowerCase().includes(this.search_traffic));
          if (e.options.length) dif.push(e);
        });
        list = dif;
      }
      return list;
    },
    referrals() {
      return [{
        id: 0,
        text: this.$t('filters.selectSA'),
      }].concat(this.subAffiliates);
    },
    sub_affiliate_email() {
      return (this.$store.state.subaffiliates.all.find(e => e.data && e.data.id === this.sub_affiliate_id) || {}).email;
    },
    firstFilter() {
      const onceValue = this.currentFilters.filter(e => e.values !== '')[0];
      return onceValue || {};
    },
    defaultFilterOption() {
      return {
        id: '',
        text: this.$t('filters.all'),
      };
    },
    filterOptions() {
      const result = {};

      this.hasOptionsFilters.forEach((filter) => {
        const { param, field } = filter;
        const options = this.filterOptionsBase[param] || [this.defaultFilterOption];

        result[param] = options.map((e) => {
          const text = e[field];

          return {
            ...e,
            text: text
              ? e.row_filter_column === 'site_player_id'
                ? `${text} [$${e.deposits_all_sum}]`
                : `${text} (${e.visits_count} ${this.$t('customReports.visits')})`
              : this.$t('filters.all'),
            id: e.row_id || '',
          };
        });
      });

      return result;
    },
    filtersMeta() {
      const base = [
        {
          type: 'custom',
          param: 'traffic_source_name',
          field: 'traffic_source_name',
          title: this.$t('filters.trafficSource'),
        },
        // sub_affiliate filter
        // {
        //   type: 'requestOnly',
        //   param: 'affiliate_id',
        //   field: 'sub_affiliate_email',
        //   title: ''
        // },
        {
          type: 'basic',
          param: 'traffic_source_id',
          field: 'traffic_source_name',
          title: this.$t('filters.trafficSource'),
        },
        {
          type: 'basic',
          param: 'country_code',
          field: 'country_name',
          title: this.$t('filters.country'),
        },
        {
          type: 'basic',
          param: 'site_player_id',
          field: 'site_player_id',
          title: this.$t('filters.players'),
          showCondition: () => this.useFilters.indexOf('player_id') !== -1,
        },
        {
          type: 'basic',
          param: 'affiliate_offer_id',
          field: 'affiliate_offer_name',
          title: this.$t('filters.offers'),
        },
      ];

      const subIdCount = 5;
      const subIds = Array.apply(null, { length: subIdCount })
        .map((item, index) => ({
          type: 'subid',
          param: `subid${index + 1}`,
          field: `subid${index + 1}`,
          title: `SubID ${index + 1}`,
        }));

      return base.concat(subIds);
    },
    hasOptionsFilters() {
      return this.filtersMeta
        .filter(item => item.type !== 'custom');
    },
    filtersMetaFiltered() {
      return this.filtersMeta
        .filter(item => item.type !== 'custom' && (!item.showCondition || item.showCondition()))
        .map(item => ({
          ...item,
          options: this.filterOptions[item.param] || [],
          filter: this.currentFiltersIndex[item.param] || {},
        }));
    },
    currentFiltersIndex() {
      const result = {};
      this.currentFilters.forEach((item) => {
        result[item.type] = item;
      });
      return result;
    },
    filtersBasic() {
      return this.filtersMetaFiltered.filter(item => item.type === 'basic');
    },
    filtersSubid() {
      return this.filtersMetaFiltered.filter(item => item.type === 'subid');
    },
    isFiltersDisabled() {
      return this.traffic_filter_name === 'all' && this.mbFilter;
    },
    pAccumulative() {
      return this.accumulative || this.accumulative === '';
    },
    accumulativeFilters() {
      const result = {};

      if (this.pAccumulative) {
        this.currentFilters
          .filter(item => item.values)
          .forEach((item) => {
            result[item.type] = {
              op: '=',
              value: item.values,
            };
          });
      }

      return result;
    },
    filters() {
      const result = {
        ...this.defaultFilter,
        ...this.accumulativeFilters,
      };

      if (this.sub_affiliate_id && this.sub_affiliate_id != 0) {
        result.affiliate_id = {
          op: '=',
          value: this.sub_affiliate_id,
        };
      }

      return result;
    },
    params() {
      return {
        from: this.from || new Date(new Date().setDate(1)),
        to: this.to || new Date(),
        filters: this.filters,
      };
    },
  },
};
</script>


<style lang="scss">
@import 'src/assets/theme/default/components/filterReports';
</style>
