<template>
  <section class="summary">
    <div id="myTabContent" class="tab-content">
      <div
        id="myTraffic"
        class="tab-pane fade show active"
        role="tabpanel"
        aria-labelledby="myTraffic-tab"
      >
        <div class="card card-header" :class="{wavebg: loading}">
          <div class="d-flex justify-content-between align-items-center period-filter-btns__wrap">
            <div class="d-flex period-filter__wrap">
              <period-wrapper
                :settings="passedPeriod"
                class="sub-affiliates__period mr-8"
                @update="setRange($event, false)"
                @change="setRange($event, true)"
              />
              <filter-reports
                v-if="!isMobile"
                class="filter-hide-mob"
                :value="filters"
                @input="updateFilters"
                :from="period.from"
                :to="period.to"
                :use-filters="useFilters"
              />
            </div>
            <div class="ui-g-w-md">
              <export-report
                class="ui-g-md"
                :export-links="$store.state.report.summary_totals.responseURL"
              />
              <div class="ui-g-md">
                <ui-button
                  icon="refresh"
                  class="btn btn-refresh btn-lowercase"
                  style="width: 34px;"
                  @click="setQuery"
                />
              </div>
            </div>
          </div>
          <filter-reports
            v-if="isMobile"
            class="filter-show-mob mt-10"
            :value="filters"
            @input="updateFilters"
            :from="period.from"
            :to="period.to"
            :key="sub_affiliate_id"
            :use-filters="useFilters"
          />
        </div>
        <summary-tables :class="{wavebg: loading}" :model="model"/>
      </div>
    </div>
  </section>
</template>

<script>
import eventBus from 'src/lib/eventBus';
import { Select, Option } from 'element-ui';
import moment from 'moment';
import UrlMixin from 'src/components/Query/url-mixin';
import UrlMixinHooks from 'src/components/Query/url-mixin-hooks';
import FilterReports from 'src/components/Filter-reports/Filter-reports.vue';
import PeriodWrapper from 'src/views/Dashboard/Reports/Filters/PeriodWrapper.vue';
import SummaryTables from 'src/components/SummaryTables.vue';
import HelpersMixin from 'src/components/Helpers/helpers-mixin.js';
import ResponsiveMixin from 'src/components/Helpers/responsive-mixin.js';
import ExportReport from 'src/views/Dashboard/Reports/ExportReport.vue';

const fetch = async (ctx) => {
  await ctx.$store.dispatch('getSubAffiliates', {
    limit: 10000,
    offset: 0,
  });
  return [];
};

export default {
  fetch,
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    FilterReports,
    PeriodWrapper,
    SummaryTables,
    ExportReport,
  },
  mixins: [
    UrlMixin,
    UrlMixinHooks,
    HelpersMixin,
    ResponsiveMixin,
  ],
  props: ['passedParams'],
  data() {
    return {
      query: null,
      sub_affiliate_id: null,
      passedPeriod: {
        period: 30,
      },
      period: {},
      filters: null,
      useFilters: [
        'traffic_source_id', 'country_code', 'player_id', 'affiliate_offer_id', 'subid1', 'subid2', 'subid3', 'subid4',
        'subid5',
      ],
      loading: false,
    };
  },
  computed: {
    cacheData() {
      return {
        query: this.query,
      };
    },
    model() {
      return this.$store.state.report.summary_totals;
    },
    target() {
      return this;
    },
  },
  watch: {
    // filters() {
    //   this.setQuery();
    // },
    '$i18n.locale': function () {
      if (this.isComponentActivated) {
        this.setQuery();
      }
    },
  },
  async created() {
    this.setFromQuery(this.passedParams);
    await this.$store.dispatch('getSettings');
    this.loading = true;
  },
  async mounted() {
    this.setFromQuery(this.passedParams);

    eventBus.$on('window-refresh', () => {
      if (this.$route.name === 'routes.summary') {
        this.setQuery();
      }
    });

    await this.setQuery();
    await this.afterBrowserRedraw();

    this.loading = false;
  },
  async activated() {
    this.setFromQuery(this.query);

    await Promise.all([
      fetch(this),
      this.$store.dispatch('getSettings'),
      this.setQuery(),
    ]);

    await this.afterBrowserRedraw();

    this.loading = false;
  },
  deactivated() {
    this.loading = true;
  },
  methods: {
    updateFilters(value) {
      this.filters = value;
      this.setQuery();
    },
    setFromQuery(params) {
      let { query = {} } = params || this.urlQuery;

      if (this.urlQuery && this.urlQuery.subAffiliateActive) query = this.urlQuery.query;

      this.query = query;
      this.sub_affiliate_id = query.sub_affiliate_id || '';

      if (query.sub_affiliate_id) {
        this.filters = [{ sub_affiliate_id: this.sub_affiliate_id }];
      }

      if (query.passedPeriod) {
        this.setPeriod(query.passedPeriod);
      }

      if (query.period && query.from) {
        this.setPeriod({
          from: query.from,
          to: query.to,
          period: query.period,
        });
      }

      this.filters = this.filters || [];
      Object.keys(query)
        .map((type) => {
          if (this.useFilters.indexOf(type) === -1) {
            return;
          }

          if (this.filters.find(e => e.type === type) && query[type] === this.filters.find(e => e.type === type).values) {

          } else {
            (this.filters || []).push({
              type,
              values: query[type],
            });
          }
        });
      this.cacheQuery();
    },
    setQuery(query, request = true) {
      this.query = this.getQueryData(query);

      if (request) {
        this.loading = true;
      }

      this.cacheQuery();

      return request
        ? this.$store
          .dispatch('getReportSummary', this.query)
          .finally(() => {
            this.loading = false;
          })
        : null;
    },
    getQueryData(data) {
      let query = { ...data, ...this.period };

      query = {
        ...query,
        filters: this.filters.filter(e => e.values && e.values !== '')
          .map(e => e = { [e.type]: { op: '=', value: e.values } }),
      };

      this.filters.forEach((e) => {
        if (e.sub_affiliate_id) {
          query.filters.push({ affiliate_id: { op: '=', value: e.sub_affiliate_id } });
        }
      });

      query = {
        ...query,
        filters: query.filters.reduce((accumulator, currentValue) => {
          const key = Object.keys(currentValue)[0];
          accumulator[key] = currentValue[key];
          return accumulator;
        }, {}),
      };

      (this.filters || []).forEach((filter) => {
        if (filter.sub_affiliate_id !== void 0) {
          if (filter.sub_affiliate_id !== 0) query.sub_affiliate_id = filter.sub_affiliate_id;
        } else {
          query = {
            sub_affiliate_id: null,
            ...query,
            [filter.type]: filter.values,
          };
        }
      });
      this.sub_affiliate_id = query.sub_affiliate_id;

      return query;
    },
    setRange(period, request = true) {
      this.setPeriod(period);
      this.setQuery(this.query, request);
    },
    setPeriod(period) {
      const periodItems = [7, -70, -7, -14, 30, -30, 100, 365, -365, -3650];
      this.period = period;

      if (period.period === 1) {
        this.passedPeriod = { range: [period.from, period.to] };
      } else if (periodItems.includes(period.period)) {
        this.passedPeriod = { period: period.period };
      } else {
        this.passedPeriod = {
          period: `${moment(period.from)
            .format('YYYY-MM-DD')} ${this.$t('periodWrapper.to')} ${moment(period.to)
            .format('YYYY-MM-DD')}`,
        };
      }

      this.query.passedPeriod = period;
    },
    getExportLink(format) {
      return (this.$store.state.report.summary_totals.responseURL || {})[format];
    },
  },
};
</script>

<style lang="scss">
@import 'src/assets/theme/default/reports/summary';
</style>
