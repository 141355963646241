<template>
  <div class="ui-range-input">
    <label
      for="min"
      class="label"
    >{{ label }}</label>
    <div class="wrapper">
      <ui-input
        id="min"
        ref="input"
        v-model="currentValue.min"
        :error="(errors.min || errors.total)"
        :tabindex="1"
        class="input"
        size="big"
        autosize
        type="text"
        placeholder="Min"
        name="min"
        @input="handleMinInput"
      />
      <span class="divider">-</span>
      <ui-input
        v-model="currentValue.max"
        :error="(errors.max || errors.total)"
        :tabindex="2"
        size="big"
        autosize
        type="text"
        class="input"
        placeholder="Max"
        @input="handleMaxInput"
      />
    </div>
  </div>
</template>

<script>

export default {
  name: 'UiRangeInput',
  props: {
    value: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: 'number',
    },
    int: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: 'Label',
    },
    error: {
      type: [Object, Array],
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      currentValue: {
        min: '',
        max: '',
      },
      readyValue: {
        min: '',
        max: '',
      },
      errors: {
        total: null,
        min: null,
        max: null,
      },
      focused: false,
      errorText: '',
    };
  },
  computed: {
    multiplier() {
      return this.type === 'currency' ? 100 : 1;
    },
  },
  watch: {
    error: {
      deep: true,
      handler(nv) {
        this.errors = {
          total: null,
          min: null,
          max: null,
        };
        if (this.$_.isArray(nv)) {
          this.errors.total = nv[0].code;
        }
        if (this.$_.isObject(nv)) {
          this.errors.min = nv.min[0].code;
          this.errors.max = nv.max[0].code;
        }
      },
    },
    value: {
      deep: true,
      handler(nv) {
        if (nv.min) {
          if (nv.min / this.multiplier !== parseFloat(this.currentValue.min)) {
            this.currentValue.min = nv.min / this.multiplier;
          }
        } else {
          this.currentValue.min = '';
        }
        if (nv.max) {
          if (nv.max / this.multiplier !== parseFloat(this.currentValue.max)) {
            this.currentValue.max = nv.max / this.multiplier;
          }
        } else {
          this.currentValue.max = '';
        }
      },
    },
    currentValue: {
      deep: true,
      handler(nv) {
        // eslint-disable-next-line
        if (nv.min != this.readyValue.min || nv.max != this.readyValue.max) {
          this.readyValue.min = parseFloat(nv.min) * this.multiplier || '';
          this.readyValue.max = parseFloat(nv.max) * this.multiplier || '';
          this.$emit('change', this.readyValue);
        }
      },
    },
  },
  created() {
    this.currentValue.min = this.value.min ? this.value.min / this.multiplier : '';
    this.currentValue.max = this.value.max ? this.value.max / this.multiplier : '';
  },
  methods: {
    handleMinInput(value) {
      const val = value;
      this.currentValue.min = (this.type === 'coef' || !this.int) ? val.replace(/[^.0-9]/g, '').replace(/^([^.]*\.)|\./g, '$1') : val.replace(/[^0-9]/g, '');
    },
    handleMaxInput(value) {
      const val = value;
      this.currentValue.max = (this.type === 'coef' || !this.int) ? val.replace(/[^.0-9]/g, '').replace(/^([^.]*\.)|\./g, '$1') : val.replace(/[^0-9]/g, '');
    },
    handleFocus(event) {
      this.focused = true;
      this.$emit('focus', event);
    },
    handleBlur(event) {
      this.focused = false;
      this.$emit('blur', event);
    },
    focus() {
      this.$refs.input.focus();
    },
    blur() {
      this.$refs.input.blur();
    },
    select() {
      this.$refs.input.select();
    },
  },
};
</script>

<style lang="scss" scoped>
.ui-range-input{
  position: relative;
  width: 194px;
  display: flex;
  flex-direction: column;
  .label{
    font-size: 14px;
    line-height: 16px;
    font-weight: 500;
    text-transform: uppercase;
    margin-bottom: 6px;
  }
  .error-msg{
    position: absolute;
    align-self: center;
    top: 74px;
    font-size: 12px;
    line-height: 14px;
    text-align: center;
    color: #d26370;
  }
  .wrapper{
    width: 100%;
    display: flex;
    align-items: center;
    .input{
      min-width: 10%;
      max-width: 100%;
      flex: 10 1 auto;
    }
    .divider{
      font-size: 14px;
      color: #7d7d7d;
      margin: 0 2px;
    }
  }
}
</style>
