<template>
  <section>
    <modal :show.sync="is_open" class="sfa-modal">
      <h5 slot="header" class="modal-title">
        {{ $t('profile.secondFactorEnableForm.title') }}
      </h5>
      <template>
        <div>
          <div>
            <div class="sfa__block-icons pb-8 mb-10">
              <div class="tip mb-14">
                1. {{ $t('profile.secondFactorEnableForm.step1') }}
              </div>
              <div class="row img-wrap mr-0">
                <img :src="require('@/assets/theme/icon/google-otp.svg')" class="col-4 p-0">
                <a href="https://apps.apple.com/ru/app/google-authenticator/id388497605" target="_blank" class="col-4 p-0">
                  <img :src="require('@/assets/theme/icon/apple-otp.svg')" class="second-img">
                </a>
                <a href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2" target="_blank" class="col-4 p-0">
                  <img :src="require('@/assets/theme/icon/android-otp.svg')">
                </a>
              </div>
            </div>
          </div>
          <div class="d-flex qr_wrap">
            <div class="qr-box w-50 mb-10">
              <img :src="data.url" alt="QR" class="">
            </div>
            <div class="sfa__block-input w-50 mt-8">
              <div>
                <div class="tip mb-16">
                  2. {{ $t('profile.secondFactorEnableForm.step2') }}
                </div>
                <CodeInput
                  :key="is_open"
                  ref="codeinput"
                  :fieldWidth="32"
                  :fieldHeight="32"
                  class="input justify-content-center w-100"
                  @change="errors_ = {}"
                  @complete="check2FACode"
                />
                <div v-if="errors_.token" class="login__error-title mb-8 mt-8">
                  {{ errors_.token[0].message }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </modal>

    <modal
      :show.sync="show_codes"
      class="modal-password"
      @close="show_codes=false;show_success = true;"
    >
      <h5 slot="header" class="modal-title">
        {{ $t('profile.modalPasswordBackup.title') }}
      </h5>
      <template>
        <div class="row backup_codes">
          <div class="col-12 mb-16">
            {{ $t('profile.modalPasswordBackup.copyBackup') }}
          </div>
          <div class="col-6">
            <p v-for="item in getfive" :key="item" class="mb-6">
              {{ item }}
            </p>
          </div>
          <div class="col-6">
            <p v-for="item in getten" :key="item" class="mb-6">
              {{ item }}
            </p>
          </div>
        </div>
      </template>
      <div slot="footer" class="">
        <button class="btn btn-outline-primary btn-sm mt-8" @click="show_codes=false; show_success = true;">
          &lt; {{ $t('profile.buttons.back') }}
        </button>
      </div>
    </modal>

    <modal :show.sync="show_success" class="modal-password">
      <h5 slot="header" class="modal-title">
        {{ $t('profile.modalPasswordTwoFactor.title') }}
      </h5>
      <template>
        <div class="text-center">
          <h1 class="text-center mt-32">
            <img :src="require('@/assets/theme/icon/ok.svg')" alt="">
          </h1>
          <p class="mb-32">
            {{ $t('profile.modalPasswordTwoFactor.securityCode') }}
          </p>
        </div>
      </template>
      <div slot="footer" class="text-center">
        <button class="btn btn-primary btn-md mt-8" @click="show_codes=false; show_success = false">
          {{ $t('profile.modalPasswordTwoFactor.btn') }}
        </button>
      </div>
    </modal>

    <enable-success ref="success" />
  </section>
</template>

<script>
import { Modal } from 'src/components/index';
import InputWrap from 'src/components/Form/InputWrap';
import CodeInput from 'vue-verification-code-input';
import EnableSuccess from './EnableSuccess';
import mixinsPasteCodeInput from '@/helpers/mixinsPasteCodeInput';

export default {
  mixins: [mixinsPasteCodeInput],

  components: {
    Modal,
    EnableSuccess,
    InputWrap,
    CodeInput,
  },
  data() {
    return {
      data: {},
      is_open: false,
      show_codes: false,
      show_success: false,
      token: '',
      pwd: '',
      errors_: {},
    };
  },

  methods: {
    enable() {
      this.$confirm.password(this.$t('profile.secondFactorEnableForm.mainTitle'), '', (pwd, modal) => {
        this.pwd = pwd;
        this.open(pwd, modal);
      });
    },

    open(pwd, modal) {
      modal.isLoading = true;
      const req = this.$request.post('totp/qr-code', { password: pwd });
      req.then((res) => {
        modal.close();
        this.data = res.data.payload;
        this.is_open = true;
        setTimeout(() => {
          const inputs = document.getElementsByClassName('react-code-input');
          this.$_.forIn(inputs, (e => e.children && e.children[0].focus()));
        });
      });
      req.catch(() => {
        // this.$alert.fail(this.$t('profile.secondFactorEnableForm.accessDenied'), this.enable);
      })
      req.finally(() => modal.isLoading = false);
      this.token = '';
    },

    check2FACode() {
      this.token = this.$refs.codeinput.values.reduce((a, b) => a.concat(b), '');
      this.$store
        .dispatch('otpEnable', { token: this.token })
        .then((data) => {
          this.show_codes = true;
          this.is_open = false;
        })
        .catch((err) => {
          this.errors_ = err.response.data.errors;
          this.is_open = true;
        });
    },
  },

  computed: {
    getten() {
      if (this.data.backup_codes) return this.data.backup_codes.slice(0, 5);
      return [];
    },
    getfive() {
      if (this.data.backup_codes) return this.data.backup_codes.slice(5, 10);
      return [];
    },
  },
};
</script>

<style lang="scss">
@import 'styles/enablesecondfactor';
</style>
