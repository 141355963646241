import { debounce } from 'lodash';

export default {
  getAffiliateOffers({ commit }, params = {}) {
    return this.$api.getAffiliateOffers(params).then((data) => {
      commit('saveAffiliateOffers', data.payload); // my
      return data;
    });
  },

  getAffiliateOffersLazy: debounce(({ dispatch }, params) => dispatch('getAffiliateOffers', params).then(data => data), 100),

  getSubAffilateOffers({ commit }, id) {
    return this.$api.getAffiliateOffers({ sub_affiliate_id: id }).then((data) => {
      commit('saveSubAffilateOffers', data.payload);
      return data;
    });
  },

  getOffer({ commit }, id) {
    return this.$api.getAffiliateOffer(id).then((data) => {
      commit('saveOffer', data.payload);
      return data;
    });
  },
};
