<template>
  <modal
    :show.sync="hasRequiredNotification"
    :show-close="false"
    class="notification"
  >
    <template>
      <h5 slot="header" class="title mb-16">
        {{ $t('alerts.importantNotification.title') }}
      </h5>
      <form>
        <div class="body" v-if="activeNotification">
          <i class="warning far fa-exclamation-circle"></i>
          <div class="label">
            {{ activeNotification.title }}
          </div>
          <div class="message">
            {{ activeNotification.message }}
          </div>
        </div>
      </form>
    </template>

    <div slot="footer" class="mt-8 ui-d-flex justify-content-center w-100">
      <button :disabled="isLoading" class="btn btn-primary btn-sm confirm" @click="readNotification">
        {{ $t('alerts.importantNotification.confirm') }}
      </button>
    </div>
  </modal>
</template>

<script>
import { Modal } from 'src/components';

export default {
  components: {
    Modal,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    hasRequiredNotification() {
      return !!this.importantNotifications.length;
    },
    importantNotifications() {
      return this.$store.state.misc.notifications.filter(el => el.is_required_to_read && !el.is_read);
    },
    activeNotification() {
      if (this.importantNotifications.length) {
        return this.importantNotifications[this.importantNotifications.length - 1];
      }

      return null;
    }
  },
  methods: {
    readNotification() {
      if (this.isLoading) return;

      this.isLoading = true;
      this.$api.readNotification(this.activeNotification.id)
        .then(() => this.$store.dispatch('getNotifications', {}))
        .finally(() => this.isLoading = false);
    },
  },
};
</script>

<style lang="scss" scoped>
.notification {
  /deep/ .modal-content {
    max-width: 360px;
  }
  .title {
    font-size: 16px;
    font-weight: 600;
    color: #2d2d2d;
  }
  .body {
    display: flex;
    align-items: center;
    flex-direction: column;

    i.warning {
      font-size: 48px;
      font-weight: 300;
      color: #FFCE06;
      margin-top: 8px;
    }

    .label {
      margin-top: 16px;
      text-align: center;
      font-size: 14px;
      font-weight: 600;
      color: #303634;
      white-space: pre-line;
    }

    .message {
      margin-top: 8px;
      margin-bottom: 16px;
      text-align: center;
      color: #303634;
      font-size: 14px;
      white-space: pre-line;
    }
  }
  .btn.confirm {
    min-width: 196px!important;
    padding: 0 30px;
  }
}

</style>
