export default {
  data() {
    return {
      lastReportQuery: '',
    };
  },
  computed: {
    exportLinks() {
      const formats = ['csv', 'xlsx'];
      const result = {};

      formats.forEach((format) => {
        result[format] = this.getExportLink(format);
      });

      return result;
    },
  },
  methods: {
    getExportLink(format) {
      return `${this.lastReportQuery}&export_format=${format}&access_token=${encodeURIComponent(this.$store.state.auth.userToken)}`;
    },
  },
};
