<template>
  <div class="col-md-4 ml-auto mr-auto login-wrapper restore__success-main">
    <div class="login-logo">
      <img :src="logo" alt="logo">
    </div>
    <form @submit.prevent>
      <card class="card-login">
        <img :src="icon" alt="icon" class="mb-24">
        <h3>{{ $t('forms.restorePassword') }}</h3>
        <div class="row">
          <div class="col-md-8 col-12">
            {{ $t('forms.restoreSucText') }}
          </div>
        </div>
        <br>
        <router-link
          :to="{ path: '/login'}"
          class="btn btn-outline-primary btn-md"
          replace
        >
          &lt; {{ $t('forms.btn.back') }}
        </router-link>
      </card>
    </form>
    <global-alerts />
  </div>
</template>
<script>
export default {
  props: {
    icon: {
      type: String,
      default: require('@/assets/theme/icon/Plane.svg'),
    },
  },
  computed: {
    logo() {
      return this.$config.layout.logoFull;
    },
  },
};
</script>
<style lang="scss">
.restore__success-main{
  .card-login {
    h3 {
      margin-left: -2px;
      margin-bottom: 12px !important;
    }
  }
}
</style>
