export default (mock) => {
  mock.onPost('/cont').reply(200, {
    success: true,
    dataContact: [
      {
        title: 'Title',
        text: 'Text',
      },
    ],
    errors: [{}],
  });
};
