import Vue from 'vue';
import lzString from 'lz-string';
import InjectPlugins from 'src/components/Report/Components/inject-plugins';

export default ({ Component, Loader, Catch }) => Vue.component('loader', {
  data() {
    return {
      error: false,
      loading: true,
      data: null,
      params: this.$route.params.urlQuery ? JSON.parse(lzString.decompressFromEncodedURIComponent(this.$route.params.urlQuery)) : {},
    };
  },
  mounted() {
    this.fetch();
  },
  mixins: [
    InjectPlugins,
  ],
  methods: {
    fetch() {
      if (this.data) {
        return;
      }

      this.loading = true;
      this.error = false;

      if (Component.fetch) {
        Component.fetch(this, this.params)
          .then((data) => {
            this.loading = false;
            this.data = data;
          })
          .catch((err) => {
            this.error = err;
          });
      }
    },
  },
  render(h) {
    if (Catch && this.error) {
      return h(Catch, {
        props: {
          ctx: this,
          error: this.error,
        },
      });
    }

    if (Loader && this.loading) {
      return h(Loader, { props: { name: Component.name } });
    }

    return h(Component, {
      props: {
        ctx: this,
        fetchedData: this.data,
        passedParams: this.params,
      },
    });
  },
});
