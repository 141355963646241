<template>
  <section>
    <div v-if="active" class="toast-error">
      <div class="toast-error__wrap">
        <div class="toast-error__inner">
          <div class="toast-error__header">
            <a
              href="#close"
              title="Закрыть"
              class="iw-close"
              @click="close"
            >x</a>
          </div>
          <div class="toast-error__text">
            <p>Сообщение</p>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>

export default {
  data() {
    return {
      active: false,
    };
  },
  methods: {
    open() {
      this.active = true;
      setTimeout(this.close, 1500);
    },
    close() {
      this.active = false;
    },
  },
};

</script>

<style lang="scss" scoped>
.toast-error {
 	position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 10000000;
    margin: 0;
    padding: 0;
	.toast-error__wrap {
		margin:auto;
		margin-top:30vh;
	}
	.toast-error__inner {
		position: relative;
		background: #000000;
		border: 1px solid #ccc;
		border-radius: 8px;
		opacity: 0.6;
		transition: all 0.5s ease;
	}
	.iw-close {
		position:absolute;
		top: 9px;
		right: 10px;
		font-size: 20px;
		color: #fff;
		text-decoration: none;
	}
	.iw-close:hover, .iw-close:focus {
		color: #ccc;
		cursor: pointer;
	}
	.toast-error__text {
		padding: 15px 20px;
		color: #fff;
	}

	@media (min-width: 550px) {
		.toast-error__wrap {
			max-width: 250px;
		}
	}
}
</style>
