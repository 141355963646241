<template>
  <section class="media media-loader">
    <div slot="head" class="head-wrap">
      <div class="justify-content-between d-flex ml-8 mr-8 level-0">
        <div class="d-flex level-1">
          <div class="d-flex level-2">
            <skeleton height="12px" width="163px" class="mr-12 mt-10 mb-0"/>
          </div>
          <div class="d-flex level-2">
            <skeleton height="34px" width="200px" class="mr-12"/>
            <skeleton height="34px" width="150px" class="mr-12"/>
          </div>
        </div>
        <div class="d-flex level-1">
          <div class="d-flex level-2 ml-8">
            <skeleton height="34px" width="153px"/>
          </div>
          <div class="d-flex level-2 ml-12">
            <div>
              <skeleton height="34px" width="80px" class="mr-12"/>
              <skeleton height="34px" width="212px"/>
            </div>
          </div>
        </div>
      </div>
    </div>
    <skeleton width="auto" class="mb-24 ml-24 mr-24"/>
  </section>
</template>

<script>
import Skeleton from '../../components/Skeleton';

export default {
  components: {
    Skeleton,
  },
  created() {
    this.name = this.$vnode.data.props.name;
  },
};
</script>

<style lang="scss">
@import 'src/assets/theme/default/media/media';
</style>
