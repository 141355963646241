<template>
  <section class="offers" :key="offers.length">
    <div class="row">
      <div class="col-12 custom-padding d-flex mb-8 pl-0 pr-0">
        <h4 v-if="!subAffiliateActive" class="offers__title pb-8 pt-0">
          {{ $t('offers.cardOffers.titleMyOffers') }}
        </h4>
        <span v-if="subAffiliateActive" class="offers__title-subaffiliate">
          <button class="btn btn-outline-primary btn-sm mt-0 mb-0 mr-16" @click="backToMyOffers">
            &lt; {{ $t('profile.buttons.back') }}
          </button>
          <span>{{ $t('subAffiliates.card.title') }}</span> /
          <span class="text-green"> {{ sub_affilate_email }} </span> /
          {{ $t('offers.offersTitleMain') }}
        </span>
      </div>

      <div class="col-md-4 custom-padding card-offer-mini-wrap pr-16 pl-0">
        <!-- my offer -->
        <div
          class="card-offer card-offer-mini card-offer-tab"
          v-for="offer in $store.state.offers.my"
          :key="offer.id"
          :class="{
            'card-offer-mini__active': offer.id === activeTab,
            wavebg: loading,
          }"
          @click="setActiveTab(offer, true)"
        >
          <div class="card-offer__head-btn">
            <div class>
              <span class="card-offer-id">ID: {{ offer.incrementing_id }}</span>
              <h5 class="card-offer-heading">
                {{ offer.title }}
              </h5>
            </div>
            <!-- arrow -->
            <i class="el-icon-arrow-right" v-if="offer.id !== activeTab"></i>
          </div>
        </div>

        <div class="col" v-if="!offers.length">
          <h4 class="offers__no-data">
            {{ $t('offers.noData') }}
          </h4>
        </div>
      </div>

      <!-- right pannel -->
      <div
        ref="rightPanel"
        class="col-md-8 custom-padding pr-0 pl-0 right-panel-scroll"
        v-for="offer in activeOffer.filter(el => el)"
        :key="offer.id"
      >
        <div class="card-offer pb-16 mb-2" :class="{ wavebg: loading }">
          <div class="card-offer__head-id">
            <div>
              <span class="card-offer-id">ID: {{ offer.incrementing_id }}</span>
              <h5 class="offers__title">
                {{ offer.title }}
              </h5>
            </div>
            <button class="btn btn-primary btn-sm card-offer__btn-r" @click="getLinks()">
              {{ $t('offers.cardOffers.btnGet') }}
            </button>
          </div>
          <div class="card-offer-content d-flex">
            <div class="card-offer__col card-offer__hold">
              <p class="card-offer__col-title">
                {{ $t('media.fields.visible_brands.title') }}
              </p>
              <span class="card-offer__col-val card-offer__col-brands">{{ getBrands(offer) }}</span>
            </div>
          </div>
        </div>

        <div class="card-offer" :class="{ wavebg: loading }">
          <div class="row offer-rc">
            <div class="offer-rc__left">
              <!-- rules and conditions -->
              <h5>
                {{ $t('offers.cardOffers.rulesConditions') }}
              </h5>
              <div
                class="offer-setting"
                v-for="(rule, index) in $store.state.offers.offer.payout_rules"
                :key="index"
                @click="setActiveRule(index)"
                :class="activeRule === index ? 'offer-setting-active' : ''"
              >
                <span>{{
                  getOfferName(rule, {
                    rowLen: 38,
                    isDefault: $store.state.offers.offer.payout_rules.length - 1 === index,
                  })
                }}</span>
                <i class="el-icon-arrow-right"></i>
              </div>
            </div>

            <div class="offer-rc__right">
              <!-- Country filter -->
              <div class="offer-rc__filter mb-16 pl-8" v-if="countries">
                <h5 class="mt-8 mb-0">
                  {{ $t('offers.cardOffers.countryFilter') }}
                </h5>
                <span v-html="countries.map(c => (countriesMap[c] ? countriesMap[c].name : c)).join(', ')"></span>
              </div>
              <!-- RevShare -->
              <div v-if="hasRevShare" class="ui-d-flex offer-rc__rules-container">
                <div v-if="$_.get(currentRule, 'settings.rev_share.sport_levels') !== null" class="offer-rc__rule">
                  <div class="offer-setting offer-setting__title">
                    {{
                      $_.get(currentRule, 'settings.rev_share.casino_levels') !== null
                        ? $t('offers.cardOffers.revShareSport')
                        : $t('offers.cardOffers.revshare')
                    }}
                  </div>
                  <div class="offer-rc__table">
                    <div
                      v-for="(level, i) in $_.get(currentRule, 'settings.rev_share.sport_levels')"
                      :key="i"
                      class="offer-rc__row"
                    >
                      <div class="ui-align-center">
                        <i class="fas fa-greater-than-equal offer-rc__less-or-equal" />
                        <span class="ui-m-sm-l">{{ level.ftd_count }} FTD</span>
                      </div>
                      <span>{{ level.percent }}%</span>
                    </div>
                  </div>
                </div>

                <div v-if="$_.get(currentRule, 'settings.rev_share.casino_levels') !== null" class="offer-rc__rule">
                  <div class="offer-setting offer-setting__title">
                    {{ $t('offers.cardOffers.revShareCasino') }}
                  </div>
                  <div class="offer-rc__table">
                    <div
                      v-for="(level, i) in $_.get(currentRule, 'settings.rev_share.casino_levels')"
                      :key="i"
                      class="offer-rc__row"
                    >
                      <div class="ui-align-center">
                        <i class="fas fa-greater-than-equal offer-rc__less-or-equal" />
                        <span class="ui-m-sm-l">{{ level.ftd_count }} FTD</span>
                      </div>
                      <span>{{ level.percent }}%</span>
                    </div>
                  </div>
                </div>
              </div>
              <!-- CPA -->
              <div
                v-for="(curCpa, i) in $_.get(
                  currentRule,
                  ['settings', 'cpa'],
                  [],
                )"
                :key="i"
              >
                <div
                  v-if="
                    checkRows(
                      curCpa.amount,
                      curCpa.daily_cap,
                      curCpa.hold_seconds,
                    ) && curCpa.amount !== null
                  "
                  class="offer-setting offer-setting__title"
                >
                  CPA
                </div>
                <div
                  v-if="
                    checkRows(
                      curCpa.amount,
                      curCpa.daily_cap,
                      curCpa.hold_seconds,
                    ) && curCpa.amount !== null
                  "
                  class="line root"
                >
                  <div class="offer-rc__table">
                    <div class="offer-rc__row" v-if="curCpa.amount !== null">
                      <span>{{ $t('offers.cardOffers.payout') }}</span>
                      <span>{{ currency }} {{ numeral(curCpa.amount).format('0,0.00') }}</span>
                    </div>
                    <div class="offer-rc__row" v-if="curCpa.cap_players_count !== null && curCpa.cap_period_seconds !== null">
                      <span>{{ $t('offers.cardOffers.cap_players') }}</span>
                      <template v-if="curCpa.cap_players_count === 0">
                        0
                      </template>
                      <template v-else>
                        {{ capPlayers({ count: curCpa.cap_players_count, days: $moment.duration(curCpa.cap_period_seconds, 'seconds').asDays() }) }}
                      </template>
                    </div>
                    <div class="offer-rc__row" v-if="curCpa.hold_seconds !== null">
                      <span>{{ $t('offers.cardOffers.hold') }}</span>
                      <span>{{ daysFunc(curCpa.hold_seconds) }}</span>
                    </div>
                  </div>
                </div>

                <!-- Deposits -->
                <div
                  class="line"
                  v-if="
                    checkRows(
                      curCpa.deposits_count_min,
                      curCpa.deposits_count_max,
                      curCpa.deposits_sum_min,
                      curCpa.deposits_sum_max,
                      curCpa.deposit_amount_min,
                      curCpa.deposit_amount_max,
                    )
                  "
                >
                  <div class="offer-setting offer-setting__title">
                    {{ $t('offers.cardOffers.deposits') }}
                  </div>
                  <div class="offer-rc__table">
                    <!-- Deposits Count -->
                    <div class="offer-rc__row" v-if="curCpa.deposits_count_min || curCpa.deposits_count_max">
                      <span>{{ $t('offers.cardOffers.depositsCount') }}</span>
                      <span>{{ integerOutput(curCpa.deposits_count_min, curCpa.deposits_count_max, false) }}</span>
                    </div>
                    <!-- Deposits Sum -->
                    <div class="offer-rc__row" v-if="curCpa.deposits_sum_min || curCpa.deposits_sum_max">
                      <span>{{ $t('offers.cardOffers.depositsSum') }}</span>
                      <span>{{ currencyOutput(curCpa.deposits_sum_min, curCpa.deposits_sum_max) }}</span>
                    </div>
                    <!-- One Deposit Amount -->
                    <div class="offer-rc__row" v-if="curCpa.deposit_amount_min || curCpa.deposit_amount_max">
                      <span>{{ $t('offers.cardOffers.oneDepositAmount') }}</span>

                      <span>{{ currencyOutput(curCpa.deposit_amount_min, curCpa.deposit_amount_max) }}</span>
                    </div>
                  </div>
                </div>

                <!-- Sport Bets-->
                <div
                  class="line"
                  v-if="
                    checkRows(
                      curCpa.sport_bets_count_min,
                      curCpa.sport_bets_count_max,
                      curCpa.sport_bets_sum_min,
                      curCpa.sport_bets_sum_max,
                      curCpa.sport_bet_amount_min,
                      curCpa.sport_bet_amount_max,
                      curCpa.sport_bets_odd_min,
                      curCpa.sport_bets_odd_max,
                    )
                  "
                >
                  <div class="offer-setting offer-setting__title">
                    {{ $t('offers.cardOffers.sportBets') }}
                  </div>
                  <div class="offer-rc__table">
                    <!-- Bets Count -->
                    <div class="offer-rc__row" v-if="curCpa.sport_bets_count_min || curCpa.sport_bets_count_max">
                      <span>{{ $t('offers.cardOffers.betsCount') }}</span>
                      <span>{{ integerOutput(curCpa.sport_bets_count_min, curCpa.sport_bets_count_max, false) }}</span>
                    </div>
                    <!-- Bets Sum -->
                    <div class="offer-rc__row" v-if="curCpa.sport_bets_sum_min || curCpa.sport_bets_sum_max">
                      <span>{{ $t('offers.cardOffers.betsSum') }}</span>
                      <span>{{ currencyOutput(curCpa.sport_bets_sum_min, curCpa.sport_bets_sum_max) }}</span>
                    </div>
                    <!-- One Bet Amount -->
                    <div class="offer-rc__row" v-if="curCpa.sport_bet_amount_min || curCpa.sport_bet_amount_max">
                      <span>{{ $t('offers.cardOffers.oneBetAmount') }}</span>
                      <span>{{ currencyOutput(curCpa.sport_bet_amount_min, curCpa.sport_bet_amount_max) }}</span>
                    </div>
                    <!-- Bet Odd -->
                    <div class="offer-rc__row" v-if="curCpa.sport_bets_odd_min || curCpa.sport_bets_odd_max">
                      <span>{{ $t('offers.cardOffers.betOdd') }}</span>
                      <span>{{ integerOutput(curCpa.sport_bets_odd_min, curCpa.sport_bets_odd_max, false) }}</span>
                    </div>
                  </div>
                </div>

                <!-- Casino Bets -->
                <div
                  class="line"
                  v-if="
                    checkRows(
                      curCpa.casino_bets_count_min,
                      curCpa.casino_bets_count_max,
                      curCpa.casino_bets_sum_min,
                      curCpa.casino_bets_sum_max,
                      curCpa.casino_bet_amount_min,
                      curCpa.casino_bet_amount_max,
                    )
                  "
                >
                  <div class="offer-setting offer-setting__title">
                    {{ $t('offers.cardOffers.casinoBets') }}
                  </div>
                  <div class="offer-rc__table">
                    <!-- Bets Count -->
                    <div class="offer-rc__row" v-if="curCpa.casino_bets_count_min || curCpa.casino_bets_count_max">
                      <span>{{ $t('offers.cardOffers.betsCount') }}</span>
                      <span>{{
                        integerOutput(curCpa.casino_bets_count_min, curCpa.casino_bets_count_max, false)
                      }}</span>
                    </div>
                    <!-- Bets Sum -->
                    <div class="offer-rc__row" v-if="curCpa.casino_bets_sum_min || curCpa.casino_bets_sum_max">
                      <span>{{ $t('offers.cardOffers.betsSum') }}</span>
                      <span>{{ currencyOutput(curCpa.casino_bets_sum_min, curCpa.casino_bets_sum_max) }}</span>
                    </div>
                    <!-- One Bet Amount -->
                    <div class="offer-rc__row" v-if="curCpa.casino_bet_amount_min || curCpa.casino_bet_amount_max">
                      <span>{{ $t('offers.cardOffers.oneBetAmount') }}</span>
                      <span>{{ currencyOutput(curCpa.casino_bet_amount_min, curCpa.casino_bet_amount_max) }}</span>
                    </div>
                  </div>
                </div>

                <!-- если rev_share_percent_sport != rev_share_percent_casino, выводим 2 строки: RevShare Sport, RevShare Casino (справа проценты)
              если ===, то просто 1 строка RevShare
              если rev_share_percent_sport === rev_share_percent_casino === null - то блок RevShare не выводится-->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <offer-popup ref="offerPopup"></offer-popup>
    <offer-request-success></offer-request-success>
    <offer-request ref="getLinks"></offer-request>
  </section>
</template>

<script>
import numeral from 'numeral';
import eventBus from 'src/lib/eventBus';
import UrlMixin from 'src/components/Query/url-mixin';
import UrlMixinHooks from 'src/components/Query/url-mixin-hooks';
import HelpersMixin from 'src/components/Helpers/helpers-mixin.js';
import ResponsiveMixin from 'src/components/Helpers/responsive-mixin.js';
import OfferRequest from './Offers/OfferRequest';
import MyOffer from './Offers/MyOffer';
import OfferPopup from './Offers/OfferPopup';
import OfferRequestSuccess from './Offers/OfferRequestSuccess';

const fetch = async (ctx, params) => {
  const { sub_affiliate_id, subAffiliateActive } = params;

  const getMarketingCampaigns = (params) => {
    ctx.$store.dispatch('getMarketingCampaigns', params);
  };

  if (subAffiliateActive) {
    getMarketingCampaigns({ sub_affiliate_id });
    return ctx.$store.dispatch('getSubAffilateOffers', sub_affiliate_id).then(data => data);
  }
  getMarketingCampaigns();
  return ctx.$store
    .dispatch('getAffiliateOffers', {
      sort_column: 'created_at',
      sort_dir: 'asc',
    })
    .then(data => data);
};

export default {
  fetch,
  components: {
    OfferPopup,
    OfferRequest,
    MyOffer,
    OfferRequestSuccess,
  },
  mixins: [UrlMixin, UrlMixinHooks, HelpersMixin, ResponsiveMixin],
  props: ['fetchedData', 'passedParams'],
  data() {
    return {
      numeral,
      loading: false,
      activeTab: '',
      activeRule: 0,
      sort_column: 'created_at',
      sort_dir: 'asc',
      sub_affilate_email: '',
      sub_affiliate_id: '',
      subAffiliateActive: false,
      lastQuery: {},
    };
  },
  mounted() {
    this.setFromQuery(this.passedParams);
    this.setActiveTab(this.firstOffer);

    eventBus.$on('window-refresh', () => {
      if (this.$route.name === 'routes.offers') {
        this.loading = true;
        this.afterBrowserRedraw(() => {
          this.loading = false;
        });
      }
    });
  },
  deactivated() {
    this.loading = true;
  },
  async activated() {
    this.loading = true;
    this.setFromQuery(this.urlQuery);
    await fetch(this, this.urlQuery);
    this.setActiveTab(this.firstOffer);
    await this.afterBrowserRedraw();
    this.loading = false;
  },
  methods: {
    capPlayers({ count, days }) {
      return this.$tc('offers.cardOffers.cap_players_per_days', days, { count, days })
    },
    hasRs(lvl) {
      return lvl?.some(el => !!+el.percent);
    },
    getOfferName(rule, { rowLen = -1, isDefault = false }) {
      if (isDefault) {
        return 'Default';
      }

      return this.countryPartName(rule, rowLen, isDefault);
    },
    isEmptyRs(lvl) {
      if (!lvl) return true;

      return lvl.every(el => !+el.percent);
    },
    prefixName(rule) {
      if (!rule) return '';
      const { rev_share, cpa } = rule.settings;

      if ([rev_share?.sport_levels, rev_share?.casino_levels].every(this.isEmptyRs) && cpa?.some(el => el.amount > 0)) {
        return '[CPA] ';
      }

      if (rev_share && cpa) {
        return '[Hyb] ';
      }

      if (rev_share) {
        return '[RS] ';
      }

      if (cpa) {
        return '[CPA] ';
      }

      return '';
    },
    countryPartName(rule, rowLen) {
      const prefix = this.prefixName(rule);
      const values = rule.filters?.country_code || [];

      if (!values?.length) {
        return prefix;
      }

      if (rowLen === -1) {
        return `${prefix} ${values.join(', ')}`;
      }

      const fitValues = this.$_.truncate(values.join(', '), {
        length: rowLen - prefix.length,
        separator: ', ',
        omission: '',
      });

      const notFit = values.filter(val => !fitValues.split(', ').includes(val));
      const countNotFit = notFit.length ? ` [+${notFit.length}]` : '';
      return `${prefix} ${fitValues}${countNotFit}`;
    },
    genCpaParams(cpa) {
      const {
        amount, currency_code, daily_cap, hold_seconds, conditions, cap_period_seconds, cap_players_count,
      } = cpa;
      const values = conditions.reduce((acc, item) => {
        acc[`${item.name}_min`] = item.min_value;
        acc[`${item.name}_max`] = item.max_value;

        return acc;
      }, {});
      return {
        amount,
        currency_code,
        daily_cap,
        cap_period_seconds,
        cap_players_count,
        hold_seconds,
        ...values,
      };
    },
    setFromQuery(query) {
      const { sub_affiliate_id, sub_affilate_email, subAffiliateActive } = query;

      this.sub_affiliate_id = sub_affiliate_id;
      this.sub_affilate_email = sub_affilate_email;
      this.subAffiliateActive = !!subAffiliateActive;
    },
    backToMyOffers() {
      this.sub_affilate_email = '';
      this.sub_affiliate_id = '';
      this.subAffiliateActive = false;
      this.$router.push({
        name: 'routes.subAffiliates',
        params: {},
      });
    },
    daysFunc(seconds) {
      if (Number(seconds) === NaN || !seconds) return 0;
      seconds = Number(seconds);
      const d = Math.floor(seconds / (3600 * 24));
      const h = Math.floor((seconds % (3600 * 24)) / 3600);
      const m = Math.floor((seconds % 3600) / 60);

      const dDisplay = d > 0 ? this.$tc('offers.cardOffers.pluralDays', d, { days: d }) : '';
      const hDisplay = h > 0 ? h + (h == 1 ? ` ${this.$t('customReports.hour')} ` : ` ${this.$t('offers.cardOffers.hours')} `) : '';
      const mDisplay = m > 0
        ? m + (m == 1 ? ` ${this.$t('customReports.minute')} ` : ` ${this.$t('offers.cardOffers.minutes')} `)
        : '';

      return dDisplay + hDisplay + mDisplay;
    },
    checkRows(...args) {
      return args.some(el => !!el);
    },
    parseObject(obj) {
      if (obj.predicate === 'NOT_EQUALS') {
        return (
          `<span class="text-red">${this.$t('offers.cardOffers.denied')}: </span>${obj.values.join(', ')}<br>`
        );
      } else if (obj.predicate === 'EQUALS') {
        return (
          `<span class="text-green">${this.$t('offers.cardOffers.allowed')}: </span>${obj.values.join(', ')}<br>`
        );
      }
    },
    getQueryData() {
      return {
        sort_column: this.sort_column,
        sort_dir: this.sort_dir,
        sub_affiliate_id: this.sub_affiliate_id,
      };
    },
    integerOutput(min, max, format = true) {
      const _this = this;

      let sign = '';
      if (!(min === null || min === undefined) && !(max === null || max === undefined)) {
        sign = ' - ';
      }
      if (format) {
        return (
          _integerOut(this.$t('offers.cardOffers.min'), min) + sign + _integerOut(this.$t('offers.cardOffers.max'), max)
        );
      } else {
        return (
          _integerOut(this.$t('offers.cardOffers.min'), min, format)
          + sign
          + _integerOut(this.$t('offers.cardOffers.max'), max, format)
        );
      }

      function _integerOut(sign, v, format = true) {
        if (v === null || v === undefined) return '';
        if (sign !== _this.$t('offers.cardOffers.min') && sign !== _this.$t('offers.cardOffers.max')) {
          throw new Error("Signs must be 'min' or 'max' ");
        }
        if (format) {
          return `${sign[0].toUpperCase() + sign.slice(1)}: ${_this.integerFormatter(v)}`;
        } else {
          return `${sign[0].toUpperCase() + sign.slice(1)}: ${v}`;
        }
      }
    },
    currencyOutput(min, max) {
      const _this = this;

      let sign = '';
      if (min && max) {
        sign = ' - ';
      }
      return (
        _currencyOut(this.$t('offers.cardOffers.min'), min) + sign + _currencyOut(this.$t('offers.cardOffers.max'), max)
      );

      function _currencyOut(sign, v) {
        if (!v) return '';
        if (sign !== _this.$t('offers.cardOffers.min') && sign !== _this.$t('offers.cardOffers.max')) {
          throw new Error("Signs must be 'min' or 'max' ");
        }
        return `${sign[0].toUpperCase() + sign.slice(1)}: ${_this.currency} ${_this.integerFormatter(v)}`;
      }
    },
    integerFormatter(v) {
      if (v === null) return '';
      return numeral(v).format('0,0.00');
    },

    currencyFormatter(code) {
      if (!code) {
        return '';
      }

      const miscCurrencies = this.$store.state.misc.currencies;
      const currency = miscCurrencies.find(c => code.toLowerCase() === c.code?.toLowerCase());

      return currency ? currency.code.toUpperCase() : '';
    },

    getLinks() {
      this.$refs.offerPopup.open(this.$store.state.offers.offer, this.sub_affiliate_id);
    },
    setActiveTab(offer, scrollToActive) {
      document.getElementsByClassName('wrapper')[0].scrollTop = 0;

      if (offer.id) {
        this.activeTab = offer.id;
        this.$store.dispatch('getOffer', this.activeTab).then(() => {
          this.activeRule = this.getDefaultActiveRule();

          if (scrollToActive && this.screenWidth < 768) {
            const rightPanel = this.$_.get(this.$refs, ['rightPanel', 0]);
            rightPanel?.scrollIntoView({ behavior: 'smooth' });
          }
        });
      }
    },

    getDefaultActiveRule() {
      const { offer } = this.$store.state.offers || {};
      return Math.max(
        offer?.payout_rules?.findIndex(rule => Object.keys(rule.filters).length === 0),
        0,
      );
    },

    setActiveRule(index) {
      this.activeRule = index;
    },
    openRequestOffer(id) {
      this.$refs.request.open(id);
    },
    getBrands() {
      return this.$store.state.offers.offer?.visible_brands?.map(brands => brands.site_name).join(', ');
    },
  },
  computed: {
    hasRevShare() {
      const amount = this.$_.get(this.currentRule, [
        'settings',
        'cpa',
      ])?.some(el => !!el.amount);

      if (this.currentRule?.settings?.rev_share) {
        return (
          [
            this.currentRule.settings.rev_share?.sport_levels,
            this.currentRule.settings.rev_share?.casino_levels,
          ].some(this.hasRs) || !amount
        );
      }

      return false;
    },
    countriesMap() {
      return this.$store.state.misc.countries.reduce((acc, item) => {
        acc[item.code] = { name: item.name };
        return acc;
      }, {});
    },
    cacheData() {
      return {
        activeTab: this.activeTab,
        activeRule: this.activeRule,
      };
    },
    activeOffer() {
      return [this.offers.find(e => e.id === this.activeTab)];
    },
    currency() {
      return this.currencyFormatter(this.currentRule.settings.cpa[0].currency_code);
    },
    countries() {
      if (_.isEmpty(this.activeOffer)) return;
      const obj = this.currentRule.filters?.country_code;
      if (!obj) return;

      return obj;
    },
    subids() {
      if (_.isEmpty(this.activeOffer)) return;
      const obj = this.currentRule.filters;
      if (!obj) return;

      const o = {};
      Object.keys(obj).forEach((e, i) => {
        if (e.indexOf('subid') !== -1) {
          o[e] = obj[e];
          o[e].name = e;
        }
      });
      return o;
    },
    offers() {
      return this.$store.state.offers.my || [];
    },
    firstOffer() {
      return this.offers.length > 0 ? this.offers[0] : {};
    },
    availableOffers() {
      return this.$store.state.offers.all;
    },
    currentRule() {
      const { offer } = this.$store.state.offers;
      const rule = this.$_.get(offer, ['payout_rules', this.activeRule]);

      if (rule) {
        return {
          ...rule,
          settings: {
            ...rule.settings,
            rev_share: rule.settings.rev_share
              ? {
                sport_levels: rule.settings.rev_share.sport_levels,
                casino_levels: this.$_.isEqual(
                  rule.settings.rev_share.sport_levels,
                  rule.settings.rev_share.casino_levels,
                )
                  ? null
                  : rule.settings.rev_share.casino_levels,
              }
              : null,
            cpa: rule.settings.cpa?.map(this.genCpaParams) || null,
          },
        };
      }

      return {};
    },
  },
  watch: {
    offers(offers) {
      if ((!this.activeTab && offers.length !== 0) || this.subAffiliateActive) {
        this.activeTab = this.firstOffer.id;
      }
    },
  },
  filters: {
    days(seconds) {
      seconds = Number(seconds);
      const d = Math.floor(seconds / (3600 * 24));
      const h = Math.floor((seconds % (3600 * 24)) / 3600);
      const m = Math.floor((seconds % 3600) / 60);

      const dDisplay = d > 0 ? d + (d == 1 ? ' day ' : ' days ') : '';
      const hDisplay = h > 0 ? h + (h == 1 ? ' hour ' : ' hours ') : '';
      const mDisplay = m > 0 ? m + (m == 1 ? ' minute ' : ' minutes ') : '';

      return dDisplay + hDisplay + mDisplay;
    },
  },
};
</script>
<style lang="scss">
@import 'src/assets/theme/default/offers/offers';
</style>
<style lang="scss" scoped>
.right-panel-scroll {
  scroll-margin-block-start: 50px;
}
.line {
  position: relative;
  padding-left: 18px;

  &.root .offer-rc__table {
    margin-left: 2px;
  }

  .offer-card__table-row {
    padding-left: 0 !important;
  }

  .offer-rc__table {
    margin-left: 14px;
    padding-left: 0;
  }

  .offer-setting__title {
    margin-left: 4px;
    position: relative;
    overflow: initial !important;

    &::before {
      content: '';
      position: absolute;
      left: -10px;
      top: 50%;
      height: 1px;
      width: 10px;
      background-color: #eaeaea;
    }
  }

  &:not(:last-child) {
    .offer-rc__table {
      &::after {
        content: '';
        position: absolute;
        left: 12px;
        top: 0;
        height: 100%;
        width: 1px;
        background-color: #eaeaea;
      }
    }

    .offer-setting__title::after {
      content: '';
      position: absolute;
      left: -10px;
      top: 0;
      height: 100%;
      width: 1px;
      background-color: #eaeaea;
    }
  }
  &:last-child {
    .offer-setting__title::after {
      content: '';
      position: absolute;
      left: -10px;
      top: 0;
      height: 50%;
      width: 1px;
      background-color: #eaeaea;
    }
  }
}
.right-panel-scroll {
  scroll-margin-block-start: 50px;
}
</style>
