<template>
  <div class="col-md-4 ml-auto mr-auto login-wrapper restore__pass-main">
    <div class="login-logo">
      <img :src="logo" alt="logo">
    </div>

    <form :class="{loading: loading, error: error}" @submit.prevent>
      <card class="card-login pt-20">
        <section class="inputs">
          <h3 class="mb-6">
            {{ $t('forms.restorePassword') }}
          </h3>
          <div class="row">
            <div class="col-md-9 col-12">
              {{ $t('forms.restoreText') }}
            </div>
          </div>
          <br>
          <div>
            <h6 class="input-title">
              {{ $t('forms.emailLabel') }}
            </h6>
            <input
              v-model="model.email"
              :placeholder="$t('forms.emailPlaceholder')"
              type="email"
              class="login-input"
              autofocus
              @focus="emailValidation = false"
              @blur="emailValidation = true"
              @input="errors_ = ''"
            >
            <div v-if="(emailValidation && !valid_email) || errors_" class="text-danger text-right">
              {{ !errors_ ? $t('forms.validation.email') : errors_[0].message }}
            </div>
          </div>
          <div class="btn-box d-flex justify-content-between">
            <router-link
              :to="{ path: '/login'}"
              class="btn btn-outline-primary btn-md"
              replace
            >
              &lt; {{ $t('forms.btn.back') }}
            </router-link>
            <button
              :disabled="!valid_email || loading"
              class="btn btn-primary btn-md"
              @click="requestRestore"
            >
              {{ $t('forms.btn.submit') }} >
            </button>
          </div>
        </section>
      </card>
      <div class="loading__block">
        <i class="fa fa-spinner fa-3x fa-fw login__spinner" />
      </div>
    </form>
  </div>
</template>

<script>
import { required, email, minLength } from 'vuelidate/lib/validators';

export default {
  data() {
    return {
      emailValidation: false,
      passwordValidation: false,
      loading: false,
      model: {
        email: '',
      },
      errors_: '',
    };
  },

  computed: {
    valid_email() {
      const _email = this.$v.model.email;
      return _email.email && _email.required;
    },
    error() {
      return this.$store.state.errors.request >= 400;
    },
    logo() {
      return this.$config.layout.logoFull;
    },
  },
  methods: {
    requestRestore() {
      const that = this;
      this.loading = true;
      this.$request
        .post('auth/forgot-password', {
          email: that.model.email,
          return_url: `${window.location.origin}/#/reset-password/%%TOKEN%%/%%AFFILIATE_EMAIL%%`,
        })
        .then(() => {
          this.$router.push('/restore-success');
        })
        .catch((err) => {
          this.errors_ = err.response.data.errors.email;
          this.loading = false;
        });
    },
  },
  validations: {
    model: {
      password: {
        required,
        minLength: minLength(8),
      },
      email: {
        required,
        email,
      },
    },
  },
};
</script>

<style lang="scss">
@import 'styles/restorepassword';
</style>
