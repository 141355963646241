<template>
  <section>
    <password ref="password" />
    <second-factor :active.sync="confirmByOTPModal" />
  </section>
</template>

<script>
import { Modal } from 'src/components';
// этот файл и 2 ниже по ходу не используются, чекнуть и на удаление
import SecondFactor from './Dialogues/SecondFactor';
import Password from './Dialogues/Password';

export default {
  components: {
    Modal,
    SecondFactor,
    Password,
  },
  data() {
    return {
      otp: false,
    };
  },
  computed: {
    confirmByOTPModal() {
      return false;
    },
  },
  created() {
    window.Vue.prototype.$confirm = this;
  },
  methods: {
    passwordCancel() {
      this.closed();
    },
    password(title, comment, callback, closed, label) {
      this.$refs.password.open(title, comment, callback, closed, label);
    },
  },
};
</script>

<style>
.otp-modal {
  background: #444 !important;
  z-index: 10000 !important;
  padding-top: 20%;
}

.password-modal {
  text-align: center;
}
.password-modal .modal-content {
  border-radius: 8px;
  max-width: 300px;
  margin: auto auto;
  box-shadow: 0px 0px 12px #aaaaaa;
}
.password-modal .modal-header {
  display: none;
}
.password-modal label {
  font-size: 20px !important;
  margin-bottom: 16px;
}
.password-modal .form-group input {
  font-size: 24px;
  padding: 40px !important;
  text-align: center;
}
.password-modal .modal-footer {
  justify-content: center;
}
.password-modal .modal-footer .btn {
  width: 230px;
  padding: 20px;
}
.password-modal.show {
  background-color: #000000c4;
}
</style>
