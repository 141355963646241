import lzString from 'lz-string';

export default {
  data() {
    return {
      path: '',
    };
  },
  computed: {
    urlQuery() {
      if (this.$route.params.urlQuery) {
        const decompressedString = lzString.decompressFromEncodedURIComponent(
          this.$route.params.urlQuery,
        );
        try {
          return JSON.parse(decompressedString);
        } catch (e) {
          console.log("Query string isn't a JSON string", decompressedString);
          return {};
        }
      }
      return {};
    },
  },
  methods: {
    getUrlQueryParam(param) {
      if (param === undefined) return this.urlQuery;
      return this.urlQuery[param];
    },
    compressUrlQuery(data) {
      return lzString.compressToEncodedURIComponent(JSON.stringify(data));
    },
    pushQuery(route, data) {
      const { commit } = this.$store;
      commit('saveUrlQuery', {
        name: route.name,
        data,
      });
      try {
        this.$router
          .replace({
            name: route.name,
            params: {
              ...route.params,
              urlQuery: this.compressUrlQuery(data),
            },
          })
          .catch((err) => {
            if (err.name === 'NavigationDuplicated') return;
            console.error(err);
          });
      } catch {}
    },
    extendUrlQuery(data) {
      const query = { ...this.urlQuery, ...data };
      return this.compressUrlQuery(query);
    },
  },
};
