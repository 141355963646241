import ElementUi from './ElementUi';
import UiButton from './UiButton.vue';
import UiFilter from './UiFilter.vue';
import UiFilters from './UiFilters.vue';
import UiIcon from './UiIcon.vue';
import UiInput from './UiInput.vue';
import UiPagination from './UiPagination.vue';
import UiPopup from './UiPopup.vue';
import UiRangeInput from './UiRangeInput.vue';
import UiSelect from './UiSelect.vue';
import UiTable from './UiTable.vue';
import UiColumnsSelect from './UiColumnsSelect.vue';


export default {
  install(Vue, options) {
    Vue.use(ElementUi);
    Vue.component(UiButton.name, UiButton);
    Vue.component(UiFilter.name, UiFilter);
    Vue.component(UiIcon.name, UiIcon);
    Vue.component(UiFilters.name, UiFilters);
    Vue.component(UiInput.name, UiInput);
    Vue.component(UiPagination.name, UiPagination);
    Vue.component(UiPopup.name, UiPopup);
    Vue.component(UiRangeInput.name, UiRangeInput);
    Vue.component(UiSelect.name, UiSelect);
    Vue.component(UiTable.name, UiTable);
    Vue.component(UiColumnsSelect.name, UiColumnsSelect);
  },
};
