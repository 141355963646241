import 'src/lib/polyfills';
import Notifications from 'src/components/NotificationPlugin';
import VeeValidate from 'vee-validate';
import GlobalDirectives from 'src/lib/globalDirectives';
import GlobalComponents from 'src/lib/globalComponents';
import SideBar from 'src/components/SidebarPlugin';
import AppNotifications from 'src/views/Notifications';
import 'bootstrap/scss/bootstrap.scss';
import 'src/assets/sass/custom-ui.scss';
import 'es6-promise/auto';
import 'select2/dist/css/select2.css';

export default {
  install(Vue) {
    Vue.use(GlobalComponents);
    Vue.use(GlobalDirectives);
    Vue.use(SideBar);
    Vue.use(Notifications);
    Vue.use(VeeValidate, { fieldsBagName: 'veeFields' });
    Vue.use(AppNotifications);
  },
};
