<template>
  <section
    class="player-report daily-report"
    :class="{
      wavebg: loading,
      mediaSAid: mediaSAid
    }"
  >
    <table-card
      ref="datatable"
      :loading="loading"
      :columns="getTableFields"
      :hide-columns="hiddenFields"
      :sort.sync="sorting"
      :page.sync="page"
      :page-size.sync="limit"
      :rows="data"
      :totals="totals"
      :count="count"
      i18n-path="reports"
      tablet-layout
      condenced
      show-total
      custom-context
      height-fix
      @filter="handleFilter"
      @change-range="fetchDataTable"
      @fetch-data="fetchDataTable"
    >
      <template slot="header">
        <div
          v-if="group_by !== 'date_formatted'"
          :class="[
            'filter-search__wrap',
            'filter-search__wrap-back',
            'pb-0',
            'align-items-center'
          ]"
        >
          <ui-button @click="back">
            &lt; {{ $t("profile.buttons.back") }}
          </ui-button>
          <b class="ml-8 mr-8">{{$moment(query.from).format('DD MMMM YYYY')}} </b>
        </div>
        <period-wrapper
          v-if="group_by === 'date_formatted'"
          class="ui-g-md ui-mobile-12"
          :settings="period"
          @update="period = $event"
          @change="fetchDataTable"
        />
        <filter-reports
          class="ui-g-md ui-mobile-12"
          :value="filters"
          @input="updateFilters"
          :from="period.from"
          :to="period.to"
          :key="countFilter"
          :use-filters="useFilters"
          @changeSAid="v => mediaSAid = v"
        />
      </template>

      <div
        class="ui-d-flex ui-mobile-hide"
        slot="actionsPrepend"
      >
        <export-report
          class="ui-g-md"
          :export-links="exportUrls"
        ></export-report>

        <div class="ui-g-md">
          <ui-button
            icon="eye"
            :icon-size="9"
            @click="openColumnsPopup"
          />
        </div>

        <div class="ui-g-md">
          <ui-button
            icon="refresh"
            @click="fetchDataTable"
          />
        </div>
      </div>

      <template slot="headerPeriodPickerActions">
        <export-report
          :class="[
            'ui-tablet-hide',
            'ui-desktop-hide',
            'ui-g-md'
          ]"
          :export-links="exportUrls"
        ></export-report>

        <div
          :class="[
            'ui-tablet-hide',
            'ui-desktop-hide',
            'ui-g-md'
          ]"
        >
          <ui-button
            icon="refresh"
            @click="fetchDataTable"
          />
        </div>
      </template>
    </table-card>

    <ui-columns-select
      ref="columnsPopup"
      v-model="selectedColumns"
      :groups="columns_groups"
      :default="default_cols"
      :supported="supportedMetrics"
      :metrics="getMetrics"
      @changed="fetchDataTable"
    ></ui-columns-select>
  </section>
</template>

<script>
import { Select, Option } from 'element-ui';
import Mobtable from 'src/components/Mobiletable/Mobtable';
import Mobpagination from 'src/components/Mobiletable/MobPagination';
import PeriodWrapper from 'src/views/Dashboard/Reports/Filters/PeriodWrapper.vue';
import FilterReports from 'src/components/Filter-reports/Filter-reports.vue';
import SortBy from 'src/components/SortBy.vue';
import VSelectMenu from 'src/components/VSelectMenu.vue';
import Formaters from 'src/views/Dashboard/CustomReports/formaters';
import InjectPlugins from 'src/components/Report/Components/inject-plugins';
import DimensionsMixin from 'src/components/Report/Components/DimensionsMixin';
import ColumnsMixin from 'src/views/Dashboard/Reports/columns-mixin';
import UrlMixin from 'src/components/Query/url-mixin';
import UrlMixinHooks from 'src/components/Query/url-mixin-hooks';
import { pageSizeMixin, resolvePageSize } from 'src/views/Dashboard/Reports/page_size.js';
import TableCard from 'src/components/Cards/TableCard.vue';
import TableCardSearchHeader from 'src/components/Cards/TableCardSearchHeader.vue';
import HelpersMixin from 'src/components/Helpers/helpers-mixin.js';
import ExportReport from 'src/views/Dashboard/Reports/ExportReport.vue';
import tableFlowMixinGenerator from 'src/views/Dashboard/Reports/tableFlowMixin.js';
import formatCurrency from '@/mixins/format-currency';

const sizes = {
  1080: 20,
  1440: 30,
};
const viewName = 'reports/daily';
const pageSize = resolvePageSize(viewName, {
  _default: 15,
  sizes,
});
let $tapReady = null;
const $readyPromise = new Promise((resolve) => {
  $tapReady = resolve;
});
const OMIT = ['impressions_uniq_count', 'visits_uniq_count'];

const defaultParams = {
  page: 1,
  pageSize,
  sort_dir: 'desc',
  sort_by: 'date_formatted',
};

const fetch = async (ctx) => {
  const settings = () => ctx.$store.dispatch('getSettings');
  const subAffiliates = () => ctx.$store.dispatch('getSubAffiliates', {
    limit: 10000,
    offset: 0,
  });

  await Promise.all([settings(), subAffiliates()]);

  return [];
};

const tableFlowMixin = tableFlowMixinGenerator({
  defaultParams: {
    ...defaultParams,
  },
  params: Object.keys(defaultParams),
  period: 'param',
  windowRefreshHook: true,
  fetchOnActivated: false,
  export: true,
});

export default {
  fetch,
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    PeriodWrapper,
    VSelectMenu,
    Mobtable,
    Mobpagination,
    FilterReports,
    SortBy,
    TableCard,
    TableCardSearchHeader,
    ExportReport,
  },
  mixins: [
    InjectPlugins,
    ColumnsMixin,
    DimensionsMixin,
    UrlMixin,
    UrlMixinHooks,
    HelpersMixin,
    pageSizeMixin,
    tableFlowMixin,
    formatCurrency,
  ],
  props: ['passedParams'],
  data() {
    return {
      viewName,
      default_cols: [],
      hiddenFields: [],
      sub_affiliate_id: null,
      group_by: 'date_formatted',
      oldpage: 1,
      count: 0,
      passedPeriod: {
        period: 30,
      },
      columnsFilter: {},
      data: [],
      loading: false,
      pageSize: 20,
      query: null,
      totals: {},
      formaters: Formaters,
      filters: null,
      columns_groups: [],
      useFilters: [
        'traffic_source_id', 'country_code', 'player_id', 'affiliate_offer_id', 'subid1', 'subid2', 'subid3', 'subid4',
        'subid5',
      ],
      selectedColumns: [],
      mediaSAid: false,
      countFilter: 0,
      exportUrls: {},
      screenWidth: '',
      render: 0,
    };
  },
  computed: {
    cacheData() {
      this.oldpage = this.query.oldpage ? this.query.oldpage : 1;
      this.period.oldfrom = this.query.oldfrom ? this.query.oldfrom : '';
      this.period.oldto = this.query.oldto ? this.query.oldto : '';
      return {
        query: this.query,
      };
    },
    getTableFields() {
      const metrics = (this.$store.state.settings.metrics || []).map((e) => {
        this.allMetrics.map((i) => { if (i.name === e.column_name) e = { ...this.formatMetric(e), ...i }; });
        return this.formatMetric(e);
      });
      const fields = this.selectedColumns.map(e => metrics.find(f => f.column_name === e)) || [];

      fields.forEach((item) => {
        item.sortable = 'custom';
        item.format = false;
      });

      if (this.group_by === 'date_formatted') {
        const _self = this;

        this.groupByField.component = (row, name) => {
          const {
            extendUrlQuery, setFromQuery, query, pushQuery, $route, refresh,
          } = this;

          return this.$createElement('a', {
            attrs: {
              href: `/#/dashboard/daily/${extendUrlQuery({
                query: {
                  ...query,
                  group_by: 'hour_formatted',
                  sort_by: 'hour_formatted',
                  from: `${row.row_id} 00:00:00`,
                  to: `${row.row_id} 23:59:59`,
                  oldfrom: _self.period.from,
                  oldto: _self.period.to,
                  oldpage: _self.page,
                  page: 1,
                },
              })}`,
            },
            on: {
              click() {
                pushQuery($route, {
                  query: {
                    ...query,
                    group_by: 'hour_formatted',
                    sort_by: _self.sorting.sort_by !== 'date_formatted' ? _self.sorting.sort_by : 'hour_formatted',
                    from: `${row.row_id} 00:00:00`,
                    to: `${row.row_id} 23:59:59`,
                    oldfrom: _self.period.from,
                    oldto: _self.period.to,
                    oldpage: _self.page,
                    page: 1,
                  },
                });

                _self.period = {
                  from: `${row.row_id} 00:00:00`,
                  to: `${row.row_id} 23:59:59`,
                  oldfrom: `${row.row_id} 00:00:00`,
                  oldto: `${row.row_id} 23:59:59`,
                  period: _self.period.period,
                };
                setFromQuery();
                refresh();
              },
            },
          }, name);
        };
      }

      fields.unshift({
        ...this.groupByField,
        sortable: 'custom',
      });
      fields[0].minWidth = 120; // костыль
      this.render++;

      return fields;
    },
    getMetrics() {
      return (this.$store.state.settings.metrics || []).map(el =>  this.formatMetric(el));
    },
    groupByField() {
      const result = (this.$store.state.settings.dimensions || []).find(field => field.column_name === this.group_by);

      return {
        ...result,
      };
    },
    target() {
      return this;
    },
  },
  watch: {
    '$i18n.locale': async function () {
      if (this.isComponentActivated) {
        const promise = Promise.all([
          this.$store.dispatch('getSettings'),
          this.resolveMetrics(),
          this.getReport(),
        ]);

        this.loading = true;
        await promise;
        this.loading = false;
      }
    },
    '$store.state.profile.user.reportSettings': {
      deep: true,
      handler(value) {
        this.resolveMetrics();
      },
    },
  },
  async created() {
    this.setFromQuery(this.passedParams);
    this.$store.dispatch('getSettingsLazy');
    this.resolveMetrics();
  },
  async mounted() {
    this.loading = true;

    this.selectedColumns = await this.resolveMetrics();

    await this.getReport();
    await this.afterBrowserRedraw();

    this.isTableReady = true;
    this.loading = false;

    this.setFromQuery(this.passedParams);
  },
  async activated() {
    this.$store.dispatch('getSettingsLazy');
    this.resolveMetrics();
    this.setFromQuery(this.query);
    this.setQuery();

    await this.fetchData(this.lastQuery);
    await this.afterBrowserRedraw();
    this.isTableReady = true;
  },
  deactivated() {
    this.loading = true;
  },
  methods: {
    updateFilters(value) {
      this.filters = value;
      this.fetchDataTable();
    },
    async resolveMetrics() {
      const {
        dimensions, grouped_metrics, metrics, metrics_visible_by_default,
      } = this.$store.state.settings;

      // перенести в стор(для модалки с колонками)
      this.columns_groups = grouped_metrics.map((e) => {
        e.items.map((i) => {
          if (i.items && !i.items.length) delete i.items;
          return i;
        });
        return e;
      });

      this.supportedMetrics = metrics.map(e => e.name);
      this.default_cols = metrics_visible_by_default;

      return this.default_cols;
    },
    setFromQuery(params) {
      let { query = {} } = params || this.urlQuery;

      if (this.urlQuery && this.urlQuery.subAffiliateActive) query = this.urlQuery.query;

      query = Object.keys(query).length == 0 ? { ...params } : query;
      this.query = query;
      this.sub_affiliate_id = query.sub_affiliate_id || '';

      if (query.sub_affiliate_id) {
        this.filters = [{ sub_affiliate_id: this.sub_affiliate_id }];
      }

      if (query.page) {
        this.page = query.page;
      }

      if (query.limit) {
        this.limit = query.limit;
      }

      if (query.group_by) {
        this.group_by = query.group_by;
        if (query.sorting) {
          this.sort_by = query.sorting[0].sort_by;
          this.sort_dir = query.sorting[0].sort_dir;
        } else if (query.sort_by) {
          this.sort_by = query.sort_by;
        }
      }

      if (query.metrics) {
        this.selectedColumns = query.metrics;
      }

      if (query.having) {
        this.columnsFilter = query.having;
      }

      if (query.passedPeriod) {
        this.setPeriod(query.passedPeriod);
      } else if (query.period) {
        this.setPeriod({
          from: query.from,
          to: query.to,
          period: query.period,
        });
      }

      this.filters = this.filters || [];

      Object.keys(query)
        .map((type) => {
          if (this.useFilters.indexOf(type) === -1) {
            return;
          }

          if (this.filters.find(e => e.type === type) && query[type] === this.filters.find(e => e.type === type).values) {

          } else {
            (this.filters || []).push({
              type,
              values: query[type],
            });
          }
        });
      this.cacheQuery();
    },
    getDataQuery() {
      let query = { ...this.period };

      const columnsFilter = {};
      this.$_.forOwn(this.columnsFilter, (e, key) => {
        columnsFilter[key] = this.$_.pick(e, ['op', 'value']);
      });
      // check column filter
      Object.keys(columnsFilter)
        .forEach((e) => {
          let flagDel = false;
          this.selectedColumns.forEach((el) => {
            e === el ? flagDel = true : '';
          });
          !flagDel ? delete columnsFilter[e] : '';
        });
      const sort_by = this.sort_by && this.selectedColumns.includes(this.sort_by) ? this.sort_by : this.group_by;

      let params = {
        dimensions: [this.group_by],
        sorting: [{
          sort_by,
          sort_dir: this.sort_dir,
        }],
        metrics: this.selectedColumns,
        limit: this.limit,
        offset: this.limit * this.page - this.limit,
        from: this.group_by === 'hour_formatted' ? this.query.from || this.query.oldfrom : this.period.from,
        to: this.group_by === 'hour_formatted' ? this.query.to || this.query.oldto : this.period.to,
        having: columnsFilter,
        metrics_format: 'pretty',
        filters: this.filters.filter(e => e.values && e.values !== '')
          .map(e => e = { [e.type]: { op: '=', value: e.values } }),
      };

      this.filters.forEach((e) => {
        if (e.sub_affiliate_id) {
          params.filters.push({ affiliate_id: { op: '=', value: e.sub_affiliate_id } });
        }
      });

      params = {
        ...params,
        filters: params.filters.reduce((accumulator, currentValue) => {
          const key = Object.keys(currentValue)[0];
          accumulator[key] = currentValue[key];
          return accumulator;
        }, {}),
      };

      (this.filters || []).map((filter) => {
        if (filter.sub_affiliate_id !== void 0) {
          if (filter.sub_affiliate_id !== 0) {
            query.sub_affiliate_id = filter.sub_affiliate_id;
          }
        } else {
          query = {
            sub_affiliate_id: null,
            ...query,
            [filter.type]: filter.values,
          };
        }
      });

      this.query = {
        ...params,
        ...query,
        group_by: this.group_by,
        page: this.page,
        oldpage: this.oldpage,
      };
      this.sub_affiliate_id = query.sub_affiliate_id;
      // this.cacheQuery();

      return params;
    },
    async fetchData() {
      this.loading = true;

      await this.getReport();
      this.loading = false;
    },
    async getReport() {
      this.loadingError = false;

      const params = this.getDataQuery();
      this.cacheQuery();

      if (!params.metrics) {
        return;
      }

      try {
        return this.$api
          .postReport(params)
          .then((res) => {
            this.count = res.misc.count;
            this.exportUrls = res.misc.export_urls;
            this.totals = res.payload.totals;
            this.currencyCode = res.misc.currency_code;
            this.data = res.payload.data.map((e) => {
              e.data = {
                id: e.row_id,
              };

              delete e.row_filter_column;
              Object.keys(e)
                .forEach((k) => {
                  if (e[k] === null) e[k] = 0; // TEMPFIX
                });
              return e;
            });
            delete params.having;
            this.afterBrowserRedraw(() => {
              this.loading = false;
            });
          })
          .catch(() => {
            this.loading = false;
            this.data = [];
            delete params.having;
          });
      } catch (e) {
        console.error(e);
      }
    },
    back() {
      const { $route, pushQuery, query } = this;
      pushQuery($route, {
        query: {
          ...query,
          group_by: 'date_formatted',
          sort_by: query.sort_by !== 'hour_formatted' ? query.sort_by : 'date_formatted',
          from: this.period.oldfrom,
          to: this.period.oldto,
          page: this.oldpage,
        },
      });
      this.setFromQuery();
      this.$nextTick(() => {
        this.oldpage = 1;
        this.refresh();
      });
    },
    handleSelectColumns(columns) {
      if (columns.indexOf(this.sort_by) === -1) {
        this.sort_by = this.group_by;
      }
    },
    groupedExpand() {
      this.multiSelectBlock = !this.multiSelectBlock;
    },
    getCurrencyClass(value) {
      return value < 0 ? 'negative' : '';
    },
    openColumnsPopup() {
      this.$refs.columnsPopup.open();
    },
    setQuery(query) {
      this.query = this.getDataQuery(query);
      this.loading = true;
      return this.$store.dispatch('getSubAffiliatesLazy', {});
    },
    dependsHack() {
      return this.getTableFields;
    },
  },
};
</script>

<style lang="scss">
@import 'src/assets/theme/default/reports/players';
</style>
