<template>
  <div class="col-md-4 ml-auto mr-auto login-wrapper reset__main">
    <div class="login-logo">
      <img :src="logo" alt="logo">
    </div>

    <form :class="{ 'loading':loading, 'error':error }" @submit.prevent>
      <card class="card-login ">
        <div class="loading__block">
          <i class="fa fa-spinner fa-3x fa-fw login__spinner" />
        </div>
        <section class="inputs">
          <h3>{{ $t('forms.resetPassword') }}</h3>
          <div class="row">
            <div class="col-md-9 col-12">
              {{ $t('forms.resetText') }}
            </div>
          </div>
          <br>
          <div>
            <input
              v-model="model.email"
              :class="{'error': emailValidation && !valid_email }"
              :placeholder="$t('forms.emailPlaceholder')"
              :disabled="loading"
              type="hidden"
              class="login-input"
              autofocus
              @focus="emailValidation = false; passwordValidation = false"
            >
          </div>
          <div>
            <h6 class="input-title">
              {{ $t('forms.newPasswordLabel') }}
            </h6>
            <div class="text-right" style="height:0px;">
              <i
                :class="{'fa-eye':!passwordShown, 'fa-eye-slash': passwordShown}"
                class="fas fa-eye text-muted"
                @click="showPassword"
              />
            </div>
            <input
              v-model="model.new_password"
              :type="passwordShown && 'text' || 'password'"
              :class="{'error': passwordValidation && !valid_password }"
              :disabled="loading"
              placeholder="***********"
              class="login-input"
              @focus="emailValidation = true; passwordValidation = false"
              @blur="passwordValidation = true"
              @keyup.enter="valid_password && valid_email && login"
            >
            <div v-if="passwordValidation && !valid_password" class="text-danger text-right">
              {{ $t('forms.validation.password') }}
            </div>
          </div>
          <div>
            <h6 class="input-title">
              {{ $t('forms.repeat_password') }}
            </h6>
            <div class="text-right" style="height:0px;">
              <i
                :class="{'fa-eye':!passwordShown, 'fa-eye-slash': passwordShown}"
                class="fas fa-eye text-muted"
                @click="showPassword"
              />
            </div>
          </div>
          <input
            v-model="repeat_password"
            :type="passwordShown && 'text' || 'password'"
            :class="{'error': passwordValidation && !valid_password }"
            :disabled="loading"
            placeholder="***********"
            class="login-input"
            @focus="emailValidation = true; passwordValidation = false"
            @blur="passwordValidation = true"
            @keyup.enter="valid_password && valid_email && login"
          >
          <div v-if="passwordValidation && repeat_password !== model.new_password" class="text-danger text-right">
            {{ $t('forms.passwordsNotMatch') }}
          </div>
          <div class="btn-box d-flex justify-content-between">
            <button class="btn btn-block btn-outline-primary big btn-md" @click="$router.push('/login')">
              &lt; {{ $t('forms.btn.back') }}
            </button>
            <button
              :disabled="loading || !valid_password || repeat_password !== model.new_password"
              class="btn btn-block btn-primary big btn-md"
              @click="resetPassword"
            >
              {{ $t('forms.btn.submit') }} &gt;
            </button>
          </div>
        </section>
      </card>
    </form>
  </div>
</template>

<script>
import { required, email, minLength } from 'vuelidate/lib/validators';

export default {
  data() {
    return {
      passwordShown: false,
      emailValidation: false,
      passwordValidation: false,
      repeat_password: '',
      loading: false,
      model: {
        email: this.$route.params.email,
        token: this.$route.params.id,
        new_password: '',
      },
    };
  },
  computed: {
    valid_email() {
      const _email = this.$v.model.email;
      return _email.email && _email.required;
    },
    valid_password() {
      const password = this.$v.model.new_password;
      return password.minLength && password.required;
    },

    error() {
      return this.$store.state.errors.request >= 400;
    },
    logo() {
      return this.$config.layout.logoFull;
    },
  },
  methods: {
    showPassword() {
      this.passwordShown = !this.passwordShown;
    },
    resetPassword() {
      this.loading = true;
      this.$request
        .post('auth/reset-password', this.model)
        .then(() => {
          this.$router.push('/login');
        })
        .catch((err) => {
          if (err.response.data.errors && err.response.data.errors.token) {
            this.$alert.error(err.response.data.errors.token[0].message);
          }
          this.loading = false;
        });
    },
  },
  validations: {
    model: {
      new_password: {
        required,
        minLength: minLength(8),
      },
      email: {
        required,
        email,
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'styles/resetpassword';
</style>
