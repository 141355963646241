<template>
  <section>
    <notifications/>
    <confirmation-dialogues ref="confirms"/>
    <important-notification />
    <global-alerts ref="alerts"/>
    <router-view :key="$store.state.initTime"/>
    <section v-show="is_flood">
      <div
        class="modal fade show d-block"
        tabindex="-1"
        role="dialog"
      >
        <div class="flood-dected">
          <i class="far fa-times-circle"/>
          <h3>Flood Detected</h3>
          <p>Please retry later</p>
          <p v-if="flood_counter" class="counter">
            {{ flood_counter }} seconds..
          </p>
        </div>
      </div>
    </section>
  </section>
</template>

<script>
import eventBus from 'src/lib/eventBus';
import config from './config';
import ImportantNotification from '@/views/Notifications/ImportantNotification.vue';

export default {
  name: 'App',
  components: { ImportantNotification },
  data() {
    return {
      is_flood: false,
      flood_counter: 0,
      flood_next_retry: new Date(),
    };
  },
  watch: {
    '$i18n.locale': function () {
      this.headersLocale();
    },
    $route(to, from) {
      document.title = `${this.$t(to.name)} - ${this.$config.pageTitle}`;
    },
  },
  mounted() {
    document.title = `${this.$t(this.$route.name)} - ${this.$config.pageTitle}`;
    document.body.setAttribute('data-route', this.$router.currentRoute.name);
  },
  updated() {
    document.body.setAttribute('data-route', this.$router.currentRoute.name);
  },
  created() {
    document.body.setAttribute('data-theme', this.$config.theme);
    if (localStorage.getItem('language') === null) {
      const langs = Object.keys(this.$i18n.messages);
      const locLang = window.navigator.language.substr(0, 2).toLowerCase();
      this.$locale = langs.includes(locLang) ? locLang : 'en';
      localStorage.setItem('language', this.$locale);
    } else {
      this.$locale = localStorage.getItem('language');
    }
    this.headersLocale();
    document.body.setAttribute('data-language', this.$locale);
    moment.locale(this.$locale);

    eventBus.$on('flood-warning', (data) => {
      this.is_flood = true;
      this.flood_next_retry = data.payload.seconds_to_wait_before_next_retry;
      window.flood_retry_int = setInterval(() => {
        this.flood_next_retry -= 1;
        this.flood_counter = this.flood_next_retry; // TODO: formating
        if (this.flood_counter <= 0) {
          this.is_flood = false;
          clearInterval(window.flood_retry_int);
        }
      }, 1000);
    });
  },
  methods: {
    headersLocale() {
      document.body.setAttribute('data-language', this.$locale);
      localStorage.setItem('language', this.$locale);
      this.$api.affiliates.defaults.headers.common['X-Selected-Locale'] = this.$locale || 'en';
      this.$api.misc.defaults.headers.common['X-Selected-Locale'] = this.$locale || 'en';
      this.$api.affiliates.defaults.headers.common['X-Customer-Id'] = config.customerId;
      this.$api.misc.defaults.headers.common['X-Customer-Id'] = config.customerId;
      document.title = `${this.$t(this.$route.name)} - ${this.$config.pageTitle}`;
      if (this.$moment) {
        this.$moment.locale(this.$locale);
      }

      setTimeout(() => {
        moment.locale(this.$locale);
      }, 300);
    },
  },
};
</script>

<style lang="scss">


.flood-dected {
  margin-top: 45px;
  position: fixed;
  left: calc(50% - 180px);
  top: calc(50% - 180px);
  width: 360px;
  height: 260px;
  border-radius: 8px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.09);
  background-color: #ffffff;
  padding: 36px;

  i {
    margin-left: -6px;
    margin-bottom: 20px;
    font-size: 48px !important;
  }

  h3 {
    margin-bottom: 6px !important;
    font-size: 30px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: 1.53;
  }

  .counter {
    position: absolute;
    bottom: 26px;
    font-size: 16px;
    font-weight: 600;
  }
}

@import 'src/assets/theme/default/common/index';
</style>
