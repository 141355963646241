<template>
  <section class="postback">
    <table-card
      ref="datatable"
      :loading="loading"
      :columns="columns"
      :actions="actions"
      :page.sync="page"
      :page-size.sync="pageSize"
      :sort.sync="sorting"
      :sort-by-name="sortByName"
      :sort-dir-name="sortDirName"
      :rows="items"
      :count="$store.state.trafficSource.totalPostbacksRows"
      i18n-path="reports"
      dont-fill-empty
      extend-height
      @fetch-data="fetchDataTable"
    >
      <div
        slot="header"
        class="ui-align-center"
      >
        <div class="ui-g-md">
          <button
            class="btn btn-outline-primary btn-sm text-nowrap"
            @click="back"
          >
            &lt; {{ $t('profile.buttons.back') }}
          </button>
        </div>

        <span
          :class="[
            'table-card__header-title',
            'ui-g-md',
            'ui-m-md-l'
          ]"
        >{{ $store.state.trafficSource.item.name }} {{ $t('postbacks.card.title') }}</span>
      </div>

      <div
        slot="actionsPrepend"
        class="ui-g-md ui-mobile-12"
      >
        <tooltip
          class="tooltip-btn-disabled"
          :title="$t('tooltip_limits.postbacks')"
          placement="bottom-left"
          :disabled="!addDisabled"
        >
          <button
            :class="[
              'btn',
              'btn-primary',
              'btn-sm',
              'text-nowrap',
              'ui-mobile-12'
            ]"
            :disabled="addDisabled"
            @click="addPostback"
          >
            {{ $t('postbacks.card.btnAdd') }}
          </button>
        </tooltip>
      </div>
    </table-card>

    <edit-postback
      ref="editPostback"
      :offset-params="queryParams"
    />
  </section>
</template>

<script>
import Mobtable from 'src/components/Mobiletable/Mobtable';
import Mobpagination from 'src/components/Mobiletable/MobPagination';
import InjectPlugins from 'src/components/Report/Components/inject-plugins';
import { setTimeout } from 'timers';
import { pageSizeMixin, resolvePageSize } from 'src/views/Dashboard/Reports/page_size.js';
import UrlMixin from 'src/components/Query/url-mixin';
import UrlMixinHooks from 'src/components/Query/url-mixin-hooks';
import TableCard from 'src/components/Cards/TableCard.vue';
import tableFlowMixinGenerator from 'src/views/Dashboard/Reports/tableFlowMixin.js';
import Tooltip from 'src/components/Tooltip.vue';
import EditPostback from './EditPostback';

const sizes = {
  1080: 20,
  1440: 30,
};

const viewName = '/postbacks';
const pageLimitProp = 'pageSize';
const pageSize = resolvePageSize(viewName, {
  _default: 15,
  sizes,
});

const defaultParams = {
  page: 1,
  pageSize,
  sort_column: 'created_at',
  sort_dir: 'desc',
};

const fetch = async (ctx, params) => {
  if (typeof params === 'string') {
    params = {
      traffic_source_id: params,
      ...defaultParams,
    };
  } if (!params || ctx.$_.isEmpty(params)) {
    params = {
      ...defaultParams,
    };
  }

  const postbacks = () => ctx.$store.dispatch('getPostbacks', {
    sort_column: params.sort_column || defaultParams.sort_column,
    sort_dir: params.sort_dir || defaultParams.sort_dir,
    traffic_source_id: ctx.$route.params.id,
    limit: pageSize || ctx.pageSize,
    ...params,
  });
  const trafficSource = () => ctx.$store.dispatch('getTrafficSource', ctx.$route.params.id);
  const count = () => ctx.$store.dispatch('getTrafficSourcePostbacksCount', {
    traffic_source_id: ctx.$route.params.id,
  });

  return await Promise.all([postbacks(), trafficSource(), count()]);
};

const tableFlowMixin = tableFlowMixinGenerator({
  defaultParams: {
    ...defaultParams,
  },
  params: Object.keys(defaultParams),
  fetch,
  windowRefreshHook: true,
  sortByName: 'sort_column',
  sortDirName: 'sort_dir',
});

export default {
  mixins: [
    UrlMixinHooks,
    UrlMixin,
    InjectPlugins,
    pageSizeMixin,
    tableFlowMixin,
  ],
  fetch,
  components: {
    EditPostback,
    Mobtable,
    Mobpagination,
    TableCard,
    Tooltip,
  },
  props: ['fetchedData', 'passedParams'],
  data() {
    return {
      viewName,
      pageLimitProp,
      loading: false,
      columns: [
        {
          name: 'created_at',
          align: 'left',
          sortable: 'custom',
          filterable: false,
          width: 155,
          format: 'date-time',
          source: 'i18n',
          i18nPath: 'reports',
        }, {
          name: 'offer',
          align: 'left',
          filterable: false,
          width: 150,
        }, {
          name: 'url',
          align: 'center',
          filterable: false,
        },
      ],
      actions: {
        width: 100,
        name: () => this.$t('profile.apiKeys.table.actions'),
        operations: [
          {
            name: 'edit',
            component: (row, ctx) => {
              const { editPostback } = this;
              const span = ctx.$createElement('span', {
                on: {
                  click(e) {
                    e.cancelBubble = true;
                    editPostback(row);
                  },
                },
              }, [
                ctx.$createElement('i', {
                  attrs: {
                    class: 'far fa-pen text-green',
                  },
                }),
              ]);
              return ctx.$createElement('el-tooltip', {
                attrs: {
                  class: 'el-tooltip icon__wrapper text-green',
                  placement: 'top',
                  content: this.$t('postbacks.card.edit'),
                  'open-delay': 500,
                },
              }, [span]);
            },
          }, {
            name: 'delete',
            component: (row, ctx) => {
              const { deletePostback } = this;
              const span = ctx.$createElement('span', {
                on: {
                  click(e) {
                    e.cancelBubble = true;
                  },
                },
              }, [
                ctx.$createElement('i', {
                  attrs: {
                    class: 'far fa-trash-alt text-red',
                  },
                }),
              ]);
              const elTooltip = ctx.$createElement('el-tooltip', {
                attrs: {
                  class: 'el-tooltip icon__wrapper text-red',
                  placement: 'top',
                  content: this.$t('promoCodes.promocodes.delete'),
                  'open-delay': 500,
                },
              }, [span]);
              return ctx.$createElement('ac-popover', {
                attrs: {
                  width: 250,
                  message: `${this.$t('topNavbar.wantDelete')}?`,
                  buttons: [
                    {
                      text: this.$t('mediaBuying.channels.cancel'),
                    }, {
                      text: this.$t('promoCodes.promocodes.delete'),
                      type: 'danger',
                      click: () => deletePostback(row),
                    },
                  ],
                },
              }, [elTooltip]);
            },
          }, {
            name: 'request log',
            component: (row, ctx) => {
              const { openRequestLog } = this;
              const span = ctx.$createElement('a', {
                on: {
                  click(e) {
                    e.cancelBubble = true;
                    openRequestLog(row);
                  },
                },
                attrs: {
                  href: `#/dashboard/traffic-source/log/${row.data.id}/request-log?traffic_source_id=${row.data.traffic_source_id}&affiliate_offer_id=${row.data.affiliate_offer_id}`,
                },
              }, [
                ctx.$createElement('i', {
                  attrs: {
                    class: 'fas fa-book-open text-info',
                  },
                }),
              ]);
              return ctx.$createElement('el-tooltip', {
                attrs: {
                  class: 'el-tooltip icon__wrapper text-green',
                  placement: 'top',
                  content: this.$t('postbacks.card.requestLog'),
                  'open-delay': 500,
                },
              }, [span]);
            },
          },
        ],
      },
      lastQuery: {},
    };
  },
  computed: {
    items() {
      return (this.$store.state.trafficSource.postbacks || []).map((e) => {
        const offer = this.$store.state.offers.my.filter(x => x.id === e.affiliate_offer_id)[0];
        return {
          offer: (offer && (offer.name || offer.id)) || this.$t('trafficSources.postbacks.notSpecified'),
          req: e.request_type,
          status: e.status || this.$t('trafficSources.postbacks.notSpecified'),
          url: e.url,
          data: e,
          created_at: e.created_at,
        };
      });
    },
    addDisabled() {
      const totalPostbacksRows = this.$store.state.trafficSource.totalPostbacksRows;
      const maxPostbacksPerTrafficSource = (this.$store.state.settings.limitations && this.$store.state.settings.limitations.max_postbacks_per_traffic_source) || 0;

      return totalPostbacksRows >= maxPostbacksPerTrafficSource;
    },
  },
  watch: {
    '$i18n.locale': function () {
      if (this.isComponentActivated) {
        this.$store.dispatch('getSettings');
      }
    },
  },
  methods: {
    back() {
      if (localStorage.getItem('getBackUrlTrafficSource') === null) {
        this.$router.push('/dashboard/traffic-source');
      } else {
        this.$router.push(JSON.parse(localStorage.getItem('getBackUrlTrafficSource')));
      }
    },
    addPostback() {
      if (!this.addDisabled) {
        this.$refs.editPostback.open();
      }
    },
    editPostback(row) {
      this.$refs.editPostback.open(row.data.id);
    },
    deletePostback(row) {
      this.$store.dispatch('deletePostback', row.data.id)
        .then(() => {
          if (this.items.length === 1 && this.page > 1) {
            this.page = this.page - 1;
          }

          this.fetchDataTable();
          // this.$store.dispatch('getTrafficSourcePostbacksCount', { traffic_source_id: this.$route.params.id, ...this.offsetParams });
          // this.$store.dispatch('getPostbacksLazy', { traffic_source_id: this.$route.params.id, ...this.offsetParams });
          this.$alert.success(this.$t('postbacks.card.postbackDeleted'));
        })
        .catch(() => {
          this.$alert.success(this.$t('postbacks.card.notDeletePostback'));
        });
    },
    openRequestLog(row) {
      localStorage.setItem('getBackUrlPostbacks', JSON.stringify(this.$route.path));
      this.$router.push({
        path: `/dashboard/traffic-source/log/${row.data.id}/request-log`,
        query: {
          traffic_source_id: row.data.traffic_source_id,
          affiliate_offer_id: row.data.affiliate_offer_id,
        },
      });
    },
    clearTooltip() {
      document.addEventListener('mouseup', this.listener = () => {
        setTimeout(() => {
          const tooltip = document.querySelectorAll('.el-tooltip__popper');
          tooltip.forEach((e, i) => {
            tooltip[i].style.display = 'none';
          });
        }, 500);
      });
    },
  },
  created() {
    this.actions.operations.map((e) => {
      if (e.component) e.component = e.component.bind(this);
    });
    this.setFromQuery(this.passedParams);
    this.$store.dispatch('getAffiliateOffersLazy', {});
  },
  activated() {
    this.$store.dispatch('getAffiliateOffersLazy', {});
    this.clearTooltip();
  },
  beforeMount() {
    this.pageSize = this.limit;
  },
  mounted() {
    this.clearTooltip();
  },
};
</script>

<style lang="scss">
@import 'src/assets/theme/default/trafficsources/postbacks';
</style>
