<template>
  <section class="contacts-view">
    <div class="card contacts__wrap">
      <h3 class="contacts__title mb-0">
        {{ $t('contacts.contactsTitle') }}
      </h3>
      <hr>
      <div class="row">
        <div class="col-lg-12 col-sm-12">
          <form>
            <form-wrap :disabled="disabled">
              <div class="form-group">
                <label for="exampleInputEmail1">{{ $t('contacts.subject') }}</label>
                <input
                  id="exampleInputEmail1"
                  v-model="subject"
                  :placeholder="$t('contacts.title')"
                  autocomplete="off"
                  type="text"
                  class="form-control"
                  autocomlete="off"
                  @click="changed = false"
                >
              </div>
              <div class="form-group">
                <label
                  for="exampleFormControlInput1"
                  class="mes__title"
                >{{ $t('contacts.mesTitle') }}</label>
                <textarea
                  id="exampleFormControlInput1"
                  v-model="message"
                  :placeholder="$t('contacts.feedback')"
                  class="form-control"
                  rows="10"
                  @click="changed = false"
                />
                <div
                  v-if="invalid_message"
                  class="text-danger text-right"
                >
                  {{ $t('contacts.invalidMessage') }}
                </div>
              </div>
            </form-wrap>
          </form>
        </div>
      </div>

      <hr class="mt-8">

      <div
        :class="{ loading: sending }"
        class="modal__footer-btn"
      >
        <button
          type="submit"
          class="btn btn-primary btn-sm modal__btn-confirm confirm mb-0 mt-0"
          @click="send"
        >
          <span>{{ $t('contacts.btnTitle') }}</span>
          <i
            v-if="sending"
            class="fas fa-spinner spinner-hide"
          />
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import FormWrap from 'src/components/ModalForm/FormWrap';

export default {
  components: {
    FormWrap,
  },
  data() {
    return {
      subject: '',
      message: '',
      changed: false,
      sending: false,
      disabled: false,
    };
  },
  computed: {
    invalid_message() {
      return this.changed && !this.message.trim();
    },
  },
  methods: {
    send() {
      this.changed = true;
      if (this.invalid_message) return;
      this.sending = true;
      this.disabled = true;

      this.$api
        .sendMessage({ message: this.message })
        .then((res) => {
          this.sending = false;
          this.disabled = false;
          this.changed = false;
          this.message = '';
          this.subject = '';

          this.$alert.success(this.$t('contacts.messageSend'));
          this.$eventBus.$emit('sendMessage');

          setTimeout(() => {
            this.$router.push('/dashboard/profile');
          }, 500);
        })
        .catch((err) => {
          this.$alert.warning(this.$t('contacts.messageNotSend'));
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@import 'Contacts/styles/contacts';
</style>
