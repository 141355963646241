import fgInput from 'src/components/Inputs/formGroupInput.vue';
import DropDown from 'src/components/Dropdown.vue';
import Card from 'src/components/Cards/Card.vue';
import {
  Input, InputNumber, Tooltip, Popover,
} from 'element-ui';
import Select2 from 'v-select2-component';
import ProgressBar from 'src/components/ProgressBar/plugin';
import Datepicker from 'vue-flatpickr-component';
import { VueRecaptcha } from 'vue-recaptcha';

const GlobalComponents = {
  install(Vue) {
    Vue.component('fg-input', fgInput);
    Vue.component('drop-down', DropDown);
    Vue.component('card', Card);
    Vue.component(Input.name, Input);
    Vue.component(InputNumber.name, InputNumber);
    Vue.component('v-select', Select2);
    Vue.component('vue-recaptcha', VueRecaptcha);
    Vue.component('datepicker', Datepicker);
    Vue.use(Tooltip);
    Vue.use(Popover);
    Vue.use(ProgressBar);
  },
};

export default GlobalComponents;
