export default (mock) => {
  mock.onGet('sub-affiliates').reply(200, {
    success: true,
    payload: [
      {
        id: '111111',
        email: 'test@gmail.com',
        first_name: 'string',
        last_name: 'string',
        account_status: 'pending',
        referral_percent: 0,
        created_at: '2018-07-25T08:23:43.539Z',
      },
      {
        id: '22222',
        email: 'best@gmail.com',
        first_name: 'string',
        last_name: 'string',
        account_status: 'pending',
        referral_percent: 1,
        created_at: '2018-07-25T08:23:43.539Z',
      },
      {
        id: '333333',
        email: 'rest@gmail.com',
        first_name: 'string',
        last_name: 'string',
        account_status: 'pending',
        referral_percent: 2,
        created_at: '2018-07-25T08:23:43.539Z',
      },
      {
        id: '111111',
        email: 'ggggg@gmail.com',
        first_name: 'string',
        last_name: 'string',
        account_status: 'active',
        referral_percent: 3,
        created_at: '2018-07-25T08:23:43.539Z',
      },
      {
        id: '444444',
        email: 'host@gmail.com',
        first_name: 'string',
        last_name: 'string',
        account_status: 'blocked',
        referral_percent: 4,
        created_at: '2018-07-25T08:23:43.539Z',
      },
    ],
    errors: [
      {},
    ],
  });
};
