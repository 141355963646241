<template>
  <div
    class="select-block-wrap select-block-wrap__nb"
    :class="{ 'select-opacity': disabled }"
  >
    <span class="pl-16">
      {{item.title}}
    </span>
    <div class="el-select__wrap">
      <v-select
        class="el-input el-input-select"
        :disabled="disabled"
        v-model="item.filter.values"
        :options="item.options"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'FilterReportsItem',
  props: {
    item: Object,
    disabled: Boolean,
  },
};
</script>
