<template>
  <div class="form-group select-wrapper token-input mt-0 mb-0">
    <div class="d-flex align-items-center">
      <!-- <input ref="input"
             v-if="!multiline"
             :value="value"
             :placeholder="placeholder"
             type="text"
             class="form-control mt-0"
             @input="e => input(e.currentTarget.value)" /> -->
      <el-input ref="input"
                v-if="!multiline"
                type="textarea"
                :value="value"
                :autosize="{ minRows: 1, maxRows: 6}"
                class="form-control-lg"
                @input="v => input(v)"
      />
      <el-input ref="input"
                v-if="multiline"
                :value="value"
                :placeholder="placeholder"
                type="textarea"
                :rows="rows || 1"
                @input="v => input(v)"
      />
      <div class="add-token-btn text-green ml-8" @click="showTokens = !showTokens">
        <ui-icon size="10px" class="text-green mr-2" name="plus" />
        {{ $t('postbacks.postback.addToken') }}
      </div>
    </div>
    <div class="el-tag__wrap" v-if="showTokens" v-click-outside="blur">
      <span v-for="({ name, token, }) in tokens"
            :key="token"
            :class="{'el-tag--selected': isSelected(token)}"
            @click="select(token)"
      >
        <el-tag :closable="false"
                :close-transition="false"
                size="small"
                type="primary"
        >+ {{ name }}</el-tag>
      </span>
    </div>
  </div>
</template>

<script>

const CURRENCY_PART = 'CURRENCY_CODE';

export default {
  name: 'TokenInput',
  props: ['id', 'value', 'placeholder', 'tokens', 'rows', 'multiline'],
  data() {
    return {
      showTokens: false,
    };
  },
  computed: {
    currencyCode() {
      return this.$store.state.payments.wallets.find(wallet => wallet.status === 'active')?.currency_code || '';
    },
  },
  methods: {
    isSelected(token) {
      return (this.value || '').includes(token.replace(`${CURRENCY_PART}}`, ''));
    },
    select(token) {
      if (this.isSelected(token)) {
        const matched = token.match(/{(\w+):\w+}/)
        const stringToSplit = matched ? new RegExp(`{${matched[1]}:\\w+}`) : token
        this.$emit('input', this.id, this.value.split(stringToSplit).join(''));
      } else {
        const value = this.value || '';
        const input = this.$refs.input.$el ? this.$refs.input.$el.getElementsByTagName('textarea')[0] : this.$refs.input;
        const begin = value.substring(0, input.selectionStart);
        const end = value.substring(input.selectionStart);
        this.$emit('input', this.id, `${begin}${token.replace(CURRENCY_PART, this.currencyCode)}${end}`);
      }
    },
    input(value) {
      this.$emit('input', this.id, value);
    },
    blur(e) {
      const input = this.$refs.input.$el ? this.$refs.input.$el.children[0] : this.$refs.input;

      if (e.toElement !== input) {
        this.showTokens = false;
      }
    },
  },
};
</script>

<style lang="scss">
  @import 'src/assets/theme/default/components/tokenInput';
</style>
