<template>
  <div
    v-if="active"
    class="login wrapper wrapper-full-page fixed-box component-secondfactor"
    style="z-index: 99999999999999;"
  >
    <div>
      <div class="content">
        <div class="container">
          <div class="backimg" />
          <div class="col-md-5 ml-auto mr-auto login-wrapper">
            <div class="login-logo">
              <img :src="logo" alt="logo">
            </div>

            <form @submit.prevent>
              <card class="card-login">
                <h3 class="card-title">
                  {{ $t('forms.sfaTitle') }}
                </h3>
                <br>
                <div class="inputs">
                  <b>{{ $t('forms.sfaLabel') }}</b>
                  <input
                    v-model="token"
                    :class="{'error': validation && !valid }"
                    :placeholder="$t('forms.sfaPlaceholder')"
                    type="text"
                    class="login-input"
                    autocomplete="off"
                    autofocus
                    @focus="validation = false;"
                    @blur="validation=true;"
                    @keyup.enter="valid && verify"
                  >
                  <div
                    v-if="validation && !valid"
                    class="text-danger text-right"
                  >
                    {{ $t('forms.validation.sfa') }}
                  </div>
                </div>
                <button
                  :disabled="!valid"
                  class="btn btn-primary btn-md btn-block big login"
                  @click="verify"
                >
                  {{ $t('forms.sfaEnter') }}
                </button>
                <div class="text-center">
                  <a @click="logout">{{ $t('forms.backToLogin') }}</a>
                </div>
              </card>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required, minLength } from 'vuelidate/lib/validators';

export default {
  name: 'SecondFactor',
  data() {
    return {
      validation: false,
      token: '',
    };
  },
  computed: {
    logo() {
      return this.$config.layout.logoFull;
    },
    valid() {
      return this.$v.token.required && this.$v.token.minLength;
    },
  },
  watch: {
    active(open) {
      if (open) this.$store.dispatch('loginPending');
    },
  },

  methods: {
    enable() {
      this.$emit('update:active', true);
    },
    disable() {
      this.$emit('update:active', false);
    },
    logout() {
      this.$router.push('/logout');
      this.disable();
      this.$store.commit('errorReset');
    },
    verify() {
      const req = this.$request.post('auth/totp', { token: this.token, trust: true });
      req.then((res) => {
        if (!res.data.payload.result) {
          this.disable();
          this.$alert.error(this.$t('forms.validation.sfa'), () => {
            this.enable();
          });
        } else {
          this.disable();
          this.$router.push(`${this.$router.currentRoute.path}?${new Date().getTime()}`);
        }
      });

      req.catch(() => {});
    },
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  validations: {
    token: {
      required,
      minLength: minLength(6),
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'styles/secondfactor';
</style>
