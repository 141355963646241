export default (mock, types) => {
  mock.onGet('test-2fa-fail').reply(423, {
    success: false,
    payload: {},
    code: types.TWO_FACTOR_UNAUTHORIZED,
    errors: [
      {},
    ],
  });
};
