<template>
  <div class="api-keys">
    <table-card
      ref="datatable"
      class="api__keys ui-m-xxl-t"
      :loading="loading"
      :columns="columns"
      :sort.sync="sorting"
      :sort-by-name="sortByName"
      :sort-dir-name="sortDirName"
      :page.sync="page"
      :page-size.sync="pageSize"
      :rows="rows"
      :actions="actions"
      :count="$store.state.profile.totalRows"
      :formaters="formaters"
      :show-total="false"
      extend-height
      is-static
      dont-fill-empty
      i18n-path="reports"
      @fetch-data="fetchData"
    >
      <span
        slot="header"
        class="api-keys__header ui-g-md"
      >
        <span class="api-keys__header-text">{{ $t("profile.apiKeys.title") }}</span>
        <tooltip
          class="api-keys__header-tooltip"
          :title="$t('profile.apiKeys.tip')"
          placement="bottom-right"
        >
          <i class="fas fa-question-circle pl-6"></i>
        </tooltip>
      </span>

      <div
        slot="actionsPrepend"
        class="ui-g-md ui-mobile-12"
      >
        <tooltip
          class="tooltip-btn-disabled"
          :title="$t('tooltip_limits.apiKeys')"
          placement="bottom-left"
          :disabled="!createDisabled"
        >
          <a
            class="btn btn-primary btn-sm ui-mobile-12"
            :class="{
              disabled: btnDisable
            }"
            :disabled="createDisabled"
            @click="createAccessKey"
          >+ {{ $t("profile.apiKeys.createButton") }}</a>
        </tooltip>
      </div>
    </table-card>

    <create-access-key ref="createAccessKey" />
  </div>
</template>

<script>
import eventBus from 'src/lib/eventBus';
import moment from 'moment';
import Mobtable from 'src/components/Mobiletable/Mobtable';
import Mobpagination from 'src/components/Mobiletable/MobPagination';
import SortBy from 'src/components/SortBy.vue';
import Formaters from 'src/views/Dashboard/CustomReports/formaters';
import TableCard from 'src/components/Cards/TableCard.vue';
import UrlMixin from 'src/components/Query/url-mixin';
import UrlMixinHooks from 'src/components/Query/url-mixin-hooks';
import DimensionsMixin from 'src/components/Report/Components/DimensionsMixin';
import InjectPlugins from 'src/components/Report/Components/inject-plugins';
import { pageSizeMixin, resolvePageSize } from 'src/views/Dashboard/Reports/page_size.js';
import HelpersMixin from 'src/components/Helpers/helpers-mixin.js';
import tableFlowMixinGenerator from 'src/views/Dashboard/Reports/tableFlowMixin.js';
import Tooltip from 'src/components/Tooltip.vue';
import CreateAccessKey from './CreateAccessKey';

const sizes = {
  1080: 10,
  1440: 20,
};
const viewName = '/api-keys';
const pageLimitProp = 'pageSize';
const pageSize = resolvePageSize(viewName, {
  _default: 10,
  sizes,
});

const defaultParams = {
  page: 1,
  pageSize,
  sort_column: 'created_at',
  sort_dir: 'desc',
};

const fetch = (ctx, params) => {
  if (ctx.$_.isEmpty(params)) {
    params = {
      ...defaultParams,
    };
  }

  const apikey = () => ctx.$store.dispatch('getApiKeys', {
    offset: ctx.pageSize * (ctx.page - 1) || 0,
    ...params,
    limit: ctx.pageSize || pageSize,
    sort_column: 'created_at',
    sort_dir: params.sort_dir || 'desc',
  });

  const count = () => ctx.$store.dispatch('getApiKeysCount');

  if (ctx.lastQuery) {
    ctx.lastQuery = { ...params };
  }

  return Promise.all([apikey(), count()]);
};

const tableFlowMixin = tableFlowMixinGenerator({
  defaultParams: {
    ...defaultParams,
  },
  params: Object.keys(defaultParams),
  fetch,
  windowRefreshHook: true,
  fetchOnMounted: true,
  sortByName: 'sort_column',
  sortDirName: 'sort_dir',
});

export default {
  fetch,
  components: {
    CreateAccessKey,
    Mobtable,
    Mobpagination,
    SortBy,
    TableCard,
    Tooltip,
  },
  mixins: [
    UrlMixin,
    UrlMixinHooks,
    DimensionsMixin,
    InjectPlugins,
    pageSizeMixin,
    HelpersMixin,
    tableFlowMixin,
  ],
  props: ['btnDisable', 'fetchedData', 'passedParams'],
  data() {
    return {
      viewName,
      pageLimitProp,
      isLoading: false,
      messageDelete: this.$t('topNavbar.wantDelete'),
      columns: [{
        name: 'key',
        filterable: false,
      }, {
        name: 'status',
        filterable: false,
        align: 'center',
      }, {
        name: 'created_at',
        filterable: false,
        sortable: true,
        align: 'center',
        format: 'date-time',
      }, {
        name: 'last_active',
        filterable: false,
        align: 'center',
      }],
      actions: {
        width: 100,
        name: () => this.$t('profile.apiKeys.table.actions'),
        operations: [{
          name: 'activate-deactivate',
          component: (row, ctx) => {
            const { deactivateKey, activateKey } = this;
            let text = ctx.$t('profile.apiKeys.table.activate');
            let klass = 'fas fa-play-circle text-green';
            if (row.status === this.$t('postbacks.card.active')) {
              text = ctx.$t('profile.apiKeys.table.deactivate');
              klass = 'fas fa-stop-circle text-red';
            }
            return ctx.$createElement('el-tooltip', {
              attrs: {
                class: 'el-tooltip icon__wrapper text-green show-channel',
                placement: 'top',
                content: text,
                'open-delay': 500,
              },
            }, [ctx.$createElement('span', {
              on: {
                click() {
                  if (row.status === ctx.$t('postbacks.card.active')) {
                    deactivateKey(row);
                  } else {
                    activateKey(row);
                  }
                },
              },
            }, [ctx.$createElement('i', {
              attrs: {
                class: klass,
              },
            })])]);
          },
        }, {
          name: 'delete',
          component: (row, ctx) => {
            const { deleteKey } = this;
            const span = ctx.$createElement('span', {
              on: {
                click(e) {
                  e.cancelBubble = true;
                },
              },
            }, [ctx.$createElement('i', {
              attrs: {
                class: 'far fa-trash-alt text-red',
              },
            })]);
            const elTooltip = ctx.$createElement('el-tooltip', {
              attrs: {
                class: 'el-tooltip icon__wrapper text-red',
                placement: 'top',
                content: this.$t('promoCodes.promocodes.delete'),
                'open-delay': 500,
              },
            }, [span]);
            return ctx.$createElement('ac-popover', {
              attrs: {
                width: 250,
                message: `${this.$t('topNavbar.wantDelete')}?`,
                buttons: [{
                  text: this.$t('mediaBuying.channels.cancel'),
                }, {
                  text: this.$t('promoCodes.promocodes.delete'),
                  type: 'danger',
                  click: () => deleteKey(row),
                }],
              },
            }, [elTooltip]);
          },
        },
        ],
      },
      lastQuery: {},
      loading: false,
      formaters: Formaters,
      isTableReady: false,
    };
  },
  computed: {
    rows() {
      return this.$store.state.profile.apiKeys.map(e => ({
        key: e.key,
        status: this.$t(`postbacks.card.${e.status}`),
        created_at: e.created_at,
        last_active: e.last_active ? moment(e.last_active).format('DD MMM YYYY HH:mm') : this.$t('profile.apiKeys.table.never'),
        data: {
          id: e.id,
        },
      }));
    },
    totalRows() {
      return this.$store.state.profile.totalRows;
    },
    createDisabled() {
      return this.$store.state.profile.totalRows >= (this.$store.state.settings.limitations || {}).max_api_keys;
    },
  },
  created() {
    this.actions.operations.map((e) => {
      if (e.component) {
        e.component = e.component.bind(this);
      }
    });
    this.setFromQuery(this.passedParams);
  },
  methods: {
    createAccessKey() {
      if (!this.createDisabled) {
        this.$confirm.password(this.$t('profile.apiKeys.passwordRequired'), '', (pwd, modal) => {
          modal.isLoading = true;
          this.$store
            .dispatch('createApiKey', { password: pwd })
            .then((data) => {
              modal.close();
              this.$store.dispatch('getApiKeysLazy', { ...this.offsetParams, ...this.sort });
              this.$refs.createAccessKey.open(data);
            })
            .finally(() => modal.isLoading = false);
        });
      }
    },
    deactivateKey(row) {
      if (this.isLoading) return;
      this.isLoading = true
      this.$store
        .dispatch('deactivateApiKey', { id: row.data.id, ...this.offsetParams, ...this.sort })
        .then(() => {
          this.$alert.success(this.$t('profile.apiKeys.apiKeyDeactivated'));
        })
        .finally(() => this.isLoading = false);
    },
    activateKey(row) {
      if (this.isLoading) return;
      this.isLoading = true
      this.$store
        .dispatch('activateApiKey', { id: row.data.id, ...this.offsetParams, ...this.sort })
        .then(() => {
          this.$alert.success(this.$t('profile.apiKeys.apiKeyActivated'));
        })
        .catch(() => {
          this.$alert.error(this.$t('profile.apiKeys.unexpectedError'));
        })
        .finally(() => this.isLoading = false);
    },
    deleteKey(row) {
      if (this.isLoading) return;
      this.isLoading = true
      this.$store
        .dispatch('deleteApiKey', row.data.id)
        .then(() => {
          this.$store.dispatch('getApiKeysLazy', { ...this.offsetParams, ...this.sort });
          this.$store.dispatch('getApiKeysCountLazy');
          this.$alert.success(this.$t('profile.apiKeys.apiKeyDeleted'));
        })
        .catch(() => {
          this.$alert.error(this.$t('profile.apiKeys.unexpectedError'));
        })
        .finally(() => this.isLoading = false);
    },
  },
};
</script>

<style lang="scss">
@import 'src/assets/theme/default/profile/apikeys';

.api-keys {
  &__header {
    align-items: center;
    .ui-tooltip {
      width: unset;
    }
  }

  &__header-text {
    font-size: 16px;
    font-weight: 600;
  }

  &__header-tooltip {
    color: var(--active-color);
    font-size: 14px;
    align-self: center;
  }
}
</style>
