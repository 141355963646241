<template>
  <div
    :class="{'fade-in':form.email, 'iphone-100vh': false}"
    class="container-fluid profile profile-row"
  >
    <div class="row">
      <div class="col-xl-6 col-12 pl-0 custom-pr">
        <div class="col-12 card card-body">
          <general
            ref="editProfile"
            :user.sync="user"
            :form.sync="form"
            :btn-disable="!user"
            :class="{wavebg: loading}"
          />

          <hr/>

          <payment-method
            :user.sync="user"
            :form.sync="form"
            :btn-disable="!user"
            :class="{wavebg: loading}"
          />

          <hr/>

          <edit-second-factor
            :user.sync="user"
            :form.sync="form"
            :btn-disable="!user"
            :class="{wavebg: loading}"
          />
        </div>
      </div>
      <div class="col-xl-6 col-12 pl-0 pr-0 custom-pr">
        <div class="col-12 p-0 custom-mt-respons" v-if="user.is_public_profile_enabled">
          <public-profile :public-profile-data.sync="user.public_profile"></public-profile>
        </div>
        <div class="col-12 p-0" :class="{ 'mt-16': user.is_public_profile_enabled }">
          <edit-notifications
            :class="{wavebg: loading}"
            :channelValue="channel"
            :turnonValue="turnon"
          />
        </div>
      </div>
    </div>
    <api-keys
      :class="{wavebg: loading}"
      :btn-disable="!user"
      :passedParams="passedParams"
    />
  </div>
</template>

<script>
import eventBus from 'src/lib/eventBus';
import HelpersMixin from 'src/components/Helpers/helpers-mixin.js';
import General from './Profile/General.vue';
import PaymentMethod from './Profile/PaymentMethod';
import EditSecondFactor from './Profile/EditSecondFactor';
import EditNotifications from './Profile/EditNotifications';
import PublicProfile from './Profile/PublicProfile';
import ApiKeys from './Profile/ApiKeys';

const fetch = ctx => ctx.$store.dispatch('getProfileData');

export default {
  fetch,
  components: {
    General,
    PaymentMethod,
    EditSecondFactor,
    EditNotifications,
    PublicProfile,
    ApiKeys,
  },
  props: ['fetchedData', 'passedParams'],
  mixins: [
    HelpersMixin,
  ],
  data() {
    return {
      loading: false,
    };
  },
  computed: {
    user() {
      return this.$store.state.profile.user;
    },
    firstDay() {
      return this.$store.state.profile.user.first_day_of_week;
    },
    channel() {
      return this.$store.getters.getUser.service_domain_changes_notifications_channel;
    },
    turnon() {
      return this.$store.getters.getUser.service_domain_changes_notifications_enabled;
    },
    form() {
      const { user } = this.$store.state.profile;

      return this.$_.cloneDeep({
        ...user,
        ...((!user.date_of_birth || user.date_of_birth === 'Invalid date') && {
          date_of_birth: this.$t('profile.generalInfo.notSpecified')
        })
      })
    },
  },
  mounted() {
    eventBus.$on('window-refresh', () => {
      if (this.$route.name === 'routes.profile') {
        this.loading = true;
        fetch(this)
          .then(() => {
            this.afterBrowserRedraw(() => {
              this.loading = false;
            });
          });
      }
    });
  },
  activated() {
    this.loading = true;
    fetch(this)
      .then(() => {
        this.afterBrowserRedraw(() => {
          this.loading = false;
        });
      });
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
@import 'src/assets/theme/default/profile/profile';
.card-body {
  @media (max-width: 650px) {
    padding: 16px !important;
  }
}
</style>
<style lang="scss">
.profile {
  .form-group.red-border {
    input[type='text'], input[type='password'] {
      border: 1px solid #ff5c70 !important;
    }
  }

  @media (max-width: 650px) {
    .card-title {
      font-size: 14px !important;
    }
  }
}
</style>
