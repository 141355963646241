<template>
  <modal :show.sync="active" class="modal-profile profile__modal-createkey">
    <h5 slot="header" class="modal-title">
      {{ $t('profile.apiKeys.createDialog.title') }}
    </h5>
    <template>
      <div class="">
        <img :src="require('@/assets/theme/icon/ok.svg')" alt="success">
        <p class="mb-16 mt-16">
          {{ $t('profile.apiKeys.createDialog.tip') }}
        </p>
        <a
          :href="csv"
          :download="data.key + '.csv'"
          class="btn btn-primary btn-sm mb-16"
        >
          {{ $t('profile.apiKeys.createDialog.download') }} .CSV {{ $t('profile.apiKeys.createDialog.file') }}
        </a>
        <h6>{{ $t('profile.apiKeys.createDialog.accessKeyId') }}</h6>
        <p class="mb-8">
          {{ data.key }}
        </p>
        <hr class="mt-10 mb-10">
        <h6>{{ $t('profile.apiKeys.createDialog.secretAccessKey') }}</h6>
        <showtext
          :text="data.secret_key || '111111111111111111111111111111111'"
          show="Show"
          hide="Hide"
        />
      </div>
    </template>
  </modal>
</template>

<script>
import { Modal } from 'src/components';
import Showtext from 'src/components/Showtext';

export default {
  components: {
    Modal,
    Showtext,
  },

  data() {
    return {
      active: false,
      data: {},
    };
  },

  computed: {
    csv() {
      return `data:text/plain;charset=utf-8,${encodeURIComponent(`ID,KEY,SECTET,TIME CREATED\n\n${this.data.id},${this.data.key},${this.data.secret_key},${this.data.created_at}`)}`;
    },
  },

  methods: {
    open(data) {
      this.active = true;
      this.data = data.payload;
      // this.$forceUpdate();
    },

    close() {
      this.active = false;
    },
  },
};
</script>

<style lang="scss">
@import 'src/assets/theme/default/profile/createaccesskey';
</style>
