<template>
  <modal class="modal-password profile__modal-change-password" :show.sync="active" @close="close">
    <h5 slot="header" class="modal-title mr-8">
      {{ $t('profile.disableSecondFactor.title') }}
    </h5>
    <template>
      <div class="modal__password">
        {{ $t('profile.disableSecondFactor.password') }} <span>*</span>
      </div>
      <password-input
        class="mb-4"
        :class="{ 'red-border': errors_.password }"
        ref="password"
        :data.sync="model.password"
        @click="errors_.password = ''"
        @input="errors_.password = ''"
      />
      <div v-if="errors_.password" class="text-danger error-style wrongPassword">
        {{ errors_.password[0].message }}
      </div>
      <label class="label-sfa mt-8 mb-0"> {{ $t('profile.disableSecondFactor.token') }} <span>*</span> </label>
      <CodeInput
        ref="codeinput"
        :key="active"
        :fields="6"
        :fieldWidth="46"
        :fieldHeight="46"
        class="input justify-content-center w-100" @change="onChange()"
      />
      <div v-if="errors_.token" class="text-danger error-style wrongPassword">
        {{ errors_.token[0].message }}
      </div>
    </template>

    <div slot="footer" class="m-0">
      <button
        class="btn btn-primary btn-sm d-block w-100 mt-8 mb-8"
        :disabled="!model.password
          || !model.token
          || model.token.length !== 6
          || isLoading"
        @click="pass"
      >
        {{ $t('profile.disableSecondFactor.disable') }}
      </button>
    </div>
  </modal>
</template>

<script>
import { Modal } from 'src/components/index';
import PasswordInput from 'src/components/PasswordInput';
import CodeInput from 'vue-verification-code-input';
import mixinsPasteCodeInput from '@/helpers/mixinsPasteCodeInput';


export default {
  mixins: [mixinsPasteCodeInput],
  components: {
    Modal,
    PasswordInput,
    CodeInput,
  },
  data() {
    return {
      isLoading: false,
      active: false,
      callback: new Function(),
      // typeCode: 'bc',
      model: {
        password: '',
        token: '',
      },
      passwordShown: false,
      errors_: {},
    };
  },

  methods: {
    open(cb) {
      this.active = true;
      this.callback = cb || new Function();
      this.model.password = '';
      this.model.token = '';
      this.errors_ = {};
    },
    pass() {
      this.isLoading = true;
      this.$store
        .dispatch('otpDisable', { ...this.model })
        .then(() => {
          this.$alert.success(this.$t('profile.secondFactor.googleAuthdisabled'));
          this.active = false;
        })
        .catch((err) => {
          this.errors_ = err.response.data.errors;
          this.$alert.error(err.response.data.message, () => {});
        })
        .finally(() => this.isLoading = false);
    },
    onChange() {
      this.model.token = this.$refs.codeinput.values.reduce((a, b) => a.concat(b), '');
      this.errors_.token = '';
    },
    close() {
      this.$refs.password.reset();
      this.model.password = '';
      this.model.token = '';
      this.errors_ = {};
      this.active = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.modal__password {
  text-transform: uppercase;
  font-weight: 600;
  padding-bottom: 7px;
  font-size: 13px;
  span {
    color: #f66;
  }
}
.password {
  position: relative;
  i {
    position: absolute;
    right: 5px;
    top: 50%;
    opacity: 0.3;
    transform: translateY(-50%);
  }
}
</style>
