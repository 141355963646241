<template>
  <section>
    <modal :show.sync="active" class="offer" @close="close">
      <template>
        <h5 slot="header" class="modal-title mt-8">
          {{ $t('offer.card.titleGen') }}
        </h5>
        <div v-loading="loading">
          <div v-if="hideSelect(brands)" class="select-wrapper w-100 mt-14 mb-10" :class="{ disabled: offer === '' }">
            <label>{{ $t('mediaTags.brand') }}</label>
            <v-select :id="`${params_id}offers.brand`" v-model="brand" :options="brands" class="el-input" @change="changeBrand" />
          </div>
          <div v-if="hideSelect(rotations)" class="select-wrapper w-100 mt-14 mb-10" :class="{ disabled: brand === '' }">
            <label>{{ $t('mediaTags.rotation') }}</label>
            <v-select :id="`${params_id}offers.rotation`" v-model="rotation" :options="rotations" class="el-input" @change="changeRotation" />
          </div>
          <div class="select-wrapper w-100 mt-14 mb-10" :class="{ disabled: rotation === '' }">
            <label>{{ $t('mediaTags.target') }}</label>
            <v-select :id="`${params_id}offers.activeTab`" v-model="activeTab" :options="urls" class="el-input" @change="setActive" />
          </div>
          <div class="generate-tag__right-col">
            <div v-popover:popover class="select-wrapper mb-14" :class="{ disabled: brand === '' || activeTab === '' }">
              <label>{{ $t('trafficSources.trafficSource') }}</label>
              <v-select
                :id="`${params_id}offers.traffic_source_id`"
                v-model="traffic_source_id"
                :options="list"
                class="el-input"
                @change="setActive('debounce'), (checkChange = false)"
              />
              <router-link v-if="!list.length" :to="{ path: '../traffic-source' }" class="text-green d-block text-right">
                {{ $t('trafficSources.addTrafficSources.title') }}
              </router-link>
            </div>
            <el-popover ref="popover" v-model="warningOpen" placement="top-end" trigger="manual" width="320">
              <p>{{ $t('promoCodes.addPromocodes.changeSubId') }}</p>
              <button class="btn btn-outline-primary btn-md" @click="saveSubids">
                {{ $t('offerRequest.btnCancel') }}
              </button>
              <button
                class="btn btn-primary btn-md ml-3"
                @click="
                  confirmChanges();
                  warningOpen = false;
                "
              >
                {{ $t('offerRequest.btnConfirm') }}
              </button>
            </el-popover>
            <div
              class="form-group generate-tag__form-group generate-tag__form-group--underline"
              :class="{ 'disable-input': brand === '' || activeTab === '' }"
            >
              <label class="generate-tag__label generate-tag__label--final" for="final-url"> {{ $t('offer.card.finalPageUrl') }}</label>
              <input
                id="final-url"
                :disabled="brand === ''"
                v-model="link"
                autocomplete="off"
                type="text"
                class="form-control generate-tag__input"
                @input="inputLink"
              />
            </div>
            <div class="tag-wrap">
              <span
                v-for="item in links"
                :key="item.page_url"
                :class="{ 'el-tag--selected': link === item.page_url, 'disabled-link': brand === '' || activeTab === '' }"
                class="el-tag el-tag--primary el-tag--small"
                @click="setLink(item.page_url)"
              >{{ item.name }}</span>
            </div>
            <div class="row row-wrap__subid pt-6">
              <div
                v-for="(item, i) in form.subids"
                :key="i"
                :class="{ 'disable-input': brand === '' || activeTab === '' }"
                class="col-6 col-6__add-subid"
              >
                <fg-input
                  v-model="item.value"
                  :disabled="brand === ''"
                  :placeholder="$t('trafficSources.postbacks.notSpecified')"
                  class="no-border form-control-lg mb-0"
                >
                  <label slot="label" class="d-flex justify-content-between">
                    <span>SubID{{ i + 1 }}</span>
                    <a
                      v-if="calcSubIdInTrafficSource < i + 1"
                      v-show="form.subids.indexOf(item) == form.subids.length - 1 && form.subids.length > 1"
                      href="#"
                      class="btn-cross"
                      @click.prevent="popSubIds"
                    >
                      <span>×</span>
                    </a>
                  </label>
                </fg-input>
              </div>
              <div class="col-6 d-flex align-items-end col-6__add-subid">
                <button
                  :disabled="form.subids.length >= 5 || brand === '' || activeTab === ''"
                  href="#"
                  class="btn btn-block btn-outline-primary btn-sm mb-0"
                  @click.prevent="form.subids.length < 5 && addSubId()"
                >
                  + {{ $t('promoCodes.addPromocodes.addAnotherSubid') }}
                </button>
              </div>
            </div>
          </div>
          <div class="">
            <div class="w-100 pt-16">
              <div class="offers__track-title mb-14">
                {{ $t('offer.card.trackUrl') }}
              </div>
              <copytext
                :button="$t('offer.card.btn')"
                :success="$t('offer.card.btnDone')"
                :text.sync="url"
                :disabled="warningOpen"
              />
            </div>
          </div>
        </div>
        <button slot="footer" class="btn btn-outline-primary btn-sm btn-close-modal" @click="close">
          {{ $t('offer.offerRequestSuccess.close') }}
        </button>
      </template>
    </modal>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import Copytext from 'src/components/Copytext';
import { Modal } from 'src/components';
import { Select, Option } from 'element-ui';
import VSelectMenu from 'src/components/VSelectMenu.vue';
import { debounce } from 'lodash';

export default {
  components: {
    Modal,
    Copytext,
    [Select.name]: Select,
    [Option.name]: Option,
    VSelectMenu,
  },
  data() {
    return {
      warningOpen: false,
      params_id: '',
      params_query: '',
      active: false,
      activeTab: '',
      url: '',
      form: {
        name: '',
        extid: '',
        subids: [{ value: '' }],
      },
      link: '',
      links: [],
      brand: '',
      rotation: '',
      traffic_source_id: null,
      selected: null,
      oldTSid: '',
      checkChange: false,
      loading: false,
      customSubids: [{ value: '' }],
      paramsSubsId: {},
      getRedirestUrlD8: debounce(this.getRedirestUrl, 800),
      site_page: null,
      visibleBrands: [],
    };
  },
  computed: {
    ...mapState({
      marketingCampaigns: state => state.mediaBuying.marketingCampaigns || [],
    }),

    offer() {
      return this.$store.state.offers.offer;
    },

    urls() {
      const urls = this.$store.state.mediaBuying.landings.map(e => ({
        ...e,
        text: e.name,
      }));
      return urls.concat({
        id: '',
        text: this.$t('profile.generalInfo.notSpecified'),
      });
    },

    list() {
      const data = this.$store.state.trafficSource.list.map(e => ({
        id: e.data.id,
        text: e.name,
      }));
      return [
        {
          id: '',
          text: this.$t('offerRequest.noTrafficSources'),
        },
      ].concat(data);
    },

    brands() {
      const brands = (this.visibleBrands || []).map(e => ({
        id: e.site_id,
        text: e.site_name,
      }));
      return brands.concat({
        id: '',
        text: this.$t('profile.generalInfo.notSpecified'),
      });
    },

    rotations() {
      const rotations = this.$_.cloneDeep(this.marketingCampaigns)
        .sort(
          (a, b) => {
            if (a.type) {
              return a.type.length - b.type.length;
            }
          },
        )
        .map(e => ({
          id: e.id,
          text: e.name,
        }));
      return rotations.concat({
        id: '',
        text: this.$t('profile.generalInfo.notSpecified'),
      });
    },

    params() {
      return {
        landing_id: this.activeTab,
        traffic_source_id: this.traffic_source_id,
        marketing_campaign_id: this.rotation,
        site_page: this.site_page,
        ...this.paramsSubsId,
      };
    },

    calcSubIdInTrafficSource() {
      const id = this.traffic_source_id;
      if (!id) return 1;

      const subidArray = Array(5).fill(null).map((el, i) => `subid${i + 1}`);

      const targetTrafficSoutce = this.$store.state.trafficSource.list
        .find(ts => ts.data.id === id);

      let resultCount = 1;
      subidArray.forEach((el, i) => {
        if (targetTrafficSoutce[el] !== null) {
          resultCount = i + 1;
        }
      });

      return resultCount;
    },
  },

  watch: {
    brand: {
      handler(data) {
        if (data) {
          this.$api.getFinalPages(data).then(res => {
            this.links = res.payload.site_final_page_urls;
          })
        } else {
          this.links = [];
        }
      }
    },
    traffic_source_id(value, oldValue) {
      if (this.checkChange) return;
      this.oldTSid = oldValue;
      // changing url string on traffic source change
      if (this.offer.tracking_links) {
        const urls = this.offer.tracking_links.find(e => e.traffic_source_id === value || e.traffic_source_id === null).urls;
        this.url = (urls.find(e => e.id === this.activeTab) || []).url;
      }

      let oldSource = this.$store.state.trafficSource.list.filter(e => e.data.id === oldValue)[0];
      oldSource = _.pick(oldSource, ['subid1', 'subid2', 'subid3', 'subid4', 'subid5']);
      const subids = {};

      if (!value) {
        this.form.extid = '';
        for (let i = 0; i < 5; i++) {
          const key = `subid${i + 1}`;
          subids[key] = this.form.subids[i] ? this.form.subids[i].value : null;
        }
        if (!_.isEmpty(oldSource) && !_.isEqual(subids, oldSource)) {
          this.addSubids(subids);
          return;
        }
        if (this.customSubids.some(e => e.value)) {
          this.form.subids = this.customSubids;
        } else {
          // if no traffic source selected clear subids
          this.form.subids = [{ value: null }];
        }
        return;
      }

      let source = this.$store.state.trafficSource.list.filter(e => e.data.id === value)[0];
      this.form.extid = source.data.extid || '';
      source = _.pick(source, ['subid1', 'subid2', 'subid3', 'subid4', 'subid5']);

      // if no traffic source selected, like from start
      if (!oldValue) {
        if (this.form.subids === 1 || !this.form.subids[0].value) {
          // if subids empty
          this.addSubids(source);
        } else {
          // take current subids
          const subids = {};
          for (let i = 0; i < 5; i++) {
            const key = `subid${i + 1}`;
            subids[key] = this.form.subids[i] ? this.form.subids[i].value : null;
          }
          // test if current subids is equal with new subids
          if (_.isEqual(subids, source)) {
            this.form.subids = [{ value: null }];
            this.addSubids(source);
          } else {
            // show warning
            this.warningOpen = true;
          }
        }
        return;
      }

      // take current subids
      for (let i = 0; i < 5; i++) {
        const key = `subid${i + 1}`;
        subids[key] = this.form.subids[i] ? this.form.subids[i].value : null;
      }
      // test if current subids is equal with old subids
      if (_.isEqual(subids, oldSource)) {
        this.form.subids = [{ value: null }];
        this.addSubids(source);
      } else {
        // show warning
        this.warningOpen = true;
      }
    },

    'form.subids': {
      handler(subids) {
        this.parseSubids(subids);
      },
      deep: true,
    },
  },

  methods: {
    hideSelect(optoins = []) {
      return optoins.length > 2;
    },

    parseSubids(subids) {
      if (!subids.subid1 && this.activeTab !== '') {
        const objSubs = {};
        for (let i = 0; i < subids.length; i++) {
          const key = `subid${i + 1}`;
          objSubs[key] = subids[i] ? subids[i].value : null;
        }

        this.paramsSubsId = objSubs;
        this.setActive('debounce');
      }
    },

    async changeBrand() {
      this.reset();
      if (this.brand === '') {
        return;
      }
      this.loading = true;
      await this.$store.dispatch('getLandings', { site_id: this.brand });
      this.loading = false;
      this.rotation = this.rotations[0].id;
      if (this.urls.length === 2) {
        this.activeTab = this.urls[0].id;
        this.setActive();
      }
    },

    async changeRotation() {
      if (this.rotation === '') {
        this.reset();
        return;
      }

      if (this.activeTab) {
        await this.getRedirestUrl();
      }
    },

    async setActive(action) {
      if (this.activeTab === '') {
        this.reset('target');
        return;
      }
      if (action === 'debounce') {
        await this.getRedirestUrlD8('hide');
        return;
      }
      await this.getRedirestUrl('hide');
    },

    async getRedirestUrl(action) {
      if (action !== 'hide') this.loading = true;
      try {
        const {
          payload,
        } = await this.$api.getRedirectUrl(this.params_id, this.params);
        this.url = payload.url;
      } finally {
        this.loading = false;
      }
    },

    open({ id, visible_brands }, sub_affiliate_id) {
      this.visibleBrands = visible_brands;
      this.reset();
      this.brand = '';
      this.params_id = id;
      this.form.subids = [{ value: null }];

      if ((visible_brands || []).length === 1) {
        this.brand = visible_brands[0].site_id;
      } else {
        this.brand = '';
      }
      this.changeBrand();

      if (sub_affiliate_id) {
        this.$store.dispatch('getSubAffiliateTrafficSourcesLazy', sub_affiliate_id);
      } else {
        this.$store.dispatch('getTrafficSourcesLazy', {});
      }

      this.active = true;
    },

    close() {
      this.active = false;
      this.brand = '';
      this.reset();
    },

    reset(action) {
      this.activeTab = '';
      this.url = '';
      if (action !== 'target') this.rotation = '';
      this.traffic_source_id = null;
      this.form.subids = [{ value: null }];
      this.customSubids = [{ value: null }];
      this.form.extid = '';
      this.link = '';
      this.links = [];
      this.site_page = '';
      this.paramsSubsId = {};
      this.checkChange = false;
      this.warningOpen = false;
      this.loading = false;
    },

    popSubIds(item) {
      this.form.subids.pop();
    },
    addSubId() {
      this.form.subids.push({
        value: '',
      });
    },

    inputLink() {
      this.site_page = this.link;
      this.getRedirestUrlD8('hide');
    },

    setLink(url) {
      this.link = url;
      this.site_page = url;
      this.getRedirestUrl('hide');
    },

    addSubids(source) {
      if (!source) {
        this.form.subids = [{ value: null }];
        return;
      }
      this.form.subids = [];
      // Push subids

      Object.keys(source).map((k) => {
        if (k.startsWith('subid')) {
          const subid_index = parseInt(k.replace('subid', '')) - 1;
          if (source[k]) {
            this.form.subids.push({
              value: source[k],
            });
          } else {
            this.form.subids.push({
              value: null,
            });
          }
        }
      });
      // reduce subids
      for (let i = this.form.subids.length - 1; i >= 1; i--) {
        if (!this.form.subids[i].value) this.form.subids.pop();
        else break;
      }
    },
    confirmChanges() {
      this.form.subids = [{ value: null }];
      this.customSubids = [{ value: null }];
      const source = this.$store.state.trafficSource.list.filter(e => e.data.id === this.traffic_source_id)[0];
      this.addSubids(source);
    },
    saveSubids() {
      if (this.form.subids.length < this.calcSubIdInTrafficSource) {
        const remainder = this.calcSubIdInTrafficSource - this.form.subids.length;

        this.customSubids = [
          ..._.cloneDeep(this.form.subids),
          ...Array(remainder).fill({ value: '' }),
        ].map(el => (!el.value ? { value: '' } : el));
        this.form.subids = this.customSubids;
      } else {
        this.customSubids = _.cloneDeep(this.form.subids);
      }

      this.checkChange = true;
      this.warningOpen = false;
    },
  },
};
</script>

<style lang="scss">
@import 'src/assets/theme/default/offers/offer';
</style>
