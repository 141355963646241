<template>
  <div>
    <div class="login wrapper wrapper-full-page">
      <div class=" ">
        <div class="content">
          <div class="container">
            <a :href="mainUrlRec" class="reload-login-recapcha"/>
            <slot />
            <zoom-center-transition :duration="pageTransitionDuration" mode="out-in">
              <router-view />
            </zoom-center-transition>
          </div>
        </div>
        <footer class="footer">
          <div class="container-fluid" />
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
import { Navbar, NavbarToggleButton } from 'src/components';
import { ZoomCenterTransition } from 'vue2-transitions';

export default {
  components: {
    Navbar,
    NavbarToggleButton,
    ZoomCenterTransition,
  },
  props: {
    backgroundColor: {
      type: String,
      default: 'black',
    },
  },

  data() {
    return {
      mainUrlRec: window.location.origin,
      showMenu: false,
      menuTransitionDuration: 250,
      pageTransitionDuration: 200,
      year: new Date().getFullYear(),
      pageClass: 'login-page',
      recaptcha_key: '',
      authenticated: !!localStorage.getItem('auth-token'),
    };
  },
  computed: {
    imageStyles() {
      const img = require('@/assets/img/bg13.jpg');
      return {
        backgroundImage: `url(${img})`,
      };
    },
  },
  watch: {
    $route(to, from) {
      this.setPageClass();
      localStorage.setItem('lastVisitedRoute', to.path || '/dashboard');
      document.title = `${this.$t(to.name)} - ${this.$config.pageTitle}`;
    },
  },
  activated() {
    this.$event.$on(this.$error.UNAUTHORIZED_ERROR, () => {
      this.error = true;
    });
  },
  beforeDestroy() {
    this.closeMenu();
  },
  methods: {
    verifyRecaptcha(data) {
      this.$store.commit('recaptcha', data);
    },
    expiredRecaptcha(data) {
      this.$refs.captcha.execute();
    },
    toggleNavbar() {
      document.body.classList.toggle('nav-open');
      this.showMenu = !this.showMenu;
    },
    closeMenu() {
      document.body.classList.remove('nav-open');
      this.showMenu = false;
    },
    setPageClass() {
      this.pageClass = `${this.$route.name}-page`.toLowerCase();
    },
  },
};
</script>

<style lang="scss">
.reload-login-recapcha {
  display: block;
}
$scaleSize: 0.8;
@keyframes zoomIn8 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  100% {
    opacity: 1;
  }
}
.wrapper-full-page .zoomIn {
  animation-name: zoomIn8;
}
@keyframes zoomOut8 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}
.wrapper-full-page .zoomOut {
  animation-name: zoomOut8;
}
</style>
