const types = {
  FORBIDDEN_ERROR: 1,
  INTERNAL_ERROR: 2,
  NOT_ALLOWED_ERROR: 3,
  TWO_FACTOR_UNAUTHORIZED: 4,
  UNAUTHORIZED_ERROR: 5,
  VALIDATION_ERROR: 6,
  NOT_FOUND_ERROR: 7,

  VALIDATION_REQUIRED: 1000,
  VALIDATION_INVALID_VALUE: 1001,
  VALIDATION_INVALID_URL_VALUE: 1002,
  VALIDATION_INVALID_BOOLEAN_VALUE: 1003,
  VALIDATION_MAX_LENGTH_EXCEEDED: 1004,
  VALIDATION_INVALID_DATE: 1005,
  VALIDATION_INVALID_PHONE_NUMBER: 1006,
  VALIDATION_INVALID_COUNTRY_CODE: 1007,
  VALIDATION_EMAIL_ALREADY_TAKEN: 1008,
  VALIDATION_MIN_PASSWORD_LENGTH: 1009,
  VALIDATION_TOTP_INVALID_PASSWORD: 1010,
  VALIDATION_TOTP_INVALID_TOKEN: 1011,
  VALIDATION_INVALID_EMAIL: 1012,
  VALIDATION_INVALID_PASSWORD: 1013,
  VALIDATION_EMAIL_NOT_REGISTERED: 1014,
  VALIDATION_INVALID_RESET_PASSWORD_TOKEN: 1015,
  VALIDATION_IS_NOT_A_STRING: 1016,
  VALIDATION_PROMO_CODE_ALREADY_TAKEN: 1017,
  VALIDATION_PAYMENT_METHOD_ALREADY_EXISTS: 1018,
  VALIDATION_INVALID_OFFER_SETTINGS_OBJECT: 1019,
  VALIDATION_MUST_BE_NON_NEGATIVE: 1020,
  VALIDATION_INVALID_PERCENT_VALUE: 1021,
  VALIDATION_SHOULD_BE_ARRAY_OR_NULL: 1022,
  VALIDATION_SHOULD_BE_AN_OBJECT: 1022,
  VALIDATION_SHOULD_BE_AN_ARRAY: 1023,
  VALIDATION_SHOULD_BE_A_STRING: 1024,
  VALIDATION_MALFORMED_SETTINGS_OBJECT: 1025,
  VALIDATION_PROMO_CODE_CONTAINS_INVALID_CHARS: 1026,
  AUTH_INVALID_CREDENTIALS_ERROR: 2000,
  AUTH_ACCOUNT_BLOCKED: 2001,
  AUTH_ACCOUNT_REJECTED: 2002,
  AUTH_ACCOUNT_PENDING: 2003,
  AUTH_INVALID_RECAPTCHA_RESPONSE: 2004,
  AUTH_EMAIL_IS_NOT_VERIFIED: 2005,


  // native errors
  NATIVE_BAD_REQUEST: 400,
  NATIVE_UNAUTHORIZED: 401,
  NATIVE_FORBIDDEN: 403,
  NATIVE_NOT_FOUND: 404,
  NATIVE_REQUEST_TIMEOUT: 408,
  NATIVE_INTERNAL_SERVER_ERROR: 500,
  NATIVE_NOT_IMPLEMENTED: 501,
  NATIVE_BAD_GATEWAY: 502,
  NATIVE_SERVICE_UNAVAILABLE: 503,
  NATIVE_GATEWAY_TIMEOUT: 504,
  NATIVE_HTTP_VERSION_NOT_SUPPORTED: 505,
  NATIVE_VARIANT_ALSO_NEGOTIATES: 506,
  NATIVE_WEB_SERVER_IS_DOWN: 521,
  NATIVE_CONNECTION_TIMED_OUT: 522,
};

const messages = {
  [types.FORBIDDEN_ERROR]: 'requestErrors.forbidden',
  [types.INTERNAL_ERROR]: 'requestErrors.internal_error',
  [types.NOT_ALLOWED_ERROR]: 'requestErrors.not_allowed',
  [types.TWO_FACTOR_UNAUTHORIZED]: 'requestErrors.2fa_unauthorized',
  [types.UNAUTHORIZED_ERROR]: 'requestErrors.unauthorized',
  [types.VALIDATION_ERROR]: 'requestErrors.validation_error',
  [types.NOT_FOUND_ERROR]: 'requestErrors.not_found',

  [types.VALIDATION_REQUIRED]: 'requestErrors.validation.required',
  [types.VALIDATION_INVALID_VALUE]: 'requestErrors.validation.invalid_value',
  [types.VALIDATION_INVALID_URL_VALUE]: 'requestErrors.validation.invalid_url_value',
  [types.VALIDATION_INVALID_BOOLEAN_VALUE]: 'requestErrors.validation.invalid_boolean_value',
  [types.VALIDATION_MAX_LENGTH_EXCEEDED]: 'requestErrors.validation.max_length_exceeded',
  [types.VALIDATION_INVALID_DATE]: 'requestErrors.validation.invalid_date',
  [types.VALIDATION_INVALID_PHONE_NUMBER]: 'requestErrors.validation.invalid_phone_number',
  [types.VALIDATION_INVALID_COUNTRY_CODE]: 'requestErrors.validation.invalid_country_code',
  [types.VALIDATION_EMAIL_ALREADY_TAKEN]: 'requestErrors.validation.email_already_taken',
  [types.VALIDATION_MIN_PASSWORD_LENGTH]: 'requestErrors.validation.min_password_length',
  [types.VALIDATION_TOTP_INVALID_PASSWORD]: 'requestErrors.validation.totp_invalid_password',
  [types.VALIDATION_TOTP_INVALID_TOKEN]: 'requestErrors.validation.totp_invalid_token',

  [types.AUTH_INVALID_CREDENTIALS_ERROR]: 'requestErrors.auth.invalid_credentials',
  [types.AUTH_ACCOUNT_BLOCKED]: 'requestErrors.auth.account_blocked',
  [types.AUTH_ACCOUNT_REJECTED]: 'requestErrors.auth.account_rejected',
  [types.AUTH_ACCOUNT_PENDING]: 'requestErrors.auth.account_pending',

  [types.NATIVE_BAD_REQUEST]: 'requestErrors.client.native_bad_request',
  [types.NATIVE_UNAUTHORIZED]: 'requestErrors.client.native_unauthorized',
  [types.NATIVE_FORBIDDEN]: 'requestErrors.client.native_forbidden',
  [types.NATIVE_NOT_FOUND]: 'requestErrors.client.native_not_found',
  [types.NATIVE_REQUEST_TIMEOUT]: 'requestErrors.client.native_request_timeout',

  [types.NATIVE_INTERNAL_SERVER_ERROR]: 'requestErrors.server.native_internal_server_error',
  [types.NATIVE_NOT_IMPLEMENTED]: 'requestErrors.server.native_not_implemented',
  [types.NATIVE_BAD_GATEWAY]: 'requestErrors.server.native_bad_gateway',
  [types.NATIVE_SERVICE_UNAVAILABLE]: 'requestErrors.server.native_service_unavailable',
  [types.NATIVE_GATEWAY_TIMEOUT]: 'requestErrors.server.native_gateway_timeout',
  [types.NATIVE_HTTP_VERSION_NOT_SUPPORTED]: 'requestErrors.server.native_http_version_not_supported',
  [types.NATIVE_VARIANT_ALSO_NEGOTIATES]: 'requestErrors.server.native_variant_also_negotiates',
  [types.NATIVE_WEB_SERVER_IS_DOWN]: 'requestErrors.server.native_web_server_is_down',
  [types.NATIVE_CONNECTION_TIMED_OUT]: 'requestErrors.server.native_connection_timed_out',
};

const events = {};
const knownTypes = [];

for (const k in types) {
  events[k] = `error/${types[k]}`;
  messages[k] = messages[types[k]];
  knownTypes.push(types[k]);
}

export default {
  install(Vue) {
    Vue.prototype.$errors = {
      types,
      knownTypes,
      messages,
      events,
    };

    Vue.prototype.$error = events;
  },
};
