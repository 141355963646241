<template>
  <div class="export-report">
    <el-dropdown
      trigger="click"
      placement="bottom"
    >
      <slot>
        <ui-button icon="download" />
      </slot>

      <el-dropdown-menu
        slot="dropdown"
        class="dropdown-exportlink ddgroup"
      >
        <el-dropdown-item>
          <a
            :href="getExportLink('csv')"
            target="_blank"
          >
            <i class="fal fa-file-csv mr-4"></i>
            CSV
          </a>
        </el-dropdown-item>
        <el-dropdown-item>
          <a
            :href="getExportLink('xlsx')"
            target="_blank"
          >
            <i class="fal fa-file-spreadsheet mr-4"></i>
            XLSX
          </a>
        </el-dropdown-item>
      </el-dropdown-menu>
    </el-dropdown>
  </div>
</template>


<script>
export default {
  name: 'ExportReport',
  props: {
    query: {
      type: String,
      default: '',
    },
    exportLinks: {
      type: [Object, String],
      default: () => ({}),
    },
  },
  methods: {
    getExportLink(format) {
      return this.exportLinks[format] || `${this.query}&export_format=${format}&access_token=${encodeURIComponent(this.$store.state.auth.userToken)}`;
    },
  },
};
</script>
