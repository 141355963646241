<template>
  <section class="promo__codes-form-wrap">
    <modal :show.sync="active" @close="close">
      <h5 slot="header" class="modal-title">
        {{ $t('promoCodes.addPromocodes.title') }}
      </h5>
      <template>
        <div>
          <form @keyup.enter="send">
            <div class="form-group select-wrapper mb-8 mt-0">
              <input-wrap :message.sync="promocode_error" name>
                <label>{{ $t('promoCodes.addPromocodes.promocode') }}</label>
                <input
                  v-model="form.promocode"
                  :placeholder="$t('promoCodes.addPromocodes.promocode')"
                  autocomplete="off"
                  type="text"
                  class="form-control m-0 w-100"
                  @input="changed = false; promocode_error = ''"
                  @change="promocode_error = ''"
                >
              </input-wrap>
              <span v-if="promocode_error" class="text-danger text-left mt-2">
                {{ promocode_error }}
              </span>
            </div>
            <div v-if="hideSelect(offers)" class="select-wrapper mb-8">
              <label>{{ $t('promoCodes.addPromocodes.offer') }}</label>
              <v-select
                id="promocode.offer"
                key="id"
                v-model="offer"
                :options="offers"
                :disabled="false"
                class="el-input"
                label="name"
                @change="offerSelect=true"
              />
              <span v-if="!offerSelect && sending" class="text-danger text-left mt-2">
                {{ $t('promoCodes.addPromocodes.selectOffer') }}
              </span>
            </div>
            <div class="select-wrapper mb-8">
              <label>{{ $t('trafficSources.trafficSource') }}</label>
              <v-select
                id="promocode.traffic_source_id"
                key="id"
                v-model="traffic_source_id"
                :options="sources"
                :disabled="false"
                class="el-input"
                label="name"
              />
            </div>
            <div class="row row-wrap__subid">
              <div
                v-for="(item, i) in form.subids"
                :key="i"
                class="col-6 col-6__add-subid"
              >
                <fg-input
                  v-model="item.value"
                  :placeholder="$t('trafficSources.postbacks.notSpecified')"
                  class="no-border form-control-lg mb-0"
                >
                  <label slot="label" class="d-flex justify-content-between">
                    <span>SubID{{ form.subids.indexOf(item) + 1 }}</span>
                    <a
                      v-show="form.subids.indexOf(item) == form.subids.length - 1 && form.subids.length > 1"
                      href="#"
                      class="btn-cross"
                      @click.prevent="popSubIds"
                    >
                      <span>×</span>
                    </a>
                  </label>
                </fg-input>
              </div>
              <div class="col-6 d-flex align-items-end col-6__add-subid">
                <button
                  :disabled="form.subids.length >= 5"
                  href="#"
                  class="btn btn-block btn-outline-primary btn-sm mt-24 mb-0"
                  @click.prevent="form.subids.length < 5 && addSubId()"
                >
                  + {{ $t('promoCodes.addPromocodes.addAnotherSubid') }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </template>
      <div slot="footer" class="text-right d-flex align-items-center">
        <a class="btn btn-outline-primary btn-sm d-flex align-items-center justify-content-center" @click="close">
          {{ $t('promoCodes.addPromocodes.cancel') }}
        </a>
        <a class="btn btn-primary btn-sm d-flex align-items-center justify-content-center ml-3 pt-0 pb-0" @click="send">
          <span v-if="!$store.state.errors.loading">
            {{ $t('promoCodes.addPromocodes.title') }}
          </span>
          <i v-if="$store.state.errors.loading" class="fas fa-spinner spinner-hide"/>
        </a>
      </div>
    </modal>
  </section>
</template>

<script>
import { Select, Option } from 'element-ui';
import { Modal } from 'src/components';
import InputWrap from 'src/components/Form/InputWrap';
import VSelectMenu from 'src/components/VSelectMenu.vue';

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    Modal,
    InputWrap,
    VSelectMenu,
  },
  props: ['offsetParams'],
  data() {
    return {
      active: false,
      offer: '',
      changed: false,
      offerSelect: false,
      sending: false,
      promocode_error: false,
      traffic_source_id: '',
      form: {
        promocode: '',
        subids: [{ value: '' }],
      },
    };
  },
  computed: {
    invalid_promocode() {
      return this.changed && !this.form.promocode.trim();
    },
    offers() {
      return this.$store.state.promocode.offers.map(e => ({
        text: e.name,
        id: e.id,
      }))
        .concat({
          id: '',
          text: this.$t('promoCodes.addPromocodes.selectOffer'),
        });
    },
    sources() {
      return this.$store.state.trafficSource.list.map(e => ({
        id: e.data.id,
        text: e.name,
      }))
        .concat({
          id: '',
          text: this.$t('offerRequest.noTrafficSources'),
        });
    },
  },
  watch: {
    traffic_source_id(id) {
      this.form.subids = [];
      const source = this.$store.state.trafficSource.list.filter(e => e.data.id === id)[0];
      if (!source) {
        this.form.subids = [{ value: '' }];
        return;
      }

      // Push subids
      Object.keys(source)
        .map((k) => {
          if (k.startsWith('subid')) {
            const subid_index = parseInt(k.replace('subid', '')) - 1;
            if (source[k]) {
              this.form.subids.push({
                value: source[k],
              });
            } else {
              this.form.subids.push({
                value: '',
              });
            }
          }
        });

      // reduce subids
      for (let i = this.form.subids.length - 1; i >= 1; i--) {
        if (!this.form.subids[i].value) {
          this.form.subids.pop();
        } else {
          break;
        }
      }
    },
  },
  activated() {
    this.$store.dispatch('getTrafficSources', {});
    this.$store.dispatch('getPromocodeOffersLazy');
  },
  methods: {
    hideSelect(optoins = []) {
      return optoins.length > 2;
    },

    selectOffer(v) {
      this.offer = v.id;
      this.offerSelect = true;
    },
    open() {
      this.reset();
      this.active = true;
      if (this.offers.length === 2) {
        this.offer = this.offers[0].id;
        this.offerSelect = true;
      }
    },
    close() {
      this.active = false;
      this.offerSelect = false;
      this.traffic_source_id = '';
      this.promocode_error = '';
      this.sending = false;
      this.reset();
    },
    reset() {
      this.changed = false;
      this.form.promocode = '';
      this.offer = '';
      this.form.subids = [
        {
          value: '',
        },
      ];
    },
    send() {
      this.sending = true;
      this.changed = true;
      if (this.invalid_promocode) {
        this.promocode_error = this.$t('promoCodes.alerts.promocodeInvalid');
        return;
      }
      if (!this.offer) {
        this.offerSelect = false;
        return;
      }

      const data = {
        traffic_source_id: this.traffic_source_id,
        affiliate_offer_id: this.offer,
        promo_code: this.form.promocode,
        status: 'active',
      };

      this.form.subids.forEach((cur, i) => {
        data[`subid${parseInt(i) + 1}`] = cur.value;
      });

      if (data.affiliate_offer_id !== this.$t('promoCodes.addPromocodes.selectOffer')) {
        this.$store
          .dispatch('createPromocode', data)
          .then((res) => {
            this.close();
            this.$emit('addPromoCode');
            this.$store.dispatch('getPromocodesCount', { media_buying_campaign_id: 'null' });
            this.$store.dispatch('getPromocodesLazy', { ...this.offsetParams });
            this.$alert.success(this.$t('promoCodes.alerts.wasCreated'));
          })
          .catch((err) => {
            this.promocode_error = err.response.data.errors.promo_code[0].message;
          });
      }
    },
    addSubId() {
      this.form.subids.push({
        value: '',
      });
    },
    popSubIds(item) {
      this.form.subids.pop();
    },
  },
};
</script>

<style lang="scss">
@import 'src/assets/theme/default/promocodes/addpromocode';
</style>
