<template>
  <section>
    <connection-fail :active.sync="failConnect" />
    <loading ref="loading" />
    <toast-error ref="toastError" />
    <toast-notification
      ref="toastAction"
      :type-message="type"
      style="right: 10px; top: 70px;"
    />
  </section>
</template>

<script>
import swal from 'sweetalert2';
import ToastNotification from 'src/components/ToastNotification/ToastNotification.vue';
import ConnectionFail from './Dialogues/ConnectionFail';
import Loading from './Dialogues/Loading';
import ToastError from './Dialogues/ToastError';

export default {
  components: {
    ConnectionFail,
    Loading,
    ToastError,
    ToastNotification,
  },
  data() {
    return {
      failConnect: false,
      message: '',
      type: '',
    };
  },
  created() {
    this.$event.$on('connection-disabled', this.connectionFail);
    window.Vue.prototype.$alert = this;
  },

  methods: {
    loading(cb) {
      this.$refs.loading.open();
      if (cb) {
        cb(this.$refs.loading);
      }
    },
    closeLoading() {
      this.$refs.loading.close();
    },
    toastError(cb) {
      this.$refs.toastError.open();
      if (cb) {
        cb(this.$refs.toastError);
      }
    },
    close() {
      swal.close();
      this.$refs.loading.close();
    },
    fail(msg, cb) {
      setTimeout(() => {
        this.type = 'danger';
        this.$refs.toastAction.open(msg);
        try { if (cb)cb(); } catch (e) {}
      }, 1000);
    },
    error(msg) {
      this.fail.apply(this, [arguments[0], arguments[1]]);
    },
    warning(msg) {
      this.type = 'warning';
      this.$refs.toastAction.open(msg);
    },
    success(message) {
      this.type = 'success';
      this.$refs.toastAction.open(message);
    },
    confirm(message, cb) {
      swal({
        type: 'warning',
        text: message,
        background: 'rgba(255,255,255,0)',
        customClass: 'no-shadow',
        showCancelButton: true,
        showConfirmButton: true,
        confirmButtonText: 'Yes, I am surre!',
      }).then((result) => {
        if (result.value) {
          cb();
        }
      });
    },
    connectionFail() {
      this.failConnect = true;
    },
  },
};
</script>

<style>
.swal2-container.swal2-shown {
  background-color: rgb(0, 0, 0) !important;
  z-index: 1000000 !important;
}
.no-shadow {
  box-shadow: 0px 0px 0px transparent !important;
}
.no-shadow .swal2-content {
  color: #fff !important;
  font-weight: bold;
}

.swal2-popup .swal2-actions.swal2-loading .swal2-styled.swal2-confirm {
  width: 15em !important;
  height: 15em !important;
  margin: 0.46875em;
  padding: 0;
}
.swal2-icon.swal2-warning {
  font-size: 24px !important;
}
</style>
