export default {
  getItems({ commit }, filter = {
    search: '',
    language_code: '',
    site_id: '',
    type: '',
    limit: 10,
    offset: 0,
    sort_column: 'incrementing_id',
    sort_dir: 'desc',
  }) {
    return this.$api.getMarketingMaterials(filter)
      .then((data) => {
        commit('saveItems', data);
        return data;
      });
  },
  getCount({ commit }, filter = {
    search: '',
    language_code: '',
    site_id: '',
    type: '',
    limit: 10,
    offset: 0,
  }) {
    return this.$api.getMarketingMaterialsCount(filter)
      .then((data) => {
        commit('saveCount', data);
        return data;
      });
  },
  getMediaContent({ commit }, id) {
    return this.$api.getMarketingMaterial(id)
      .then((data) => {
        commit('saveMediaContent', data.payload);
        return data;
      });
  },
  getSites({ commit }) {
    return this.$api.getSites()
      .then((data) => {
        commit('saveSites', data.payload);
        return data;
      });
  },
};
