<template>
  <div :class="{'nav-open': $sidebar.showSidebar, 'iphone': iphone }" class="wrapper">
    <side-bar :background-color="$config.sidebar.theme" :logo-mini="require('@/assets/theme/icon/logo-mini.svg')">
      <template slot="links">
        <section v-for="menu in formatMenuBlock($config.sidebar.menus)" :key="menu.title">
          <h4 v-if="menu.title" class="sidebar-title">
            {{ $t(menu.title) }}
          </h4>
          <sidebar-item
            v-for="item in formatMenuItems(menu.links)"
            :key="item.title"
            :link="{name: $t(item.title), img: item.img, icon: item.icon, path: formatPath(item), active:true}"
          />
        </section>
        <li class="liveChat" :class="{'btn-success': $intercom.visible}" style="width: 100%" @click="toggleLivechat">
          <a class="nav-link">
            <i class="fas fa-comments" />
            <p>{{ $t("sidebar.menu.help.livechat") }}
              <span v-show="intercomUnreadCount" class="livechat-mail">
                {{ intercomUnreadCount }}
                <img :src="require('@/assets/theme/sidebar/envelope-icon.svg')" alt="envelope" />
              </span>
            </p>
          </a>
        </li>
        <div v-if="managerPublicProfile" class="public-manager">
          <el-tooltip
            :content="managerPublicProfile.name"
            placement="right"
          >
            <div class="title">
              <span>{{ managerPublicProfile.name }}</span>
              <i class="fas fa-headset"></i>
            </div>
          </el-tooltip>
          <div class="label">
            {{ $t('sidebar.menu.yourManager') }}
          </div>
          <div class="contact" v-for="item in managerPublicProfile.contacts">
            <ui-icon
              v-if="iconMap[item.type] && iconMap[item.type].icon"
              :name="item.type"
              :custom-class="iconMap[item.type].icon"
              :color="iconMap[item.type].color || '#fff'"
            />
            <img
              v-if="iconMap[item.type] && iconMap[item.type].img"
              :src="iconMap[item.type].img"
              alt=""
            />
            <el-tooltip
              :content="item.value"
              placement="right"
            >
              <a
                :href="getSocialLink(item.type, item.value)"
                :target="item.type === 'email' && '_blank'"
              >
                {{ item.value }}
              </a>
            </el-tooltip>
          </div>
          <div class="note">
            {{ managerPublicProfile.additional_information }}
          </div>
        </div>
      </template>
    </side-bar>
    <div class="main-panel">
      <top-navbar />
      <div :class="{content: !$route.meta.hideContent}" @click="toggleSidebar">
        <keep-alive>
          <router-view />
        </keep-alive>
      </div>
      <testbox v-if="$config.environment === 'test'" />
    </div>
  </div>
</template>

<script>
import PerfectScrollbar from 'perfect-scrollbar';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import Testbox from 'src/test/testbox';
import { SlideYDownTransition, ZoomCenterTransition } from 'vue2-transitions';
import lzString from 'lz-string';
import TopNavbar from './TopNavbar.vue';
import Tooltip from '@/components/Tooltip.vue';

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

export default {
  components: {
    Tooltip,
    TopNavbar,
    SlideYDownTransition,
    ZoomCenterTransition,
    Testbox,
  },
  data() {
    return {
      iphone: false,
      livechatOn: false,
      intercomReady: false,
      intercomUnreadCount: 0,
      iconMap: {
        email: {
          icon: 'fas fa-envelope',
        },
        skype: {
          icon: 'ui-icon skype-icon',
          color: '#4088f7',
        },
        telegram: {
          img: require('@/assets/theme/icon/telegram.svg'),
        },
      },
    };
  },
  computed: {
    pending() {
      return this.$store.state.auth.loginProgress;
    },
    managerPublicProfile() {
      return this.$store.state.settings.manager_info;
    },
  },
  watch: {
    $route(to) {
      localStorage.setItem('lastVisitedRoute', to.path);
      document.title = `${this.$t(to.name)} - ${this.$config.pageTitle}`;
    },
  },
  created() {
    if (localStorage.getItem('auth-token') !== 'null') {
      // const code = localStorage.getItem('language') || 'en';
      setTimeout(() => {
        this.$store.dispatch('loginSuccess');
      }, 1000);
      // this.$store.dispatch("getSettingsLazy");
      this.$store.dispatch('getTimezones');
      // this.$store.dispatch('saveProfileLanguage', {language_code: code});
      this.$store.dispatch('getCountries');
      this.$store.dispatch('getLanguages');
      this.$store.dispatch('getSites');
      this.$store.dispatch('getCurrencies');
      this.$store.dispatch('getPaymentMethods');
      this.$store.dispatch('getWallets');
      this.$store.dispatch('getDedicatedDomainCount');
      this.$store.dispatch('getPostPlatforms');
      this.$store.dispatch('getMessengers');
    }

    document.title = `${this.$t(this.$route.name)} - ${this.$config.pageTitle}`;
  },
  mounted() {
    if (/iPhone/i.test(navigator.userAgent)) {
      this.iphone = true;
      document.body.setAttribute('iphone', this.iphone);
    }

    const docClasses = document.body.classList;
    const isWindows = navigator.platform.startsWith('Win');

    if (isWindows) {
      initScrollbar('sidebar');
      initScrollbar('sidebar-wrapper');
      docClasses.add('perfect-scrollbar-on');
    } else {
      docClasses.add('perfect-scrollbar-off');
    }

    this.intercomUnreadCount = this.$intercom.unreadCoun;
  },
  methods: {
    getSocialLink(type, value) {
      switch (type) {
        case 'telegram':
          return `tg://resolve?domain=${value.replace('@', '')}`
        case 'skype':
          return `skype:${value.replace('live:.cid.', '')}?chat`
        case 'email':
          return `mailto:${value}`
        default:
          return ''
      }
    },
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },

    formatMenuBlock(menu) {
      return menu.filter((item) => {
        if (item.title === 'sidebar.menu.marketingTools.mediaBuying') {
          return this.$store.state.profile.user.media_buying_enabled;
        }
        return true;
      });
    },

    formatMenuItems(menu) {
      return menu.filter((item) => {
        if (!item.path) {
          return true;
        }

        if (item.title === 'sidebar.menu.marketingTools.dedicatedDomains') {
          return !!this.$store.state.domains.dedicatedDomainCount.count;
        }

        if (item.name === 'routes.subAffiliates') {
          return this.$store.state.profile.user.referral_program_enabled;
        } else if (item.name === 'routes.subAffiliatesReports') {
          return this.$store.state.profile.user.referral_program_enabled;
        } else if (item.name === 'routes.mediaBuying') {
          return this.$store.state.profile.user.media_buying_enabled;
        } else if (item.name === 'routes.androidApps') {
            return this.$store.state.settings.optional_sections.mobile_apps_available;
        } else {
          return true;
        }
      });
    },

    formatPath(item) {
      const { urlQueries } = this.$store.state.misc;
      if (urlQueries[item.name]) {
        const urlQuery = lzString.compressToEncodedURIComponent(JSON.stringify(urlQueries[item.name]));

        return `${item.path}/${urlQuery}`;
      } else {
        return item.path;
      }
    },
    toggleLivechat() {
      if (!this.$store.state.settings.intercom) {
        return;
      }

      if (!this.$store.state.settings.intercom.live_chat_available) {
        this.$sidebar.displaySidebar(false);
        this.$router.push({ name: 'routes.contacts' });
        return;
      }

      if (!this.$intercom.visible) {
        this.$intercom.show();
        this.intercomUnreadCount = 0;
      } else {
        this.$intercom.hide();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar-mini {
  .public-manager {
    display: none;
  }
}

.public-manager {
  width: 100%;
  margin: 12px 16px;
  padding: 16px;
  border-radius: 8px;
  background-color: #464d4b;
  color: #fff;
  overflow: hidden;

  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    font-weight: 600;

    span {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      margin-right: 2px;
    }

    i {
      font-size: 16px !important;
      margin-right: 0 !important;
      color: #fff !important;
    }
  }

  .note {
    font-size: 12px;
    margin-top: 8px;
    white-space: pre-wrap;
    overflow: hidden;
  }

  .label {
    height: 17px;
    font-size: 12px;
    margin-top: 2px;
  }

  .contact {
    display: flex;
    align-items: center;
    height: 19px;
    font-size: 14px;
    margin-top: 8px;

    a {
      overflow: hidden;
      text-overflow: ellipsis;
      color: #fff;

      &:hover {
        text-decoration: none;
      }
    }

    i, img {
      width: auto!important;
      margin-right: 4px!important;
      font-size: 16px!important;
    }
  }
}
</style>
<style lang="scss">
.fade-enter-active {
  transition: opacity 0.5s;
}

.fade-enter, .fade-leave-to, .fade-leave-active /*  in <2.1.8 */
{
  opacity: 0;
}

.tab-count {
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--color-text-red);
  color: #fff;
  font-weight: bold;
  font-size: 10px;
  height: 16px;
  min-width: 16px;
  padding: 0 2px;
  border-radius: 50%;
}

$scaleSize: 0.95;
@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  to {
    opacity: 1;
  }
}

.main-panel .zoomIn {
  animation-name: zoomIn95;
}

@keyframes zoomOut95 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.main-panel .zoomOut {
  animation-name: zoomOut95;
}
</style>
