<template>
  <section class="edit-postback">
    <modal id="modalId" :show.sync="active">
      <h5 slot="header" class="modal-title">
        <span v-show="!edit">{{ $t('postbacks.card.btnAdd') }}</span>
        <span v-show="edit">{{ $t('postbacks.card.editPostback') }}</span>
      </h5>
      <template>
        <div class="row border-bot">
          <div class="col-12 select-wrapper">
            <div v-if="hideSelect(offers)">
              <label>{{ $t('postbacks.card.offer') }}</label>
              <v-select
                key="form.affiliate_offer_id"
                v-model="form.affiliate_offer_id"
                :options="offers"
                class="el-input"
                label="name"
                @change="error_offer = false"
              />
              <div
                v-if="error_offer"
                class="text-danger text-right"
              >
                {{ $t('postbacks.postback.pleaseSelectOffer') }}
              </div>
            </div>
            <div
              class="request"
              :class="hideSelect(offers) && 'mt-14'"
            >
              <label>{{ $t('postbacks.card.type') }}</label>
              <div class="request__radio-wrapper">
                <div class="request__radio-one">
                  <input
                    id="get"
                    v-model="form.request_type"
                    type="radio"
                    name="radio-group"
                    value="get"
                  >
                  <label for="get">{{ $t('postbacks.postback.get') }}</label>
                </div>
                <div class="request__radio-two">
                  <input
                    id="post"
                    v-model="form.request_type"
                    type="radio"
                    name="radio-group"
                    value="post"
                  >
                  <label for="post">{{ $t('postbacks.postback.post') }}</label>
                </div>
              </div>
            </div>
            <div class="send mt-8">
              <label>{{ $t('postbacks.postback.send') }}</label>
              <div class="send__check-wrapper row">
                <div class="send__col-one col-6">
                  <div class="send__checkbox-item checkbox">
                    <input
                      id="checkbox-registrations"
                      v-model="form.events_to_send.registrations"
                      type="checkbox"
                    >
                    <label
                      class="checkbox-label"
                      :class="{'checkbox-check': form.events_to_send.registrations}"
                      for="checkbox-registrations"
                    >{{ $t('postbacks.postback.registrations') }}</label>
                  </div>
                  <div class="send__checkbox-item checkbox">
                    <input
                      id="checkbox-new-deposits"
                      v-model="form.events_to_send.new_deposits"
                      type="checkbox"
                    >
                    <label
                      class="checkbox-label"
                      :class="{'checkbox-check': form.events_to_send.new_deposits}"
                      for="checkbox-new-deposits"
                    >{{ $t('postbacks.postback.newdeposits') }}</label>
                  </div>
                  <div class="send__checkbox-item checkbox">
                    <input
                      id="checkbox-deposits-with-drow"
                      v-model="form.events_to_send.deposits_withdrawals"
                      type="checkbox"
                    >
                    <label
                      class="checkbox-label"
                      :class="{'checkbox-check': form.events_to_send.deposits_withdrawals}"
                      for="checkbox-deposits-with-drow"
                    >{{ $t('postbacks.postback.depositsWithDrow') }}</label>
                  </div>
                  <div class="send__checkbox-item checkbox">
                    <input
                      id="checkbox-first-deposits"
                      v-model="form.events_to_send.new_first_deposits"
                      type="checkbox"
                    >
                    <label
                      class="checkbox-label"
                      :class="{'checkbox-check': form.events_to_send.new_first_deposits}"
                      for="checkbox-first-deposits"
                    >{{ $t('postbacks.postback.first_deposits') }}</label>
                  </div>
                  <div class="send__checkbox-item checkbox">
                    <input
                      id="checkbox-chargebacks"
                      v-model="form.events_to_send.chargebacks"
                      type="checkbox"
                    >
                    <label
                      class="checkbox-label"
                      :class="{'checkbox-check': form.events_to_send.chargebacks}"
                      for="checkbox-chargebacks"
                    >{{ $t('postbacks.postback.chargebacks') }}</label>
                  </div>
                </div>

                <div class="send__col-two col-6">
                  <div class="send__checkbox-item checkbox">
                    <input
                      id="checkbox-new-bets"
                      v-model="form.events_to_send.new_bets"
                      type="checkbox"
                    >
                    <label
                      class="checkbox-label"
                      :class="{'checkbox-check': form.events_to_send.new_bets}"
                      for="checkbox-new-bets"
                    >{{ $t('postbacks.postback.newBets') }}</label>
                  </div>
                  <div class="send__checkbox-item checkbox">
                    <input
                      id="checkbox-bet-results"
                      v-model="form.events_to_send.bets_results"
                      type="checkbox"
                    >
                    <label
                      class="checkbox-label"
                      :class="{'checkbox-check': form.events_to_send.bets_results}"
                      for="checkbox-bet-results"
                    >{{ $t('postbacks.postback.betResults') }}</label>
                  </div>
                  <div class="send__checkbox-item checkbox">
                    <input
                      id="checkbox-rs_revenue"
                      v-model="form.events_to_send.rs_revenue"
                      type="checkbox"
                    >
                    <label
                      class="checkbox-label"
                      :class="{'checkbox-check': form.events_to_send.rs_revenue}"
                      for="checkbox-rs_revenue"
                    >{{ $t('offers.cardOffers.revshare') }}</label>
                  </div>
                  <div class="send__checkbox-item checkbox">
                    <input
                      id="checkbox-cpa_revenue"
                      v-model="form.events_to_send.cpa_revenue"
                      type="checkbox"
                    >
                    <label
                      class="checkbox-label"
                      :class="{'checkbox-check': form.events_to_send.cpa_revenue}"
                      for="checkbox-cpa_revenue"
                    >{{ $t('postbacks.postback.cpa') }}</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12">
            <div class="url-wrap mt-6">
              <label class="mb-2">{{ $t('postbacks.postback.url') }}</label>
              <token-input id="url"
                           :value="form.url"
                           :placeholder="$t('postbacks.postback.url')"
                           :tokens="tokens"
                           :multiline="false"
                           @input="onTokenInputChange"
              />
              <div v-if="error_url" class="text-danger text-right">
                {{ $t('postbacks.postback.urlIsRequired') }}
              </div>
            </div>
          </div>
        </div>
      </template>
      <div slot="footer" class="text-right">
        <div class="postback__btn-save-wrap">
          <button
            type="button"
            class="btn btn-primary btn-sm"
            @click="savePostback"
          >
            {{ $t('postbacks.postback.savePostback') }}
          </button>
        </div>
      </div>
    </modal>
  </section>
</template>

<script>
import {
  Select, Option, Input, Tag,
} from 'element-ui';
import { Modal, TokenInput } from 'src/components/index';

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Input.name]: Input,
    [Tag.name]: Tag,
    TokenInput,
    Modal,
  },
  props: ['offsetParams'],
  data() {
    return {
      active: false,
      error_url: false,
      error_offer: false,
      edit: false,
      form: {
        url: '',
        affiliate_offer_id: '',
        request_type: 'get',
        events_to_send: {
          registrations: false,
          new_deposits: false,
          deposits_withdrawals: false,
          new_bets: false,
          bets_results: false,
          cpa_revenue: false,
          rs_revenue: false,
          new_first_deposits: false,
          chargebacks: false,
        },
      },
      showTags: false,
    };
  },
  computed: {
    offers() {
      const offers = this.$store.state.offers.my;
      return offers.map(e => ({
        text: e.name,
        id: e.id,
      }))
        .concat({
          id: '',
          text: this.$t('promoCodes.addPromocodes.selectOffer'),
        });
    },
    tokens() {
      return this.$store.state.settings.postback_tokens;
    },
  },
  watch: {
    active() {
      this.form = {
        url: '',
        affiliate_offer_id: '',
        request_type: 'get',
        events_to_send: {
          registrations: false,
          new_deposits: false,
          deposits_withdrawals: false,
          new_bets: false,
          bets_results: false,
          cpa_revenue: false,
          rs_revenue: false,
          new_first_deposits: false,
          chargebacks: false,
        },
      };
      this.edit = false;
    },
  },
  activated() {
    this.form.traffic_source_id = this.$route.params.id;
  },
  methods: {
    hideSelect(optoins = []) {
      return optoins.length > 2;
    },

    open(edit) {
      this.active = true;
      this.error_url = false;
      if (edit) {
        this.edit = true;
        this.$store
          .dispatch('getPostback', {
            id: edit,
          })
          .then(() => {
            this.form = this.$store.state.trafficSource.postback;
            this.edit = true;
          });
      }
      if (!edit) {
        this.$nextTick(() => {
          if (this.offers.length === 2) {
            this.$set(this.form, 'affiliate_offer_id', this.offers[0].id);
          }
        });
      }
    },
    savePostback() {
      if (this.form.affiliate_offer_id.length < 30) {
        this.error_offer = true;
        const modal = $('#modalId');
        modal.animate({ scrollTop: 0 }, 300);
        return;
      }

      if (!this.form.url) {
        this.error_url = true;
        return;
      }
      this.form.traffic_source_id = this.$route.params.id;
      this.$store.dispatch((this.edit && 'updatePostback') || 'createPostback', this.form)
        .then(() => {
          this.$store.dispatch('getTrafficSourcePostbacksCount', { traffic_source_id: this.$route.params.id, ...this.offsetParams });
          this.$store.dispatch('getPostbacksLazy', { traffic_source_id: this.$route.params.id, ...this.offsetParams });
          this.active = false;
          this.$alert.success(this.$t('postbacks.postback.postbackSaved'));
        })
        .catch((err) => {
          if (err.response.data.errors.url) {
            this.$alert.error(err.response.data.errors.url[0].message);
          } else {
            this.$alert.error(this.$t('postbacks.postback.notSavePostback'));
          }
        });
    },
    onTokenInputChange(k, v) {
      this.form = {
        ...this.form,
        [k]: v,
      };
    },
  },
};
</script>

<style lang="scss">
.edit-postback {
  .el-select .el-input .el-input__inner {
    line-height: 18px;
  }

  .request__radio-wrapper {
    display: flex;

    label {
      font-weight: normal !important;
      padding-left: 20px !important;
    }
  }

  .url-wrap > label {
    margin-bottom: 6px;
    font-weight: 600;
    font-size: 13px;
  }

  .no-border.el-textarea {
    height: 100%;

    .el-textarea__inner {
      border-radius: 8px;
    }
  }

  .send__check-wrapper {
    .checkbox-label {
      padding-left: 22px !important;
      text-transform: none;
      color: #303634 !important;
      font-weight: normal;

      &:before {
        top: 1px !important;
      }

      &:after {
        width: 16px !important;
        height: 16px !important;
        top: 2px !important;
        left: 1px !important;
      }

      &.checkbox-check:after {
        border: 1px solid transparent !important;
      }
    }
  }
}
</style>
