<template>
  <transition name="fade">
    <div
      v-if="isOpen"
      class="ui-popup"
    >
      <div class="wrapper">
        <div class="head">
          <div class="title">
            <slot name="title">
              {{ title }}
            </slot>
          </div>
          <span class="close" @click="close(); $emit('cancel');">X</span>
        </div>
        <div class="body">
          <slot/>
        </div>
        <div class="controls">
          <slot name="controls">
            <button class="btn btn-outline-danger btn-sm" @click="reset">
              {{ $t(`customReports.resetDefaults`) }}
            </button>
            <div>
              <button class="btn btn-outline-primary btn-sm" @click="cancel">
                {{ $t('alerts.dataTable.cancel') }}
              </button>
              <button
                class="btn btn-primary btn-sm ml-16"
                :disabled="!selected.length"
                @click="save"
              >
                {{ $t('alerts.dataTable.apply') }}
              </button>
            </div>
          </slot>
        </div>
      </div>
      <div class="overlay"/>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'UiPopup',
  props: {
    title: {
      type: String,
      default: 'Title',
    },
    fixed: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 555,
    },
    actionName: {
      type: String,
      default: 'Save',
    },
    selected: {
      type: Array,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  created() {

  },
  mounted() {
    window.addEventListener('keyup', this.escHandler);
  },
  beforeDestroy() {
    window.removeEventListener('keyup', this.escHandler);
  },
  methods: {
    open() {
      this.isOpen = true;
      this.$emit('open');
    },
    close() {
      this.isOpen = false;
      this.$emit('close');
    },
    save() {
      this.$emit('save');
    },
    cancel() {
      this.$emit('cancel');
    },
    reset() {
      this.$emit('reset');
    },
    escHandler(e) {
      if (e.key === 'Escape' && this.isOpen) {
        this.close();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ui-popup{
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000000;
  &.fade-enter-active, &.fade-leave-active{
    transition: all .4s;
    .wrapper{
      transition: all .4s;
    }
  }
  &.fade-enter, &.fade-leave-to{
    opacity: 0;
    .wrapper{
      transform: translateY(20px);
    }
  }
  .wrapper{
    display: flex;
    flex-direction: column;
    z-index: 902;
    top: 12vh;
    border-radius: 8px;
    background-color: white;
    box-sizing: border-box;
    overflow: hidden;
    .head{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 24px 0 10px;
      margin: 0 24px;
      border-bottom: 1px solid #e7ecf1;
      .title{
        font-size: 16px;
        font-weight: 600;
        line-height: 28px;
        color: #303634;
      }
      .close{
        color: #2d2d2d;
        opacity: 1;
        font-size: 20px;
        cursor: pointer;
      }
    }
    .body{
      padding: 0 24px;
    }
    .controls{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 16px 0 24px;
      margin: 0 24px;
      border-top: 1px solid #e7ecf1;
      .btn-outline-primary{
        min-width: 100px;
      }
      .btn-primary{
        min-width: 196px;
      }
    }
  }
  .overlay{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0.5;
    background-color: #303634;
    z-index: 901;
  }
}
</style>
