<template>
  <transition name="fade">
    <div
      v-show="isContextOpen"
      ref="context"
      :style="{
        left: `${contextLeft}px`,
        top: `${contextTop}px`,
      }"
      class="table-context-menu"
    >
      <div
        :class="{'context-tab-disabled': disabledActions.drilldown}"
        class="item"
        @click="handleReportAction('=')"
      >
        <ui-icon class="context-icon" name="drilldown" />
        <span>{{ $t('customReports.drilldown') }}</span>
      </div>
      <div
        :class="{'context-tab-disabled': disabledActions.exclude}"
        class="item"
        @click="handleReportAction('!=')"
      >
        <span>
          <i class="fas fa-page-break mr-2"></i>
          {{ $t('customReports.exclude') }}
        </span>
      </div>
      <a
        class="item"
        :href="getExportLink('csv')"
        @click="hideContextMenu"
      >
        <i class="fal fa-file-csv ml-2 mr-8"></i>
        {{ $t('customReports.exportCsv') }}
      </a>
      <a
        class="item"
        :href="getExportLink('xlsx')"
        @click="hideContextMenu"
      >
        <i class="fal fa-file-spreadsheet ml-2 mr-8"></i>
        {{ $t('customReports.exportXlsx') }}
      </a>
    </div>
  </transition>
</template>

<script>
export default {
  props: {
    disabledActions: {
      type: Object,
      default: () => ({}),
    },
    exportUrls: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      isContextOpen: false,
      contextTop: 0,
      contextLeft: 0,
    };
  },
  async mounted() {
    document.addEventListener('click', this.clickOutside, false);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.clickOutside, false);
  },
  methods: {
    clickOutside(e) {
      if (this.$refs.context && !this.$refs.context.contains(e.target)) {
        this.hideContextMenu();
      }
    },
    showContextMenu(event) {
      this.contextLeft = event.clientX;
      this.contextTop = event.clientY;
      this.isContextOpen = true;
    },
    hideContextMenu() {
      this.isContextOpen = false;
    },
    getExportLink(format) {
      return (this.exportUrls || {})[format];
    },
    handleReportAction(op) {
      this.$emit('before-create-report', null, op);
      this.hideContextMenu();
    },
  },
};
</script>
