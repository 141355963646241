var render = function render(){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"ui-input",class:[
    _vm.size,
    {'search': _vm.config.isSearch},
    {'borderless': _vm.config.borderless},
    {'disabled': _vm.config.disabled},
    {'required': _vm.config.required},
    {'clearable': _vm.config.clearable},
    {'focus': _vm.focused},
    {'filled': !!_vm.currentValue},
    {'error': _vm.currentError},
    {'with-icon': _vm.config.preIcon || _vm.config.postIcon},
  ],style:({ width: _vm.getInputWidth }),on:{"mouseenter":function($event){_vm.hovered = true},"mouseleave":function($event){_vm.hovered = false}}},[(_vm.config.label)?_c('span',{staticClass:"label-text"},[_vm._v(_vm._s(_vm.config.label))]):_vm._e(),_c('div',{staticClass:"input-wrapper"},[(_vm.config.preIcon)?_c('ui-icon',{staticClass:"input-pre-icon",attrs:{"name":_vm.config.preIcon,"size":_vm.getIconSize,"color":_vm.config.isSearch ? '#747474' : ''}}):_vm._e(),_c('input',{ref:"input",staticClass:"input-element",attrs:{"tabindex":_vm.config.tabindex,"placeholder":_vm.config.placeholder,"readonly":_vm.config.readonly,"type":_vm.config.type || 'text',"disabled":_vm.config.disabled},domProps:{"value":_vm.currentValue},on:{"input":_vm.handleInput,"focus":_vm.handleFocus,"blur":_vm.handleBlur,"change":_vm.handleChange,"keyup":_vm.keyup}}),(_vm.config.clearable)?_c('div',{staticClass:"input-post-icon clear-btn",class:{'show-clear': _vm.showClear},on:{"click":_vm.clear}},[_c('span',[_vm._v("×")])]):(_vm.config.postIcon)?_c('ui-icon',{staticClass:"input-post-icon",attrs:{"name":_vm.config.postIcon,"size":_vm.getIconSize}}):_vm._e()],1),(_vm.currentError)?_c('span',{staticClass:"input-error"},[_vm._v(_vm._s(_vm.$t(`validation.errors.${_vm.currentError}`)))]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }