<template>
  <div
    :class="[
      'table-card-search-header',
      'ui-mobile-12'
    ]"
  >
    <div
      :class="{
        'table-card-search-header__content': true,
        'table-card-search-header--content-mobile': showActionsMobile,
        'ui-g-w-md': true,
        'ui-mobile-12': true
      }"
    >
      <div
        :class="[
          'table-card-search-header__search-wrapper',
          'form-group',
          'form-groups',
          'ui-mobile-12',
          'ui-g-md'
        ]"
      >
        <i
          :class="[
            'table-card-search-header__search-icon',
            'fa',
            'fa-search'
          ]"
          aria-hidden="true"
        />
        <input
          class="table-card-search-header__input-search"
          :value="pValue"
          :placeholder="pPlaceholder"
          autocomplete="off"
          type="text"
          @input="setValue($event.target.value)"
          @keyup.enter="search"
        >
        <span
          v-if="pValue"
          class="table-card-search-header__clear-icon-wrapper"
          @click="clearInput"
        >
          ×
        </span>
      </div>

      <div
        v-if="hasActions"
        :class="{
          'table-card-search-header__actions': true,
          'table-card-search-header__actions--show-mobile': showActionsMobile,
          'ui-g-md': true
        }"
      >
        <slot name="actions"></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TableCardSearchHeader',
  props: {
    value: [Number, String],
    placeholder: String,
    showActionsMobile: Boolean,
  },
  data() {
    return {
      pValue: '',
    };
  },
  computed: {
    hasActions() {
      return !!this.$slots.actions;
    },
    pShowActionsMobile() {
      return this.showActionsMobile || this.showActionsMobile === '';
    },
    pPlaceholder() {
      return this.placeholder || `${this.$t('promoCodes.promocodes.search')}...`;
    },
  },
  watch: {
    value: {
      immediate: true,
      handler(value) {
        this.pValue = value;
      },
    },
  },
  methods: {
    clearInput() {
      this.setValue('');
      this.search();
    },
    setValue(value) {
      this.pValue = value;
      this.$emit('input', value);
    },
    search() {
      this.$emit('search', this.pValue);
    },
  },
};
</script>

<style lang="scss">
  .table-card-search-header {
    display: flex;
    // width: 100%;

    &__content {
      align-items: baseline;
    }

    &__search-icon {
      position: absolute;
      left: 14px;
      top: 16px;
      color: #a1a1a1;
      font-size: 12px;
    }

    &__search-wrapper {
      flex-grow: 1;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }

    &__input-search {
      padding-left: 32px;
      padding-right: 24px;
      width: 100%;
      margin-top: 0 !important;
    }

    &__clear-icon-wrapper {
      position: absolute;
      //+1px border
      top: 4px;
      height: 32px;
      right: 14px;

      display: flex;
      align-items: center;
      border: none;
      font-weight: 500;
      color: #999999;
      margin-top: 0;
      font-size: 22px;
      cursor: pointer;
      user-select: none;
      line-height: 34px;
    }

    &__clear-icon {
      font-size: 14px;
    }

    &__actions {
      display: flex;
      // margin-left: 8px;

      &--show-mobile {
        display: none;
      }
    }

    @media (max-width: 650px) {
      width: 100%;
      // margin-bottom: 10px;

      &__content {
        flex-wrap: wrap;

        &--mobile {
          flex-wrap: nowrap;
          width: auto;
          flex-grow: 0;
        }
      }

      &__actions {
        margin-left: 0;
        flex-wrap: wrap;
        width: 100%;

        &--show-mobile {
          display: block;
          flex-grow: 0;
          width: auto;
        }
      }
    }
  }
</style>
