<template>
  <div class="ui-filters">
    <el-popover
      placement="bottom"
      trigger="manual"
      v-model="filtersOpen"
      width="325"
      popper-class="ui-filters_wrapper"
    >
      <div
        class="btn btn-refresh btn-sm d-flex"
        :class="{ 'btn-primary': filtersOpen || activeFilter,
                  'btn-outline-primary': !filtersOpen && !activeFilter }"
        slot="reference"
        ref="mainBtn"
        @click="toggleFilters"
      >
        <i class="fas fa-filter mr-4"></i>
        {{ $t('ui.filters.inner.filters') }}
        <span v-if="activeFilter" class="length-filter pl-2">({{ filterLength }})</span>
      </div>

      <div
        ref="pop"
        class="ui-filters_pop"
      >
        <div
          v-if="isMobile"
          class="ui-filters_title"
        >
          <div class="cross" @click="toggleFilters">
            ×
          </div>{{$t('filters.filter')}}
          <div
            v-if="activeFilter && isMobile"
            class="reset-filters text-red ml-8"
            @click="resetFilters"
          >
            {{$t('filters.resetFilters')}}
          </div>
        </div>
        <ui-filters-item
          v-for="(item, id) in currentFilter"
          :key="id"
          :filter-element="item"
        />
        <div class="d-flex pl-16 pr-16 mb-16 pt-16 filter-buttons">
          <button
            :disabled="!activeFilter"
            class="btn btn-primary btn-refresh w-100"
            @click="applyFilters()"
          >
            <span class="before-icon">{{$t('filters.applyFilters')}}</span>
          </button>
          <button
            v-if="activeFilter && !isMobile"
            class="btn btn-outline-danger btn-sm ml-8 w-100"
            @click="resetFilters"
          >
            <span class="text-red">×</span>
            {{$t('filters.resetFilters')}}
          </button>
        </div>
      </div>
    </el-popover>
    <div
      v-if="activeFilter && !isMobile"
      class="reset-filters text-red ml-8"
      @click="resetFilters"
    >
      {{$t('filters.resetFilters')}}
    </div>
  </div>
</template>

<script>

import UiFiltersItem from 'src/components/UiFiltersItem/UiFiltersItem.vue';
import ResponsiveMixin from 'src/components/Helpers/responsive-mixin.js';

export default {
  name: 'UiFilters',

  components: {
    UiFiltersItem,
  },

  mixins: [
    ResponsiveMixin,
  ],

  props: {
    useFilters: {
      type: Array,
      default() {
        return [
          'traffic_source_id',
          'country_code',
          'player_id',
          'affiliate_offer_id',
          'subid1',
          'subid2',
          'subid3',
          'subid4',
          'subid5',
        ];
      },
    },
    filters: {
      type: Object,
      default: () => ({}),
    },
    route: {
      type: String,
      default: () => (''),
    },
  },

  data() {
    return {
      filtersOpen: false,
      allFilters: {},
    };
  },

  watch: {
    allFiltersMeta: {
      immediate: true,
      deep: true,
      handler(value) {
        this.allFilters = {
          ...this.allFilters,
          ...value,
        };
      },
    },
  },

  computed: {
    statusFilters() {
      return ['', 'draft', 'on_review', 'for_rework', 'approved', 'declined'].map(type => ({
        id: type,
        text: this.$t(`mediaBuying.posts.status.${type === '' ? 'all_status' : type}`),
      }));
    },

    typeFilters() {
      return ['', 'publication', 'cancellation'].map(type => ({
        id: type,
        text: this.$t(`mediaBuying.posts.type.${type === '' ? 'all_type' : type}`),
      }));
    },

    allFiltersMeta() {
      return {
        type: {
          type: 'type',
          field: 'type',
          value: 'all',
          title: this.$t('filters.type.title'),
          options: [
            {
              id: 'all',
              text: this.$t('filters.type.all'),
            },
            {
              id: 'landing',
              text: this.$t('filters.type.landing'),
            },
            {
              id: 'html5_banner',
              text: this.$t('filters.type.html5_banner'),
            },
            {
              id: 'simple_banner',
              text: this.$t('filters.type.simple_banner'),
            },
          ],
        },
        language: {
          type: 'language',
          field: 'language_code',
          value: 'all',
          title: this.$t('filters.language.title'),
          options: this.languages,
        },
        brand: {
          type: 'brand',
          field: 'site_id',
          value: 'all',
          title: this.$t('filters.brand.title'),
          options: this.brands,
        },
        visibility: {
          type: 'visibility',
          field: 'visibility',
          title: this.$t('filters.visibility.title'),
          value: 'all',
          options: [
            {
              id: 'all',
              text: this.$t('filters.visibility.all'),
            },
            {
              id: 'public',
              text: this.$t('filters.visibility.public'),
            },
            {
              id: 'private',
              text: this.$t('filters.visibility.private'),
            },
          ],
        },

        typePosts: {
          type: 'typePosts',
          field: 'typePosts',
          value: '',
          title: this.$t('filters.type.title'),
          options: this.typeFilters,
        },

        statusPosts: {
          type: 'statusPosts',
          field: 'statusPosts',
          value: '',
          title: 'statusPosts',
          options: this.statusFilters,
        },
      };
    },

    languages() {
      const languages = this.$store.state.misc.languages.map(l => ({
        id: l.code,
        text: l.name,
      }));

      return [{
        id: 'all',
        text: this.$t('filters.language.all'),
      }, ...languages];
    },
    brands() {
      const brands = this.$store.state.media.sites.map(e => ({
        id: e.id,
        text: e.name,
      }));
      return [{
        id: 'all',
        text: this.$t('filters.brand.all'),
      }, ...brands];
    },
    currentFilter() {
      return this.initFilters();
    },
    filterLength() {
      return this.currentFilter.filter(e => e.value !== '' && e.value !== 'all').length;
    },

    activeFilter() {
      return this.filterLength > 0;
    },
  },

  mounted() {
    document.addEventListener('click', this.clickOutside, true);
  },

  destroyed() {
    document.removeEventListener('click', this.clickOutside, true);
  },

  methods: {
    initFilters() {
      const filteredArray = this.$_.filter(this.allFilters, item => this.useFilters.indexOf(item.type) !== -1);
      filteredArray.forEach((item) => {
        if (this.filters !== null) {
          item.value = this.filters[item.field];
        } else {
          item.value = 'all';
        }
      });
      return filteredArray;
    },
    toggleFilters() {
      if (this.filtersOpen) {
        this.initFilters();
      }
      this.filtersOpen = !this.filtersOpen;
    },

    initDictionaries() {
      this.allFilters.brand.options = this.brands;
      this.allFilters.language.options = this.languages;
    },

    clickOutside(e) {
      const target = this.$refs.mainBtn.contains(e.target) || e.target.className === 'select2-search__field' || e.target.className === 'add-placeholder';
      if (target) return;
      if (!this.$refs.pop.contains(e.target) && this.filtersOpen === true) {
        this.currentFilter.forEach((item) => {
          if (this.filters !== null) {
            item.value = this.filters[item.field];
          } else {
            item.value = 'all';
          }
        });
        this.toggleFilters();
      }
    },

    applyFilters() {
      const val = {};
      this.$_.forEach(this.currentFilter, (filter) => {
        this.$_.assign(val, { [filter.field]: filter.value });
      });
      this.$emit('input', val);
      this.toggleFilters();
    },
    resetFilters() {
      this.$emit('reset');
      this.initFilters();
      this.filtersOpen = false;
    },
  },
};
</script>


<style lang="scss">
  @import 'src/assets/theme/default/components/UiFilters';
</style>
