export default () => ({
  list: [],
  item: {},
  postbacks: [],
  postback: {},
  tags: [],
  tag: {},
  tagsCount: 0,
  totalRows: 0,
  totalPostbacksRows: 0,
  setUrl: '',
  postbacksRequestsLog: '',
  postbacksRequestsLogCount: '',
  postbacksResponseUrl: '',
});
