export default {
  saveTrafficSources(state, payload) {
    state.list = payload.map(obj => ({
      created_at: obj.created_at,
      name: obj.name,
      name_ts: obj.name,
      postbacks: obj.postbacks_count || 'No',
      data: {
        id: obj.id,
        extid: obj.external_id,
      },
      subid1: obj.subid1,
      subid2: obj.subid2,
      subid3: obj.subid3,
      subid4: obj.subid4,
      subid5: obj.subid5,
    }));
  },
  saveTrafficSourceCount(state, payload) {
    state.totalRows = payload;
  },
  saveSubAffiliateTrafficSources(state, payload) {
    state.list = payload.map(obj => ({
      name: obj.name,
      postbacks: obj.postbacks_count || 'No',
      data: {
        id: obj.id,
        extid: obj.external_id,
      },
      subid1: obj.subid1,
      subid2: obj.subid2,
      subid3: obj.subid3,
      subid4: obj.subid4,
      subid5: obj.subid5,
    }));
  },
  saveTrafficSource(state, payload) {
    state.item = payload;
  },
  savePostback(state, payload) {
    state.postback = payload;
  },
  savePostbacks(state, payload) {
    state.postbacks = payload;
  },
  saveTrafficSourcePostbacksCount(state, payload) {
    state.totalPostbacksRows = payload;
  },
  saveTrafficSourcePostbackRequestsLog(state, payload) {
    state.postbacksRequestsLog = payload;
  },
  saveTrafficSourcePostbackResponseUrl(state, payload) {
    state.postbacksResponseUrl = payload;
  },
  saveTrafficSourcePostbackRequestsLogCount(state, payload) {
    state.postbacksRequestsLogCount = payload;
  },
  saveTags(state, payload) {
    state.tags = payload;
  },
  saveTagsCount(state, payload) {
    state.tagsCount = payload.count;
  },
  saveTag(state, payload) {
    state.tag = payload;
  },
};
