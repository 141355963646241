var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"player-report daily-report",class:{
    wavebg: _vm.loading,
    mediaSAid: _vm.mediaSAid
  }},[_c('table-card',{ref:"datatable",attrs:{"loading":_vm.loading,"columns":_vm.getTableFields,"hide-columns":_vm.hiddenFields,"sort":_vm.sorting,"page":_vm.page,"page-size":_vm.limit,"rows":_vm.data,"totals":_vm.totals,"count":_vm.count,"i18n-path":"reports","tablet-layout":"","condenced":"","show-total":"","custom-context":"","height-fix":""},on:{"update:sort":function($event){_vm.sorting=$event},"update:page":function($event){_vm.page=$event},"update:pageSize":function($event){_vm.limit=$event},"update:page-size":function($event){_vm.limit=$event},"filter":_vm.handleFilter,"change-range":_vm.fetchDataTable,"fetch-data":_vm.fetchDataTable}},[_c('template',{slot:"header"},[(_vm.group_by !== 'date_formatted')?_c('div',{class:[
          'filter-search__wrap',
          'filter-search__wrap-back',
          'pb-0',
          'align-items-center'
        ]},[_c('ui-button',{on:{"click":_vm.back}},[_vm._v(" < "+_vm._s(_vm.$t("profile.buttons.back"))+" ")]),_c('b',{staticClass:"ml-8 mr-8"},[_vm._v(_vm._s(_vm.$moment(_vm.query.from).format('DD MMMM YYYY'))+" ")])],1):_vm._e(),(_vm.group_by === 'date_formatted')?_c('period-wrapper',{staticClass:"ui-g-md ui-mobile-12",attrs:{"settings":_vm.period},on:{"update":function($event){_vm.period = $event},"change":_vm.fetchDataTable}}):_vm._e(),_c('filter-reports',{key:_vm.countFilter,staticClass:"ui-g-md ui-mobile-12",attrs:{"value":_vm.filters,"from":_vm.period.from,"to":_vm.period.to,"use-filters":_vm.useFilters},on:{"input":_vm.updateFilters,"changeSAid":v => _vm.mediaSAid = v}})],1),_c('div',{staticClass:"ui-d-flex ui-mobile-hide",attrs:{"slot":"actionsPrepend"},slot:"actionsPrepend"},[_c('export-report',{staticClass:"ui-g-md",attrs:{"export-links":_vm.exportUrls}}),_c('div',{staticClass:"ui-g-md"},[_c('ui-button',{attrs:{"icon":"eye","icon-size":9},on:{"click":_vm.openColumnsPopup}})],1),_c('div',{staticClass:"ui-g-md"},[_c('ui-button',{attrs:{"icon":"refresh"},on:{"click":_vm.fetchDataTable}})],1)],1),_c('template',{slot:"headerPeriodPickerActions"},[_c('export-report',{class:[
          'ui-tablet-hide',
          'ui-desktop-hide',
          'ui-g-md'
        ],attrs:{"export-links":_vm.exportUrls}}),_c('div',{class:[
          'ui-tablet-hide',
          'ui-desktop-hide',
          'ui-g-md'
        ]},[_c('ui-button',{attrs:{"icon":"refresh"},on:{"click":_vm.fetchDataTable}})],1)],1)],2),_c('ui-columns-select',{ref:"columnsPopup",attrs:{"groups":_vm.columns_groups,"default":_vm.default_cols,"supported":_vm.supportedMetrics,"metrics":_vm.getMetrics},on:{"changed":_vm.fetchDataTable},model:{value:(_vm.selectedColumns),callback:function ($$v) {_vm.selectedColumns=$$v},expression:"selectedColumns"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }