export default {
  getDedicatedDomain({ commit }, params = {}) {
    return this.$api.getDedicatedDomain(params).then((data) => {
      commit('saveDedicatedDomain', data.payload);
      commit('saveDedicatedDomainCount', data.misc);
      return data;
    });
  },

  getDedicatedDomainCount({ commit }, params = {}) {
    return this.$api.getDedicatedDomainCount(params).then((data) => {
      commit('saveDedicatedDomainCount', data.payload);
      return data;
    });
  },

  putDedicatedDomain({}, { id, params }) {
    return this.$api.putDedicatedDomain(id, params).then(data => data);
  },
};
