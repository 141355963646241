<template>
  <section class="request-log">
    <table-card
      ref="datatable"
      class="ui-flex-grow"
      :loading="loading"
      :columns="columns"
      :sort.sync="sorting"
      :sort-by-name="sortByName"
      :sort-dir-name="sortDirName"
      :page.sync="page"
      :page-size.sync="pageSize"
      :rows="rows"
      :count="$store.state.trafficSource.postbacksRequestsLogCount.count"
      :formaters="formaters"
      i18n-path="reports"
      dont-fill-empty
      extend-height
      :row-selectable="false"
      :show-total="false"
      :period="period"
      @set-range="period = $event"
      @fetch-data="fetchDataTable"
    >
      <div
        slot="header"
        class="ui-g-md ui-mobile-12"
      >
        <a
          :class="[
            'btn',
            'btn-outline-primary',
            'btn-sm',
            'ui-mobile-12'
          ]"
          @click="back"
        >
          &lt; {{ $t('profile.buttons.back') }}
        </a>
      </div>

      <div
        slot="actionsPrepend"
        class="ui-d-flex ui-mobile-12"
      >
        <export-report
          class="ui-g-md"
          :export-links="$store.state.trafficSource.postbacksResponseUrl"
        >
          <ui-button class="btn btn-download">
            <i class="fas fa-file-download"></i>
          </ui-button>
        </export-report>

        <period-wrapper
          class="ui-g-md ui-mobile-12"
          :settings="period"
          @update="period = $event"
          @change="fetchDataTable"
        />
      </div>
    </table-card>
  </section>
</template>

<script>
import Mobtable from 'src/components/Mobiletable/Mobtable';
import Mobpagination from 'src/components/Mobiletable/MobPagination';
import SortBy from 'src/components/SortBy.vue';
import Formaters from 'src/views/Dashboard/CustomReports/formaters';
import PeriodWrapper from 'src/views/Dashboard/Reports/Filters/PeriodWrapper.vue';
import InjectPlugins from 'src/components/Report/Components/inject-plugins';
import UrlMixin from 'src/components/Query/url-mixin';
import UrlMixinHooks from 'src/components/Query/url-mixin-hooks';
import TableCard from 'src/components/Cards/TableCard.vue';
import { pageSizeMixin, resolvePageSize } from 'src/views/Dashboard/Reports/page_size.js';
import tableFlowMixinGenerator, { getDefaultPeriod } from 'src/views/Dashboard/Reports/tableFlowMixin.js';
import ExportReport from 'src/views/Dashboard/Reports/ExportReport.vue';

const sizes = {
  1080: 20,
  1440: 30,
};
const viewName = '/request-log';
const pageLimitProp = 'pageSize';
const pageSize = resolvePageSize(viewName, {
  _default: 15,
  sizes,
});

const defaultParams = {
  totalRows: 0,
  pageSize,
  page: 1,
  sort_dir: 'desc',
  sort_column: 'timestamp',
  lastVisitedRoute: '',
};

const defaultPeriod = getDefaultPeriod();

const fetch = (ctx, params) => {
  const period = ctx.period || params.period || defaultPeriod;

  const log = () => ctx.$store.dispatch('getTrafficSourcePostbackRequestsLog', {
    id: ctx.$route.params.id,
    traffic_source_id: ctx.$route.query.traffic_source_id,
    affiliate_offer_id: ctx.$route.query.affiliate_offer_id,
    from: period.from,
    to: period.to,
    sort_column: params.sort_column || defaultParams.sort_column,
    ...params,
    sort_dir: params.sort_dir || ctx.sort_dir || 'desc',
    offset: params.offset || ctx.offset || 0,
    limit: params.limit || ctx.pageSize || pageSize,
  });

  const count = () => ctx.$store.dispatch('getTrafficSourcePostbackRequestsLogCount', {
    id: ctx.$route.params.id,
    from: period.from,
    to: period.to,
    ...params,
  });

  return Promise.all([count(), log()]);
};

const tableFlowMixin = tableFlowMixinGenerator({
  defaultParams: {
    ...defaultParams,
  },
  params: Object.keys(defaultParams),
  requestOmit: ['lastVisitedRoute'],
  fetch,
  windowRefreshHook: true,
  period: 'param',
  sortByName: 'sort_column',
  sortDirName: 'sort_dir',
});

export default {
  fetch,
  components: {
    PeriodWrapper,
    Mobtable,
    Mobpagination,
    SortBy,
    TableCard,
    ExportReport,
  },
  mixins: [
    UrlMixinHooks,
    UrlMixin,
    InjectPlugins,
    pageSizeMixin,
    tableFlowMixin,
  ],
  props: ['fetchedData', 'passedParams'],
  data() {
    return {
      viewName,
      pageLimitProp,
      traffic_source_id: '',
      affiliate_offer_id: '',
      report_data: [],
      loading: false,
      loadingError: false,
      pagination: true,
      totalCount: 0,
      formaters: Formaters,
      columns: [
        {
          name: 'request_id',
          align: 'left',
          filterable: false,
          sortable: false,
        },
        {
          name: 'attempt',
          align: 'center',
          filterable: false,
          sortable: false,
        },
        {
          name: 'timestamp',
          align: 'left',
          filterable: false,
          sortable: 'custom',
          format: 'date-time-sec',
        },
        {
          name: 'request_type',
          align: 'center',
          filterable: false,
          sortable: false,
        },
        {
          name: 'url',
          align: 'center',
          filterable: false,
          sortable: false,
        },
        {
          name: 'response_http_code',
          align: 'center',
          filterable: false,
          sortable: false,
        },
      ],
      lastQuery: {},
      lastVisitedRoute: '',
      unwatch: Function,
    };
  },
  computed: {
    rows() {
      const map = this.$store.state.trafficSource.postbacksRequestsLog.map((e) => {
        e.request_type = (e.request_type || 'get').toUpperCase();
        return e;
      });
      return map;
    },
  },
  watch: {
    '$i18n.locale': function () {
      if (this.isComponentActivated) {
        this.fetchDataTable(this.lastQuery);
      }
    },
  },
  created() {
    if (this.$route.query.traffic_source_id) {
      this.lastVisitedRoute = localStorage.getItem('lastVisitedRoute');
    }
    this.setFromQuery(this.passedParams);
  },

  activated() {
    this.traffic_source_id = this.$route.query.traffic_source_id ? this.$route.query.traffic_source_id : '';
    this.affiliate_offer_id = this.$route.query.affiliate_offer_id ? this.$route.query.affiliate_offer_id : '';

    this.fetchData(this.lastQuery);
  },

  mounted() {
    this.fetchData(this.passedParams.lastQuery, this.fetchedData);
  },
  methods: {
    back() {
      if (localStorage.getItem('getBackUrlPostbacks') === null) {
        this.$router.push(`${this.lastVisitedRoute}`);
      } else {
        this.$router.push(JSON.parse(localStorage.getItem('getBackUrlPostbacks')));
      }
    },
    setRange(period) {
      this.$refs.datatable.setRange(period);
    },
  },
};
</script>

<style lang="scss">
.request-log {
  min-height: calc(100vh - 100px);

  > .card {
    min-height: inherit;
  }
}
</style>
