<template>
  <section>
    <modal
      :show.sync="active"
      class="offer get-links-modal"
      @close="close"
    >
      <template>
        <h5 slot="header" class="modal-title mt-8">
          {{ $t('offer.card.titleGen') }}
        </h5>
        <div v-loading="loading">
          <div class="wrap-row-column d-flex mt-12 mb-8">
            <div
              v-if="$store.state.profile.user.referral_program_enabled"
              class="select-wrapper w-100"
            >
              <label>{{$t('mediaBuying.campaign.affiliate')}}</label>
              <v-select
                :id="`${randomizer()}GetLinks.sub_affiliate_id`"
                v-model="sub_affiliate_id"
                :options="referrals"
                class="el-input"
                @change="changeSubAffiliateId"
              />
            </div>
            <div class="select-wrapper w-100 ml-16" :class="{'ml-0': !$store.state.profile.user.referral_program_enabled}">
              <label>{{ $t('promoCodes.addPromocodes.offer') }}</label>
              <v-select
                :id="`${randomizer()}GetLinks.offer`"
                v-model="offer"
                :options="offers"
                class="el-input"
                @change="changeOffer"
              />
              <span v-if="!offerSelect && sending" class="text-danger text-left mt-2">
                {{ $t('promoCodes.addPromocodes.selectOffer') }}
              </span>
            </div>
          </div>
          <!-- brand -->
          <div v-if="hideSelect(brands)" class="select-wrapper w-100 mt-14 mb-10" :class="{'disabled': offer === ''}">
            <label>{{ $t('mediaTags.brand') }}</label>
            <v-select
              :id="`${randomizer()}GetLinks.brand`"
              v-model="brand"
              :options="brands"
              :disabled="offer === ''"
              class="el-input"
              @change="changeBrand"
            />
          </div>

          <div
            v-if="hideSelect(rotations)"
            class="select-wrapper w-100 mt-14 mb-10" :class="{'disabled': brand === ''}"
          >
            <label>{{ $t('mediaTags.rotation') }}</label>
            <v-select
              :id="`${randomizer()}GetLinks.rotation`"
              v-model="rotation"
              :options="rotations"
              class="el-input"
              @change="changeRotation"
            />
          </div>

          <!-- target -->
          <div class="select-wrapper w-100 mb-10" :class="{'disabled': brand === ''}">
            <label>{{ $t('mediaTags.target') }}</label>
            <v-select
              :id="`${randomizer()}GetLinks.activeTab`"
              v-model="activeTab"
              :options="urls"
              class="el-input"
              @change="setActive"
            />
          </div>

          <div class="generate-tag__right-col">
            <div v-popover:popover class="select-wrapper mb-10" :class="{'disabled': activeTab === ''}">
              <label>{{ $t('trafficSources.trafficSource') }}</label>
              <v-select
                :id="`${randomizer()}GetLinks.traffic_source_id`"
                v-model="traffic_source_id"
                :options="list"
                class="el-input"
                @click="checkChange = false"
                @change="selectTrafficSource"
              />
              <router-link
                v-if="!list.length"
                :to="{ path: '../traffic-source'}"
                class="text-green d-block text-right"
              >
                {{ $t('trafficSources.addTrafficSources.title') }}
              </router-link>
            </div>
            <el-popover
              ref="popover"
              v-model="warningOpen"
              placement="top-end"
              trigger="manual"
              width="320"
            >
              <p>{{ $t('promoCodes.addPromocodes.changeSubId') }}</p>
              <button
                class="btn btn-outline-primary btn-md"
                @click="checkTSid"
              >
                {{ $t('offerRequest.btnCancel') }}
              </button>
              <button
                class="btn btn-primary btn-md ml-3"
                @click="confirmChanges(); warningOpen = false"
              >
                {{ $t('offerRequest.btnConfirm') }}
              </button>
            </el-popover>
            <div class="form-group generate-tag__form-group generate-tag__form-group--underline" :class="{'disable-input': activeTab === ''}">
              <label
                class="generate-tag__label generate-tag__label--final"
                for="final-url"
              >{{ $t('offer.card.finalPageUrl') }}</label>
              <input
                id="final-url"
                v-model="link"
                autocomplete="off"
                type="text"
                class="form-control generate-tag__input"
                @input="inputLink"
              >
            </div>
            <div class="tag-wrap">
              <span
                v-for="item in links"
                :key="item.page_url"
                :class="{'el-tag--selected': link === item.page_url, 'disabled-link': activeTab === ''}"
                class="el-tag el-tag--primary el-tag--small"
                @click="setLink(item.page_url)"
              >{{ item.name }}</span>
            </div>
            <div class="row row-wrap__subid pt-6">
              <div
                v-for="(item, i) in form.subids"
                :key="i"
                :class="{'disable-input': activeTab === ''}"
                class="col-6 col-6__add-subid"
              >
                <fg-input
                  v-model="item.value"
                  :disabled="activeTab === ''"
                  :placeholder="$t('trafficSources.postbacks.notSpecified')"
                  class="no-border form-control-lg mb-0"
                >
                  <label
                    slot="label"
                    class="d-flex justify-content-between"
                  >
                    <span>SubID{{ i + 1 }}</span>
                    <a
                      v-if="calcSubIdInTrafficSource < i + 1"
                      v-show="form.subids.indexOf(item) === form.subids.length - 1 && form.subids.length > 1"
                      href="#"
                      class="btn-cross"
                      @click.prevent="popSubIds"
                    >
                      <span>×</span>
                    </a>
                  </label>
                </fg-input>
              </div>
              <div class="col-6 d-flex align-items-end col-6__add-subid">
                <button
                  :disabled="form.subids.length >= 5 || activeTab === ''"
                  href="#"
                  class="btn btn-block btn-outline-primary btn-sm mb-0"
                  @click.prevent="form.subids.length < 5 && addSubId()"
                >
                  + {{ $t('promoCodes.addPromocodes.addAnotherSubid') }}
                </button>
              </div>
            </div>
          </div>
          <div class="">
            <div class="w-100 pt-16 mb-getlinks">
              <div class="offers__track-title mb-8">
                {{ $t('offer.card.trackUrl') }}
              </div>
              <copytext
                :button="$t('offer.card.btn')"
                :success="$t('offer.card.btnDone')"
                :text.sync="url"
                :disabled="warningOpen"
              />
            </div>
          </div>
        </div>
        <button
          slot="footer"
          class="btn btn-outline-primary btn-sm btn-close-modal"
          @click="close"
        >
          {{ $t('offer.offerRequestSuccess.close') }}
        </button>
      </template>
    </modal>
  </section>
</template>

<script>
import Copytext from 'src/components/Copytext';
import { Modal } from 'src/components';
import { Select, Option } from 'element-ui';
import VSelectMenu from 'src/components/VSelectMenu.vue';
import { debounce } from 'lodash';
import { mapState } from 'vuex';
import randomizer from 'src/helpers/randomizer';

export default {
  components: {
    Modal,
    Copytext,
    [Select.name]: Select,
    [Option.name]: Option,
    VSelectMenu,
  },
  data() {
    return {
      randomizer,
      warningOpen: false,
      params_id: '',
      params_query: '',
      active: false,
      activeTab: '',
      url: '',
      links: [],
      form: {
        name: '',
        extid: '',
        subids: [{ value: '' }],
      },
      link: '',
      traffic_source_id: null,
      selected: null,
      oldTSid: '',
      checkChange: false,
      sub_affiliate_id: '0',
      offer: '',
      offerSelect: false,
      sending: false,
      loading: false,
      brand: '',
      customSubids: [{ value: '' }],
      site_page: null,
      rotation: '',
      getRedirestUrlD: debounce(this.getRedirestUrl, 800),
      paramsSubsId: {},
    };
  },

  computed: {
    ...mapState({
      marketingCampaigns: state => state.mediaBuying.marketingCampaigns || [],
    }),

    offers() {
      return this.$store.state.offers.my.map(e => ({
        id: e.id,
        text: e.name,
        tracking_links: e.tracking_links,
      }))
        .concat({
          id: '',
          text: this.$t('promoCodes.addPromocodes.selectOffer'),
        });
    },

    referrals() {
      return this.$store.state.subaffiliates.all.map(e => ({
        id: e.data.id,
        text: e.email,
      }))
        .concat({
          id: 0,
          text: this.$t('mediaBuying.campaign.my_account'),
        });
    },

    brands() {
      const brands = (this.$store.state.offers.offer.visible_brands || []).map(e => ({
        id: e.site_id,
        text: e.site_name,
      }));
      return brands.concat({
        id: '',
        text: this.$t('profile.generalInfo.notSpecified'),
      });
    },

    rotations() {
      const rotations = this.$_.cloneDeep(this.marketingCampaigns)
        .sort(
          (a, b) => {
            if (a.type) {
              return a.type.length - b.type.length;
            }
          },
        )
        .map(e => ({
          id: e.id,
          text: e.name,
        }));
      return rotations.concat({
        id: '',
        text: this.$t('profile.generalInfo.notSpecified'),
      });
    },

    urls() {
      const urls = this.$store.state.mediaBuying.landings.map(e => ({
        ...e,
        text: e.name,
      }));
      return urls.concat({
        id: '',
        text: this.$t('profile.generalInfo.notSpecified'),
      });
    },

    list() {
      return this.$store.state.trafficSource.list.map(e => ({
        id: e.data.id,
        text: e.name,
      })).concat({
        id: '',
        text: this.$t('offerRequest.noTrafficSources'),
      });
    },

    params() {
      return {
        landing_id: this.activeTab,
        traffic_source_id: this.traffic_source_id,
        marketing_campaign_id: this.rotation,
        media_buying_campaign_id: this.$route.params.id,
        site_page: this.site_page,
        ...this.paramsSubsId,
      };
    },

    calcSubIdInTrafficSource() {
      const id = this.traffic_source_id;
      if (!id) return 1;

      const subidArray = Array(5).fill(null).map((el, i) => `subid${i + 1}`);

      const targetTrafficSoutce = this.$store.state.trafficSource.list
        .find(ts => ts.data.id === id);

      let resultCount = 1;
      subidArray.forEach((el, i) => {
        if (targetTrafficSoutce[el] !== null) {
          resultCount = i + 1;
        }
      });

      return resultCount;
    },
  },

  watch: {
    brand: {
      handler(data) {
        if (data) {
          this.$api.getFinalPages(data).then(res => {
            this.links = res.payload.site_final_page_urls;
          })
        } else {
          this.links = [];
        }
      }
    },
    traffic_source_id(value, oldValue) {
      if (this.checkChange) return;
      this.oldTSid = oldValue;

      let oldSource = this.$store.state.trafficSource.list.filter(e => e.data.id === oldValue)[0];
      oldSource = _.pick(oldSource, ['subid1', 'subid2', 'subid3', 'subid4', 'subid5']);
      const subids = {};

      // if no traffic source selected, clear subids
      if (!value) {
        this.form.extid = '';
        for (let i = 0; i < 5; i++) {
          const key = `subid${i + 1}`;
          subids[key] = this.form.subids[i] ? this.form.subids[i].value : null;
        }
        if (!_.isEmpty(oldSource) && !_.isEqual(subids, oldSource)) {
          this.addSubids(subids);
          return;
        }
        if (this.customSubids.some(e => e.value)) {
          this.form.subids = this.customSubids;
        } else {
          // if no traffic source selected clear subids
          this.form.subids = [{ value: null }];
        }
        return;
      }

      let source = this.$store.state.trafficSource.list.filter(e => e.data.id === value)[0];
      this.form.extid = source.data.extid || '';
      source = _.pick(source, ['subid1', 'subid2', 'subid3', 'subid4', 'subid5']);

      // if no traffic source selected, like from start
      if (!oldValue) {
        if (this.form.subids === 1 || !this.form.subids[0].value) {
          // if subids empty
          this.addSubids(source);
        } else {
          // take current subids
          const subids = {};
          for (let i = 0; i < 5; i++) {
            const key = `subid${i + 1}`;
            subids[key] = this.form.subids[i] ? this.form.subids[i].value : null;
          }
          // test if current subids is equal with new subids
          if (_.isEqual(subids, source)) {
            this.form.subids = [{ value: null }];
            this.addSubids(source);
          } else {
            // show warning
            this.warningOpen = true;
          }
        }
        return;
      }

      // take current subids
      for (let i = 0; i < 5; i++) {
        const key = `subid${i + 1}`;
        subids[key] = this.form.subids[i] ? this.form.subids[i].value : null;
      }
      // test if current subids is equal with old subids
      if (_.isEqual(subids, oldSource)) {
        this.form.subids = [{ value: null }];
        this.addSubids(source);
      } else {
        // show warning
        this.warningOpen = true;
      }
    },

    'form.subids': {
      handler(subids) {
        this.parseSubids(subids);
      },
      deep: true,
    },
  },

  methods: {
    hideSelect(optoins = []) {
      return optoins.length > 2;
    },

    parseSubids(subids) {
      if (!subids.subid1 && this.activeTab !== '') {
        const objSubs = {};
        for (let i = 0; i < subids.length; i++) {
          const key = `subid${i + 1}`;
          objSubs[key] = subids[i] ? subids[i].value : null;
        }

        this.paramsSubsId = objSubs;
        this.setActive('debounce');
      }
    },

    changeSubAffiliateId() {
      this.reset();
      this.loading = true;
      const id = this.sub_affiliate_id !== '0' ? { sub_affiliate_id: this.sub_affiliate_id } : {};
      const offers = this.$store.dispatch('getAffiliateOffers', id);
      const traffic = this.$store.dispatch('getTrafficSources', id);

      this.$store.dispatch('getMarketingCampaigns', id);
      Promise.all([offers, traffic])
        .then(() => {
          this.loading = false;
        });
    },

    inputLink() {
      this.site_page = this.link;
      this.getRedirestUrlD('hide');
    },

    setLink(url) {
      this.link = url;
      this.site_page = url;
      this.getRedirestUrl('hide');
    },

    changeOffer() {
      this.resetAffiliateData();
      this.activeTab = '';
      this.brand = '';
      this.offerSelect = true;

      if (this.offer !== '') {
        this.loading = true;

        this.$store.dispatch('getOffer', this.offer)
          .then((res) => {
            if ((res.payload.visible_brands || []).length === 1) {
              this.brand = res.payload.visible_brands[0].site_id;
            } else {
              this.brand = '';
            }
            this.changeBrand();
            this.loading = false;
          });
      }
    },

    async changeBrand() {
      this.resetAffiliateData();
      this.activeTab = '';
      if (this.brand === '') {
        return;
      }

      this.loading = true;
      await this.$store.dispatch('getLandings', { site_id: this.brand });
      this.loading = false;
      this.rotation = this.rotations[0].id;
      if (this.urls.length === 2) {
        this.activeTab = this.urls[0].id;
        this.setActive();
      }
    },

    async changeRotation() {
      if (this.rotation === '') {
        // this.resetAffiliateData();
        return;
      }
      if (this.activeTab) {
        await this.getRedirestUrl();
      }
    },

    async setActive(action) {
      if (this.activeTab === '') {
        this.resetAffiliateData();
        return;
      }
      if (action === 'debounce') {
        await this.getRedirestUrlD('hide');
        return;
      }
      await this.getRedirestUrl('hide');
    },

    async getRedirestUrl(action) {
      if (action !== 'hide') this.loading = true;
      try {
        const {
          payload,
        } = await this.$api.getRedirectUrl(this.offer, this.params);
        this.url = payload.url;
      } finally {
        this.loading = false;
      }
    },

    selectTrafficSource() {
      this.checkChange = false;
      this.setActive();
    },
    checkTSid() {
      this.customSubids = this.$_.cloneDeep(this.form.subids);
      this.checkChange = true;
      this.warningOpen = false;
    },
    open() {
      this.reset();
      this.active = true;
      this.loading = true;
      const offers = this.$store.dispatch('getAffiliateOffers', {});
      const traffic = this.$store.dispatch('getTrafficSources', {});

      const id = this.sub_affiliate_id !== '0' ? { sub_affiliate_id: this.sub_affiliate_id } : {};
      this.$store.dispatch('getMarketingCampaigns', id);

      Promise.all([offers, traffic])
        .then(() => {
          this.loading = false;
        });
    },
    close() {
      this.active = false;
      this.sub_affiliate_id = '0';
      this.loading = false;
      this.reset();
    },
    reset() {
      this.brand = '';

      this.site_page = null;
      this.rotation = '';
      this.paramsSubsId = {};
      this.url = '';

      this.activeTab = '';
      this.traffic_source_id = null;
      this.form.subids = [{ value: null }];
      this.customSubids = [{ value: null }];
      this.form.extid = '';
      this.link = '';
      this.links = [];
      this.offer = '';
      this.checkChange = false;
      this.warningOpen = false;
    },
    resetAffiliateData() {
      this.url = '';
      this.rotation = '';
      this.traffic_source_id = null;
      this.form.subids = [{ value: null }];
      this.customSubids = [{ value: null }];
      this.site_page = '';
      this.form.extid = '';
      this.link = '';
      this.checkChange = false;
      this.warningOpen = false;
    },
    popSubIds() {
      this.form.subids.pop();
    },
    addSubId() {
      this.form.subids.push({
        value: '',
      });
    },

    addSubids(source) {
      if (!source) {
        this.form.subids = [{ value: null }];
        return;
      }

      this.form.subids = [];
      // Push subids

      Object.keys(source)
        .map((k) => {
          if (k.startsWith('subid')) {
            if (source[k]) {
              this.form.subids.push({
                value: source[k],
              });
            } else {
              this.form.subids.push({
                value: null,
              });
            }
          }
        });

      // reduce subids
      for (let i = this.form.subids.length - 1; i >= 1; i--) {
        if (!this.form.subids[i].value) {
          this.form.subids.pop();
        } else {
          break;
        }
      }
    },
    confirmChanges() {
      this.form.subids = [{ value: null }];
      this.customSubids = [{ value: null }];
      const source = this.$store.state.trafficSource.list.filter(e => e.data.id === this.traffic_source_id)[0];
      this.addSubids(source);
    },
    saveSubids() {
      if (this.form.subids.length < this.calcSubIdInTrafficSource) {
        const remainder = this.calcSubIdInTrafficSource - this.form.subids.length;

        this.customSubids = [
          ..._.cloneDeep(this.form.subids),
          ...Array(remainder).fill({ value: '' }),
        ].map(el => (!el.value ? { value: '' } : el));
        this.form.subids = this.customSubids;
      } else {
        this.customSubids = _.cloneDeep(this.form.subids);
      }

      this.checkChange = true;
      this.warningOpen = false;
    },
  },
};
</script>

<style lang="scss">
@import 'src/assets/theme/default/offers/offer';
</style>
