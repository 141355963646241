<template>
  <section>
    <modal
      :show.sync="active"
      class="offer__request"
    >
      <template>
        <h5
          slot="header"
          class="modal-title mt-8"
        >
          {{ $t('offerRequest.title') }}
        </h5>

        <form
          @submit.prevent
          @keyup.enter="send"
        >
          <div class="select-wrapper">
            <label class="mt-0 pt-0">
              {{ $t('offerRequest.selectTitle') }}
            </label>
            <v-select
              v-model="traffic_source"
              :options="list"
              class="el-input"
            />
            <router-link
              v-if="!list.length"
              :to="{ path: './traffic-source'}"
              class="text-green d-block text-right"
            >
              {{ $t('trafficSources.addTrafficSources.title') }}
            </router-link>
          </div>
          <div class="select-wrapper">
            <label>
              {{ $t('trafficSources.addTrafficSources.url') }}
            </label>
            <el-input
              v-model="url"
              :placeholder="$t('offerRequest.placeholderInput')"
              @click="changed = false"
            />
            <div
              v-if="changed && invalid_url"
              class="text-danger text-right"
            >
              {{ $t('trafficSources.addTrafficSources.pleaseInputUrl') }}
            </div>
          </div>
          <div class="select-wrapper">
            <div class="form-group mt-8 mb-8">
              <label
                for="exampleFormControlInput1"
                class="mes__title"
              >{{ $t('offerRequest.comment') }}</label>
              <textarea
                id="exampleFormControlInput1"
                v-model="comment"
                :placeholder="$t('offerRequest.placeholderTextarea')"
                rows="5"
                class="form-control"
              />
            </div>
          </div>
        </form>

        <div
          slot="footer"
          class="text-center"
        >
          <button
            class="btn btn-outline-primary btn-sm mr-10 mt-8 mb-4 req-btn-c"
            @click="close"
          >
            {{ $t('offerRequest.btnCancel') }}
          </button>
          <button
            class="btn btn-primary btn-sm modal__btn-confirm mt-8 mb-4 req-btn-s"
            @click="send"
          >
            {{ $t('offerRequest.btnSend') }}
          </button>
        </div>
      </template>
    </modal>
    <offer-request-success ref="success" />
  </section>
</template>

<script>
import { Modal } from 'src/components';
import { Select, Option, Input } from 'element-ui';
import OfferRequestSuccess from './OfferRequestSuccess';

export default {
  components: {
    Modal,
    [Select.name]: Select,
    [Option.name]: Option,
    [Input.name]: Input,
    OfferRequestSuccess,
  },
  data() {
    return {
      active: false,
      offer: this.$t('promoCodes.addPromocodes.selectOffer'),
      traffic_source: '',
      url: '',
      comment: '',
      offer_id: '',
      changed: false,
    };
  },
  computed: {
    list() {
      const data = this.$store.state.trafficSource.list.map(e => ({
        id: e.data.id,
        text: e.name,
      }));
      return [{ id: '', text: this.$t('offerRequest.noTrafficSources') }].concat(data);
    },
    invalid_url() {
      const regexp = /^(?:http(s)?:\/\/)[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
      return !regexp.test(this.url.trim());
    },
  },
  methods: {
    open(offer_id) {
      this.reset();
      this.offer_id = offer_id;
      this.active = true;
    },
    close() {
      this.active = false;
      this.reset();
    },
    reset() {
      this.traffic_source = '';
      this.url = '';
      this.comment = '';
    },
    send() {
      this.changed = true;
      if (this.invalid_url) return;

      this.$request
        .post('offers/request-access', {
          offer_id: this.offer_id,
          traffic_source_name: this.traffic_source,
          traffic_source_url: this.url,
          description: this.comment,
        })
        .then(() => {
          this.active = false;
          this.$refs.success.open();
          this.$store.dispatch('getOffers');
        })
        .catch((err) => {
          this.$alert.error(this.$t('request_errors.internal_error'));
        });
    },
  },
};
</script>

<style lang="scss">
@import 'src/assets/theme/default/offers/offerrequest';
</style>
