<template>
  <section class="traffic-source__modal">
    <modal :show.sync="active">
      <h5
        slot="header"
        class="modal-title d-flex"
        style="direction: ltr"
      >
        {{ $t('trafficSources.postbacks.edit') }} {{ form.name }}
      </h5>

      <template>
        <form @keyup.enter="send">
          <div class="form-group select-wrapper mt-0">
            <label>{{ $t('trafficSources.addTrafficSources.name') }}</label>
            <input
              v-model="form.name"
              :placeholder="$t('trafficSources.addTrafficSources.trafficSourceName')"
              type="text"
              class="form-control mt-0 w-100"
            >
            <div
              v-if="changed && invalid_name"
              class="text-danger text-right"
            >
              {{ $t('trafficSources.addTrafficSources.pleaseInputName') }}
            </div>
          </div>
          <div class="form-group select-wrapper form-group__external mb-4">
            <label>{{ $t('trafficSources.addTrafficSources.externalId') }}</label>
            <input
              v-model="form.external_id"
              :placeholder="$t('trafficSources.addTrafficSources.externalId')"
              autocomplete="off"
              type="text"
              class="form-control mt-0 w-100"
              @click="changed = false"
            >
            <div
              v-if="false"
              class="text-danger text-right"
            >
              {{ $t('trafficSources.addTrafficSources.pleaseInputExternal') }}
            </div>
          </div>

          <div class="row row-wrap__subid">
            <div
              v-for="(item, i) in form.subids"
              :key="i"
              class="col-6 col-6__add-subid"
            >
              <fg-input
                v-model="item.value"
                :placeholder="$t('trafficSources.postbacks.notSpecified')"
                class="no-border form-control-lg mb-0"
              >
                <label
                  slot="label"
                  class="d-flex justify-content-between"
                >
                  <span>SubID{{ form.subids.indexOf(item) + 1 }}</span>
                  <a
                    v-show="form.subids.indexOf(item) == form.subids.length - 1 && form.subids.length > 1"
                    href="#"
                    class="btn-cross"
                    @click.prevent="popSubIds"
                  ><span>×</span></a>
                </label>
              </fg-input>
            </div>
            <div class="col-6 d-flex align-items-end col-6__add-subid">
              <button
                :disabled="form.subids.length >= 5"
                href="#"
                class="btn btn-block btn-outline-primary btn-sm mt-24 mb-0"
                @click.prevent="form.subids.length < 5 && addSubId()"
              >
                + {{ $t('promoCodes.addPromocodes.addAnotherSubid') }}
              </button>
            </div>
          </div>
        </form>
      </template>

      <div
        slot="footer"
        class="text-right"
      >
        <a
          class="btn btn-outline-primary btn-sm mt-8 mb-4"
          @click="close"
        >{{ $t('trafficSources.addTrafficSources.cancel') }}</a>
        <a
          class="btn btn-primary btn-sm ml-16 mt-8 mb-4"
          @click="send"
        >{{ $t('trafficSources.addTrafficSources.send') }}</a>
      </div>
    </modal>
  </section>
</template>

<script>
import { Modal } from 'src/components';

export default {
  components: {
    Modal,
  },
  props: ['offsetParams'],

  data() {
    return {
      active: false,
      changed: false,
      source_id: '',
      form: {
        name: '',
        external_id: '',
        subids: [{ value: '' }],
      },
    };
  },

  computed: {
    invalid_name() {
      return !this.form.name.trim();
    },
    invalid_external_id() {
      const regexp = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
      return !regexp.test(this.form.external_id.trim());
    },
  },

  methods: {
    open(id) {
      this.reset();
      this.source_id = id;
      this.$api
        .getTrafficSource(id)
        .then((data) => {
          this.edit(data.payload);
        })
        .catch((err) => {
          this.$alert.error('Error loading traffic source');
        });
    },
    edit(payload) {
      const val = payload;
      if (!val.subid1) val.subid1 = ' ';

      this.active = true;
      this.form.name = val.name;
      this.form.external_id = val.external_id;

      if (val.subid1 || val.subid2 || val.subid3 || val.subid4 || val.subid5) this.form.subids = [];

      for (let i = 1; i < 6; i++) {
        this.form.subids.push({
          value: val[`subid${i}`] || '',
        });
      }
      let tail = false;
      while (!tail) {
        const item = this.form.subids.pop();
        tail = item.value;
        if (tail) this.form.subids.push(item);
      }
      if (this.form.subids[0] && this.form.subids[0].value === ' ') this.form.subids[0].value = '';
    },
    close() {
      this.active = false;
      this.reset();
    },
    send() {
      this.changed = true;
      if (this.invalid_name) return;
      this.$emit('addTrafficSource');
      this.form_disabled = true;
      const data = {
        id: this.source_id,
        name: this.form.name,
        external_id: this.form.external_id,
      };
      for (const i in this.form.subids) {
        if (this.form.subids[i]) data[`subid${parseInt(i) + 1}`] = this.form.subids[i].value;
      }
      this.$store.dispatch('updateTrafficSource', data).then((res) => {
        this.$store.dispatch('getTrafficSourcesLazy', { ...this.offsetParams });
        this.$alert.success(this.$t('trafficSources.postbacks.trafficUpdated'));
      });
      this.close();
    },
    reset() {
      (this.form.name = ''), (this.form.external_id = ''), (this.changed = false);
      this.form.subids = [
        {
          value: '',
        },
      ];
    },
    addSubId() {
      this.form.subids.push({
        value: '',
      });
    },
    popSubIds(item) {
      this.form.subids.pop();
    },
  },
};
</script>

<style lang="scss">
.traffic-source__modal {
  label {
    margin: 0 0 4px !important;
  }
  .form-group {
    margin-top: 0 !important;
    margin-bottom: 14px;
  }
  .form-group__external {
    margin-bottom: 0;
  }
  .has-label {
    margin-top: 24px !important;
  }
  textarea {
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    min-height: 115px !important;
  }
  .btn-outline-primary {
    min-width: 100px !important;
  }
}
</style>
