<template>
  <section class="edit-tag">
    <modal id="modalId"
           :show.sync="active"
           @close="close"
    >
      <h5 slot="header" class="modal-title">
        <span v-show="!edit">{{ $t('tagManager.card.addTag') }}</span>
        <span v-show="edit">{{ $t('tagManager.card.editTag') }}</span>
      </h5>
      <template>
        <form>
          <div class="row border-bot" :class="{wavebg: loading}">
            <div class="col-12">
              <div class="form-group select-wrapper mt-0 mb-0">
                <label>{{ $t('tagManager.tags.fields.name.title') }}</label>
                <input v-model="form.name"
                       :placeholder="$t('tagManager.tags.fields.name.title')"
                       type="text"
                       class="form-control mt-0 w-100"
                       @input="() => clearError('name')"
                />
                <div v-if="formErrors.has('name')"
                     class="text-danger"
                >
                  {{ $t('tagManager.errors.nameRequired') }}
                </div>
              </div>
            </div>
            <div class="col-12 mt-10">
              <div class="select-wrapper">
                <label>{{ $t('tagManager.tags.fields.platform.title') }}</label>
                <div class="request__radio-wrapper">
                  <div v-for="(platform, i) in platforms"
                       :key="i"
                       class="request__radio-one"
                  >
                    <input :id="platform"
                           v-model="form.platform"
                           :value="platform"
                           type="radio"
                           name="radio-group"
                           :disabled="platform !== 'Landings' && platform !== 'PWA'"
                           @change="() => clearError('platform')"
                    />
                    <label :for="platform">{{ platform }}</label>
                  </div>
                </div>
                <div v-if="formErrors.has('platform')"
                     class="text-danger"
                >
                  {{ $t('tagManager.errors.platformRequired') }}
                </div>
              </div>
            </div>
            <div class="col-12 mt-10">
              <div class="select-wrapper">
                <label>{{ $t('tagManager.tags.fields.triggers.title') }}</label>
                <div class="send__check-wrapper row">
                  <div class="send__col-one col-12 row">
                    <div v-for="(trigger, i) in triggers"
                         :key="i"
                         class="send__checkbox-item checkbox col-6"
                    >
                      <input :id="trigger"
                             v-model="form.triggers[trigger]"
                             type="checkbox"
                             @change="() => clearError('triggers')"
                      />
                      <label class="checkbox-label"
                             :class="{'checkbox-check': form.triggers[trigger]}"
                             :for="trigger"
                      >{{ $t(`tagManager.triggers.${trigger}`) }}</label>
                    </div>
                  </div>
                </div>
                <div v-if="formErrors.has('triggers')"
                     class="text-danger"
                >
                  {{ $t('tagManager.errors.triggerRequired') }}
                </div>
              </div>
            </div>
            <div class="col-12 mt-10">
              <div class="select-wrapper">
                <label>{{ $t('tagManager.tags.fields.template.title') }}</label>
                <v-select key="id"
                          v-model="form.template"
                          :options="templates"
                          class="el-input mt-4"
                          label="name"
                          :settings="dropdownFiltersSettings"
                          @change="onTemplateChange"
                />
                <div v-if="formErrors.has('template')"
                     class="text-danger"
                >
                  {{ $t('tagManager.errors.templateRequired') }}
                </div>
              </div>
            </div>
            <div class="col-12" v-if="showAttributes">
              <div v-for="(attribute, i) of attrributes"
                   :key="i"
                   class="form-group select-wrapper"
              >
                <label class="mb-12">{{ attribute.name }}</label>
                <el-input v-if="!attribute.is_tokens_applicable && attribute.input_type === 'multiline'"
                          :value="form[attribute.key]"
                          :placeholder="attribute.name"
                          :label="attribute.name"
                          type="textarea"
                          :rows="3"
                          @input="v => onChange(attribute.key, v)"
                />
                <input v-if="!attribute.is_tokens_applicable && attribute.input_type === 'simple'"
                       :value="form[attribute.key]"
                       :placeholder="attribute.name"
                       type="text"
                       class="form-control mt-0 w-100"
                       @input="e => onChange(attribute.key, e.currentTarget.value)"
                />
                <token-input v-if="attribute.is_tokens_applicable"
                             :id="attribute.key"
                             :value="form[attribute.key]"
                             :placeholder="attribute.name"
                             :tokens="tokens"
                             :multiline="attribute.input_type === 'multiline'"
                             :rows="3"
                             @input="onChange"
                />
                <div v-if="attribute.is_required && formErrors.has(attribute.key)"
                     class="text-danger"
                >
                  {{ `${attribute.name} ${$t('tagManager.errors.isRequired')}` }}
                </div>
                <div v-if="attribute.value_type === 'json' && formErrors.has(`${attribute.key}_json`)"
                     class="text-danger"
                >
                  {{ `${attribute.name} ${$t('tagManager.errors.invalidJson')}` }}
                </div>
              </div>
            </div>
          </div>
        </form>
      </template>
      <div slot="footer"
           class="text-right"
      >
        <div class="tagmanager__btn-save-wrap">
          <a class="btn btn-outline-primary btn-sm mt-8 mb-4"
             @click="close"
          >
            {{ $t('trafficSources.addTrafficSources.cancel') }}
          </a>
          <a class="btn btn-primary btn-sm ml-16 mt-8 mb-4"
             @click="() => saveTag()"
          >
            {{ !edit ? $t('tagManager.card.addTag') : $t('tagManager.card.editTag') }}
          </a>
        </div>
      </div>
    </modal>
  </section>
</template>

<script>
import { Modal, TokenInput } from 'src/components/index';

export default {
  components: {
    TokenInput,
    Modal,
  },
  data() {
    return {
      active: false,
      edit: false,
      loading: false,
      form: {
        triggers: {},
      },
      triggers: ['page_load', 'page_close', 'signup'],
      dropdownFiltersSettings: {
        minimumResultsForSearch: Infinity,
      },
      formErrors: new Set(),
    };
  },
  props: ['passedParams', 'platforms'],
  computed: {
    templates() {
      return this.$store.state.misc.tagTemplates.map(tt => ({
        id: tt.id,
        text: tt.name,
      }));
    },
    showAttributes() {
      if (!this.form.template) {
        return false;
      }

      const template = this.$store.state.misc.tagTemplates.find(tt => tt.id === this.form.template);
      return template && template.attributes.length > 0;
    },
    attrributes() {
      if (!this.form.template) {
        return [];
      }

      const template = this.$store.state.misc.tagTemplates.find(tt => tt.id === this.form.template);
      return template ? template.attributes : [];
    },
    tokens() {
      return this.$store.state.settings.tag_manager_tokens;
    },
  },
  methods: {
    open(id) {
      this.loading = !!id;
      this.edit = !!id;
      this.active = true;

      if (id) {
        this.$store.dispatch('getTag', id)
          .then((tag) => {
            this.form = {
              id: tag.id,
              name: tag.name,
              template: tag.tag_manager_template_id,
              platform: this.platforms.find(p => p.toLowerCase() === tag.embed_platform),
              triggers: this.triggers.reduce((result, item) => ({
                ...result,
                [item]: tag.triggers.includes(item),
              }), {}),
              ...tag.attributes,
            };

            this.loading = false;
          });
      } else {
        const facebook = this.$store.state.misc.tagTemplates.find(tt => tt.name === 'Facebook');

        if (facebook) {
          this.form = { ...this.form };
          this.form.template = facebook.id;
          this.form.platform = 'Landings';
          this.onTemplateChange(this.form.template);
        }
      }

      this.clearTooltip();
    },
    close() {
      this.resetForm();
      this.active = false;
    },
    async saveTag() {
      if (this.validateForm()) {
        const template = this.$store.state.misc.tagTemplates.find(tt => tt.id === this.form.template);

        if (template) {
          const tagParams = {
            data: {
              traffic_source_id: this.$store.state.trafficSource.item.id,
              tag_manager_template_id: this.form.template,
              name: this.form.name,
              embed_platform: this.form.platform.toLowerCase(),
              triggers: Object.entries(this.form.triggers)
                .filter(([, v]) => v)
                .map(([k]) => k),
              attributes: template.attributes.reduce((result, item) => ({
                ...result,
                [item.key]: this.form[item.key],
              }), {}),
            },
            passedParams: {
              ...this.passedParams,
              traffic_source_id: this.$store.state.trafficSource.item.id,
            },
          };

          if (!this.edit) {
            await this.$store
              .dispatch('createTag', tagParams)
              .then(() => {
                this.$emit('success');
                this.$alert.success(this.$t('tagManager.messages.wasCreated'));
              });
          } else {
            tagParams.data.id = this.form.id;
            await this.$store
              .dispatch('updateTag', tagParams)
              .then(() => {
                this.$emit('success');
                this.$alert.success(this.$t('tagManager.messages.wasUpdated'));
              });
          }
        }

        this.close();
      }
    },
    validateForm() {
      this.formErrors.clear();

      // Name
      if (!this.form.name) {
        this.formErrors = new Set(Array.from(this.formErrors)).add('name');
      }

      // Platform
      if (!this.form.platform) {
        this.formErrors = new Set(Array.from(this.formErrors)).add('platform');
      }

      // Triggers
      if (Object.values(this.form.triggers)
        .filter(v => v).length === 0) {
        this.formErrors = new Set(Array.from(this.formErrors)).add('triggers');
      }

      // Template
      if (!this.form.template) {
        this.formErrors = new Set(Array.from(this.formErrors)).add('template');
      } else {
        const template = this.$store.state.misc.tagTemplates.find(tt => tt.id === this.form.template);

        if (template) {
          template.attributes.forEach((a) => {
            if (a.is_required && !this.form[a.key]) {
              this.formErrors = new Set(Array.from(this.formErrors)).add(a.key);
            }

            if (a.value_type === 'json' && this.form[a.key] && !this.isJson(this.form[a.key])) {
              this.formErrors = new Set(Array.from(this.formErrors)).add(`${a.key}_json`);
            }
          });
        }
      }

      return this.formErrors.size === 0;
    },
    resetForm() {
      this.formErrors.clear();
      this.form = {
        triggers: {},
      };
    },
    onChange(k, v) {
      this.clearError(k);
      this.form = {
        ...this.form,
        [k]: v,
      };
    },
    onTemplateChange(id) {
      const template = this.$store.state.misc.tagTemplates.find(tt => tt.id === id);

      if (template) {
        template.attributes.forEach((a) => {
          this.form[a.key] = a.default_value;
        });
      }

      this.clearError('template');
    },
    clearError(key) {
      this.formErrors = new Set(Array.from(this.formErrors));
      this.formErrors.delete(key);
    },
    isJson(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }

      return true;
    },
    clearTooltip() {
      const tooltips = document.querySelectorAll('.el-tooltip__popper');
      tooltips.forEach((e, i) => {
        tooltips[i].style.display = 'none';
      });
    },
  },
};
</script>

<style lang="scss">
@import 'src/assets/theme/default/trafficsources/tagmanager';
</style>
