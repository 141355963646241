<template>
  <section class="profile profile-loader">
    <div class="mb-20">
      <div class="mr-20">
        <div class="card">
          <div class="d-flex justify-content-between">
            <skeleton height="12px" width="98px" class="mt-10" />
            <div>
              <skeleton height="34px" width="152px" class="mr-12" />
              <skeleton height="34px" width="66px" />
            </div>
          </div>
          <el-divider class="mb-20 mt-14" />
          <div class="d-flex flex-column">
            <skeleton height="9px" width="195px" />
            <div class="d-flex flex-row">
              <div class="d-flex flex-column flex-grow-1">
                <skeleton height="9px" width="76px" class="mt-20" />
                <skeleton height="12px" width="92px" class="mt-10" />
                <skeleton height="9px" width="76px" class="mt-30" />
                <skeleton height="12px" width="92px" class="mt-10" />
                <skeleton height="9px" width="76px" class="mt-30" />
                <skeleton height="12px" width="92px" class="mt-10" />
              </div>
              <div class="d-flex flex-column flex-grow-1">
                <skeleton height="9px" width="76px" class="mt-20" />
                <skeleton height="12px" width="92px" class="mt-10" />
                <skeleton height="9px" width="76px" class="mt-30" />
                <skeleton height="12px" width="92px" class="mt-10" />
                <skeleton height="9px" width="76px" class="mt-30" />
                <skeleton height="12px" width="92px" class="mt-10" />
              </div>
            </div>
          </div>
          <el-divider class="mb-0 mt-30" />
          <div class="d-flex flex-row mb-10">
            <div class="d-flex flex-column flex-grow-1">
              <skeleton height="9px" width="76px" class="mt-20" />
              <skeleton height="12px" width="150px" class="mt-10" />
            </div>
            <div class="d-flex flex-column flex-grow-1">
              <skeleton height="9px" width="76px" class="mt-20" />
              <skeleton height="12px" width="150px" class="mt-10" />
            </div>
          </div>
          <skeleton height="9px" width="76px" class="mt-20" />
          <skeleton height="12px" width="350px" class="mt-10" />
        </div>
      </div>
      <div class="mr-20">
        <div class="card mb-20">
          <div class="d-flex justify-content-between">
            <skeleton height="12px" width="136px" class="mr-12 mt-10 mb-0" />
            <div>
              <skeleton height="34px" width="67px" />
            </div>
          </div>
          <el-divider class="mb-20 mt-14" />
          <skeleton height="10px" width="180px" />
          <skeleton height="10px" width="100px" class="mt-20" />
          <skeleton height="10px" width="180px" class="mt-20" />
          <skeleton height="10px" width="100px" class="mt-20" />
        </div>
        <div class="card d-flex flex-column">
          <skeleton height="12px" width="68px" class="mr-12 mt-10 mb-30" />
          <skeleton width="auto" height="auto" class="flex-grow-1" />
        </div>
      </div>
      <div>
        <div class="card mb-20">
          <div class="d-flex justify-content-between">
            <skeleton height="12px" width="136px" class="mr-12 mt-10 mb-0" />
            <div>
              <skeleton height="34px" width="67px" />
            </div>
          </div>
          <el-divider class="mb-20 mt-14" />
          <skeleton height="10px" width="300px" class="mb-20" />
          <skeleton height="10px" width="180px" />
          <skeleton height="10px" width="100px" class="mt-20" />
        </div>
      </div>
    </div>
    <div>
      <div class="card">
        <div class="mb-20">
          <div>
            <div>
              <skeleton height="12px" width="68px" class="mr-12 mt-10 mb-0" />
            </div>
          </div>
          <div>
            <div>
              <skeleton height="34px" width="155px" class="mr-12" />
            </div>
            <div>
              <skeleton height="34px" width="80px" class="mr-12" />
              <skeleton height="34px" width="212px" />
            </div>
          </div>
        </div>
        <skeleton width="auto" height="auto" />
      </div>
    </div>
  </section>
</template>

<script>
import Skeleton from '../../components/Skeleton';

export default {
  components: {
    Skeleton,
  },
};
</script>

<style lang="scss">
@import 'src/assets/theme/default/profile/profile';
</style>
