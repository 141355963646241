import NotFoundPage from 'src/views/General/NotFoundPage';
import pages from './pages';
import dashboard from './dashboard';

export default [
  pages,
  dashboard,
  {
    path: '*',
    name: '404',
    component: NotFoundPage,
  },
];
