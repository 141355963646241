<template>
  <section>
    <div>
      <span v-if="shown">{{ text }}</span>
      <span
        v-if="!shown"
        class="placeholder"
      >{{ placeholder || getPlaceholder }}</span>
      <a
        class="text-green"
        v-if="!shown"
        href="#"
        @click.prevent="shown = true"
      >{{ show || 'Show' }}</a>
      <a
        class="text-green"
        v-if="shown"
        href="#"
        @click.prevent="shown = false"
      >{{ hide || 'Hide' }}</a>
    </div>
  </section>
</template>
<script>
export default {
  props: ['placeholder', 'text', 'show', 'hide'],
  data() {
    return {
      shown: false,
    };
  },
  computed: {
    getPlaceholder() {
      let result = '';
      this.text.split('').forEach(() => result += '*');
      return result;
    },
  },
};
</script>
<style scoped>
  a{
    margin-left:6px;
  }
  .placeholder{
    position:relative;
    top:3px;
    font-size:14px;
  }
  span{
    width: 100%;
    word-wrap: break-word;
  }
</style>
