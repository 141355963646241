<template>
  <div class="ui-filter">
    <ui-button
      :filled="!!filtersOpen || !$_.isEmpty(currentFilters)"
      icon="filter"
      class="main-btn"
      @click="toggleFilters"
    >
      Filter
    </ui-button>
    <transition name="fade">
      <div
        v-show="filtersOpen"
        class="pop"
      >
        <div class="arrow" />
        <div
          v-for="(item, index) in currentFilters"
          :key="index"
          class="filter-row"
        >
          <ui-button
            :icon-size="2"
            color="red"
            icon="minus"
            class="btn"
            @click="removeFilter(index)"
          />
          <ui-select
            v-model="item.type"
            :options="availableFilters"
            :exclude-options="excludeFilters"
            :label-i18n="'ui.filters'"
            value-prop="type"
            label-prop="type"
            class="filter_type"
            @select="item.values = getFilter(item.type).multiple ? [] : ''"
          />
          <ui-select
            v-model="item.values"
            :options="getFilter(item.type).options"
            :value-prop="getFilter(item.type).valueProp"
            :label-prop="getFilter(item.type).labelProp"
            :remote="getFilter(item.type).remote"
            :filter-method="getFilter(item.type).method"
            :disabled="!item.type"
            :multiple="getFilter(item.type).multiple"
            :filterable="getFilter(item.type).filterable"
            class="filter_type"
          />
        </div>
        <div class="controls">
          <ui-button
            :disabled="availableFilters.length === currentFilters.length"
            icon="plus"
            class="btn"
            @click="addFilter"
          />
          <ui-button
            filled
            icon="download"
            class="btn"
            @click="applyFilters"
          >
            Apply Filters
          </ui-button>
          <ui-button
            color="red"
            class="btn"
            @click="resetFilters"
          >
            Reset Filters
          </ui-button>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'UiFilter',
  props: {
    value: {
      type: Object,
      default() {
        return {};
      },
    },
    useFilters: {
      type: Array,
      default() {
        return [
          'affiliate',
          'affiliateReport',
          'player',
          'playerReport',
          'country',
          'playerCountry',
          'trafficType',
        ];
      },
    },
    from: {
      type: Object,
      default() {
        return this.$moment().startOf('day').subtract(30, 'd');
      },
    },
    to: {
      type: Object,
      default() {
        return this.$moment().endOf('day');
      },
    },
  },
  data() {
    return {
      filtersOpen: false,
      allFilters: {
        affiliate: {
          type: 'affiliate',
          field: 'affiliate_id',
          options: [],
          labelProp: 'email',
          valueProp: 'id',
          multiple: true,
          filterable: true,
          remote: true,
          method(q) {
            const query = {
              limit: 100,
              offset: 0,
              search: q,
              account_status: ['approved'],
            };
            return new Promise((resolve, reject) => {
              this.$api.getAffiliates(query)
                .then((response) => {
                  resolve(response.data.payload);
                })
                .catch((error) => {
                  reject(error);
                });
            });
          },
        },
        affiliateReport: {
          type: 'affiliateReport',
          field: 'affiliate_id',
          options: [],
          labelProp: 'affiliate_email',
          valueProp: 'row_id',
          multiple: true,
          filterable: true,
          remote: true,
          method(q) {
            const query = {
              search: q,
              group_by: 'affiliate_email',
              limit: 100,
              offset: 0,
              sort_by: 'deposits_all_count',
              sort_dir: 'desc',
              columns: 'deposits_all_count',
              from: this.$moment(this.from)
                .startOf('day')
                .format('YYYY-MM-DD HH:mm:ss'),
              to: this.$moment(this.to)
                .endOf('day')
                .format('YYYY-MM-DD HH:mm:ss'),
            };
            return new Promise((resolve, reject) => {
              this.$api.getMainReport(query)
                .then((response) => {
                  resolve(response.data.payload.data);
                })
                .catch((error) => {
                  reject(error);
                });
            });
          },
        },
        player: {
          type: 'player',
          field: 'player_id',
          options: [],
          labelProp: 'site_player_id',
          valueProp: 'id',
          multiple: true,
          filterable: true,
          remote: true,
          method(q) {
            const query = {
              limit: 100,
              offset: 0,
              search: q,
            };
            return new Promise((resolve, reject) => {
              this.$api.getPlayers(query)
                .then((response) => {
                  resolve(response.data.payload);
                })
                .catch((error) => {
                  reject(error);
                });
            });
          },
        },
        playerReport: {
          type: 'playerReport',
          field: 'player_id',
          options: [],
          labelProp: 'site_player_id',
          valueProp: 'row_id',
          multiple: true,
          filterable: true,
          remote: true,
          method(q) {
            const query = {
              search: q,
              group_by: 'site_player_id',
              limit: 100,
              offset: 0,
              sort_by: 'deposits_all_sum',
              sort_dir: 'desc',
              columns: 'deposits_all_sum',
              from: this.$moment(this.from)
                .startOf('day')
                .format('YYYY-MM-DD HH:mm:ss'),
              to: this.$moment(this.to)
                .endOf('day')
                .format('YYYY-MM-DD HH:mm:ss'),
            };
            return new Promise((resolve, reject) => {
              this.$api.getMainReport(query)
                .then((response) => {
                  resolve(response.data.payload.data);
                })
                .catch((error) => {
                  reject(error);
                });
            });
          },
        },
        country: {
          type: 'country',
          field: 'country_code',
          options: [],
          labelProp: 'name',
          valueProp: 'code',
          multiple: true,
          filterable: true,
          remote: false,
          method: null,
        },
        playerCountry: {
          type: 'playerCountry',
          field: 'player_country_code',
          options: [],
          labelProp: 'name',
          valueProp: 'code',
          multiple: true,
          filterable: true,
          remote: false,
          method: null,
        },
        trafficType: {
          type: 'trafficType',
          field: 'traffic_type',
          options: [
            {
              type: 'all',
              name: 'All',
            },
            {
              type: 'affiliates',
              name: 'Partners',
            },
            {
              type: 'direct',
              name: 'Direct',
            },
          ],
          labelProp: 'name',
          valueProp: 'type',
          multiple: false,
          filterable: false,
          remote: false,
          method: null,
        },
      },
      currentFilters: [],
    };
  },
  computed: {
    availableFilters() {
      const fs = this.$_.filter(this.allFilters, filter => this.useFilters.indexOf(filter.type) !== -1);
      return fs;
    },
    excludeFilters() {
      return this.$_.map(this.currentFilters, filter => filter.type);
    },
    ...mapGetters({
      countries: 'misc/countries',
    }),
  },
  watch: {
    countries() {
      this.initCountries();
    },
    value: {
      deep: true,
      handler(nv, ov) {
        if (!this.$_.isEqual(nv, ov)) {
          this.transformValue();
        }
      },
    },
  },
  created() {
    this.initCountries();
    this.allFilters.affiliate.method.bind(this)('')
      .then((response) => {
        this.allFilters.affiliate.options = response;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  mounted() {
    if (!this.$_.isEmpty(this.value)) {
      this.transformValue();
    }
  },
  methods: {
    getFilter(type) {
      return this.allFilters[type] || { multiple: true };
    },
    initCountries() {
      this.allFilters.playerCountry.options = this.countries;
      this.allFilters.country.options = this.countries;
    },
    toggleFilters() {
      this.filtersOpen = !this.filtersOpen;
      this.$emit('toggle', this.filtersOpen);
    },
    addFilter() {
      this.currentFilters.push({
        type: '',
        values: [],
      });
    },
    removeFilter(i) {
      this.currentFilters.splice(i, 1);
    },
    applyFilters() {
      const val = {};
      this.$_.forEach(this.currentFilters, (filter) => {
        this.$_.assign(val, { [this.getFilter(filter.type).field]: filter.values });
      });
      this.$emit('input', val);
      this.toggleFilters();
    },
    resetFilters() {
      this.currentFilters = [];
      this.$emit('input', {});
      this.toggleFilters();
    },
    reset() {
      this.currentFilters = [];
      this.$emit('input', {});
    },
    transformValue() {
      let fs = [];
      fs = this.$_.map(Object.keys(this.value), key => ({
        type: this.$_.find(this.allFilters, f => f.field === key).type,
        values: this.value[key],
      }));
      if (!this.$_.isEqual(fs, this.currentFilters)) {
        this.currentFilters = fs;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.ui-filter{
  position: relative;
  .pop{
    position: absolute;
    z-index: 9999;
    top: 44px;
    left: -20px;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    padding: 24px 20px;
    box-sizing: border-box;
    background-color: #fff;
    border: 1px solid #d3d3d3;
    border-radius: 5px;
    user-select: none;
    &.fade-enter-active, &.fade-leave-active{
      transition: all .4s;
    }
    &.fade-enter, &.fade-leave-to{
      opacity: 0;
      transform: translateY(8px);
    }
    .arrow{
      position: absolute;
      top: -6px;
      left: 56px;
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid #d3d3d3;
    }
    .filter-row{
      display: flex;
      align-items: center;
      margin-bottom: 16px;
      .filter_type{
        width: 160px;
        margin: 0 8px;
      }
      .filter_value{
        width: 280px;
      }
    }
    .controls{
      display: inline-flex;
      justify-content: flex-start;
      flex-wrap: nowrap;
      width: auto;
      .btn + .btn {
        margin-left: 8px;
      }
    }
  }
}
</style>
