<template>
  <div class="sort-by">
    <div class="btn-group">
      <div class="btn-group-select">
        <span>
          <label class="mb-0 ml-16">{{$t('filters.sortBy')}}: </label>
          {{ text }}
        </span>
        <el-select
          v-model="sortItem"
          class="el-input"
          clearable
          @change="send"
        >
          <el-option
            v-for="item in columnsList"
            :key="item.text"
            :label="item.text"
            :value="item.id"
          />
        </el-select>
      </div>
      <button class="btn-icon pr-10 pl-8" @click="amountRotate">
        <i :class="{fasRotate: rotateIcon}" class="fas fa-sort-amount-down" />
      </button>
    </div>
  </div>
</template>

<script>
import { Select, Option } from 'element-ui';
import VSelectMenu from 'src/components/VSelectMenu.vue';
import ColumnsMixin from 'src/components/Helpers/columns-mixin.js';

export default {
  components: {
    ElSelect: Select,
    ElOption: Option,
    VSelectMenu,
  },
  mixins: [
    ColumnsMixin,
  ],
  props: ['columns', 'tableHeader', 'sort_by', 'sort_dir', 'label', 'labelSubhead', 'i18n-path'],
  data() {
    return {
      sortItem: '',
      rotateIcon: true,
    };
  },
  computed: {
    columnMetrics() {
      return this.getMetrics(this.columns, 'name');
    },
    text() {
      const i18nPath = this.$vnodei18nPath || this.i18nPath || 'reports.fields';

      return this.columnMetrics[this.sortItem] && this.columnMetrics[this.sortItem].source
        ? this.columnMetrics[this.sortItem].title
        : this.sortItem
          ? this.$t(`${i18nPath}.${this.sortItem}.title`)
          : '';
    },
    columnsList() {
      let columnsList = this.columns.filter(e => e.sortable);

      if (columnsList) {
        columnsList = columnsList.map(e => ({
          id: e.name || e.column_name,
          text: e.source
            ? e.title
            : this.$t(this.i18nPath ? `${this.i18nPath}.${e.name}.title` : `reports.fields.${e.name}.title`),
          title: e.title,
        }));
      }

      return columnsList;
    },
  },
  watch: {
    sort_by(value) {
      this.sortItem = value;
    },
  },
  methods: {
    send(v) {
      if (v.id) this.sortItem = v.id;

      this.$emit('sortBy', {
        sort_by: this.sortItem,
        sort_dir: this.rotateIcon && 'asc' || 'desc',
      });
    },
    amountRotate() {
      this.rotateIcon = !this.rotateIcon;
      this.send({});
    },
  },
  mounted() {
    this.rotateIcon = this.sort_dir === 'asc';
    this.sortItem = this.sort_by;
  },
};
</script>

<style lang="scss">
.sort-by {
  display: none;

  .btn-group {
    width: 100%;

    .el-select {
      width: 100%;
    }

    .v-selectmenu {
      width: calc(100% - 33px) !important;

      .sm-default-btn {
        margin-top: 0;
        border-radius: 8px 0 0 8px !important;

        &.showDropdown {
          border-radius: 8px 0 0 0 !important;
        }
      }
    }
  }

  .btn-group-select {
    width: 100%;
    display: flex;
    align-items: center;

    > span {
      white-space: nowrap;
      position: absolute;
      font-size: 13px;
      z-index: 9;
      pointer-events: none;

      label {
        font-weight: 600;
      }
    }

    .el-input__inner {
      color: transparent !important;

      &:focus {
        border: 1px solid #d3d3d3 !important;
      }
    }

    .el-select__caret:before {
      display: none;
    }

    .select2 {
      z-index: 100;

      .select2-selection {
        margin: 0;
        background-color: transparent !important;
        background-image: none !important;
        border: none !important;

        .select2-selection__rendered {
          display: none;
        }

        b {
          top: 60%;
        }
      }
    }
  }

  .sort-by__label {
    display: flex;
    font-size: 11px;
    font-weight: 600;
  }

  .el-input__inner {
    border-radius: 8px 0 0 8px !important;
    border-color: #d3d3d3 !important;
  }

  .btn-icon {
    background-color: #fff;
    background-image: linear-gradient(to top, rgba(0, 0, 0, 0.06) 0%, rgba(255, 255, 255, 0.06) 100%);
    border: 1px solid #d3d3d3;
    border-radius: 0 8px 8px 0;
    border-left-width: 0;
    cursor: pointer;
    outline: none;
  }

  .fas {
    font-size: 14px;
    color: #7d7d7d;
  }

  .fasRotate {
    transform: rotate(180deg);
  }

  @media (max-width: 650px) {
    display: block;
  }
}
</style>
