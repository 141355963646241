import Vue from 'vue';
import Vuex from 'vuex';
import eventBus from 'src/lib/eventBus';
import settings from './settings';
import errors from './errors';
import auth from './auth';
import profile from './profile';
import offers from './offers';
import payments from './payments';
import promocode from './promocode';
import trafficSource from './traffic-source';
import misc from './misc';
import subaffiliates from './subaffiliates';
import media from './media';
import mediaBuying from './media-buying';
import report from './report';
import domains from './domains';

Vue.use(Vuex);

const modules = () => ({
  settings: settings(),
  errors: errors(),
  auth: auth(),
  profile: profile(),
  offers: offers(),
  payments: payments(),
  promocode: promocode(),
  misc: misc(),
  subaffiliates: subaffiliates(),
  trafficSource: trafficSource(),
  media: media(),
  mediaBuying: mediaBuying(),
  report: report(),
  domains: domains(),

});
const store = new Vuex.Store({
  modules: modules(),
  state: {
    initTime: new Date().getTime(),
  },
  mutations: {
    reset(state) {
      const s = modules();
      Object.keys(s)
        .forEach((key) => {
          Object.keys(s[key].state)
            .map((key2) => {
              state[key][key2] = s[key].state[key2];
            });
        });
      state.initTime = new Date().getTime();
    },
  },
});
store.subscribeAction((action, state) => {
  eventBus.$emit(`store.${action.type}`, state);
});
store.subscribe((mutation) => {
  eventBus.$emit(`state.${mutation.type}`, mutation.payload);
});
eventBus.$on('logout', () => {
  store.commit('reset');
});

export default store;
