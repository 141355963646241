import unauthorized from './unauthorized';
import unathorized2fa from './unauthorized2fa';
import blocked from './blocked';
import reviewing from './reviewing';
import fail from './fail';
import promoCodes from './affiliates/promoCodes';
import subaffiliates from './subaffiliates';
import daily from './daily';
import media from './media';

import wallets from './affiliates/wallets';
import contacts from './affiliates/contacts';

const MockAdapter = require('axios-mock-adapter');

export default function (api, types) {
  const mock = new MockAdapter(api);

  reviewing(mock, types);
  unauthorized(mock, types);
  blocked(mock, types);
  fail(mock, types);
  unathorized2fa(mock, types);
  promoCodes(mock);
  wallets(mock);
  subaffiliates(mock);
  contacts(mock);
  daily(mock);
  media(mock);
}
