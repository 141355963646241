<template>
  <section class="promo__codes">
    <table-card
      ref="datatable"
      :loading="loading"
      :columns="columns"
      :sort.sync="sorting"
      :sort-by-name="sortByName"
      :sort-dir-name="sortDirName"
      :actions="actions"
      :page.sync="page"
      :page-size.sync="pageSize"
      :rows="rows"
      :count="$store.state.domains.dedicatedDomainCount.count || 0"
      :show-total="false"
      :row-selectable="false"
      :header="$t('routes.domains')"
      dont-fill-empty
      extend-height
      tablet-layout
      i18n-path="reports"
      @fetch-data="fetchDataTable"
    >
      <table-card-search-header
        slot="header"
        class="ui-g-md"
        v-model="search"
        show-actions-mobile
        @search="handleSearch"
      />
    </table-card>

    <edit-domains
      ref="editCode"
      :offset-params="{
        ...offsetParams,
        limit: pageSize,
        search: search,
        media_buying_campaign_id: 'null',
      }"
      @confirm="getPromoCodes"
    />
  </section>
</template>

<script>
import Mobtable from 'src/components/Mobiletable/Mobtable';
import Mobpagination from 'src/components/Mobiletable/MobPagination';
import UrlMixin from 'src/components/Query/url-mixin';
import UrlMixinHooks from 'src/components/Query/url-mixin-hooks';
import InjectPlugins from 'src/components/Report/Components/inject-plugins';
import PeriodWrapper from 'src/views/Dashboard/Reports/Filters/PeriodWrapper.vue';
import TableCard from 'src/components/Cards/TableCard.vue';
import TableCardSearchHeader from 'src/components/Cards/TableCardSearchHeader.vue';
import HelpersMixin from 'src/components/Helpers/helpers-mixin.js';
import { setTimeout } from 'timers';
import { pageSizeMixin, resolvePageSize } from 'src/views/Dashboard/Reports/page_size.js';
import tableFlowMixinGenerator from 'src/views/Dashboard/Reports/tableFlowMixin.js';
import Tooltip from 'src/components/Tooltip.vue';
import EditDomains from './Domains/EditDomains.vue';

const sizes = {
  1080: 20,
  1440: 30,
};
const viewName = '/domains';
const pageLimitProp = 'pageSize';
const pageSize = resolvePageSize(viewName, {
  _default: 15,
  sizes,
});

const defaultParams = {
  totalRows: 0,
  search: '',
  page: 1,
  pageSize,
  sort_column: 'created_at',
  sort_dir: 'desc',
};

const fetch = async (ctx, params) => {
  if (ctx.$_.isEmpty(params)) {
    params = {
      ...defaultParams,
    };
  }

  const trafficSources = () => ctx.$store.dispatch('getTrafficSources', {});
  const promocodeOffers = () => ctx.$store.dispatch('getPromocodeOffers');
  const count = () => ctx.$store.dispatch('getDedicatedDomainCount');
  const promocodes = () => ctx.$store.dispatch('getDedicatedDomain', {
    sort_column: params.sort_column || defaultParams.sort_column,
    sort_dir: params.sort_dir || defaultParams.sort_dir,
    search: params.search || '',
    limit: ctx.pageSize || pageSize,
    ...params,
  });

  const result = await Promise.all([trafficSources(), promocodeOffers(), count(), promocodes()]);

  return [result[2], result[3]];
};

const tableFlowMixin = tableFlowMixinGenerator({
  defaultParams: {
    ...defaultParams,
  },
  params: Object.keys(defaultParams),
  fetch,
  windowRefreshHook: true,
  initPeriod: 'init',
  sortByName: 'sort_column',
  sortDirName: 'sort_dir',
});

export default {
  fetch,
  components: {
    EditDomains,
    PeriodWrapper,
    Mobtable,
    Mobpagination,
    TableCard,
    TableCardSearchHeader,
    Tooltip,
  },
  mixins: [UrlMixin, UrlMixinHooks, InjectPlugins, HelpersMixin, pageSizeMixin, tableFlowMixin],
  props: ['fetchedData', 'passedParams'],
  data() {
    return {
      viewName,
      pageLimitProp,
      loading: false,
      limitSubIds: 5,
      columns: [
        {
          name: 'created_at',
          align: 'left',
          sortable: 'custom',
          filterable: false,
          width: 155,
          format: 'date-time',
          source: 'i18n',
          i18nPath: 'reports',
        },
        {
          name: 'domain_name',
          align: 'left',
          sortable: 'custom',
          filterable: false,
        },
        // {
        //   name: 'registrations',
        //   align: 'center',
        //   filterable: false,
        //   sortable: false,
        //   width: 150,
        // },
      ],
      actions: {
        width: 90,
        name: () => this.$t('profile.apiKeys.table.actions'),
        operations: [
          {
            name: 'edit',
            component: (row, ctx) => {
              const { editCode } = this;
              const span = ctx.$createElement(
                'span',
                {
                  on: {
                    click(e) {
                      e.cancelBubble = true;
                      editCode({
                        promo_code: row.promo_code,
                        affiliateOfferId: row.data.affiliateOfferId,
                        id: row.data.id,
                        traffic_source_id: row.data.traffic_source_id,
                        subids: row.data.subids,
                      });
                    },
                  },
                },
                [
                  ctx.$createElement('i', {
                    attrs: {
                      class: 'far fa-pen text-green',
                    },
                  }),
                ],
              );
              return ctx.$createElement(
                'el-tooltip',
                {
                  attrs: {
                    class: 'el-tooltip icon__wrapper text-green',
                    placement: 'top',
                    content: ctx.$t('postbacks.card.edit'),
                    'open-delay': 500,
                  },
                },
                [span],
              );
            },
          },
        ],
      },
      lastQuery: {},
      isTableReady: false,
    };
  },
  computed: {
    rows() {
      return this.$store.state.domains.dedicatedDomain.map((e) => {
        const subids = [];
        for (let i = 1; i <= this.limitSubIds; i++) {
          const key = `subid${i}`;
          subids.push({ value: e[key] });
        }

        let tail = false;
        while (!tail) {
          const item = subids.pop();

          if (item === undefined) {
            subids.push({ value: null });
            break;
          }
          tail = item.value;
          if (tail) subids.push(item);
        }

        return {
          data: {
            id: e.id,
            affiliateOfferId: e.affiliate_offer_id,
            subids,
            traffic_source_id: e.traffic_source_id,
          },
          domain_name: e.domain_name,
          registrations: e.registrations_count,
          created_at: e.created_at,
        };
      });
    },
  },
  mounted() {},
  created() {
    this.actions.operations.map((e) => {
      if (e.component) {
        e.component = e.component.bind(this);
      }
    });
    this.setFromQuery(this.passedParams);
  },
  activated() {},

  methods: {
    async getPromoCodes(data = {}) {
      this.loading = true;
      await this.$store.dispatch('getDedicatedDomainCount');
      return await this.$store
        .dispatch('getDedicatedDomain', {
          search: this.search || '',
          ...this.offsetParams,
          limit: this.pageSize,
          sort_column: this.sort_column,
          sort_dir: this.sort_dir,
          ...this.period,
          ...data,
          offset: (this.page - 1) * this.pageSize,
        })
        .then(() => {
          setTimeout(() => (this.loading = false), 800);
        });
    },
    editCode(promocode) {
      this.$refs.editCode.open(promocode);
    },
  },
};
</script>

<style lang="scss">
@import 'src/assets/theme/default/promocodes/promocodes';

.promo__codes {
  .table-card-search-header__content {
    flex-wrap: nowrap;
  }
  .ui-tooltip__title-host.bottom-left {
    bottom: -4px;
  }
}
</style>
