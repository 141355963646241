<template>
  <div
    :class="[
      theme,
      size,
      color,
      {'disabled': disabled},
      {'loading': loading},
      {'filled': filled},
      {'empty': isEmpty},
      {'fixed-width': isFixedWidth},
    ]"
    class="ui-button"
    @click="handleClick"
  >
    <ui-icon
      v-if="icon"
      :name="icon"
      :size="getIconSize"
      :color="getIconColor"
      class="btn-icon"
    />
    <span
      v-if="!isEmpty"
      class="btn-text"
    ><slot /></span>
    <div
      v-if="loading"
      class="loading-cover"
    >
      <ui-icon
        :color="getIconColor"
        :size="getIconSize + 4"
        name="loading"
        class="spinner"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiButton',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    color: {
      type: String,
      default: 'yellow',
    },
    theme: {
      type: String,
      default: 'default',
    },
    size: {
      type: String,
      default: 'medium',
    },
    filled: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
    iconSize: {
      type: [String, Number],
      default: '',
    },
    fixedWidth: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    isBtnDisabled() {
      return this.disabled || this.loading;
    },
    getIconSize() {
      if (this.iconSize) {
        return this.iconSize;
      }
      switch (this.size) {
        case 'medium':
          return 12;
        case 'big':
          return 16;
        default:
          return 12;
      }
    },
    getIconColor() {
      if (this.filled) {
        switch (this.color) {
          case 'red':
          case 'green':
            return '#fff';
          default:
            break;
        }
      } else {
        switch (this.color) {
          case 'red':
            return '#ff5c70';
          case 'green':
            return '#5cb000';
          default:
            break;
        }
      }
      return '';
    },
    isFixedWidth() {
      return this.fixedWidth || this.fixedWidth === '';
    },
    isEmpty() {
      return !this.$slots.default;
    },
  },
  methods: {
    handleClick(e) {
      if (this.isBtnDisabled) {
        return;
      }
      this.$emit('click', e);
    },
  },
};
</script>

<style lang="scss" scoped>
  @import 'src/assets/theme/default/components/UiButton';
</style>
