import { debounce } from 'lodash';

const state = () => ({
  count: 0,
  all: [],
  blocked: [],
});
const actions = {
  getSubAffiliatesCount({ commit }, params) {
    return this.$api.getSubAffiliatesCount(params)
      .then((data) => {
        commit('saveSubAffiliatesCount', data.payload);
        return data.payload;
      });
  },

  getSubAffiliates({ commit }, params) {
    return this.$api.getSubAffiliates({ ...params })
      .then((data) => {
        commit('saveSubAffiliates', data.payload);
        return data;
      });
  },
  getSubAffiliatesLazy: debounce(({ dispatch }, params) => {
    dispatch('getSubAffiliates', params);
  }, 100),
};
const mutations = {
  saveSubAffiliates(state, payload) {
    state.all = payload.map(obj => ({
      email: obj.email,
      date: obj.created_at,
      status: obj.account_status,
      percent: obj.referral_percent,
      data: {
        id: obj.id,
      },
    }));
  },

  saveSubAffiliatesCount(state, payload) {
    state.count = payload.count;
  },
};

export default () => ({
  state: state(),
  actions,
  mutations,
});
