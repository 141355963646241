<template>
  <div
    :class="[customClass]"
    class="filters-wrapper"
  >
    <div class="toolbar">
      <div class="filters">
        <ui-button
          :filled="!!filtersOpen || selectedFilters.length > 0"
          icon="filter"
          class="btn"
          @click="toggleFilters"
        >
          Filter
        </ui-button>
        <el-select
          v-model="selectedFilter"
          class="filter_preset"
          clearable
          placeholder="Select Filter"
        />
        <span
          class="reset_link"
          @click="resetFilters"
        >Reset Filters</span>
      </div>
    </div>
    <div class="toolbar filters">
      <div class="wrapper">
        <div class="center">
          <el-collapse
            ref="filters_coll"
            v-model="filtersOpen"
            class="filters_coll"
            accordion
          >
            <el-collapse-item name="filters">
              <div class="filters-wrapper">
                <div
                  v-for="(item, index) in selectedFilters"
                  :key="index"
                  class="filter-row"
                >
                  <!-- remove filter -->
                  <ui-button
                    :icon-size="2"
                    color="red"
                    icon="minus"
                    class="btn"
                    @click="removeFilter(index)"
                  />

                  <!-- select filter -->
                  <el-select
                    v-model="item.type"
                    class="filter_type"
                    clearable
                    placeholder="Select Filter"
                    @change="changeFilter(item.type)"
                  >
                    <el-option
                      v-for="fil in availableFilterTypes"
                      :key="fil.type"
                      :label="$t(`reports.filters.${fil.type}`)"
                      :value="fil.type"
                    />
                  </el-select>

                  <!-- select value -->
                  <el-select
                    v-model="item.values"
                    class="filter_value"
                    clearable
                    filterable
                    collapse-tags
                    placeholder="Select Value"
                    @change="getFiltredFilters(item.type)"
                  >
                    <el-option
                      v-for="val in filterValues[item.type]"
                      :key="val.id || val.code || val.row_id"
                      :label="val[$_.find(allFilters, (el) => el.type === item.type).display]"
                      :value="val.id || val.code || val.row_id"
                    />
                  </el-select>
                </div>
                <!-- controls -->
                <div class="controls">
                  <ui-button
                    :disabled="availableFilterTypes.length === 0 || selectedFilters.length >= allFilters.length"
                    icon="plus"
                    class="btn"
                    @click="addFilter"
                  />
                  <ui-button
                    filled
                    icon="download"
                    class="btn"
                    @click="applyFilters"
                  >
                    Apply Filters
                  </ui-button>
                  <ui-button
                    color="red"
                    class="btn"
                    @click="resetFilters"
                  >
                    Reset Filters
                  </ui-button>
                </div>
              </div>
            </el-collapse-item>
          </el-collapse>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiFilters',
  props: {
    allFilters: {
      type: Array,
      required: true,
    },
    filterValues: {
      type: Object,
      required: true,
    },
    customClass: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      filtersOpen: '',
      selectedFilters: [],
      selectedFilter: '',
    };
  },
  computed: {
    availableFilterTypes() {
      return this.$_.differenceWith(
        this.allFilters,
        this.selectedFilters,
        (af, sf) => sf.type === af.type,
      );
    },
  },
  methods: {
    changeFilter(cur) {
      this.selectedFilters.forEach((e) => {
        if (e.type === cur) e.values = '';
      });
    },
    applyFilters() {
      this.toggleFilters();
      this.$emit('apply-filters', this.selectedFilters);
    },
    removeFilter(i) {
      this.selectedFilters.splice(i, 1);
    },
    resetFilters() {
      this.selectedFilters = [];
      this.$emit('reset-filters');
    },
    toggleFilters() {
      this.filtersOpen = this.filtersOpen ? '' : 'filters';
    },
    getFiltredFilters(type) {
      this.lastChangedFilter = type;
    },
    addFilter() {
      if (
        this.availableFilterTypes.length === 0
        || this.selectedFilters.length >= this.allFilters.length
      ) {
        return;
      }
      this.selectedFilters.push({
        type: '',
        values: [],
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.toolbar-top {
  display: flex;
  justify-content: space-between;
  .filters,
  .refresh {
    display: inline-block;
  }
}
.filter_preset,
.reset_link {
  margin-left: 12px;
}

.toolbar {
  padding: 16px 0;
  border-bottom: 1px solid #eaeaea;
  .wrapper {
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .date-label {
      font-size: 16px;
      align-self: center;
      .back {
        margin-right: 12px;
        color: #20815e;
        text-decoration: none;
      }
      span {
        font-weight: 500;
      }
    }
    .section {
      display: flex;
      align-items: flex-end;
      .pagination {
        margin-right: 20px;
      }
      .left-label {
        color: #303634;
        margin-right: 10px;
      }
      .btn {
        margin-left: 12px;
      }
      .filter_preset {
        margin: 0 12px;
      }
      .reset_link {
        color: #c23e4d;
        font-size: 14px;
        cursor: pointer;
        margin-bottom: 10px;
      }
      .select {
        position: relative;
        .columns {
          position: absolute;
          top: 0px;
          left: -60px;
          opacity: 0;
          visibility: hidden;
        }
      }
    }
    .select-label {
      .range-title {
        display: flex;
        width: 100%;
        justify-content: space-between;
      }
      .text {
        display: block;
        margin-bottom: 5px;
        font-size: 14px;
        color: #303634;
      }
      .select {
        width: 180px;
        height: 34px;
      }
      &:not(:last-child) {
        margin-right: 16px;
      }
    }
  }
  &.filters {
    position: relative;
    padding: 0;
    border: none;
    .wrapper {
      position: absolute;
      left: 0;
      right: 0;
      margin: 0;
      width: 100%;
      z-index: 900;
      background-color: #f7f7f7;
      .center {
        width: 100%;
        margin: 0 auto;
        .filters-wrapper {
          margin-left: 16px;
          box-sizing: border-box;
          padding: 24px 0;
          .filter-row {
            display: flex;
            margin-bottom: 16px;
            .filter_type {
              width: 160px;
              margin: 0 8px;
            }
          }
          .controls {
            display: inline-flex;
            flex-wrap: nowrap;
            width: auto;
            .btn + .btn {
              margin-left: 8px;
            }
          }
        }
      }
    }
  }
}
</style>

<style lang="scss">
.reports-filter {
  .toolbar:first-child {
    border-bottom: none;
    padding-bottom: 0;
  }
  .toolbar.filters .wrapper {
    margin-top: 16px;
  }
}
.live-lastBets {
  .filter_value {
    width: 160px;
    .el-input {
      .el-input__inner {
        height: 34px !important;
      }
    }
  }
  .el-collapse {
    width: 100%;
    border: none;
    .el-collapse-item__header {
      display: none;
    }
    .el-collapse-item__wrap {
      border: none;
      background-color: #f7f7f7;
      .el-collapse-item__content {
        padding: 0;
      }
    }
  }
  .filter_value {
    &.el-select {
      .el-select__tags {
        flex-wrap: nowrap;
        & > span {
          display: flex;
          overflow: hidden;
          .el-tag:first-child {
            display: flex;
            align-items: center;
            .el-select__tags-text {
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }
  .reset_link {
    color: #c23e4d;
    font-size: 14px;
    cursor: pointer;
    margin-bottom: 10px;
  }
}
</style>
