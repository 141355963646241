<template>
  <section class="promo__codes-form-wrap media-buying-modal">
    <modal :show.sync="active" @close="close">
      <h5 slot="header" class="modal-title">
        {{ $t(`promoCodes.addPromocodes.${id ? 'editPromocode' : 'title'}`) }}
      </h5>
      <template>
        <div>
          <form v-loading="loading">
            <div class="d-flex mb-8 mobile-flex-column">
              <div class="select-wrapper w-100" v-if="$store.state.profile.user.referral_program_enabled">
                <label>{{$t('mediaBuying.campaign.affiliate')}}</label>
                <v-select
                  :id="`${$randomizer()}}promocode.sub_affiliate_id`"
                  v-model="sub_affiliate_id"
                  :options="referrals"
                  class="el-input"
                  @change="changeSubAffiliateId"
                />
              </div>
              <div class="select-wrapper w-100 ml-16" :class="{'ml-0': !$store.state.profile.user.referral_program_enabled}">
                <label>{{ $t('promoCodes.addPromocodes.offer') }}</label>
                <v-select
                  :id="`${$randomizer()}promocode.offer`"
                  v-model="offer"
                  :options="offers"
                  class="el-input"
                  @change="offerSelect=true"
                />
                <span v-if="!offerSelect && sending" class="text-danger text-left mt-2">
                  {{ $t('promoCodes.addPromocodes.selectOffer') }}
                </span>
              </div>
            </div>
            <div class="form-group select-wrapper mb-8 mt-0">
              <label>{{ $t('promoCodes.addPromocodes.promocode') }}</label>
              <input
                v-model="form.promocode"
                :placeholder="$t('promoCodes.addPromocodes.promocode')"
                :disabled="id !== ''"
                autocomplete="off"
                type="text"
                class="form-control m-0 w-100"
                @input="changed = false; promocode_error = ''"
                @change="promocode_error = ''"
              >
              <span v-if="promocode_error" class="text-danger text-left mt-2">
                {{ promocode_error }}
              </span>
            </div>

            <el-popover
              ref="popover"
              v-model="warningOpen"
              placement="top-end"
              trigger="manual"
              width="320"
            >
              <p>{{ $t('promoCodes.addPromocodes.changeSubId') }}</p>
              <button
                class="btn btn-outline-primary btn-md"
                @click="warningOpen = false"
              >
                {{ $t('offerRequest.btnCancel') }}
              </button>
              <button
                class="btn btn-primary btn-md ml-3"
                @click="confirmChanges(); warningOpen = false"
              >
                {{ $t('offerRequest.btnConfirm') }}
              </button>
            </el-popover>

            <div v-popover:popover class="select-wrapper mb-8">
              <label>{{ $t('trafficSources.trafficSource') }}</label>
              <v-select
                :id="`${$randomizer()}promocode.traffic_source_id`"
                v-model="traffic_source_id"
                :options="sources"
                class="el-input"
                @change="changeSource"
              />
            </div>
            <div class="row row-wrap__subid">
              <div
                v-for="(item, i) in form.subids"
                :key="i"
                class="col-6 col-6__add-subid"
              >
                <fg-input
                  v-model="item.value"
                  :placeholder="$t('trafficSources.postbacks.notSpecified')"
                  class="no-border form-control-lg mb-0"
                >
                  <label slot="label" class="d-flex justify-content-between">
                    <span>SubID{{ form.subids.indexOf(item) + 1 }}</span>
                    <a
                      v-show="form.subids.indexOf(item) === form.subids.length - 1 && form.subids.length > 1"
                      href="#"
                      class="btn-cross"
                      @click.prevent="popSubIds"
                    >
                      <span>×</span>
                    </a>
                  </label>
                </fg-input>
              </div>
              <div class="col-6 d-flex align-items-end col-6__add-subid">
                <button
                  :disabled="form.subids.length >= 5"
                  href="#"
                  class="btn btn-block btn-outline-primary btn-sm mt-24 mb-0"
                  @click.prevent="form.subids.length < 5 && addSubId()"
                >
                  + {{ $t('promoCodes.addPromocodes.addAnotherSubid') }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </template>
      <div slot="footer" class="text-right d-flex align-items-baseline">
        <a class="btn btn-outline-primary btn-sm" @click="close">
          {{ $t('promoCodes.addPromocodes.cancel') }}
        </a>
        <a class="btn btn-primary btn-sm ml-3" :disabled="loading" @click="send">
          <span v-if="!loading">
            {{ $t(`promoCodes.addPromocodes.${id ? 'send' : 'title'}`) }}
          </span>
          <i v-if="loading" class="fas fa-spinner spinner-hide"/>
        </a>
      </div>
    </modal>
  </section>
</template>

<script>
import { Select, Option } from 'element-ui';
import { Modal } from 'src/components';

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    Modal,
  },
  data() {
    return {
      active: false,
      offer: '',
      changed: false,
      offerSelect: false,
      sending: false,
      promocode_error: false,
      traffic_source_id: '',
      sub_affiliate_id: 0,
      form: {
        promocode: '',
        subids: [{ value: '' }],
      },
      loading: false,
      id: '',
      warningOpen: false,
    };
  },
  computed: {
    invalid_promocode() {
      return this.changed && !this.form.promocode.trim();
    },
    offers() {
      let array = [];

      if (this.sub_affiliate_id !== 0 && !this.loading) {
        array = this.$store.state.offers.my;
      } else {
        array = this.$store.state.promocode.offers;
      }
      return array.map(e => ({
        id: e.id,
        text: e.name,
      }))
        .concat({
          id: '',
          text: this.$t('promoCodes.addPromocodes.selectOffer'),
        });
    },
    sources() {
      return this.$store.state.trafficSource.list.map(e => ({
        id: e.data.id,
        text: e.name,
      }))
        .concat({
          id: '',
          text: this.$t('offerRequest.noTrafficSources'),
        });
    },
    referrals() {
      return this.$store.state.subaffiliates.all.map(e => ({
        id: e.data.id,
        text: e.email,
      }))
        .concat({
          id: 0,
          text: this.$t('mediaBuying.campaign.my_account'),
        });
    },
  },
  watch: {
    traffic_source_id(id) {
      if (!this.id) {
        this.form.subids = [];
        const source = this.$store.state.trafficSource.list.filter(e => e.data.id === id)[0];
        if (!source) {
          this.form.subids = [{ value: '' }];
          return;
        }
        // Push subids
        Object.keys(source)
          .map((k) => {
            if (k.startsWith('subid')) {
              if (source[k]) {
                this.form.subids.push({
                  value: source[k],
                });
              } else {
                this.form.subids.push({
                  value: '',
                });
              }
            }
          });
        // reduce subids
        for (let i = this.form.subids.length - 1; i >= 1; i--) {
          if (!this.form.subids[i].value) {
            this.form.subids.pop();
          } else {
            break;
          }
        }
      }
    },
  },
  methods: {
    open(row) {
      // this.$store.dispatch('getPromocodeOffers');
      this.reset();
      this.active = true;
      this.loading = true;
      if (row.data) {
        const id = row.affiliate_id ? { sub_affiliate_id: row.sub_affiliate_id } : {};
        const offers = this.$store.dispatch('getAffiliateOffers', id);
        const traffic = this.$store.dispatch('getTrafficSources', id);

        Promise.all([offers, traffic])
          .then((res) => {
            this.offer = row.data.affiliateOfferId;
            this.traffic_source_id = row.data.traffic_source_id;
            this.form.subids = this.$_.cloneDeep(row.data.subids);
            this.offerSelect = true;
            this.loading = false;
          });

        this.sub_affiliate_id = row.affiliate_id ? row.sub_affiliate_id : 0;
        this.form.promocode = row.promo_code;
        this.id = row.data.id;
      } else {
        this.$store.dispatch('getTrafficSources', {}).then(() => {
          this.loading = false;
        });
      }
    },
    close() {
      this.active = false;
      this.offerSelect = false;
      this.form.promocode = '';
      this.sub_affiliate_id = 0;
      this.id = '';
      this.reset();
    },
    reset() {
      this.changed = false;
      this.sending = false;
      this.offer = '';
      this.traffic_source_id = '';
      this.form.subids = [{ value: '' }];
      this.promocode_error = '';
      this.loading = false;
      this.warningOpen = false;
    },
    changeSubAffiliateId() {
      this.reset();
      this.loading = true;
      const id = this.sub_affiliate_id !== '0' ? { sub_affiliate_id: this.sub_affiliate_id } : {};
      const offers = this.$store.dispatch('getAffiliateOffers', id);
      const traffic = this.$store.dispatch('getTrafficSources', id);

      Promise.all([offers, traffic])
        .then(() => {
          this.loading = false;
        });
    },
    send() {
      this.sending = true;
      this.changed = true;
      if (this.invalid_promocode) {
        this.promocode_error = this.$t('promoCodes.alerts.promocodeInvalid');
        return;
      }

      if (!this.offer) {
        this.offerSelect = false;
        return;
      }

      const data = {
        traffic_source_id: this.traffic_source_id !== '' ? this.traffic_source_id : null,
        affiliate_offer_id: this.offer,
        promo_code: this.form.promocode,
        status: 'active',
        media_buying_campaign_id: this.$route.params.id,
      };

      this.form.subids.forEach((cur, i) => {
        data[`subid${parseInt(i) + 1}`] = cur.value;
      });

      this.loading = true;
      if (!this.id) {
        this.$store.dispatch('createPromocode', data)
          .then(() => {
            this.close();
            this.$emit('addPromoCode');
            this.$alert.success(this.$t('promoCodes.alerts.wasCreated'));
          })
          .catch((err) => {
            this.promocode_error = err.response.data.errors.promo_code[0].message;
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.$store.dispatch('putPromocode', {
          id: this.id,
          opts: data,
        })
          .then(() => {
            this.close();
            this.$emit('addPromoCode');
            this.$alert.success(this.$t('promoCodes.alerts.wasUpdated'));
          })
          .catch(() => {
            this.$alert.error(this.$t('promoCodes.alerts.couldntCreate'));
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    changeSource(value) {
      if (this.id) {
        if (!value) {
          // if no traffic source selected clear subids
          this.form.subids = [{ value: null }];
          return;
        }
        // subids from promocodes
        let promocodeSubIds = this.$store.state.promocode.promocodes.filter(e => e.id === this.id)[0];
        promocodeSubIds = _.pick(promocodeSubIds, ['subid1', 'subid2', 'subid3', 'subid4', 'subid5']);

        // subids from ts
        let source = this.$store.state.trafficSource.list.filter(e => e.data.id === value)[0];
        source = _.pick(source, ['subid1', 'subid2', 'subid3', 'subid4', 'subid5']);

        // subids from old ts
        let oldSource = this.$store.state.trafficSource.list.filter(e => e.data.id === this.oldValue)[0];
        oldSource = _.pick(oldSource, ['subid1', 'subid2', 'subid3', 'subid4', 'subid5']);

        // take current subids
        const subids = {};
        for (let i = 0; i < 5; i++) {
          const key = `subid${i + 1}`;
          subids[key] = this.form.subids[i] ? this.form.subids[i].value : null;
        }

        // if current subids is equal with subids from promocodes or trafficsources
        if (_.isEqual(subids, oldSource)) {
          this.form.subids = [];
          this.addSubids(source);
        } else {
          this.warningOpen = true;
        }
        this.oldValue = value;
      }
    },
    confirmChanges() {
      this.form.subids = [];
      const source = this.$store.state.trafficSource.list.filter(e => e.data.id === this.traffic_source_id)[0];
      this.addSubids(source);
    },
    addSubids(source) {
      if (!source) {
        this.form.subids = [{ value: null }];
        return;
      }
      this.form.subids = [];
      // Push subids
      Object.keys(source).map((k) => {
        if (k.startsWith('subid')) {
          const subid_index = parseInt(k.replace('subid', '')) - 1;
          if (source[k]) {
            this.form.subids.push({
              value: source[k],
            });
          } else {
            this.form.subids.push({
              value: null,
            });
          }
        }
      });
      // reduce subids
      for (let i = this.form.subids.length - 1; i >= 1; i--) {
        if (!this.form.subids[i].value) this.form.subids.pop();
        else break;
      }
    },
    addSubId() {
      this.form.subids.push({
        value: '',
      });
    },
    popSubIds() {
      this.form.subids.pop();
    },
  },
};
</script>

<style lang="scss">
@import 'src/assets/theme/default/promocodes/addpromocode';
</style>
