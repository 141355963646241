<template>
  <div
    class="select-block-wrap select-block-wrap__nb"
  >
    <span class="pl-16">
      {{filterElement.title}}
    </span>
    <div class="el-select__wrap">
      <v-select
        class="el-input el-input-select"
        v-model="filterElement.value"
        :options="filterElement.options"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiFiltersItem',

  props: {
    filterElement: {
      type: Object,
      default() {
        return {};
      },
    },
  },
};
</script>
