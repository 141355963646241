// Campaigns
export function getCampaignsCount({ root_campaign_id, status, search }) {
  return this.affiliates
    .get('media-buying/campaigns/count', {
      params: {
        root_campaign_id,
        status,
        search,
      },
    })
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err));
}

export function getCampaignsAttentionCounters() {
  return this.affiliates
    .get('media-buying/campaigns/attention-counters')
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err));
}

export function getCampaigns({
  root_campaign_id,
  status,
  sort_column,
  sort_dir,
  search,
  limit,
  offset,
  needs_attention_first,
}) {
  return this.affiliates
    .get('media-buying/campaigns', {
      params: {
        root_campaign_id,
        status,
        sort_column,
        sort_dir,
        search,
        limit,
        offset,
        needs_attention_first,
      },
    })
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err));
}

export function createCampaign(payload) {
  return this.affiliates
    .post('media-buying/campaigns', payload)
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err));
}

export function getCampaignsTrackable({ sub_affiliate_id, search }) {
  return this.affiliates
    .get('media-buying/campaigns/trackable', {
      params: {
        sub_affiliate_id,
        search,
      },
    })
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err));
}

export function editCampaign(payload) {
  return this.affiliates
    .put(`media-buying/campaigns/${payload.id}`, payload)
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err));
}

export function getCampaign(id) {
  return this.affiliates
    .get(`media-buying/campaigns/${id}`)
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err));
}

export function getCampaignSummaryReport({
  id,
  from,
  to,
  traffic_filter,
  affiliate_offer_id,
  player_id,
  traffic_source_id,
  country_code,
  subid1,
  subid2,
  subid3,
  subid4,
  subid5,
  sub_affiliate_id,
}) {
  return this.affiliates
    .get(`/media-buying/campaigns/${id}/summary-report`, {
      params: {
        id,
        from,
        to,
        traffic_filter,
        affiliate_offer_id,
        player_id,
        traffic_source_id,
        country_code,
        subid1,
        subid2,
        subid3,
        subid4,
        subid5,
        sub_affiliate_id,
      },
    })
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err));
}

export function postCampaignArchive(id) {
  return this.affiliates
    .post(`media-buying/campaigns/${id}/archive`)
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err));
}

export function postCampaignActivate(id) {
  return this.affiliates
    .post(`media-buying/campaigns/${id}/activate`)
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err));
}

// Payments
export function getPayments(params) {
  return this.affiliates
    .get('media-buying/payments', {
      params,
    })
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err));
}

export function createPayment(payload) {
  return this.affiliates
    .post('media-buying/payments', payload)
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err));
}

export function editPayment(payload) {
  return this.affiliates
    .put(`media-buying/payments/${payload.id}`, payload)
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err));
}

export function getPayment(id) {
  return this.affiliates
    .get(`media-buying/payments/${id}`)
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err));
}

export function postPaymentComments(payload) {
  return this.affiliates
    .post(`media-buying/payments/${payload.id}/comments`, payload)
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err));
}

export function getPaymentComment(params) {
  return this.affiliates
    .get(`media-buying/payments/${params.id}/comments`, {
      params,
    })
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err));
}

export function postPaymentMarkAsRead(id) {
  return this.affiliates
    .post(`media-buying/payments/${id}/mark-as-read`)
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err));
}

export function postPaymentMarkAsUnread(id) {
  return this.affiliates
    .post(`media-buying/payments/${id}/mark-as-unread`)
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err));
}

// export function getPaymentCommentsCount(id) {
//   return this.affiliates
//     .get(`media-buying/payments/${id}/comments/count`)
//     .then(res => Promise.resolve(res.data))
//     .catch(err => Promise.reject(err));
// }

// Posts
export function getPosts(params) {
  return this.affiliates
    .get('media-buying/posts', {
      params,
    })
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err));
}

export function createPost(payload) {
  return this.affiliates
    .post('media-buying/posts', payload)
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err));
}

export function editPost(payload) {
  return this.affiliates
    .put(`media-buying/posts/${payload.id}`, payload)
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err));
}

export function getPost(id) {
  return this.affiliates
    .get(`media-buying/posts/${id}`)
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err));
}

export function deletePost(id) {
  return this.affiliates
    .delete(`media-buying/posts/${id}`, id)
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err));
}

export function postToReview(id) {
  return this.affiliates
    .post(`media-buying/posts/${id}/to-review`)
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err));
}

export function setPostComments(payload) {
  return this.affiliates
    .post(`media-buying/posts/${payload.id}/comments`, payload)
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err));
}

export function getPostComment({ id, sort_column, sort_dir }) {
  return this.affiliates
    .get(`media-buying/posts/${id}/comments`, {
      params: { sort_column, sort_dir },
    })
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err));
}

export function getPostCommentsCount(id) {
  return this.affiliates
    .get(`media-buying/posts/${id}/comments/count`)
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err));
}

export function postPostMarkAsRead(id) {
  return this.affiliates
    .post(`media-buying/posts/${id}/mark-as-read`)
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err));
}

export function postPostMarkAsUnread(id) {
  return this.affiliates
    .post(`media-buying/posts/${id}/mark-as-unread`)
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err));
}

// Files
export function uploadFiles(params) {
  return this.affiliates
    .post('files', params)
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err));
}

export function uploadSettings() {
  return this.affiliates
    .get('files/upload-settings')
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err));
}

// comments
export function updateComments(payload) {
  const { id } = payload;
  const data = {
    content: payload.content,
    file_ids: payload.file_ids,
  };
  return this.affiliates
    .put(`comments/${id}`, data)
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err));
}

export function deleteComments(id) {
  return this.affiliates
    .delete(`comments/${id}`)
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err));
}

export function getTrackingUrl(payload) {
  return this.affiliates
    .get(`affiliate-offers/${payload.id}/tracking-url`, { params: payload })
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err));
}

export function getRedirectUrl(id, payload) {
  return this.affiliates
    .get(`affiliate-offers/${id}/redirect-url`, { params: payload })
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err));
}

export function getMarketingCampaigns(payload) {
  return this.affiliates
    .get('marketing-campaigns', { params: payload })
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err));
}

export function getLandings(payload) {
  return this.affiliates
    .get('/landings', { params: payload })
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err));
}

export function getPaymentMethod({
  sort_column,
  sort_dir,
  search,
  limit,
  offset,
}) {
  return this.affiliates
    .get('/media-buying/payment-methods', {
      params: {
        sort_column,
        sort_dir,
        search,
        limit,
        offset,
      },
    })
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err));
}
