export default {
  get(key) {
    const cookies = document.cookie.split('; ');
    for (let i = 0; i < cookies.length; i++) {
      const c = cookies[i].replace(/^\s+/, '');
      if (c.indexOf(`${key}=`) === 0) {
        return decodeURIComponent(c.substring(key.length + 1)
          .split('+')
          .join(' '));
      }
    }
    return null;
  },
  set(name, value, expires, secure = false) {
    const date = new Date();
    date.setTime(date.getTime() + (expires || 0));
    let domain = location.hostname.split('.');
    domain = domain.length > 2 ? domain.slice(domain.length - 2, domain.length)
      .join('.') : domain.join('.');
    document.cookie = `${name}=${encodeURIComponent(value)};${secure ? 'secure;' : ''}expires=${date.toGMTString()};domain=.${domain};path=/`;
  },
  remove(name) {
    const date = new Date(0);
    document.cookie = `${name}=; path=/; expires=${date.toUTCString()}`;
  },
};
