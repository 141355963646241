import underscore from 'lodash';
import moment from 'moment';
import Vue from 'vue';

export default {
  beforeCreate() {
    if (!this.$_) Vue.prototype.$_ = underscore;
    if (!this.$moment) {
      Vue.prototype.$moment = moment;
    }
  },
};
