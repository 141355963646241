export default {
  saveItems(state, data) {
    state.items = data.payload;
  },
  saveCount(state, data) {
    state.totals = { ...state.totals, count: data.payload.count };
  },
  saveMediaContent(state, payload) {
    state.mediacontent = payload;
  },
  saveSites(state, payload) {
    state.sites = payload;
  },
};
