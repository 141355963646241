<template>
  <section class="promo__codes-form-wrap">
    <modal :show.sync="active" @close="close">
      <h5 slot="header" class="modal-title">
        {{ $t('promoCodes.addPromocodes.editPromocode') }}
      </h5>

      <template>
        <div>
          <form @keyup.enter="send">
            <div class="form-group select-wrapper mb-8 mt-0">
              <label>{{ $t('promoCodes.addPromocodes.promocode') }}</label>
              <input
                v-model="form.promocode"
                autocomplete="off"
                type="text"
                class="form-control m-0 w-100"
                disabled
              >
            </div>
            <div class="select-wrapper mb-8">
              <label>{{ $t('promoCodes.addPromocodes.offer') }}</label>
              <v-select
                :id="`${id}promocode.offers`"
                key="id"
                v-model="offer"
                :options="offers"
                :disabled="false"
                class="el-input"
                label="name"
                @change="offerSelect=true"
              />
              <span v-if="!offerSelect && sending" class="text-danger text-left">
                {{ $t('promoCodes.addPromocodes.selectOffer') }}
              </span>
            </div>

            <el-popover
              ref="popover"
              v-model="warningOpen"
              placement="top-end"
              trigger="manual"
              width="320"
            >
              <p>{{ $t('promoCodes.addPromocodes.changeSubId') }}</p>
              <button
                class="btn btn-outline-primary btn-md"
                @click="warningOpen = false"
              >
                {{ $t('offerRequest.btnCancel') }}
              </button>
              <button
                class="btn btn-primary btn-md ml-3"
                @click="confirmChanges(); warningOpen = false"
              >
                {{ $t('offerRequest.btnConfirm') }}
              </button>
            </el-popover>

            <div
              v-popover:popover
              class="select-wrapper mb-8"
            >
              <!-- select traffic source -->
              <label>{{ $t('trafficSources.trafficSource') }}</label>
              <v-select
                :id="`${id}promocode.sources`"
                key="id"
                v-model="traffic_source_id"
                :options="sources"
                :disabled="false"
                class="el-input"
                label="name"
                @change="changeSource"
              />
            </div>

            <div class="row row-wrap__subid">
              <div
                v-for="(item, i) in form.subids"
                :key="i"
                class="col-6 col-6__add-subid"
              >
                <fg-input
                  v-model="item.value"
                  :placeholder="$t('trafficSources.postbacks.notSpecified')"
                  class="no-border form-control-lg mb-0"
                >
                  <label
                    slot="label"
                    class="d-flex justify-content-between"
                  >
                    <span>SubID{{ form.subids.indexOf(item) + 1 }}</span>
                    <a
                      v-show="form.subids.indexOf(item) === form.subids.length - 1 && form.subids.length > 1"
                      href="#"
                      class="btn-cross"
                      @click.prevent="popSubIds"
                    >
                      <span>×</span>
                    </a>
                  </label>
                </fg-input>
              </div>
              <div class="col-6 d-flex align-items-end col-6__add-subid">
                <button
                  :disabled="form.subids.length >= 5"
                  href="#"
                  class="btn btn-block btn-outline-primary btn-sm mt-24 mb-0"
                  @click.prevent="form.subids.length < 5 && addSubId()"
                >
                  + {{ $t('promoCodes.addPromocodes.addAnotherSubid') }}
                </button>
              </div>
            </div>
          </form>
        </div>
      </template>
      <div slot="footer" class="text-right d-flex align-items-baseline">
        <a class="btn btn-outline-primary btn-sm mb-4" @click="close">
          {{ $t('promoCodes.addPromocodes.cancel') }}
        </a>
        <a class="btn btn-primary btn-sm ml-3" @click="send">
          <span v-if="!$store.state.errors.loading">{{ $t('promoCodes.addPromocodes.send') }}</span>
          <i v-if="$store.state.errors.loading" class="fas fa-spinner spinner-hide"/>
        </a>
      </div>
    </modal>
  </section>
</template>

<script>
import { Select, Option } from 'element-ui';
import { Modal } from 'src/components';
import { isEqual } from 'lodash';
import VSelectMenu from 'src/components/VSelectMenu.vue';

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    Modal,
    VSelectMenu,
  },
  props: ['offsetParams'],
  data() {
    return {
      warningOpen: false,
      active: false,
      offer: '',
      id: '',
      changed: false,
      offerSelect: false,
      sending: false,
      traffic_source_id: '',
      oldTSid: '',
      form: {
        promocode: '',
        subids: [{ value: null }],
      },
    };
  },

  computed: {
    offers() {
      const offers = this.$store.state.promocode.offers.map(e => ({
        text: e.name,
        id: e.id,
      }));
      return [{ id: '', text: this.$t('promoCodes.addPromocodes.selectOffer') }].concat(offers);
    },
    sources() {
      const sources = this.$store.state.trafficSource.list.map(e => ({
        id: e.data.id,
        text: e.name,
      }));
      return [{ id: '', text: this.$t('offerRequest.noTrafficSources') }].concat(sources);
    },
  },
  activated() {
    this.$store.dispatch('getPromocodeOffersLazy');
  },

  watch: {
    traffic_source_id(value, oldValue) {
      this.oldTSid = oldValue;
    },
  },
  methods: {
    selectOffer(v) {
      this.offer = v.id;
      this.offerSelect = true;
    },
    changeSource(value) {
      if (!value) {
        // if no traffic source selected clear subids
        this.form.subids = [{ value: null }];
        return;
      }
      // subids from promocodes
      let promocodeSubIds = this.$store.state.promocode.promocodes.filter(e => e.id === this.id)[0];
      promocodeSubIds = _.pick(promocodeSubIds, ['subid1', 'subid2', 'subid3', 'subid4', 'subid5']);

      // subids from ts
      let source = this.$store.state.trafficSource.list.filter(e => e.data.id === value)[0];
      source = _.pick(source, ['subid1', 'subid2', 'subid3', 'subid4', 'subid5']);

      // subids from old ts
      let oldSource = this.$store.state.trafficSource.list.filter(e => e.data.id === this.oldValue)[0];
      oldSource = _.pick(oldSource, ['subid1', 'subid2', 'subid3', 'subid4', 'subid5']);

      // take current subids
      const subids = {};
      for (let i = 0; i < 5; i++) {
        const key = `subid${i + 1}`;
        subids[key] = this.form.subids[i] ? this.form.subids[i].value : null;
      }

      // if current subids is equal with subids from promocodes or trafficsources
      if (_.isEqual(subids, oldSource)) {
        this.form.subids = [];
        this.addSubids(source);
      } else {
        // show warning
        this.warningOpen = true;
      }
      this.oldValue = value;
    },
    confirmChanges() {
      this.form.subids = [];
      const source = this.$store.state.trafficSource.list.filter(e => e.data.id === this.traffic_source_id)[0];
      this.addSubids(source);
    },
    addSubids(source) {
      if (!source) {
        this.form.subids = [{ value: null }];
        return;
      }
      this.form.subids = [];
      // Push subids
      Object.keys(source).map((k) => {
        if (k.startsWith('subid')) {
          const subid_index = parseInt(k.replace('subid', '')) - 1;
          if (source[k]) {
            this.form.subids.push({
              value: source[k],
            });
          } else {
            this.form.subids.push({
              value: null,
            });
          }
        }
      });
      // reduce subids
      for (let i = this.form.subids.length - 1; i >= 1; i--) {
        if (!this.form.subids[i].value) this.form.subids.pop();
        else break;
      }
    },

    async open(opts) {
      await this.$store.dispatch('getTrafficSources', {});
      this.offer = opts.affiliateOfferId;
      this.id = opts.id;
      this.form.promocode = opts.promo_code;
      this.traffic_source_id = opts.traffic_source_id;
      this.active = true;
      this.offerSelect = true;
      this.form.subids = opts.subids;
    },
    close() {
      this.form.subids = [{ value: null }];
      this.active = false;
      this.form.promocode = '';
      this.changed = false;
      this.traffic_source_id = '';
      this.offer = '';
      this.offerSelect = false;
      this.sending = false;
      this.warningOpen = false;
    },
    send() {
      this.sending = true;
      this.changed = true;
      if (!this.offer) {
        this.offerSelect = false;
        return;
      }

      const data = {
        traffic_source_id: this.traffic_source_id,
        affiliate_offer_id: this.offer,
        promo_code: this.form.promocode,
        status: 'active',
      };

      this.form.subids.forEach((cur, i) => {
        data[`subid${parseInt(i) + 1}`] = cur.value;
      });

      this.$store
        .dispatch('putPromocode', { id: this.id, opts: data })
        .then((res) => {
          this.close();
          this.$store.dispatch('getPromocodesLazy', { ...this.offsetParams });
          this.$alert.success(this.$t('promoCodes.alerts.wasUpdated'));
        })
        .catch((err) => {
          this.$alert.error(this.$t('promoCodes.alerts.couldntCreate'));
        });
    },

    addSubId() {
      this.form.subids.push({
        value: null,
      });
    },
    popSubIds(item) {
      this.form.subids.pop();
    },
  },
};
</script>

<style lang="scss">
@import 'src/assets/theme/default/promocodes/addpromocode';
</style>
