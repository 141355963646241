export default {
  methods: {
    filterColumns(columns = [], group_by = '') {
      const { reportSettings } = this.$store.state.profile.user;
      if (group_by && !reportSettings.group_by.includes(group_by)) return () => columns;
      else {
        return (predicate) => {
          columns.data = reportSettings.group_by;
          return columns.filter(predicate);
        };
      }
    },
  },
};
