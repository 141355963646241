import { debounce } from 'lodash';

export default {
  saveProfile({ dispatch }, data) {
    return this.$api.putProfile(data)
      .then((data) => {
        dispatch('getProfileData');
        return data;
      });
  },

  saveProfilePassword({ dispatch }, data) {
    return this.$api.postProfileUpdatePassword(data)
      .then((data) => {
        dispatch('getProfileData');
        return data;
      });
  },

  saveProfileLanguage({ dispatch }, data) {
    return this.$api.postProfileUpdateLanguage(data)
      .then(data => data);
  },

  saveProfilePaymentMethod({ dispatch }, data) {
    return this.$api.postProfileUpdatePaymentMethod(data)
      .then((data) => {
        dispatch('getProfileData');
        return data;
      });
  },

  otpEnable({ dispatch }, data) {
    return this.$api.postTotpEnable(data)
      .then((data) => {
        dispatch('getProfileData');
        return data;
      });
  },

  otpDisable({ dispatch }, data) {
    return this.$api.postTotpDisable(data)
      .then((data) => {
        dispatch('getProfileData');
        return data;
      });
  },

  createOtpBackupCodes({ dispatch }, data) {
    return this.$api.postTotpBackupCodesGenerateNewCodes(data)
      .then((data) => {
        dispatch('getProfileData');
        return data.payload;
      });
  },

  getProfileData({ commit }) {
    return this.$api.getProfile()
      .then((data) => {
        const profileData = data.payload;
        commit('saveProfileData', profileData);
        return Promise.resolve(profileData);
      });
  },

  getApiKeys({ commit }, opts) {
    return this.$api.getApiKeys({ ...opts })
      .then((data) => {
        commit('saveApiKeys', data.payload);
        return data;
      });
  },

  getApiKeysLazy: debounce(({ dispatch }, opts) => dispatch('getApiKeys', opts), 100),

  getApiKeysCount({ commit }, opts) {
    return this.$api.getApiKeysCount({ ...opts })
      .then((data) => {
        commit('saveApiKeysCount', data.payload.count);
        return data;
      });
  },

  getApiKeysCountLazy: debounce(({ dispatch }, opts) => dispatch('getApiKeysCount', opts), 100),

  createApiKey({ dispatch }, data) {
    return this.$api.postApiKeys(data)
      .then((data) => {
        dispatch('getApiKeysCount');
        return data;
      });
  },

  deactivateApiKey({ dispatch }, opts) {
    return this.$api.putApiKey(opts.id, { status: 'inactive' })
      .then((data) => {
        delete opts.id;
        dispatch('getApiKeys', opts);
        return data;
      });
  },

  activateApiKey({ dispatch }, opts) {
    return this.$api.putApiKey(opts.id, { status: 'active' })
      .then((data) => {
        delete opts.id;
        dispatch('getApiKeys', opts);
        return data;
      });
  },

  deleteApiKey(undefined, id) {
    return this.$api.deleteApiKey(id);
  },

  saveNotificationSettings({ dispatch }, { enabled, channel }) {
    return this.$api.saveNotificationSettings({
      service_domain_changes_notifications_enabled: enabled,
      service_domain_changes_notifications_channel: channel,
    });
  },

  addAdditionalEmail({ dispatch }, { email }) {
    return this.$api.addAdditionalEmail({
      email,
    });
  },

  deleteAdditionalEmail({ dispatch }, id) {
    return this.$api.deleteAdditionalEmail(id);
  },

  resendAdditionalEmail({ dispatch }, id) {
    return this.$api.resendAdditionalEmail(id);
  },
};
