<template>
  <div class="the-pagination">
    <el-select
      v-if="showSizeSelect"
      v-model="currentPageSize"
      placeholder="Page Size"
      class="size-select"
      popper-class="wrap-select-options"
      size="mini"
    >
      <template slot="prefix">
        <ui-icon
          :size="10"
          name="hamburger"
          class="icon-rows"
        />
      </template>
      <el-option
        v-for="item in pageSizes"
        :key="item"
        :label="item"
        :value="item"
      />
    </el-select>
    <i
      class="fas fa-chevron-double-left"
      :class="{ disabled : currentPage <= 1 }"
      @click="currentPage = 1"
    ></i>
    <i
      class="fas fa-chevron-left"
      :class="{ disabled : currentPage <= 1 }"
      @click="currentPage > 1 ? currentPage -= 1 : ''"
    ></i>
    <ui-input
      ref="input"
      v-model="currentPageInp"
      size="big"
      type="text"
      class="page-input"
      autosize
    />
    <span class="label-info">{{ $t('customReports.of') }} {{ totalPages || 1 }}</span>
    <i
      class="fas fa-chevron-right"
      :class="{ disabled : currentPage >= totalPages }"
      @click="currentPage < totalPages ? currentPage += 1 : ''"
    ></i>
    <i
      class="fas fa-chevron-double-right"
      :class="{ disabled : currentPage >= totalPages }"
      @click="currentPage < totalPages ? currentPage = totalPages : ''"
    ></i>
  </div>
</template>

<script>
export default {
  name: 'UiPagination',
  props: {
    page: {
      type: Number,
      required: true,
    },
    pageSize: {
      type: Number,
      required: true,
    },
    count: {
      type: Number,
      required: true,
    },
    pageSizes: {
      type: Array,
      default() {
        return [
          10,
          20,
          50,
          100,
        ];
      },
    },
    showSizeSelect: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentPage: this.page,
      currentPageInp: this.page,
      currentPageSize: this.pageSize,
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.count / this.pageSize);
    },
    currentPageInpChangeDebounced() {
      return this.$_.debounce(this.currentPageInpChange, 500);
    },
  },
  watch: {
    page(value) {
      this.currentPage = value;
    },
    count() {
      if (parseInt(this.currentPage, 10) > this.totalPages) {
        this.currentPage = 1;
      }
    },
    currentPage(nv) {
      if (!nv || nv < 1 || nv > this.totalPages) {
        this.currentPage = 1;
      }

      this.currentPageInp = this.currentPage.toString();
      this.$emit('page-change', this.currentPage, this.currentPageSize);
    },
    currentPageInp(nv) {
      this.currentPageInpChangeDebounced(nv);
    },
    currentPageSize(nv) {
      this.currentPageSize = parseInt(nv, 10);
      this.currentPage = 1;
      this.$emit('page-change', this.currentPage, this.currentPageSize);
    },
  },
  methods: {
    resetPagination() {
      this.currentPage = 1;
    },
    currentPageInpChange(value) {
      const v = parseInt(value.replace(/[^0-9]/g, ''), 10);

      if (v !== this.currentPage) {
        this.currentPage = v;
      }
    },
  },
};
</script>

<style lang="scss">
@import "src/assets/theme/default/reports/colors.scss";
@import "src/assets/theme/default/reports/icons.scss";

.the-pagination {
  display: flex;
  align-items: center;

  .btn {
    height: 14px;
    width: 16px;
    cursor: pointer;
    margin: 0 !important;
    padding: 0;

    &.disabled {
      filter: grayscale(100%);
      opacity: .5;
      cursor: not-allowed;
    }
  }
  .fas {
    font-size: 14px;
    width: 15px;
    text-align: center;
    color: var(--table-link-color);
    cursor: pointer;
    &.disabled {
      pointer-events: none;
      opacity: .5;
      cursor: not-allowed;
    }
  }

  .size-select {
    width: 64px;
    margin-right: 6px;
  }

  .page-input {
    width: 39px !important;
    height: 34px;
    margin: 0 4px;
  }

  .label-info {
    font-size: 14px;
    white-space: nowrap;
  }

  .el-select .el-input .el-input__inner {
    padding-right: 17px;
    border-color: #d3d3d3 !important;
  }

  .el-input {
    .el-input__inner {
      padding-left: 20px;
      text-align: center;
      height: 34px;
    }

    .el-input__prefix {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: 2px;
      i {
        transform: scaleX(.9);
      }
    }

    .el-input__suffix {
      i {
        display: block;
        position: absolute;
        width: 6px;
        height: 6px;
        border: 2px solid #7d7d7d;
        border-left: none;
        border-top: none;
        transform: rotate(45deg);
        top: 13px;
        right: 3px;
        &.is-reverse {
          transform: rotate(225deg);
          top: 15px;
        }
        &:before {
          display: none;
        }
      }
    }
  }

  .page-input {
    .input-element {
      text-align: center;
      padding: 0 6px !important;
    }
  }

  @media (max-width: 650px) {
    display: none;
    width: 100%;
    justify-content: space-between;
    .el-select {
      width: 50%;
    }
  }
}

.el-select-dropdown.wrap-select-options {
  max-width: 64px;
  .el-select-dropdown__item {
    padding: 0 16px;
  }
}
</style>
