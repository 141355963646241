export default {
  props: {
    bookmarks: {
      type: Array,
      default: () => [],
    },
    reports: {
      type: Array,
      default: () => [],
    },
    currentReport: {
      type: Number,
      default: 0,
    },
    allDimension: {
      type: Array,
      default: () => [],
    },
    isPopoverEditIndex: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      isLoadingBookmark: false,
    };
  },
  methods: {
    getBookmarks() {
      this.isLoadingBookmark = true;
      return this.$api.getBookmarks()
        .then((response) => {
          this.isLoadingBookmark = false;
          this.$emit('update:bookmarks', response.payload);
          this.onBookmarksUpdated();

          // if(this.bookmarks.length === 0) {
          //   this.isPopoverVis = false;
          // }
        })
        .catch((error) => {
          console.log(error);
          this.isLoadingBookmark = false;
        });
    },
    /** @override */
    onBookmarksUpdated() {},

    saveBookmark() {
      this.isLoadingBookmark = true;
      this.$api.addBookmarks(this.bookmarks)
        .then(() => {
          this.getBookmarks();
        })
        .catch((error) => {
          this.isLoadingBookmark = false;
          console.log(error);
        });
    },
    deleteBookmark(key) {
      this.bookmarks.splice(key, 1);
      this.saveBookmark();
    },
    pushBookmark(v) {
      this.$emit('update:isPopoverEditIndex', v);
      this.$emit('create-report', this.bookmarks[v]);
    },
    saveTabBookmark(index) {
      this.reports[index].bookmarkName = this.constTabBookmarkName;
      this.reports[index].isPinnedBookmark = true;
      this.bookmarks.push(this.reports[index]);
      this.saveBookmark();
    },
  },
};
