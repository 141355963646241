<template>
  <section class="">
    <modal :show.sync="isEdit" @close="isEdit = false" class="modal-alert">
      <h5 slot="header" class="modal-title">
        {{$t('profile.generalInfo.title_edit')}}
      </h5>
      <template>
        <div class="row">
          <div class="col-md-6 col-6">
            <fg-input
              v-model="form.first_name"
              :placeholder="(!form.first_name && $t('profile.generalInfo.notSpecified')) || ''"
              class="no-border form-control-lg"
              @input="errors_.first_name = ''"
            >
              <label slot="label">{{ $t('profile.generalInfo.firstName') }}</label>
            </fg-input>
            <span v-if="errors_.first_name" class="text-danger form-error">
              {{ errors_.first_name[0].message }}
            </span>
          </div>

          <!--Last name-->
          <div class="col-md-6 col-6 pl-8">
            <fg-input
              v-model="form.last_name"
              :placeholder="(!form.last_name && $t('profile.generalInfo.notSpecified')) || ''"
              class="no-border form-control-lg"
              @input="errors_.last_name = ''"
            >
              <label slot="label">{{ $t('profile.generalInfo.lastName') }}</label>
            </fg-input>
            <span v-if="errors_.last_name" class="text-danger form-error">{{ errors_.last_name[0].message }}</span>
          </div>

          <!--date of birth-->
          <div class="col-sm-6 col-12 mt-16">
            <div class="form-group no-border form-control-lg has-label">
              <label class="mb-4">{{ $t('profile.generalInfo.birthDate') }}</label>
              <el-date-picker
                class="date-picker datepicker-input p-0"
                :class="['cursor-default']"
                ref="datepicker"
                :placeholder="$t('profile.generalInfo.notSpecified')"
                v-model="birthDate"
                type="date"
                :editable="false"
                :default-value="birthDate ? undefined : defaultDateView"
                :clearable="false"
                :picker-options="pickerOptions"
                format="dd MMM yyyy"
              />
            </div>
            <span
              v-if="errors_.date_of_birth"
              class="text-danger form-error"
            >{{ errors_.date_of_birth[0].message }}</span>
          </div>

          <div class="col-12 profile__general-banddown mt-16">
            <hr />
          </div>

          <!--contact number-->
          <div class="col-12">
            <div class="col-sm-6 col-12 pl-0 pr-0 phone-input">
              <fg-input
                ref="phoneInput"
                v-model="form.phone_number"
                :placeholder="(!form.phone_number && $t('profile.generalInfo.notSpecified')) || ''"
                class="no-border form-control-lg"
                @keyup="formatTel"
              >
                <label slot="label" class="mb-4">{{ $t('profile.generalInfo.contactNumber') }}</label>
              </fg-input>
              <span v-if="errors_.phone_number" class="text-danger form-error">
                {{ errors_.phone_number[0].message }}
              </span>
            </div>
          </div>

          <div class="col-md-6 col-6 mt-16 select-wrapper">
            <label class="mt-4 mb-4">{{ $t('profile.generalInfo.messenger') }}</label>
            <v-select
              class="el-input "
              :placeholder="(!form.messenger && $t('profile.generalInfo.notSpecified')) || ''"
              :options="messengers"
              v-model="form.messenger"
            />
          </div>

          <!--messenger id-->
          <div class="col-md-6 col-6 pl-8 mt-16">
            <fg-input
              v-model="form.messenger_id"
              :placeholder="messengerIdPlaceholder"
              class="no-border form-control-lg"
            >
              <label slot="label">{{ $t('profile.generalInfo.messengerId') }}</label>
            </fg-input>
            <span v-if="errors_.messenger_id" class="text-danger form-error">
              {{ errors_.messenger_id[0].message }}</span>
          </div>

          <div class="col-12 profile__general-banddown mt-16">
            <hr />
          </div>

          <!--country-->
          <div class="col-md-6 col-6 select-wrapper profile__select-disable">
            <label class="mt-4 mb-4">{{ $t('profile.generalInfo.country') }}</label>
            <v-select
              v-model="form.country_code"
              :placeholder="(!form.country_code && $t('profile.generalInfo.notSpecified')) || ''"
              :options="countries"
              class="el-input"
              label="value"
            />
          </div>

          <!--city-->
          <div class="col-md-6 col-6 pl-8">
            <fg-input
              v-model="form.city"
              :placeholder="(!form.city && $t('profile.generalInfo.notSpecified')) || ''"
              class="no-border form-control-lg"
            >
              <label slot="label" class>{{ $t('profile.generalInfo.city') }}</label>
            </fg-input>
          </div>

          <!--address-->
          <div class="col-12 mt-16">
            <fg-input
              v-model="form.address"
              :placeholder="(!form.address && $t('profile.generalInfo.notSpecified')) || ''"
              class="no-border form-control-lg"
            >
              <label slot="label" class>{{ $t('profile.generalInfo.address') }}</label>
            </fg-input>
            <span v-if="errors_.address" class="text-danger form-error">
              {{ errors_.address[0].message }}</span>
          </div>

          <!--zip code-->
          <div class="col-12 mt-16">
            <div class="col-sm-6 col-12 pl-0 pr-0">
              <fg-input
                v-model="form.postal_code"
                :placeholder="(!form.postal_code && $t('profile.generalInfo.notSpecified')) || ''"
                class="no-border form-control-lg"
              >
                <label slot="label" class>{{ $t('profile.generalInfo.postal_code') }}</label>
              </fg-input>
              <span v-if="errors_.postal_code" class="text-danger form-error">
                {{ errors_.postal_code[0].message }}</span>
            </div>
          </div>

          <div class="col-12 profile__general-banddown mt-16">
            <hr />
          </div>

          <!--timezone-->
          <div class="col-md-6 col-6 select-wrapper profile__select-disable">
            <label class="mt-4">{{ $t('profile.generalInfo.timezone') }}</label>
            <v-select
              v-model="form.timezone"
              :options="timezones"
              :placeholder="(!form.timezone && $t('profile.generalInfo.notSpecified')) || ''"
              class="el-input"
              label="value"
            />
          </div>

          <!--first day of week-->
          <div class="col-md-6 col-6 pl-8 select-wrapper profile__select-disable">
            <label class="mt-4">{{ $t('profile.generalInfo.firstDay') }}</label>
            <v-select
              v-model="form.first_day_of_week"
              :options="days"
              :placeholder="$t('profile.generalInfo.notSpecified')"
              :settings="dropdownFiltersSettings"
              class="el-input"
              label="value"
            />
          </div>
        </div>
      </template>
      <div slot="footer" class="d-flex justify-content-end w-100 mt-8 mr-0">
        <button class="btn btn-outline-primary btn-sm btn__back" @click="close">
          {{ $t('profile.buttons.cancel') }}
        </button>
        <button class="btn btn-primary btn-sm ml-16" @click="save" :disabled="isLoading">
          {{$t('profile.buttons.save')}}
        </button>
      </div>
    </modal>
  </section>
</template>

<script>
import { Modal } from 'src/components';
import moment from 'moment/moment';
import 'intl-tel-input/build/js/utils';
import TelInput from 'intl-tel-input';

const days = [
  {
    id: 'monday',
    text: 'profile.generalInfo.days.mo',
  },
  {
    id: 'sunday',
    text: 'profile.generalInfo.days.su',
  },
];

export default {
  components: {
    Modal,
  },
  data() {
    return {
      isEdit: false,
      locale: '',
      tel: TelInput,

      form: {},
      loading: false,
      isLoading: false,
      birthDate: '',
      defaultDateView: moment().subtract(18, 'year').format('YYYY-MM-DD'),
      pickerOptions: {
        disabledDate(time) {
          return time.setFullYear(time.getFullYear() + 18) > Date.now();
        },
      },
      days: days.map(d => ({
        ...d,
        text: this.$t(d.text),
      })),
      dropdownFiltersSettings: {
        minimumResultsForSearch: Infinity,
      },
      errors_: {},
    };
  },
  computed: {
    messengers() {
      return this.$store.state.misc.messengers.map((e) => ({
        id: e.id,
        text: e.name,
      }));
    },
    messengerIdPlaceholder() {
      if (!this.form.messenger_id) {
        return (
          this.$store.state.misc.messengers.find((e) => e.id === this.form.messenger)?.example_placeholder ||
          this.$t('profile.generalInfo.notSpecified')
        );
      }
      return '';
    },
    countries() {
      return this.$store.state.misc.countries.map((e) => {
        e.id = e.code;
        e.text = `${e.name} - ${e.name_native}`;
        return e;
      });
    },
    timezones() {
      return this.$store.state.misc.timezones.map((e) => {
        e.id = e.zone;
        e.text = e.name;
        return e;
      });
    },
  },
  watch: {
    form(v) {
      if (this.form.phone_number && this.tel.setNumber) {
        this.tel.setNumber(v.phone_number, intlTelInputUtils.numberFormat.INTERNATIONAL);
        this.form.phone_number = this.tel.getNumber(intlTelInputUtils.numberFormat.INTERNATIONAL);
      }
    },
    locale() {
      this.days.forEach((d, i) => (d.text = this.$t(days[i].text)));
      this.days = [...this.days];
    },
  },
  activated() {
    if (this.form) {
      this.initTel();
    }
  },
  updated() {
    this.locale = this.$locale;
  },
  methods: {
    open(data) {
      this.errors_ = {};
      this.isEdit = true;
      this.loading = true;

      const { user_date_of_birth, ...restData } = data
      this.form = restData;
      this.birthDate = user_date_of_birth;
      this.initTel();
    },
    close() {
      this.isEdit = false;
    },

    initTel() {
      const phone$el = this.$refs.phoneInput.$el.querySelector('input');
      this.tel = TelInput(phone$el, {
        initialCountry: this.form.country_code,
        formatOnDisplay: true,
        nationalMode: true,
        autoPlaceholder: true,
        autoHideDialCode: true,
      });
    },

    formatTel() {
      this.form.phone_number = this.tel.getNumber(intlTelInputUtils.numberFormat.INTERNATIONAL);
    },

    save() {
      this.errors_ = {};

      const data = {
        ...this.$_.cloneDeep(this.form),
        phone_number: this.tel?.getNumber(intlTelInputUtils.numberFormat.E164),
        date_of_birth: this.birthDate && moment(this.birthDate).format('YYYY-MM-DD'),
      };

      this.isLoading = true;

      this.$store
        .dispatch('saveProfile', data)
        .then(() => {
          this.$alert.success(this.$t('profile.success.saved'));
          this.isEdit = false;
        })
        .catch((err) => {
          const errors = err.response.data.errors;

          if (Object.keys(errors).length) {
            this.errors_ = errors;
          } else {
            this.$alert.fail(this.$t('profile.errors.incomplete_profile'));
          }
        })
        .finally(() => this.isLoading = false);
    },
  },
};
</script>

<style lang="scss" scoped>
.form-error {
  font-size: 12px;
  top: -5px;
  position: relative;
}

.modal-alert {
  .row {
    margin-right: -15px;
    margin-left: -15px;
  }
}
</style>
