const state = () => ({
  loading: false,
  request: false,
  message: 'Error',
});
const actions = {
  errorRequest({ commit }, data) {
    commit('errorRequest', data);
  },
};
const mutations = {
  errorRequest(state, data) {
    state.request = data.status;
    state.message = data.message || 'Error';
  },
  errorReset(state, data) {
    state.request = null;
    state.message = 'Error';
  },
  requestStart(state) {
    state.loading = true;
  },

  requestEnd(state) {
    state.loading = false;
  },
};
export default () => ({
  state: state(),
  actions,
  mutations,
});
