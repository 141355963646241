import languages from './languages.json';
import currencies from './currencies.json';

export default {
  install(Vue, config) {
    config = config || { enabledLanguages: ['en'] };
    Vue.prototype.$languages = config.enabledLanguages.map(l => ({ code: l, ...languages[l] }));
    Vue.prototype.$currencies = currencies;
  },
};
