
import requestTests from './request/index';

export default {

  install(Vue) {
    requestTests(Vue.prototype.$request, Vue.prototype.$errors.types);
    requestTests(Vue.prototype.$requestMisc, Vue.prototype.$errors.types);
  },

};
