<template>
  <section class="ts-loader">
    <div class="card wavebg w-100">
      <div class="d-flex ts-header justify-content-between align-items-center">
        <div class="">
          <div class="">
            Traffic Sources
          </div>
        </div>
        <div class="d-flex justyfy-content-end mt-0 mb-0">
          <button class="btn btn-primary btn-sm">
            + New Traffic Source
          </button>
          <div class="empty-pagination ml-8" style="width: 269px">
            <div class="empty-select mr-8" style="width: 80px">
              100
            </div>
            <span>&lt; &lt;&lt; Page</span>
            <div class="form-group mb-0 mt-0">
              <input placeholder="1" class="form-control mt-0 pl-16 pr-16" style="width: 40px">
            </div>
            <span>of 2 > >></span>
          </div>
        </div>
      </div>

      <div class="table-empty mt-16 mb-8">
        <div class="table-empty__head">
          <!-- <div class="cell">Promocode</div>
            <div class="cell">Promocode</div>
            <div class="cell">Actions</div> -->
        </div>
        <div class="table-empty__body">
          <!-- <div class="table-empty__body-row">
              <div class="cell">Promocode</div>
              <div class="cell">!@#$%^&*){}/*++</div>
              <div class="cell text-red">Delete</div>
            </div>
            <div class="table-empty__body-row">
              <div class="cell">Promocode</div>
              <div class="cell">!@#$%^&*){}/*++</div>
              <div class="cell text-red">Delete</div>
            </div>
            <div class="table-empty__body-row">
              <div class="cell">Promocode</div>
              <div class="cell">!@#$%^&*){}/*++</div>
              <div class="cell text-red">Delete</div>
            </div>
            <div class="table-empty__body-row">
              <div class="cell">Promocode</div>
              <div class="cell">!@#$%^&*){}/*++</div>
              <div class="cell text-red">Delete</div>
            </div> -->
        </div>

        <div class="mob-table__block mob-table__block-actions pt-0">
          <div class="d-flex justify-content-between">
            <span>Promocode</span>
            <span>0</span>
          </div>
          <div class="d-flex justify-content-between">
            <span>Promocode</span>
            <span>0</span>
          </div>
          <div class="d-flex justify-content-between">
            <span>Promocode</span>
            <span>0</span>
          </div>
          <div class="mob-table__actions">
            <span class="text-green">Promocode</span>
            <span class="text-red">Promocode</span>
            <span class="text-green">Promocode</span>
          </div>
        </div>
        <div class="mob-table__block mob-table__block-actions">
          <div class="d-flex justify-content-between">
            <span>Promocode</span>
            <span>0</span>
          </div>
          <div class="d-flex justify-content-between">
            <span>Promocode</span>
            <span>0</span>
          </div>
          <div class="d-flex justify-content-between">
            <span>Promocode</span>
            <span>0</span>
          </div>
          <div class="mob-table__actions">
            <span class="text-green">Promocode</span>
            <span class="text-red">Promocode</span>
            <span class="text-green">Promocode</span>
          </div>
        </div>
        <div class="mob-table__block mob-table__block-actions">
          <div class="d-flex justify-content-between">
            <span>Promocode</span>
            <span>0</span>
          </div>
          <div class="d-flex justify-content-between">
            <span>Promocode</span>
            <span>0</span>
          </div>
          <div class="d-flex justify-content-between">
            <span>Promocode</span>
            <span>0</span>
          </div>
          <div class="mob-table__actions">
            <span class="text-green">Promocode</span>
            <span class="text-red">Promocode</span>
            <span class="text-green">Promocode</span>
          </div>
        </div>
        <div class="mob-table__block mob-table__block-actions">
          <div class="d-flex justify-content-between">
            <span>Promocode</span>
            <span>0</span>
          </div>
          <div class="d-flex justify-content-between">
            <span>Promocode</span>
            <span>0</span>
          </div>
          <div class="d-flex justify-content-between">
            <span>Promocode</span>
            <span>0</span>
          </div>
          <div class="mob-table__actions">
            <span class="text-green">Promocode</span>
            <span class="text-red">Promocode</span>
            <span class="text-green">Promocode</span>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {

};
</script>

<style lang="scss">
@import 'src/assets/theme/default/components/styles-loaders';

.ts-loader{
  @media (max-width: 650px){
    .ts-header{
      border-bottom: 1px solid #e7ecf1;
      padding-bottom: 16px;
      display: block !important;
      >.d-flex{
        display: block !important;
        .btn-primary{
          width: 100%;
          margin-bottom: 10px;
          margin-top: 10px;
        }
      }
      .empty-pagination{
        margin-left: 0;
      }
    }
  }
}
</style>
