<template>
  <div class="mob-pagination">
    <div class="mob-pagination__wrap pb-16 pl-16 pr-16">
      <ui-button
        class=""
        :class="{ disabled : currentPage <= 1 }"
        @click="currentPage > 1 ? currentPage -= 1 : ''"
      >
        <span class="text-capitalize">{{ $t('customReports.back') }}</span>
      </ui-button>
      <span class="label-info">{{currentPageInp}} {{ $t('customReports.of') }} {{ totalPages }}</span>
      <ui-button
        class=""
        :class="{ disabled : currentPage >= totalPages }"
        @click="currentPage < totalPages ? currentPage += 1 : ''"
      >
        <span class="text-capitalize">{{ $t('customReports.next') }}</span>
      </ui-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiPagination',
  props: {
    page: {
      type: Number,
      required: true,
    },
    pageSize: {
      type: Number,
      required: true,
    },
    count: {
      type: Number,
      required: true,
    },
    pageSizes: {
      type: Array,
      default() {
        return [
          10,
          20,
          50,
          100,
        ];
      },
    },
    showSizeSelect: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      currentPage: this.page,
      currentPageInp: this.page,
      currentPageSize: this.pageSize,
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.count / this.pageSize);
    },
  },
  watch: {
    count() {
      if (parseInt(this.currentPage, 10) > this.totalPages) {
        this.currentPage = 1;
      }
    },
    currentPage(nv) {
      if (!nv || nv < 1 || nv > this.totalPages) {
        this.currentPage = 1;
      }

      this.currentPageInp = this.currentPage.toString();
      this.$emit('page-change', this.currentPage, this.currentPageSize);
    },
    currentPageInp(nv) {
      const v = parseInt(nv.replace(/[^0-9]/g, ''), 10);

      if (v !== this.currentPage) {
        this.currentPage = v;
      }
    },
    currentPageSize(nv) {
      this.currentPageSize = parseInt(nv, 10);

      if (Math.ceil(this.count / this.currentPageSize) < this.currentPage) {
        this.currentPage = 1;
      }

      this.$emit('page-change', this.currentPage, this.currentPageSize);
    },
  },
};
</script>

<style lang="scss">
.mob-pagination {
  background: #f7f7f7;

  &__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fff;

    .ui-button {
      padding: 9px 24px !important;
      border-radius: 8px !important;

      .btn-text {
        font-size: 12px;
      }
    }

    .label-info {
      font-size: 14px;
      white-space: nowrap;
      font-weight: 600;
    }
  }

  @media (min-width: 650px) {
    display: none;
  }
}
</style>
