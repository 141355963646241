<template>
  <section>
    <slot />
    <div
      v-if="error"
      class="error-wrapper"
    >
      <div class="text-danger error-style wrongPassword">
        {{ errorText || message }}
      </div>
    </div>
  </section>
</template>

<script>
export default {
  props: ['name', 'message', 'active'],

  data() {
    return {
      error: false,
      errorText: '',
    };
  },

  watch: {
    error(v) {
      if (v) {
        $(this.$el)
          .find('input')
          .addClass('input-error');
        $(this.$el)
          .find('textarea')
          .addClass('input-error');
      } else {
        $(this.$el)
          .find('input')
          .removeClass('input-error');
        $(this.$el)
          .find('textarea')
          .removeClass('input-error');
      }
    },
  },

  created() {
    this.$event.$on('request/error', (data) => {
      if (data.status !== 422) return;
      if (data.errors[this.name]) {
		    this.error = true;
        this.errorText = data.errors[this.name][0].message;
      }
    });
    this.$event.$on('forms/reset', () => {
      this.error = false;
    });
  },
};
</script>

<style lang="scss" scoped>
section {
  display: inline-block;
}

.error-style {
  position: relative;
  margin-top: -6px;
  font-size: 12px;
  color: #ff5c70;
  text-align: right;
  font-style: italic;
}
</style>
