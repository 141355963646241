<template>
  <modal
    :show.sync="active"
    :close-modal-bottom="true"
    :style="{ backgroundImage: 'url(' + preview + ')' }"
    class="media-preview"
    footer-classes="justify-content-start"
  >
    <template>
      <div class="col-12 text-center mt-0">
        <div ref="preview" />
      </div>
    </template>
  </modal>
</template>

<script>
import { Modal } from 'src/components';

export default {
  components: {
    Modal,
  },
  data() {
    return {
      active: false,
      data: {},
      item: '',
      preview: require('@/assets/img/watermark.svg'),
      loading: true,
      popup: true,
    };
  },


  watch: {
    active() {
      if (!this.active) {
        this.$emit('closeBanner', true);
      }
    },
    item(v) {
      $(this.$refs.preview)
        .html($(this.item));
    },
  },
  methods: {
    open(item) {
      this.item = item;
      this.active = true;
      this.$emit('openBanner', true);
    },
  },
};
</script>

<style lang="scss">
.media-preview {
  background-size: 36px;

  .modal-dialog {
    min-width: 100%;
    display: flex;
    margin: 0 !important;
    height: 100%;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .modal-header {
    border: none;
    padding-right: 15px !important;

    button {
      color: #fff;
      font-size: 48px;
      padding-bottom: 0 !important;
    }
  }

  .modal-content {
    max-width: max-content;
    background: transparent;
    border-radius: 0px !important;
  }

  .modal-footer {
    display: none;
  }

  @media (max-width: 650px) {
    .modal-dialog {
      max-width: 100%;
      width: 100%;
      float: none;

      .modal-header {
        padding-right: 0 !important;
      }

      .modal-body {
        .col-12 {
          padding: 0;

          > div {
            overflow: auto;
            max-height: calc(100vh - 120px);
            max-width: calc(100vw - 32px);
          }
        }
      }
    }
  }
  @media (max-height: 750px) {
    .modal-dialog {
      .modal-body {
        margin-top: 30px;
      }
    }
  }
}
</style>
