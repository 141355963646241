
import state from './state';
import actions from './actions';
import mutations from './mutations';

export default () => ({
  state: state(),
  actions,
  mutations,
});
