import { debounce } from 'lodash';

export default {
  getSettings({ commit }) {
    return this.$api.getSettings().then((data) => {
      commit('saveSettings', data);
      return data;
    });
  },
  getSettingsLazy: debounce(({ dispatch }) => dispatch('getSettings'), 100),
};
