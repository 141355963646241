<template>
  <section>
    <el-tooltip
      :content="message"
      :manual="true"
      :value="copied"
      :hide-after="1000"
      placement="right"
      effect="light"
    >
      <a
        href="#"
        @click.prevent="copy"
      >{{ text }}</a>
    </el-tooltip>
    <input
      ref="copycat"
      type="text"
      class="hide"
    >
  </section>
</template>

<script>
export default {
  props: ['message', 'text'],
  data() {
    return {
      copied: false,
    };
  },
  methods: {
    copy(ev) {
      this.$refs.copycat.value = ev.target.innerText.trim();

      if (navigator.userAgent.match(/ipad|ipod|iphone/i)) {
        const el = $(this.$refs.copycat).get(0);
        const editable = el.contentEditable;
        const readOnly = el.readOnly;
        el.contentEditable = true;
        el.readOnly = false;
        const range = document.createRange();
        range.selectNodeContents(el);
        const sel = window.getSelection();
        sel.removeAllRanges();
        sel.addRange(range);
        el.setSelectionRange(0, 999999);
        el.contentEditable = editable;
        el.readOnly = readOnly;
      } else {
        this.$refs.copycat.select();
      }

      document.execCommand('copy');
      this.copied = true;
      setTimeout(() => {
        $(window).one('click', () => {
          this.copied = false;
        });
      });
      setTimeout(() => {
        this.copied = false;
      }, 1000);
    },
  },
};
</script>

<style scoped>
  .hide {
    opacity: 0;
    position: fixed;
    z-index: -1;
    right: 0px;
    bottom: -50px;
  }

  a {
    color: var(--color-text-green);
  }

  a:hover {
    border-bottom-color: var(--color-text-green);
    border-bottom-style: dashed;
    border-bottom-width: 1px;
    text-decoration: none;
  }
</style>
