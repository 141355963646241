<template>
  <section :class="{'transparent': complete}">
    <slot />
  </section>
</template>
<script>
export default {
  props: ['disabled', 'complete'],
  watch: {
    disabled(val) {
      if (val) {
        $(this.$el).find('input').attr('disabled', 'disabled');
        $(this.$el).find('textarea').attr('disabled', 'disabled');
      } else {
        $(this.$el).find('input').removeAttr('disabled');
        $(this.$el).find('textarea').removeAttr('disabled');
      }
    },
  },
};
</script>

<style lang="scss" scoped>
	.transparent{
		opacity: 0;
	}
</style>
