import { Line } from 'vue-chartjs';
import { hexToRGB } from './utils';

require('hammerjs');
require('chartjs-plugin-zoom');


const chartColor = '#FFFFFF';
const gradientChartOptionsConfiguration = {
  maintainAspectRatio: false,
  legend: {
    display: true,
  },
  tooltips: {
    bodySpacing: 4,
    mode: 'nearest',
    intersect: 0,
    position: 'nearest',
    xPadding: 10,
    yPadding: 10,
    caretPadding: 10,
  },
  responsive: 1,
  scales: {
    xAxes: [{
      gridLines: {
        display: false,
      },
      ticks: {

      },
    }],
    yAxes: [{
      id: 'left-y-axis',
      position: 'left',
      gridLines: {
        display: false,
      },
      ticks: {
        suggestedMin: 0,
      },
      scaleLabel: {
        display: true,
        labelString: 'Visit',
      },
    }, {
      id: 'right-y-axis',
      position: 'right',
      gridLines: {
        display: false,
      },
      ticks: {

        suggestedMin: 0,
      },
      scaleLabel: {
        display: true,
        labelString: 'Hits',
      },
    }],
  },

  layout: {
    padding: {
      left: 0, right: 0, top: 15, bottom: 15,
    },
  },
};

export default {
  name: 'line-chart',
  extends: Line,
  props: ['labels', 'datasets', 'data', 'color', 'extraOptions', 'title', 'scales', 'configuration'],
  mounted() {
    this.rander();
  },
  methods: {
    rander() {
      try {
        const fallBackColor = '#f96332';
        const color = this.backgroundColor || fallBackColor;
        const chartElement = document.getElementById(this.chartId);

        if (!chartElement) {
          return;
        }

        const ctx = chartElement.getContext('2d');

        const gradientStroke = ctx.createLinearGradient(500, 0, 100, 0);
        gradientStroke.addColorStop(0, color);
        gradientStroke.addColorStop(1, chartColor);

        const gradientFill = ctx.createLinearGradient(0, 170, 0, 50);
        gradientFill.addColorStop(0, 'rgba(128, 182, 244, 0)');
        gradientFill.addColorStop(1, hexToRGB(color, 0.4));

        const chartOptions = Object.assign(this.configuration, this.extraOptions || {});
        this.renderChart({
          labels: this.labels || [],
          datasets: this.datasets ? this.datasets : [{
            label: this.title || '',
            borderColor: this.borderColor || color,
            pointBorderColor: this.pointBorderColor || '#444',
            pointBackgroundColor: this.pointBackgroundColor || color,
            pointBorderWidth: this.pointBorderWidth || 2,
            pointHoverRadius: this.pointHoverRadius || 4,
            pointHoverBorderWidth: this.pointHoverBorderWidth || 1,
            pointRadius: this.pointRadius || 4,
            fill: this.fill || true,
            backgroundColor: this.backgroundColor || gradientFill,
            borderWidth: this.borderWidth || 2,
            data: this.data || [],
          }],

        }, chartOptions);
      } catch (err) {
        console.error(err);
      }
    },
  },
  watch: {
    labels() {
      this.rander();
    },
  },
};
