export function getSettings() {
  return this.affiliates
    .get('settings')
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err));
}
export function getFinalPages(siteId) {
  return this.affiliates
    .get(`final-pages/${siteId}`)
    .then(res => Promise.resolve(res.data))
    .catch(err => Promise.reject(err));
}
