<template>
  <div class="col-md-4 ml-auto mr-auto login-wrapper blocked-account blocked-account review-account">
    <div class="login-logo">
      <img :src="logo" alt="logo">
    </div>
    <form>
      <card class="card-login">
        <img :src="require('@/assets/theme/icon/hourglass.svg')">
        <h3>
          {{ $t('forms.unverifiedAccount.verifyEmail') }}
          <br>
        </h3>
        <div class="row">
          <div class="col-12 blocked-account__text">
            {{ $t('forms.unverifiedAccount.sentVerificationLink') }}
          </div>
        </div>
        <button class="btn btn-primary btn-md" @click="$router.push('/logout')">
          &lt; {{ $t('forms.btn.logout') }}
        </button>
      </card>
    </form>
  </div>
</template>

<script>
export default {
  computed: {
    logo() {
      return this.$config.layout.logoFull;
    },
  },
};
</script>
