<template>
  <modal
    :show.sync="active"
    @close="close"
    :class="[{ loading: sending }, { completeClass: complete }]"
    class="modal-password profile__modal-change-password"
  >
    <h5 slot="header" class="modal-title">
      {{ $t('profile.changePassword.title') }}
    </h5>

    <template>
      <complete v-if="complete" :message="$t('dialogues.PassChangeSuccess')"/>

      <div class="modal__body" @keyup.enter="save">
        <form-wrap :complete="complete" :disabled="sending">
          <input-wrap :message="$t('dialogues.wrongPassword')" name="old_password">
            <label class="pwd-label">
              {{ $t('profile.changePassword.oldPassword') }} <span>*</span>
            </label>
            <password-input ref="old_password" :data.sync="model.old_password"/>
          </input-wrap>
          <input-wrap name="">
            <label class="pwd-label">
              {{ $t('profile.changePassword.newPassword') }} <span>*</span>
            </label>
            <password-input ref="new_password" :data.sync="model.new_password"/>
          </input-wrap>
          <input-wrap name="">
            <label class="pwd-label">
              {{ $t('profile.changePassword.repeatPassword') }} <span>*</span>
            </label>
            <password-input ref="repeat_password" :data.sync="model.repeat_password"/>
          </input-wrap>
          <small v-if="validation && !$v.model.repeat_password.sameAs" class="text-danger">
            * {{ $t('profile.changePassword.dontMatch') }}
          </small>
          <small v-if="validation && !$v.model.repeat_password.minLength" class="text-danger">
            * {{ $t('profile.changePassword.wrongLen') }}
          </small>
        </form-wrap>
      </div>
    </template>

    <div
      v-if="!complete"
      slot="footer"
      class="d-flex justify-content-end modal__footer-btn"
    >
      <button class="btn btn-outline-primary btn-sm modal__btn-cancel mt-8" @click="close" >
        {{ $t('profile.buttons.cancel') }}
      </button>
      <button
        :disabled="!valid || sending"
        class="btn btn-primary btn-sm modal__btn-save ml-16 mt-8"
        @click="save"
      >
        <i v-if="sending" :class="{'transparent-top':sending}" class="fas fa-spinner spinner-hide position-relative"/>
        <span :class="{'transparent-t':sending}">{{ $t('profile.buttons.save') }}</span>
      </button>
    </div>
    <div
      v-if="complete"
      slot="footer"
      class="modal__checkOk"
    >
      <button class="btn btn-primary btn-md modal__btn-cancel" @click="close">
        {{ $t('profile.disableSuccess.ok') }}
      </button>
    </div>
  </modal>
</template>

<script>
import { Modal } from 'src/components';
import { required, minLength, sameAs } from 'vuelidate/lib/validators';
import FormWrap from 'src/components/ModalForm/FormWrap';
import Complete from 'src/components/ModalForm/Complete';
import InputWrap from 'src/components/Form/InputWrap';
import PasswordInput from 'src/components/PasswordInput';

export default {
  components: {
    Modal,
    FormWrap,
    Complete,
    InputWrap,
    PasswordInput,
  },
  data() {
    return {
      active: false,
      validation: false,
      model: {
        old_password: '',
        new_password: '',
        repeat_password: '',
      },
      sending: false,
      complete: false,
      error: false,
      password_show: false,
    };
  },
  validations: {
    model: {
      new_password: {
        required,
        minLength: minLength(8),
      },
      repeat_password: {
        required,
        minLength: minLength(8),
        sameAs: sameAs('new_password'),
      },
    },
  },
  computed: {
    valid() {
      const new_pwd = this.$v.model.new_password;
      const rep_pwd = this.$v.model.repeat_password;
      return new_pwd.minLength && new_pwd.required && rep_pwd.sameAs;
    },
  },
  watch: {
    active(condition) {
      if (!condition) {
        this.sending = false;
        this.error = false;
        this.complete = false;
        this.disabled = false;
        this.$event.$emit('forms/reset');
      }
    },
  },
  methods: {
    show_password() {
      this.password_show = !this.password_show;
      if (this.password_show) {
        $(this.$el)
          .find('input')
          .attr('type', 'text');
      } else {
        $(this.$el)
          .find('input')
          .attr('type', 'password');
      }
    },
    reset() {
      this.$refs.old_password.reset();
      this.$refs.new_password.reset();
      this.$refs.repeat_password.reset();
    },
    open(payload) {
      this.reset();
      this.payload = payload;
      this.active = true;
      document.addEventListener('keydown', this.keydownListener, true);
    },
    close() {
      this.active = false;
      this.sending = false;
      this.error = false;
      this.complete = false;
      this.reset();
      document.removeEventListener('keydown', this.keydownListener, true);
    },
    keydownListener(e) {
      if (e.keyCode == 13) {
        e.preventDefault();
        if (this.valid) this.enter();
      }
    },
    save() {
      const fields = {
        old_password: this.model.old_password,
        new_password: this.model.repeat_password,
      };
      this.sending = true;
      this.disabled = true;
      this.$store
        .dispatch('saveProfilePassword', fields)
        .then(() => {
          this.complete = true;
          this.sending = false;
        })
        .catch(() => {
          this.error = true;
          this.sending = false;
          this.complete = false;
        });
    },
  },
};
</script>

<style lang="scss">
@import 'src/assets/theme/default/profile/changepassword';
.modal-password .password.form-group {
  margin-top: -16px;
}
.pwd-label {
  text-transform: uppercase;
  font-weight: 600;
}
.transparent-t {
  color: transparent;
}
.transparent-top:before {
  top: -15px !important;
}
.completeClass {
  h1 {
    margin: 30px auto;
    width: 60px;
  }
  .modal__footer-btn {
    display: none;
  }
  .modal__checkOk {
    display: block;
    .btn {
      width: 100%;
    }
  }
}

.modal__footer-btn {
  .modal__btn-cancel {
    min-width: 120px !important;
  }
  .modal__btn-confirm {
    position: relative;
    .sendingPassword {
      opacity: 0;
    }
  }
}
.profile__modal-change-password {
  .modal.loading {
    .fa-spinner {
      display: inline;
    }
    .confirm {
      font-size: 12px;
    }
  }
  .fa-spinner:before {
    position: absolute;
    font-size: 25px;
    top: -14px;
    left: 43%;
    animation-name: spin;
    animation-duration: 1500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
}

.modal__body.sendingPassword {
  opacity: 0.7;
}
</style>
