<template>
  <section class="">
    <modal :show.sync="isEdit" @close="close" class="modal-alert profile__modal-paymet-edit public-profile">
      <h5 slot="header" class="modal-title">
        {{$t('profile.public_profile.edit.title')}}
      </h5>
      <template>
        <div class="form-group mb-0 mt-10">
          <label class="form-title">{{$t('profile.public_profile.name')}}</label>
          <input :placeholder="$t('profile.public_profile.name')" v-model="form.name" class="form-control mt-2"/>
          <span v-if="errors_.name" class="form-error text-danger">{{ errors_.name[0].message }}</span>
        </div>
        <template v-if="form.contacts.length">
          <div class="row contacts mt-16 mb-4">
            <div class="column">
              <span class="form-title">{{$t('profile.generalInfo.messenger')}}</span>
            </div>
            <div class="column">
              <span class="form-title messenger-id">{{$t('profile.generalInfo.messengerId')}}</span>
            </div>
          </div>
          <div class="item-container mb-12" :key="i" v-for="(item, i) in form.contacts">
            <div class="col-6 pr-8 pl-0">
              <v-select
                class="v-select w-100"
                v-model="item.type"
                :options="messengers"
                :class="{ 'form-error-input': getContactError(i, 'type') }"

                :multiple="false"
                :placeholder="$t('registration.placeholder_messenger')"
              />
            </div>
            <div class="col-6 pl-8 pr-0 column">
              <el-tooltip
                placement="top"
                :disabled="!getContactError(i, 'value')"
                :content="getContactError(i, 'value')"
              >
                <ui-input
                  v-model="item.value"
                  :class="{ 'form-error-input': getContactError(i, 'value') }"
                  size="big"
                  autosize
                  type="text"
                  class="input"
                  :placeholder="$t('profile.generalInfo.messengerId')"
                />
              </el-tooltip>
              <i
                class="fas fa-trash-alt text-red ml-8 pointer"
                :class="{ disabled: form.contacts.length === 1 }"
                @click="deleteMessenger(i)"
              />
            </div>
          </div>
        </template>
        <span class="add-messenger" @click="addMessenger">+ {{$t('profile.public_profile.edit.add_another_messenger')}}</span>
        <div class="form-group select-wrapper mb-8">
          <label class="form-title">{{ $t('profile.public_profile.edit.misc') }}</label>
          <el-input
            type="textarea"
            maxlength="255"
            v-model="form.additional_information"
            :rows="4"
            :placeholder="$t('profile.public_profile.edit.misc')"
          />
          <span v-if="errors_.additional_information" class="form-error text-danger">{{ errors_.additional_information[0].message }}</span>
        </div>
      </template>
      <div slot="footer" class="d-flex justify-content-end w-100 mt-8 mr-0">
        <button class="btn btn-outline-primary btn-sm btn__back" @click="close">
          {{ $t('profile.buttons.cancel') }}
        </button>
        <button class="btn btn-primary btn-sm ml-16" @click="save" :disabled="isLoading">
          {{$t('profile.buttons.save')}}
        </button>
      </div>
    </modal>
  </section>
</template>

<script>
import { Modal } from 'src/components';

export default {
  components: {
    Modal,
  },
  data() {
    return {
      isEdit: false,
      form: this.defaultData(),

      isLoading: false,
      errors_: {},
      messengers: [
        {
          id: "email",
          text: "Email",
          example_placeholder: "@email"
        },
        {
          id: "telegram",
          text: "Telegram",
          example_placeholder: "@telegram"
        },
        {
          id: "skype",
          text: "Skype",
          example_placeholder: "Skype Callto"
        },
        {
          id: '',
          text: this.$t('profile.generalInfo.messenger'),
        },
      ]
    };
  },
  computed: {},
  watch: {},

  methods: {
    getContactError(i, type) {
      return this.$_.get(this.errors_.contacts, [i, type, 0, 'message'], '')
    },
    defaultData() {
      return {
        name: '',
        contacts: [
          {
            type: null,
            value: '',
          }
        ],
        additional_information: '',
      }
    },
    open(data) {
      this.errors_ = {};
      this.isEdit = true;
      this.loading = true;

      this.form = this.$_.cloneDeep(data || this.defaultData());
    },
    deleteMessenger(i) {
      if(this.form.contacts.length <= 1) return;

      this.form.contacts = [...this.form.contacts.slice(0, i), ...this.form.contacts.slice(i + 1)];
    },
    addMessenger() {
      this.form.contacts.push({
        type: null,
        value: '',
      })
    },

    close() {
      this.isEdit = false;
    },

    save() {
      this.errors_ = {};

      this.isLoading = true;

      this.$api.putPublicProfile(this.form)
        .then(() => {
          this.$alert.success(this.$t('profile.public_profile.success.updated'));
          this.$store.dispatch('getProfileData');
          this.isEdit = false;
        })
        .catch((err) => {
          this.errors_ = err.response.data.errors;
        })
        .finally(() => this.isLoading = false);
    },
  },
};
</script>

<style lang="scss" scoped>
.item-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  /deep/ {
    .form-group input.form-control {
      margin-top: 0;
    }
  }
}
.ui-input {
  margin-bottom: 0;
}
.form-error-input {
  /deep/ {
    .input-wrapper input.input-element, .selection .select2-selection {
      border-color: var(--color-text-red)!important;
    }
  }
}

.form-error {
  font-size: 12px;
  text-align: right;
}

.row {
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  &.contacts {
    gap: 16px;
  }

  /deep/ {
    .form-group input.form-control {
      margin-top: 0;
    }
  }

  .column {
    display: flex;
    align-items: center;
    flex: 1;

    .pointer {
      cursor: pointer;
    }

    .disabled {
      opacity: .5;
      cursor: not-allowed;
    }
  }

  .form-title {
    font-size: 13px;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
  }
}

.add-messenger {
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  color: var(--color-text-green);
}

.public-profile {
  @media (max-width:544px) {
    max-width: 100%;

    /deep/ {
      .ui-input.el-tooltip.input {
        width: calc(100% - 22px)!important;
      }
    }
  }
}
</style>
