<template>
  <div class="row custom-padding custom-padding-main">
    <div
      v-for="(item, i) in offersMy"
      :key="i"
      class="col-md-6"
    >
      <div class="card-offer col-12">
        <div class="card-offer__head">
          <h5 class="card-offer__title">
            {{ item.name }}
          </h5>
          <button
            class="btn btn-outline-primary btn-sm card-offer__btn-g"
            @click="$emit('getLinks', item.id, $route.query)"
          >
            {{ $t('offers.cardOffers.btnGet') }}
          </button>
        </div>

        <div
          v-if="item.conversion_type === 'hybrid_deposit_plus_rev_share' || item.conversion_type === 'rev_share'"
          class="card-offer__body"
        >
          <div class="card-offer__col card-offer__rev">
            <p class="card-offer__col-title">
              {{ $t('offers.cardOffers.revshare') }}
            </p>
            <span class="card-offer__col-val">{{ item.settings.rev_share_percent | percent }}</span>
          </div>
          <div class="card-offer__col card-offer__hold">
            <p class="card-offer__col-title">
              {{ $t('offers.cardOffers.hold') }}
            </p>
            <span
              class="card-offer__col-val"
            >{{ formatDays(item.settings.hold_seconds || item.settings.hold_seconds_rev_share) }}</span>
          </div>
        </div>

        <div
          v-if="item.settings.payout"
          class="card-offer__body"
        >
          <div class="card-offer__col card-offer__cup">
            <p class="card-offer__col-title">
              {{ $t('offers.cardOffers.payout') }}
            </p>
            <span class="card-offer__col-val">{{ item.settings.payout | currencyInt | currency }}</span>
          </div>
          <div class="card-offer__col card-offer__cup">
            <p class="card-offer__col-title">
              {{ $t('offers.cardOffers.cap') }}
            </p>
            <span class="card-offer__col-val">{{ item.settings.daily_cap }}</span>
          </div>
          <div class="card-offer__col card-offer__hold">
            <p class="card-offer__col-title">
              {{ $t('offers.cardOffers.hold') }}
            </p>
            <span
              class="card-offer__col-val"
            >{{ formatDays(item.settings.hold_seconds || item.settings.hold_seconds_deposit) }}</span>
          </div>
        </div>

        <div
          v-if="item.settings.deposits_sum"
          class="card-offer__body"
        >
          <div
            v-if="item.settings.deposits_sum.min"
            class="card-offer__col card-offer__cup card-offer__dep"
          >
            <p
              class="card-offer__col-title"
            >
              {{ $t('offers.cardOffers.depositSum') }} ({{ $t('offers.cardOffers.min') }})
            </p>
            <span
              class="card-offer__col-val"
            >{{ item.settings.deposits_sum.min | currencyInt | currency }}</span>
          </div>
          <div
            v-if="item.settings.deposits_sum.max"
            class="card-offer__col card-offer__cup card-offer__dep"
          >
            <p
              class="card-offer__col-title"
            >
              {{ $t('offers.cardOffers.depositSum') }} ({{ $t('offers.cardOffers.max') }})
            </p>
            <span
              class="card-offer__col-val"
            >{{ item.settings.deposits_sum.max | currencyInt | currency }}</span>
          </div>
        </div>

        <div
          v-if="item.settings.deposit_value"
          class="card-offer__body"
        >
          <div
            v-if="item.settings.deposit_value.min"
            class="card-offer__col card-offer__cup card-offer__dep"
          >
            <p
              class="card-offer__col-title"
            >
              {{ $t('offers.cardOffers.depositAmount') }} ({{ $t('offers.cardOffers.min') }})
            </p>
            <span
              class="card-offer__col-val"
            >{{ item.settings.deposit_value.min | currencyInt | currency }}</span>
          </div>
          <div
            v-if="item.settings.deposit_value.max"
            class="card-offer__col card-offer__cup card-offer__dep"
          >
            <p
              class="card-offer__col-title"
            >
              {{ $t('offers.cardOffers.depositAmount') }} ({{ $t('offers.cardOffers.max') }})
            </p>
            <span
              class="card-offer__col-val"
            >{{ item.settings.deposit_value.max | currencyInt | currency }}</span>
          </div>
        </div>

        <div
          v-if="item.settings.deposits_count"
          class="card-offer__body"
        >
          <div
            v-if="item.settings.deposits_count.min"
            class="card-offer__col card-offer__cup card-offer__dep"
          >
            <p
              class="card-offer__col-title"
            >
              {{ $t('offers.cardOffers.numberOfDeposites') }} ({{ $t('offers.cardOffers.min') }})
            </p>
            <span class="card-offer__col-val">{{ item.settings.deposits_count.min }}</span>
          </div>
          <div
            v-if="item.settings.deposits_count.max"
            class="card-offer__col card-offer__cup card-offer__dep"
          >
            <p
              class="card-offer__col-title"
            >
              {{ $t('offers.cardOffers.numberOfDeposites') }} ({{ $t('offers.cardOffers.max') }})
            </p>
            <span class="card-offer__col-val">{{ item.settings.deposits_count.max }}</span>
          </div>
        </div>

        <div
          v-if="item.settings.bets_count"
          class="card-offer__body"
        >
          <div
            v-if="item.settings.bets_count.min"
            class="card-offer__col card-offer__cup card-offer__dep"
          >
            <p
              class="card-offer__col-title"
            >
              {{ $t('offers.cardOffers.numberOfBets') }} ({{ $t('offers.cardOffers.min') }})
            </p>
            <span class="card-offer__col-val">{{ item.settings.bets_count.min }}</span>
          </div>
          <div
            v-if="item.settings.bets_count.max"
            class="card-offer__col card-offer__cup card-offer__dep"
          >
            <p
              class="card-offer__col-title"
            >
              {{ $t('offers.cardOffers.numberOfBets') }} ({{ $t('offers.cardOffers.max') }})
            </p>
            <span class="card-offer__col-val">{{ item.settings.bets_count.max }}</span>
          </div>
        </div>

        <div
          v-if="item.settings.bet_value"
          class="card-offer__body"
        >
          <div
            v-if="item.settings.bet_value.min"
            class="card-offer__col card-offer__cup card-offer__dep"
          >
            <p
              class="card-offer__col-title"
            >
              {{ $t('offers.cardOffers.betAmount') }} ({{ $t('offers.cardOffers.min') }})
            </p>
            <span
              class="card-offer__col-val"
            >{{ item.settings.bet_value.min | currencyInt | currency }}</span>
          </div>
          <div
            v-if="item.settings.bet_value.max"
            class="card-offer__col card-offer__cup card-offer__dep"
          >
            <p
              class="card-offer__col-title"
            >
              {{ $t('offers.cardOffers.betAmount') }} ({{ $t('offers.cardOffers.max') }})
            </p>
            <span
              class="card-offer__col-val"
            >{{ item.settings.bet_value.max | currencyInt | currency }}</span>
          </div>
        </div>

        <div
          v-if="item.settings.bets_sum"
          class="card-offer__body"
        >
          <div
            v-if="item.settings.bets_sum.min"
            class="card-offer__col card-offer__cup card-offer__dep"
          >
            <p
              class="card-offer__col-title"
            >
              {{ $t('offers.cardOffers.betSum') }} ({{ $t('offers.cardOffers.min') }})
            </p>
            <span
              class="card-offer__col-val"
            >{{ item.settings.bets_sum.min | currencyInt | currency }}</span>
          </div>
          <div
            v-if="item.settings.bets_sum.max"
            class="card-offer__col card-offer__cup card-offer__dep"
          >
            <p
              class="card-offer__col-title"
            >
              {{ $t('offers.cardOffers.betSum') }} ({{ $t('offers.cardOffers.max') }})
            </p>
            <span
              class="card-offer__col-val"
            >{{ item.settings.bets_sum.max | currencyInt | currency }}</span>
          </div>
        </div>

        <div
          v-if="item.settings.bet_coefficient"
          class="card-offer__body"
        >
          <div
            v-if="item.settings.bet_coefficient.min"
            class="card-offer__col card-offer__cup card-offer__dep"
          >
            <p
              class="card-offer__col-title"
            >
              {{ $t('offers.cardOffers.betCoefficient') }} ({{ $t('offers.cardOffers.min') }})
            </p>
            <span class="card-offer__col-val">{{ item.settings.bet_coefficient.min }}</span>
          </div>
          <div
            v-if="item.settings.bet_coefficient.max"
            class="card-offer__col card-offer__cup card-offer__dep"
          >
            <p
              class="card-offer__col-title"
            >
              {{ $t('offers.cardOffers.betCoefficient') }} ({{ $t('offers.cardOffers.max') }})
            </p>
            <span class="card-offer__col-val">{{ item.settings.bet_coefficient.max }}</span>
          </div>
        </div>

        <div
          v-if="item.settings.bet_games_sum"
          class="card-offer__body"
        >
          <div
            v-if="item.settings.bet_games_sum.min"
            class="card-offer__col card-offer__cup card-offer__dep"
          >
            <p
              class="card-offer__col-title"
            >
              {{ $t('offers.cardOffers.BetGamesSum') }} ({{ $t('offers.cardOffers.min') }})
            </p>
            <span
              class="card-offer__col-val"
            >{{ item.settings.bet_games_sum.min | currencyInt | currency }}</span>
          </div>
          <div
            v-if="item.settings.bet_games_sum.max"
            class="card-offer__col card-offer__cup card-offer__dep"
          >
            <p
              class="card-offer__col-title"
            >
              {{ $t('offers.cardOffers.BetGamesSum') }} ({{ $t('offers.cardOffers.max') }})
            </p>
            <span
              class="card-offer__col-val"
            >{{ item.settings.bet_games_sum.max | currencyInt | currency }}</span>
          </div>
        </div>

        <div
          v-if="item.settings.bet_game_value"
          class="card-offer__body"
        >
          <div
            v-if="item.settings.bet_game_value.min"
            class="card-offer__col card-offer__cup card-offer__dep"
          >
            <p
              class="card-offer__col-title"
            >
              {{ $t('offers.cardOffers.betGamesAmount') }} ({{ $t('offers.cardOffers.min') }})
            </p>
            <span
              class="card-offer__col-val"
            >{{ item.settings.bet_game_value.min | currencyInt | currency }}</span>
          </div>
          <div
            v-if="item.settings.bet_game_value.max"
            class="card-offer__col card-offer__cup card-offer__dep"
          >
            <p
              class="card-offer__col-title"
            >
              {{ $t('offers.cardOffers.betGamesAmount') }} ({{ $t('offers.cardOffers.max') }})
            </p>
            <span
              class="card-offer__col-val"
            >{{ item.settings.bet_game_value.max | currencyInt | currency }}</span>
          </div>
        </div>

        <div
          v-if="item.settings.bet_games_count"
          class="card-offer__body"
        >
          <div
            v-if="item.settings.bet_games_count.min"
            class="card-offer__col card-offer__cup card-offer__dep"
          >
            <p
              class="card-offer__col-title"
            >
              {{ $t('offers.cardOffers.betGames') }} ({{ $t('offers.cardOffers.min') }})
            </p>
            <span class="card-offer__col-val">{{ item.settings.bet_games_count.min }}</span>
          </div>
          <div
            v-if="item.settings.bet_games_count.max"
            class="card-offer__col card-offer__cup card-offer__dep"
          >
            <p
              class="card-offer__col-title"
            >
              {{ $t('offers.cardOffers.betGames') }} ({{ $t('offers.cardOffers.max') }})
            </p>
            <span class="card-offer__col-val">{{ item.settings.bet_games_count.max }}</span>
          </div>
        </div>

        <div class="card-offer__footer">
          <p class="card-offer__country-title">
            {{ $t('offers.cardOffers.countryFilter') }}
          </p>
          <div class="card-offer__set-country">
            <span
              v-for="item in countriesProd(item)"
              :key="item + Date.now()"
              class="card-offer__country"
              v-html="item"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  filters: {
    percent(value) {
      if (!value) {
        return '-';
      }
      return `${parseFloat(value)}%`;
    },
    days(val) {
      const value = parseInt(val);
      if (value / 86400 < 1) {
        return Math.floor(value / 3600) + this.$t('offers.cardOffers.hours');
      }
      const day = Math.floor(value / 86400);
      const remain = value % 86400;
      const hours = Math.floor(remain / 3600);
      return day + this.$t('offers.cardOffers.days') + hours + this.$t('offers.cardOffers.hours');
    },
  },
  props: ['item'],
  computed: {
    offersMy() {
      if (this.item) {
        return this.item;
      }
      return this.$store.state.offers.my;
    },
  },
  activated() {
    this.$store.dispatch('getOffers');
    this.$store.dispatch('getTrafficSourcesLazy', {});
  },
  methods: {
    countriesProd(item) {
      if (!item.settings.traffic_filter) return [`<span class='text-green'>${this.$t('offers.cardOffers.allTrafficAcceptable')}</span>`];

      return item.settings.traffic_filter.map((e) => {
        const item = e.conditions.country_code;
        if (item.predicate === 'NOT_EQUALS') {
          if (!item.values.length) {
            return `<span class='text-green'>${this.$t('offers.cardOffers.allTrafficAcceptable')}</span>`;
          }
          return `<span class="text-red">${this.$t('offers.cardOffers.except')}: </span>${item.values.join(', ')}`;
        } if (item.predicate === 'EQUALS') {
          if (!item.values.length) {
            return `<span class='text-green'>${this.$t('offers.cardOffers.allTrafficAcceptable')}</span>`;
          }
          return `<span class="text-green">${this.$t('offers.cardOffers.allowed')}: </span>${item.values.join(', ')}`;
        }
        return `<span class='text-green'>${this.$t('offers.cardOffers.allTrafficAcceptable')}</span>`;
      });
    },
    formatDays(val) {
      const value = parseInt(val);
      if (!val) {
        return this.$t('offers.cardOffers.fewSeconds');
      }

      const month = this.$moment.duration(value, 'seconds').months();
      const day = this.$moment.duration(value, 'seconds').days();
      const hours = this.$moment.duration(value, 'seconds').hours();
      const min = this.$moment.duration(value, 'seconds').minutes();
      const sec = this.$moment.duration(value, 'seconds').seconds();

      return (
        ((month && `${month + this.$t('customReports.month')} `) || '')
        + ((day && `${day + this.$t('offers.cardOffers.days')} `) || '')
        + ((hours && `${hours + this.$t('offers.cardOffers.hours')} `) || '')
        + ((min && `${min + this.$t('offers.cardOffers.minutes')} `) || '')
        + ((sec && sec + this.$t('offers.cardOffers.seconds')) || '')
      );
    },
  },
};
</script>
