<template>
  <div class="col-md-5 ml-auto mr-auto login-wrapper wrong-credentials">
    <div class="login-logo">
      <img :src="logo" alt="logo">
    </div>
    <form>
      <card class="card-login">
        <i class="far fa-times-circle" />
        <div class="">
          <h3 class="text-center mt-32">
            {{ $t('forms.validation.wrongEmailPassword') }}
          </h3>
        </div>
        <div class="btn-box d-flex justify-content-between w-100">
          <a class="btn btn-outline-primary btn-md w-100 hhov" @click="$router.push('/login')">
            <span>&lt; {{ $t('forms.btn.back') }}</span>
          </a>
        </div>
      </card>
    </form>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // mainUrl: window.location.origin,
    };
  },
  computed: {
    logo() {
      return this.$config.layout.logoFull;
    },
  },
};
</script>

<style lang="scss">
.wrong-credentials{
  .hhov span{
    font-size: 14px !important;
    color: #000 !important;
  }
  .card-body{
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  h3{
    margin-bottom: 33px;
  }
  @media (max-width: 450px) {
    margin-top: 4vh !important;
    form {
      position: absolute;
      width: calc(100% + 52px);
      left: 0;
      top: 80px;
      margin-left: -26px;
    }
    .card-login {
      height: 1000px;
      border-radius: 0 !important;
      padding: 16px 35px !important;
    }
    .error__block, .loading__block {
      position: absolute;
      width: 100% !important;
      left: 0;
      top: 0;
      padding: 44px 53px 30px !important;
    }
    .loading__block {
      top: 10% !important;
      z-index: -1;
    }
  }
}
</style>
