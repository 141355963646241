<template>
  <ui-popup
    ref="columnsPopup"
    :selected="selected"
    :width="1000"
    :title="$t('customReports.columnsSettings')"
    class="columnsPopup"
    @save="applyColumnsSettings"
    @cancel="cancelColumnsSettings"
    @reset="reset"
  >
    <div class="d-flex">
      <div class="selects pr-24">
        <div class="controls">
          <h6>{{ $t('customReports.columnsVisibility') }}</h6>
          <ui-input
            v-model="filter"
            :width="440"
            pre-icon="search"
            ref="filter"
            :placeholder="$t('customReports.search')"
            class="search-input"
            autofocus
          />
        </div>
        <div class="d-flex mb-10" style="min-height: 20px">
          <div class="checkbox" v-if="!filter">
            <input id="selectAll" type="checkbox" v-model="selectAll" @change="onSelectAll" />
            <label for="selectAll" class="checkbox-label">
              {{ $t('customReports.selectAll') }}
            </label>
          </div>
          <div class="checkbox" v-if="filter">
            <input
              id="selectMatched"
              type="checkbox"
              v-model="selectMatched"
              @click="clickSelectMatched(!selectMatched)"
            />
            <label for="selectMatched" class="checkbox-label">
              {{ $t('customReports.selectMatched') }}
            </label>
          </div>
          <div class="checkbox ml-16">
            <input id="showSelected" type="checkbox" v-model="showOnlySelected" />
            <label for="showSelected" class="checkbox-label">
              {{ $t('customReports.showOnlySelected') }}
            </label>
          </div>
        </div>
        <div class="selects__empty mt-16" v-if="showNoData(groups)">
          <span>{{ $t('offers.noData') }}</span>
        </div>
        <div class="groups__wrap pr-2" v-if="!showNoData(groups)">
          <div class="mb-8" v-for="group in groups" :key="group.group_name" v-show="showGroup(group)">
            <div class="collapse-groups">
              <div class="checkbox pointer">
                <input
                  :id="'selectGroup-' + group.group_name"
                  type="checkbox"
                  :checked="isGroupSelected(group) === 'yes'"
                  @click="selectGroup(group)"
                />
                <label
                  :for="'selectGroup-' + group.group_name"
                  class="checkbox-label"
                  :class="{ 'some-selected': isGroupSelected(group) !== 0 && isGroupSelected(group) !== 'yes' }"
                >
                  <!-- {{$t(`customReports.${group.group_name}`)}} -->
                  {{ selectTitleGroup(group.group_name) }}
                </label>
                <label class="checkbox-label checkbox-label-padding" @click="$event.stopPropagation()">
                  <span class="pl-8 text-green">
                    {{ countByGroup(group, selected) }} / {{ countGroup(group) }}
                    {{ $t('customReports.columns') }}
                  </span>
                </label>
              </div>
              <span class="collapse-btn text-green" @click="hideGroup(group)">
                <template v-if="!isGroupHidden(group)">
                  {{ $t('customReports.hide') }}
                </template>
                <template v-else>
                  {{ $t('customReports.show') }}
                </template>
                <i
                  class="fal pl-4"
                  :class="{ 'fa-chevron-up': !isGroupHidden(group), 'fa-chevron-down': isGroupHidden(group) }"
                ></i>
              </span>
            </div>
            <div
              class="flex-column"
              v-for="column in group.items"
              :key="column.name"
              v-show="showColumn(group, column)"
            >
              <div class="item w-100" @click="select(column.name, $event)">
                <div class="checkbox stop">
                  <input :id="'selectItem-' + column.name" type="checkbox" :checked="selected.includes(column.name)" />
                  <label :for="'selectItem-' + column.name" class="checkbox-label">
                    {{ selectTitle(column.name) }}
                  </label>
                </div>
                <span class="description">
                  {{ selectDesc(column.name) }}
                </span>
              </div>
              <div
                v-for="child in column.items || []"
                :key="child.name + column.name + child.searchString"
                v-show="showSubColumn(group, child)"
                class="child w-100"
                @click="select(child.name, $event)"
              >
                <div class="checkbox stop">
                  <input :id="'selectItem-' + child.name" type="checkbox" :checked="selected.includes(child.name)" />
                  <label :for="'selectItem-' + child.name" class="checkbox-label">
                    {{ selectTitle(child.name) }}
                  </label>
                </div>
                <span class="description">
                  {{ selectDesc(child.name) }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="list pl-24">
        <div class="controls mb-0">
          <h6>{{ $t('customReports.columnsOrder') }}</h6>
        </div>
        <div class="collapse-groups" style="min-height: 34px">
          <div class="checkbox">
            <label>
              {{ $t('customReports.visibleColumns') }}
              <span class="text-green ml-8 pl-10">{{ selected.length }} {{ $t('customReports.columns') }}</span>
            </label>
          </div>
        </div>
        <draggable :list="draggableItems" :key="selected.length" class="draggable__wrap">
          <div
            v-for="column in draggableItems"
            :key="column.order"
            v-show="selected.includes(column.name)"
            class="item drag"
          >
            <div class="checkbox">
              <label class="checkbox-label stop">
                {{ selectTitle(column.name) }}
              </label>
            </div>
            <span class="description">
              {{ selectDesc(column.name) }}
              <label :for="'selectItem-' + column.name" class="checkbox-label delete-item">
                <i class="fal fa-times-square"></i>
              </label>
            </span>
            <i class="fas fa-grip-lines"></i>
          </div>
        </draggable>
      </div>
    </div>
  </ui-popup>
</template>

<script>
import draggable from 'vuedraggable';

export default {
  name: 'UiColumnsSelect',
  components: {
    draggable,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    metrics: {
      type: Array,
      default: () => [],
    },
    groups: {
      type: Array,
      default: () => [],
    },
    default: {
      type: Array,
      default: () => [],
    },
    supported: {
      type: Array,
      default: () => [],
    },
    isDragged: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      filter: '',
      expand: false,
      draggableItems: this.$_.cloneDeep(this.metrics),
      selected: this.value,
      showOnlySelected: false,
      selectAll: false,
      selectMatched: false,
      hideOtherList: true,
      collapse: this.groups.slice(0, 2),
      changed: new Date().getTime(),
      filteredList: [],
    };
  },
  computed: {
    filteredArray() {
      return this.filteredList.map(({ name }) => name);
    },
    metricsss() {
      return this.metrics;
    },
  },
  watch: {
    value(value) {
      if (JSON.stringify(this.selected) !== JSON.stringify(value)) {
        this.selected = this.$_.clone(this.value);

        // causes additional reqests
        // if (!this.isDragged) {
        //   this.$emit('changed');
        // }
      }
    },
    groups() {
      this.collapse = this.groups.slice(0, 2);
    },
    selected(value) {
      const newSelectAll = this.supported.length === value.length;

      if (this.selectAll !== newSelectAll) {
        this.selectAll = newSelectAll;
      }
    },
    showOnlySelected() {
      this.$forceUpdate();
    },
    filter() {
      this.filteredList = [];
      this.selectMatched = false;

      // проверяем все метрики: в группах, подгруппах и одиночные и пушим в список- filteredList
      this.groups.forEach(e => e.items.forEach((i) => {
        if (i.items) {
          if (i.items.filter(c => this.find(c.searchString))[0] && this.supported.includes(i.name) || this.find(i.searchString)) {
            this.filteredList.push({
              name: i.name,
              searchString: i.searchString,
            });
            i.items.filter(c => this.find(c.searchString)).forEach(y => this.filteredList.push(y));
          }
        } else if (this.find(i.searchString) && this.supported.includes(i.name)) {
          this.filteredList.push(i);
        }
      }));

      this.manualCheck();
      this.collapse = this.groups.slice();
    },
    metrics() {
      this.draggableItems = this.$_.cloneDeep(this.metrics);
    },
  },
  methods: {
    onSelectAll() {
      if (this.selectAll) {
        this.selected = this.draggableItems.map(e => e.name).filter(e => this.supported.includes(e));
      } else {
        this.selected = [];
      }
    },
    clickSelectMatched(value) {
      if (value) {
        this.filteredList.filter(e => !this.selected.includes(e.name)).forEach(e => this.selected.push(e.name));
      } else {
        this.filteredList.forEach((e) => {
          this.selected = this.selected.filter(i => i !== e.name);
        });
      }
    },
    applyColumnsSettings() {
      const draggableItems = this.draggableItems.filter(e => this.selected.includes(e.name)).map(e => e.name);

      this.$emit('input', draggableItems);
      this.$emit('changed', draggableItems);
      this.filter = '';
      this.$refs.columnsPopup.close();
    },
    cancelColumnsSettings() {
      this.selected = this.value;
      this.filter = '';
      this.$refs.columnsPopup.close();
    },
    resetColumnsOrder() {
      this.draggableItems = this.$_.cloneDeep(this.metrics);
    },
    reset() {
      this.selectAll = false;
      this.$nextTick(() => {
        this.selected = this.$_.cloneDeep(this.default);
      });
      this.resetColumnsOrder();
    },
    isSupported(metric) {
      return this.supported.includes(metric);
    },
    manualCheck() {
      this.selectMatched = this.filteredList.every(e => this.selected.includes(e.name));
    },
    select(column) {
      if (this.selected.includes(column)) {
        this.selected.splice(this.selected.indexOf(column), 1);
      } else {
        this.selected.push(column);
      }

      this.manualCheck();
    },
    selectGroup(group) {
      const items = this.getByGroup(group).map(e => e.name);
      const select = this.isGroupSelected(group);
      // отфильтрованный массив по поиску и по уже выбранным метрикам(то есть то, что нужно чекнуть сейчас)
      const groupFilterList = this.filteredList.filter(e => items.includes(e.name) && !this.selected.includes(e.name));
      // отфильтрованный массив по поиску(нужен в случае, когда groupFilterList = 0 -то есть уже выбранны все метрики в группе)
      const groupFilterList2 = this.filteredList.filter(e => items.includes(e.name));

      if (!this.filter) {
        if (select === 'yes') {
          this.selected = this.selected.filter(e => !items.includes(e));
        } else if (select < items.length) {
          this.selected = this.selected.concat(items.filter(e => !this.selected.includes(e)));
        } else {
          this.selected = this.selected.concat(items);
        }
      } else if (!groupFilterList.length) {
        groupFilterList2.forEach((e) => {
          this.selected = this.selected.filter(i => i !== e.name);
        });
      } else {
        groupFilterList.forEach(e => this.selected.push(e.name));
      }
      this.manualCheck();
    },
    isGroupSelected(group) {
      const items = this.getByGroup(group);
      const selected = this.selected.filter(e => items.map(c => c.name).includes(e));
      return selected.length !== 0 && items.length === selected.length ? 'yes' : selected.length;
    },
    getByGroup(group = {}) {
      const { items = [] } = group;
      const childs = items
        .map(g => (g.items || [])
          .filter((e) => {
            if (this.filteredArray.length) {
              return this.filteredArray.includes(e.name)
            }

            return true;
          })
        .map(c => c.name)).flat();
      const all = items
        .filter((e) => {
          if (this.filteredArray.length) {
            return this.filteredArray.includes(e.name)
          }

          return true;
        })
        .map(e => e.name)
        .concat(childs)
        .filter(x => this.isSupported(x));
      return this.draggableItems.filter(e => all.includes(e.name));
    },
    open(value) {
      if (value && value.length) this.selected = this.$_.clone(value);
      const indexGroups = (columns) => {
        columns.map((e) => {
          e.searchString = `${this.selectTitle(e.name)} ${this.selectDesc(e.name)}`.toLowerCase();
          if (e.items) {
            indexGroups(e.items);
          }
        });
      };
      this.groups.map((g) => {
        indexGroups(g.items);
      });
      this.$refs.columnsPopup.open();
      setTimeout(() => this.$refs.filter.focus(), 300);
    },
    showSelected(item, group) {
      if (this.showOnlySelected) {
        return this.selected.indexOf(item) !== -1 || group && this.countHiddenGroup(group);
      }
      return true;
    },
    isGroupHidden(group) {
      return !this.collapse.includes(group);
    },
    hideGroup(group) {
      if (this.collapse.includes(group)) {
        this.collapse.splice(this.collapse.indexOf(group), 1);
      } else {
        this.collapse.push(group);
      }
    },
    showGroup(group) {
      if (this.showOnlySelected) {
        return this.countHiddenGroup(group);
      }

      if (!this.filter) {
        return this.countGroup(group);
      }

      const searchStrings = group.items
        .map(c => (c.items ? [c.searchString].concat(c.items.map(cc => cc.searchString)) : [c.searchString]))
        .flat();

      return searchStrings.find(ss => ss.includes(this.filter.toLowerCase()));
    },
    showColumn(group, column) {
      return (
        (column.items?.filter(c => this.find(c.searchString))[0] || this.find(column.searchString))
        && this.isSupported(column.name)
        && !this.isGroupHidden(group)
        && this.showSelected(column.name, column)
      );
    },
    showSubColumn(group, subColumn) {
      return (
        this.find(subColumn.searchString, 1)
        && this.isSupported(subColumn.name, 1)
        && !this.isGroupHidden(group)
        && this.showSelected(subColumn.name)
      );
    },
    showNoData(groups) {
      const nodata = groups.filter(e => this.showGroup(e) !== false);
      return nodata.length === 0;
    },
    countByGroup(group, selected) {
      return this.getByGroup(group).filter(e => {
        if (this.filteredArray.length) {
          return this.filteredArray.includes(e.name) && selected.includes(e.name)
        } else {
          return selected.includes(e.name);
        }
      }).length;
    },
    countGroup(group) {
      return this.getByGroup(group).length;
    },
    countHiddenGroup(group) {
      return this.getByGroup(group).filter(({ column_name }) => this.selected.includes(column_name)).length;
    },
    find(text) {
      return text ? text.toLowerCase().includes(this.filter.toLowerCase()) : true;
    },
    selectTitleGroup(name) {
      return this.$store.state.settings.metrics_groups.find(e => e.group_name === name).title;
    },
    selectTitle(name) {
      return this.metrics.find(e => e.column_name === name).title;
    },
    selectDesc(name) {
      return this.metrics.find(e => e.column_name === name).description;
    },
  },
};
</script>

<style lang="scss">
@import 'src/assets/theme/default/components/UiColumnsSelect';
</style>
