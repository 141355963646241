<template>
  <div
    class="bookmarks-list-mobile select bookmarks-dropdown w-100 ml-0 mr-0"
    :class="{'hideBookmarks': hideBookmarks && bookmarks.length}"
  >
    <div
      class="select-link"
      @click="hideBookmarks = !hideBookmarks"
    >
      <span v-if="!mobBookmarkName" class="text">
        <i class="fas fa-star"></i>
        {{ $t('customReports.bookmarks') }}
      </span>

      <span v-else class="text report_name">
        {{ mobBookmarkName }}
      </span>

      <i class="el-icon-arrow-down el-icon--right" />
    </div>
    <button
      v-if="hideBookmarks"
      class="btn btn-outline-primary btn-sm btn-refresh w-100 mt-8"
      :disabled="$_.isEmpty(reports[currentReport].defaultFilter)"
      @click="handleAddBookmark"
    >
      <i class="fal fa-plus pr-2" />
      {{ $t('customReports.addBookmark') }}
    </button>
    <div v-if="openModal" class="openModal">
      <div>
        <ui-input
          v-model="constTabBookmarkName"
          :label="$t('customReports.addBookmark')"
          class="boookmark-edit__input"
        />
        <span class="d-flex justify-content-end mt-8">
          <button
            class="btn btn-outline-primary btn-sm"
            @click="openModal = false"
          >
            {{ $t('profile.buttons.cancel') }}
          </button>
          <button
            class="btn btn-primary btn-sm ml-16"
            @click="saveTabBookmark(1), openModal = false"
          >
            {{ $t('profile.buttons.save') }}
          </button>
        </span>
      </div>
    </div>

    <div
      class="grouping_select_item"
      @click="handleOpenBookmark()"
    >
      <span class="text-green">{{ $t('customReports.defaultReport') }}</span>
    </div>

    <div
      class="grouping_select_item"
      v-for="(item, key) in bookmarks"
      :key="item.key"
      :class="{ 'active-bookmark': isPopoverEditIndex === key }"
      @click="handleOpenBookmark(key)"
    >
      <span>{{ item.bookmarkName }}</span>
    </div>
  </div>
</template>

<script>
import BookmarksMixin from './bookmarks-mixin.js';

export default {
  mixins: [
    BookmarksMixin,
  ],
  computed: {
    playersType() {
      return this.reports[this.currentReport].playersType;
    },
  },
  data() {
    return {
      openModal: false,
      hideBookmarks: false,
      mobBookmarkName: '',
      constTabBookmarkName: '',
      playerTypeMob: false,
    };
  },
  methods: {
    handleOpenBookmark(bookmarkIndex) {
      this.$emit('remove-promocode-report');

      if (bookmarkIndex) {
        this.pushBookmark(bookmarkIndex);
        this.mobBookmarkName = this.bookmarks[bookmarkIndex].bookmarkName;
      }

      this.hideBookmarks = false;
    },
    handleAddBookmark() {
      if (this.reports[1].bookmarkName === '') {
        this.constTabBookmarkName = `${(this.allDimension.find(e => e.column_name === Object.keys(this.reports[1].defaultFilter)[0]) || {}).title}: ${this.reports[1].defaultFilterName}`;
        (allDimension.find(e => e.column_name === key) || {}).title;
      } else {
        this.constTabBookmarkName = this.reports[1].bookmarkName;
      }

      this.openModal = !this.openModal;
    },
  },
};
</script>
