<template>
  <section class="promo__codes-form-wrap media-buying-modal">
    <modal :show.sync="active" @close="close">
      <h5 slot="header" class="modal-title">
        {{$t(form.id ? 'mediaBuying.campaigns.edit_campaign' :
          'mediaBuying.campaigns.add_campaign')}}
      </h5>
      <template>
        <div>
          <form>
            <div v-if="rootCampaignId === null && !form.edited">
              <div class="form-group select-wrapper mb-8 mt-0">
                <label>{{$t('mediaBuying.campaign.cost_traffic_tracking')}}</label>
                <div :key="costTrafficTracking" class="type__radio-wrapper d-flex">
                  <el-radio v-model="costTrafficTracking" label="child">
                    {{$t('mediaBuying.campaign.nested_campaigns')}}
                  </el-radio>
                  <el-radio v-model="costTrafficTracking" label="parent">
                    {{$t('mediaBuying.campaign.this_campaign')}}
                  </el-radio>
                </div>
              </div>
              <div class="form-group select-wrapper mb-8 mt-0">
                <label>{{$t('mediaBuying.campaign.payment_requests')}}</label>
                <div :key="paymentRequests" class="type__radio-wrapper d-flex">
                  <el-radio v-model="paymentRequests" label="child">
                    {{$t('mediaBuying.campaign.nested_campaigns')}}
                  </el-radio>
                  <el-radio v-model="paymentRequests" label="parent">
                    {{$t('mediaBuying.campaign.this_campaign')}}
                  </el-radio>
                </div>
              </div>
            </div>
            <div class="form-group select-wrapper mb-8 mt-0">
              <label>{{$t('mediaBuying.channels.fields.channel_name.title')}}</label>
              <input
                v-model="form.name"
                :placeholder="$t('mediaBuying.channels.fields.channel_name.title')"
                autocomplete="off"
                type="text"
                class="form-control m-0 w-100"
                @keydown.enter.prevent
                @click="error.name = false"
              >
              <span v-if="error.name" class="text-danger text-left mt-2">
                {{$t('mediaBuying.channels.errors.invalid_name')}}
              </span>
            </div>
            <div class="form-group select-wrapper mb-8 mt-0">
              <label>{{$t('mediaBuying.channels.fields.description.title')}}</label>
              <el-input
                type="textarea"
                maxlength="1024"
                v-model="form.description"
                :autosize="{ minRows: 3, maxRows: 8}"
                class="no-border form-control-lg textarea-autosize"
                :placeholder="$t('mediaBuying.channels.fields.description.title')"
                @input="error.description = false"
              />
              <span v-if="error.description" class="text-danger text-left mt-2">
                {{$t('mediaBuying.channels.errors.invalid_description')}}
              </span>
            </div>
          </form>
        </div>
      </template>
      <div slot="footer" class="text-right d-flex align-items-baseline">
        <a class="btn btn-outline-primary btn-sm mb-4" @click="close">
          {{ $t('promoCodes.addPromocodes.cancel') }}
        </a>
        <a class="btn btn-primary btn-sm ml-16" :disabled="loading" @click="send">
          <span v-if="!loading">
            {{$t(form.id ? 'mediaBuying.channels.save' : 'mediaBuying.channels.add')}}
          </span>
          <i v-if="loading" class="fas fa-spinner spinner-hide" />
        </a>
      </div>
    </modal>
  </section>
</template>

<script>
import { Modal } from 'src/components';

export default {
  components: {
    Modal,
  },
  data() {
    return {
      rootCampaignId: null,
      costTrafficTracking: 'child',
      paymentRequests: 'child',
      active: false,
      form: {
        name: '',
        description: '',
        payments_currency_code: 'USD',
        cost_model: 'fixed',
      },
      typesPayment: [
        {
          id: 'fixed',
          text: this.$t('mediaBuying.campaigns.fixed_price'),
        },
        {
          id: 'cpm',
          text: 'CPM',
        },
        {
          id: 'cpc',
          text: 'CPC',
        },
        {
          id: 'cpv',
          text: 'CPV',
        },
        {
          id: 'cpi',
          text: 'CPI',
        },
      ],
      campaign_id: '',
      error: [],
      dropdownFiltersSettings: {
        minimumResultsForSearch: Infinity,
      },
      locale: '',
      loading: false,
    };
  },
  props: ['id'],
  computed: {
    currencyList() {
      return this.$store.state.misc.currencies.map(e => ({
        id: e.code,
        text: e.name,
      }));
    },
  },
  methods: {
    open(campaign) {
      this.active = true;
      this.rootCampaignId = campaign.root_campaign_id || null;

      if (campaign.edited) {
        this.form = this.$_.cloneDeep(campaign);
        this.costTrafficTracking = this.form.cost_traffic_tracking;
        this.paymentRequests = this.form.payment_requests;
      }
    },
    close() {
      this.active = false;
      this.reset();
    },
    reset() {
      this.error = [];
      this.form = {
        name: '',
        description: '',
        payments_currency_code: 'USD',
        cost_model: 'fixed',
      };
      this.costTrafficTracking = 'child';
      this.paymentRequests = 'child';
    },

    send() {
      this.loading = true;
      if (!this.form.edited) {
        this.$store.dispatch('createCampaign', {
          name: this.form.name,
          description: this.form.description,
          root_campaign_id: this.rootCampaignId,
          cost_traffic_tracking: this.costTrafficTracking,
          payment_requests: this.paymentRequests,
        })
          .then(() => {
            this.close();
            this.$emit('addCampaign');
            this.$alert.success(this.$t('mediaBuying.campaigns.creation_success_message'));
          })
          .catch((err) => {
            this.error = err.response.data.errors;
          })
          .finally(() => {
            this.loading = false;
          });
      } else {
        this.$store.dispatch('editCampaign', {
          id: this.form.id,
          name: this.form.name,
          description: this.form.description,
        })
          .then(() => {
            this.close();
            this.$emit('addCampaign');
            this.$alert.success(this.$t('mediaBuying.campaigns.edit_success_message'));
          })
          .catch((err) => {
            this.error = err.response.data.errors;
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
  },
  updated() {
    this.locale = this.$locale;
  },
  watch: {
    locale() {
      this.typesPayment[0].text = this.$t('mediaBuying.campaigns.fixed_price');
    },
  },
};
</script>
