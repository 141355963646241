export default {
  saveSettings(state, {
    payload: {
      intercom, limitations, postback_tokens, tag_manager_tokens, telegram_bot, reports, optional_sections, manager_info,
    } = {},
  } = {}) {
    const rim = [];
    const dim = reports.grouped_dimensions.forEach(i => i.items.forEach(y => rim.push(y.name)));
    state.intercom = intercom;
    state.limitations = limitations;
    state.manager_info = manager_info;
    state.postback_tokens = postback_tokens;
    state.tag_manager_tokens = tag_manager_tokens;
    state.optional_sections = optional_sections;
    state.telegram_bot = telegram_bot;
    state.reports = reports;
    state.dimensionsAll = reports.dimensions.map((e) => { e.name = e.column_name; return e; });
    state.dimensions = state.dimensionsAll.filter(e => rim.includes(e.name));
    state.dimensions_groups = reports.dimensions_groups;
    state.grouped_dimensions = reports.grouped_dimensions;
    state.grouped_metrics = reports.grouped_metrics;
    state.metrics = reports.metrics.map((e) => { e.name = e.column_name; return e; });
    state.metrics_groups = reports.metrics_groups;
    state.metrics_visible_by_default = reports.metrics_visible_by_default;
    state.notifyReady();
  },
};
