<template>
  <div class="connection-lost">
    <h1 class="text-center text-white">
      <i class="fas fa-wifi" />
      <br>
      <small class="small-text">{{ $t('alerts.connectionFail.message') }}</small>
    </h1>
    <div class="login-logo">
      <img :src="logo" alt="logo">
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    logo() {
      return this.$config.layout.logoFull;
    },
  },
};
</script>

<style lang="scss" scoped>
.connection-lost{
  z-index: 999999999999999999;
  position: fixed;
  display: flex;
  justify-content: center;
  flex-direction: column;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 96vh;
  .small-text{
    font-family: monospace;
    font-size: 22px;
  }
}
</style>
