<template>
  <el-popover v-model="visible"
              popper-class="confirm-popover"
              :placement="placement"
              :title="title"
              :trigger="trigger"
              :width="width"
              :disabled="disabled"
  >
    <p>{{message}}</p>
    <div v-if="buttons">
      <el-button v-for="button in buttons"
                 class="btn btn-sm"
                 :class="button.type ==='success' ? 'btn-outline-green' : button.type ==='danger' ? 'btn-outline-danger' : 'btn-outline-primary'"
                 :type="button.type"
                 :key="button.type"
                 @click.prevent="() => clickButtonHandler(button.click)"
      >
        {{button.text}}
      </el-button>
    </div>
    <slot slot="reference"/>
  </el-popover>
</template>

<script>
export default {
  name: 'Popover',
  data() {
    return {
      visible: false,
    };
  },
  props: {
    placement: {
      default: 'top',
      type: String,
    },
    title: {
      default: '',
      type: String,
    },
    trigger: {
      default: 'click',
      type: String,
    },
    width: {
      default: 200,
      type: Number,
    },
    message: {
      default: '',
      type: String,
    },
    buttons: {
      default: null,
      type: Array,
    },
    disabled: {
      default: false,
      type: Boolean,
    },
  },
  methods: {
    clickButtonHandler(handler) {
      this.visible = false;

      if (handler) {
        handler();
      }
    },
  },
};
</script>

<style lang="scss">
.confirm-popover {
  > p {
    word-break: break-word;
  }
  & > div {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    & > button {
      outline: none;
      height: 34px;
      margin-left: 8px !important;
    }
  }
}
</style>
