<template>
  <section class="traffic-source__modal">
    <modal :show.sync="active">
      <h5
        slot="header"
        class="modal-title"
      >
        {{ $t('trafficSources.addTrafficSources.title') }}
      </h5>

      <template>
        <form @keyup.enter="send">
          <div class="form-group select-wrapper mt-0">
            <label>{{ $t('trafficSources.addTrafficSources.name') }}</label>
            <input
              v-model="form.name"
              :placeholder="$t('trafficSources.addTrafficSources.trafficSourceName')"
              autocomplete="off"
              type="text"
              class="form-control mt-0 w-100"
              @input="changed = false"
            >
            <div
              v-if="changed && invalid_name"
              class="text-danger text-left mt-2"
            >
              {{ $t('trafficSources.addTrafficSources.pleaseInputName') }}
            </div>
          </div>
          <div class="form-group select-wrapper form-group__external mb-4">
            <label>{{ $t('trafficSources.addTrafficSources.externalId') }}</label>
            <input
              v-model="form.external_id"
              :placeholder="$t('trafficSources.addTrafficSources.externalId')"
              autocomplete="off"
              type="text"
              class="form-control mt-0 w-100"
            >
            <!-- <div class="text-danger text-left mt-2">
              {{ $t('trafficSources.addTrafficSources.pleaseInputExternal') }}
            </div> -->
          </div>

          <div class="row row-wrap__subid">
            <div
              v-for="(item, i) in form.subids"
              :key="i"
              class="col-6 col-6__add-subid"
            >
              <fg-input
                v-model="item.value"
                :placeholder="$t('trafficSources.postbacks.notSpecified')"
                class="no-border form-control-lg mb-0"
              >
                <label
                  slot="label"
                  class="d-flex justify-content-between"
                >
                  <span>SubID{{ form.subids.indexOf(item) + 1 }}</span>
                  <a
                    v-show="form.subids.indexOf(item) == form.subids.length - 1 && form.subids.length > 1"
                    href="#"
                    class="btn-cross"
                    @click.prevent="popSubIds"
                  >
                    <span>×</span>
                  </a>
                </label>
              </fg-input>
            </div>
            <div class="col-6 d-flex align-items-end col-6__add-subid">
              <button
                :disabled="form.subids.length >= 5"
                href="#"
                class="btn btn-block btn-outline-primary btn-sm mt-24 mb-0"
                @click.prevent="form.subids.length < 5 && addSubId()"
              >
                + {{ $t('promoCodes.addPromocodes.addAnotherSubid') }}
              </button>
            </div>
          </div>
        </form>
      </template>

      <div
        slot="footer"
        class="text-right"
      >
        <a
          class="btn btn-outline-primary btn-sm mt-8 mb-4"
          @click="close"
        >{{ $t('trafficSources.addTrafficSources.cancel') }}</a>
        <a
          class="btn btn-primary btn-sm ml-16 mt-8 mb-4"
          @click="send"
        >{{ $t('trafficSources.addTrafficSources.send') }}</a>
      </div>
    </modal>
  </section>
</template>

<script>
import { Modal } from 'src/components';

export default {
  components: {
    Modal,
  },
  props: ['offsetParams'],

  data() {
    return {
      active: false,
      changed: false,
      form: {
        name: '',
        external_id: '',
        subids: [{ value: '' }],
      },
    };
  },

  computed: {
    invalid_name() {
      return !this.form.name.trim();
    },
    invalid_external_id() {
      const regexp = /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;
      return !regexp.test(this.form.external_id.trim());
    },
  },

  methods: {
    open(id) {
      this.reset();
      this.active = true;
    },
    close() {
      this.active = false;
      this.reset();
    },
    send() {
      this.changed = true;
      if (this.invalid_name) return;
      this.$emit('addTrafficSource');
      const data = {
        name: this.form.name,
        external_id: this.form.external_id,
      };
      let i = 1;
      this.form.subids.forEach((e) => {
        data[`subid${i}`] = e.value;
        i++;
      });
      this.$store.dispatch('createTrafficSource', data)
        .then(() => {
          this.$store.dispatch('getTrafficSourcesLazy', { ...this.offsetParams });
          this.$alert.success(this.$t('trafficSources.addTrafficSources.wasCreated'));
        });
      this.close();
    },
    reset() {
      this.form.name = '';
      this.form.external_id = '';
      this.changed = false;
      this.form.subids = [
        {
          value: '',
        },
      ];
    },
    addSubId() {
      this.form.subids.push({
        value: '',
      });
    },
    popSubIds(item) {
      this.form.subids.pop();
    },
  },
};
</script>

<style lang="scss">
.traffic-source__modal {
  textarea {
    border: 1px solid #d9d9d9;
    border-radius: 8px;
    min-height: 115px !important;
  }

  .btn-outline-primary {
    min-width: 100px !important;
  }

  .btn-primary {
  }
}
</style>
