<template>
  <component
    :is="baseComponent"
    :to="link.path ? link.path : '/'"
    :class="{active : isActive}"
    tag="li"
  >
    <a
      v-if="isMenu"
      :aria-expanded="!collapsed"
      href="#"
      class="nav-link sidebar-menu-item"
      data-toggle="collapse"
      @click.prevent="collapseMenu"
    >
      <i :class="link.icon"/>
      <p>
        {{ link.name }}
        <b class="caret"/>
      </p>

    </a>

    <collapse-transition>
      <div
        v-if="$slots.default || this.isMenu"
        v-show="!collapsed"
      >
        <ul class="nav">
          <slot/>
        </ul>
      </div>
    </collapse-transition>

    <slot
      v-if="children.length === 0 && !$slots.default && link.path"
      name="title"
    >
      <component
        :is="elementType(link, false)"
        :to="link.path"
        :class="{active: link.active}"
        :target="link.target"
        :href="'#'+link.path"
        class="nav-link ui-d-flex"
        @click.native="linkClick"
      >
        <template v-if="addLink">
          <span class="sidebar-mini-icon">{{ linkPrefix }}</span>
          <span class="sidebar-normal">{{ link.name }}</span>
        </template>
        <template v-else>
          <i v-if="link.icon" :class="`fas fa-${link.icon}`"></i>
          <img v-if="link.img" :src="link.img" alt=""/>
          <p class="d-flex align-items-center">
            <span> {{ link.name }}</span>
            <span class="tab-count ml-4" v-if="link.path.includes('media-buying-campaigns') && countMb.needs_attention_total">{{countMb.needs_attention_total}}</span>
            <span class="tab-count ml-4" v-if="link.path.includes('media-buying-payments') && countMb.needs_attention_payments">{{countMb.needs_attention_payments}}</span>
            <span class="tab-count ml-4" v-if="link.path.includes('media-buying-posts') && countMb.needs_attention_posts">{{countMb.needs_attention_posts}}</span>
          </p>
        </template>
      </component>
    </slot>
  </component>
</template>
<script>
import { CollapseTransition } from 'vue2-transitions';
import { clone } from 'lodash';

export default {
  name: 'SidebarItem',
  components: {
    CollapseTransition,
  },
  props: {
    menu: {
      type: Boolean,
      default: false,
    },
    link: {
      type: Object,
      default: () => ({
        name: '',
        path: '',
        children: [],
      }),
    },
  },
  provide() {
    return {
      addLink: this.addChild,
      removeLink: this.removeChild,
    };
  },
  inject: {
    addLink: { default: null },
    removeLink: { default: null },
    autoClose: {
      default: true,
    },
  },
  data() {
    return {
      children: [],
      collapsed: true,
      original: {},
    };
  },
  computed: {
    baseComponent() {
      return this.isMenu || this.link.isRoute ? 'li' : 'router-link';
    },
    linkPrefix() {
      if (this.link.name) {
        const words = this.link.name.split(' ');
        return words.map(word => word.substring(0, 1))
          .join('');
      }
    },
    isMenu() {
      return this.children.length > 0 || this.menu === true;
    },
    isActive() {
      if (this.$route && this.$route.path && this.original.path) {
        return this.$route.path.startsWith(this.original.path);
      }
      return false;
    },
    countMb() {
      return this.$store.state.mediaBuying.campaignsAttentionCounters;
      // this.$store.state.mediaBuying.paymentsCountSidebarModeration +
      // this.$store.state.mediaBuying.paymentsCountSidebarPayout;
    },
  },
  mounted() {
    this.original = clone(this.link);
    if (this.addLink) {
      this.addLink(this);
    }
    if (this.link.collapsed !== undefined) {
      this.collapsed = this.link.collapsed;
    }
    if (this.isActive && this.isMenu) {
      this.collapsed = false;
    }
  },
  destroyed() {
    if (this.$el && this.$el.parentNode) {
      this.$el.parentNode.removeChild(this.$el);
    }
    if (this.removeLink) {
      this.removeLink(this);
    }
  },
  methods: {
    addChild(item) {
      const index = this.$slots.default.indexOf(item.$vnode);
      this.children.splice(index, 0, item);
    },
    removeChild(item) {
      const tabs = this.children;
      const index = tabs.indexOf(item);
      tabs.splice(index, 1);
    },
    elementType(link, isParent = true) {
      if (link.isRoute === false) {
        return isParent ? 'li' : 'a';
      }
      return 'router-link';
    },
    linkAbbreviation(name) {
      const matches = name.match(/\b(\w)/g);
      return matches.join('');
    },
    linkClick() {
      if (this.autoClose && this.$sidebar && this.$sidebar.showSidebar === true) {
        this.$sidebar.displaySidebar(false);
      }
    },
    collapseMenu() {
      this.collapsed = !this.collapsed;
    },
    collapseSubMenu(link) {
      link.collapsed = !link.collapsed;
    },
  },
};
</script>
<style lang="scss">

  .livechat-mail {
    animation: pulse 2s infinite;
    float: right;

    img {
      padding-top: 3px;
      height: 22px;
    }
  }

  a.nav-link.router-link-exact-active.active {
    .livechat-mail {
      img {
        filter: invert(1)
      }
    }
  }


  @keyframes pulse {
    0% {
      opacity: 1;
    }
    50% {
      opacity: .2;
    }
    100% {
      opacity: 1;
    }
  }

</style>
