<template>
  <section class="summary">
    <div id="myTabContent" class="tab-content">
      <div
        id="myTraffic"
        class="tab-pane fade show active"
        role="tabpanel"
        aria-labelledby="myTraffic-tab"
      >
        <div class="card card-header">
          <div class="d-flex justify-content-between align-items-center period-filter-btns__wrap">
            <div class="d-flex period-filter__wrap">
              <period-wrapper
                :settings="passedPeriod"
                class="sub-affiliates__period mr-8"
                @update="setRange"
              />
              <filter-reports
                class="filter-hide-mob"
                v-model="filters"
                :from="period.from"
                :to="period.to"
                :key="traffic_filter"
                :filter-id="$route.params.id"
                :use-filters="useFilters"
                :default-filter="defaultFilter"
                :mbFilter="isReferralProgramEnabled"
              />
            </div>
            <div class="btns__wrap">
              <ui-button
                icon="refresh"
                class="btn btn-refresh btn-lowercase ml-8"
                style="width: 34px;"
                @click="setQuery"
              />
            </div>
          </div>
          <filter-reports
            class="filter-show-mob mt-10"
            v-model="filters"
            :from="period.from"
            :to="period.to"
            :filterId="$route.params.id"
            :key="traffic_filter"
            :use-filters="useFilters"
            :default-filter="defaultFilter"
            :mbFilter="isReferralProgramEnabled"
          />
        </div>
        <summary-tables :model="model"/>
      </div>
    </div>
  </section>
</template>

<script>
import { Select, Option } from 'element-ui';
import moment from 'moment';
import UrlMixin from 'src/components/Query/url-mixin';
import UrlMixinHooks from 'src/components/Query/url-mixin-hooks';
import FilterReports from 'src/components/Filter-reports/Filter-reports.vue';
import PeriodWrapper from 'src/views/Dashboard/Reports/Filters/PeriodWrapper.vue';
import SummaryTables from 'src/components/SummaryTables.vue';

export default {
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    FilterReports,
    PeriodWrapper,
    SummaryTables,
  },
  mixins: [UrlMixin, UrlMixinHooks],
  props: ['statusChannel', 'statusCampaign', 'activeTab', 'passedParams'],
  data() {
    return {
      query: {},
      sub_affiliate_id: null,
      traffic_filter: 'all',
      traffic_filter_name: 'all',
      passedPeriod: {
        period: 30,
      },
      period: {},
      filters: [],
      useFilters: [
        'traffic_source_id', 'country_code', 'player_id', 'affiliate_offer_id', 'subid1', 'subid2', 'subid3', 'subid4',
        'subid5',
      ],
      lastReportQuery: '',
      isReferralProgramEnabled: this.$store.state.profile.user.referral_program_enabled,
    };
  },
  computed: {
    model() {
      return this.$store.state.report.summary_totals;
    },
    defaultFilter() {
      return {
        media_buying_campaign_id: {
          op: '=',
          value: [this.$route.params.id],
        },
      };
    },
  },
  created() {
    if (this.activeTab === 'summary') {
      this.setFromQuery(this.passedParams);
    }
  },
  watch: {
    filters(value) {
      (value || []).map((e) => {
        this.traffic_filter = e.traffic_filter;
        this.traffic_filter_name = e.traffic_filter_name;
        if (e.sub_affiliate_id) {
          this.sub_affiliate_id = e.sub_affiliate_id;
        }
      });
      if (!this.$_.isEqual(this.passedParams.filters, this.filters)) {
        this.setQuery();
      }
    },
    activeTab() {
      if (this.activeTab === 'summary') {
        this.setFromQuery(this.passedParams);
        this.setQuery();
      }
    },
  },
  methods: {
    setFromQuery(params) {
      let {
        sub_affiliate_id, traffic_filter, traffic_filter_name, query = {},
      } = params;
      this.query = query;
      query = params;
      this.traffic_filter = traffic_filter || 'all';
      this.traffic_filter_name = traffic_filter_name || 'all';
      this.sub_affiliate_id = sub_affiliate_id || '';

      if (sub_affiliate_id) {
        this.filters = [{ sub_affiliate_id }];
      }

      if (query.passedPeriod) {
        this.setPeriod(query.passedPeriod);
      }

      if (query.period && query.from) {
        this.setPeriod({
          from: query.from,
          to: query.to,
          period: query.period,
        });
      }

      Object.keys(query)
        .map((type) => {
          if (this.useFilters.indexOf(type) === -1) {
            return;
          }

          if (this.filters.find(e => e.type === type) && query[type] === this.filters.find(e => e.type === type).values) {

          } else {
            (this.filters || []).push({
              type,
              values: query[type],
            });
          }
        });
      this.filters = [...this.filters, { traffic_filter: this.traffic_filter }, { traffic_filter_name: this.traffic_filter_name }];
    },
    setQuery(query) {
      this.query = this.getQueryData(query);
      this.$emit('update', { tab: 'summary' }, this.query);
    },
    getQueryData(data) { // одинаковые
      let query = { ...data };
      query = { ...query, ...this.period };

      (this.filters || []).forEach((filter) => {
        if (filter.sub_affiliate_id !== void 0) {
          if (filter.sub_affiliate_id !== 0) query.sub_affiliate_id = filter.sub_affiliate_id;
        } else {
          query = {
            ...query,
            [filter.type]: filter.values,
          };
          if (filter.traffic_filter) {
            query.traffic_filter = filter.traffic_filter;
          }
          if (filter.traffic_filter_name) {
            query.traffic_filter_name = filter.traffic_filter_name;
          }
        }
      });

      query = {
        ...query,
        filters: this.filters,
      };
      this.sub_affiliate_id = query.sub_affiliate_id;

      if (query.sub_affiliate_id === '0') {
        delete query.sub_affiliate_id;
      }

      return query;
    },
    setRange(period) {
      if (this.activeTab === 'summary') {
        if (!this.$_.isEqual(this.period, period)) {
          this.setPeriod(period);
          this.setQuery();
        }
      }
    },
    setPeriod(period) { // одинаковые
      const periodItems = [7, -70, -7, -14, 30, -30, 100, 365, -365, -3650];
      this.period = period;

      if (period.period === 1) {
        this.passedPeriod = { range: [period.from, period.to] };
      } else if (periodItems.includes(period.period)) {
        this.passedPeriod = { period: period.period };
      } else {
        this.passedPeriod = {
          period: `${moment(period.from)
            .format('YYYY-MM-DD')} ${this.$t('periodWrapper.to')} ${moment(period.to)
            .format('YYYY-MM-DD')}`,
        };
      }

      this.query.passedPeriod = period;
    },
  },
};
</script>

<style lang="scss">
@import 'src/assets/theme/default/reports/summary';
</style>
