<template>
  <div class="mini-wr">
    <div
      class="err-data"
      v-if="$store.state.report.loadingmini !== false"
      v-loading="$store.state.report.loadingmini !== false"
    ></div>
    <div v-if="$store.state.report.dataerr == false || dReg" class="err-data">
      <p v-if="!$store.state.report.dataerr">
        {{ $t('alerts.dataTable.loadingError') }}
      </p>
      <p v-if="dReg && !$store.state.report.loadingmini">
        {{ $t('alerts.dataTable.noData') }}
      </p>
    </div>
    <line-chart
      :configuration="configuration"
      :labels="labels"
      :datasets="datasets"
      :height="height"
      :key="dReg"
    />
  </div>
</template>

<script>
import LineChart from 'src/components/Charts/LineChartDashbord';

export default {

  components: {
    LineChart,
  },
  props: ['configuration', 'labels', 'datasets', 'height', 'dReg'],
  data() {
    return {};
  },
  mounted() {

  },
};
</script>
