<template>
  <div
    :data-color="backgroundColor"
    :class="{iphone: iphone}"
    class="sidebar"
  >
    <div class="logo">
      <a
        href="#"
        class="simple-text"
      >
        <div class="logo-image">
          <img :src="logo" @click="returnDashboard" alt="logo"/>
        </div>
      </a>
      <div class="navbar-minimize">
        <button
          id="minimizeSidebar"
          class="btn btn-simple btn-icon btn-neutral btn-round"
          @click="minimizeSidebar"
        >
          <img
            :src="hamburgerw"
            class="visible-on-sidebar-regular"
            alt=""
          >
          <img
            :src="hamburgerw"
            class="visible-on-sidebar-mini"
            alt=""
          >
        </button>
      </div>
    </div>
    <div
      ref="sidebarScrollArea"
      class="sidebar-wrapper"
    >
      <slot/>
      <ul class="nav">
        <slot name="links">
          <sidebar-item
            v-for="(link, index) in sidebarLinks"
            :key="link.name + index"
            :link="link"
          >
            <sidebar-item
              v-for="(subLink, index) in link.children"
              :key="subLink.name + index"
              :link="subLink"
            />
          </sidebar-item>
        </slot>
        <section class="language-bottom logout-bottom-mob">
          <li class="language-item">
            <language-popover
              :iphone="iphone"
            />
          </li>
        </section>
        <section class="logout-bottom-mob">
          <drop-down
            tag="li"
            position="center"
            direction="up"
            class="log-item"
            style="width:260px;text-align: center;"
          >
            <a
              slot="title"
              class="nav-link"
              href="#"
              @click.prevent
            >
              <img
                :src="logoutIcon"
                alt="logout"
              >
            </a>
            <div class="logout-box">
              <h4>{{ $t('topNavbar.logout') }}</h4>
              <p>{{ $t('topNavbar.youSure') }}<br>{{ $t('topNavbar.wantExit') }}?</p>
              <a
                class="btn btn-danger btn-sm btn-block"
                href="#/logout"
                @click="cookiesRemove"
              >{{ $t('topNavbar.logout') }}</a>
            </div>
          </drop-down>
        </section>
      </ul>
      <div>
        <div class="version">
          Version: {{ $appVersion }}
        </div>
        <a href="https://affiliatecontrol.com/" target="_blank" rel="noopener noreferrer" class="logo-label">
          <img :src="require('@/assets/img/affcontrol-logo-panel.svg')" alt="">
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import LanguagePopover from 'src/components/LanguagePopover';
import { Dropdown, DropdownMenu, DropdownItem } from 'element-ui';
import CookiesProvider from 'src/lib/cookieProvider.js';
import eventBus from 'src/lib/eventBus';
import logoutIcon from '@/assets/theme/icon/logout-icon.svg';

export default {
  name: 'Sidebar',
  components: {
    LanguagePopover,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [Dropdown.name]: Dropdown,
  },
  props: {
    title: {
      type: String,
      default: 'NAME',
    },
    backgroundColor: {
      type: String,
      default: 'black',
      validator: (value) => {
        const acceptedValues = ['', 'blue', 'azure', 'green', 'orange', 'red', 'purple', 'black', 'betwinner'];
        return acceptedValues.indexOf(value) !== -1;
      },
    },
    logoMini: {
      type: String,
      default: require('@/assets/logo.png'),
    },
    hamburger: {
      type: String,
      default: require('@/assets/theme/sidebar/hamburger.svg'),
    },
    hamburgerw: {
      type: String,
      default: require('@/assets/theme/sidebar/hamburger-w.svg'),
    },
    sidebarLinks: {
      type: Array,
      default: () => [],
    },
    autoClose: {
      type: Boolean,
      default: true,
    },
  },
  provide() {
    return {
      autoClose: this.autoClose,
    };
  },
  data() {
    return {
      iphone: false,
      minimized: false,
      filferLang: '',
      selected: 'en',
      transLang: true,
      logoutIcon,
    };
  },
  computed: {
    logo() {
      return this.$config.layout.logoFull;
    },
  },
  beforeDestroy() {
    this.$sidebar.showSidebar = !this.$sidebar.showSidebar;
  },
  mounted() {
    this.iphone = /iPhone/i.test(navigator.userAgent);
  },
  methods: {
    cookiesRemove() {
      const name = 'autologin_token';
      CookiesProvider.remove(name);
      eventBus.$on('logout', this.$request.removeAuthToken);
    },
    minimizeSidebar() {
      this.minimized = !this.minimized;
      this.$sidebar.showSidebar = !this.$sidebar.showSidebar;

      if (this.minimized) {
        this.transLang = true;
        document.body.classList.toggle('sidebar-mini');
      } else {
        document.body.classList.remove('sidebar-mini');
      }
    },
    change(code) {
      this.selected = code;
      this.transLang = true;
      return (this.$i18n.locale = code);
    },
    changeLang() {
      this.transLang = !this.transLang;
    },
    returnDashboard() {
      this.$router.push({
        name: 'routes.dashboard',
      });
      this.$sidebar.displaySidebar(false);
    },
  },
};
</script>

<style lang="scss">
.sidebar {
  .version {
    // position: fixed;
    // left: 23px;
    // bottom: 47px;
    margin-left: 24px;
    margin-bottom: 4px;
    color: #999;
    font-size: small;
    @media (max-width: 650px) {
      display: none;
    }
  }
  .nav {
    padding-bottom: 70px;
  }

  .language-bottom-desktop {
    position: fixed;
    bottom: 0;
    width: 260px;
    .transLang {
      display: none;
    }

    .lang__container {
      background-color: #444444;
      padding: 12px;
    }

    .lang__list {
      display: flex;
      flex-wrap: wrap;
      justify-content: left;
      list-style-type: none;
      padding: 10px 0 0 0;
    }

    .lang__item {
      width: 20%;
      text-align: center;

      &.active span {
        background: #696969;
        padding: 3px 8px;
        border-radius: 4px;
      }
    }

    .lang__link {
      color: #fff;
      text-transform: uppercase;
      cursor: pointer;
    }

    .lang__search {
      background-color: #ffffff;
    }

    .el-dropdown {
      width: 100%;
      background-color: #464d4b;
      color: #e7e7e7;
      height: 38px;

      .el-dropdown-link {
        display: block;
        font-size: 11px;
        font-weight: 600;
        line-height: 30px;
        letter-spacing: 1px;
        padding: 5px 10px 5px 20px;
        cursor: pointer;
      }

      .el-icon-arrow-down {
        float: right;
        transform: rotate(180deg);
      }
    }
  }

  .logout-bottom-mob {
    position: fixed;
    bottom: -18px;
    left: 0;
    width: 260px;
    .language-item,
    .btn-primary,
    .btn-primary:hover {
      color: #e7e7e7 !important;
      background-color: #464d4b !important;
    }

    .lang__item.active span {
      background: #696969;
      padding: 3px 8px;
      border-radius: 4px;
    }
  }

  .log-item {
    width: inherit;
    background-color: #ff5c70;
  }

  .language-bottom {
    bottom: 39px;
  }
}

.sidebar.iphone {
  .logout-bottom-mob {
    position: static;
  }
}

.sidebar-mini {
  .logout-bottom-mob,
  .language-bottom-desktop {
    width: 60px;

    .nav-item-lang {
      display: none;
    }
  }
}

.sidebar:hover {
  .logout-bottom-mob,
  .language-bottom-desktop {
    width: 260px;
  }

  .nav-item-lang {
    display: inline;
  }
}

.sidebar-wrapper {
  &::-webkit-scrollbar {
    width: 6px;
    background-color: rgba(37, 42, 41, 0);
  }

  &::-webkit-scrollbar-thumb {
    background-color: #464d4b;
    border-radius: 8px;
  }
}

@media (min-width: 991px) {
  .logout-bottom-mob {
    display: none;
  }
}

</style>
