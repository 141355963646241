<template>
  <div class="col-md-4 ml-auto mr-auto login-wrapper blocked-account review-account">
    <div class="login-logo">
      <img :src="logo" alt="logo">
    </div>
    <form>
      <card class="card-login">
        <img :src="require('@/assets/theme/icon/hourglass.svg')">
        <h3>{{ $t('forms.otherAccount.workingAnother') }}</h3>
        <div class="row">
          <div class="col-12 blocked-account__text">
            {{ $t('forms.otherAccount.sessionRestarted') }}
          </div>
        </div>
        <a
          class="btn btn-primary btn-md" href="/"
        >
          {{ $t('forms.otherAccount.reloadWindow') }}
        </a>
      </card>
    </form>
  </div>
</template>

<script>

export default {
  computed: {
    logo() {
      return this.$config.layout.logoFull;
    },
  },
};
</script>
