<template>
  <modal
    :show.sync="active"
    class="modal-password component-password"
    @close="close"
  >
    <template>
      <h5 slot="header" class="modal-title">
        {{ title }}
      </h5>
      <form :disabled="$store.state.errors.loading">
        <div class="modal__body">
          <div v-if="!label" class="modal__body-up mb-24">
            <h1>
              <img :src="require('@/assets/theme/icon/lock.svg')" alt="lock">
            </h1>
            <span>
              {{ $t('alerts.passwordDialog.message') }} {{ $store.state.profile.user.email }}
            </span>
          </div>
          <div class="modal__body-password">
            {{ label || $t('alerts.passwordDialog.label') }}
          </div>
          <input-wrap
            :message.sync="wrongMessage"
            name="password"
            @click.native="wrongMessage = ''"
          >
            <password-input ref="passwordRef" :data.sync="password"/>
          </input-wrap>
          <div class="text-danger error-style wrongPassword">
            {{ errMessage }}
          </div>
          <span>{{ comment }}</span>
        </div>
      </form>
    </template>

    <div slot="footer" class="text-center mt-8">
      <button class="btn btn-outline-primary btn-sm modal__btn-cancel" @click="close">
        {{ $t('alerts.passwordDialog.cancel') }}
      </button>
      <button :disabled="!valid || isLoading" class="btn btn-primary btn-sm modal__btn-confirm ml-8" @click="enter">
        <span v-if="!$store.state.errors.loading">
          {{ $t('alerts.passwordDialog.confirm') }}
        </span>
        <i v-if="$store.state.errors.loading" class="fas fa-spinner spinner-hide"/>
      </button>
    </div>
  </modal>
</template>

<script>
import { Modal } from 'src/components';
import FormWrap from 'src/components/ModalForm/FormWrap';
import InputWrap from 'src/components/Form/InputWrap';
import PasswordInput from 'src/components/PasswordInput';

export default {
  components: {
    Modal,
    FormWrap,
    InputWrap,
    PasswordInput,
  },
  data() {
    return {
      password: '',
      passwords: '',
      active: false,
      invalid: false,
      callback: new Function(),
      onclose: new Function(),
      title: 'Disable Two-Factor Authentication',
      label: '',
      comment: null,
      complete: false,
      disabled: false,
      wrongMessage: '',
      errMessage: '',
      min: 8,
      isLoading: false,
    };
  },
  computed: {
    valid() {
      return this.password.length >= (this.min || 8);
    },
  },
  methods: {
    open(title, comment, callback, onclose, label, min) {
      this.title = title || this.title;
      this.label = label || this.label;
      this.comment = comment;
      this.callback = callback;
      this.onclose = onclose || new Function();
      this.active = true;
      this.min = min || 8;
      document.addEventListener('keydown', this.keydownListener, true);
    },
    close() {
      this.active = false;
      this.title = 'Disable Two-Factor Authentication';
      this.comment = '';
      this.callback = new Function();
      this.onclose = new Function();
      this.password = '';
      this.errMessage = '';
      this.label = '';
      this.disabled = true;
      this.$event.$emit('forms/reset');
      this.$refs.passwordRef.reset();
      document.removeEventListener('keydown', this.keydownListener, true);
    },
    keydownListener(e) {
      if (e.keyCode == 13) {
        e.preventDefault();
        if (this.valid) this.enter();
      }
    },
    enter() {
      if (!this.password && !this.valid) {
        return;
      }
      if (this.callback) {
        this.wrongMessage = this.$t('dialogues.wrongPassword');
        this.callback(this.password, this);
      }
    },
  },
};
</script>

<style lang="scss">
@import 'styles/password';
</style>
