import NotFound from 'src/views/General/NotFound';
import DashboardLayout from 'src/views/Layout/Layout';
import Profile from 'src/views/Dashboard/Profile';
import Offers from 'src/views/Dashboard/OffersNew.vue';
import Contacts from 'src/views/Dashboard/Contacts';
import Postbacks from 'src/views/Dashboard/TrafficSources/Postbacks.vue';
import TagManager from 'src/views/Dashboard/TrafficSources/TagManager.vue';
import RequestLog from 'src/views/Dashboard/TrafficSources/RequestLog.vue';
import SubAffiliates from 'src/views/Dashboard/SubAffiliates';
import PromoMaterials from 'src/views/Dashboard/PromoMaterials';
import AdvertisingCampaigns from 'src/views/Dashboard/MediaBuying/AdvertisingCampaign';
import DetailAdCampaign from 'src/views/Dashboard/MediaBuying/DetailAdCampaign';
import DetailAdCampaignSub from 'src/views/Dashboard/MediaBuying/DetailAdCampaignSub';
import PaymentsView from 'src/views/Dashboard/MediaBuying/PaymentsView/PaymentsView';
import PostsView from 'src/views/Dashboard/MediaBuying/PostsView/PostsView';
import PaymentHistory from 'src/views/Dashboard/Wallet';
import PromoCodes from 'src/views/Dashboard/PromoCodes';
import AndroidApps from 'src/views/Dashboard/AndroidApps';
import Notifications from 'src/views/Dashboard/Notifications';
import Domains from 'src/views/Dashboard/Domains';
import TrafficSource from 'src/views/Dashboard/TrafficSource';
import Summary from 'src/views/Dashboard/Summary';
import Daily from 'src/views/Dashboard/DailyReports';
import Players from 'src/views/Dashboard/PlayerReports';
import Custom from 'src/views/Dashboard/CustomReports';
import SubAffiliatesReports from 'src/views/Dashboard/SubAffiliatesReports';
import Index from 'src/views/Dashboard/Index';
import withLoader from 'src/components/withLoader';
import IndexLoader from 'src/views/Loaders/Index-Loader.vue';
import ProfileLoader from 'src/views/Loaders/Profile-Loader.vue';
import WalletLoader from 'src/views/Loaders/Wallet-Loader.vue';
import SubAffiliatesLoader from 'src/views/Loaders/SubAffiliates-Loader.vue';
import TrafficSourcesLoader from 'src/views/Loaders/TrafficSources-Loader.vue';
import PostbacksLoader from 'src/views/Loaders/Postbacks-Loader.vue';
import RequestLogLoader from 'src/views/Loaders/RequestLog-Loader.vue';
import OffersLoader from 'src/views/Loaders/Offers-Loader.vue';
import PromoCodesLoader from 'src/views/Loaders/PromoCodes-Loader.vue';
import DomainsLoader from 'src/views/Loaders/Domains-Loader.vue';
import PromoMaterialsLoader from 'src/views/Loaders/PromoMaterials-Loader.vue';
import SummaryLoader from 'src/views/Loaders/Summary-Loader.vue';
import DailyLoader from 'src/views/Loaders/Daily-Loader.vue';
import PlayersLoader from 'src/views/Loaders/Players-Loader.vue';
import CustomLoader from 'src/views/Loaders/Custom-Loader.vue';
import MediaBuyingLoader from 'src/views/Loaders/MediaBuying-Loader';
import authRequired from './hooks/auth_required';

export default {
  path: '/dashboard',
  component: DashboardLayout,
  beforeEnter: authRequired,
  children: [
    {
      path: 'main/:urlQuery?',
      name: 'routes.dashboard',
      component: withLoader({
        Component: Index,
        Loader: IndexLoader,
        Catch: IndexLoader,
      }),
    },
    {
      path: 'profile/:urlQuery?',
      name: 'routes.profile',
      component: withLoader({
        Component: Profile,
        Loader: ProfileLoader,
      }),
    },
    {
      path: 'contacts/:urlQuery?',
      name: 'routes.contacts',
      component: Contacts,
    },
    {
      path: 'payment-information/:urlQuery?',
      name: 'routes.paymentHistory',
      component: withLoader({
        Component: PaymentHistory,
        Loader: WalletLoader,
      }),
    },
    {
      path: 'sub-affiliates/:urlQuery?',
      name: 'routes.subAffiliates',
      component: withLoader({
        Component: SubAffiliates,
        Loader: SubAffiliatesLoader,
      }),
    },
    {
      path: 'traffic-source/:urlQuery?',
      name: 'routes.trafficSource',
      component: withLoader({
        Component: TrafficSource,
        Loader: TrafficSourcesLoader,
      }),
    },
    {
      path: 'traffic-source/tag-manager/:id/:urlQuery?',
      name: 'routes.tagManager',
      component: withLoader({
        Component: TagManager,
        Loader: PostbacksLoader,
      }),
    },
    {
      path: 'traffic-source/:id/:urlQuery?',
      name: 'routes.postbacks',
      component: withLoader({
        Component: Postbacks,
        Loader: PostbacksLoader,
      }),
    },
    {
      path: 'traffic-source/log/:id/request-log/:urlQuery?',
      name: 'routes.requestLog',
      component: withLoader({
        Component: RequestLog,
        Loader: RequestLogLoader,
      }),
    },
    {
      path: 'offers/:urlQuery?',
      name: 'routes.offers',
      component: withLoader({
        Component: Offers,
        Loader: OffersLoader,
      }),
    },
    {
      path: 'domains/:urlQuery?',
      name: 'routes.domains',
      component: withLoader({
        Component: Domains,
        Loader: DomainsLoader,
      }),
    },
    {
      path: 'promo-codes/:urlQuery?',
      name: 'routes.promoCodes',
      component: withLoader({
        Component: PromoCodes,
        Loader: PromoCodesLoader,
      }),
    },
    {
      path: 'android-apps/:urlQuery?',
      name: 'routes.androidApps',
      component: withLoader({
        Component: AndroidApps,
      }),
    },
    {
      path: 'promo-materials/:urlQuery?',
      name: 'routes.promoMaterials',
      component: withLoader({
        Component: PromoMaterials,
        Loader: PromoMaterialsLoader,
      }),
    },
    {
      path: 'media-buying-campaigns/:urlQuery?',
      name: 'routes.mediaBuying',
      component: withLoader({
        Component: AdvertisingCampaigns,
        Loader: MediaBuyingLoader,
      }),
    },
    {
      path: 'media-buying-campaigns/:id/:urlQuery?',
      name: 'routes.mediaBuyingCampaignDetails',
      component: withLoader({
        Component: DetailAdCampaign,
        Loader: MediaBuyingLoader,
      }),
    },
    {
      path: 'media-buying-campaigns-sub/:id/:urlQuery?',
      name: 'routes.mediaBuyingCampaignDetailsSub',
      component: withLoader({
        Component: DetailAdCampaignSub,
        Loader: MediaBuyingLoader,
      }),
    },
    {
      path: 'media-buying-payments/:urlQuery?',
      name: 'routes.mediaBuyingPayments',
      component: withLoader({
        Component: PaymentsView,
        Loader: MediaBuyingLoader,
      }),
    },
    {
      path: 'media-buying-posts/:urlQuery?',
      name: 'routes.mediaBuyingPosts',
      component: withLoader({
        Component: PostsView,
        Loader: MediaBuyingLoader,
      }),
    },

    {
      path: 'summary/:urlQuery?',
      name: 'routes.summary',
      component: withLoader({
        Component: Summary,
        Loader: SummaryLoader,
      }),
    },
    {
      path: 'daily/:urlQuery?',
      name: 'routes.daily',
      component: withLoader({
        Component: Daily,
        Loader: DailyLoader,
      }),
    },
    {
      path: 'players/:urlQuery?',
      name: 'routes.players',
      component: withLoader({
        Component: Players,
        Loader: PlayersLoader,
      }),
    },
    {
      path: 'custom/:urlQuery?',
      name: 'routes.custom',
      component: withLoader({
        Component: Custom,
        Loader: CustomLoader,
      }),
    },
    {
      path: 'sub-affiliate-reports/:urlQuery?',
      name: 'routes.subAffiliatesReports',
      component: withLoader({
        Component: SubAffiliatesReports,
        Loader: PlayersLoader,
      }),
    },
    {
      path: 'notifications',
      name: 'routes.notifications',
      component: withLoader({
        Component: Notifications,
      }),
    },
    {
      path: '*',
      name: 'routes.notfound',
      component: NotFound,
    },
  ],
};
