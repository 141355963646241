<template>
  <div class="password form-group">
    <form>
      <input
        v-model="model"
        :type="passwordShown && 'text' || 'password'"
        class="password__input form-control "
        placeholder="***********"
        @input="input"
        autocomplete="on"
      />
    </form>
    <i
      :class="{'fa-eye':!passwordShown, 'fa-eye-slash': passwordShown}"
      class="fas text-muted mr-6"
      @click="show"
    />
  </div>
</template>

<script>
export default {
  props: ['data'],
  data() {
    return {
      passwordShown: false,
      model: '',
    };
  },
  watch: {
    model(val) {
      this.$emit('update:data', this.model);
    },
  },
  mounted() {
    this.$on('update:data', (data) => {
      this.model = data;
    });
  },
  methods: {
    show() {
      this.passwordShown = !this.passwordShown;
    },
    input() {
      this.$emit('input', this.model);
      this.$event.$emit('forms/reset');
    },
    reset() {
      this.passwordShown = false;
      this.model = '';
    },
  },
};
</script>

<style lang="scss" scoped>
.password {
  position: relative;

  i {
    position: absolute;
    right: 5px;
    top: 50%;
    opacity: 0.3;
    transform: translateY(-50%);
  }
}
</style>
