export default {
  data() {
    return {
      screenWidth: 0,
    };
  },
  computed: {
    onWindowResizeDebonced() {
      return this.$_.debounce(this.onWindowResize, 100);
    },
    isMobile() {
      return this.screenWidth <= 650;
    },
    isTablet() {
      return this.screenWidth > 650 && this.screenWidth <= 1050;
    },
    isDesktop() {
      return this.screenWidth > 1050;
    },
  },
  methods: {
    onWindowResize(event) {
      this.screenWidth = event.target.innerWidth;
    },
  },
  activated() {
    window.addEventListener('resize', this.onWindowResizeDebonced, true);
  },
  deactivated() {
    window.removeEventListener('resize', this.onWindowResizeDebonced, true);
  },
  mounted() {
    this.screenWidth = window.innerWidth;
    window.addEventListener('resize', this.onWindowResizeDebonced, true);
  },
};
