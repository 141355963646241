var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"player-report player-report__breakpoint",class:{
    wavebg: _vm.loading,
    mediaSAid: _vm.mediaSAid
  }},[_c('table-card',{ref:"datatable",attrs:{"loading":_vm.loading,"columns":_vm.getTableFields,"hide-columns":_vm.hiddenFields,"sort":_vm.sorting,"page":_vm.page,"page-size":_vm.limit,"rows":_vm.data,"totals":_vm.totals,"count":_vm.count,"i18n-path":"reports","period-picker":"","tablet-layout":"","condenced":"","show-total":"","height-fix":"","period":_vm.period,"col-filters":_vm.columnsFilter},on:{"update:sort":function($event){_vm.sorting=$event},"update:page":function($event){_vm.page=$event},"update:pageSize":function($event){_vm.limit=$event},"update:page-size":function($event){_vm.limit=$event},"update:period":function($event){_vm.period=$event},"change-range":_vm.fetchDataTable,"filter":_vm.handleFilter,"fetch-data":_vm.fetchDataTable}},[_c('div',{class:[
        'ui-d-flex',
        'ui-mobile-12',
        'ui-mobile-wrap'
      ],attrs:{"slot":"header"},slot:"header"},[_c('table-card-search-header',{class:{
          'ui-g-md': true,
          'ui-mobile-12': true,
          'ui-mobile-hide': !_vm.swowInput,
          'ui-tablet-hide': true,
        },on:{"search":_vm.handleSearch},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('filter-reports',{staticClass:"ui-g-md ui-mobile-12",attrs:{"value":_vm.filters,"from":_vm.period.from,"to":_vm.period.to,"use-filters":_vm.useFilters},on:{"input":_vm.updateFilters,"changeSAid":v => _vm.mediaSAid = v}})],1),_c('div',{staticClass:"ui-d-flex ui-mobile-hide",attrs:{"slot":"actionsPrepend"},slot:"actionsPrepend"},[_c('export-report',{staticClass:"ui-g-md",attrs:{"export-links":_vm.exportUrls}}),_c('div',{staticClass:"ui-g-md"},[_c('ui-button',{attrs:{"icon":"eye","icon-size":9},on:{"click":_vm.openColumnsPopup}})],1),_c('div',{staticClass:"ui-g-md"},[_c('ui-button',{attrs:{"icon":"refresh"},on:{"click":_vm.fetchDataTable}})],1)],1),_c('template',{slot:"headerPeriodPickerActions"},[_c('table-card-search-header',{class:[
          'ui-g-md',
          'ui-mobile-hide',
          'ui-desktop-only-hide'
        ],on:{"search":_vm.handleSearch},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('div',{class:[
          'ui-tablet-hide',
          'ui-desktop-hide',
          'ui-g-md',
        ]},[_c('ui-button',{attrs:{"icon":"search"},on:{"click":function($event){_vm.swowInput = !_vm.swowInput}}})],1),_c('export-report',{class:[
          'ui-tablet-hide',
          'ui-desktop-hide',
          'ui-g-md'
        ],attrs:{"export-links":_vm.exportUrls}}),_c('div',{class:[
          'ui-tablet-hide',
          'ui-desktop-hide',
          'ui-g-md'
        ]},[_c('ui-button',{attrs:{"icon":"refresh"},on:{"click":_vm.fetchDataTable}})],1)],1)],2),_c('ui-columns-select',{ref:"columnsPopup",attrs:{"groups":_vm.columns_groups,"default":_vm.default_cols,"supported":_vm.supportedMetrics,"metrics":_vm.getMetrics},on:{"changed":_vm.fetchDataTable},model:{value:(_vm.selectedColumns),callback:function ($$v) {_vm.selectedColumns=$$v},expression:"selectedColumns"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }