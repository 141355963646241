<template>
  <section
    class="promo__codes-form-wrap media-buying-modal add-public-modal"
    :class="{ paymentStatusSuccess: paymentStatus === 'successCreated' }"
  >
    <modal :show.sync="active" @close="close">
      <h5 slot="header" class="modal-title" v-if="!form.id">
        {{ $t('mediaBuying.campaign.request_payment') }}
      </h5>
      <h5 slot="header" class="modal-title" v-if="form.id">
        <span class="incrementing-id"> (ID: {{ form.incrementing_id }}) </span>
        {{ $t('mediaBuying.campaign.payment') }}
      </h5>
      <template>
        <div>
          <form v-if="paymentStatus === 'successCreated'">
            <div class="successPaymentBlock">
              <div>
                <img :src="require('@/assets/theme/icon/ok.svg')" alt />
              </div>
              <div class="title">
                {{ $t('mediaBuying.campaign.payment_request_created_message') }}
              </div>
              <div class="message" v-html="$t('mediaBuying.campaign.payment_request_success_message')"></div>
            </div>
          </form>
          <form v-else>
            <div v-if="form.id" class="comment-overflow">
              <div class="comment-wrap__wrap" v-for="(comment, index) in comments" :key="index">
                <div class="comment-wrap__mess">
                  <div v-if="comment.author_type === 'admin'" class="aff-text-left mb-8">
                    <span class="comment-wrap__mess-text comment-wrap__mess-text-left">
                      <span class="date">
                        {{ comment.created_at | formatDate3 }}
                      </span>
                      <span class="comment-wrap__mess-text-row">{{ comment.content }}</span>
                      <a
                        v-for="(file, ind) in comment.files"
                        :key="ind"
                        :href="file.download_url"
                        :download="file.filename"
                        target="_blank"
                        class="comment-wrap__mess-files mb-4 mr-4"
                      >
                        <span class="file-name">{{ file.filename }}</span>
                        <i class="fas fa-download pl-4"></i>
                      </a>
                    </span>
                  </div>
                  <div v-if="comment.author_type === 'affiliate'" class="aff-text-right text-right mb-8">
                    <span
                      :class="{ editable: editIndexComment === index }"
                      class="comment-wrap__mess-text comment-wrap__mess-text-right"
                    >
                      <span class="comment-wrap__content">
                        <span
                          v-if="(form.moderation_status === 'for_rework' || form.payout_status === 'for_rework') && hideEditAndDelete(comment)"
                          class="comment-wrap__control"
                        >
                          <span class="date">
                            {{ comment.created_at | formatDate3 }}
                          </span>
                          <i
                            v-if="editIndexComment !== index"
                            @click="editMessage(comment, index)"
                            class="fas fa-pen pl-4"
                          />
                          <i
                            v-if="editIndexComment !== index"
                            @click="deleteMessage(comment, index)"
                            class="fas fa-times pl-4"
                          />
                        </span>
                        <span class="comment-wrap__mess-text-row">{{ comment.content }}</span>
                        <a
                          v-for="(file, ind) in comment.files"
                          :key="ind"
                          :href="file.download_url"
                          :download="file.filename"
                          target="_blank"
                          class="comment-wrap__mess-files mb-4 mr-4"
                        >
                          <span class="file-name-wrap">
                            <span class="file-name">{{ file.filename }}</span>
                            <i class="fas fa-download pl-4"></i>
                          </span>
                        </a>
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="form.id" class="comment-wrap__inn mt-8">
              <div class="d-flex comment-wrap">
                <div>
                  <div class="form-group select-wrapper m-0 w-100">
                    <textarea
                      v-model="comment"
                      :disabled="!(form.moderation_status === 'for_rework' || form.payout_status === 'for_rework')"
                      :placeholder="$t('mediaBuying.campaign.comment')"
                      class="form-control form-control-ta"
                    />
                  </div>
                  <button
                    v-if="flagEditComment"
                    class="btn btn-outline-danger btn-sm btn-icon ml-8"
                    :disabled="!(form.moderation_status === 'for_rework' || form.payout_status === 'for_rework')"
                    @click.prevent="resetEdit"
                  >
                    <i class="fas fa-times text-red"></i>
                  </button>
                  <button
                    class="btn btn-outline-primary d-flex align-items-center justify-content-center btn-sm ml-8"
                    :disabled="isDisabledBtnComment || !comment"
                    @click.prevent="preloadImage"
                  >
                    <img :src="require('@/assets/theme/icon/ok.svg')" alt="" height="12" />
                    <span class="ml-4">{{ $t('mediaBuying.campaign.send') }}</span>
                  </button>
                </div>
              </div>
              <div class="form-group select-wrapper file-wrap file-wrap-comment mb-0 mt-0">
                <el-upload
                  :class="{
                    disabled:
                      fileListComment.length === $store.state.mediaBuying.uploadSettingsData.max_files_per_request,
                  }"
                  class="upload-demo"
                  action=""
                  :on-remove="handleRemoveComment"
                  :on-change="onSuccessComment"
                  :file-list="fileListComment"
                  :auto-upload="false"
                  multiple
                >
                  <button class="btn btn-outline-primary btn-sm btn-icon" @click.prevent>
                    <i class="fas fa-paperclip" />
                  </button>
                </el-upload>
                <span v-if="errorFile.length" class="error error-file text-danger text-left mt-2">
                  {{ errorFile[0] }}
                </span>
              </div>
            </div>
            <div v-if="form.id" class="statuses pay-info-wrap d-flex mb-8 mt-4 pt-10">
              <div class="select-wrapper mr-32">
                <label>{{ $t('mediaBuying.payments.fields.moderation_status.title') }}</label>
                <span v-html="formatStatus(form.moderation_status)" />
              </div>
              <div class="select-wrapper mr-32">
                <label>{{ $t('mediaBuying.payments.fields.payout_status.title') }}</label>
                <span v-html="formatStatus(form.payout_status)" />
              </div>
            </div>
            <div class="d-flex mobile-flex-column">
              <div class="select-wrapper w-100 mb-0">
                <label>{{ $t('mediaBuying.campaign.payment_type') }}</label>
                <v-select
                  :id="`${$randomizer()}form.site_payment_method_id`"
                  v-model="form.site_payment_method_id"
                  :options="paymentList"
                  class="el-input"
                  @change="(form.payment_wallet = ''); form.currency_code = ''; (error.site_payment_method_id = '')"
                />
                <span v-if="error.site_payment_method_id" class="text-danger text-left mt-2">
                  {{ error.site_payment_method_id[0].message }}
                </span>
              </div>
              <div
                class="form-group select-wrapper w-100 ml-16 mb-0 mt-0"
                :class="{ 'disable-input': form.site_payment_method_id === '' }"
              >
                <label>{{ $t('mediaBuying.campaign.wallet') }}</label>
                <input
                  :disabled="form.site_payment_method_id === ''"
                  v-model="form.payment_wallet"
                  :placeholder="wallet_placeholder"
                  autocomplete="off"
                  type="text"
                  class="form-control m-0 w-100"
                  @keydown.enter.prevent
                  @input="error.payment_wallet = ''"
                />
                <span v-if="error.payment_wallet" class="text-danger text-left mt-2">
                  {{ error.payment_wallet[0].message }}
                </span>
              </div>
            </div>

            <div class="d-flex mobile-flex-column mobile-flex-column-period">
              <div class="form-group select-wrapper per-wrap">
                <label>{{ $t('mediaBuying.campaign.payment_period_start') }}</label>
                <i class="far fa-calendar-alt"></i>
                <el-date-picker
                  class="date-picker datepicker-input m-0 p-0 w-100"
                  ref="datepicker"
                  v-model="form.payment_period_start"
                  :placeholder="$t('mediaBuying.campaign.specify_period')"
                  type="datetime"
                  format="dd MMM yyyy HH:mm"
                  default-time="00:00:00"
                  @change="error.payment_period_start = ''"
                />
                <span v-if="error.payment_period_start" class="text-danger text-left mt-2">
                  {{ error.payment_period_start[0].message }}
                </span>
              </div>
              <div class="form-group select-wrapper per-wrap ml-16">
                <label>{{ $t('mediaBuying.campaign.payment_period_end') }}</label>
                <i class="far fa-calendar-alt"></i>
                <el-date-picker
                  class="date-picker datepicker-input m-0 p-0 w-100"
                  ref="datepicker"
                  v-model="form.payment_period_end"
                  :placeholder="$t('mediaBuying.campaign.specify_period')"
                  type="datetime"
                  format="dd MMM yyyy HH:mm"
                  default-time="23:59:59"
                  @change="error.payment_period_end = ''"
                />
                <span v-if="error.payment_period_end" class="text-danger text-left mt-2">
                  {{ error.payment_period_end[0].message }}
                </span>
              </div>
            </div>

            <div class="d-flex mobile-flex-column">
              <div
                class="select-wrapper w-100"
                :class="{ 'disable-input': form.site_payment_method_id === '' }"
              >
                <label>{{ $t('dashboard.currency') }}</label>
                <v-select
                  :id="`${$randomizer()}GetLinks.curreny_cd`"
                  v-model="form.currency_code"
                  :disabled="!form.site_payment_method_id"
                  :options="currencyList"
                  class="el-input"
                />
                <span v-if="error.currency_code" class="text-danger text-left mt-2">
                  {{ error.currency_code[0].message }}
                </span>
              </div>
              <div class="form-group select-wrapper sum-wrap mb-8 mt-0 ml-16">
                <label>{{ $t('mediaBuying.campaign.sum') }}</label>
                <div class="d-flex w-100">
                  <!-- <span>{{ currencyFormatter($store.state.mediaBuying.campaign.payments_currency_code) }}</span> -->
                  <input
                    v-model="form.amount"
                    :placeholder="$t('mediaBuying.campaign.sum')"
                    autocomplete="off"
                    type="text"
                    class="form-control mt-0"
                    @keydown.enter.prevent
                    @input="error.amount = ''"
                  />
                </div>
                <span v-if="error.amount" class="text-danger text-left mt-2">
                  {{ error.amount[0].message }}
                </span>
              </div>
            </div>

            <div class="form-group select-wrapper mb-8">
              <label>{{ $t('mediaBuying.campaign.description') }}</label>
              <el-input
                type="textarea"
                maxlength="1024"
                v-model="form.description"
                :autosize="{ minRows: 3, maxRows: 8 }"
                class="no-border form-control-lg textarea-autosize"
                :placeholder="$t('mediaBuying.campaign.description')"
                @input="error.description = ''"
              />
              <span v-if="error.description" class="text-danger text-left mt-2">
                {{ error.description[0].message }}
              </span>
            </div>
            <div class="form-group select-wrapper file-wrap mb-0">
              <label class="file-wrap__label">{{ $t('mediaBuying.campaign.files') }}</label>
              <span class="file-wrap__span">
                {{
                  `${$t('mediaBuying.campaign.up_to')} ${$store.state.mediaBuying.uploadSettingsData.max_files_per_request} ${$t(
                    'mediaBuying.campaign.files2',
                  )},
                ${$t('mediaBuying.campaign.max')} ${$store.state.mediaBuying.uploadSettingsData.max_upload_bytes / 1024 / 1024}${$t(
                    'mediaBuying.campaign.mb_each',
                  )}`
                }}
              </span>
              <el-upload
                :class="{
                  disabled: fileList.length === $store.state.mediaBuying.uploadSettingsData.max_files_per_request,
                }"
                class="upload-demo"
                drag
                action=""
                :on-remove="handleRemove"
                :on-change="onSuccess"
                :file-list="fileList"
                :auto-upload="false"
                multiple
              >
                <div class="el-upload__text">
                  {{ $t('mediaBuying.campaign.dnd') }}
                  <span class="text-green">{{ $t('mediaBuying.campaign.select_file') }}</span>
                </div>
              </el-upload>
              <span v-if="errorFileDown.length" class="error error-file text-danger text-right mt-2">
                {{ errorFileDown[0] }}
              </span>
            </div>
          </form>
        </div>
      </template>
      <div v-if="paymentStatus === 'successCreated'" slot="footer" class="successPaymentFooter">
        <a class="btn btn-primary btn-sm" @click="close">
          <span>{{ $t('mediaBuying.campaign.got_it') }}</span>
        </a>
      </div>
      <div v-else slot="footer" class="text-right d-flex align-items-baseline">
        <a class="btn btn-outline-primary btn-sm" @click="close">
          {{ $t('promoCodes.addPromocodes.cancel') }}
        </a>
        <a class="btn btn-primary btn-sm ml-16" :disabled="loading" @click="requestSend">
          <span v-if="!loading">{{ $t('mediaBuying.campaign.request_payment') }}</span>
          <i v-if="loading" class="fas fa-spinner spinner-hide" />
        </a>
      </div>
    </modal>
  </section>
</template>

<script>
import { Modal } from 'src/components';
import eventBus from 'src/lib/eventBus';
import editMessages from './mixins/editMessages';
import uploadFileDragAndDrop from './mixins/uploadFileDragAndDrop';

const classes = {
  approved: 'text-green',
  on_review: 'text-orange',
  declined: 'text-red',
  paid: 'text-green',
  processing: 'text-orange',
  not_paid: 'text-red',
  for_rework: 'text-red',
  failed: 'text-red',
};

export default {
  components: {
    Modal,
  },
  mixins: [editMessages, uploadFileDragAndDrop],
  data() {
    return {
      fileListBack: [],
      fileListCommentBack: [],
      saveImageDefaultAdd: [],
      errorFileDown: [],
      idNeedsDeleted: [],
      fileListDouble: [],
      fileListDoubleComment: [],
      fileList: [],
      fileListComment: [],
      countFile: 0,
      countFileComment: 0,
      comment: '',
      comments: [],
      formComment: {
        content: '',
        file_ids: [],
      },
      active: false,
      form: {
        site_payment_method_id: '',
        payment_period_start: '',
        payment_period_end: '',
        currency_code: '',
        file_ids: [],
      },
      fileListBackup: [],
      fileIdsBackup: [],
      error: [],
      loading: false,
      amount: '',
      paymentStatus: '',
    };
  },
  props: ['id'],

  computed: {
    currencyList() {
      if (!this.form.site_payment_method_id) {
        return [{
          id: '',
          text: this.$t('profile.generalInfo.notSpecified'),
        }];
      }

      const paymentMethod = this.paymentList.find(({ id }) => id === this.form.site_payment_method_id);
      const { currency_code } = this.form;

      if (paymentMethod.allowed_currencies === 'selected') {
        return this.$_.uniqBy([...paymentMethod.currencies,  ...(currency_code ? [{ currency_code }] : [])], 'currency_code')
          .map(({ currency_code }) => this.$store.state.misc.currencies.find(({ code }) => code === currency_code))
          .map((cur) => ({ id: cur.code, text: cur.name }))
          .concat({ id: '', text: this.$t('profile.generalInfo.notSpecified') });
      }

      return this.$store.state.misc.currencies
        .map((cur) => ({ id: cur.code, text: cur.name }))
        .concat({ id: '', text: this.$t('profile.generalInfo.notSpecified') });
    },
    paymentList() {
      return this.$store.state.mediaBuying.paymentMethod
        .map((e) => ({
          ...e,
          text: e.name,
        }))
        .concat({
          id: '',
          text: this.$t('profile.generalInfo.notSpecified'),
        });
    },
    wallet_placeholder() {
      if (this.paymentList.length) {
        const payment = this.paymentList.find((e) => e.id === this.form.site_payment_method_id) || {};
        return payment.wallet_placeholder;
      }
    },
  },
  methods: {
    getComments() {
      this.getCommentsPayment(this.form.id);
    },
    open(row) {
      this.active = true;
      this.paymentStatus = '';
      this.form.currency_code = '';

      if (row && row.id) {
        this.getCommentsPayment(row.id);
        const pickQuery = [
          'id',
          'incrementing_id',
          'moderation_status',
          'payout_status',
          'currency_code',
          'site_payment_method_id',
          'payment_period_end',
          'payment_period_start',
          'payment_wallet',
          'amount',
          'description',
          'file_ids',
          'files',
        ];

        this.form = {
          ...this.$_.pick(row, pickQuery),
          currency_code: row.currency_code,
        };

        this.idNeedsDeleted = this.$_.cloneDeep(this.form.files.map((file) => file.id));
        this.form.file_ids = this.$_.cloneDeep(this.idNeedsDeleted);

        this.fileList = row.files.map((e) => ({
          status: 'ready',
          name: e.filename,
          id: e.id,
        }));
      }
    },
    close() {
      this.active = false;
      this.reset();
    },
    reset() {
      this.resetEdit();
      this.errorFile = [];
      this.errorFileDown = [];
      this.error = [];
      this.fileList = [];
      this.form = {
        site_payment_method_id: '',
        payment_period_start: '',
        payment_period_end: '',
        file_ids: [],
      };
      this.comments = [];
      this.fileListComment = [];
      this.comment = '';
      this.formComment = {
        content: '',
        file_ids: [],
      };
    },
    send() {
      this.loading = true;
      this.paymentStatus = '';

        this.form.payment_period_start = this.form.payment_period_start
        ? moment(this.form.payment_period_start).format('YYYY-MM-DD HH:mm:00')
        : '';
      this.form.payment_period_end = this.form.payment_period_end
        ? moment(this.form.payment_period_end).format('YYYY-MM-DD HH:mm:00')
        : '';

      if (!this.form.id) {
        this.$store
          .dispatch('createPayment', {
            ...this.form,
            campaign_id: this.$store.state.mediaBuying.campaign.id,
          })
          .then(() => {
            this.$emit('addPayment', 'silent', 'hard');
            this.paymentStatus = 'successCreated';
          })
          .catch((err) => {
            this.error = err.response.data.errors;
            this.fileList = this.$_.cloneDeep(this.fileListBackup);
            this.form.file_ids = [];
          })
          .finally(() => {
            this.$nextTick(() => {
              this.loading = false;
            });
          });
      } else {
        this.$store
          .dispatch('editPayment', {
            ...this.form,
            status: 'on_review',
          })
          .then(() => {
            eventBus.$emit('addPayment', undefined, 'hard');
            this.close();
            this.$alert.success(this.$t('mediaBuying.campaign.payment_request_edited_message'));
          })
          .catch((err) => {
            this.error = err.response.data.errors;
            this.fileList = this.$_.cloneDeep(this.fileListBackup);
            this.form.file_ids = [];
          })
          .finally(() => {
            this.$nextTick(() => {
              this.loading = false;
            });
          });
      }
    },
    postCommentsPayment(id) {
      this.loading = true;
      this.formComment.content = this.comment;
      this.$store
        .dispatch('postPaymentComments', { id, ...this.formComment })
        .then(() => {
          this.comment = '';
          this.formComment = {
            content: '',
            file_ids: [],
          };
          this.getCommentsPayment(this.form.id);
        })
        .finally(() => {
          this.loading = false;
          this.isDisabledBtnComment = false;
        });
    },
    getCommentsPayment(id) {
      this.loading = true;
      const [sort_column, sort_dir] = ['created_at', 'asc'];
      this.$store
        .dispatch('getPaymentComment', {
          id,
          sort_column,
          sort_dir,
        })
        .then((res) => {
          this.comments = res.payload;
          setTimeout(() => {
            const over = document.getElementsByClassName('comment-overflow')[0];
            if (over) over.scrollTop = 9999;
          }, 100);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    currencyFormatter(code) {
      if (!code) {
        return '';
      }

      const miscCurrencies = this.$store.state.misc.currencies;
      const currency = miscCurrencies.find((c) => code.toLowerCase() === c.code?.toLowerCase());

      return currency ? currency.code.toUpperCase() : '';
    },

    formatStatus(status) {
      return status && `<span class='${classes[status]}'>${this.$t(`mediaBuying.payments.status.${status}`)}</span>`;
    },
  },
};
</script>

<style lang="scss">
.el-upload__input {
  display: block;
}

.statuses {
  border-top: 1px solid #dee2e6;
}
</style>
<style lang="scss" scoped>
.media-buying-modal.paymentStatusSuccess {
  /deep/ {
    .modal-dialog .modal-content {
      width: 360px;
    }
  }
}
.successPaymentFooter {
  display: flex;
  justify-content: center;
  flex: 1;
}
.successPaymentBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 32px;
  padding-bottom: 32px;

  .title {
    margin-top: 16px;
    font-size: 14px;
    font-weight: 700;
    line-height: 1.71;
    color: var(--color-text-primary);
    padding-left: 8px;
    padding-right: 8px;
  }

  .message {
    margin-top: 8px;
    font-size: 14px;
    line-height: 1.71;
    color: var(--color-text-primary);
    padding-left: 18px;
    padding-right: 18px;
    text-align: center;

    /deep/ {
      span {
        color: var(--color-text-green);
      }
    }
  }
}
</style>
