<template>
  <div
    :class="{
      'table-card': true,
      'table-card__has-row-click': rowClick,
      'card': true,
      'card-mob': true,
      'wavebg': loading
    }"
  >
    <div
      :class="{
        'table-card__header': true,
        'table-card__header--condenced': pCondenced,
        'ui-g-w-md': true
      }"
    >
      <slot name="headerUpper"></slot>

      <div class="table-card__header-center">
        <div
          :class="{
            'table-card__header-left': true ,
            'ui-tablet-wrap': pTabletLayout,
            'ui-tablet-6': pTabletLayout,
            'ui-mobile-12': true,
            'ui-mobile-wrap': true
          }"
        >
          <div
            v-if="header"
            :class="{
              'table-card__header-title': true,
              'ui-g-md': true,
              'ui-mobile-12': true,
              'ui-mobile-m-md-b': true,
              'ui-desktop-m-sm-r': true,
              'ui-tablet-12': pTabletLayout,
            }"
          >
            {{ header }}
          </div>

          <div
            v-if="pPeriodPicker"
            :class="{
              'ui-g-md': true,
              'ui-tablet-12': pTabletLayout,
              'ui-mobile-12': true,
            }"
          >
            <div
              :class="{
                'ui-g-w-md': true,
                'ui-tablet-12': pTabletLayout,
                'ui-mobile-12': true,
              }"
            >
              <period-wrapper
                :class="{
                  'peroid-table': true,
                  'ui-g-md': true,
                  'ui-mobile-12': true,
                  'table-card__period-picker': true,
                  'table-card__header-control': true,

                }"
                :settings="period"
                @update="setRange"
                @change="$emit('change-range', $event)"
              />
              <slot name="headerPeriodPickerActions"></slot>
            </div>
          </div>

          <slot name="header"></slot>
        </div>
        <div
          :class="{
            'table-card__header-right': true,
            'table-card__header-control': true,
            'ui-tablet-wrap': pTabletLayout,
            'ui-tablet-6': pTabletLayout,
            'ui-mobile-12': true
          }"
        >
          <slot name="actionsPrepend"></slot>

          <div
            :class="{
              'table-card__header-right-lower': true,
              'table-card__header-control': true,
              'ui-tablet-hide': !hasActionsAppend && !pHasPagination,
              'ui-desktop-hide': !hasActionsAppend && !pHasPagination,
              'ui-mobile-hide': !hasActionsAppend && !hasSort,
              'ui-tablet-12': pTabletLayout,
              'ui-mobile-12': true,
              'ui-g-md': true
            }"
          >
            <div
              :class="{
                'table-card__header-control': true,
                'ui-g-w-md': true,
                'ui-mobile-12': true,
                'ui-12': true,
                'ui-tablet-wrap': pTabletLayout,
                'ui-mobile-wrap': true,
              }"
            >
              <div
                v-if="sort && !$_.isEmpty(sort)"
                :class="[
                  'sort-wrap',
                  'table-card__sort-wrap',
                  'table-card__header-control',
                  'ui-g-md',
                  'ui-desktop-hide',
                  'ui-mobile-12'
                ]"
              >
                <sort-by
                  class="table-card__sort-by ui-mobile-12"
                  :columns="columnsSort"
                  :sort_by="sort[sortByName]"
                  :sort_dir="sort[sortDirName]"
                  :key="sort[sortByName]"
                  :i18n-path="pI18nPathSort"
                  @sortBy="handleSortBy"
                />
              </div>

              <slot name="actionsAppend"></slot>

              <div
                v-if="pHasPagination"
                :class="[
                  'table-card__header-control',
                  'ui-g-md',
                  'ui-mobile-hide'
                ]"
              >
                <ui-pagination
                  ref="pagination"
                  :page="page"
                  :page-size="pageSize"
                  :count="count"
                  show-size-select
                  @page-change="pageChange"
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <slot name="headerAppend"></slot>
    </div>


    <ui-table
      ref="dataTable"
      :class="{
        'table-card__desktop-table': true,
        'table-card__desktop-table--condenced': pCondenced,
        'table-card__desktop-table--sparse': !pCondenced,
        'table-card__desktop-table--height-fix': !pHeightFix,
        'table-card__desktop-table--disabled-actions': pDisableActions
      }"
      :fields="columnsComputed"
      :hide-fields="hideColumns"
      :data="rows"
      :total="totals"
      :currency="currency"
      :rows-count="pageSize"
      :row-selectable="rowSelectable"
      :actions="actionsDesktop"
      :show-total="showTotal"
      :i18n-path="i18nPath"
      :col-filters="colFilters"
      :is-static="isStatic"
      :extend-height="extendHeight"
      :external-height="externalHeight"
      :dont-fill-empty="dontFillEmpty"
      :row-click="rowClick"
      :custom-context="customContext"
      :placement-tooltip="placementTooltip"
      element-loading-custom-class="data-loader-spinner"
      :sort="uiTableSort"
      :disable-sort="!hasSort"
      :selected-row="selectedRow"
      @sort="handleSort"
      @filter="$emit('filter', $event)"
      @checkedRows="handleCheckedRows"
      @current-change="$emit('current-change', $event)"
    />
    <mobtable
      ref="datatable"
      :data.sync="rows"
      :columns.sync="columnsComputed"
      :hide-fields="hideColumns"
      :currency="currency"
      :formaters="formaters"
      :totals="totals"
      :actions="actionsMobile"
      :sort-field="sort[this.sortByName]"
      :sort_dir="sort[this.sortDirName]"
      :i18n-path="i18nPath"
      :switchable="pMobileSwitchable"
      :period="pMobileHasPeriod ? period : undefined"
    />
    <mobpagination
      v-if="pHasPagination && rows.length"
      :class="{
        'ui-m-xxxl-b': showTotal,
      }"
      :page="page || 1"
      :page-size="pageSize || 0"
      :count="count"
      show-size-select
      @page-change="pageChange"
    />
  </div>
</template>

<script>
import SortBy from 'src/components/SortBy.vue';
import Mobtable from 'src/components/Mobiletable/Mobtable';
import Mobpagination from 'src/components/Mobiletable/MobPagination';
import PeriodWrapper from 'src/views/Dashboard/Reports/Filters/PeriodWrapper';
import ColumnsMixin from 'src/components/Helpers/columns-mixin.js';

/**
 * Generic table component with card wrapper and mobile version
 *
 * Slots list:
 * @slot headerUpper -- upper part of the header. Use case: breadcrumbs
 * @slot headerPeriodPickerActions -- Left part. Same block as period picker. Use this if component need extra flow control with responsive wrap
 * @slot header -- Left part. Last component of left part of the header
 * @slot actionsPrepend -- Right part. Before embedded components. By default stick to left part of header
 * @slot actionsAppend -- Right part. After embedded components.
 */
export default {
  name: 'TableCard',
  components: {
    SortBy,
    Mobtable,
    Mobpagination,
    PeriodWrapper,
  },
  mixins: [
    ColumnsMixin,
  ],
  data() {
    return {
      offsetParams: {
        limit: 10,
        offset: 0,
      },
      pLoading: false,
    };
  },
  props: {
    loading: Boolean,
    columns: Array,
    hideColumns: Array,
    sort: {
      type: Object,
      default: () => ({}),
    },
    sortByName: {
      type: String,
      default: 'sort_by',
    },
    sortDirName: {
      type: String,
      default: 'sort_dir',
    },
    page: {
      type: Number,
      default: 1,
    },
    pageSize: {
      type: Number,
      default: 0,
    },
    currency: {
      type: String,
      default: '',
    },
    selectedRow: [Array, Object],
    rows: Array,
    totals: Object,
    count: Number,
    formaters: Object,
    actions: Object,

    periodPicker: {
      type: Boolean,
      default: false,
    },
    period: Object,
    header: String,
    i18nPath: String,
    colFilters: [Array, Object],
    isStatic: [String, Boolean],
    showTotal: [Boolean, Boolean],
    extendHeight: [String, Boolean],
    customContext: [String, Boolean],
    dontFillEmpty: [String, Boolean],
    rowSelectable: [String, Boolean],
    externalHeight: [Number, String],
    placementTooltip: [String],
    rowClick: Function,

    /**
     * Custom props section
     */

    /** Add classes to fix incorrect height calculation */
    heightFix: [Number, String],

    /** Add classes for tablet screen width */
    tabletLayout: [String, Boolean],

    /** Reduce padding of table card wrapper */
    condenced: [String, Boolean],

    /** Enable or disable pagination */
    hasPagination: {
      type: [String, Boolean],
      default: true,
    },

    /** Disable actions in table */
    disableActions: [String, Boolean],

    /** i18nPath for sort if it distinct from i18nPath prop */
    i18nPathSort: String,

    /**
    * Mobile props section
    */
    mobileSwitchable: [String, Boolean],
    mobileHasPeriod: [String, Boolean],
  },
  computed: {
    actionsDesktop() {
      return this.omitActions('omitDesktop');
    },
    actionsMobile() {
      return this.omitActions('omitMobile');
    },
    pPeriodPicker() {
      return this.periodPicker || this.periodPicker === true;
    },
    hasActionsPrepend() {
      return this.$slots.actionsPrepend;
    },
    hasActionsAppend() {
      return !!this.$slots.actionsAppend;
    },
    hasSort() {
      return this.sort && !this.$_.isEmpty(this.sort);
    },
    hasHeader() {
      return this.$slots.header;
    },
    pTabletLayout() {
      return this.tabletLayout === '' || this.tabletLayout;
    },
    pCondenced() {
      return this.condenced === '' || this.condenced;
    },
    pHeightFix() {
      return this.heightFix === '' || this.heightFix;
    },
    pHasPagination() {
      return this.hasPagination === '' || this.hasPagination;
    },
    pDisableActions() {
      return this.disableActions === '' || this.disableActions;
    },
    pI18nPathSort() {
      return this.i18nPathSort || (`${this.i18nPath}.fields`);
    },
    uiTableSort() {
      return {
        prop: this.sort[this.sortByName],
        order: this.sort[this.sortDirName],
      };
    },
    columnsSort() {
      return this.columnsComputed.filter(item => item.show !== false);
    },
    columnsComputed() {
      return this.processColumns(this.columns, { currencyLabel: this.currency });
    },
    pMobileSwitchable() {
      return this.mobileSwitchable || this.mobileSwitchable === '';
    },
    pMobileHasPeriod() {
      return this.mobileHasPeriod || this.mobileHasPeriod === '';
    },
    actionsName() {
      return typeof this.actions.name === 'function'
        ? this.actions.name()
        : this.actions.name;
    },
  },
  methods: {
    omitActions(omitProp) {
      return this.actions
        ? {
          ...this.actions,
          name: this.actionsName,
          operations: this.actions.operations
            .filter(item => !item[omitProp]),
        }
        : this.actions;
    },
    pageChange(page, size) {
      this.offsetParams.offset = size * (page - 1);

      if (this.pageSize !== size) {
        this.offsetParams.offset = 0;
        this.resetPager();
      }

      this.offsetParams.limit = size;

      this.$emit('update:page', page);
      this.$emit('update:pageSize', size);

      const params = {
        ...this.offsetParams,
        ...this.sort,
        ...this.period,
      };

      this.$emit('fetch-data', params, null);
    },
    handleSort(params) {
      this.$refs.dataTable.$refs.dataTable.setCurrentRow();

      const sort = {
        [this.sortByName]: params.prop,
        [this.sortDirName]: params.order,
      };

      this.sortBy(sort);
    },
    handleSortBy(params) {
      const sort = {
        [this.sortByName]: params.sort_by,
        [this.sortDirName]: params.sort_dir,
      };

      this.sortBy(sort);
    },
    resetPager() {
      if (this.$refs.datatable) {
        this.$refs.datatable.resetPager();
      }

      if (this.$refs.pagination) {
        this.$refs.pagination.resetPagination();
      }
    },
    sortBy(sort) {
      this.$emit('update:sort', sort);
      this.$nextTick(() => {
        this.pageChange(this.page, this.pageSize);
      });
    },
    resetSort() {
      this.$refs.dataTable.setSortVisibility([this.sort[this.sortByName]]);
      this.$refs.dataTable.$refs.dataTable.sort(this.sort[this.sortByName], `${this.sort[this.sortDirName]}ending`);
    },
    setRange(period) {
      if (!this.$_.isEqual(this.period, period)) {
        this.resetPager();
      }

      this.$emit('update:period', period);

      this.$nextTick(() => {
        if (localStorage.getItem('auth-token') && localStorage.getItem('auth-token') !== 'null') {
          this.offsetParams.limit = this.pageSize;

          this.resetSort();

          const params = {
            offset: 0,
            ...period,
            ...this.sort,
          };

          if (this.pHasPagination) {
            Object.assign(params, this.offsetParams);
          }

          // this.$emit('fetch-data', params, null);
        }

        this.$emit('set-range', period);
      });
    },
    handleCheckedRows(rowId, checkRows, row) {
      this.$emit('checkedRows', rowId, checkRows, row);
    },
  },
};
</script>

<style lang="scss">
.table-card {
  &.card.card-mob {
    padding: 0;
    position: static;
  }

  .select-respons {
    position: relative;
    width: 100%;
  }

  &__actions {
    display: flex;
  }

  &__header {
    flex-direction: column;
    padding: 24px 24px 16px;

    &--condenced {
      padding: 16px;
    }
  }

  &__header-center {
    flex-grow: 1;
    width: 100%;
    display: flex;
  }

  &__has-row-click {
    .el-table__row {
      cursor: pointer;
    }
  }

  &__desktop-table {
    @at-root #{selector-append(&, & + '--condenced', '.ui-table')} {
      padding-left: 16px;
      padding-right: 16px;
      padding-bottom: 0 !important;
    }
  }

  &__desktop-table {
    @at-root #{selector-append(&, & + '--sparse', '.ui-table')} {
      padding-left: 24px;
      padding-right: 24px;
      padding-bottom: 24px !important;
    }
  }

  &__desktop-table {
    &--height-fix {
      .el-table__body-wrapper {
        height: auto !important;
      }
    }

    &--disabled-actions {
      .row-value.actions {
        opacity: .5;
        pointer-events: none;
      }
    }

    .row-value.actions {
      .icon__wrapper[disabled] {
        opacity: .35;
        cursor: not-allowed !important;
      }
    }
  }

  .el-table__row {
    .overflow-ellipsis {
      display: inline-block;
      text-align: left;
      width: auto;
      max-width: 100%;
      padding-top: 2px;
    }
  }

  .el-table .el-table__row td {
    padding: 0;
  }

  .el-table th.is-leaf .cell .filter-opener.open {
    display: flex !important;
    opacity: 1 !important;
  }

  &__header-title {
    white-space: nowrap;
    font-size: 16px;
    line-height: 1;
    font-weight: 600;
    display: flex;
    align-items: center;
    height: 42px;
  }

  &__header-button {
    width: 100%;
  }

  &__header-left {
    display: flex;

    &--tablet-layout {
      flex-wrap: wrap;
    }
  }

  &__sort-wrap {
    display: flex;
  }

  &__header-right {
    display: flex;
    flex-grow: 1;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;

    &--tablet-layout {
      flex-wrap: wrap;
    }
  }

  &__header-control {
    display: flex;
    justify-content: flex-end;
  }

  @media (max-width: 650px) {
    &__header {
      flex-wrap: wrap;
      flex-direction: column;
      padding: 16px;
    }

    &__header-center {
      flex-wrap: wrap;
      flex-direction: column;
    }

    &__header-right {
      flex-wrap: wrap;
      flex-direction: column;
      align-items: flex-start;
      flex-grow: 1;
      width: 100%;
    }

    &__period-picker {
      flex-grow: 1;
      width: 100%;
    }

    &__sort-by,
    &__sort-wrap {
      flex-grow: 1;
      width: 100%;
    }

    &__header-control {
      width: 100%;
    }

    &__actions {
      &--append {
        margin-right: 0;
      }
    }

    // &__header-right-lower {
    //   &--empty {
    //     display: none;
    //   }
    // }
  }
}
</style>
