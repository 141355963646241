import VueProgressBar from 'vue-progressbar';

export default {
  install(Vue) {
    Vue.use(VueProgressBar, {
      color: '#ffce06',
      failedColor: '#d26370',
      thickness: '4px',
      autoRevert: false,
      inverse: false,
      transition: { speed: '0.3s', opacity: '0.6s', termination: 600 },
    });
  },
};
