import Axios from 'axios';
import { debounce } from 'lodash';
import store from 'src/store';
import ApiLib from './api';
import Cookie from './cookieProvider';
import configGlobal from '../config';
/*
 * Extends:
 *   Vue.prototype.$request
 *   Store.prototype.$request
 *   $request.setAuthToken(token)
 *   $request.removeAuthToken()
 */

export default {
  install(Vue, config) {
    const $eventBus = config.eventBus;
    const $router = config.router;
    const $errors = config.errors;
    const affiliatesApi = Axios.create({
      baseURL: config.baseURL,
      withCredentials: true,
      headers: {
        common: {
          Authorization: localStorage.getItem('auth-token') || '',
          'X-Selected-Locale': localStorage.getItem('language') || 'en',
          'X-Customer-Id': configGlobal.customerId,
        },
      },
    });
    const miscApi = Axios.create({
      baseURL: config.miscURL,
      withCredentials: true,
      headers: {
        common: {
          Authorization: localStorage.getItem('auth-token') || '',
          'X-Selected-Locale': localStorage.getItem('language') || 'en',
          'X-Customer-Id': configGlobal.customerId,
        },
      },
    });

    Object.defineProperty(affiliatesApi, '$router', {
      value: $router,
      writable: false,
    });
    Object.defineProperty(miscApi, '$router', {
      value: $router,
      writable: false,
    });

    affiliatesApi.setAuthToken = miscApi.setAuthToken = function (token) {
      this.defaults.headers.common.Authorization = token;
      localStorage.setItem('auth-token', token);
      Cookie.set(
        'autologin_token',
        token,
        config.store.state.auth.userTtl,
        location.protocol === 'https:',
      );
    }.bind(affiliatesApi);
    affiliatesApi.removeAuthToken = miscApi.setAuthToken = function () {
      this.defaults.headers.common.Authorization = null;
      localStorage.removeItem('auth-token');
      Cookie.remove('autologin_token');
      Object.keys(localStorage).forEach((key) => {
        if (key !== 'email' && key !== 'language') {
          localStorage.removeItem(key);
        }
      });
    }.bind(affiliatesApi);

    $eventBus.$on('logout', affiliatesApi.removeAuthToken);
    $eventBus.$on('set-auth-token', (token) => {
      affiliatesApi.setAuthToken(token);
      miscApi.setAuthToken(token);
    });

    const startEmit = debounce(() => {
      $eventBus.$emit('request-start');
    }, 100);
    const endEmit = debounce(() => {
      $eventBus.$emit('connection-enabled');
      $eventBus.$emit('request-end');
    }, 100);

    function interceptorsRequest(cnf) {
      const token = localStorage.getItem('auth-token');
      Cookie.set(
        'autologin_token',
        token,
        config.store.state.auth.userTtl,
        location.protocol === 'https:',
      );
      cnf.headers.Authorization = token;
      config.store.commit('errorReset');
      startEmit();
      config.store.commit('requestStart');

      if (
        token
        && config.store.state.auth.userToken
        && token !== 'null'
        && config.store.state.auth.userToken !== token
      ) {
        config.router.push('/other-account');
      }
      config.store.commit('userToken', token);
      return cnf;
    }

    affiliatesApi.interceptors.request.use(interceptorsRequest);
    miscApi.interceptors.request.use(interceptorsRequest);

    function interceptorsResponse(response) {
      config.store.commit('errorReset');
      endEmit();
      config.store.commit('requestEnd');
      if (config.environment === 'test') {
        console.log(response);
      }
      if (response.status === 401) {
        config.router.push('/login');
      }
      return Promise.resolve(response);
    }

    function interceptorsResponseError(err) {
      if (err.response.data.code === 'NOT_ENOUGH_PERMISSIONS_ERROR') {
        if (err.response.config.url === 'media-buying/campaigns/attention-counters') {
          store.dispatch('getProfileData');
        } else {
          config.router.push({ name: '404' });
        }
      }
      config.store.commit('requestEnd');
      $eventBus.$emit('request-fail');
      endEmit();
      if (
        err.message === 'Network Error'
        || err.message.indexOf('timeout') !== -1
      ) {
        $eventBus.$emit('connection-disabled');
        return Promise.reject(err);
      }
      $eventBus.$emit('connection-enabled');

      try {
        let _code;
        if (err.response.data) {
          _code = err.response.data.code;
          if (err.response.data.errors) {
            _code = (err.response.data.errors[0]
                && err.response.data.errors[0].code)
              || _code;
          }
        }
        config.store.commit('errorRequest', {
          status: err.response.status,
          message: $errors.messages[_code],
        }); // TODO: add meanignful messages on general purpose requests
        $eventBus.$emit('request/error', {
          status: err.response.status || err.response.data.status,
          errors: err.response.errors || err.response.data.errors,
          source: '',
          code: _code,
          data: err.response.data,
          message: err.response.data.message,
        });
        $eventBus.$emit(`error.${_code}`, {
          status: err.response.status || err.response.data.status,
          errors: err.response.errors || err.response.data.errors,
          code: _code,
          source: '',
        });
        $eventBus.$emit(`response/${err.response.status}`, {
          status: err.response.status,
          message: $errors.messages[err.response.data.code],
        });
      } catch (e) {
        console.log(e);
      }
      $eventBus.$emit(`error/${err.response.status}`);

      return Promise.reject(err);
    }

    affiliatesApi.interceptors.response.use(
      interceptorsResponse,
      interceptorsResponseError,
    );
    miscApi.interceptors.response.use(
      interceptorsResponse,
      interceptorsResponseError,
    );

    Vue.prototype.$request = affiliatesApi;
    Vue.prototype.$requestMisc = miscApi;
    Vue.prototype.$api = new ApiLib(affiliatesApi, miscApi);

    Object.defineProperty(config.store, '$request', {
      value: affiliatesApi,
      writable: false,
    });
    Object.defineProperty(config.store, '$api', {
      value: Vue.prototype.$api,
      writable: false,
    });
  },
};
