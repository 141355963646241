export default (mock) => {
  mock.onGet('/promo-codes').reply(200, {
    success: true,
    payload: [
      {
        promocode: 123456,
        registrations: 'payout',
        players: 25,
        statistics: {},
      },
      {
        promocode: 54654654,
        registrations: 'payout',
        players: 5,
        statistics: {},
      },
      {
        promocode: 2345345,
        registrations: 'payout',
        players: 23,
        statistics: {},
      },
      {
        promocode: 896787,
        registrations: 'payout',
        players: 34,
        statistics: {},
      },
      {
        promocode: 45654654,
        registrations: 'logout',
        players: 25,
        statistics: {},
      },
    ],
    errors: [
      {},
    ],
  });

  mock.onPost('/promo-codes').reply(200);
};
